import React, { useState } from "react";
import TransactionStatus from "../../../../../../components/transactions/components/TransctionStatus";
import { DetailTitle } from "../../../../../Transactions/style";
import {
    BeneficiaryWrapper,
    Check,
    CheckFlex,
    CopyIcon,
    Flex,
    Heading,
    Paragraph,
    TransactionWrapper,
} from "./styles";

interface Props {
    reference: string;
    amount?: string;
    rate?: string;
    date: string;
    transactionId?: string;
    status?: string;
    sourceAmount?: string;
    destinationAmount?: string;
    transactionType?: string;
    narration?: string;
    bankName?: string;
    accountNumber?: string;
    accountName?: string;
}

const Details = ({
    reference,
    amount,
    date,
    transactionId,
    rate,
    narration,
    bankName,
    status,
    sourceAmount,
    destinationAmount,
    transactionType,
    accountNumber,
    accountName,
}: Props) => {
    const [copy, setCopy] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

    return (
        <>
            <DetailTitle>{amount}</DetailTitle>
            <TransactionWrapper>
                <Flex>
                    <Paragraph>Status</Paragraph>
                    <TransactionStatus text={status} />
                </Flex>
                <Flex>
                    <Paragraph>Transaction ID</Paragraph>
                    <CheckFlex>
                        {copy ? (
                            <Check />
                        ) : (
                            <CopyIcon
                                onClick={() =>
                                    onCopyLinkToClipboard(
                                        transactionId,
                                    )
                                }
                                data-testid="copy-icon"
                            />
                        )}

                        <Heading>{reference}</Heading>
                    </CheckFlex>
                </Flex>

                <Flex>
                    <Paragraph>Transaction type</Paragraph>
                    <Heading>{transactionType}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>Rate</Paragraph>
                    <Heading>{rate}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>Source Amount</Paragraph>
                    <Heading>{sourceAmount}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>
                        Destination Amount
                    </Paragraph>
                    <Heading>{destinationAmount}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>Date</Paragraph>
                    <Heading>{date}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>Narration</Paragraph>
                    <Heading>{narration}</Heading>
                </Flex>
            </TransactionWrapper>

            <BeneficiaryWrapper>
                <Flex>
                    <Paragraph>Bank account name</Paragraph>
                    <Heading>{accountName}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>
                        Bank account number
                    </Paragraph>
                    <Heading>{accountNumber}</Heading>
                </Flex>

                <Flex>
                    <Paragraph>Bank name</Paragraph>
                    <Heading>{bankName}</Heading>
                </Flex>
            </BeneficiaryWrapper>
        </>
    );
};

export default Details;
