import React from "react";
import {
  StepsContainer,
  StepsTitle,
  StepsForm,
  InputContainer,
  SelectContainer,
  FlagBox,
  PlaceholderText,
  ErrorBox,
  Custom,
  SelectOption,
  Input,
  RateBox,
} from "../steps/style";
import "../../../../components/common/CustomSelect/style.css";
import { Button } from "../../../../components/common/Button";
import { ReactComponent as TransferIcon } from "../../assets/transfer.svg";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/alert-circle.svg";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { setFundsValue, setAmountValue } from "../../CardSlice";
import { addCommasToNumber } from "../../utils";
import { Link } from "react-router-dom";
import { FundsProps } from "../../types";

const { Option } = Select;

const Funds = ({
  handleFunds,
  smallText,
  feeAlert,
  fee,
  IncreaseHeight,
}: FundsProps) => {
  const dispatch = useAppDispatch();
  const { wallets, fundsValue, amountValue, rate } = useAppSelector(
    (state) => state.cards,
  );

  const amount = amountValue ? parseFloat(amountValue) : 0;
  const total = amount * rate;
  const totalWithFees = (amount + fee) * rate;

  const ngnWallet = wallets
    ? wallets.find((wallet: { currency: string }) => wallet.currency === "NGN")
    : {};

  const ngnBalance = ngnWallet ? ngnWallet.balance : 0;
  const minimumUsdBalance = 10; 

  return (
    <StepsContainer>
      <StepsTitle>
        <h1>How much do you want to add to your USD card?</h1>
        <p>{smallText}</p>
      </StepsTitle>

      <StepsForm onSubmit={handleFunds}>
        <InputContainer
          $hasError={parseFloat(amountValue) < minimumUsdBalance && IncreaseHeight}
        >
          <Input
            type="number"
            value={amountValue}
            onChange={(e) => dispatch(setAmountValue(e.target.value))}
            hasError={
              parseFloat(amountValue) < minimumUsdBalance ||
              (fundsValue &&
                fundsValue.label === ngnWallet.currency &&
                total > ngnBalance)
            }
          />

          {!amountValue && 
            <PlaceholderText>
              Fund with
            </PlaceholderText>
          }

          <FlagBox>
            <p className="currency-flag currency-flag-usd" />
            <p>USD</p>
          </FlagBox>

          {fundsValue &&
          fundsValue?.label === ngnWallet.currency &&
          total > ngnBalance && (
            <ErrorBox>
              <span>
                Exceeds your NGN wallet balance by ₦
                {addCommasToNumber((total - ngnBalance).toFixed(2))}
              </span>
              <Link to={"/wallets/NGN"}>
                Top-up funds
              </Link>
            </ErrorBox>
          )}

          {parseFloat(amountValue) < minimumUsdBalance && (
            <>
              <span>
                <ErrorIcon /> Minimum top-up amount is $10
              </span>
              {feeAlert}
            </>
          )}
        </InputContainer>

        <SelectContainer>
          <Custom
            labelInValue={true}
            optionLabelProp="label"
            value={{
              value: "",
              label: fundsValue ? (
                <SelectOption>
                  <span className={"currency-flag currency-flag-ngn"} />
                  <span>{fundsValue.label} Wallet</span>
                  <span>₦{addCommasToNumber(fundsValue ? fundsValue.value.toFixed(2) : 0)}</span>
                </SelectOption>
              ) : (
                "Paying with"
              ),
            }}
            onChange={(e: any) => dispatch(setFundsValue(e))}
            suffixIcon={<DropdownIcon />}
            popupClassName="filter-dropdown"
          >
            <Option key={1} value={ngnBalance} label={ngnWallet?.currency}>
              <SelectOption>
                <span className="currency-flag currency-flag-ngn" />
                <span>NGN Wallet</span>
                <span>₦{addCommasToNumber(ngnBalance.toFixed(2))}</span>
              </SelectOption>
            </Option>
          </Custom>

          {fundsValue?.label === "NGN" && (
            <RateBox>
              ₦{addCommasToNumber(total.toFixed(2))} at $1
              <TransferIcon /> ₦{rate ? rate.toFixed(2) : 0}
            </RateBox>
          )}
        </SelectContainer>

        <Button
          label="Continue"
          type="submit"
          theme="primary"
          width="100%"
          fontSize="14px"
          height="48px"
          disabled={
            fundsValue === null ||
            !rate ||
            amount < minimumUsdBalance ||
            (fundsValue.label === ngnWallet.currency &&
              totalWithFees > ngnBalance)
          }
        />
      </StepsForm>
    </StepsContainer>
  );
};

export default Funds;
