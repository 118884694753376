import React from "react";
import { Cards, PageTitle, Wrapper } from "./style";
import ContactCard from "../../components/contact/ContactCard";

type ContactType = {
  url: string;
  key:
    | "twitter"
    | "whatsapp"
    | "facebook"
    | "email"
    | "instagram"
    | "linkedin"
    | "website"
    | "support"
    | "youtube";
  title: string;
  subTitle: string;
};

const contacts: ContactType[] = [
  {
    key: "whatsapp",
    title: "Message on WhatsApp",
    subTitle: "+1 415-993-3858",
    url: "https://wa.me/+14159933858",
  },
  {
    key: "email",
    title: "Send us an email",
    subTitle: "vip-support@klasha.com",
    url: "mailto:vip-support@klasha.com",
  },
  {
    key: "website",
    title: "Visit our website",
    subTitle: "www.klasha.com",
    url: "https://www.klasha.com",
  },
  {
    key: "instagram",
    title: "Klasha on Instagram",
    subTitle: "@klashabusiness",
    url: "https://www.instagram.com/klashabusiness/?igshid=YmMyMTA2M2Y%3D%2F&",
  },

  {
    key: "twitter",
    title: "Klasha on Twitter",
    subTitle: "@KlashaBusiness",
    url: "https://twitter.com/KlashaBusiness",
  },
  {
    key: "linkedin",
    title: "Klasha on Linkedin",
    subTitle: "Klasha",
    url: "https://www.linkedin.com/company/klasha/",
  },
  {
    key: "support",
    title: "FAQs",
    subTitle: "https://support.klasha.com/en/",
    url: "https://support.klasha.com/en/",
  },
  {
    key: "youtube",
    title: "Klasha on Youtube",
    subTitle: "Klasha",
    url: "https://www.youtube.com/channel/UC1p4ewFR0PUtrZooHlCQfOw",
  },
];

const ContactUs = () => (
  <Wrapper>
    <PageTitle>Contact us</PageTitle>
    <Cards>
      {contacts.map((contact) => (
        <ContactCard
          url={contact.url}
          key={contact.key}
          id={contact.key}
          title={contact.title}
          subTitle={contact.subTitle}
        />
      ))}
    </Cards>
  </Wrapper>
);

export default ContactUs;
