export const currencies = [
    { label: "CNY", value: "CNY" },
    { label: "GBP", value: "GBP" },
    { label: "KES", value: "KES" },
    { label: "NGN", value: "NGN" },
    { label: "USD", value: "USD" },
    { label: "ZAR", value: "ZAR" },
    { label: "CDF", value: "CDF" },
    { label: "UGX", value: "UGX" },
    { label: "XOF", value: "XOF" },
    { label: "XAF", value: "XAF" },
    { label: "RWF", value: "RWF" },
    { label: "ZMW", value: "ZMW" },
    { label: "MWK", value: "MWK" },
    { label: "SLL", value: "SLL" },
];
