import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  font-family: "Inter";

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Left = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 20px;
    width: min(50%, 505px);
  }
`;

export const Right = styled.div`
  margin-top: 32px;
  @media (min-width: 768px) {
    margin-top: 0;
    margin-left: 24px;
    flex: 1 1;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  & button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
  }
  & span {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 500;
  }
  @media (min-width: 768px) {
    margin-bottom: 20px;
    width: min(100%, 504px);
  }
`;

export const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: Inter;
`;
