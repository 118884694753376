import React from "react";
import {
    FlexRole,
    RoleDescription,
    RoleList,
    RoleTitle,
    Dots
} from "../../styles";
import { titleCase } from "../../utils/capitalizeName";

interface Props {
    title: string;
    description?: string;
    total_members: string;
    onClick?: (data) => void;
}

const RoleListData = ({
    title,
    description,
    total_members,
    onClick
}: Props) => {

    
    return (
        <RoleList>
            <div>
                <RoleTitle>{titleCase(title)}</RoleTitle>
                <RoleDescription>
                    {description}
                </RoleDescription>
            </div>
            <FlexRole>
                <RoleDescription>
                    ({total_members})  Members
                </RoleDescription>

                <Dots onClick={onClick}/>
            </FlexRole>
        </RoleList>
    );
};

export default RoleListData;
