import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import dayjs from "dayjs";
import { debounce } from 'lodash';
import { Button } from "../../components/common/Button";
import { PageTitle, TableWrapper, Wrapper } from "./styles";
import RequestRefundModal, {
    formData,
} from "./Components/RequestRefundModal";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import {
    InitiateRefund,
    getATransactionDetails,
    getAllRefunds,
} from "./redux/actions";
import Loader from "../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import CustomTable from "../../components/common/CustomTable";
import {
    handleExportCSV,
    handleExportXLSX,
    filterOption,
    format,
    tableColumns,
    updateFilterStatus,
    updateStatus,
} from "./utils";
import { EmptyDescription } from "./Components/EmptyState";
import TableTools from "../../components/common/TableTools";
import { formatCurrencyAmount } from "../../utils/currency";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import Details from "./Components/Details";
import { formatLongString } from "../../utils/formatString";
import {getBusinessKeys} from "../Settings/settingsAPI";

const initialState = {
    openModal: false,
    tableData: null,
    amount: null,
    date: null,
    status: null,
    search: null,
    count: null,
    page: null,
    openDrawer: false,
    record: null,
    key: null,
    apiValue: false,
    transaction: null,
};

const Refunds = () => {
    const dispatch = useAppDispatch();
    const [state, setState] = useState(initialState);
    const startDate = state.date
        ? dayjs(state.date[0]).format("YYYY-MM-DD")
        : null;
    const endDate = state.date
        ? dayjs(state.date[1]).format("YYYY-MM-DD")
        : null;
    const { onShowAlert: onShowSuccessAlert } =
        useContext(SuccessAlertContext) || {};
    const { onShowAlert: onShowErrorAlert } =
        useContext(NetworkErrorAlertContext) || {};
    const { loading } = useAppSelector(
        (state) => state.refunds || {},
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );

    const handleRequestRefund = async (data: formData) => {
        if (!state.apiValue) {
            const actionPromise = await dispatch(
                getATransactionDetails({
                    tnxRef: data.txRef,
                }),
            );
            if (
                getATransactionDetails.rejected.match(
                    actionPromise,
                )
            ) {
                onShowErrorAlert(
                    "",
                    "Transaction not found",
                );
            } else if (
                getATransactionDetails.fulfilled.match(
                    actionPromise,
                )
            ) {
                setState((prev) => ({
                    ...prev,
                    apiValue: true,
                    transaction: actionPromise.payload,
                }));
            }
        } else {
            try {
                const action = await dispatch(
                    InitiateRefund(data),
                );
                
                if (InitiateRefund.rejected.match(action)) {
                    onShowErrorAlert(
                        "",
                        String(action.payload),
                    );
                } else {
                    onShowSuccessAlert(
                        `Refund created successfully`,
                    );
                    setState((prev) => ({
                        ...prev,
                        openModal: false,
                    }));
                    getAllMerchantRefunds();
                }
            } catch (error) {
                return error;
            }
        }
    };

    const formattedAmount = Number(
        state?.amount?.replace(/,/g, ""),
    );
    const getAllMerchantRefunds = useCallback(async () => {
        const action = await dispatch(
            getAllRefunds({
                pageNumber: state?.page,
                refundRef: state?.search,
                pageSize: "8",
                key: state?.key,
                startDay: startDate,
                endDay: endDate,
                status: updateFilterStatus(state.status),
                fundAmount: formattedAmount,
            })
        );
    
        if (action.type === getAllRefunds.fulfilled.type) {
            const refundsData = action.payload.refunds;
            const tableData = refundsData.map((item) => ({
                transId: formatLongString(item.refundRef, 20) || "N/A",
                ref: item.refundRef,
                accountName: item?.beneficiaryDetails?.accountName || "N/A",
                accountNumber: item?.beneficiaryDetails?.accountNumber || "N/A",
                bank: item?.beneficiaryDetails?.bank || "N/A",
                currency: item.currency || "N/A",
                transactionRef: formatLongString(item.txRef, 10) || "N/a",
                transactionId: item.txRef,
                amount: formatCurrencyAmount(item.currency, item.amount) || "N/A",
                date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm") || "N/A",
                status: updateStatus(item.status) || "N/A",
            }));
    
            setState((prev) => ({
                ...prev,
                tableData: tableData,
                count: action.payload.totalCount,
                page: action.payload.pageNumber,
            }));
        } else {
            onShowErrorAlert("", "An error occurred. Can not get refunds");
        }
    }, [dispatch, state]);


    useEffect(() => {
        if (state?.key || state?.page || state?.search) {
            getAllMerchantRefunds();
        }
    }, [state?.key, state?.page, state?.search]);




    const handleDataUpdate = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };



    const handleReset = async () => {
        setState(initialState);
            await fetchData();
            getAllMerchantRefunds();
    };


    const getExportRefunds = useCallback(async () => {
        const action = await dispatch(getAllRefunds({
            key: state.key || "",
            pageNumber: 1,
            pageSize: 1000
        }));

        if (action.type === getAllRefunds.fulfilled.type) {
            const refundsData = action.payload.refunds;
            return refundsData.map((item) => ({
                transId: item.refundRef || "N/A",
                ref: item.refundRef,
                accountName: item?.beneficiaryDetails?.accountName || "N/A",
                accountNumber: item?.beneficiaryDetails?.accountNumber || "N/A",
                bank: item?.beneficiaryDetails?.bank || "N/A",
                currency: item.currency || "N/A",
                transactionRef: item.txRef || "N/A",
                transactionId: item.txRef,
                amount: formatCurrencyAmount(item.currency, item.amount) || "N/A",
                date: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm") || "N/A",
                status: updateStatus(item.status) || "N/A",
            }));
        } else {
            onShowErrorAlert("", "An error occurred. Cannot get refunds");
            return [];
        }
    }, [dispatch, state]);




    const handleTableData = (row: any) => {
        setState((prev) => ({
            ...prev,
            record: row,
            openDrawer: true,
        }));
    };

    const handleUpdateAmount = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: format(value.target.value),
        }));
    };

    const fetchData = async () => {
        try {
            const action = await getBusinessKeys(businessId)
            const { data } = action;
            setState((prev) => ({
                ...prev,
                key: data.publicKey,
                }));

        }
        catch (error) {
            onShowErrorAlert("", "An error occurred. Cannot get refunds");

        }
    }

    const debouncedHandleDataUpdate = useCallback(
        debounce((key, value) => {
            handleDataUpdate(key, value);
        }, 300),
        []
    );

    useEffect(() => {
        fetchData();
    }, [businessId]);

        

    return (
        <Wrapper>
            <PageTitle>Refunds</PageTitle>

            <Button
                theme="secondary"
                label="Create new refund"
                width="148px"
                height="40px"
                className="refund-cta"
                onClick={() =>
                    setState((prev) => ({
                        ...prev,
                        openModal: true,
                    }))
                }
            />

            <RequestRefundModal
                openModal={state.openModal}
                setState={setState}
                handleNext={handleRequestRefund}
                apiValue={state.apiValue}
                transaction={state.transaction}
            />
            <Loader isLoading={loading} />

            <TableWrapper>
                <TableTools
                    onSearchChange={(value) =>
                        debouncedHandleDataUpdate('search', value.target.value)
                    }
                    showAmount
                    placeHolder="Search by refund ID"
                    amountValue={state.amount}
                    amountValueChange={(e) =>
                        handleUpdateAmount("amount", e)
                    }
                    setState={setState}
                    onStatusChange={(value) =>
                        handleDataUpdate("status", value)
                    }
                    options={filterOption}
                    onDateChange={(value) =>
                        handleDataUpdate("date", value)
                    }
                    onFilterButtonClick={getAllMerchantRefunds}
                    onResetButtonClick={handleReset}
                    onCSVButtonClick={() => handleExportCSV(getExportRefunds)}
                    onXLSButtonClick={() =>
                        handleExportXLSX(getExportRefunds)
                    }
                    statusValue={state.status}
                />
                <CustomTable
                    columns={tableColumns}
                    dataSource={state?.tableData}
                    extraEmptyChildren={
                        <EmptyDescription />
                    }
                    totalItemCount={state?.count}
                    onPageChange={(data) =>
                        handleDataUpdate("page", data)
                    }
                    handleRowClick={handleTableData}
                />
            </TableWrapper>

            <DrawerContainer
                open={state.openDrawer}
                placement="right"
                onClose={() =>
                    handleDataUpdate("openDrawer", false)
                }
                title="Refund details"
            >
                <Details
                    reference={state?.record?.transId}
                    transaction_id={state?.record?.ref}
                    amount={state?.record?.amount}
                    source={""}
                    transactionRef={state?.record?.transactionId}
                    txRef={state?.record?.transactionRef}
                    rate={""}
                    date={state?.record?.date}
                    status={state?.record?.status}
                />
            </DrawerContainer>
        </Wrapper>
    );
};

export default Refunds;
