import React from 'react';
import { Select } from "antd";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/arrow-down.svg";
import { StyledCurrencyInput, Wrapper } from './styles';
import { CustomSelect, SelectOption } from '../../rates/Conversion/style';

const { Option } = Select;

interface CurrencyProps {
    onSelectChange?: (data: string) => void;
    selectValue?: string;
    countries?: {
        value?: string;
        label?: string;
        fullName?: string;
    }[];
    placeholder?: string;
    amount?: number;
    setAmountValue?: (data: any) => void;
}


const CurrencySelector = ({
    countries,
    onSelectChange,
    selectValue,
    placeholder,
    amount,
    setAmountValue
}: CurrencyProps) => {

    return (
        <Wrapper>
            <StyledCurrencyInput placeholder={placeholder} value={amount} onValueChange={setAmountValue} />

            <div className="select">
                <CustomSelect
                    dropdownMatchSelectWidth={false}
                    placement="bottomLeft"
                    value={selectValue}
                    onChange={onSelectChange}
                    suffixIcon={<DropdownIcon />}
                    popupClassName="filter-dropdown"
                    placeholder="Currency"
                >
                    {countries?.length ? (
                        countries.map((curr) => (
                            <Option key={curr.value} value={curr.value}>
                                <SelectOption>
                                    <span className={`currency-flag currency-flag-${curr.value.toLowerCase()}`} />
                                    <span>{curr.value}</span>
                                </SelectOption>
                            </Option>
                        ))
                    ) : (
                        <Option value="select-currency">No currency selected</Option>
                    )}
                </CustomSelect>
            </div>

        </Wrapper>
    );
};

export default CurrencySelector;

