import styled from "styled-components";
import { ReactComponent as Copy } from "./assets/copyIcon.svg";
import { ReactComponent as CheckIcon } from "./assets/checkIcon.svg";




export const SettlementDetailWrapper = styled.div`
border-radius: 8px;
background: #F9F9F9;
padding: 10px;
margin-top: 20px;
}`;

export const Heading = styled.h3`
color: #000;
font-family: Athletics;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.48px;

`;

export const PayoutBtnWrapper = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
flex-row: wrap;

`

export const RequestPayoutContent = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
text-align: center;
h1{
color: var(--shades-black, #000);
text-align: center;
font-family: Athletics;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 160% */
letter-spacing: -0.4px;
}
p{
color: var(--grey-grey-700, #565C69);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}
`

export const MobileWrapper = styled.div`
  width: 100%;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 16px 0px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Top = styled.div`
  padding: 0 16px;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 10px;

  p{
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px; 
    font-family: "Inter";
    color: #767676;
  }

  span {
    font-style: "Athletics";
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-top: 8px;
  }
  
`;

export const Bottom = styled.div`
  display: flex;
  padding: 16px 0 0 16px;
  flex-wrap: wrap;
  justify-content: space-between;

`;

export const BottomItem = styled.div`
  width: 40%;
  margin-bottom: 16px;

`;

export const BottomLabel = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 15.6px;
  color: #767676;
  margin-bottom: 4px;
`;

export const BottomValue = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
`;


export const Flex = styled.div`
display: flex;
justify-content: space-between;
align-items:center;
`

export const Paragraph = styled.p`
color: #6B6B6B;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */

`;

export const SubHeading = styled.p`
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
padding: 10px;
`;



export const DetailsWrapper = styled.div`
margin-top: 40px
border-radius: 8px;
background: #F9F9F9;
padding: 10px;

`;


export const CopyIcon = styled(Copy)`
margin-bottom: 10px;

`;

export const Check = styled(CheckIcon)`
margin-bottom: 10px;

`;