import styled, { css } from "styled-components";
import { ReactComponent as KlashaLogo } from "./assets/icons/klasha.svg";
import { ReactComponent as CloseIcon } from "./assets/icons/dots.svg";

import { ReactComponent as Broken } from "./assets/icons/brokenLink.svg";
import { Dropdown } from "antd";

export const Heading = styled.h1`
    color: #000;
    font-family: Athletics;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.4px;
`;

export const EmptyWrapperStyle = styled.div`
    h1 {
        color: #000;
        text-align: center;
        font-family: Athletics;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.48px;
    }
    p {
        color: #565c69;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
    button {
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
    }
`;

export const StyledTransactionsTable = styled.div`
    font-family: "Inter";
    margin-top: 35px;
    overflow: hidden;

    .invite-team {
        margin: 20px;
        button {
            text-align: center;

            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 15.6px; /* 130% */
        }
    }

    .transaction-list {
        display: flex;
        justify-content: space-between;
    }

    h3 {
        margin: 20px;
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        line-height: 28px;
    }

    @media (min-width: 768px) {
        border: 1px solid #d9d9d9;
        border-radius: 8px;
    }
`;

export const InviteContent = styled.div`
    h1 {
        color: #000;
        text-align: center;
        font-family: Athletics;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px; /* 160% */
        letter-spacing: -0.4px;
    }

    .edit-btn {
        margin-top: 30px;
    }
`;

export const Flex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

export const Note = styled.p`
    color: #606673;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.6px;
    margin-top: 20px;
`;

export const Wrapper = styled.div`
    text-align: center;
    height: 50vh;
    margin-top: 80px;
    .show-otp {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .pass-btn {
        margin-top: 20px;
    }
`;

export const Container = styled.div`
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    background: #fff;
    max-width: 456px;
    height: auto;
    padding: 32px 40px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 20px auto 0;
    h3 {
        color: #000;
        text-align: center;
        font-family: Athletics;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    button {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
    }
`;

export const Logo = styled(KlashaLogo)``;

export const BrokenLinkImage = styled(Broken)``;

export const Agreement = styled.div`
    color: #606673;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.6px;
`;

export const PasswordErrorBoxBody = styled.div`
    border-top: 3px solid #dcdcdc;
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const PasswordErrorBoxWrapper = styled.div`
    display: flex;
    align-items: center;
    & p {
        margin-left: 5px;
        margin-bottom: unset;
    }
`;

export const PasswordErrorBox = styled.div`
    ${(props: { $initialState: boolean }) => {
        if (props.$initialState) {
            return css`
                & p {
                    color: #cccccc;
                }
                & ${PasswordErrorBoxWrapper} {
                    display: none;
                }
                & ${PasswordErrorBoxBody} {
                    border: unset;
                }
                & ${PasswordErrorBoxBody} > div {
                    margin-right: 10px;
                }
            `;
        } else {
            return css`
                & ${PasswordErrorBoxBody} > div {
                    margin-right: 10px;
                }
            `;
        }
    }}
`;

export const Title = styled.h1`
    color: #000;
    text-align: center;
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
`;

export const SubTitle = styled.p`
    color: #757575;
    text-align: center;
    font-family: Inter;
    line-height: 5px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

export const OTPWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const DropDown = styled(Dropdown)``;

export const MenuFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 10px;
    gap: 10px;
    span {
        color: var(--Shades-Black, #000);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .color {
        color: red;
    }
`;

export const PasswordContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const ToggleContainer = styled.div`
    padding: 12px 16px;
    border-bottom: 1px solid #f0f0f0;
`;

export const RoleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
     width: 34%;
     gap: 20px;
    margin: auto;
    margin-top: 40px;
    @media (max-width: 768px) {
        width: 100%;
    }

    h1 {
        font-family: Athletics;
        font-size: 28px;

        font-weight: 700;
        line-height: 34px;
        letter-spacing: -0.02em;
        text-align: center;
    }
`;

export const Module = styled.h2`
border-bottom: 1px solid #F0F0F0;
padding: 15px;
font-family: Athletics;
font-size: 24px;
font-weight: 700;
line-height: 32px;
letter-spacing: -0.02em;
text-align: left;
`;

export const ParamsTitle = styled.div`
padding: 20px;
border: 1px solid #F0F0F0;
text-align: right;
background:#F0F0F0;
display: flex;
justify-content: space-between;
span{
color: #5F5F5F;
text-align: right;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 15.6px;
}
`;

export const PermissionItem = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 20px;
padding: 15px;
border-bottom: 1.5px solid #F0F0F0;
`;


export const PermissionTitle = styled.span`
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
`;

export const RoleList = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 20px;
:hover{
    background: #FAFAFA;
}

`;

export const RoleDescription = styled.span`
font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: #4C4C4C;

`;

export const RoleTitle = styled.h1`
font-family: Inter;
font-size: 14px;
font-weight: 700;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color: #4C4C4C;
`;

export const FlexRole = styled.div`
display: flex;
align-items: center;
gap: 100px;
`

export const RoleHeading = styled.h2`
padding: 20px;
font-family: Inter;
font-size: 18px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0em;
text-align: left;
color: #464646;
margin:0;

`;

export const Dots = styled(CloseIcon)`
cursor: pointer;
`