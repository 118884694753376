import React from 'react';
import { Bottom, BottomItem, BottomLabel, BottomValue, MobileWrapper } from '../styles';


interface Props{
    transactionRef: string;
    source:string;
    transactionType: string;
    currency: string;
    amount: string;
    date: string;
}



const MobileCard = ({transactionRef, source, transactionType, currency, amount, date }: Props) => {
  return (
    <MobileWrapper>
        <Bottom>
            <BottomItem>
            <BottomLabel> Transaction ref</BottomLabel>
            <BottomValue>{transactionRef}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Source</BottomLabel>
            <BottomValue>{source}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Transaction type</BottomLabel>
            <BottomValue>{transactionType}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Currency</BottomLabel>
            <BottomValue>{currency}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Amount</BottomLabel>
            <BottomValue>{amount}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Dae</BottomLabel>
            <BottomValue>{date}</BottomValue>
            </BottomItem>

        </Bottom>


    </MobileWrapper>
  )
}

export default MobileCard;