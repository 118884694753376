import React, { useState, useContext } from "react";
import { BlockWrapper, BlockBox, variants, transition } from "./style";
import { Button } from "../.././../../components/common/Button";
import Loader from "../../../../components/common/Loader";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { blockCard, getBusinessKeys, getCard } from "../../CardsApi";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { setCard } from "../../CardSlice";

type Props = {
  setBlockCard: (arg: boolean) => void;
};

const BlockCard = ({ setBlockCard }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { card } = useAppSelector((state) => state.cards);
  const { businessId, userObj } = useAppSelector((state) => state.users);

  const activeCard = card ? card.find((c) => c.isActive === true) : {};
  const cardId = activeCard  ? activeCard.cardId : '';

  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const { onShowAlert: onSuccess } = useContext(SuccessAlertContext);

  const handleBlockCard = async () => {
    try {
      setIsLoading(true);
      const publicKey = await getBusinessKeys(businessId);
      localStorage.setItem("key", publicKey.publicKey);

      if (publicKey) {
        const blockedData = await blockCard(cardId);

        if (blockedData) {
          const cardData = await getCard(userObj.id);
          dispatch(setCard(cardData));
        }
      }

      setIsLoading(false);
      setBlockCard(false);
      onSuccess("Card blocked successfully");
      localStorage.removeItem("key");
    } catch (err) {
      localStorage.removeItem("key");
      const errorObj = err?.response?.data;
      let errorMessage = "";

      if (typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Failed. Try again";
      }

      setIsLoading(false);
      setBlockCard(false);
      onShowAlert(errorObj, errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <BlockWrapper>
      <Loader isLoading={isLoading} />

      <BlockBox
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={transition}
      >
        <h3>Block card</h3>
        <p>
          Do you want to confirm the blocking of this card? While the card is
          blocked, you will not be able to make any payments.
        </p>
        <div>
          <Button
            width="100%"
            label="No, cancel"
            theme="alternate"
            height="40px"
            fontSize="12px"
            onClick={() => setBlockCard(false)}
          />
          <Button
            width="100%"
            label="Yes, block"
            theme="primary"
            height="40px"
            fontSize="12px"
            onClick={handleBlockCard}
          />
        </div>
      </BlockBox>
    </BlockWrapper>
  );
};

export default BlockCard;
