import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Wrapper } from "./style"
import { Button } from '../../../common/Button';
import { ReactComponent as NoBankIcon } from "../../../../assets/icons/bank.svg"

const AddBankAccount = ({setAddBankAcct}:{setAddBankAcct: (arg:boolean) => void}) => {
    const navigate = useNavigate();

    const closeModal = () => {
        setAddBankAcct(false);
        navigate('/settings/accounts');
    };

  return (
    <Wrapper>
        <NoBankIcon/>
        
        <h1>Oops! No bank account added yet</h1>
        <p>Looks like you haven't added any bank accounts yet</p>
        <Button
            theme="secondary"
            label="Add a bank account"
            fontSize="12px"
            height="48px"
            onClick={closeModal}
        />
    </Wrapper>
  )
}

export default AddBankAccount