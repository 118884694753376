import React from "react";
import {
  EditButton,
  Title,
  Wrapper,
  Nationality,
  Top,
  Bottom,
  TitleCountry,
  DocumentUrl,
  DOB,
  Document,
} from "./style";
import { ReactComponent as EditIcon } from "../../../../../../assets/icons/edit-two.svg";
import { ReactComponent as DocumentIcon } from "../../../../../../assets/icons/document.svg";
import { Proprietor } from "../../../../types";
import { countyList } from "../../../../../../assets/countries2";

const getCountry = (cName: string) => {
  const country = countyList.find((co) => co.name === cName);
  return country ? country.code : "--";
};

const ProprietorCard = ({
  name,
  proprietorObj,
  onClickCard,
  nationality,
  dob,
}: {
  name: string;
  nationality: string;
  dob: string;
  documentUrl?: string;
  proprietorObj: any;
  onClickCard: (prop: Proprietor) => void;
}) => {
  const onClick = () => {
    onClickCard(proprietorObj);
  };

  return (
    <Wrapper>
      <Top>
        <TitleCountry>
          <Title>{name}</Title>
          <EditButton onClick={onClick} type="button">
            <EditIcon />
          </EditButton>
        </TitleCountry>
        <DOB>DOB: {dob}</DOB>
      </Top>
      <Bottom>
        <Document>
          <DocumentIcon />
          <DocumentUrl>Document</DocumentUrl>
        </Document>
        <Nationality>{getCountry(nationality)}</Nationality>
      </Bottom>
    </Wrapper>
  );
};

export default ProprietorCard;
