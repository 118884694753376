import CurrencyInput from "react-currency-input-field";
import { Input, Select  } from "antd";
import styled from "styled-components";


export const Header = styled.p`
  font-family: "Athletics";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px; 
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 16px;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TextArea = styled(Input.TextArea)`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  &:hover {
    border: 1px solid #a3a3a3;
  }

  &:focus {
    border: 1px solid #000000;
    box-shadow: none;
  }
`;

export const CustomInput = styled(Input)`
  width: 100%;
  height: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  &:hover {
    border: 1px solid #a3a3a3;
  }

  &:focus {
    border: 1px solid #000000;
    box-shadow: none;
  }
`;

export const Amount = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  position: relative;
`;

export const Currency = styled(CurrencyInput)`
  width: ${(props: {
    $width?: string;
    $height?: string;
    $showBorder?: boolean;
    $showArrow?: boolean;
    $showSelector?: boolean;
  }) => props.$width || "100%"};
  height: ${(props) => props.$height || "40px"};
  border: ${(props) => (!props.$showBorder ? "1px solid #f0f0f0" : "none")};
  border-radius: 8px;
  padding: 14px;

  :hover {
    border-color: #a3a3a3 !important;
  }

  :focus {
    border-color: black !important;
  }
`;

export const CustomSelect = styled(Select)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  div[class="ant-select-selector"] {
    height: 40px !important;
    border: 0px !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }
  span[class="ant-select-selection-item"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16px !important;
    color: #000000 !important;
    }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px; 
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
`;

export const ButtonsDiv = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f0f0f0;
  padding-top: 15px;

  button {
    &:nth-child(1){
      width: 17%;
    }

    &:nth-child(2) {
      width: 80%;
    }
  }
`;

export const Limit = styled.div`
  position: relative;

  .tooltip {
    position: absolute;
    top: 12px;
    right: 3%;
    cursor: pointer;
  }
`;

export const CustomSelectTwo = styled(CustomSelect)`
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  
  &:hover {
    border: 1px solid #a3a3a3;
  }
  `;

export const IntervalBox = styled.div`
  position: relative;

  p {
    position: absolute;
    margin: 0;
    top: 10px;
    left: 15px;
    color: #605d5d;
  }
`;