import React, { useState } from "react";
import {
    Title,
    Balance,
    PayoutBtnContainer,
} from "../styles";
import { Button } from "../../../components/common/Button";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as DropDown } from "../../../assets/icons/drop-down.svg";

import { Tooltip } from "antd";
import {
    Flex,
    TooltipWrapper,
} from "../../Customers/styles";
import { useNavigate } from "react-router-dom";
import {
    DropdownMenuItem,
    UserBox,
} from "../../../components/AppShell/components/Header/style";
import { getBusinessText } from "../../../utils/UpdateBusinessType";
import { useAppSelector } from "../../../app/hooks";

interface Props {
    title: string;
    balance: string;
    toolTip?: React.ReactElement;
    onClick?: any;
    isSettlement?: boolean;
    disabled?: boolean;
    tooltipText: string;
}

const AccountBalance = ({
    title,
    balance,
    isSettlement,
    disabled,
    tooltipText,
}: Props) => {
    const [dropdownOpen, setdDropdownOpen] =
        useState(false);
    const navigate = useNavigate();
    const { business } = useAppSelector(
        (state) => state.settings,
    )
    const businessType = getBusinessText(
        business?.organizationType,
    );

    return (
        <>
            <Flex>
                <TooltipWrapper>
                    <Title>{title}</Title>
                    <Tooltip
                        overlayClassName="input-tooltip"
                        placement="right"
                        title={tooltipText}
                        trigger={["hover", "click"]}
                        className="tool-tip"
                    >
                        <InfoIcon />
                    </Tooltip>
                </TooltipWrapper>

                {isSettlement && (
                    <Button
                        data-testid="request-payout-button"
                        theme="primary"
                        width="150px"
                        height="40px"
                        disabled={disabled}
                        onClick={() =>
                            setdDropdownOpen(true)
                        }
                    >
                        <PayoutBtnContainer>
                            <span>Request payout</span>
                            <DropDown />
                        </PayoutBtnContainer>

                        <UserBox isOpen={dropdownOpen}>
                            <div className="dropdown dropdown-space">
                               {businessType === 'Registered Business' && (
                            <DropdownMenuItem onClick={() => navigate("/settlements/fund/settlement")}>
                            <span>
                            To my wallet
                            </span>
                            </DropdownMenuItem>
                                
                                )}

                                <DropdownMenuItem onClick={() => navigate("/settlements/fund/payout")}>
                                    <span>
                                        To bank account
                                    </span>
                                </DropdownMenuItem>
                            </div>
                        </UserBox>
                    </Button>
                )}
            </Flex>
            <Balance>{balance}</Balance>
            {dropdownOpen && <div className="overlay" onClick={() => setdDropdownOpen(false)}/>}

        </>
    );
};

export default AccountBalance;