import React, { useContext, useEffect, useState } from "react";
import { InputBox, Wrapper, DualInputs, Options, Error } from "./style";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../../common/Input";
import CustomSelect from "../../../../common/CustomSelect";
import { BusinessType } from "../../../../../pages/Settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  getKlashaBusinessCategories,
  getKlashaBusinessTypes,
} from "../../../../../pages/Compliance/complianceSlice";
import { sortCurrencyObjectByNameFunction } from "../../../../../utils/format";
import { Button } from "../../../../common/Button";
import { UpdateMerchantDetails } from "../../../../../pages/Settings/settingsAPI";
import Loader from "../../../../common/Loader";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { getMerchantBusiness } from "../../../../../pages/Dashboard/dashboardAPI";

type FormInputs = {
  businessType: string | number;
  businessCategory: string | number;
  businessName: string;
  businessEmail: string;
  phoneNumber: string;
  country: string;
  city: string;
  streetAddress: string;
  website: string;
  zipCode: string;
};
const BusinessForm = ({
  initialBusiness,
}: {
  initialBusiness: BusinessType | null;
}) => {
  const dispatch = useAppDispatch();
  const { businessId } = useAppSelector((state) => state.users);
  const [loading, setLoading] = useState(false)
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const successAlertContext = useContext(
    SuccessAlertContext,
);
const { onShowAlert: onSuccess } =
    successAlertContext || {};
    const { onShowAlert} = useContext(
        NetworkErrorAlertContext,
      );


  const {
    handleSubmit,
    control,
    formState: {errors, isDirty },
    watch
  } = useForm<FormInputs>({
    defaultValues: {
      businessType: initialBusiness?.businessType,
      businessCategory: initialBusiness?.businessCategory,
      businessName: initialBusiness?.name,
      businessEmail: initialBusiness?.email,
      phoneNumber: initialBusiness?.phone,
      country: initialBusiness?.country,
      city: initialBusiness?.city,
      streetAddress: initialBusiness?.address,
      website: initialBusiness?.website,
      zipCode: initialBusiness?.zipCode,
    },
  });

  const [busTypes, setBusTypes] = useState([]);
  const [busCategories, setBusCategories] = useState([]);
  const watchedFields = watch();

  

  const {
    businessTypes,
    businessCategories
  } = useAppSelector((state) => state.compliance);

  const handleGetBusinessData = async (id) => {
    await getMerchantBusiness(id)


}

  useEffect(() => {
    dispatch(getKlashaBusinessTypes());
    dispatch(getKlashaBusinessCategories());
  }, []);

  const UpdateMerchantBusinessData = () => {
    const data = {
        email: watchedFields.businessEmail,
        website: watchedFields.website
    };

    setLoading(true);

    UpdateMerchantDetails(data, businessId)
        .then(() => {
            setLoading(false);
            handleGetBusinessData(businessId);
            onSuccess("Business details updated");
        })
        .catch((error) => {
            setLoading(false);
            onShowAlert("", error?.response?.data.message);
        });
};


  useEffect(() => {
    if (businessCategories?.length) {
      const options = businessCategories.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      // sort options list alphabetically and move Other/Others to the end of the list
      const sortedOptions = options.sort(sortCurrencyObjectByNameFunction);
      const othersElementIndex = sortedOptions.findIndex((el) =>
        ["Others", "Other"].includes(el.label)
      );
      if (othersElementIndex !== -1) {
        sortedOptions.push(sortedOptions.splice(othersElementIndex, 1)[0]);
      }
      setBusCategories(sortedOptions);
    }
  }, [businessCategories]);

  useEffect(() => {
    if (businessTypes?.length) {
      const options = businessTypes.map((type) => ({
        label: type.name,
        value: type.id,
      }));
      setBusTypes(options);
    }
  }, [businessTypes]);

  return (
    <Wrapper>
        <Loader isLoading={loading}/>
      <Options>
        <InputBox>
          <Controller
            name="businessName"
            control={control}
            render={({ field }) => (
              <Input
              disableLabel
                type="text"
                label="Business name"
                field={field}
                labelColor="#FFFFFF"
                disabled
              />
            )}
          />
        </InputBox>

        <InputBox>
          <Controller
            name="streetAddress"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="Business address"
                field={field}
                labelColor="#FFFFFF"
                disabled
                disableLabel
              />
            )}
          />
        </InputBox>

       
         <InputBox>
          <Controller
            name="businessType"
            control={control}
            render={({ field }) => (
              <CustomSelect
                options={busTypes}
                $height="48px"
                $width="100%"
                field={field}
                placeholder="Business type"
                disabled
                disableLabel
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="businessCategory"
            control={control}
            render={({ field }) => (
              <CustomSelect
                options={busCategories}
                $height="48px"
                $width="100%"
                field={field}
                placeholder="Business category"
                disabled
                disableLabel
              />
            )}
          />
        </InputBox>
        <DualInputs>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="City"
                field={field}
                labelColor="#FFFFFF"
                disabled
                disableLabel

              />
            )}
          />
          <Controller
            name="zipCode"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="Post code"
                field={field}
                labelColor="#FFFFFF"
                disabled
                disableLabel

              />
            )}
          />
        </DualInputs>
      </Options>
      

        <InputBox>
          <Controller
            name="businessEmail"
            control={control}
            rules={{
                required: "Email is required",
                pattern: {
                  value: emailRegex,
                  message: 'Please enter a valid email address.',
                },
              }}
            render={({ field }) => (
              <Input
                type="text"
                label="Email address"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
                
              />
            )}
          />
          {errors.businessEmail && (
                <Error className="error">
                 {
                    errors.businessEmail.message}
                    </Error>)}
        </InputBox>

        <InputBox>
          <Controller
            name="website"
            control={control}
            rules={{
                required: "Website is required",
                validate: (value) => {
                    const urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}(\/.*)?$/i;
                    if (urlPattern.test(value)) {
                        return true;
                    }
                    return "Enter a valid URL";
                },
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Website"
                field={field}
                disableLabel
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.website && (
                                <Error className="error">
                                    {
                                        errors.website
                                            .message
                                    }
                                </Error>
                            )}
        </InputBox>

        <Button label="Save" theme="primary" width="100px" height="40px" onClick={handleSubmit(UpdateMerchantBusinessData)}
                            disabled={!isDirty || loading}
            />
    </Wrapper>
  );
};

export default BusinessForm;
