import api from "../../app/api";

export const getSalesInRange = async (businessId, todaysDate) => {
  const { data } = await api.get(
    `merchant/sales/btwdate?businessId=${businessId}&date=2020-12-01&second=${todaysDate}&status=successful`
  );
  return data;
};

export const getSalesByMethod = async (businessId) => {
  const { data } = await api.get(`merchant/sales/business/total/${businessId}`);
  return data;
};

export const getLastThirtyDaysSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/last/thirty/days/${businessId}/0`
  );
  return data;
};

export const getLastSevenDaysSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/last/seven/days/${businessId}/0`
  );
  return data;
};

export const getCurrentDayTotalSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/daily/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentWeekTotalSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/weekly/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentMonthTotalSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/monthly/sum/${businessId}/0`
  );
  return data;
};

export const getPreviousMonthTotalSales = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/previous/month/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentWeekSalesData = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/weekly/chart/${businessId}/0`
  );
  return data;
};

export const getCurrentMonthSalesData = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/monthly/chart/${businessId}/0`
  );
  return data;
};

export const getPreviousMonthSalesData = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/previous/month/chart/${businessId}/0`
  );
  return data;
};

export const getAllTimeSalesData = async (businessId) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/all/time/sum/${businessId}/0`
  );
  return data;
};
