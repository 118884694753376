import styled from "styled-components";
import { Steps, Input } from "antd";
import { InputNumber } from "antd";
import { InputNumberProps } from 'antd/lib/input-number';

interface Props {
    selected: boolean;
}

export const Wrapper = styled.div`
    width: 50%;
    margin: auto;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StepContainer = styled(Steps)`
    .ant-steps-item-icon {
        display: none;
    }
    .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: #ef2c5a;
}

@media (max-width: 768px) {
    display: none;
}
`;

export const StepContent = styled.div`
    margin: auto;
    width: 70%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const PayoutWrapper = styled.div`
    padding-top: 30px;
    @media (max-width: 768px) {
        width: 100%;
    }
   
`;

export const Heading = styled.h1`
    color: #000;
    text-align: center;
    font-family: Athletics;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 121.429% */
    letter-spacing: -0.56px;
`;

export const Paragraph = styled.p`
    color: #767676;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const InputBox = styled.div`
    display: block;
    width: 100%;
    margin-bottom: 34px;

    & > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 130%;
        margin-top: 4px;
        color: var(--primary-pink);
    }

    & div[class~="ant-input-number-affix-wrapper"] {
        border: 1px solid #a3a3a3 !important;
        border-radius: 8px;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        box-shadow: none !important;
        font-family: "Inter";

        & > span {
            padding-right: 8px;
            border-right: 1px solid #a3a3a3;
        }

        & > div[class="ant-input-number"] {
            padding-left: 8px;
        }
    }

    & div[class="ant-input-number-handler-wrap"] {
        display: none;
    }

    .error-top {
        padding-top: 12px;
    }
`;

export const FeesWrapper = styled.div`
    display: flex;
    padding: 10px 14px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: #f9f9f9;
`;

export const BeneficiaryBoxWrapper = styled.div`
    display: flex;
    padding: 30px 5px;
    flex-direction: column;

    span {
        color: var(--shades-black-500, #767676);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.6px;
    }

    h1 {
        color: var(--shades-black, #000);
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        padding-top: 10px;
    }
`;
export const Flex = styled.div`
    display: flex;
    gap: 15px;
`;

export const InputWrapper = styled.div`
    padding-top: 20px;
`;

export const BeneficiaryWrapper = styled.div`
    margin-top: 20px;
`;

export const Back = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const BeneficiaryCardWrapper = styled.div<Props>`
border-radius: 8px;
border: ${({ selected }) =>
    selected ? "1px solid #000" : "1px solid #E9E9E9"};
padding: 14px;
cursor: pointer;
display: flex;
flex-direction: column;
gap: 20px;
}

h1{
color: var(--shades-black, #000);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
};

p{
color: var(--shades-black-600, #5F5F5F);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 15.6px; /* 130% */

}
.sub-text{
color: var(--shades-black, #000);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
};
input{
    &.active{
        background: red;
    }
}
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align items in the center */
`;

export const GridBox = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    
`;

export const GridContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    height: 40vh;

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
`;

export const AddBeneficiary = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--shades-black-50, #e9e9e9);
    padding-top: 40px;
    .add-beneficiary {
        color: var(--button-text-link-hover, #ef2c5a);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
        cursor: pointer;
    }
    p {
        color: var(--shades-black, #000);
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        font-size: 12px;
    }
`;

export const Search = styled(Input)`
padding:7px;
border-radius: 8px;
border: 1px solid var(--border-input-empty, #F0F0F0);
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 6px;
`;

interface StyledInputNumberProps extends InputNumberProps {
    width?: string;
  }



export const CurrencyForm = styled(InputNumber)<StyledInputNumberProps>`
width: 100%;
height: 38px;
padding: 5px;
width: ${(props) => (props.width ? '100%' : 'auto')};

.ant-input-number-wrapper{
border-radius: 8px !important;
border: 1px solid var(--border-input-empty, #F0F0F0);
};

.ant-input-number-focused{
box-shadow: none;
}


.ant-input-number-group > .ant-input-number:first-child{
border-radius: 8px;
border: 1px solid var(--border-input-empty, #F0F0F0);
border-right: none;

};

.ant-input-number-group-addon:last-child{
border: 1px solid var(--border-input-empty, #F0F0F0);
background-color: #fff;
border-left: none;
}
`;

export const CurrencyWrapper = styled.div`
display: flex;
align-items: center;
`;

export const CurrencyFlag = styled.span`
margin-right: 8px;
`

export const CurrencyCode = styled.span`
color: var(--shades-black, #000);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */
`;

export const SelectedDropdownOption = styled.div`
  display: flex;
  align-items: center;

  & li {
    margin-right: 10px;
  }
`;

export const DetailsSectionHeader = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #dcdcdc;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailsLabel = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #ADADAD;
`;


export const DetailsValue = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: #000000;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTitle = styled.p`
  font-family: Athletics;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: transparent;
  border: none;
  & > span {
    margin-right: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--primary-pink);
  }
`;

export const DetailsBox = styled.div`
  padding: 20px 40px;
  font-family: Athletics;
  border: 1px solid #969696;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const DetailsContent = styled.div``