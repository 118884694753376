import React, { useEffect } from "react";
import {
    AboutContent,
    AboutDescription,
    AboutKeys,
    AboutTitle,
    KeyList,
    Wrapper,
} from "./style";
import Key from "./components/Key";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../app/hooks";
import { merchantBusinessKeys } from "../../../pages/Settings/settingsSlice";
import Loader from "../../common/Loader";
import { ReactComponent as KeyFileIcon } from "../../../assets/icons/bi_filetype-key.svg";
import UpgradeButton from "../../common/UpgradeBusiness/UpgradeButton";
import { getBusinessText } from "../../../utils/UpdateBusinessType";
import WebhookUrl from "./components/WebhookUrl";

const Keys = () => {
    const dispatch = useAppDispatch();

    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { status, keys } = useAppSelector(
        (state) => state.settings,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
   

    useEffect(() => {
        if (businessId) {
            dispatch(merchantBusinessKeys(businessId));
        }
    }, [businessId, dispatch]);
    if (status === "loading") {
        return <Loader isLoading />;
    }
    const businessType = getBusinessText(
        business?.organizationType,
    );
    
        
    return (
        <>
            {businessType === "Starter Business" && (
                <UpgradeButton />
            )}
            <Wrapper>
                {businessType !== "Starter Business" && (
                    <KeyList>
                        <Key
                            label="Merchant private key"
                            value={keys?.data?.secretKey}
                        />
                        <Key
                            label="Merchant public key"
                            value={keys?.data?.publicKey}
                        />
                        <Key
                            label="Encryption key"
                            value={keys?.data?.encryptionKey}
                        />

                        <Key
                            label="New encryption key"
                            value={keys?.data?.newEncryptionKey}
                        />

<Key
                            label="Merchant Business ID"
                            value={businessId}
                        />

                        <WebhookUrl />


                    </KeyList>
                )}
                <AboutKeys>
                    <AboutContent>
                        <KeyFileIcon />
                        <AboutTitle>API keys</AboutTitle>
                        <AboutDescription>
                            The API key, which includes your
                            public and private keys, is
                            unique to your merchant
                            dashboard and is used to
                            authenticate requests for
                            billings and payments linked to
                            your business. Your API keys are
                            needed when integrating Klasha
                            to receive payments as it links
                            transactions made to your
                            dashboard. Remember to keep your
                            API keys confidential and safe.
                        </AboutDescription>
                    </AboutContent>
                </AboutKeys>
            </Wrapper>
        </>
    );
};

export default Keys;
