import CryptoJS from "crypto-js";


export const encryptData = (data) => {
    const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        'AanwanQJgwof2ZR5vUnhHEt2FpMMDJ6lpClGuRWzEpc=',
    ).toString();
    return encrypted;
};

