import api from "../../app/api";

export const getTransactionsFiltered = async (data) => {
  const response = await api.post(`nucleus/tnx/paginated/filter`, data);
  return response.data;
};

export const getTransactionsAll = async (data) => {
  const response = await api.post(`nucleus/tnx/paginated/filter`, data);
  return response.data;
};
