
export const statusOptions = [
  { value: "0", label: "Successful" },
  { value: "1", label: "Pending" },
  { value: "2", label: "Failed" },
  { value: "3", label: "Cancelled" },
  { value: "4", label: "Reversed" },
  { value: "9", label: "All" },
];

const convertToCSV = (data:{}[]) => {
  const headers = Object.keys(data[0]).join(",");
  const rows = data.map((item:{}) => Object.values(item).join(","));
  return [headers, ...rows].join("\n");
};

export const exportCSV = (data) => {
  if (!data.length) {
    return;
  } else {
    const csvData = data.map((link) => ({
      key: link.no,
      title: link.title,
      amount: link.amount,
      currency: link.currency,
      link: link.link.url,
      date: link.date,
      type: link.type,
    }));

    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(csvData);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "Payment-links.csv");
    document.body.appendChild(link);
    link.click();
  }
};
