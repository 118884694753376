import React, { useState } from "react";
import { Check, CheckFlex, CopyIcon, Flex, Heading, Paragraph, Title, TransactionAmount, TransactionWrapper } from "../styles";
import TransactionStatus from "../../../components/transactions/components/TransctionStatus";

interface Props {
    reference?: string;
    amount?: string;
    source?: string;
    txRef?:string;
    bank?: string;
    accountName?: string;
    accountNumber?: string;
    rate?: string;
    date?: string;
    merchant_amount?:string
    transaction_id?:string
    status?:string
    transactionRef?:string
}

const Details = ({
    reference,
    amount,
    date,
    txRef,
    merchant_amount,
    transaction_id,
    transactionRef,
    status
}: Props) => {
    const [copy, setCopy] = useState<string | null>(null);
    const [copyRef, setCopyRef] = useState<string | null>(null);


    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

    const onCopyToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopyRef(data);
        setTimeout(() => {
            setCopyRef(null);
        }, 3000);
    };

    
    return (
        <>
        <Flex>
        <Title>{amount}</Title>
         <TransactionStatus text={status} />
        </Flex>
        <TransactionWrapper>
            <TransactionAmount>{merchant_amount}</TransactionAmount>
            <Flex>
                <Paragraph>Refund ID</Paragraph>
                <CheckFlex>
                    {copy ? <Check /> : <CopyIcon onClick={() =>
                         onCopyLinkToClipboard(transaction_id)} data-testid="copy-icon" />}

                    <Heading>{reference}</Heading>
                </CheckFlex>

            </Flex>

            <Flex>
                <Paragraph>Transaction amount</Paragraph>
                <Heading>{amount}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Transaction Reference</Paragraph>
                <CheckFlex>
                    {copyRef ? <Check /> : <CopyIcon onClick={() =>
                         onCopyToClipboard(transactionRef)} />}

                    <Heading>{txRef}</Heading>
                    </CheckFlex>
            </Flex>

            <Flex>
                <Paragraph>Date</Paragraph>
                <Heading>{date}</Heading>
            </Flex>
        </TransactionWrapper></>
    );
};

export default Details;
