import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { allCollectionsColumn, exportToCSV, exportToXLS } from "./utils";
import dayjs from "dayjs";
import CustomTable from "../../components/common/CustomTable";
import { getCurrencySymbolFromCode } from "../../utils/format";
import { getCollectionTransactions } from "./settlementsAPI";
import {
    EmptyWrapper,
    EmptyWrapperTitle,
    TransactionBreakDownHeader,
} from "./style";
import { useAppSelector } from "../../app/hooks";
import Loader from "../../components/common/Loader";
import { StyledTransactionsTable } from "../Wallets/components/TransactionsTable/style";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import CustomerDetails from "./CustomerDetails";
import TableTools from "../../components/common/TableTools";
import { formatLongString } from "../../utils/formatString";
import { formatCurrencyAmount } from "../../utils/currency";
import TransactionDetails from "./TransactionDetails";

const EmptyBalanceDescription = () => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>No Data</EmptyWrapperTitle>
        </EmptyWrapper>
    );
};

interface DateProps {
    startDate: Date;
    endDate: Date;
}

const CollectionTransaction = () => {
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const [transactionData, setTransactionData] = useState(
        [],
    );
    const [totalPage, setTotalPage] = useState<any>([]);
    const [date, setDate] = useState<DateProps>();
    const [loading, setLoading] = useState(false);
    const { source_currency } = useParams();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [exportCollectionData, setExportCollectionData] =
        useState(null);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [transactionDetail, setTransactionDetail] =
        useState(null);

    const currencyCode =
        getCurrencySymbolFromCode(source_currency);

    const startDate =
        date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
    const endDate =
        date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

    const onPageChange = () => {
        setPageNumber((prev) => prev + 1);
    };

    const handleReset = async () => {
        setDate(null);
    };

    const filter = () => {
        getTransactions();
    };

    const getTransactions = async () => {
        const data = {
            business: businessId,
            sourceCurrency: source_currency,
            pageNumber: pageNumber,
            startDay: startDate,
            endDay: endDate,
            pageSize: 10,
        };
        setTransactionData([]);

        if(!businessId){
            return null
        }
        else{
            setLoading(true);
            try {
                await getCollectionTransactions(data).then(
                    (data) => {
                        setLoading(false);
                        setTotalPage(data);
                        const collectionBreakDown =
                            data.transactionSplit;
                        const tableData =
                            collectionBreakDown.map((item) => ({
                                ref: formatLongString(
                                    item?.transaction.tnxRef,
                                    20,
                                ),
                                merchant_amount:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .sourceCurrency,
                                        item.transaction
                                            .sourceAmount,
                                    ) || "--",
                                payer_amount:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .currency,
                                        Number(item.transaction
                                            .chargedAmount / 100),
                                    ) || "--",
                                currency:
                                    item.transaction
                                        .settledCurrency
                                        || "--",
                                source:
                                    currencyCode +
                                    " " +
                                    (
                                        item.transaction
                                            .amount / 100 ||
                                        "0.00"
                                    ).toLocaleString(),
                                amount_less:
                                    formatCurrencyAmount(item.foreignCurrency,
                                    item.foreignAmount),
                                paymentType:
                                    item.transaction
                                        .paymentType,
                                settlement_amount:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .settledCurrency                                        ,
                                        item.transaction
                                            .settledAmount,
                                    ) || "-",
                                amount:
                                    currencyCode +
                                    " " +
                                    Number(
                                        item.transaction
                                            .sourceAmount,
                                    ).toLocaleString(),
                                date: dayjs(
                                    item.transaction.createdAt,
                                ).format("DD.MM.YYYY HH:mm"),
                                settled:
                                item?.settledCurrency +
                                    " " +
                                    (
                                        item.transaction
                                            .settledAmount ||
                                        "0.00"
                                    ).toLocaleString(),
                                local:
                                    currencyCode +
                                    " " +
                                    (
                                        item.amount / 100
                                    ).toLocaleString(),
                                less:
                                    item.foreignCurrency +
                                    " " +
                                    item.foreignAmount,
                                reference:
                                    item.transaction.tnxRef ||
                                    "No Ref",
                                rate:
                                    item.transaction
                                        .settlementRate ||
                                    "0.00",
                                customer:
                                    item.transaction.customer,
                                transaction_id:
                                    item?.transaction.tnxRef,
                            }));
                        setTransactionData(tableData);
                        setExportCollectionData(data);
                    },
                );
            } catch (error) {
                setLoading(false);
            }
        
        }
    };

    useEffect(() => {
        getTransactions();
    }, [pageNumber]);

    const handleRowClick = (row: any) => {
        setTransactionDetail(row);
        setOpenDrawer(true);
    };

    

    

    return (
        <div>
            <StyledTransactionsTable>
                <Loader isLoading={loading} />
                <TransactionBreakDownHeader>
                    <h3>{`${source_currency} collections`}</h3>

                   {transactionData?.length > 0 && (
                    <div className="filter">
                    <TableTools
                        placeHolder="Search"
                        hideSearch
                        onDateChange={(date) =>
                            setDate(date)
                        }
                        onFilterButtonClick={filter}
                        onResetButtonClick={handleReset}
                        onCSVButtonClick={() => exportToCSV(exportCollectionData, currencyCode)}
                        onXLSButtonClick={() => exportToXLS(exportCollectionData, currencyCode )}
                    />
                </div>
                    
                    )}
                </TransactionBreakDownHeader>

                <CustomTable
                    columns={allCollectionsColumn}
                    extraEmptyChildren={
                        <EmptyBalanceDescription />
                    }
                    dataSource={transactionData}
                    onPageChange={onPageChange}
                    totalItemCount={totalPage.totalPages}
                    loading={loading}
                    handleRowClick={handleRowClick}
                />

                <DrawerContainer
                    title="Transaction details"
                    onClose={() => setOpenDrawer(false)}
                    open={openDrawer}
                    placement={"right"}
                >
                    <TransactionDetails
                        merchant_amount={
                            transactionDetail?.merchant_amount
                        }
                        reference={transactionDetail?.ref}
                        merchantAmount={
                            transactionDetail?.merchant_amount
                        }
                        payerAmount={
                            transactionDetail?.payer_amount
                        }
                        amountLessFess={
                            transactionDetail?.amount_less ||
                            "-"
                        }
                        currency={
                            transactionDetail?.currency
                        }
                        settledAmount={
                            transactionDetail?.settlement_amount ||
                            "-"
                        }
                        rate={transactionDetail?.rate}
                        date={transactionDetail?.date}
                        transaction_id={
                            transactionDetail?.transaction_id
                        }
                    />

                    <CustomerDetails
                        email={
                            transactionDetail?.customer
                                ?.email
                        }
                        phone={
                            transactionDetail?.customer
                                ?.phone
                        }
                        method={
                            transactionDetail?.paymentType ||
                            "-"
                        }
                    />
                </DrawerContainer>
            </StyledTransactionsTable>
        </div>
    );
};

export default CollectionTransaction;
