import React from "react";

import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedin.svg";
import { ReactComponent as PaymentLinkIcon } from "../assets/payment-link.svg";
import { ReactComponent as TelegramIcon } from "../assets/telegram.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as WhatsappIcon } from "../assets/whatsapp.svg";
import { ReactComponent as YoutubeIcon } from "../assets/youtube.svg";

const keyToIcon = {
  twitter: <TwitterIcon />,
  whatsapp: <WhatsappIcon />,
  facebook: <FacebookIcon />,
  instagram: <InstagramIcon />,
  linkedin: <LinkedInIcon />,
  website: <PaymentLinkIcon />,
  support: <PaymentLinkIcon />,
  email: <TelegramIcon />,
  youtube: <YoutubeIcon />,
};

export default keyToIcon;
