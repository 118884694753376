import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0px;
    h1 {
        color: #000;
        text-align: center;
        font-family: Athletics;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.48px;
    }

    p {
        color: #000;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
    }

    button {
        width: 10%;
        height: 40px;
    }

    @media (max-width: 768px) {
        button {
            width: 50%;
        }
    }
`;
