
import styled from 'styled-components';


export const Wrapper = styled.div`
text-align: center;
margin-top: 20px;
font-family: "Inter";
`



export const Title = styled.h1``;

export const Subtitle = styled.p`
color: #8F8F8F;
`