import React from "react";
import { AuthWrapper } from "../../components/AuthWrapper";
import LoginForm from "./login";

const Login = () => {
  return (
    <AuthWrapper>
      <LoginForm />
    </AuthWrapper>
  );
};

export default Login;
