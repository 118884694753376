import axios from "axios";

// Axios Instance without an interceptor.
export const defaultApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
  },
});

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
  },
});

let isRedirecting = false;

api.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (!isRedirecting && window.location.pathname !== "/login") {
        isRedirecting = true;
        localStorage.clear();
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default api;
