import styled from "styled-components";
import { Steps } from "antd";

export const MainStep = styled(Steps)`
  .ant-steps-item:last-child {
    display: none !important ;
  }

  &.ant-steps {
    display: flex;
    gap: 1rem;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    overflow: hidden;

    @media (max-width: 768px) {
      gap: 0.5rem;
    }
  }

  .ant-steps-item {
    flex: 1;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    background-color: #f0f0f0;
  }

  .ant-steps-item-container,
  .ant-steps-item-icon,
  .ant-steps-item-content,
  .ant-steps-item-tail {
    display: none !important;
  }

  .ant-steps-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }

  .ant-steps-item-finish::before {
    transform: scaleX(1);
  }

  .ant-steps-item + .ant-steps-item {
    margin-left: 0 !important;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: 32px;
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
    padding-left: 32px;
  }
`;

export const NavButton = styled.div`
  @media (min-width: 768px) {
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  @media (min-width: 768px) {
    width: min(100%, 650px);
  }
`;

export const StepsWrapper = styled.div`
  padding: 18px 8px 24px;
  background-color: var(--orange-background);
  width: 100%;

  @media (min-width: 768px) {
    background-color: #ffffff;
    margin-left: 0;
    margin-top: 0;
    padding: 0;
  }

  & > div[class~="ant-steps-navigation"] {
    padding-top: 0;
  }
`;

export const Content = styled.div`
  margin-top: 48px;
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 16px;
  justify-content: center;
`;

export const StepHeader = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  & > p {
    margin-bottom: 0;
  }
`;

export const StepTitle = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-right: 16px;
  @media (min-width: 576px) {
    font-size: 24px;
  }
`;

export const StepNumber = styled.p`
  color: rgba(0, 0, 0, 0.2);
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-feature-settings: "pnum" on, "lnum" on;
`;
