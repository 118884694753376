import React from 'react';
import { EmptyHeading, EmptyParagraph } from '../styles';
import { Button } from '../../../components/common/Button';

interface Props{
    title: string;
    paragraph: string;
    subtitle?: string;
    handleVirtualAccount?: () => void;
    hasVirtual?: "default" | "virtual"
}

const EmptyVirtualAccount = ({title, paragraph, subtitle, handleVirtualAccount, hasVirtual = "default"}: Props ) => {
  return (
    <div>
        <EmptyHeading>{title}</EmptyHeading>
        <EmptyParagraph>{paragraph}</EmptyParagraph>
        <EmptyParagraph>{subtitle}</EmptyParagraph>
        {hasVirtual === 'virtual' && (
         <Button theme='secondary' label='Generate virtual account' width='230px' onClick={handleVirtualAccount}  />
            )}



    </div>
  )
}

export default EmptyVirtualAccount