import React from 'react';
import { ReactComponent as EmptyPerson } from '../../../assets/icons/empty-person.svg';
import {Wrapper, Title, Subtitle} from './style'

interface Props {
    title: string;
    subtitle: string;
}

const EmptyBeneficiary = ({ title, subtitle }: Props) => {
    return (
        <Wrapper>
            <EmptyPerson />
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Wrapper>
    )
}

export default EmptyBeneficiary;
