import React, { ChangeEvent, useState } from "react";
import ToggleButton from "../../common/ToggleButton/ToggleButton";
import { Popover } from "antd";
import {
  FilterBox,
  FilterBoxFooter,
  FilterBoxItem,
  FilterBoxItemTitle,
  FilterButton,
  FilterFooterApplyButton,
  FilterFooterResetButton,
  RightButtons,
  Top,
  ToggleButtonContainer,
  ToggleButtonContainerMobile,
  Bottom,
} from "./style";
import "./style.css";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { statusOptions } from "../utils";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import CustomSelectTwo from "../../common/CustomSelect/CustomSelectTwo";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import Search from "../../common/Search";
import Export from "../../common/Export";
import { Button } from "../../common/Button";
import ResponsivePopover from "../../../pages/Wallets/components/TransactionsTable/components/Tools/ResponsivePopover";
import ExportByFilter from "../../common/ExportByFilter";

const FilterContent = ({
  onStatusChange,
  onDateChange,
  onFilterButtonClick,
  onResetButtonClick,
  toggleType
}: {
  onFilterButtonClick: () => void;
  onResetButtonClick: () => void;
  onStatusChange: (val) => void;
  onDateChange: (val, val2) => void;
  toggleType: string


}) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  
  const onResetClick = () => {
    onResetButtonClick();
    setSelectedMethod(null);
    setSelectedDate([]);
  };
  const onMethodSelectChange = (value) => {
    onStatusChange(value);
    setSelectedMethod(value);
  };
  const onDateRangeChange = (dates, dateString) => {
    onDateChange(dates, dateString);
    setSelectedDate(dates);
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };
  return (
    <FilterBox>
      <FilterBoxItem>
        {toggleType === 'Payout' && (
            <div>
            <FilterBoxItemTitle>Status</FilterBoxItemTitle>
            <CustomSelectTwo
              options={statusOptions}
              value={selectedMethod}
              onChange={onMethodSelectChange}
            />
            </div>)}
      </FilterBoxItem>
      <FilterBoxItem>
        <FilterBoxItemTitle>Date range</FilterBoxItemTitle>
        <CustomDateRangePicker
          value={selectedDate}
          onChange={onDateRangeChange}
          disabledDate={disabledDate}
        />
      </FilterBoxItem>
      <FilterBoxFooter>
        <FilterFooterApplyButton onClick={onFilterButtonClick}>
          Filter
        </FilterFooterApplyButton>
        <FilterFooterResetButton onClick={onResetClick}>
          Reset
        </FilterFooterResetButton>
      </FilterBoxFooter>
    </FilterBox>
  );
};
const TitleFilter = ({
  search,
  setSearch,
  showFilter,
  disableRequest,
  onRequestClick,
  onDateChange,
  onStatusChange,
  onResetButtonClick,
  onFilterButtonClick,
  activeToggle,
  setActiveToggle,
  handleExportClick,
}: {
  search: string,
  setSearch: (val: string) => void;
  showFilter: number;
  disableRequest?: boolean;
  onRequestClick: () => void;
  onFilterButtonClick: () => void;
  onResetButtonClick: () => void;
  onStatusChange: (val) => void;
  onDateChange: (val, val2) => void;
  activeToggle: string;
  setActiveToggle: (data: string) => void;
  handleExportClick?: (val: boolean) => void;
}) => {

    const toggleType = ["Collections", "Payout"];

  const handleToggle = (item) => {
    setActiveToggle(item);
  };

  return (
    <div>
      <Top>
        <ToggleButtonContainer>
          <ToggleButton 
          toggleType={toggleType}
          activeToggle={activeToggle}
          setActiveToggle={setActiveToggle}
          handleToggle={handleToggle}
          
          />
        </ToggleButtonContainer>

        <Button
          label="Request payout"
          theme="secondary"
          height="40px"
          width="128px"
          fontSize="12px"
          onClick={onRequestClick}
          disabled={disableRequest}
        />
      </Top>

      {showFilter > 0 &&
        <Bottom>
         <Search
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
         />

          <RightButtons>
            <ResponsivePopover
              content={
                <FilterContent
                  onStatusChange={onStatusChange}
                  onDateChange={onDateChange}
                  onFilterButtonClick={onFilterButtonClick}
                  onResetButtonClick={onResetButtonClick}
                  toggleType={activeToggle}
                />
              }
              trigger="click"
              className="pop_over"
            >
              <FilterButton>
                <span>Filter</span>
                <FilterIcon />
              </FilterButton>
            </ResponsivePopover>

            <ExportByFilter
             customButtonTitle="Get Statement"
             onExportClick={handleExportClick}
            />
          </RightButtons>
        </Bottom>
      }

      <ToggleButtonContainerMobile>
        <ToggleButton 
         toggleType={toggleType}
         activeToggle={activeToggle}
         setActiveToggle={setActiveToggle}
         handleToggle={handleToggle}
        
        />
      </ToggleButtonContainerMobile>
    </div>
  );
};

export default TitleFilter;
