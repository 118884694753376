import React, { useContext } from "react";
import { usePostHog } from 'posthog-js/react'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Wrapper,
  Header,
  InputBox,
  FormContainer,
  Content,
  FooterButtons,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Button } from "../../../../common/Button";
import { modifyComplianceData } from "../../../../../pages/Compliance/complianceSlice";
import { DeclarationFormInputs } from "../../../types";
import { useNavigate } from "react-router-dom";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { gtagEvent } from "../../../../../utils/gtag";

const Declaration = () => {
  const posthog = usePostHog();
  const { complianceData } = useAppSelector((state) => state.compliance);
  const { business } = useAppSelector((state) => state.settings);
  const { businessId, userObj } = useAppSelector((state) => state.users);
  const { accountUser } = userObj;
  const {
    handleSubmit,
    control,
    reset,
  } = useForm<DeclarationFormInputs>({
    defaultValues: {
      declarationName:
        complianceData?.declarationName ||
        `${accountUser?.firstName} ${accountUser?.lastName}`,
      declarationCompanyName:
        complianceData?.declarationCompanyName || business?.name,
    },
  });
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  

  const onSubmit: SubmitHandler<DeclarationFormInputs> = async (data) => {

    try {
      await dispatch(
        modifyComplianceData({
          ...complianceData,
          businessId,
          declarationName: data.declarationName,
          declarationCompanyName: data.declarationCompanyName,
          startupComplianceStatus: "DONE",
        })
      );

      posthog?.capture("kyb_completed", { status: "success" })
      gtagEvent({
        action: "Compliance_submit_declaration",
        category: "compliance",
        label: "Compliance submit declaration",
      });
      window.localStorage.clear();
      navigate("/auth/login");
    } catch (e) {
      posthog?.capture("kyb_completed", { status: "failure", failure_reason: e.message })
      onShowErrorAlert(e, e.message);
    }
  };

  const onCancel = () => {
    reset(
      {
        declarationName: null,
        declarationCompanyName: null,
      },
      {
        keepErrors: true,
        keepTouched: false,
      }
    );
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>Declaration</Header>
      <FormContainer>
        <Content>
          <div>
            <span>I</span>
            <InputBox>
              <Controller
                name="declarationName"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <input type="text" placeholder="Name" {...field} />
                )}
              />
            </InputBox>
            <span>confirm and agree, on behalf of </span>

            <InputBox>
              <Controller
                name="declarationCompanyName"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <input type="text" placeholder="Business name" {...field} />
                )}
              />
            </InputBox>
            <span>,</span>
          </div>
          <p>
            That the above responses are current, accurate, and reflective of
            our institution’s business and anti-money laundering information.
          </p>
          <p>
            That I am duly authorised to sign for and act on behalf of the
            company and that no approval is required from any other person.
          </p>
          <p>
            That we will notify Klasha immediately of any changes to the
            information provided herein.
          </p>
          <p>
            That we authorise all background searches, due-diligence enquiries
            and use of our information as set out in Klasha’s AML and KYC
            Policies.
          </p>
        </Content>
      </FormContainer>
      <FooterButtons>
        <Button
          label="Cancel"
          type="button"
          theme="alternate"
          height="48px"
          fontSize="14px"
          onClick={onCancel}
        />
        <Button
          label="Agree"
          type="submit"
          theme="primary"
          height="48px"
          fontSize="14px"
          // onClick={onSubmit}
        />
      </FooterButtons>
    </Wrapper>
  );
};

export default Declaration;
