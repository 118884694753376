import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { uploadImage } from "../../../utils";
import {
    InputContainer,
    UploadButton,
    UploadContainer,
    UploadWrapper,
    UploadHeader,
    DropdownButton,
    DoneDiv,
    DoneLeft,
    DoneRight,
    DoneContainer,
} from "./style";
import { Progress } from "antd";
import { ReactComponent as DropdownIcon } from "../../../../../assets/icons/arrow-down.svg";
import { ProgressWrapper } from "../../../../common/CustomUpload/style";
import { ReactComponent as DocumentIcon } from "../../../../../assets/icons/document.svg";

type ProgressStatus = "idle" | "progress" | "done";


const ComplianceUpload = ({
    onUpload,
    docId,
    docName,
    approvalStatus,
    uploadPath,
    onUploadFinish,
    label,
    title,
}: {
    onUpload: (newDoc: any) => void;
    onUploadFinish?: (docUrl: any, docId: any) => void;
    docName: string;
    docId: number;
    approvalStatus?: string;
    uploadPath?: string;
    label: string;
    title: string;
}) => {
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [documentExistStatus, setDocumentExistStatus] = useState("idle");
    const [fileType, setFileType] = useState("");
    const [fileName, setFileName] = useState("");
    const [uploadingStatus, setUploadingStatus] =
        useState<ProgressStatus>("idle");
    const [documentUrl, setDocumentUrl] = useState("");
    const [expanded, setExpanded] = useState(true);

    const { businessId } = useAppSelector((state) => state.users);

    const onDocUploadFinish = (docUrl: any) => {
        onUploadFinish?.(docUrl, docId);
    };


    const onUploadDocument = (file, docName) => {
        if (!file) {
            return; 
        }
        uploadImage({
            file,
            docName,
            setUploadingProgress: setUploadingProgress,
            setFileType: setFileType,
            setDocumentExistStatus: setDocumentExistStatus,
            setDocumentUrl: setDocumentUrl,
            setUploadingStatus: setUploadingStatus,
            businessId,
            setFileName: setFileName,
            uploadPath,
            onFinish: onDocUploadFinish,
        });
    };

    useEffect(() => {
        if (["APPROVED", "FAILED", "PENDING"].includes(approvalStatus)) {
            setUploadingStatus("done");
        }
    }, [approvalStatus]);

    useEffect(() => {
        if (uploadingStatus === "done") {
            const newDocDetail = {
                id: docId,
                fileName: fileName,
                fileType: fileType,
                uploadStatus: uploadingStatus,
                uploadProgress: uploadingProgress,
                docUrl: documentUrl,
                docExistsStatus: documentExistStatus,
            };
            onUpload(newDocDetail);
        }
    }, [uploadingStatus]);


    const onClick = () => {
        setExpanded(!expanded);
    };
    return (
    
            <InputContainer>
                <UploadContainer $isExpanded={expanded}>
                    <UploadHeader>
                        <p>{title}</p>
                        <p>{label}</p>
                    </UploadHeader>
                    {expanded && uploadingStatus !== "done" && (
                        <UploadWrapper
                        name="id"
                        customRequest={({ file }) => onUploadDocument(file, docName)}
                        accept=".doc,.docx,image/*,.pdf"
                        >
                            <UploadButton>
                                {uploadingStatus === "idle" && (
                                    <div>
                                        <h3>Upload file</h3>
                                        <p>PDF, JPEG or PNG less than 5MB</p>

                                    </div>
                                )}
                                {uploadingStatus === "progress" && (
                                    <ProgressWrapper>
                                        <Progress percent={uploadingProgress} showInfo={false} />
                                        <p>{uploadingProgress}%</p>
                                    </ProgressWrapper>
                                )}
                            </UploadButton>
                        </UploadWrapper>
                    )}
                    {expanded && uploadingStatus === "done" && (
                        <DoneContainer>
                            <DoneDiv>
                                <DoneLeft>
                                    <DocumentIcon />
                                    <p>Document</p>
                                </DoneLeft>
                                <DoneRight>
                                    <p>Uploaded</p>
                                </DoneRight>
                            </DoneDiv>
                        </DoneContainer>
                    )}
                </UploadContainer>

                <DropdownButton type="button" onClick={onClick} $isExpanded={expanded}>
                    <DropdownIcon />
                </DropdownButton>
            </InputContainer>
    
    );
};

export default ComplianceUpload;
