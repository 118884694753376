import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  font-family: "Inter";
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;

  h1 {
    font-family: "Athletics";
    color: #000000;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    margin: 0;
  }

  p {
    color: #767676;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  button {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    button {
      width: 209px;
    }
  }
`;


export const Title = styled.h1`
  margin-top: 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #000000;
  padding-bottom: 12px;
  border-bottom: 1px solid #E9E9E9;
`;




export const Beneficiaries = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
