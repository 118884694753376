import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import InputAndSelect from "../../components/common/InputAndSelect";
import {
    Heading,
    InputWrapper,
    RateBox,
    Wrapper,
} from "./styles";
import { Button } from "../../components/common/Button";
import { useAppSelector } from "../../app/hooks";
import { FundUsdSettlementWallet, getRate} from "./api";
import Loader from "../../components/common/Loader";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../utils/formatCurrency";

interface Wallet {
    currency: string;
}

const FundSettlementWallet = () => {
    const { wallets } = useAppSelector(
        (state) => state?.wallets || {},
    );
    const successAlertContext = useContext(
        SuccessAlertContext,
    );
    const { onShowAlert: onSuccess } =
        successAlertContext || {};
    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        userAmount: 1,
        amount: null,
        destinationCurrency: null,
        selectedCurrency: null,
        convertedAmount: null,
        rate: null,
        loading: false,
        disable: false,
    });

    const filteredWallets = wallets as Wallet[];

    const uniqueCurrencies = Array.from(
        new Set(
            filteredWallets?.map(
                (wallet) => wallet.currency,
            ),
        ),
    );

    const options = uniqueCurrencies.map((currency) => ({
        label: currency,
        value: currency,
    }));

    const ngnOption = options?.find(
        (option) => option.value === "NGN",
    );
    const ngnOptions = ngnOption ? [ngnOption] : [];

    const Balance = wallets?.find(
        (currency) => currency.currency === "NGN",
    );

    const totalAmount = (1 / userData.rate) * userData.amount

    useEffect(() => {
        if (userData?.amount && Balance?.settlementBalance < userData?.amount) {
          onShowAlert(
            "",
            "Amount cannot be more than settlement balance",
          );
          setUserData((prevData) => ({
            ...prevData,
            disable: true,
            convertedAmount: null,
          }));
        } else if (!userData?.amount) {
          setUserData((prevData) => ({
            ...prevData,
            convertedAmount: null,
          }));
        } else {
          setUserData((prevData) => ({
            ...prevData,
            disable: false,
          }));
        }
      }, [userData?.amount, Balance?.settlementBalance]);
      
      
    useEffect(() => {
        const fetchData = async () => {
            setUserData((prevData) => ({
                ...prevData,
                loading: true,
            }));
            try {
                const result = await getRate({
                    amount: userData.amount,
                    destinationCurrency:userData.selectedCurrency,
                    sourceCurrency: userData.destinationCurrency,
                    businessId,
                    productType: "SWAP"
                });
                userData.loading = false;
                setUserData((prevData) => ({
                    ...prevData,
                    convertedAmount: result.amount,
                    rate: result.rate,
                    loading: false,
                }));
            } catch (error) {
                setUserData((prevData) => ({
                    ...prevData,
                    loading: false,
                }));
                return error;
            }
        };

        if (
            userData.amount !== null &&
            userData.selectedCurrency !== null &&
            userData.destinationCurrency !== null
        ) {
            fetchData();
        }
    }, [
        userData.amount,
        userData.selectedCurrency,
        userData.destinationCurrency,
    ]);

    const handleAmountChange = (enteredAmount) => {
       if(enteredAmount < 1){
        setUserData((prevData) => ({
            ...prevData,
            amount: 0,
        }));
    }else{
        setUserData((prevData) => ({
            ...prevData,
            amount: enteredAmount,
        }));
    
    }
    };

    const handleCurrencyChange = (selectedCurrency) => {
        setUserData((prevData) => ({
            ...prevData,
            selectedCurrency,
        }));
    };

    const handleSettlementCurrency = (selectedCurrency) => {
        setUserData((prevData) => ({
            ...prevData,
            destinationCurrency: selectedCurrency,
        }));
    };

    const FundMerchantWallet = async () => {
        if (
            !userData.amount ||
            !userData.selectedCurrency ||
            !userData.destinationCurrency
        ) {
            onShowAlert(
                "",
                "Please provide the amount and select both currencies",
            );
            return;
        }

        setUserData((prevData) => ({
            ...prevData,
            loading: true,
        }));

        const data = {
            sourceCurrency: userData.destinationCurrency,
            destinationCurrency: userData.selectedCurrency,
            amount: userData.amount,
        };

        try {
            await FundUsdSettlementWallet(data, businessId);
            setUserData((prevData) => ({
                ...prevData,
                loading: false,
            }));
            onSuccess("Wallet funded successfully");
            navigate("/wallets");
        } catch (error) {
            setUserData((prevData) => ({
                ...prevData,
                loading: false,
            }));
            onShowAlert("", error.response.data.error);
        }
    };

    return (
        <Wrapper>
            <Loader isLoading={userData.loading} />
            <Heading>
                How much do you want to add to your wallet?
            </Heading>

            <InputWrapper>
                <InputAndSelect
                    selectPlaceholder="Currency"
                    width="100%"
                    amountValue={userData.amount}
                    onAmountChange={handleAmountChange}
                    options={options}
                    onSelectChange={handleCurrencyChange}
                />

                <div className="rate-box">
                    <RateBox>
                        <span>{`+ ${Number(0).toFixed(
                            2,
                        )} NGN`}</span>
                        <span>Processing fee</span>
                    </RateBox>

                    {userData.rate !== null && (
                        <RateBox>
                            <span>{`x ${ formatCurrency(1 / userData?.rate)}`}</span>
                            <span>Exchange rate</span>
                        </RateBox>
                    )}
                </div>

                <InputAndSelect
                    selectPlaceholder="Currency"
                    width="100%"
                    amountValue={userData.amount ? totalAmount.toFixed(2) : null}
                    options={ngnOptions}
                    onSelectChange={
                        handleSettlementCurrency
                    }
                    readOnly
                />
                <Button
                    label="Add funds"
                    height="40px"
                    onClick={FundMerchantWallet}
                    disabled={userData.disable}
                />
            </InputWrapper>
        </Wrapper>
    );
};

export default FundSettlementWallet;
