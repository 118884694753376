import React from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Wrapper, Header, Inputs, InputBox } from "./style";
import { countryOptions } from "./utils";
import { useToast } from "../../../../app/hooks/useToast";
import { countries } from "countries-list";
import { useAppSelector } from "../../../../app/hooks";
import { Input } from "../../../../components/common/Input";
import { Button } from "../../../../components/common/Button";
import { InputPhone } from "../../../../components/common/InputPhone";
import CustomSelect from "../../../../components/common/CustomSelect";
import { createAndUpdateWireBeneficiary } from "../../../KlashaWire/wiresAPI";

type FormInputs = {
  beneficiaryName: string;
  businessName: string;
  country: string;
  bankName: string;
  accNum: string;
  phoneNumber: string;
  beneficiaryEmail: string;
  iban: string;
  swiftCode: string;
  address: string;
  bankAddress: string;
};

const BeneficiaryModal = ({ onSaved }: { onSaved?: () => void }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>();

  
  const { businessId } = useAppSelector((state) => state.users);
  const toast = useToast()

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    try {
        createAndUpdateWireBeneficiary({
          name: data.businessName,
          beneficiaryName: data.beneficiaryName,
          country: data.country ? countries[data.country]?.name : "--",
          countryCode: data.country,
          bankName: data.bankName,
          accountNumber: data.accNum,
          phone: data.phoneNumber,
          email: data.beneficiaryEmail,
          iban: data.iban,
          swiftCode: data.swiftCode,
          business: businessId,
          address: data.address,
          bankAddress: data.bankAddress,
        });
       onSaved();
       toast.notifySuccess('Beneficiary saved')

    
    } catch (err) {
        toast.notifyError(err)
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>Create new beneficiary</Header>
      <Inputs>
        <InputBox>
          <Controller
            name="beneficiaryName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary name"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.beneficiaryName?.type === "required" && (
            <span>Please input the beneficiary&apos;s name</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="businessName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Business name"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.businessName?.type === "required" && (
            <span>Please input the beneficiary&apos;s business name</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="country"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                placeholder="Country"
                options={countryOptions}
                $height="48px"
                $width="100%"
                field={field}
                showSearch
              />
            )}
          />
          {errors.country?.type === "required" && (
            <span>Please select the beneficiary&apos;s country</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank name"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.bankName?.type === "required" && (
            <span>Please input the beneficiary&apos;s bank name</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankAddress"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank address"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.bankAddress?.type === "required" && (
            <span>Please input the beneficiary&apos;s bank address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="accNum"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                label="Account number"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.accNum?.type === "required" && (
            <span>Please input the beneficiary&apos;s account number</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputPhone
                label="Phone number"
                field={field}
                isSecondaryBackground
              />
            )}
          />
          {errors.phoneNumber?.type === "required" && (
            <span>Please input the beneficiary&apos;s phone number</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="beneficiaryEmail"
            control={control}
            rules={{
              required: true,
              pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary email"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.beneficiaryEmail?.type === "required" && (
            <span>Please input the beneficiary&apos;s email</span>
          )}
          {errors.beneficiaryEmail?.type === "pattern" && (
            <span>Please enter a valid email address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="address"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary address"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.address?.type === "required" && (
            <span>Please input the beneficiary&apos;s address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="iban"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="IBAN"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="swiftCode"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="Swift code"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
      </Inputs>
      <Button
        label="Add beneficiary"
        width="100%"
        type="submit"
        theme="secondary"
        height="48px"
        fontSize="14px"
      />
    </Wrapper>
  );
};

export default BeneficiaryModal;