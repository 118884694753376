import React, { useEffect, useState } from "react";
import { uploadImage } from "../../compliance/utils";
import {
  Content,
  DocumentWrapper,
  Label,
  Icon,
} from "./styles";
import { useAppSelector } from "../../../app/hooks";
import { ProgressStatus } from "../../../pages/Wallets/utils";

interface UploadProps {
  type: string;
  documentTitle?: string;
  documentSubtitle?: string;
  onUploadFinish?: (docUrl: string, docId: number) => void;
  docId?: number;
  uploadPath?: string;
  onUpload?: (newDoc: any) => void; 
  imageId?: string;
  setDocumentUrl?:any
}

const DocumentUpload = ({
  type = "INITIAL",
  documentTitle,
  documentSubtitle,
  setDocumentUrl,
  onUploadFinish,
  docId,
  uploadPath,
  onUpload,
  imageId,
}: UploadProps) => {
  const [uploadingProgress, setUploadingProgress] =
    useState(0);
  const [documentExistStatus, setDocumentExistStatus] =
    useState("idle");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadingStatus, setUploadingStatus] =
    useState<ProgressStatus>("idle");
  const { businessId } = useAppSelector(
    (state) => state.users
  );

  function convertToSentenceCase(inputString) {
    if (
      typeof inputString !== "string" ||
      inputString.length === 0
    ) {
      return inputString;
    }

    if (inputString === "FAILED") {
      return "Rejected";
    }

    const lowerCaseString = inputString.toLowerCase();
    const sentenceCaseString =
      lowerCaseString.charAt(0).toUpperCase() +
      lowerCaseString.slice(1);

    return sentenceCaseString;
  }
  
  useEffect(() => {
    setUploadingStatus("idle");
  }, []);

  const onUploadDocument = (file: File | null) => {
    if (file) {
      setUploadingStatus("progress");
      const docName = file.name;
        uploadImage({
        file,
        docName,
        setUploadingProgress,
        setFileType,
        setDocumentExistStatus,
        setDocumentUrl,
        setUploadingStatus,
        businessId,
        setFileName,
        uploadPath,
        onFinish: (docUrl) => {
         
          const newDocDetail = {
            id: docId,
            fileName: fileName,
            fileType: fileType,
            uploadStatus: uploadingStatus,
            uploadProgress: uploadingProgress,
            docExistsStatus: documentExistStatus,
            imageId,
            docUrl: docUrl,
          };
  
          onUpload && onUpload(newDocDetail);
        },
        imageId,
      });
    }
  };
  

  

  useEffect(() => {
    if (["APPROVED", "FAILED", "PENDING"].includes(type)) {
      setUploadingStatus("done");
    }
  }, [type]);

  return (
    <Label>
      <Content status={type}>
        <Icon iconType={type} />
        <div>
          <h3>
            {uploadingStatus === "progress"
  ? "Compressing..."
  : documentTitle
  ? documentTitle
  : uploadingStatus === "done"
  ? fileName
  : "Upload file"}


          </h3>
          <p>
            {uploadingStatus === "done"
              ? convertToSentenceCase(type)
              : type === "LOADING"
              ? "Please wait..."
              : documentSubtitle
              ? documentSubtitle
              : documentTitle
              ? documentTitle
              : "PDF, JPEG or PNG less than 5MB"}
          </p>
        </div>
      </Content>
      <DocumentWrapper
        type="file"
        accept=".pdf, .jpg, .png"
        onChange={(event) =>
          onUploadDocument(event.target.files?.[0])
        }
        disabled={type !== "INITIAL"}
      />
    </Label>
  );
};

export default DocumentUpload;
