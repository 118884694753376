import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  margin: 0; 
  display: flex;
`;

export const Body = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.2s ease-in;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: relative;

  margin-left: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "200px" : "0"};

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const BodyContent = styled.div`
  flex: 1 1;
  padding: 30px 32px;
  position: relative;
  margin-bottom: 40px;

  @media (min-width: 992px) {   
    height: 100%;
    overflow-y: auto; 
  }

  @media(max-width: 992px) {
    padding: 16px;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  backdrop-filter: blur(4px);
  background-color: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "rgba(0, 0, 0, 0.50)" : "none"};
  display: ${(props: { $isExpanded: "none" | "min" | "max" }) =>
    props.$isExpanded === "max" ? "block" : "none"};
  transition: all 1s ease;

  @media (min-width: 993px) {   
    display: none;
  }
`;
