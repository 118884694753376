import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    StepsContainer,
    StepsTitle,
    ReviewBox,
    ReviewHeader,
    ReviewDetails,
    ReviewItem,
    AlertBox,
    Alert,
    AlertText,
    ReviewRate,
} from "../steps/style";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/alert-circle.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/transfer.svg";
import { alertItems } from "../../staticData";
import { Button } from "../../../../components/common/Button";
import {
    useAppSelector,
    useAppDispatch,
} from "../../../../app/hooks";
import Loader from "../../../../components/common/Loader";
import {
    addCommasToNumber,
    generateRandomNumber,
} from "../../utils";
import {
    setcardholderId,
} from "../../CardSlice";
import { sendOTP } from "../../../../api/usersAPI";
import { AlertProps, ReviewProps } from "../../types";
import { createCard, sendFund } from "../../CardsApi";
import { getCountryByAbbreviation } from "country-currency-map";
import ErrorMessage from "../steps/ErrorMessage";
import VerifyPayment from "./VerifyPayment";
import { encryptData } from "../../../../utils/ecryptionData";
import { usePostHog } from "posthog-js/react";
import { useToast } from "../../../../app/hooks/useToast";


const Rate = ({ rate, label }: any) => {
    return (
        <ReviewRate>
            $1
            <ExchangeIcon />
            {label === "NGN" && "₦"}
            {rate ? rate.toFixed(2) : 0}
        </ReviewRate>
    );
};

const Review = ({
    onEdit,
    feeText,
    fee,
    type,
}: ReviewProps) => {
    const dispatch = useAppDispatch();
    const today: string = new Date()
        .toLocaleDateString()
        .replaceAll("/", ".");
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate()
    const toast = useToast()
    
    const [verifyOTP, setVerifyOTP] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [code, setCode] = useState(null)
    const [ref, setRef] = useState("");
	const location = useLocation();
    const posthog = usePostHog();


    
    const {
        fundsValue,
        amountValue,
        rate,
        wallets,
        card,
        merchantBusiness,
    } = useAppSelector((state) => state.cards);
    const { userObj, businessId } = useAppSelector(
        (state) => state.users,
    );
    const { accountUser } = userObj;
    const usdCard = card ? card.find((c) => c.isActive === true) : {};
    


    const postHog = (type: string, msg?: string) =>{
        if(type === "success"){
          posthog?.capture("fund_black_card_web", { Status: "Successful"})
        }
        if(type === "failed"){
          posthog?.capture("fund_black_card_web", { Status: "Failed", Failure_Reason: msg })
        }
      }

    const amount = amountValue
        ? parseFloat(amountValue)
        : 0;
    const convertedAmount = amount * rate;
    const getFullCountryName = (country: string) => {
        if (country.length > 3) {
            return country;
        } else {
            const fullName =
                getCountryByAbbreviation(country);
            return fullName;
        }
    };

    useEffect(() => {
        const createCardRef = generateRandomNumber();
        setRef(createCardRef);
    }, []);

    const merchant = merchantBusiness
        ? merchantBusiness.find(
              (biz) => biz.id === businessId,
          )
        : {};
    const selectedWallet = wallets
        ? wallets.find(
              (wallet: { currency: string }) =>
                  wallet.currency === fundsValue?.label,
          )
        : {};

    const amtToFund = amount + fee;
    const ngnTotal = amtToFund * rate;

    const reviewItems = [
        {
            id: 1,
            name: "Amount to be funded",
            value: `$${amount.toFixed(2)}`,
        },
        {
            id: 2,
            name: feeText,
            value: `$${fee.toFixed(2)}`,
        },
        {
            id: 3,
            name: "Total amount",
            value: `$${amtToFund.toFixed(2)}`,
        },
        {
            id: 4,
            name: "Rate",
            value: fundsValue ? (
                <Rate
                    rate={rate}
                    label={fundsValue.label}
                />
            ) : (
                0
            ),
        },
        {
            id: 5,
            name: "Amount to be debited",
            value: `₦${addCommasToNumber(
                ngnTotal.toFixed(2),
            )}`,
        },
        {
            id: 6,
            name: "Source of debit",
            value:
                fundsValue && `${fundsValue.label} Wallet`,
        },
        {
            id: 7,
            name: "Date",
            value: today,
        },
    ];
    

    const createPayload = () => {
        return {
          first_name: accountUser.firstName,
          last_name: accountUser.lastName,
          convertedAmount: convertedAmount,
          amount: amount,
          walletId: selectedWallet ? selectedWallet.id : "",
          customerId: selectedWallet ? selectedWallet.userId : "",
          businessId: selectedWallet ? selectedWallet.businessId : "",
          createCurrency: "USD",
          currency: "USD",
          debitCurrency: selectedWallet ? selectedWallet.currency : "",
          address: merchant?.address || "Ligali Ayorinde",
          city: merchant?.city || "Lekki",
          state: merchant?.state || "Lagos",
          country: getFullCountryName(merchant?.country || ""),
          walletTxRef: `klasha-business-card-visa-${ref}`,
          phone: merchant?.phone || "",
          email_address: merchant?.email || "",
          eTag: "4.0.0",
          otp: code,
        };
      };
      
      const createSendFundPayload = () => {
        return {
          convertedAmount: convertedAmount,
          amount: amount,
          walletId: selectedWallet ? selectedWallet.id : "",
          transRef: `klasha-business-send-funds-${ref}`,
          cardId: usdCard ? usdCard.cardId : "",
          eTag: "4.0.0",
          otp: code,
        };
      };
      
      const createEncryptedFormData = (payload) => {
        return {
          message: encryptData(payload),
        };
      };
      
      const createEncryptedSendFundsData = (sendFundPayload) => {
        return {
          message: encryptData(sendFundPayload),
        };
      };
      
      const createBlackCard = async (fundingType: string) => {
        setLoading(true);
      
        try {
          setLoading(false);
          if (fundingType === "create-card") {
            const formData = createEncryptedFormData(createPayload());
            
            const data = await createCard(formData);
            dispatch(setcardholderId(data.data.cardholder_id));
          } else if (location.pathname === "/cards/send-funds") {
            const sendFundsData = createEncryptedSendFundsData(
              createSendFundPayload()
            );
            await sendFund(sendFundsData);
            toast.notifySuccess('success')
            setTimeout(() => {
            navigate('/cards')
            }, 2000)
            postHog("success")
          }
        } catch (err) {
          const errorObj = err?.response?.data;
          postHog("failed", errorMessage)
      
          if (
            errorObj?.message ===
            '500 : [{"message":"500 Internal Server Error: [Internal Server Error]","error":"InternalError"}]'
          ) {
            setErrorMessage("A technical error occurred");
          } else if (typeof errorObj?.error === "string") {
            setErrorMessage(errorObj.error);
          } else if (typeof errorObj?.message === "string") {
            setErrorMessage(errorObj.message);
          } else {
            setErrorMessage("Card funding failed");
          }
      
          setLoading(false);
          setShowError(true);
        }
      };
      

    const handleSendOTP = async (user: {
        email: string;
    }) => {
        try {
            setLoading(true);
            await sendOTP(user);

            setLoading(false);
            setVerifyOTP(true);
        } catch (error) {
            const errorObj = error?.response?.data;

            if (typeof errorObj?.error === "string") {
                setErrorMessage(errorObj.error);
            } else if (
                typeof errorObj?.message === "string"
            ) {
                setErrorMessage(errorObj.message);
            } else if (typeof errorObj === "string") {
                setErrorMessage(errorObj);
            } else {
                setErrorMessage("Failed to send OTP");
            }

            setLoading(false);
            setShowError(true);
        }
    };

    return (
        <StepsContainer>
            <Loader isLoading={loading} />

            {showError && (
                <ErrorMessage
                    setShowCardError={setShowError}
                    errorMessage={errorMessage}
                />
            )}

            <StepsTitle>
                <h1>Review your transaction details</h1>
            </StepsTitle>

            <ReviewBox>
                <ReviewHeader>
                    <h1>Transaction details</h1>
                    <span onClick={onEdit}>Edit</span>
                </ReviewHeader>

                <ReviewDetails>
                    {reviewItems.map((item) => (
                        <ReviewItem key={item.id}>
                            <p>{item.name}</p>
                            <span>{item.value}</span>
                        </ReviewItem>
                    ))}
                </ReviewDetails>
            </ReviewBox>

            <AlertBox>
                <Alert>
                    <AlertIcon />
                </Alert>
                <AlertText>
                    {alertItems.map((item: AlertProps) => (
                        <li key={item.id}>{item.title}</li>
                    ))}
                </AlertText>
            </AlertBox>

            <Button
                label="Confirm and make payment"
                height="48px"
                fontSize="14px"
                onClick={() => handleSendOTP(accountUser?.email)}
            />

            {verifyOTP && (
                <VerifyPayment
                  setVerifyOTP={setVerifyOTP}
                    makeRequest={() => createBlackCard(type)}
                    otp={code}
                    setOtp={setCode}
                />
            )}
        </StepsContainer>
    );
};

export default Review;
