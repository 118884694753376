import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllTimeSalesData,
  getSalesByMethod,
  getSalesInRange,
} from "./analyticsAPI";

export interface AnalyticState {
  sales: any[];
  salesByMethod: any;
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
  allTimeSales: any[];
}

const initialState: AnalyticState = {
  sales: [],
  salesByMethod: {},
  status: "idle",
  isLoading: false,
  error: null,
  allTimeSales: [],
};

export const salesInfo = createAsyncThunk(
  "merchant/sales/success",
  async ({
    businessId,
    todaysDate,
  }: {
    businessId: number | string;
    todaysDate: string;
  }) => {
    const response = await getSalesInRange(businessId, todaysDate);
    return response;
  }
);

export const salesByMethodInfo = createAsyncThunk(
  "merchant/sales/method",
  async ({ businessId }: { businessId: number | string }) => {
    const response = await getSalesByMethod(businessId);
    return response;
  }
);

export const allTimeSalesData = createAsyncThunk(
  "merchant/sales/all/time",
  async ({ businessId }: { businessId: number | string }) => {
    const response = await getAllTimeSalesData(businessId);
    return response;
  }
);

export const analyticsSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(salesInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(salesInfo.fulfilled, (state, action) => {
        state.status = "idle";
        const sales = action.payload;
        state.sales = sales;
      })
      .addCase(salesInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(salesByMethodInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(salesByMethodInfo.fulfilled, (state, action) => {
        state.status = "idle";
        const salesByMethod = action.payload;
        state.salesByMethod = salesByMethod;
      })
      .addCase(allTimeSalesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(allTimeSalesData.fulfilled, (state, action) => {
        state.status = "idle";
        state.allTimeSales = action.payload;
      });
  },
});


export default analyticsSlice.reducer;
