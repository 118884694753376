import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@reach/window-size";
import debounce from 'lodash/debounce';
import dayjs from "dayjs";
import exportFromJSON from "export-from-json";
import {
  EmptyWrapper,
  EmptyWrapperTitle,
  EmptyWrapperSubTitle,
  MobileBody,
  Title,
  Wrapper,
  BoxBody,
} from "./style";
import CustomTable from "../../components/common/CustomTable";
import TitleFilter from "../../components/transactions/components/TitleFilter";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  transactionsAllInfo,
  transactionsInfo,
  TransactionsType,
} from "./transactionsSlice";
import { getBaeName, getMethodLabel, getSourceLabel, tableColumns } from "./utils";
import Loader from "../../components/common/Loader";
import TransactionCard from "../../components/transactions/components/TransactionCard";
import { formatCurrencyAmount } from "../../utils/currency";
import EmptyMobileCard from "../../components/emptyStates/EmptyMobileCard";
import { formatLongString } from "../../utils/formatString";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import Details from "./Details";
import RefundModal, { formData } from "./RefundModal";
import { InitiateRefund } from "../Refunds/redux/actions";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { selectUserRoleAndPermissions } from "../Settings/Team/redux/slice";


const EmptyBalanceDescription = () => {
  return (
    <EmptyWrapper>
      <EmptyWrapperTitle>No transaction yet</EmptyWrapperTitle>
      <EmptyWrapperSubTitle>All received or sent transactions will appear here.</EmptyWrapperSubTitle>
    </EmptyWrapper>
  );
};


const Transactions = () => {
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("");
  const [filterDate, setFilterDate] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [filterResult, setFilterResult] = useState<TransactionsType>({});
  const [isFiltering, setIsFiltering] = useState(false);
  const [state, setState] = useState({openModal: false, openDrawer: false, record: null})
  const { loading } = useAppSelector(
    (state) => state.refunds,
);
const {role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);


  const { status: transactionStatus, transactions } = useAppSelector(
    (state) => state.transactions,
  );

  const { onShowAlert: onShowSuccessAlert } = useContext(
    SuccessAlertContext,
);

const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
);

const hasDownloadPermission =
        permissions?.["transactions"]?.includes("EXPORT_TRANSACTION_STATEMENT");

  const { businessId } = useAppSelector((state) => state.users);

  const getExportData = async () => {
    const filterPayload = getQueryFields();
    const allTrans = await dispatch(
      transactionsAllInfo({
        filter: {
          business: businessId,
          ...filterPayload,
        },
      }),
    ).unwrap();
    return allTrans.transactions.map((cd, index) => {
      return {
        "S/n": index + 1,
        "Transaction id": cd.transaction.tnxRef ?? "N/A",
        Customer: cd.transaction.customer
          ? cd.transaction.customer.email
          : "N/A",
        source: cd.transaction.channel
          ? getSourceLabel(cd.transaction.channel)
          : "N/A",
        Method: cd.transaction.paymentType
          ? getBaeName(cd.transaction.extra) +
            getMethodLabel(cd.transaction.paymentType)
          : "N/A",
        currency: cd.transaction.currency,
        Amount: formatCurrencyAmount(
          cd.transaction.currency,
          cd.transaction.chargedAmount / 100,
        ),
        Date: dayjs(cd.transaction.createdAt).format("DD.MM.YYYY - HH:mm"),
        Status:
          cd.transaction.status?.toLowerCase() !== "success"
            ? cd.transaction.status
            : "Pending",
      };
    });
  };

  useEffect(() => {
    setTransactionData([]);

    if (transactions.transactions) {
      setFilterResult(transactions);
      const tableData = transactions.transactions.map((cus, index) => {

        return {
          key: cus.transaction.id,
          no: index + 1,
          refundId: cus.transaction.tnxRef,
          transId: formatLongString(cus.transaction.tnxRef, 40),
          email: cus.transaction.customer
            ? cus.transaction.customer?.email
            : "N/A",
            name:cus?.transaction?.customer?.name ,
          source:
            cus?.transaction?.channel !== null
              ? getSourceLabel(cus.transaction.channel)
              : "N/A",
          method:
            cus?.transaction?.paymentType !== null
              ? getBaeName(cus.transaction.extra) +
                getMethodLabel(cus.transaction.paymentType)
              : "N/A",
          currency: cus.transaction.currency,
          amount: formatCurrencyAmount(
            cus.transaction.currency,
            cus.transaction.chargedAmount / 100,
          ),
          newAmount: cus?.transaction.chargedAmount / 100,
          status:
            cus.transaction.status?.toLowerCase() !== "success"
              ? cus.transaction.status
              : "Pending",
          date: dayjs(cus.transaction.createdAt).format("DD.MM.YYYY - HH:mm"),
        };
      });

      setTransactionData(tableData);
    } else {
      setFilterResult(null);
    }
  }, [transactions]);

  const onPageChange = (page) => {
        setPageNumber(page);
  };

  const onMethodChange = (value) => {
    setPaymentType(value);
    onPageChange(1);
  };

  const onSourceChange = (value) => {
    setSelectedChannel(value);
    onPageChange(1);
  };

  const onDateChange = (date, dateString) => {
    setFilterDate(dateString);
    onPageChange(1);
  };

  const exportToCSV = async () => {
    if(hasDownloadPermission || role === "OWNER"){
        const data = await getExportData();
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName: "transactions", exportType });
    }
    else{
    onShowErrorAlert("", "Access is denied");


    }
  };

  const getQueryFields = () => {
    const queryFields: any = {};

    if (filterDate.length && filterDate[0].length > 0) {
      const [startDay, endDay] = filterDate;
      queryFields.startDay = startDay;
      queryFields.endDay = endDay;
    }
    if (paymentType.length > 0 && paymentType !== "all") {
      queryFields.paymentType = paymentType;
    }
    if (selectedChannel.length > 0 && selectedChannel !== "all") {
      queryFields.channel = selectedChannel;
    }
    if (searchQuery.length > 0) {
      queryFields.customerEmail = searchQuery;
    }
    return queryFields;
  };

    const debouncedGetFilteredData = debounce(async () => {
        setIsFiltering(true);
        const filterPayload = getQueryFields();
        await dispatch(
            transactionsInfo({
                filter: {
                    business: businessId,
                    pageSize: 10,
                    tnxRef: searchQuery,
                    pageNumber,
                    ...filterPayload,
                },
            }),
        );
        setIsFiltering(false);
    }, 300);

    useEffect(() => {
        debouncedGetFilteredData();
    }, [searchQuery, pageNumber]);

    useEffect(() => {
        return () => {
            debouncedGetFilteredData.cancel();
        };
    }, []);


    const onFilterButtonClick = () => {
      debouncedGetFilteredData();
  };

  const onResetButtonClick = async () => {
    setIsFiltering(true);
    setPaymentType("");
    setSelectedChannel("");
    setFilterDate([]);
    await dispatch(
      transactionsInfo({
        filter: {
          business: businessId,
          pageSize: 10,
          pageNumber: 1,
          customerEmail: searchQuery,
        },
      }),
    );
    setIsFiltering(false);
  };


  const handleRowData = (data) => {
    // to be returned
    // setState((prev) => ({...prev, openDrawer: true, record: data}));
}

const handleDataUpdate = (field, value) => {
    setState((prev) => ({
        ...prev,
        [field]: value,
    }));
};

const onRequestRefunds = () => {
    setState((prev) => ({...prev, openModal: true, openDrawer: false}))

}

const handleRequestRefund = async (data: formData) => {
    data.txRef = state.record?.refundId
    try {
        const action = await dispatch(
            InitiateRefund(data),
        );
        if (InitiateRefund.rejected.match(action)) {
            onShowErrorAlert(
                "",
                String(action.payload),
            );
        } else {
            onShowSuccessAlert(
                `Refund created successfully`,
            );
            setState((prev) => ({
                ...prev,
                openModal: false,
            }));
        }
    } catch (error) {
        return error;
    }
};




const filteredTransaction = transactionData?.filter((item) =>
    Object.values(item).some(
      (val) =>
        typeof val === "string" &&
        val.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );



  return (
    <Wrapper>
      <Loader isLoading={transactionStatus === "loading" || isFiltering || loading} />
      <Title>Transactions</Title>


      <BoxBody>
        <TitleFilter
            showFilter
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onDateChange={onDateChange}
            onSourceChange={onSourceChange}
            onMethodChange={onMethodChange}
            onCSVButtonClick={exportToCSV}
            onXLSButtonClick={exportToCSV}
            onFilterButtonClick={onFilterButtonClick}
            onResetButtonClick={onResetButtonClick}
        />

        {width > 767 ? (
          <CustomTable
            columns={tableColumns}
            extraEmptyChildren={<EmptyBalanceDescription />}
            dataSource={filteredTransaction}
            currentPage={pageNumber}
            totalItemCount={filterResult?.totalCount || 0}
            onPageChange={onPageChange}
            handleRowClick={handleRowData}
          />
        ) : (
          <MobileBody>
            {transactionData?.length ? transactionData.map((trans) => (
              <TransactionCard
                key={trans.key}
                transactionId={trans.transId}
                email={trans.email}
                source={trans.source}
                amount={trans.amount}
                status={trans.status}
                method={trans.method}
                reqDate={trans.date}
              />
            )) : 
            <EmptyMobileCard
             title="No transaction yet"
             subText="All received or sent transactions will appear here."
            />
            }
          </MobileBody>
        )}
      </BoxBody>

      <DrawerContainer open={state.openDrawer} onClose={() =>  handleDataUpdate('openDrawer', false)} title="Transaction details" placement="right">
        <Details reference={formatLongString(state?.record?.transId, 10)} amount={state?.record?.amount} 
        method={state?.record?.method || "N/A"} source={state?.record?.source}  
        customerName={state.record?.name || "N/A"}
          rate={""} date={state?.record?.date}
          customerEmail={state?.record?.email}
        status={state?.record?.status} transaction_id={state?.record?.transId} onRefundRequest={onRequestRefunds} transactionRef={state?.record?.refundId}/>

      </DrawerContainer>

      <RefundModal openModal={state.openModal} setState={setState}
       handleNext={handleRequestRefund} defaultAmount={state?.record?.newAmount}  />
    </Wrapper>
  );
};

export default Transactions;