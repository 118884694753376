import styled from "styled-components";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

type SummaryCardWrapperProps = {
  theme: string;
};

export const Title = styled.h1`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Inter"
`;

export const Subtitle = styled.p`
  font-family: "Athletics";
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  line-height: 32px;
  letter-spacing: -0.48px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #000000;
  border-radius: 8px;
  color: #ffffff;
  padding: 16px;
  max-height: 147px;
  width: 100%;
  overflow-x: auto;
  
  @media (min-width: 768px){
    width: 25%;
  }
`;

export const CollectionWrapper = styled.div`
  background: #fffbf7;
  border-radius: 8px;
  width: 100%;
  padding: 16px;
  
  @media (min-width: 768px) {
    width: 75%;
  }
`;

export const CollectionTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CollectionHeading = styled.div`
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #000000;
`;

export const Currencies = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;

  .active {
    background: #ef2c5a;
    color: #fff;
    h1 {
      color: #fff;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 1px solid #e8e4e1;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    cursor: pointer;
    color: #000000;
    font-family: "Inter";

    &:hover {
      background: #f5efe9;
    }

    h1 {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
    }
    
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    @media (min-width: 768px) {
      width: 24%;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }
`;

export const CustomCalendarWrapper = styled(RangePicker)`
  width: min(285px, 100%);
  height: 38px;
  border: 1px solid #a3a3a3 !important;
  border-radius: 8px;
  box-shadow: none;
  :hover {
    border-color: #a3a3a3 !important;
  }

  & div[class="ant-picker-input"] > input {
    font-family: "Inter";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    display: none !important;
  }

  &
    div[class="ant-picker-input"]
    > input:placeholder-shown {
    font-weight: 400;
    color: #565c69 !important;
  }

  & div[class="ant-picker-input"] > input::placeholder {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69 !important;
  }

  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
  }

  & div[class~="ant-picker-active-bar"] {
    background-color: #000000;
  }

  &.ant-picker-range {
    border: none !important;
    input {
      display: none;
    }
    span {
      display: none;
    }
  }
`;

export const SummaryCardWrapper = styled.div<SummaryCardWrapperProps>`
  padding: 24px;
  border-radius: 10px;
  min-width: 306.33px;
  max-width: 500px;
  width: 32%;
  background-color: ${({ theme }) => {
    switch (theme){
      case "primary": return "#000000";
      case 'secondary': return '#FFFBF7';
      case 'tertiary': return '#FFFFFF';
      default: return '#000000'
    }
  }};

  border: ${({ theme }) => {
    switch (theme){
      case 'primary': return '1px solid #000000';
      case 'secondary': return '1px solid #FFFBF7';
      case 'tertiary': return '1px solid #EDEDED';
      default: return '1px solid #000000'
    }
  }};
`

export const CollectionCardTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => {
    switch (theme){
      case 'primary': return '#E9E9E9';
      case 'secondary': return '#000000';
      case 'tertiary': return '#000000';
      default: return '#E9E9E9'
    }
  }};
`

export const CollectionCardFigures = styled.div`
  margin-top: 48px;
  display: flex;
  gap: 4px;
  flex-direction: column;
`

export const CollectionCardNumber = styled.p`
  color: ${({ theme }) => {
    switch (theme){
      case 'primary': return '#FFFFFF';
      case 'secondary': return '#000000';
      case 'tertiary': return '#D1D1D1';
      default: return '#FFFFFF'
    }
  }};
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 0;
`

export const CollectionCardSubline = styled.p`
  color: #8F8F8F;
  margin-bottom: 0;
`

export const InfoWrapper = styled.div`
  font-family: "Athletics";
  padding-top: 24px;
`

export const ContentBlockWrapper = styled.div`
  margin-top: 41px;
`

export const ContentHeader = styled.h2`
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  color: #000000;
`

export const ContentBody = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-family: "Inter";
`