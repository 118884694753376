import styled from "styled-components";
import { Button, Upload } from "antd";

export const Label = styled.p`
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;

  @media (max-width: 768px) {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 102px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  justify-content: center;
  font-family: "Proxima Nova", sans-serif;

  & > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-right: 8px;
  }
`;

export const RightWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  & > p {
    margin-bottom: 0;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: "Proxima Nova", sans-serif;

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 6px;
  }
  & div[class~="ant-progress-inner"] {
    background-color: #e9e9e9;
  }
  & div[class~="ant-progress-bg"] {
    background-color: #3a40dd;
    height: 4px !important;
  }
  & div[class~="ant-progress-text"] {
    display: none;
  }
`;

export const DoneText = styled.div`
  & > p {
    margin-bottom: 0;
    font-family: "Proxima Nova", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #717070;
  }
`;

export const LeftWrapper = styled.div`
  margin-right: 15px;
  & > p {
    margin-bottom: 0;
  }
`;

export const LeftNotIdle = styled.div`
  display: flex;
  align-items: center;
  & > ${Label} {
    margin: 0 6px 0 15px;
  }
  & > span {
    display: flex;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  //border-radius: 8px;
  & > div {
    width: 100%;
  }
`;

export const UploadContainer = styled.div`
  max-height: ${(props: { $isExpanded: boolean }) =>
    props.$isExpanded ? "192px" : "50px"};
  border: 1px solid #f4f4f4;
  border-radius: 8px;
  padding: 12px;
`;

export const UploadWrapper = styled(Upload)`
  width: 100%;
  display: flex;
  border: 3px dashed #dbdbdb;
  border-radius: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  gap: 99px;
  height: 62px;
  cursor: pointer;

  & > div {
    height: 100%;
  }

  & div[class~="ant-upload"] {
    width: 100%;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }
  & button:hover {
    color: black !important;
    border-color: black !important;
  }
`;

export const UploadButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  font-family: "Proxima Nova", sans-serif;

  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #464646;
  }

  & span {
    text-decoration-line: underline;
    margin-right: 5px;
  }
`;

export const UploadHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > p {
    font-family: "Proxima Nova", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #565c69;
    margin-right: 15px;
    margin-bottom: 0;
  }

  & > svg {
    position: relative;
    top: 0;
    right: 0;
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  background: #ffffff;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  transform: ${(props: { $isExpanded: boolean }) =>
    props.$isExpanded ? "none" : "rotate(-90deg)"};

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const DoneContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  flex-direction: row;
  padding: 8px 12px;
  gap: 99px;
  height: 62px;

  & > div {
    height: 100%;
  }

  & p {
    margin-bottom: 0;
  }
`;

export const DoneDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DoneLeft = styled.div`
  display: flex;
  align-items: center;
  & > p {
    margin-left: 10px;
  }
`;

export const DoneRight = styled.div`
  display: flex;
  align-items: center;
  & > p {
    color: #0eab44;
    margin-right: 10px;
  }
  & > button {
    color: #f9123b;
  }
`;