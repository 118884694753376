import api from "../../app/api";

export async function getRequiredDocuments({ businessType, country }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/requireddocument/${businessType}/${country}`,
  );
  return data;
}

export async function getRequiredDocumentWithoutCountry({ businessId }) {
    const { data } = await api.get(
      `${process.env.REACT_APP_API_URL}merchant/busdocupload/${businessId}/business/v2`,
    );
    return data;
  }

  export async function ComplainceStatus({ businessId }) {
    const { data } = await api.get(
      `${process.env.REACT_APP_API_URL}merchant/busdocupload/recently-disapproved/${businessId}`,
    );
    return data;
  }


export async function getUploadedDocuments({ businessId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/busdocupload/${businessId}/business/v2`,
  );
  return data;
}
export async function getMerchantBusiness({ businessId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/user/${businessId}`,
  );
  return data;
}

export async function getStaff({ businessId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/businessstaff/${businessId}/business`,
  );
  return data;
}

export async function getBusinessTypes() {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/businesstype/active`,
  );
  return data;
}

export async function getOrganizationType() {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/organizationtype`,
  );

  return data;
}

export async function getBusinessCategories() {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/businesscategory`,
  );
  return data;
}

export async function addBusiness(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/business/`,
    payload,
  );
  return data;
}

export async function addBusinessContact(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/businessstaff/`,
    payload,
  );
  return data;
}

export async function uploadDocument(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/busdocupload/v2`,
    payload,
  );
  return data;
}

export async function getMerchantUseCases() {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/feature`,
  );
  return data;
}

export async function getMerchantCustomerOutreach({ featureId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/featureoptions/get/${featureId}`,
  );
  return data;
}

export async function addBusinessSignupReason(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/businessinterest/`,
    payload,
  );
  return data;
}

export async function updateComplianceData(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/business/compliance`,
    payload,
  );
  return data;
}

export async function addUpdateProprietor(payload) {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}merchant/business/proprietor`,
    payload,
  );
  return data;
}

export async function getAllBusinessProprietors({ businessId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/business/proprietor/business/${businessId}`,
  );
  return data;
}

export async function getComplianceData({ userId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/business/compliance/user/${userId}`,
  );
  return data;
}

export async function getAllOrgBusinesses({ orgId }) {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}merchant/businesstype/organization/${orgId}`,
  );
  return data;
}
