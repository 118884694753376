import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import Account from "../VirtualAccount/components/Account";
import {
    StyledTransactionsTable,
    ToggleContainer,
} from "../Wallets/components/TransactionsTable/style";
import {
    getSettlementPayout,
    getSettlementDetails,
} from "./api";

import CustomTable from "../../components/common/CustomTable";
import { payoutColumns, tableColumns } from "./utils";
import { formatCurrencyAmount } from "../../utils/currency";
import { formatLongString } from "../../utils/formatString";
import { allWallets } from "../Wallets/redux/slice";
import Loader from "../../components/common/Loader";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import ToggleButton from "../../components/common/ToggleButton/ToggleButton";
import { useWindowSize } from "@reach/window-size";
import TableTools from "../../components/common/TableTools";
import EmptyVirtualAccount from "../Customers/components/EmptyVirtualAccount";
import exportFromJSON from "export-from-json";
import {
    convertToSentenceCase,
    getFirstName,
} from "../../utils/gtag";
import SettlementMobileCard from "./components/SettlementMobileCard";
import PayoutMobilecard from "./components/PayoutMobilecard";
import PayoutTransactionDetails from "./components/PayoutTransactionDetails";

interface DateProps {
    startDate: Date;
    endDate: Date;
}

const tooltipText =
    "Settlement balance gets funded with funds from your virtual accounts 1:00AM daily.";

const Settlements = () => {
    const { wallets } = useAppSelector(
        (state) => state?.wallets,
    );
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const toggleType = ["Settlements", "Payouts"];
    const dispatch = useAppDispatch();
    useState([]);
    const [data, setData] = useState<any>([]);
    const [settlementDetails, setSettlementDetails] =
        useState(null);
    const [payoutData, setPayoutData] = useState(null);
    const [date, setDate] = useState<DateProps>();
    const [search, setSearch] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState(null);

    const [loading, setLoading] = useState(false);
    const [allWallet, setAllWallet] = useState<any>();
    const [settlemetData, setSettlementData] = useState(null);
    const { businessId, userId } = useAppSelector(
        (state) => state?.users,
    );

    const {
        business,
      } = useAppSelector((state) => state.settings);

    const balance = wallets?.find(
        (curr) => curr.currency === "NGN",
    );

    const startDate =
        date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
    const endDate =
        date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

    const showDrawer = () => {
        setOpen(true);
    };

    const searchParams = new URLSearchParams(
        location.search,
    );
    const activeParam = searchParams.get("");
    const initialActiveToggle =
        activeParam && toggleType.includes(activeParam)
            ? activeParam
            : "Settlements";

    const [activeToggle, setActiveToggle] = useState(
        initialActiveToggle,
    );

    useEffect(() => {
        if (businessId && userId) {
            dispatch(allWallets({ businessId }));
        }
    }, [businessId, userId]);

    useEffect(() => {
        setActiveToggle(initialActiveToggle);
    }, [initialActiveToggle]);

    
    const getPayoutDetails = async () => {
        setLoading(true);
        try {
          const res = await getSettlementPayout({
            walletId: balance?.id,
            product: "VirtualAccount"
          });
      
          const formattedTableData = await Promise.all(
            res?.foreignBankTransferRequests?.map(async (data) => {
              const {
                request_id: key,
                requestId: transaction_id,
                destinationCurrency,
                amount,
                sourceCurrency,
                fee,
                accountHolderName,
                bankName,
                createdAt
              } = data;
      
              const formattedTransactionId = formatLongString(transaction_id, 15);
              const formattedAmountPaid = formatCurrencyAmount(destinationCurrency, amount);
              const formattedFees = formatCurrencyAmount(sourceCurrency, fee);
              const payoutCurrency = sourceCurrency;
              const formattedRate = Number(data?.rate || 1).toFixed(data?.destinationCurrency === 'NGN' ? 2 : 6);
      
              let totalDebit;
              if (data?.rate > 1) {
                const calculatedTotal = amount * data?.rate + fee;
                totalDebit = formatCurrencyAmount(sourceCurrency, calculatedTotal);
              } else {
                const totalAmountWithFee = amount + fee;
                totalDebit = formatCurrencyAmount(sourceCurrency, totalAmountWithFee);
              }
      
              const beneficiary = getFirstName(accountHolderName || business?.name || 'N/A');
              const date = dayjs(createdAt).format("DD.MM.YYYY - HH:mm");
              const accountName = accountHolderName || business?.name || "N/A";
              const accountNumber = data.accountNumber || "N/A";
              const bankNameFormatted = bankName || "N/A";
              const transactionRef = data.requestId || "N/A";
      
              return {
                key,
                transaction_id: formattedTransactionId,
                request: transaction_id,
                amount_paid: formattedAmountPaid,
                currency: sourceCurrency,
                fees: formattedFees,
                payoutCurrency,
                rate: formattedRate,
                total_debit: totalDebit,
                beneficiary,
                date,
                account_name: accountName,
                account_number: accountNumber,
                bank_name: bankNameFormatted,
                transactionRef
              };
            })
          );
      
          setPayoutData(formattedTableData);
          setPageCount(res);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          return error;
        }
      };
      

    const filter = () => {
        getSettlement();
    };

    const handleReset = async () => {
        setDate(null);
    };

    const onPageChange = (page: number) => {
        setPageNumber(page);
    };

    const getSettlement = async () => {
        const data = {
            startDate: startDate,
            endDate: endDate,
            pageNumber: pageNumber,
            pageSize: 10,
            walletId: balance?.id,
            virtualAccountSettlementStatus: 0,
            businessId,
        };
        setLoading(true);

        try {
            const res = await getSettlementDetails(data);
            setLoading(false);
            setSettlementData(res);
            const tableData =
                res?.virtualAccountCollectionSettlements
                    .filter(
                        (data) =>
                            data.virtualAccountSettlementStatus ===
                            "SETTLEMENT",
                    )
                    .map((data) => ({
                        key: data.id,
                        transaction_id: formatLongString(
                            data.reference,
                            20,
                        ),
                        source: "Virtual account",
                        transaction_type:
                            convertToSentenceCase(
                                data.virtualAccountSettlementStatus,
                            ),
                        currency: data.currency,
                        amount: formatCurrencyAmount(
                            data.currency,
                            data.amount,
                        ),
                        date: dayjs(data.createdAt).format(
                            "DD.MM.YYYY - HH:mm",
                        ),
                    }));

            setAllWallet(tableData);
            setData(res);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (businessId !== null) {
            if (activeToggle === "Settlements") {
                getSettlement();
            }
            if (activeToggle === "Payouts") {
                getPayoutDetails();
            }
        }
    }, [activeToggle, pageNumber]);


    

    const getExportData = async () => {
        return settlemetData?.virtualAccountCollectionSettlements?.map((data) => ({
            "Transaction Id": 
                data.reference,
            "Source": "Virtual account",
            "Transaction type":
                convertToSentenceCase(
                    data.virtualAccountSettlementStatus,
                ),
            "Currency": data.currency,
            "Amount": data?.amount + " " + data?.currency,
            "Date": dayjs(data.createdAt).format(
                "DD.MM.YYYY - HH:mm",
            ),
        }));
    };

    const exportToXLS = async () => {
        const data = await getExportData();
        const exportType = exportFromJSON.types.xls;
        exportFromJSON({
            data,
            fileName: "Settlement transactions",
            exportType,
        });
    };

    const exportToCSV = async () => {
        const data = await getExportData();
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({
            data,
            fileName: "Settlement transactions",
            exportType,
        });
    };

    const handleRowClick = (row: any) => {        
        setSettlementDetails(row);
        showDrawer();
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleToggle = (item) => {
        setActiveToggle(item);
        navigate(`/settlements?=${item}`);
    };


    const filteredTransaction = allWallet?.filter((item) =>
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(search.toLowerCase())
        )
      );



    return (
        <div>
            <Account
                title="Settlement balance"
                balance={formatCurrencyAmount(
                    "NGN",
                    balance?.settlementBalance?.toFixed(
                        2,
                    ) || 0,
                )}
                isSettlement
                disabled={
                    balance?.settlementBalance <= 0 ||
                    balance === undefined
                }
                tooltipText={tooltipText}
            />

            <StyledTransactionsTable>
                <h3>Transactions</h3>
                {allWallet?.length > 0 && (
                    <div>
                        {activeToggle !== "Payouts" && (
                            <TableTools
                                placeHolder="Search"
                                onSearchChange={(e) =>
                                    setSearch(
                                        e.target.value,
                                    )
                                }
                                onFilterButtonClick={filter}
                                onResetButtonClick={
                                    handleReset
                                }
                                onDateChange={(date) =>
                                    setDate(date)
                                }
                                onXLSButtonClick={
                                    exportToXLS
                                }
                                onCSVButtonClick={
                                    exportToCSV
                                }
                            />
                        )}
                    </div>
                )}
                <ToggleContainer>
                    <ToggleButton
                        toggleType={toggleType}
                        activeToggle={activeToggle}
                        setActiveToggle={setActiveToggle}
                        handleToggle={handleToggle}
                    />
                </ToggleContainer>
                {activeToggle === "Settlements" && (
                    <>
                        {width > 767 ? (
                            <CustomTable
                                columns={tableColumns}
                                dataSource={filteredTransaction}
                                currentPage={pageNumber}
                                totalItemCount={
                                    data?.totalCount
                                }
                                onPageChange={onPageChange}
                                extraEmptyChildren={
                                    <EmptyVirtualAccount
                                        title="No transactions yet"
                                        paragraph="All received or sent transactions will appear here."
                                    />
                                }
                            />
                        ) : (
                            <>
                                {allWallet?.map((data) => (
                                    <SettlementMobileCard
                                        key={data.key}
                                        transactionRef={formatLongString(
                                            data.transaction_id,
                                            10,
                                        )}
                                        source="Virtual account"
                                        transactionType={
                                            data.transaction_type
                                        }
                                        currency={
                                            data.currency
                                        }
                                        amount={data.amount}
                                        date={data.date}
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}

                {activeToggle === "Payouts" && (
                    <>
                        {width > 767 ? (
                            <CustomTable
                                columns={payoutColumns}
                                dataSource={payoutData}
                                currentPage={pageNumber}
                                handleRowClick={
                                    handleRowClick
                                }
                                totalItemCount={
                                    pageCount?.totalElements
                                }
                                onPageChange={onPageChange}
                                extraEmptyChildren={
                                    <EmptyVirtualAccount
                                        title="No transactions yet"
                                        paragraph="All received or sent transactions will appear here."
                                    />
                                }
                            />
                        ) : (
                            <>
                                {payoutData?.map((data) => (
                                    <PayoutMobilecard
                                        key={data.id}
                                        transactionRef={formatLongString(
                                            data.transaction_id,
                                            10,
                                        )}
                                        payoutAmount={
                                            data.amount_paid
                                        }
                                        transferFees={
                                            data.fees
                                        }
                                        rate={data.rate}
                                        totalDebit={
                                            data.total_debit
                                        }
                                        beneficiary={
                                            data.beneficiary
                                        }
                                        date={data.date}
                                        payoutCurrency={
                                            data.currency
                                        }
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </StyledTransactionsTable>
            <Loader isLoading={loading} />

            <DrawerContainer
                title="Payout details"
                onClose={onClose}
                open={open}
                placement={"right"}
            >
                <PayoutTransactionDetails
                    heading="Details"
                    transactionId={
                        settlementDetails?.transaction_id
                    }
                    request={settlementDetails?.request}
                    transactionAmount={
                        settlementDetails?.amount_paid
                    }
                    currency={settlementDetails?.payoutCurrency}
                    transferFee={settlementDetails?.fees}
                    rate={settlementDetails?.rate || 'N/A'}
                    totalDebit={
                        settlementDetails?.total_debit
                    }
                    date={settlementDetails?.date}
                />
                <br />

                <PayoutTransactionDetails
                    heading="Beneficiary"
                    bankName={settlementDetails?.bank_name}
                    accountName={
                        settlementDetails?.account_name
                    }
                    accountNumber={
                        settlementDetails?.account_number
                    }
                    isBeneficiary
                />
            </DrawerContainer>
        </div>
    );
};

export default Settlements;
