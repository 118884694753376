import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../app/api";
import { InputProps } from "../types";

export const adminLogin = createAsyncThunk(
    "auth/adminLogin",
    async (
        { username, password }: InputProps,
        { rejectWithValue }
    ) => {
        try {
            const response = await api.post(`/auth/account/speciallogin`, {
                username,
                password,
            });

            if (response.data?.token) {
                localStorage.setItem("token", response.data.token);
            }

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data?.message || "Login failed");
        }
    }
);
