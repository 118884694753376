import React, { useContext, useEffect, useState } from "react";
import { Content, Wrapper, UploadContainer } from "./style";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { merchantBusinessDetail } from "../../../pages/Dashboard/dashboardSlice";
import {
  getRequiredDocumentsByType,
  getUploadedDocumentsByBusinessId,
  uploadRequiredDocument,
} from "../../../pages/Compliance/complianceSlice";
import Loader from "../../common/Loader";
import UploadHeader from "../../compliance/Steps/components/UploadInfo";
import SelfCustomUpload from "../../common/CustomUpload/SelfCustomUpload";
import { stringToSentenceCase } from "../../../utils/format";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { getCountryValue } from "../../compliance/utils";

const Verification = () => {
  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const [updatedDocumentsList, setUpdatedDocumentsList] = useState([]);
  const { onShowAlert } = useContext(SuccessAlertContext);
  const [uploadDetails, setUploadDetails] = useState([]);

  const { status: businessStatus, businessId } = useAppSelector(
    (state) => state.users,
  );
  const { business } = useAppSelector((state) => state.settings);

  const {
    status: docStatus,
    documents,
    uploadedDocuments,
  } = useAppSelector((state) => state.compliance);

  useEffect(() => {
    if (businessId) {
      if (Object.keys(business).length < 1) {
        dispatch(merchantBusinessDetail(businessId));
      }
      dispatch(getUploadedDocumentsByBusinessId({ businessId }));
    }
  }, [businessId]);

  useEffect(() => {
    if (business && Object.keys(business).length) {
      dispatch(
        getRequiredDocumentsByType({
          businessType: business.businessType,
          country: getCountryValue(business.country),
        }),
      );
    }
  }, [business]);

  useEffect(() => {
    if (documents?.length) {
      const updatedRequiredDocuments = documents.map((doc) => {
        const uploadedDocs = uploadedDocuments.filter(
          (upDoc) => upDoc.businessDocument.id === doc.businessDocument.id,
        );
        const uploadedDocsLength = uploadedDocs.length;
        if (uploadedDocs && uploadedDocsLength) {
          return uploadedDocs[uploadedDocsLength - 1];
        }
        return doc;
      });
      setUpdatedDocumentsList(updatedRequiredDocuments);
    }
  }, [documents, uploadedDocuments]);

  const uploadDocumentURL = async (downUrl, busDocId) => {
    const payload = {
      businessDocument: busDocId,
      url: downUrl,
      approved: false,
      business: businessId,
      documentApprovalStatus: "PENDING",
    };
    try {
      await dispatch(uploadRequiredDocument(payload)).unwrap();
      onShowAlert("The document has been updated successfully");
    } catch (err) {
      onShowErrorAlert(err, err.message);
    }
  };

  const onUploadDocumentDone = async (newDoc: any) => {
    const newDetails = [...uploadDetails];
    const detailsIndex = uploadDetails.findIndex((det) => det.id === newDoc.id);
    if (detailsIndex !== -1) {
      newDetails.splice(detailsIndex, 1, newDoc);
      setUploadDetails(newDetails);
    }
  };

  return (
    <Wrapper>
      <Loader isLoading={businessStatus === "loading" || docStatus === "loading"} />

      <Content>
        {updatedDocumentsList.map((busType) => {
          return ["document", "upload"].includes(
            busType.businessDocument.type,
          ) ? (
            <UploadContainer key={busType.businessDocument.id}>
              <UploadHeader
                label={busType.businessDocument.description}
                title={
                  busType.businessDocument.document
                    ? stringToSentenceCase(busType.businessDocument.document)
                    : ""
                }
              />
              <SelfCustomUpload
                onUpload={onUploadDocumentDone}
                docId={busType.businessDocument.id}
                docName={busType.businessDocument.document}
                approvalStatus={busType?.documentApprovalStatus}
                uploadPath={`verification/documents/${busType.businessDocument.id}/`}
                onUploadFinish={uploadDocumentURL}
              />
            </UploadContainer>
          ) : null;
        })}
      </Content>
    </Wrapper>
  );
};

export default Verification;
