import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import firebaseApp from "../../app/firebase";
import { addYears, isBefore } from 'date-fns'
import { Dispatch, SetStateAction } from "react";

export const uploadImage = ({
    file,
    docName,
    setDocumentExistStatus,
    businessId,
    setFileType,
    setFileName,
    setUploadingStatus,
    setUploadingProgress,
    setDocumentUrl,
    uploadPath,
    onFinish,
    imageId,
  }: {
    businessId: number;
    file: any;
    docName: string;
    imageId?: string;
    uploadPath?: string;
    onFinish?: (docUrl: any) => void;
    setDocumentExistStatus?: Dispatch<SetStateAction<string>>;
    setFileType?: Dispatch<SetStateAction<string>>;
    setFileName?: Dispatch<SetStateAction<string>>;
    setUploadingStatus?: Dispatch<SetStateAction<string>>;
    setDocumentUrl?: Dispatch<SetStateAction<string>>;
    setUploadingProgress?: Dispatch<SetStateAction<number>>;
  }) => {
    setDocumentExistStatus("idle");
    const storage = getStorage(firebaseApp);
  
    const splitFileName = file?.name?.split(".");
    const docFileType = splitFileName[splitFileName?.length - 1];
    setFileType(docFileType);
    setFileName(`${docName}.${docFileType}`);
    const storageRef = ref(
      storage,
      `${
        uploadPath || "bulk/invoice/documents/"
      }${businessId}/${imageId}`,
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploadingStatus("progress");
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        const progressToTwoDec = Number(progress.toFixed(2));
        setUploadingProgress(progressToTwoDec);
      },
      (error) => {
        setUploadingStatus("failed");
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            if (setDocumentUrl) {
              setDocumentUrl(downloadURL);
            }
            if (onFinish) {
              onFinish(downloadURL);
            }
          })
          .then(() => {
            setUploadingStatus("done");
          });
      },
    );
  };
  

export const getCountryValue = (country) => {
  if (
    country === "NG" ||
    country === "SA" ||
    country === "KE" ||
    country === "ZM" ||
    country === "TZ" ||
    country === "UG"
  ) {
    return country;
  } else {
    return "INTERNATIONAL";
  }
};


export const validateDOB = (value) => {
    const today = new Date();
    const minDate = addYears(today, -18);

    return isBefore(new Date(value), minDate) || 'Age must be at least 18 years.';
  };