import React from "react";
import { Wrapper } from "./style";
import { ReactComponent as ErrorIcon } from "../../assets/icons/alert-circle.svg";

const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <Wrapper>
      <ErrorIcon />
      <span>{message}</span>
    </Wrapper>
  );
};

export default ErrorMessage;
