import { useEffect, useState } from 'react';
import countryList from 'react-select-country-list';

const useCountryOptions = () => {
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCountryOptions = async () => {
      try {
        const countries = await countryList().getData();
        const filteredCountries = countries.filter(country => country.label !== 'Nigeria');
        const options = filteredCountries.map(country => ({
          value: country.value,
          label: country.label,
        }));
        setCountryOptions(options);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchCountryOptions();

    return () => {};
  }, []);

  return countryOptions;
};

export default useCountryOptions;
