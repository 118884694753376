import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormInputDiv } from "../../../../forms/Signup/style";
import Select from "react-select";
import { usePostHog } from 'posthog-js/react'
import {
    Content,
    Section,
    Wrapper,
    ErrorText,
    TextAreaContainer,
    InputContainer,
} from "./style";
import { Input as AntInput, Tooltip } from "antd";
import {
    getKlashaBusinessCategories,
    getKlashaBusinessTypes,
    getAllBusinessInOrganization
} from "../../../../../pages/Compliance/complianceSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { StepOneFormInputs } from "../../../types";
import Loader from "../../../../common/Loader";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { getOrganizationType } from "../../../../../pages/Compliance/complianceAPI";
import { gtagEvent } from "../../../../../utils/gtag";
import { Input } from "../../../../common/Input";
import { ReactComponent as ErrorIcon } from "../../../../../assets/icons/alert-circle.svg";
import CustomSelect from "../../../../common/CustomSelect";
import { InputPhone } from "../../../../common/InputPhone";
import { addUpdateMerchantBusiness } from "../../../../../pages/Settings/settingsSlice";
import { Button } from "../../../../common/Button";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info2.svg";
import countryList from "react-select-country-list";
import BusinessLogoUpload from "./BusinessLogoUpload";

interface Props {
    onSave: () => void;
    setSelectedBusinessType: (data: string) => void
}

const StepOne = ({ onSave, setSelectedBusinessType }: Props) => {
    const [busTypes, setBusTypes] = useState();
    const [busCategory, setBusCategory] = useState([]);
    const [organizationType, setOrganizationType] = useState([]);
    const [fileUrl, setFileUrl] = useState<any>(null)
    const [getBus, setGetBus] = useState<string>();
    const [loading, setLoading] = useState(false);
    const posthog = usePostHog();

    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const { userId, businessId, } = useAppSelector((state) => state.users);
  
   
    const { business } = useAppSelector((state) => state.settings);
    const { users } = useAppSelector((state) => state);


    const { username, email } = users?.userObj || ''


    useEffect(() => {
        if (localStorage.getItem("business")) {
            setGetBus(localStorage.getItem("business"));
        }
    }, []);

    const validationRegex = {
        website: /^(www\.[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,})$/i,
        socialMedia: /^(https?:\/\/)?(www\.)?(facebook|twitter|instagram|linkedin)\.com\/\w+$/,
    };

    const options = useMemo(() => {
        const allCountries = countryList().getData();
        return allCountries.map((country) => ({
            label: country.label,
            value: country.value,
        }));
    }, []);


     

    const {
        handleSubmit,
        control,
        formState,
        watch,
        formState: { errors },
    } = useForm<StepOneFormInputs>({
        defaultValues: {
            businessType: business?.businessType,
            businessCategory: business?.businessCategory,
            businessName: business?.name,
            businessEmail: business?.email || email,
            phoneNumber: business?.phone || username,
            country: business?.country,
            businessDescription: business?.businessDescription,
            streetAddress: business?.address,
            website: business?.website,
            organizationType: business?.organizationType,
            categoryName: business?.otherBusinessCategory,
            state: business?.state,
            city: business?.city,
        },
    });

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getKlashaBusinessTypes());
    }, []);

    
    useEffect(() => {
        dispatch(getKlashaBusinessCategories())
            ?.then((data) => {
                const { payload } = data;
                    const customSort = (a, b) => {
                    if (a.label === "Other" && b.label !== "Other") {
                        return 1; 
                    } else if (a.label !== "Other" && b.label === "Other") {
                        return -1;
                    } else {
                        return a.label.localeCompare(b.label); 
                    }
                };
                    const categories = payload.map((data) => ({
                    label: data.name,
                    value: data.id
                }));
    
                const sortedCategories = [...categories].sort(customSort);
                    setBusCategory(sortedCategories);
            })
            .catch((error) => {
                console.log("Error fetching business categories: ", error);
            });
    }, []);
    

    const updateBusinessName = (data) => {
        if (data === "Registered") {
            return "Registered Business";
        }
        if (data === "Registered Businesss") {
            return "Registered Business";
        }
        return data;
    };

    useEffect(() => {
        const secondOrgType = organizationType?.find(
            (type) => type.label === "Registered Business",
        );
        dispatch(getAllBusinessInOrganization({ orgId: secondOrgType?.value }))
            ?.then((data) => {
                const options = data.payload
                    .filter((ty) => ty.name !== "Others")
                    .map((type) => ({
                        label: type.name,
                        value: type.id,
                    }));
                setBusTypes(options);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [organizationType]);

    useEffect(() => {
        async function fetchData() {
            try {
                const type = await getOrganizationType();
                const updateType = type
                    .filter((ty) => ty.name !== "Others")
                    .map((type) => ({
                        label: updateBusinessName(type.name),
                        value: type.id,
                    }));
                setOrganizationType(updateType);
            } catch (error) {
                console.error("Error fetching organization type:", error);
            }
        }
        fetchData().catch((error) => {
            console.error("Error in fetchData:", error);
        });
    }, []);
  

    const onTrySubmit = async (data, onFinish?: () => void) => {
        try {
            setLoading(true);
    
            const businessPayload = {
                id: undefined,
                enabled: true,
                businessType: data.businessType,
                businessCategory: data.businessCategory,
                name: data.businessName,
                email: data.businessEmail,
                phone: data.phoneNumber,
                country: data.country.value,
                city: data.city,
                businessDescription: data?.businessDescription,
                state: data.state,
                address: data.streetAddress,
                website: data.website,
                organizationType: data.organizationType,
                owner: userId,
                complianceStatus: "STEP2",
                approved: false,
                customisationLink: fileUrl,
                otherBusinessCategory: data.categoryName,
            };
            if (businessId) {
                businessPayload.id = businessId;
            }
                const action = await dispatch(addUpdateMerchantBusiness(businessPayload));
                if (addUpdateMerchantBusiness.fulfilled.match(action)) {
                posthog.capture('merchant_business_creation', {
                    userId: userId,
                    businessId: businessPayload.id,
                    businessType: businessPayload.businessType,
                    businessCategory: businessPayload.businessCategory,
                    location: {
                        country: businessPayload.country,
                        city: businessPayload.city,
                        address: businessPayload.address,
                    },
                    timestamp: new Date().toISOString(),
                });
    
                onFinish();
            } else if (addUpdateMerchantBusiness.rejected.match(action)) {
                onShowErrorAlert("", String("An error occured please reach out to support"));
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setLoading(false);
        }
    };
    

    const onNext: SubmitHandler<StepOneFormInputs> = async (data) => {
        await onTrySubmit(data, onSave);

    };

    const selectedOrganizationType = watch<any>("organizationType");


    const handleBusinessType = (data) => {
        if (data === 1) {
            setSelectedBusinessType("Registered Business")
        }
        else if (data === 2) {
            setSelectedBusinessType("Starter Business")
        }
    }    
    return (
        <Wrapper onSubmit={handleSubmit(onNext)}>
            <Loader isLoading={loading} />
            <Section>
    </Section>
            <Content>
                <Section>
                    <div>
                        <Controller
                            name="businessName"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="Business name"
                                    value={field.value}
                                    onChange={field.onChange}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                />
                            )}
                        />

                        {errors.businessName?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Field is required</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                <Section>
                    <div>
                        <Controller
                            name="organizationType"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <CustomSelect
                                    defaultValue={getBus}
                                    placeholder="Type of organisation"
                                    options={organizationType}
                                    $height="40px"
                                    $width="100%"
                                    field={field}
                                    onChange={handleBusinessType}
                                />
                            )}
                        />
                        {errors.organizationType?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Select type of organisation</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                {selectedOrganizationType === 1 && (
                    <Section>
                        <div>
                            <Controller
                                name="businessType"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CustomSelect
                                        placeholder="Type of registered business"
                                        options={busTypes}
                                        $height="40px"
                                        $width="100%"
                                        field={field}
                                    />
                                )}
                            />
                            {errors.businessType?.type === "required" && (
                                <ErrorText>
                                    <ErrorIcon />
                                    <span>Select type of registered business</span>
                                </ErrorText>
                            )}
                        </div>
                    </Section>
                )}




                <Section>
                    <div>
                        <Controller
                            name="businessCategory"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => {
                    

                                return (
                                    <CustomSelect
                                        options={busCategory}
                                        $height="40px"
                                        $width="100%"
                                        field={field}
                                        placeholder="Business Category"
                                    />
                                );
                            }}
                        />
                        {errors.businessCategory?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Select business Category</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                <Section>
                    <div>
                        <Controller
                            name="country"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={options}
                                    placeholder="Country of registration"
                                    isSearchable
                                    filterOption={({ label }, inputValue) =>
                                        label.toLowerCase().startsWith(inputValue.toLowerCase())
                                    }
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "pink",
                                            primary: "none",
                                        },
                                    })}
                                />
                            )}
                        />
                        {errors.country && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Select country of registration</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                <Section>
                    <div>
                        <Controller
                            name="state"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="State/Province/Region"
                                    value={field.value}
                                    onChange={field.onChange}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                />
                            )}
                        />

                        {errors.state?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>State is required</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                <Section>
                    <div>
                        <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="City"
                                    value={field.value}
                                    onChange={field.onChange}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                />
                            )}
                        />
                    </div>
                </Section>
                <Section>
                    <div>
                        <Controller
                            name="streetAddress"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="Business address"
                                    value={field.value}
                                    onChange={field.onChange}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                    tooltipText="This should be your current business address, not your home or PO box address. "
                                />
                            )}
                        />
                        {errors.streetAddress?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Field is required</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>

                <Section>
                    <h1 className="business-logo-copy">Add your business logo (optional)</h1>
                    <p className="business-logo-text">Your business logo will be included on all transaction receipts issued to your customers.</p>
                <div>
        <Controller
          name="businessUrl"
          control={control}
          render={({ field }) => (
            <BusinessLogoUpload 
              onFileChange={(data) => setFileUrl(data)}
              setFileUrl={setFileUrl}
              fileUrl={fileUrl}
            />
          )}
        />
      </div>
                </Section>

                <Section>
                    <TextAreaContainer>
                        <Controller
                            name="businessDescription"
                            control={control}
                            rules={{
                                required: true,
                            }}
                            render={({ field }) => (
                                <InputContainer>
                                    <AntInput.TextArea
                                        rows={5}
                                        placeholder="Business description"
                                        {...field}
                                    />

                                    <Tooltip
                                        overlayClassName="input-tooltip"
                                        placement="right"
                                        title="Tell us more about your business, products, and services."
                                        trigger={["hover", "click"]}
                                    >
                                        <InfoIcon />
                                    </Tooltip>
                                </InputContainer>
                            )}
                        />
                        {errors.businessDescription?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Field is required</span>
                            </ErrorText>
                        )}
                    </TextAreaContainer>
                </Section>

                
                <h1>Contact details</h1>
                <FormInputDiv
                    $hasError={["required"].indexOf(errors.phoneNumber?.type) > -1}
                >
                    <Controller
                        name="phoneNumber"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        defaultValue={email}
                        render={({ field }) => (
                            <InputPhone
                                label="Phone number"
                                field={{ ...field }}




                            />
                        )}
                    />
                    {errors.phoneNumber && (
                        <span>Phone number is required</span>
                    )}
                </FormInputDiv>

                <Section>
                    <div>
                        <Controller
                            name="businessEmail"
                            control={control}
                            rules={{
                                required: true,
                                pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="Email address"
                                    value={field.value}
                                    onChange={field.onChange}
                                    defaultValue={formState.defaultValues.businessEmail}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                />
                            )}
                        />
                        {errors.businessEmail?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Field is required</span>
                            </ErrorText>
                        )}
                        {errors.businessEmail?.type === "pattern" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Please enter a valid email address</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>


                <Section>
                    <div>
                        <Controller
                            name="website"
                            control={control}
                            rules={{
                                required: true,
                                pattern: validationRegex.website,

                            }}
                            render={({ field }) => (
                                <Input
                                    type="text"
                                    label="Website/social media"
                                    value={field.value}
                                    onChange={field.onChange}
                                    labelColor="#FFFFFF"
                                    height="40px"
                                />
                            )}
                        />

                        {errors.website?.type === "required" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Website/social media is required</span>
                            </ErrorText>
                        )}
                        {errors.website?.type === "pattern" && (
                            <ErrorText>
                                <ErrorIcon />
                                <span>Please enter a valid website or social media address. It should start with www and have a valid domain extension such as .com, .org, .net, etc.</span>
                            </ErrorText>
                        )}
                    </div>
                </Section>
            </Content>

            <Button
                label="Continue"
                type="submit"
                theme="primary"
            />
        </Wrapper>
    );
};

export default StepOne;
