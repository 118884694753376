import React from "react";
import { Body, Header, SubTitle, Title, Wrapper } from "./style";
import keyToIconUrl from "./utils";

interface CheckoutCardProps {
  url: string;
  // id: "magento" | "flutter" | "ios" | "opencart" | "android" | "woocommerce";
  id: string;

  title: string;
  description: string;
}

const CheckoutCard = ({ id, url, title, description }: CheckoutCardProps) => (
  <Wrapper href={url} target="_blank" rel="noreferrer">
    <Header>
      <img src={keyToIconUrl[id]} />
    </Header>
    <Body>
      <Title>
        <p>{title}</p>
      </Title>
      <SubTitle>{description}</SubTitle>
    </Body>
  </Wrapper>
);

export default CheckoutCard;
