import dayjs from "dayjs"
import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import exportFromJSON from "export-from-json";


export const tableColumns = [
    {
      title: "Refund ID",
      dataIndex: "transId",
      key: "transId",
      fixed: "left",
    },
    {title: "Transaction Ref", dataIndex: "transactionRef", key: "transactionRef"},
    { title: "Currency", dataIndex: "currency", key: "currency" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Date - Time",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => <TransactionStatus text={text} />,
    },
  ];

  export const filterOption = [
    {label: 'All', value: 'all'},
    {label: 'Successful', value: 'successful'},
    {label: 'Pending', value: 'pending'},
    {label: 'Failed', value: 'failed'},
  ]

export const updateStatus = (data) => {
    if(data === 'completed'){
        return 'successful'
    }
    return data

}

export const updateFilterStatus = (data) => {
    if(data === 'successful'){
        return 'completed'
    }
    
    if(data === null){
        return ''
    }
    return data

}



export const getExportData = async (data) => {
    return data?.refunds.map(
        (item) => ({
            REFUND_ID: item.refundRef || "--",
                    ACCOUNT_NAME:
                        item?.beneficiaryDetails
                            ?.accountName || "--",
                    ACCOUNT_NUMBER:
                        item?.beneficiaryDetails
                            ?.accountNumber || "--",
                    BANK:
                        item?.beneficiaryDetails?.bank ||
                        "--",
                    CURRENCY: item.currency || "--",
                    AMOUNT: item.currency  + " " + item.amount,
                                            date:
                        dayjs(item.createdAt).format(
                            "DD.MM.YYYY - HH:mm",
                        ) || "--",
                    STATUS:
                        updateStatus(item.status) || "--",
            TRANSACTION_REF: item.txRef
           
        }),
    );
};

export const exportData = async (fileType, fileName, refundAPI) => {
    const data = await refundAPI();
    let exportType;

    if (fileType === 'csv') {
        exportType = exportFromJSON.types.csv;
    } else if (fileType === 'xls') {
        exportType = exportFromJSON.types.xls;
    } else {
        throw new Error('Unsupported file type');
    }

    exportFromJSON({ data, fileName, exportType });
};

export const handleExportCSV = async (refundAPI) => {
    await exportData('csv', 'refunds', refundAPI);
};

export const handleExportXLSX = async (refundAPI) => {
    await exportData('xls', 'refunds', refundAPI);
};



export const format = (value) => {
    const formattedValue = value
        .replace(/[^\d]/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedValue;
};