import styled from "styled-components";
import { ReactComponent as Success } from '../../../../../assets/icons/success-icon.svg'

export const Wrapper = styled.div`
  width: 50%;
  margin: auto;
  button {
    margin-top: 20px;
  }
  
`;

export const ReuploadCopy = styled.h3`
  margin-top: 10px;
  font-family: Athletics;
`;


export const ModalWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center; /* Corrected property name */
    
    h1 {
        color: var(--shades-black, #000);
        text-align: center;
        font-family: Athletics;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.48px;
    }
    
    p {
        color: var(--shades-black-500, #767676);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`;


export const UploadWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 35vh;
  overflow-x: scroll;
`;

export const Heading = styled.h1`
  color: var(--black, #000);
  font-family: Athletics;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
`;

export const ReuploadContent = styled.form`
  width: 50%;
  margin: auto;
  h1 {
    color: var(--black, #000);
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }
  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: min(790px, 100%);
  }
`;

export const Line = styled.div`
  border-bottom: 3px solid #000;
  margin-bottom: 20px;
`;

export const Content = styled.form`
  width: 100%;
  @media (min-width: 768px) {
    width: 90%;
  }
  @media (min-width: 1024px) {
    width: min(790px, 100%);
  }
`;

export const FormContent = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const Options = styled.div`
  display: flex;
  margin-bottom: 0;
  align-items: flex-end;
  & > * {
    margin-bottom: 16px;
    width: 100%;
  }
  @media (min-width: 768px) {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;
    & > * {
      width: min(48%, 359px);
    }
    & > *:first-child {
      margin-right: 24px;
    }
  }
`;

export const UploadContainer = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 40px;
  }`

export const DocumentUploadCopy = styled.p`
color: var(--shades-black, #000);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 450;
line-height: 16px;
position: relative;
top: 7px;  
`;


export const Icon = styled(Success)`
    display: block;
    margin: 0 auto;
`;