import React, { ReactNode } from "react";
import { Progress } from "antd";
import {
  UploadButton,
  UploadWrapper,
  Label,
  UploadDiv,
  ProgressWrapper,
  RightWrapper,
  DoneText,
  LeftWrapper,
  LeftNotIdle,
} from "./style";
import { ReactComponent as UpArrow } from "../../../assets/icons/arrow-up.svg";
import { ReactComponent as DocumentIcon } from "../../../assets/icons/document.svg";


type ProgressStatus = "idle" | "progress" | "done" | "failed";
interface CustomUploadProps {
  name?: string;
  customRequest?: any;

  uploadingStatus?: ProgressStatus;
  progressPercent?: number;
  doneText?: ReactNode;
  approvalStatus?: string;
}

interface UploadLeftProps {
  uploadingStatus?: ProgressStatus;
}

interface UploadRightProps {
  uploadingStatus?: ProgressStatus;
  progressPercent?: number;
  doneText?: string | ReactNode;
}

export const UploadLeft = ({ uploadingStatus }: UploadLeftProps) => {
  return (
    <LeftWrapper>
      {uploadingStatus === "idle" && <Label>Select a file</Label>}
      {["done", "progress"].includes(uploadingStatus) && (
        <LeftNotIdle>
          <DocumentIcon />
          <Label>Document</Label>
        </LeftNotIdle>
      )}
    </LeftWrapper>
  );
};

export const UploadRight = ({
  progressPercent,
  uploadingStatus,
  doneText = "Pending approval",
}: UploadRightProps) => {
  return (
    <RightWrapper>
      {uploadingStatus === "idle" && (
        <UploadDiv>
          <span>Upload</span>
          <UpArrow />
        </UploadDiv>
      )}
      {uploadingStatus === "progress" && (
        <ProgressWrapper>
          <Progress percent={progressPercent} showInfo={false} />
          <p>{progressPercent}%</p>
        </ProgressWrapper>
      )}
      {uploadingStatus === "done" && (
        <DoneText>
          <p>{doneText}</p>
        </DoneText>
      )}
    </RightWrapper>
  );
};

export const CustomUpload = (props: CustomUploadProps) => {
  return (
    <UploadWrapper
      {...props}
      accept=".doc,.docx,image/*,.pdf"
      disabled={props.approvalStatus === "APPROVED"}
    >
      <UploadButton>
        <UploadLeft uploadingStatus={props.uploadingStatus} />
        <UploadRight
          progressPercent={props.progressPercent}
          uploadingStatus={props.uploadingStatus}
          doneText={props.doneText}
        />
      </UploadButton>
    </UploadWrapper>
  );
};
