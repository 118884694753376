import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  font-family: "Inter";  
  width: 90%;
  margin: 0 auto 50px auto;

  button {
    margin-top: 40px;
  }

  @media (min-width: 768px) {
    width: 100%;
    margin: 0 0 70px 0;
  }
`;

export const DetailsBox = styled.div`
  padding: 24px;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const DetailsSectionHeader = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #000000;
    margin: 0;
  }

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px; 
    color: var(--primary-pink);
    cursor: pointer;
  }
`;

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 16px;
    color: #767676;
    font-weight: 400;
    line-height: 24px; 
    margin: 0;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    color: #000000;
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  text-align: center;
  padding: 30px 0;

  img {
    height: 80px;
    object-fit: cover;
  }

  h3 {
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    font-weight: 500;
  }
  
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #484848;
    width: 400px;
  }
`;
