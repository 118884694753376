import { Steps } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    margin: auto;
    margin-top: 40px;
    @media (max-width: 768px) {
        width: 100%;
    }

    .rate-box {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    button {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px; /* 130% */
    }
`;

export const StepContainer = styled(Steps)`
    .ant-steps-item-icon {
        display: none;
    }
    .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title {
        color: #ef2c5a;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const StepContent = styled.div`
    margin: auto;
    margin-top: 30px;
    height: 70vh;
    overflow-x: auto;
    width: 85%;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Title = styled.h1`
    font-family: Athletics;
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.02em;
    text-align: center;
`;

export const Paragraph = styled.p`
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: center;
    color: #767676;
`;

export const Flex = styled.div`
    display: flex;
    gap: 20px;

    .form-wrapper {
        width: 100%;
    }
`;

export const AddUltimateBeneficial = styled.div`
    height: Hug (40px) px;
    padding: 12px 20px 12px 20px;
    gap: 10px;
    border-radius: 8px 0px 0px 0px;
    opacity: 0px;
    background: #f9f9f9;
    text-align: center;
    button {
        background: none;
    }
`;

export const DetailsBox = styled.div`
    padding: 24px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const DetailsSectionHeader = styled.div`
    padding-bottom: 8px;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        color: #000000;
        margin: 0;
    }

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: var(--primary-pink);
        cursor: pointer;
    }
`;

export const DetailsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const DetailsRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        line-height: 24px;
        margin: 0;
    }

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
    }
`;

export const BeneficfiaryList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust this as needed */
    & > div {
        width: calc(50% - 10px);
        margin-bottom: 20px;
    }
`;

export const DocumentUploadWrapper = styled.div`
    margin-top: 30px;
`;

export const DocumentUploadBox = styled.div`
    border: 1px solid #d1d1d1;
    padding: 20px;
    width: 100% !important;
`;

export const DocumentTitle = styled.p`
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
`;

export const DocumentContainer = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 90%;
`;

export const NotificationBox = styled.div`
    background: #f9f9f9;
    border: 1px solid #d1d1d1;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 8px;
    width: 100%;
`;

export const DocumentSection = styled.section`
    ul {
        color: #606673;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: left;
    }
`;

export const NotificationSection = styled.section`
ul{
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 15.6px;
text-align: left;
}
img{
    margin-top: -75px
}
`;
