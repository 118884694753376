import styled, { css } from "styled-components";
import { Input } from "antd";

export const Top = styled.div`
  width: 100%;
  font-family: "Proxima Nova", sans-serif;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 2px;
  text-align: left;
`;

export const AccountNumber = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #717070;
  margin-bottom: 0;
  text-align: left;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BankName = styled.p`
  width: 100%;
  margin-bottom: 2px;
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const AccountType = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--primary-pink);
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
`;

export const Currency = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #0eab44;
  @media (min-width: 768px) {
    color: #000000;
  }
`;

interface WrapperProps {
    $isSelected: boolean;
    $disabled: boolean;
  }

export const Wrapper = styled.button<WrapperProps>`
  min-height: 138px;
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px 0;

  ${(props: { $isSelected: boolean; $disabled: boolean }) => {
    if (props.$disabled) {
      return css`
        background: #8f8f8f;
        border-color: #8f8f8f;
        & ${Label},& ${BankName},& ${Currency} {
          color: #000000;
        }
        & ${AccountNumber} {
          color: #000000;
        }
      `;
    } else if (props.$isSelected) {
      return css`
        border-color: #000000;
      `;
    } else {
      return css`
        border-color: #EEEEEE;
      `;
    }
  }};

  
`;

export const Search = styled(Input)`
height: 54px;
border-radius: 10px;
`

export const CardWrapper = styled.div`
display: flex;
justify-content: center;
gap: 5px;
flex-direction: column;
margin-top: 10px;
overflow-y: scroll;
height: 30vh;
`

export const Form = styled.div`
 width: 90%;
 margin: 0 auto;

 @media (min-width: 768px){
  width: 100%;
  margin: 0;
 }

`

export const Btn = styled.div`
margin-top: 15px;
`

export const CTA = styled.div`
display: flex;
padding: 5px;
cursor: pointer;
position:absoulte;
width: 100%;
margin:auto;
margin-top: 10px;
background: #F3F3F3;
border-radius: 16px;
:hover{
  background: #F3F3F3;
  border-radius: 16px;
  padding: 5px;
  h1{
      color: #EF2C5A;

  }

}
.arrow-icon{
  margin-left: 25px;
    position: relative;
    left: -13px;
};
justify-content: space-between;
.item{
  display: flex;
  gap: 20px;
}
h1{
  font-size: 16px;
  padding-top: 10px;
}

.avatar-img{
background: #F9F9F9;
border-radius: 33px;
width: 50px;
height: 50px;
}
`

export const ButtonWrapper = styled.div`
  margin-top: 45px;

  p {
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px;
    color: #565C69;
  }

  @media (min-width: 768px) {
   p {
    font-size: 12px;
   } 
  }
`;

export const Note = styled.div`
background: #F9F9F9;
padding: 16px;
border-radius: 8px;
border: 1px, 1px, 0px, 1px;
display: flex;
gap: 16px;
align-items: center;
span{
    color: #000000;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;


}


`