import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";
import { nextVariants } from "../../../../utils";
import {
    ContentWrapper,
    DetailsBox,
    DetailsSectionHeader,
    SectionTitle,
    EditButton,
    DetailsLabel,
    DetailsRow,
    DetailsValue,
    MainHeader,
    DetailsContent,
} from "../styles";
import { Button } from "../../../../../../components/common/Button";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import { formatCurrencyAmount } from "../../../../../../utils/currency";
import {
    sendMerchantOTP,
} from "../../../../redux/slice";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../../components/common/Loader";
import { OTP } from "../OTP";

const Preview = ({
    onGoBack,
}: {
    onGoBack: (arg) => void;
}) => {
    const selectedBeneficiary = useAppSelector(
        (state) =>
            state?.formData?.selectedBeneficiary || null,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { loading } = useAppSelector(
        (state) => state?.wallets || {},
    );
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const dispatch = useAppDispatch();
    const [showOtp, setShowOtp] = useState(false);
    const [transactionId, setTransactionId] = useState("");
    const savedUserData = useAppSelector(
        (state) => state?.formData?.userData,
    );

    const convertedAmount = savedUserData?.convertedAmount !== undefined && savedUserData?.convertedAmount !== null
    ? savedUserData.convertedAmount.toFixed(4) : null
    

    const formattedDate = dayjs().format("YYYY-DD-MM");
    const sendWithdrawalRequest = async () => {
        
        const payload = {
            beneficiaryAmount: savedUserData.amount,
            business: businessId,
            merchantBeneficiary: selectedBeneficiary?.id,
            destinationCurrency: selectedBeneficiary?.currency,
            destinationAmount: savedUserData?.amount

        };
        const action = await dispatch(
            sendMerchantOTP({
                data: payload,
            }),
        );
        if (sendMerchantOTP.fulfilled.match(action)) {
            setShowOtp(true);
            const prefix = "KBASS-";
            const maxLength = 25;
            const randomId = uuidv4().replace(/-/g, "");            
            const requiredLength =
                maxLength - prefix.length;
            const trimmedRandomId = randomId.substring(
                0,
                requiredLength,
            );
            const transactionId = `${prefix}${trimmedRandomId}`;            
            setTransactionId(transactionId);
        }

        if(sendMerchantOTP.rejected.match(action)){
            onShowErrorAlert("", String(action.payload))
        }
    };

   

    return (
        <ContentWrapper>
            <Loader isLoading={loading} />
            <MainHeader>
                <h1>
                    {" "}
                    Review the details of your transfer
                </h1>
            </MainHeader>
            <motion.div
                variants={nextVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                <DetailsBox>
                    <DetailsSectionHeader>
                        <SectionTitle>
                            Transaction details
                        </SectionTitle>
                        <EditButton
                            onClick={() => onGoBack(1)}
                        >
                            <span>Edit</span>
                        </EditButton>
                    </DetailsSectionHeader>
                    <DetailsContent>
                        <DetailsRow>
                            <DetailsLabel>
                                Beneficiary’s amount
                            </DetailsLabel>
                            <DetailsValue>{`${formatCurrencyAmount(
                                savedUserData?.currency || savedUserData?.destinationCurrency,
                                savedUserData?.amount,
                            )}`}</DetailsValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailsLabel>
                                Processing fee
                            </DetailsLabel>
                            <DetailsValue>{`${formatCurrencyAmount(
                                (savedUserData?.currency) || (savedUserData?.sourceCurrency),
                                savedUserData?.fees,
                            )}`}</DetailsValue>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>
                                Total amount
                            </DetailsLabel>
                            <DetailsValue>
                                {`${formatCurrencyAmount(
                                (savedUserData?.currency) || savedUserData.sourceCurrency,
                                (convertedAmount), 4
                            )}`}
                            
                            </DetailsValue>
                        </DetailsRow>

                        <DetailsRow>
                            <DetailsLabel>
                                Date
                            </DetailsLabel>
                            <DetailsValue>
                                {formattedDate}
                            </DetailsValue>
                        </DetailsRow>
                    </DetailsContent>

                    <DetailsSectionHeader>
                        <SectionTitle>
                            Beneficiary details
                        </SectionTitle>
                        <EditButton
                            onClick={() => onGoBack(2)}
                        >
                            <span>Edit</span>
                        </EditButton>
                    </DetailsSectionHeader>
                    <DetailsContent>
                        <DetailsRow>
                            <DetailsLabel>
                                Beneficiary
                            </DetailsLabel>
                            <DetailsValue>
                                {
                                    selectedBeneficiary?.beneficiaryName
                                }
                            </DetailsValue>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>
                                Bank name
                            </DetailsLabel>
                            <DetailsValue>
                                {
                                    selectedBeneficiary?.bankName
                                }
                            </DetailsValue>
                        </DetailsRow>
                        <DetailsRow>
                            <DetailsLabel>
                                Account number
                            </DetailsLabel>
                            <DetailsValue>
                                {
                                    selectedBeneficiary?.accountNumber
                                }
                            </DetailsValue>
                        </DetailsRow>
                    </DetailsContent>
                </DetailsBox>
            </motion.div>

            {showOtp && (
                <OTP
                    setVerifyOTP={setShowOtp}
                    transactionId={transactionId}
                    ResendOTP={sendWithdrawalRequest}
                />
            )}

            <Button
                height="48px"
                label="Continue"
                className="btn"
                onClick={sendWithdrawalRequest}
            />
        </ContentWrapper>
    );
};

export default Preview;
