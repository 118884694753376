import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  font-family: "Inter";
`;

export const Title = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;

  h1 {
    font-family: "Athletics";
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    margin: 0;
  }

  p {
    font-size: 16px;
    color: #767676;
    font-weight: 400;
    line-height: 24px; 
    margin: 0;
  }
`;

export const BoxBody = styled.div`
  @media (min-width: 768px) {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  button {
    margin-top: 30px;
  }
`;

export const EmptyWrapperTitle = styled.h1`
  font-family: "Athletics";
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; 
  letter-spacing: -0.48px;
  color: #000000;
  margin: 0;
`;

export const EmptyWrapperSubTitle = styled.p`
  width: 400px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
  color: #8F8F8F;
  text-align: center;
`;

export const ActionBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  button {
    background-color: unset;
    border: unset;
    cursor: pointer;

    svg {
      height: 24px;
    }
  }
`;

export const LinkItem = styled.a`
  color: #000000;
  min-width: 300px;
  width: 20vw;
  //text-overflow: clip;
  overflow: hidden;
  //white-space: nowrap;
  display: block;

  &:hover {
    color: #000000;
  }
`;

export const MobileBody = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;

  @media (min-width: 768px) {
    display: none;
  }
`;
