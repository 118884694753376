import styled from "styled-components";
import { Checkbox } from "antd";
import CurrencyInput from "react-currency-input-field";

export const Wrapper = styled.form`
  width: 100%;
`;

export const Header = styled.p`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 8px;
`;

export const SubHeader = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #565C69;
  margin-bottom: 40px;
  text-align: center;
`;

export const Inputs = styled.div`
  width: 100%;
  margin-bottom: 42px;
`;

export const InputBox = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 35px;

  & > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }

  & div[class~="ant-input-number-affix-wrapper"] {
    border: 1px solid #a3a3a3 !important;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    box-shadow: none !important;
    font-family: "Inter";

    & > span {
      padding-right: 8px;
      border-right: 1px solid #a3a3a3;
    }

    & > div[class="ant-input-number"] {
      padding-left: 8px;
    }
  }

  & div[class="ant-input-number-handler-wrap"] {
    display: none;
  }
`;

export const CheckboxWrapper = styled(Checkbox)`
  & span[class="ant-checkbox-inner"] {
    border: 2px solid #000000;
    background-color: transparent;
    border-color: #000000 !important;
  }

  & span[class~="ant-checkbox-checked"]:after {
    border: 2px solid #000000;
    border-color: #000000;
  }

  & span[class~="ant-checkbox-checked"] span[class="ant-checkbox-inner"] {
    background-color: transparent;
    border-color: #000000;
  }

  &
    span[class~="ant-checkbox-checked"]
    span[class="ant-checkbox-inner"]::after {
    border-color: var(--primary-pink);
  }
`;
export const CheckboxText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 48.5%;
  }
`;

export const StyledCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 14px;
  flex: 1 1;
  height: 48px;

  &:hover{
    border: 1px solid #a3a3a3;
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
