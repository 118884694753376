import styled from "styled-components";

export const Title = styled.p`
  font-family: "Athletics";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  margin: 0;
`;

export const Inputs = styled.div<{ showTitle: boolean }>`
  margin: ${({showTitle}) => showTitle ? "20px 0 50px 0" : "0 0 50px 0"};
  text-align: left;
`;


export const InputBox = styled.div`
  width: 100%;
  margin-bottom: 25px;

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;

export const CountryBox = styled.div`
  width: 100%;
  margin-bottom: 25px;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;
