import React, { useState, useRef } from 'react';
import UploadedFileIcon from "../../../../../assets/icons/upload-icon.svg";
import DeleteFileIcon from "../../../../../assets/icons/delete-file.icon.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/icons/upload-icon.svg";
import { DocumentUploadBox, Flex, Space, UploadButton } from './style';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

interface Props {
  onFileChange?: (fileUrl: string | null) => void;
  error?: string;
  fileUrl: string | null
  setFileUrl?: (data: any) => void

}

const BusinessLogoUpload: React.FC<Props> = ({ onFileChange, error, fileUrl, setFileUrl }) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size;

      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        // Todo: Use Klasha error toast here
        console.error("Only JPEG and PNG files are allowed.");
        return;
      }

      if (fileSize > 5 * 1024 * 1024) {
        // Todo: Use Klasha error toast here
        console.error("File size must be less than 5MB.");
        return;
      }

      setFile(selectedFile);
      uploadFileToFirebase(selectedFile);
    }
  };

  const uploadFileToFirebase = (file: File) => {
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      }, 
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFileUrl(downloadURL);
          onFileChange(downloadURL);
          setUploading(false);
        });
      }
    );
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setFileUrl(null);
    onFileChange(null);
  };

  return (
    <Space>
      {!file ? (
        <DocumentUploadBox>
          <input 
            type="file"
            accept="image/jpeg,image/png"
            onChange={handleFileChange} 
            style={{ display: 'none' }} 
            ref={fileInputRef}
          />
          <Flex>
            <UploadButton onClick={handleUploadClick}>
              <UploadIcon />
              <div>
                <h4>Upload Business logo</h4>
                <span>JPEG or PNG less than 5MB</span>
              </div>
            </UploadButton>
          </Flex>
          {error && <p>{error}</p>}
        </DocumentUploadBox>
      ) : (
        <DocumentUploadBox>
          <Flex>
            <div className="uploaded-content">
              <img src={UploadedFileIcon} />
              <span>
                <p>{file.name}</p>
                <p className="uploaded-copy">{uploading ? 'Uploading...' : 'Uploaded'}</p>
              </span>
            </div>
            <button onClick={handleDeleteFile}>
              <img src={DeleteFileIcon}/>
            </button>
          </Flex>
        </DocumentUploadBox>
      )}
    </Space>
  );
}

export default BusinessLogoUpload;
