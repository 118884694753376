import React from 'react';
import { Bottom, BottomItem, BottomLabel, BottomValue, MobileWrapper } from '../styles';


interface Props{
    transactionRef: string;
    accountNumber:string;
    amountReceived: string;
    amountSettled: string;
    date: string;
    onClick: (data) => void;
}



const MobileCard = ({transactionRef, accountNumber, amountReceived, amountSettled, date, onClick}: Props) => {
  return (
    <MobileWrapper onClick={onClick}>
        <Bottom>
            <BottomItem>
            <BottomLabel> Transaction ref</BottomLabel>
            <BottomValue>{transactionRef}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel> Account Number</BottomLabel>
            <BottomValue>{accountNumber}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Amount Received</BottomLabel>
            <BottomValue>{amountReceived}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Amount Settled</BottomLabel>
            <BottomValue>{amountSettled}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Date</BottomLabel>
            <BottomValue>{date}</BottomValue>
            </BottomItem>

        </Bottom>


    </MobileWrapper>
  )
}

export default MobileCard;