import axios from "axios";

const api2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000, // Sets timeout to 30 seconds 
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api2.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("key");
    if (token && request.headers) {
      request.headers["x-auth-token"] = `${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error),
);

export default api2;
