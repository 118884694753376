import React from "react";
import Header from "../Header";
import HeaderComponentV2 from "../HeaderComponentV2";
import { useHeaderFlag } from "../../../../app/hooks/useHeaderFlag";


type HeaderToDisplayProps = {
  onShowSide: () => void;
  setDropdownOpen: (val: boolean) => void;
}

export const HeaderToDisplay: React.FC<HeaderToDisplayProps> = ({
  onShowSide,
  setDropdownOpen,
}) => {
  const headerFlagValue = useHeaderFlag();
  return (
    <>
      {headerFlagValue ?? true ? (
        <HeaderComponentV2
          onShowSide={onShowSide}
          setDropdownOpen={setDropdownOpen}
        />
      ) : (
        <Header
          onShowSide={onShowSide}
          setDropdownOpen={setDropdownOpen}
        />
      )}
    </>
  );
};
