import { createSlice } from '@reduxjs/toolkit'
import { adminLogin } from "./action";

const initialState = {
    loading: false,
    error: null,
    success: false,
    errormsg: null,
}

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(adminLogin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(adminLogin.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(adminLogin.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            })

    }

})

export default adminSlice.reducer;