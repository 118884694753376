import styled from "styled-components";
import { motion } from "framer-motion";
import { Select } from "antd";

export const Wrapper = styled(motion.div)`
  width: 100%;

  @media (min-width: 768px) {
    max-width: 500px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 600px;
  }
`;

export const Back = styled(motion.span)`
  position: absolute;
  top: -25px;
  left: 0;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 0;
    left: -90px;
  }
  @media (min-width: 1300px) {
    left: -200px;
  }
`;

export const StepsContainer = styled.div`
  @media (min-width: 768px) {
    padding: 0 30px;
  }
`;

export const StepsTitle = styled.header`
  text-align: center;
  
  h1 {
    font-family: "Athletics";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px; 
    letter-spacing: -0.56px;
    margin-bottom: 8px;
    color: #000000;
  }

  p {
    font-family: "Inter";
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; 
  }

  @media (min-width: 576px) {
    h1 {
      font-size: 28px;
      line-height: 34px;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const StepsForm = styled.form`
  position: relative;
  margin-top: 55px;

  button {
    margin-top: 30px;
  }
`;

export const StepsBox = styled.div`
  position: relative;
  margin-top: 55px;

  button {
    margin-top: 30px;
  }
`;

export const InputContainer = styled.div`
  height: ${(props: { $hasError?: boolean }) =>
    props.$hasError ? "115px" : "80px"};
  position: relative;

  span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const ErrorBox = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  margin-top: 4px;
  color: var(--primary-pink);
  display: flex;
  align-items: flex-end;
  gap: 10px;

  a {
    color: #000000;
    text-decoration: underline;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding-left: 15px;
  border: ${(props: { hasError?: boolean }) =>
    props.hasError
      ? "1px solid var(--primary-pink)"
      : "1px solid #a3a3a3;"} !important;
  border-radius: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  outline: 0;

  &:hover {
    border: 1px solid #0000009f;
  }
  &:focus {
    border: ${(props: { hasError?: boolean }) =>
      props.hasError
        ? "1px solid var(--primary-pink)"
        : "2px solid #a3a3a3;"} !important;
  }
`;

export const PlaceholderText = styled.div`
  position: absolute;
  top: 13px;
  left: 3%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #565C69;
`;

export const Fee = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
`;

export const InfoBox = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: -230px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    width: 216px;
    height: 56px;
    color: #ffffff;
    padding: 12px;
    background-color: #313131;
    border-radius: 4px;
  }
`;

export const FlagBox = styled.div`
  position: absolute;
  top: 12px;
  right: 2%;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #484848;

  p:first-child {
    width: 18px;
    border-radius: 2px;
  }
`;

export const SelectContainer = styled.div`
  text-align: left;
  height: 80px;
  font-weight: 400;
  
  p {
    font-size: 12px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  span {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #565c69;
  }
`;

export const RateBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Inter";
    font-size: 14px;
    line-height: 16px;
    color: #565c69;
    margin-top: 7px;

`;

export const Custom = styled(Select)`
  width: 100%;
  color: #000000 !important;
  font-size: 16px !important;

  & div[class="ant-select-selector"] {
    height: 40px !important;
    border: 1px solid #a3a3a3 !important;
    border-radius: 8px !important;
    cursor: pointer !important;

    &:hover {
      border: 1px solid #0000009f !important;
    }
    &:focus() {
      border: 1px solid #0000005b !important;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5) !important;
    }
  }

  & span[class="ant-select-selection-item"] {
    font-family: "Inter";
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16px !important;
    color: #565C69 !important;
    }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: "Inter";

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;

    &:last-child {
      margin-left: 6px;
    }
  }
`;

export const ReviewBox = styled.div`
  font-family: "Inter";
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 15px 20px;
  margin-top: 30px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #E8E8E8;

  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px; 
    color: #000000;
    margin: 0;
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: var(--primary-pink);
    cursor: pointer;

    &:hover {
      color: #ef2c59c1;
    }
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 18px;
      line-height: 28px; 
    }
  }
`;

export const ReviewDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 20px;

  @media (min-width: 768px) {
    gap: 8px;
  }
`;

export const ReviewItem = styled.span`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  p {
    color: #767676;
  }
  
  span {
    color: #000000;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

export const ReviewRate = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const AlertBox = styled.div`
  display: flex;
  gap: 25px;
  padding: 16px;
  width: 100%;
  background-color: #F9F9F9;
  border-radius: 8px;
  margin: 20px 0 35px 0;

  @media (min-width: 768px) {
    gap: 30px;
    padding: 16px;
  }
`;

export const Alert = styled.div`
  height: 30px; 
`;

export const AlertText = styled.ul`
  color: #000000;
  padding: 0;
  margin: 0;
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 15.6px;

  li:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const ErrorWrapper = styled.div`
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Box = styled(motion.div)`
  width: 298px;
  height: 90px;
  margin: 10% 2% 0 0;
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  position: relative;

  span {
    position: absolute;
    right: 3%;
    top: 3%;
    cursor: pointer;
  }
`;

export const RedLine = styled.div`
  width: 4px;
  height: 100%;
  background: #f9123b;
  border-radius: 6px 0px 0px 6px;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 7px;

  svg {
    margin-top: 3px;
  }
`;


export const Text = styled.div`
  font-family: "Inter";
  width: 90%;
  
  h1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-bottom: 4px;
    color: #0a0a0a;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #767676;
    margin-bottom: 0;
  }
`;