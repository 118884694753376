import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 16px;
  font-family: "Proxima Nova", sans-serif;
  
  @media (min-width: 768px) {
    display: none;
  }
`;

export const Top = styled.div`
  display: flex;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 10px;
`;

export const TopLeft = styled.div`
  width: 50%;
  & p:nth-child(1) {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    color: #0eab44;
  }
  & p:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
  }
  & p:nth-child(3) {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 160%;
    color: #222223;
    text-transform: uppercase;
  }
`;

export const TopRight = styled.div`
  display: none;
  justify-content: flex-end;
  flex: 1 1;
  & > button {
    background-color: transparent;
    border: none;
    padding: 5px;
  }
  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ef2c5a;
  }
`;

export const Bottom = styled.div`
  display: flex;
  padding-top: 16px;
  & div p:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #8f8f8f;
  }
  & div p:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
    color: #222223;
  }
`;

export const BottomLeft = styled.div`
  width: 50%;
`;

export const BottomRight = styled.div`
  flex: 1 1;
`;
