import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "../../steps/style";
import Funds from "../../GeneralComponents/Funds";
import Review from "../../GeneralComponents/Review";
import { animate, exit, initial } from "../../../utils";
import { AnimatePresence } from "framer-motion";
import CustomStepper from "../../../../../components/common/CustomStepper";

const stages = [
    {
        stage: 1,
        title: "Amount",
    },
    {
        stage: 2,
        title: "Review",
    },
];

const SendFunds = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const handleFunds = (e: {
        preventDefault: () => void;
    }) => {
        e.preventDefault();
        setStep(2);
    };

    return (
        <AnimatePresence>
            <Wrapper
                initial={initial}
                animate={animate}
                transition={{
                    type: "spring",
                    stiffness: 70,
                    when: "beforeChildren",
                }}
                exit={exit}
            >
                <CustomStepper
                    stages={stages}
                    step={step}
                    setStep={setStep}
                    onClose={() => navigate("/cards")}
                />

                {step === 1 && (
                    <Funds
                        handleFunds={handleFunds}
                        IncreaseHeight={false}
                        fee={1.5}
                        smallText="You will be charged $1.50 every time you fund your card."
                    />
                )}

                {step === 2 && (
                    <Review
                        feeText="Fee"
                        fee={1.5}
                        type="fund-card"
                        onEdit={(): void => setStep(1)}
                    />
                )}
            </Wrapper>
        </AnimatePresence>
    );
};

export default SendFunds;
