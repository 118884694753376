import React from "react";
import {
  ReceiptButtons,
  ReceiptContainerHeader,
  ReceiptFooterNote,
  ReceiptHeading,
  ReceiptItem,
  ReceiptItemHeader,
  ReceiptItemsSection,
  ReceiptWrapper,
  Line,
} from "../../../pages/KlashaWire/style";
import downloadToPdf from "../../../libs/downloadPdf";
import klashaLogo from "../../../assets/images/logo.png";
import { WireTableData } from "../utils";
import { useAppSelector } from "../../../app/hooks";
import { formatStatus } from "../../../pages/Wallets/utils";
import { Button } from "../../common/Button";

const Receipt = ({
  receiptRef,
  receipt,
  setShowReceiptModal,
}: {
  receiptRef: any;
  receipt: WireTableData;
  setShowReceiptModal: (are: boolean) => void;
}) => {
  const { business } = useAppSelector((state) => state.settings);
  const currentYear: number = new Date().getFullYear();
  

  return (
    <ReceiptWrapper ref={receiptRef}>
      <Line/>

      <ReceiptContainerHeader>
        <img src={klashaLogo} alt="logo" />
        <p>Klasha wire</p>
      </ReceiptContainerHeader>
      <ReceiptHeading>
        {formatStatus(receipt.status?.toLowerCase())}
      </ReceiptHeading>
          
          <div>
            <ReceiptItemsSection>
              <ReceiptItemHeader>Profile details</ReceiptItemHeader>
              <ReceiptItem>
                <p>Company</p>
                <span>{business.name}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Address</p>
                <span>{business.address}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Email address</p>
                <span>{business.email}</span>
              </ReceiptItem>
            </ReceiptItemsSection>
            <ReceiptItemsSection>
              <ReceiptItemHeader>Transaction details</ReceiptItemHeader>
              <ReceiptItem>
                <p>Transaction reference</p>
                <span>{receipt.ref}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Paid</p>
                <span>{receipt.merchantPay}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Transaction status</p>
                <span>{formatStatus(receipt.status?.toLowerCase())}</span>
              </ReceiptItem>
            </ReceiptItemsSection>
            <ReceiptItemsSection>
              <ReceiptItemHeader>Recipient details</ReceiptItemHeader>
              <ReceiptItem>
                <p>Recipient name</p>
                <span>{receipt.recipient?.beneficiaryName}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Account number</p>
                <span>{receipt.recipient?.accountNumber}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Bank name</p>
                <span>{receipt.recipient?.bankName}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Amount</p>
                <span>{receipt.recipientPay}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Company name</p>
                <span>{receipt.recipient?.name}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Email ddress</p>
                <span>{receipt.recipient?.email}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Address</p>
                <span>{receipt.recipient?.address}</span>
              </ReceiptItem>
              <ReceiptItem>
                <p>Country</p>
                <span>{receipt.recipient?.country}</span>
              </ReceiptItem>
            </ReceiptItemsSection>
          </div>

        <ReceiptFooterNote>
            We value your relationship with us and look forward to a long-term
            <br /> association. Please feel free to contact us at support@klasha.com or
            <br /> WhatsApp +1 415-993-3858. © Klasha {currentYear}
        </ReceiptFooterNote>

      <ReceiptButtons>
        <span onClick={() =>setShowReceiptModal(false)}>Cancel</span>
        <Button 
          label="Download"
          fontSize="12px"
          height="40px"
          width="90px"
          onClick={() =>  downloadToPdf(receiptRef, "receipt")}
        />
      </ReceiptButtons>
    </ReceiptWrapper>
  );
};

export default Receipt;
