import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  background-color: #ffffff;

  svg {
    height: 16px;
    margin-left: 5px;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

    width: max-content;
    padding: 0 20px;
    height: 40px;
    cursor: pointer;
    border: 1px solid #000000;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      width: 40px;
      padding: unset;
    }

  span {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
