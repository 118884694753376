import { useEffect } from 'react';

const useDisableRightClickAndInspect = (enabled: boolean) => {
    useEffect(() => {
        if (!enabled) return;

        const disableRightClick = (e: MouseEvent) => e.preventDefault();

        const disableInspect = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.shiftKey && e.key === 'I') e.preventDefault();
            if (e.key === 'F12' || (e.ctrlKey && e.key === 'U')) e.preventDefault();
        };

        const detectDevTools = () => {
            const threshold = 160;

            const checkDevToolsOpen = () => {
                const isDevToolsOpen = window.outerWidth - window.innerWidth > threshold ||
                    window.outerHeight - window.innerHeight > threshold;
                if (isDevToolsOpen) {
                    console.log('Developer tools are open. Closing page.');
                    window.close();
                }
            };

            window.addEventListener('resize', checkDevToolsOpen);
            checkDevToolsOpen();

            return () => {
                window.removeEventListener('resize', checkDevToolsOpen);
            };
        };

        document.addEventListener('contextmenu', disableRightClick);
        document.addEventListener('keydown', disableInspect);
        const detectDevToolsCleanup = detectDevTools();

        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
            document.removeEventListener('keydown', disableInspect);
            detectDevToolsCleanup();
        };
    }, [enabled]);
};

export default useDisableRightClickAndInspect;
