import React from "react";
import {
  Wrapper,
  Section,
  Item,
  Actions,
} from "./style";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ReactComponent as ShareIcon } from "../../../../pages/PaymentLinks/assets/share.svg";
import { ReactComponent as DeleteIcon } from "../../../../pages/PaymentLinks/assets/failed.svg";
import { ReactComponent as CheckIcon } from "../../../..//assets/icons/check.svg";
import LinkType from "../LinkType";
import { formatLongString } from "../../../../utils/formatString";

interface CardProps {
  record: { 
    id: number,
    amount: string;
    title: string;
    date: string;
    type: string;
    currency: string;
    link: { token: string, url: string };
  }; 
  selectedRecord: null;
  onCopyLinkToClipboard: (record: any) => void;
  onDeleteLink: (id: number) => void;
  openShareModal: (url: string) => void;
  redirectToUrl: (url: string) => void;
}

const LinkCard = ({
  selectedRecord,
  onCopyLinkToClipboard,
  onDeleteLink,
  openShareModal,
  record,
  redirectToUrl,
}: CardProps) => {

  return (
    <Wrapper title="link-card">
      <Section>
        <Item>
          <span>Amount</span>
          <p>{record.currency} {record.amount}</p>
        </Item>
        <Actions>
          <button
            onClick={() => onCopyLinkToClipboard(record)}
            title="copy"
            type="button"
          >
            {selectedRecord === record.id ? <CheckIcon /> : <CopyIcon />}
          </button>
          <button
            title="share"
            type="button"
            onClick={() => openShareModal(record.link.url)}
          >
            <ShareIcon />
          </button>
          <button
            title="delete"
            type="button"
            onClick={() => onDeleteLink(record.id)}
          >
            <DeleteIcon />
          </button>
        </Actions>
      </Section>

      <Section>
        <Item>
          <span>Title</span>
          <p>{record.title}</p>
        </Item>

        <Item>
          <span>Status</span>
          <LinkType text={record.type} />
        </Item>
      </Section>

      <Section>
        <Item>
          <span>Reference</span>
          <p onClick={() => redirectToUrl(record.link.url)}>{formatLongString(record.link.token, 15)}</p>
        </Item>

        <Item>
          <span>Date</span>
          <p>{record.date}</p>
        </Item>
      </Section>
    </Wrapper>
  );
};

export default LinkCard;
