import React, { ChangeEvent, useContext, useState } from "react";
import { useMutation } from "react-query";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AxiosError } from "axios";
import {
  FormBodyDiv,
  FormInputDiv,
  SubTitleParagraph,
  TitleParagraph,
} from "./style";
import { AuthWrapper } from "../../components/AuthWrapper";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { forgotPassword } from "../../api/authAPI";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import PasswordSent from "../../components/auth/PasswordSent";

type Inputs = {
  email: string;
  domain: string;
};

const ForgotPassword = () => {
  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");

  const mutation = useMutation(forgotPassword, {
    onError: (error: AxiosError) => {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (errorObj.error === "Bad credentials") {
        errorMessage = "Invalid password or email";
      } else if (errorObj.error === "User is disabled") {
        errorMessage = "Please confirm your email";
      } else if (
        errorObj.error ===
        "Full authentication is required to access this resource"
      ) {
        errorMessage =
          "You have reached the maximum amount of login attempts. Please try again in 24 hours";
      } else {
        errorMessage = errorObj.error;
      }
      onShowAlert(errorObj, errorMessage);
    },
    onSuccess: async (data, variables) => {
      setIsSubmitted(true);
      setSignupEmail(variables.email);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    mutation.mutate({ ...data, domain: window.location.host });
  };
  return (
    <AuthWrapper>
      {!isSubmitted ? (
        <div>
          <TitleParagraph>Forgot your password?</TitleParagraph>
          <SubTitleParagraph>
            Enter your email address to reset your password
          </SubTitleParagraph>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormBodyDiv>
              <FormInputDiv>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                  }}
                  render={({ field }) => (
                    <Input 
                      type="text" 
                      label="Email" 
                      field={field}
                      value={email}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    />
                  )}
                />
                {errors.email?.type === "required" && (
                  <span>The email is required</span>
                )}
                {errors.email?.type === "pattern" && (
                  <span>Please enter a valid email address</span>
                )}
              </FormInputDiv>

              <Button 
                label="Submit" 
                type="submit" 
                height="40px"
                fontSize="12px"
                disabled={!email}
              />
            </FormBodyDiv>
          </form>
        </div>
      ) : (
        <PasswordSent email={signupEmail} />
      )}
    </AuthWrapper>
  );
};

export default ForgotPassword;
