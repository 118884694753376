import styled from "styled-components";
import { Input, Select } from "antd";

const { TextArea } = Input;

export const Wrapper = styled.form`
  width: 100%;
  font-family: "Proxima Nova", sans-serif;
`;

export const Content = styled.div`
  margin-bottom: 40px;
  width: min(700px, 100%);
`;

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const Options = styled.div``;

export const DualInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  flex-wrap: wrap;
  & > * {
    margin-bottom: 16px;
    width: 100%;
  }
  @media (min-width: 768px) {
    margin-bottom: 16px;
    & > * {
      margin-bottom: 0;
      width: 48%;
    }
    & > *:first-child {
      margin-right: 24px;
    }
  }
`;

export const UploadContainer = styled.div``;

export const ErrorText = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: var(--primary-pink);
    text-align: left;
    margin-left: 8px;
  }
`;

export const Question = styled.div`
  margin-bottom: 40px;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 24px;
  }
  @media (min-width: 768px) {
  }
`;

export const YesNoDiv = styled.div`
  margin-bottom: 24px;

  & > * {
    display: flex;
    & > label {
      margin-right: 24px;
      width: 100px !important;
      height: 40px !important;
      display: flex;
      align-items: center;
    }
  }
`;
export const DetailsContainer = styled.div`
  border-top: 1px dashed #d2d2d2;
  padding-top: 24px;
  display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};
`;

export const Details = styled(TextArea)`
  border: 1px solid #a3a3a3 !important;
  border-radius: 8px !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding: 12px 14px;
`;

export const InputBox = styled.div`
  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 180%;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 16px;
  }
`;

export const AmountInputBox = styled.div`
  display: flex;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  height: 40px;

  & input {
    border-radius: 0 8px 8px 0;
    flex: 1 1;
    height: 100%;
    border: none;
    padding-left: 10px;
  }
`;

export const AmountSelect = styled(Select)`
  width: 80px;
  height: 100%;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  color: #464646;
  border-radius: 8px 0px 0px 8px;

  & span[class="ant-select-selection-item"] {
    display: flex;
    align-items: center;
  }

  & span[class="ant-select-selection-placeholder"] {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #565c69 !important;
  }
  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px 0px 0px 8px !important;
    border: none !important;
  }
`;
