import { StatusTag} from "./TransctionStatus.styles";

type Props={
  text: string;
}

const TransactionStatus = ({ text }: Props) => {
  
  const textBody = (char: string) =>{
    if(char?.toLowerCase() === "pending"){
      return <span>In progress</span>
    } else if(char?.toLocaleLowerCase() === 'in review'){
        return <span>In review</span>}
         else if (char?.toLowerCase() === "pending_reversal"){
      return  <span>Pending reversal</span>
    } else {
      return <span>{char?.toLowerCase()}</span>
    }
  }

  return (
    <StatusTag
      $status={text?.toLowerCase()}
      data-testid='status-tag'>
      {textBody(text)}
    </StatusTag>
  );
};

export default TransactionStatus;