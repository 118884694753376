import dayjs from "dayjs";

export const initial = {opacity: 0, x: "60%" };
export const animate = { opacity: 1, x: "0", };
export const exit = {opacity: 0, x: "60%"  };
export const transition = { type: 'spring', stiffness: 100};

export const itemVariant = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

export const itemTransition = {
  type: "spring",
  stiffness: 300,
  damping: 20,
};

export function addCommasToNumber(value: string | number): string {
  if (!value) {
    return '';
  }

  const stringValue = typeof value === 'string' ? value : value.toString();
  const [integerPart, decimalPart] = stringValue.split('.');

  let result = '';
  let digitsCount = 0;

  for (let i = integerPart.length - 1; i >= 0; i--) {
    result = integerPart[i] + result;
    digitsCount++;

    if (digitsCount % 3 === 0 && i !== 0) {
      result = ',' + result;
    }
  }

  if (decimalPart) {
    result += '.' + decimalPart;
  }
  return result;
}

export function generateRandomNumber() {
  const secureRandomNumber = (min: number, max: number) => {
    let randomNumber: number;
  
    if (window?.crypto?.getRandomValues) {
      const randomBuffer = new Uint32Array(1);
      window.crypto.getRandomValues(randomBuffer);
      randomNumber = randomBuffer[0] / (0xffffffff + 1);
    } else {
      randomNumber = Math.random();
    }
    return Math.floor(randomNumber * (max - min + 1) + min);
  };

  const randomNumber1 = secureRandomNumber(10000, 99999);
  const randomNumber2 = secureRandomNumber(10000000, 99999999);
  const randomNumber3 = secureRandomNumber(100000000000000, 999999999999999);

  return `${randomNumber1}-${randomNumber2}-${randomNumber3}`;
}

export function formatToSentenceCase(str : string) {
  if (!str) {
    return '';
  }
  const trimmedStr = str.trim();
  const firstChar = trimmedStr.charAt(0).toUpperCase();
  const restOfStr = trimmedStr.slice(1).toLowerCase();

  return `${firstChar}${restOfStr}`;
}

export function formatCurrencyAndAmount(curr: string, amt: number){
  let symbol: string;

  if(curr === "USD"){
    symbol = '$';
  } else {
    symbol = '';
  }
  return `${symbol}${amt.toFixed(2)}`
}

const convertToCSV = (data:{}[]) => {
  const headers = Object.keys(data[0]).join(",");
  const rows = data.map((item:{}) => Object.values(item).join(","));
  return [headers, ...rows].join("\n");
};

export const exportCSV = (data) => {
  if (!data.length) {
    return;
  } else {
    const csvData = data.map((item) => ({
      key: item.id,
      No: item.id,
      Date: dayjs(item.createdAt).format("DD.MM.YYYY HH:mm"),
      Description: item.description,
      Amount: formatCurrencyAndAmount(item.currency, item.amount),
      Type: formatToSentenceCase(item.cardTransactionType),
      Reference: item.transactionReference,
    }));

    const csvContent = "data:text/csv;charset=utf-8," + convertToCSV(csvData);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "CardTransactions.csv");
    document.body.appendChild(link);
    link.click();
  }
};

export function truncateString(str: string, maxLength: number) {
  if (str?.length && str?.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
}

export function insertSpaceToString(str: string) {
 if(str){
    const regex = /(.{4})/g;
    return str.replace(regex, "$1 ");
  } 
}









