import React from "react";
import { Controller, useForm } from "react-hook-form";
import CustomModal from "../../components/common/CustomModal";
import { Btns,  FormWrapper, ModalContent } from "./style";
import CustomSelect from "../../components/common/CustomSelect";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";

interface Props {
    setState: (data) => void;
    openModal: boolean;
    handleNext: (data) => void;
    defaultAmount?:string
}

export interface formData {
    txRef: string;
    amount: string;
    refundType: string;
    refundReason?: string;
}

const RequestRefundModal = ({
    openModal,
    setState,
    handleNext,
    defaultAmount
}: Props) => {
    const options = [
        { label: "Full payment", value: "full" },
        { label: "Half payment", value: "partial" },
    ];

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<formData>();

    const watchAll = watch()

    const refund = watchAll.refundType === 'full';

    React.useEffect(() => {
        if (refund) {
            setValue('amount', defaultAmount || '');
        } else {
            setValue('amount', '');
        }
    }, [refund, defaultAmount, setValue]);

    const handleCloseModal = () => {
        setState((prev) => ({...prev, openModal: false}))
        setValue('amount',  '');
        setValue('txRef',  '');
        setValue('refundType',  null);
        
    
    }

    
    return (
        <CustomModal
            isModalVisible={openModal}
            onClose={handleCloseModal}
            width="480px"
        >
            <ModalContent>
                <h1>Refund payment</h1>
                <p>
                A refund will be processed and completed within 1-3 working days.{" "}
                </p>
            </ModalContent>
            <Controller
                name="refundType"
                control={control}
                rules={{
                    required: "Field is required",
                    validate: (value) => {
                        if (!value) {
                            setState((prev) => ({
                                ...prev,
                                refundType: null,
                            }));
                            return false; 
                        }
                        return true; 
                    },
                }}
                render={({ field }) => (
                    <FormWrapper>
                        <CustomSelect
                            placeholder="Choose refund type"
                            $width="100%"
                            options={options}
                            onChange={(selectedOption) => {
                                field.onChange(
                                    selectedOption,
                                );
                            }}
                            value={field.value}
                        />
                        {errors?.refundType && (
                            <span className="error">
                                {errors?.refundType?.message}
                            </span>
                        )}
                    </FormWrapper>
                )}
            />

            <FormWrapper>
                <Controller
                    name="amount"
                    rules={{ required: true }}
                    control={control}
                    defaultValue={defaultAmount}
                    render={({ field }) => (
                        <Input
                            label="Refund amount"
                            disabled={refund}
                            disableLabel
                            value={field.value}
                            onChange={field.onChange}
                            
                        />
                    )}
                />
                {errors?.amount?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </FormWrapper>

        
            <FormWrapper>
                <Controller
                    name="refundReason"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                        <Input
                            label="Refund reason (optional)"
                            value={field.value}
                            onChange={field.onChange}
                            disableLabel
                        />
                    )}
                />
            </FormWrapper>

            <Btns>
                <Button
                    theme="alternate"
                    label="Cancel"
                    height="40px"
                    width="210px"
                    onClick={handleCloseModal
                    }
                    
                />
                <Button
                    theme="secondary"
                    label="Refund"
                    height="40px"
                    width="210px"
                    onClick={handleSubmit(handleNext)}
                    testId="confirm"
                />
            </Btns>
        </CustomModal>
    );
};

export default RequestRefundModal;
