import React, { useState, useContext } from "react";
import { Wrapper } from "./style";
import Otp from "../../../../../../../../components/common/OTP/index"
import { SuccessAlertContext } from "../../../../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../.././../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../../../../components/common/Loader";
import { useAppSelector } from "../../../../../../../../app/hooks";
import { sendOTP, verifyOTP } from "../../../../../../../../api/usersAPI";

interface Props {
  makePayment: () => void;
  setVerifyOTP: (args: boolean) => void;
}

const WalletOTP = ({ setVerifyOTP, makePayment }: Props) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userObj } = useAppSelector((state) => state.users);
  const { accountUser } = userObj;
  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const { onShowAlert: onSuccess } = useContext(SuccessAlertContext);

  const handleVerifyOTP = (values: string) => {
    if (values.length < 6) {
      onShowAlert(Error, "The OTP code is not complete");
    } else {
      verifyUserOTP(values, userObj);
    }
  };

  const verifyUserOTP = async (otpString: string, user: { email: string }) => {
    try {
      setIsLoading(true);
      await verifyOTP(otpString, accountUser?.email)
      
      setOtp("");
      setIsLoading(false);
      setVerifyOTP(false);
      makePayment();
    } catch (error) {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (typeof errorObj === "string") {
        errorMessage = errorObj;
      } else if (typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Invalid OTP";
      }

      setIsLoading(false);
      onShowAlert(errorObj, errorMessage);
    }
  };

  const handleResendOTP = async (user: { email: string }) => {
    try {
      setIsLoading(true);
      await sendOTP(user);

      setIsLoading(false);
      setOtp("");
      onSuccess("Check your email for the OTP");
    } catch (error) {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (typeof errorObj === "string") {
        errorMessage = errorObj;
      } else if (typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Resend Failed";
      }

      setIsLoading(false);
      onShowAlert(errorObj, errorMessage);
    }
  };

  return (
    <Wrapper>
      <Loader isLoading={isLoading} />

      <Otp
        value={otp}
        onChange={setOtp}
        smallText="Enter the verification code sent to your email address to send funds."
        buttonLabel="Continue"
        onClose={() => setVerifyOTP(false)}
        onClick={() => handleVerifyOTP(otp)}
        handleClick={() => handleResendOTP(accountUser?.email)}
      />
    </Wrapper>
  );
};

export default WalletOTP;
