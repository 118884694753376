export const transNumOptions = [
  { label: "1-100", value: "1-100" },
  { label: "101-500", value: "101-500" },
  { label: "Greater than 500", value: ">500" },
];

export const fundsSourceList = [
  { label: "Business", value: "Business" },
  { label: "Services", value: "Services" },
];
