import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  font-family: Inter;

  & div[title="account-card"]:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: Inter;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    display: block;
  }
`;

export const EditButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 5px;
  display: flex;
  align-items: center;
  & span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #ef2c5a;
    margin-right: 8px;
  }
`;

export const ButtonDiv = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  & > span {
    margin-right: 8px;
  }
  & > button {
    width: 190px;
    height: 40px;
    border: 1px solid #000000;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > button > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
  & svg path {
    stroke: #000000;
  }
`;

export const EmptyWrapper = styled.div`
  font-family: "Inter";
`;

export const EmptyWrapperTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 8px;
`;

export const EmptyWrapperSubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #464646;
  margin-bottom: 24px;
`;

export const EmptyWrapperButton = styled.button`
  //padding: 12px 20px;
  width: 128px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border: unset;
`;
