import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTransactionsAll, getTransactionsFiltered } from "./transactionsAPI";

export interface TransactionsType {
  pageNo?: number;
  totalCount?: number;
  totalPages?: number;
  transactions?: any[];
}
export interface TransactionState {
  transactions: TransactionsType;
  allTransactions: TransactionsType;
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
}

const initialState: TransactionState = {
  transactions: {},
  allTransactions: {},
  status: "idle",
  isLoading: false,
  error: null,
};

export const transactionsInfo = createAsyncThunk(
  "merchant/transactions",
  async ({ filter }: { filter: any }) => {
    const response = await getTransactionsFiltered(filter);
    return response;
  }
);

export const transactionsAllInfo = createAsyncThunk(
  "merchant/transactions/all",
  async ({ filter }: { filter: any }) => {
    const response = await getTransactionsAll(filter);
    return response;
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(transactionsInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(transactionsInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.transactions = action.payload;
      })
      .addCase(transactionsInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(transactionsAllInfo.fulfilled, (state, action) => {
        state.status = "idle";
        state.allTransactions = action.payload;
      });
  },
});

export default transactionsSlice.reducer;
