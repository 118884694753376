import dayjs from "dayjs";

export const statusOptions = [
  { value: "0", label: "Successful" },
  { value: "1", label: "Pending" },
  { value: "2", label: "Failed" },
  { value: "3", label: "Cancelled" },
  { value: "4", label: "Reversed" },
  { value: "9", label: "All" },
];

export type WireTableData = {
  beneficiaryName: string;
  merchantPay: string;
  recipientPay: string;
  status: string;
  createdAt: string;
  [key: string]: any;
  amountReceived: number;
};

export type WireTable = {
  bulkMerchantPayment: {
    sourceCurrency: string;
    amountReceived: number;
    sourceAmount: number;
    destinationCurrency: string;
    destinationAmount: string;
    paid: boolean;
    bulkMerchantRequestStatus: string;
    merchantBeneficiary: {
      beneficiaryName: string;
      email: string;
      accountNumber: string;
      bankName: string;
      name: string;
      address: string;
      country: string;
    };
    createdAt: string | number | Date | dayjs.Dayjs;
    updatedAt: string | number | Date | dayjs.Dayjs;
    rate: number;
    ref: string;
  };
};

export const initial = {opacity: 0, x: "60%" };
export const animate = { opacity: 1, x: "0", };
export const exit = {opacity: 0, x: "60%"  };