import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
    font-family: 'Athletics';
    font-size: 24px;
    font-weight: 500;
    line-height: 32px; 
    letter-spacing: -0.48px;
    color: #000000;
`;

export const BlockWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-left: 0;
  align-items: center;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  backdrop-filter: blur(4px);
`;

export const BlockBox = styled(motion.div)`
  width: 340px;
  height: 240px;
  background: #ffffff;
  box-shadow: 0px 0px 38px rgba(45, 66, 89, 0.16);
  border-radius: 8px;
  padding: 18px;

  h3 {
    font-family: "Athletics";
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  p {
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 25px;
  }

  @media (min-width: 768px) {
    width: 464px;
    height: 232px;
    padding: 24px;
  }
`;

export const variants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

export const transition = {
  type: "spring",
  stiffness: 300,
  damping: 20,
};
