import React, { useState } from "react";
import { Wrapper } from "./style";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download_icon.svg";

type Props = {
  customButtonTitle?: string;
  onExportClick?: (val: boolean) => void;
};

const ExportByFilter = ({ customButtonTitle, onExportClick }: Props) => {

  return (
    <Wrapper onClick={() => onExportClick(true)}>
   
          <span>{customButtonTitle ?? 'Export'}</span>
          <DownloadIcon />
   
    </Wrapper>
  );
};

export default ExportByFilter;
