import React, { useEffect, useState } from "react";
import {
  PaginationContainer,
  CardContainer,
  PaginationControls,
} from "./style";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";

interface WalletItem {
  key: string;
  transId: string;
  type: string;
  date: string;
  amount: string;
  status: string;
  name: string;
  acct: string;
}

interface WalletCardProps {
  transId: string;
  type: string;
  date: string;
  amount: string;
  status: string;
  name: string;
  acct: string;
}

interface EmptyMobileCardProps {
  title: string;
  subText: string;
}

interface CustomMobileTableProps {
  data?: WalletItem[] | any;
  WalletCard: React.ComponentType<WalletCardProps>;
  EmptyMobileCard: React.ComponentType<EmptyMobileCardProps>;
  currentPage: number;
  totalItemCount: number;
  onPageChange: (page: number, pageSize: number) => void;
  pageSize?: number;
  pageSizeOptions?: number[];
}

const CustomMobileTable: React.FC<CustomMobileTableProps> = ({
  data = [],
  WalletCard,
  EmptyMobileCard,
  currentPage,
  totalItemCount,
  onPageChange,
  pageSize = 10,
  pageSizeOptions = [10, 20, 50],
}) => {
  const handlePageChange: PaginationProps["onChange"] = (page, size) => {
    onPageChange(page, size);
  };

  return (
    <PaginationContainer>
      <CardContainer>
        {data?.length > 0 ? (
          data.map((wal) => (
            <WalletCard
              key={wal.key}
              transId={wal.transId}
              type={wal.type}
              date={wal.date}
              amount={wal.amount}
              status={wal.status.toLowerCase()}
              name={wal.name}
              acct={wal.acct}
            />
          ))
        ) : (
          <EmptyMobileCard
            title="No transactions yet"
            subText="All received or sent transactions will appear here"
          />
        )}
      </CardContainer>
      {data?.length > 0 ? (
        <PaginationControls>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={totalItemCount}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </PaginationControls>
      ) : (
        ""
      )}
    </PaginationContainer>
  );
};

export default CustomMobileTable;
