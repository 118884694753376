import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;
export const TitleParagraph = styled.p`
  font-family: "Athletics";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 8px;
`;

export const SubTitleParagraph = styled.p`
  font-family:  "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #A39D9E;
  margin-bottom: 40px;
  
`;

export const FormBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    margin-bottom: 28px;
    max-width: 400px;
  }
  & > button {
    max-width: 400px;
  }
`;

export const PasswordsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100% !important;
  
  & > div {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const PasswordErrorBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-left: 5px;
    margin-bottom: unset;
  }
`;

export const PasswordDiv = styled.div`
  span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: #A39D9E;
    margin-bottom: 6px;
  }
`;

export const PasswordErrorBoxBody = styled.div`
  border-top: 3px solid #dcdcdc;
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const PasswordErrorBox = styled.div`
  ${(props: { $initialState: boolean }) => {
    if (props.$initialState) {
      return css`
        & p {
          color: #cccccc;
        }
        & ${PasswordErrorBoxWrapper} {
          display: none;
        }
        & ${PasswordErrorBoxBody} {
          border: unset;
        }
        & ${PasswordErrorBoxBody} > div {
          margin-right: 10px;
        }
      `;
    } else {
      return css`
        & ${PasswordErrorBoxBody} > div {
          margin-right: 10px;
        }
      `;
    }
  }}
`;
