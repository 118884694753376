import React from "react";
import { Cards, PageTitle, SubTitle, Wrapper } from "./style";
import MarketingCard from "../../components/marketing/MarketingCard";

const instagram = [
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2Flow%2FRamadan_9.png?alt=media&token=b7bc5ff4-091d-4f61-8508-92b1d769151b",
    title: "Frictionless online payments",
    description:
      "Sell into Africa and give your African consumers a frictionless payments experience online.",
    downloadLink:
      "https://res.cloudinary.com/dcrw8ds16/image/upload/v1633348132/Ramadan_9_lo3x4b.png",
    // "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2FRamadan_9.png?alt=media&token=ecbd4c15-88aa-474c-bbb6-b891b02066f5",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2Flow%2FRamadan_10.png?alt=media&token=323eaabd-e237-48fb-a0d5-bf83c4d941f5",
    title: "Frictionless online payments",
    description:
      "Sell into Africa and give your African consumers a frictionless payments experience online.",
    downloadLink:
      "https://res.cloudinary.com/dcrw8ds16/image/upload/v1633348124/Ramadan_10_yjxqyh.png",
    // "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2FRamadan_10.png?alt=media&token=be22ce6e-f08a-474c-8cfc-58f8845795ac",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2Flow%2FRamadan_11.png?alt=media&token=c784be72-1602-47a9-b5d7-8e2586fb1609",
    title: "Frictionless online payments",
    description:
      "Sell into Africa and give your African consumers a frictionless payments experience online.",
    downloadLink:
      "https://res.cloudinary.com/dcrw8ds16/image/upload/v1633348156/Ramadan_11_bxceys.png",
    // "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2FRamadan_11.png?alt=media&token=8f4cafcf-a7b6-42b9-b5b9-a273d0c3750f",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2Flow%2FRamadan_13.png?alt=media&token=b0e61bcc-f7bf-42b8-9d48-8bef9acaf8b0",
    title: "Frictionless online payments",
    description:
      "Sell into Africa and give your African consumers a frictionless payments experience online.",
    downloadLink:
      "https://res.cloudinary.com/dcrw8ds16/image/upload/v1633348182/Ramadan_13_p6tktz.png",
    // "https://firebasestorage.googleapis.com/v0/b/klasha-merchant.appspot.com/o/public%2Fimages%2Fmarketing%2FRamadan_13.png?alt=media&token=ea32b586-a2b6-4dc9-aff4-f6192e80d8fe",
  },
];

const Marketing = () => (
  <Wrapper>
    <PageTitle>Marketing</PageTitle>
    <SubTitle>
      Tell the world you&apos;ve launched with Klasha! Simply download the
      assets and upload them to your social media profiles or scheduling tool.
      Remember to tag <span>@klasha_inc</span> in your announcements to reach
      even more shoppers and conversions.
    </SubTitle>
    <Cards>
      {instagram.map((card) => (
        <MarketingCard
          key={card.title}
          title={card.title}
          description={card.description}
          url={card.image}
          downloadLink={card.downloadLink}
        />
      ))}
    </Cards>
  </Wrapper>
);

export default Marketing;
