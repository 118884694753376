import React, { useContext } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Title, Inputs, InputBox, CountryBox } from "./style";
import { Button } from "../../../../../../components/common/Button";
import { Input } from "../../../../../../components/common/Input";
import { useAppSelector } from "../../../../../../app/hooks";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { countryOptions } from "./utils";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { InputPhone } from "../../../../../../components/common/InputPhone";
import { createAndUpdateWireBeneficiary } from "../../../../wiresAPI";

type FormInputs = {
  beneficiaryName: string;
  businessName: string;
  country: string;
  bankName: string;
  accNum: string;
  phoneNumber: string;
  beneficiaryEmail: string;
  iban: string;
  swiftCode: string;
  address: string;
  bankAddress: string;
};

const BeneficiaryModal = ({ 
  setShowNewModal, 
  setLoading, 
  getBeneficiaries, 
  showTitle,
} : {
  showTitle: boolean;
  setShowNewModal?: (arg: boolean) => void
  setLoading:(arg: boolean) => void;
  getBeneficiaries: (arg: number) => void;
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const { onShowAlert: onShowSuccessAlert } = useContext(SuccessAlertContext);
  const { businessId } = useAppSelector((state) => state.users);

  const emailRegex =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const country = data.country
    const code = countryOptions.find((c) => c.label === country)

    const payload = {
      name: data.businessName,
      beneficiaryName: data.beneficiaryName,
      country: data.country || "--",
      countryCode: code?.code || "--",
      bankName: data.bankName,
      accountNumber: data.accNum,
      phone: data.phoneNumber,
      email: data.beneficiaryEmail,
      iban: data.iban,
      swiftCode: data.swiftCode,
      business: businessId,
      address: data.address,
      bankAddress: data.bankAddress,
    };

    try {
      setLoading(true)
      await createAndUpdateWireBeneficiary(payload);
      setLoading(false)
      
      if(setShowNewModal){
        setShowNewModal(false);
      }

      onShowSuccessAlert("Beneficiary created successfully");
      getBeneficiaries(businessId);
    } catch (err) {
      setLoading(false)
      onShowErrorAlert(err, err.message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
     {showTitle && <Title>Create new beneficiary</Title>}
     
      <Inputs showTitle={showTitle}>
        <InputBox>
          <Controller
            name="beneficiaryName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary name"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.beneficiaryName?.type === "required" && (
            <span>Please input the beneficiary&apos;s name</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="businessName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Business name"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.businessName?.type === "required" && (
            <span>Please input the beneficiary&apos;s business name</span>
          )}
        </InputBox>
        <CountryBox>
          <Controller
            name="country"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                placeholder="Country"
                options={countryOptions}
                $width="100%"
                field={field}
                showSearch
              />
            )}
          />
          {errors.country?.type === "required" && (
            <p>Please select the beneficiary&apos;s country</p>
          )}
        </CountryBox>
        <InputBox>
          <Controller
            name="bankName"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank name"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.bankName?.type === "required" && (
            <span>Please input the beneficiary&apos;s bank name</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="bankAddress"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Bank address"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.bankAddress?.type === "required" && (
            <span>Please input the beneficiary&apos;s bank address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="accNum"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                label="Account number"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.accNum?.type === "required" && (
            <span>Please input the beneficiary&apos;s account number</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputPhone
                label="Phone number"
                field={field}
                isSecondaryBackground
              />
            )}
          />
          {errors.phoneNumber?.type === "required" && (
            <span>Please input the beneficiary&apos;s phone number</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="beneficiaryEmail"
            control={control}
            rules={{
              required: true,
              pattern: emailRegex,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary email"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.beneficiaryEmail?.type === "required" && (
            <span>Please input the beneficiary&apos;s email</span>
          )}
          {errors.beneficiaryEmail?.type === "pattern" && (
            <span>Please enter a valid email address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="address"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Beneficiary address"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
          {errors.address?.type === "required" && (
            <span>Please input the beneficiary&apos;s address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="iban"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="IBAN"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="swiftCode"
            control={control}
            render={({ field }) => (
              <Input
                type="text"
                label="Swift code"
                field={field}
                labelColor="#FFFFFF"
                disableLabel
              />
            )}
          />
        </InputBox>
      </Inputs>

      <Button
        label="Continue"
        theme={showTitle ? "secondary" : "primary"}
        height={showTitle ? "40px" : "48px"}
        fontSize="12px"
        type="submit"
      />
    </form>
  );
};

export default BeneficiaryModal;
