import { useState, useEffect } from 'react';
import { useWindowSize } from '@reach/window-size';

const usePostHogDeviceTypeCapture = () => {
  const [agent, setAgent] = useState('PC');
  const { width } = useWindowSize();

  useEffect(() => {
    const newAgent = width <= 768 ? 'Mobile' : 'PC'; 
    setAgent(newAgent);
  }, []);

  return agent;
};

export default usePostHogDeviceTypeCapture;
