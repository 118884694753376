import React, { useState } from "react";
import {
    Flex,
    Paragraph,
    SubHeading,
    Heading,
    DetailsWrapper,
    CopyIcon,
    Check,
} from "../styles";

interface Props {
    heading?: string;
    transactionId?: string;
    transactionAmount?: string;
    currency?: string;
    transferFee?: string;
    rate?: string;
    totalDebit?: string;
    request?:string;
    type?: string;
    date?: string;
    accountName?: string;
    accountNumber?: string;
    bankName?: string;
    isBeneficiary?: boolean;
}

const TransactionDetails = ({
    heading,
    transactionId,
    transactionAmount,
    currency,
    transferFee,
    rate,
    totalDebit,
    type,
    date,
    accountName,
    accountNumber,
    bankName,
    request,
    isBeneficiary,
}: Props) => {
    const [copy, setCopy] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

    return (
        <div>
            <DetailsWrapper>
                <Heading>{heading}</Heading>
                {!isBeneficiary && (
                    <>
                        <Flex>
                            <Paragraph>
                                Transaction ID
                            </Paragraph>
                            <Flex>
                                {copy ? <Check /> : <CopyIcon onClick={() => onCopyLinkToClipboard(request)} data-testid="copy-icon"/>
 }
                                <SubHeading>
                                    {transactionId}
                                </SubHeading>
                            </Flex>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Transaction amount
                            </Paragraph>
                            <SubHeading>
                                {transactionAmount}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Payout currency
                            </Paragraph>
                            <SubHeading>
                                {currency}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Transfer fee
                            </Paragraph>
                            <SubHeading>
                                {transferFee}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Exchange rate
                            </Paragraph>
                            <SubHeading>{rate}</SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Total debit
                            </Paragraph>
                            <SubHeading>
                                {totalDebit}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>Date</Paragraph>
                            <SubHeading>{date}</SubHeading>
                        </Flex>
                    </>
                )}

                {isBeneficiary && (
                    <>
                        <Flex>
                            <Paragraph>
                                Account name
                            </Paragraph>
                            <SubHeading>
                                {accountName}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>
                                Account number
                            </Paragraph>
                            <SubHeading>
                                {accountNumber}
                            </SubHeading>
                        </Flex>

                        <Flex>
                            <Paragraph>Bank name</Paragraph>
                            <SubHeading>
                                {bankName}
                            </SubHeading>
                        </Flex>
                    </>
                )}
            </DetailsWrapper>
        </div>
    );
};

export default TransactionDetails;
