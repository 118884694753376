import React, { useContext } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Wrapper, Header, Inputs, InputBox, Footer } from "./style";
import { Button } from "../../../../../../common/Button";
import { Input } from "../../../../../../common/Input";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import CustomSelect from "../../../../../../common/CustomSelect";
import { addUpdateBusinessContacts } from "../../../../../../../pages/Settings/settingsSlice";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import { InputPhone } from "../../../../../../common/InputPhone";

type FormInputs = {
  name: string;
  email: string;
  role: string;
  phoneNumber: string;
};

const ContactModal = ({
  header,
  initialValues,
  roleOptions,
  onClose,
}: {
  header: string;
  initialValues: any;
  roleOptions: any;
  onClose: () => void;
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({
    defaultValues: {
      name: initialValues?.name,
      email: initialValues?.email,
      phoneNumber: initialValues?.phone,
      role: initialValues?.businessStaffRole?.id,
    },
  });

  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onShowSuccessAlert } = useContext(SuccessAlertContext);

  const { businessId } = useAppSelector((state) => state.users);

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      const payload = {
        name: data.name,
        businessStaffRole: data.role,
        phone: data.phoneNumber,
        email: data.email,
        business: businessId,
        id: initialValues?.id,
      };
      await dispatch(addUpdateBusinessContacts(payload)).unwrap();
      onShowSuccessAlert("Contact saved");
      onClose();
    } catch (err) {
      let theErrorMessage;
      if (err.message === "Request failed with status code 409") {
        theErrorMessage = "Duplicate contact not saved";
      } else {
        theErrorMessage = err.message;
      }
      onShowErrorAlert(err, theErrorMessage);
    }
  };

  const onCancel = () => {
    reset(
      {
        name: null,
        email: null,
        phoneNumber: null,
        role: null,
      },
      {
        keepErrors: true,
        keepTouched: false,
      }
    );
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>{header}</Header>
      <Inputs>
        <InputBox>
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Name"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Email"
                field={field}
                labelColor="#FFFFFF"
              />
            )}
          />
          {errors.email?.type === "required" && (
            <span>Please input the email</span>
          )}
          {errors.email?.type === "pattern" && (
            <span>Please enter a valid email address</span>
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="role"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <CustomSelect
                options={roleOptions}
                $height="48px"
                $width="100%"
                field={field}
                placeholder="Role"
              />
            )}
          />
        </InputBox>
        <InputBox>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <InputPhone label="Phone number" field={field} />
            )}
          />
          {errors.phoneNumber?.type === "required" && (
            <span>Please input the phone number</span>
          )}
        </InputBox>
      </Inputs>
      <Footer>
        <Button
          label="Cancel"
          width="184px"
          type="button"
          theme="alternate"
          height="48px"
          fontSize="14px"
          onClick={onCancel}
        />
        <Button
          label="Save"
          width="184px"
          type="submit"
          theme="secondary"
          height="48px"
          fontSize="14px"
        />
      </Footer>
    </Wrapper>
  );
};

export default ContactModal;
