import React from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import { Button } from "../../../../../../components/common/Button";
import { Input } from "../../../../../../components/common/Input";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { FormWrapper } from "../../style";
import CustomDatePicker from "../../../../../../components/common/CustomDateRangePicker/CustomDatePicker";
import useCountryOptions from "../../../../../../app/hooks/useCountryList";
import { updateFormData } from "../../../../redux/formDataSlice";
import {
    companyEnterprise,
    industries,
} from "../../../../static";

interface Props {
    handleNext: () => void;
}

interface formData {
    companyName: string;
    companyRegistrationNumber: string;
    companyCountryIncorporation: string;
    date: string;
    enterPriseType?: string;
    companyType?: string;
    industry: string;
    website: string;
    tax: string;
    code: string;
    city: string;
    addressCountry: string;
    incorporationCountry: string;
}

const Company = ({ handleNext }: Props) => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<formData>() || {};
    const dispatch = useAppDispatch();
    const formData = useAppSelector(
        (state) => state.formData,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    const countryIncorporation = useCountryOptions();

    const onSubmit = (data: formData) => {
        const formattedDate = dayjs(data.date);
        const formattedDateString =
            formattedDate.format("YYYY-MM-DD");
        data.date = formattedDateString;
        dispatch(updateFormData(data));
        handleNext();
    };

    React.useEffect(() => {
        setValue(
            "companyRegistrationNumber",
            formData?.companyRegistrationNumber,
        );
        setValue(
            "companyCountryIncorporation",
            formData?.companyCountryIncorporation,
        );
        setValue(
            "enterPriseType",
            formData?.enterPriseType,
        );
        setValue("companyType", formData?.companyType);
        setValue("industry", formData?.industry);
        setValue("tax", formData?.tax);
        setValue("code", formData?.code);
        // setValue('date', formData.date.format('YYYY-MM-DD'));
        setValue(
            "addressCountry",
            formData?.addressCountry,
        );
        setValue("city", formData?.city || business?.city);
    }, [formData, setValue]);
    

    return (
        <>
            <FormWrapper>
                <div></div>
                <div>
                    <Controller
                        defaultValue={business?.name}
                        name="companyName"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Legal name"
                                value={field.value}
                                onChange={field.onChange}
                                hasError={
                                    errors?.companyName
                                        ?.type ===
                                    "required"
                                }
                            />
                        )}
                    />
                    {errors?.companyName?.type ===
                        "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>
                <div>
                    <Controller
                        name="companyRegistrationNumber"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Registration number"
                                value={field.value}
                                onChange={field.onChange}
                                hasError={
                                    errors
                                        ?.companyRegistrationNumber
                                        ?.type ===
                                    "required"
                                }
                            />
                        )}
                    />
                    {errors?.companyRegistrationNumber
                        ?.type === "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
    <Controller
        name="companyCountryIncorporation"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
            <CustomSelect
                placeholder="Country incorporation"
                showSearch
                field={field}
                $width="100%"
                options={countryIncorporation}
                filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            />
        )}
    />
    {errors?.companyCountryIncorporation?.type === "required" && (
        <span className="error">
            Field is required
        </span>
    )}
</div>


                <div>
                    <Controller
                        name="date"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <CustomDatePicker
                                {...field}
                                placeholder="Date of incorporation"
                            />
                        )}
                    />
                    {errors?.date?.type === "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="enterPriseType"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                placeholder="Enterprise type"
                                options={companyEnterprise.map(
                                    (role) => ({
                                        value: role.value,
                                        label: role.label,
                                    }),
                                )}
                                $width="100%"
                                field={field}
                            />
                        )}
                    />
                    {errors?.enterPriseType?.type ===
                        "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="industry"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                showSearch
                                placeholder="Industry"
                                options={industries.map(
                                    (role) => ({
                                        value: role,
                                        label: role,
                                    }),
                                )}
                                field={field}
                                $width="100%"
                            />
                        )}
                    />
                    {errors?.industry?.type ===
                        "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="website"
                        rules={{ required: true }}
                        defaultValue={business?.website}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Website"
                                value={field.value}
                                onChange={field.onChange}
                                hasError={
                                    errors?.website
                                        ?.type ===
                                    "required"
                                }
                            />
                        )}
                    />
                    {errors?.website?.type ===
                        "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="tax"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                placeholder="Country Tax Residency"
                                options={
                                    countryIncorporation
                                }
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                field={field}
                                showSearch
                                $width="100%"
                            />
                        )}
                    />
                    {errors?.tax?.type === "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="city"
                        defaultValue={business?.city}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="City"
                                value={field.value}
                                onChange={field.onChange}
                                hasError={
                                    errors?.city?.type ===
                                    "required"
                                }
                            />
                        )}
                    />
                    {errors?.city?.type === "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="code"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                label="Postal code"
                                value={field.value}
                                onChange={field.onChange}
                                hasError={
                                    errors?.code?.type ===
                                    "required"
                                }
                            />
                        )}
                    />
                    {errors?.code?.type === "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <div>
                    <Controller
                        name="addressCountry"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <CustomSelect
                                placeholder="Country"
                                options={
                                    countryIncorporation
                                }
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch
                                field={field}
                                $width="100%"
                            />
                        )}
                    />
                    {errors?.addressCountry?.type ===
                        "required" && (
                        <span className="error">
                            Field is required
                        </span>
                    )}
                </div>

                <Button
                    label="Continue"
                    height="40px"
                    onClick={handleSubmit(onSubmit)}
                />
            </FormWrapper>
        </>
    );
};

export default Company;
