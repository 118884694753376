import React from "react";
import { BodyDiv, Footer, HeaderTitle, SubtitleText, Wrapper } from "./style";
import { ReactComponent as SuccessMailIcon } from "../assets/mail-sent.svg";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";

const SignupSuccess = ({
  onResendEmailClick,
}: {
  onResendEmailClick: () => void;
}) => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate("/login");
  };
  return (
    <Wrapper>
      <BodyDiv>
        <SuccessMailIcon />
        <HeaderTitle>Account confirmation</HeaderTitle>
        <SubtitleText>
          Check your email to confirm your account
        </SubtitleText>
      </BodyDiv>
      <Footer>
        <Button
          label="Log in"
          type="button"
          theme="primary"
          height="40px"
          fontSize="12px"
          onClick={onLoginClick}
        />
        <Button
          label="Resend email"
          type="button"
          theme="alternate"
          height="40px"
          fontSize="12px"
          onClick={onResendEmailClick}
        />
      </Footer>
    </Wrapper>
  );
};

export default SignupSuccess;
