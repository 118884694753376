import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    h1 {
        font-family: "Athletics";
        font-size: 20px;
        font-weight: 500;
        line-height: 32px; 
        letter-spacing: -0.4px;
        color: #000000;
        margin: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid #F5F5F5;
    }
`;

export const Section = styled.div`
    font-family: "Inter";
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
`;

export const Line = styled.div`
    height: 1px;
    background-color: #F5F5F5;
`;

export const Left = styled.div`
    display: flex;
    gap: 15px;

    h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 28px; 
        color: #000000;
        margin: 0;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
        color: #484848;
        margin-top: 3px;
    }
`;