import React from "react";
import { Button } from "../../../../../../components/common/Button";
import { Input } from "../../../../../../components/common/Input";
import { Controller, useForm } from "react-hook-form";
import {
    FormWrapper,
    Question,
    YesNoDiv,
} from "../../style";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import ComplianceRadio from "../../../../../../components/compliance/ComplianceRadio";
import { Radio } from "antd";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import useCountryOptions from "../../../../../../app/hooks/useCountryList";
import { updateFormData } from "../../../../redux/formDataSlice";

interface Props {
    handleNext?: () => void;
}

interface formData {
    flowOfFunds: string;
    depositorIndustry: string;
    beneficiaryIndustry: string;
    requiredCurrency: string;
    sourceCountry: string;
    destinationCountry: string;
    monthlyTransactions: string;
    annualVolume: string;
    adverseMedia: string;
    customerDueDiligence: string;
    fx: string;
    UBO: boolean;
    AML: boolean;
}

const AdditionalCompany = ({ handleNext }: Props) => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<formData>() || {};
    const dispatch = useAppDispatch();
    const formData = useAppSelector(
        (state) => state.formData,
    );
    const countryIncorporation = useCountryOptions()

    const onSubmit = (data: formData) => {
        dispatch(updateFormData(data));
        handleNext();
    };
   

    React.useEffect(() => {
        setValue("flowOfFunds", formData?.flowOfFunds);
        setValue("depositorIndustry", formData?.depositorIndustry);
        setValue("beneficiaryIndustry", formData?.beneficiaryIndustry);
        setValue(
            "requiredCurrency",
            formData?.requiredCurrency,
        );
        setValue("sourceCountry", formData?.sourceCountry);
        setValue(
            "destinationCountry",
            formData?.destinationCountry,
        );
        setValue(
            "monthlyTransactions",
            formData?.monthlyTransactions,
        );
        setValue("annualVolume", formData?.annualVolume);
        setValue("fx", formData?.fx);
        setValue("adverseMedia", formData?.adverseMedia);
        setValue("customerDueDiligence", formData?.customerDueDiligence);
        setValue("requiredCurrency", "USD");


    }, [formData, setValue]);
    return (
        <FormWrapper>
            <div></div>
            <div>
                <Controller
                    name="flowOfFunds"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Rationale for flow of funds"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={
                                errors?.flowOfFunds
                                    ?.type === "required"
                            }
                        />
                    )}
                />
                {errors?.flowOfFunds?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>
            <div>
                <Controller
                    name="depositorIndustry"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Depositor's industries"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={
                                errors?.depositorIndustry
                                    ?.type === "required"
                            }
                        />
                    )}
                />
                {errors?.depositorIndustry?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="beneficiaryIndustry"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Beneficiary's industry"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={
                                errors?.beneficiaryIndustry?.type ===
                                "required"
                            }
                        />
                    )}
                />
                {errors?.beneficiaryIndustry?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
            <Controller
                name="requiredCurrency"
                defaultValue="USD"
                rules={{
                    required: 'Field is required',
                    validate: value =>
                        ['USD'].includes(value.toUpperCase()) ||
                        'Invalid currency format.'
                }}
                control={control}
                render={({ field }) => (
                    <Input
                        label="Required currency"
                        disabled
                        value={field.value}
                        onChange={field.onChange}
                        hasError={!!errors?.requiredCurrency}
                    />
                )}
            />
            {errors?.requiredCurrency?.message && (
                <span className="error">
                    {errors.requiredCurrency.message}
                </span>
            )}
        </div>

            <div>
                <Controller
                    name="sourceCountry"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <CustomSelect
                            placeholder="Source country"
                            options={countryIncorporation}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            field={field}
                            $width="100%"
                        />
                    )}
                />
                {errors?.sourceCountry?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="destinationCountry"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <CustomSelect
                            placeholder="Destination country"
                            options={countryIncorporation}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            field={field}
                            $width="100%"
                        />
                    )}
                />
                {errors?.destinationCountry?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="monthlyTransactions"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="No. of monthly transactions"
                            value={field.value}
                            type="number"
                            onChange={field.onChange}
                            hasError={
                                errors?.monthlyTransactions
                                    ?.type === "required"
                            }
                        />
                    )}
                />
                {errors?.monthlyTransactions?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="annualVolume"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Expected annual volume (USD)"
                            value={field.value}
                            type="number"
                            onChange={field.onChange}
                            hasError={
                                errors?.annualVolume
                                    ?.type === "required"
                            }
                        />
                    )}
                />
                {errors?.annualVolume?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="fx"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="FX %"
                            value={field.value}
                            type="number"
                            onChange={field.onChange}
                            hasError={
                                errors?.fx?.type ===
                                "required"
                            }
                        />
                    )}
                />
                {errors?.fx?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="adverseMedia"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Question>
                            <p>
                                Do any
                                Directors/UBOs/Controllers
                                have any adverse media,
                                sanctions or PEP status
                                associated to them?
                            </p>
                            <YesNoDiv>
                                <Radio.Group {...field}>
                                    <ComplianceRadio
                                        label="Yes"
                                        value="yes"
                                    />
                                    <ComplianceRadio
                                        label="No"
                                        value="no"
                                    />
                                </Radio.Group>
                            </YesNoDiv>
                        </Question>
                    )}
                />
                {errors?.adverseMedia?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="customerDueDiligence"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Question>
                            <p>
                                As per your AML Policy
                                please confirm that you have
                                completed the customer due
                                diligence on your customer?
                            </p>
                            <YesNoDiv>
                                <Radio.Group {...field}>
                                    <ComplianceRadio
                                        label="Yes"
                                        value="yes"
                                    />
                                    <ComplianceRadio
                                        label="No"
                                        value="no"
                                    />
                                </Radio.Group>
                            </YesNoDiv>
                        </Question>
                    )}
                />
                {errors?.customerDueDiligence?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>
            <Button
                label="Continue"
                height="40px"
                onClick={handleSubmit(onSubmit)}
            />
        </FormWrapper>
    );
};

export default AdditionalCompany;
