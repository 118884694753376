import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 10000;
  pointer-events: auto;

  ${(props: { $isComponentType: boolean }) => {
    if (props.$isComponentType) {
      return css`
        height: 100%;
        width: 100%;
        position: absolute;
        & svg {
          width: 18px;
        }
        pointer-events: none;
      `;
    }
    return css`
      height: 100vh;
      width: 100vw;
      //width: calc(100vw - 280px);
      //margin-left: 280px;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.1);
    `;
  }}
`;

export const LoaderContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SpinDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    margin-left: ${(props: { $isNotFull?: boolean }) =>
      !props.$isNotFull ? "0" : "280px"};
  }
`;

export const SpinLogo = styled.div`
  & > svg {
    width: 40px;
  }
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation-delay: -0.45s;
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 1px solid #ef2c5a;
  border-radius: 50%;
  padding: ${(props: { $isComponentType }) =>
    props.$isComponentType ? "18px" : "60px"};
  animation: spinner 0.9s cubic-bezier(0.5, 0, 0.5, 1)
    infinite;
  border-color: #ef2c5a rgba(239, 44, 90, 0.6)
    rgba(239, 44, 90, 0.3) rgba(239, 44, 90, 0.1);
  z-index: 1;
`;

// export const SpinDiv = styled.div``;
// export const SpinDiv = styled.div``;
