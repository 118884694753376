import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getBusinessWallet,
  getCurrentDayTotalSales,
  getCurrentMonthSalesData,
  getCurrentMonthTotalSales,
  getCurrentWeekSalesData,
  getCurrentWeekTotalSales,
  getLastSevenDaysSales,
  getLastThirtyDaysSales,
  getMerchantBusiness,
  getPreviousMonthSalesData,
  getPreviousMonthTotalSales,
  getFilteredSales,
} from "./dashboardAPI";

export interface UserState {
  token: string;
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: boolean;
  error: string | null;
  thirtySales: any[];
  sevenSales: any[];
  filteredSales: any[];
  daysTotal: any[];
  weeksTotal: any[];
  monthsTotal: any[];
  previousMonthsTotal: any[];
  weeksSales: any[];
  monthsSales: any[];
  previousMonthsSales: any[];
  walletStatus: boolean;
}

const initialState: UserState = {
  token: "",
  status: "idle",
  isLoading: false,
  error: null,
  thirtySales: [],
  sevenSales: [],
  filteredSales: [],
  daysTotal: [],
  weeksTotal: [],
  monthsTotal: [],
  previousMonthsTotal: [],
  weeksSales: [],
  monthsSales: [],
  previousMonthsSales: [],
  walletStatus: false,
};

export const filteredDaysSales = createAsyncThunk(
  "merchant/sales/filtered",
  async ({ businessId, date, values}: { businessId: string; date: string, values?:any}) => {
    const response = await getFilteredSales(businessId, date, values);
    return response;
  }
);

export const sevenDaysSales = createAsyncThunk(
  "merchant/sales/seven",
  async ({ businessId }: { businessId: string }) => {
    const response = await getLastSevenDaysSales(businessId);
    return response;
  }
);

export const thirtyDaysSales = createAsyncThunk(
  "merchant/sales/thirty",
  async ({ businessId }: { businessId: string }) => {
    const response = await getLastThirtyDaysSales(businessId);
    return response;
  }
);

export const currentDayTotalSales = createAsyncThunk(
  "merchant/sales/day/sum",
  async ({ businessId }: { businessId: string }) => {
    const response = await getCurrentDayTotalSales(businessId);
    return response;
  }
);

export const currentWeekTotalSales = createAsyncThunk(
  "merchant/sales/week/sum",
  async ({ businessId }: { businessId: string }) => {
    const response = await getCurrentWeekTotalSales(businessId);
    return response;
  }
);

export const currentMonthTotalSales = createAsyncThunk(
  "merchant/sales/month/sum",
  async ({ businessId }: { businessId: string }) => {
    const response = await getCurrentMonthTotalSales(businessId);
    return response;
  }
);

export const previousMonthTotalSales = createAsyncThunk(
  "merchant/sales/last/month/sum",
  async ({ businessId }: { businessId: string }) => {
    const response = await getPreviousMonthTotalSales(businessId);
    return response;
  }
);

export const currentWeekSalesData = createAsyncThunk(
  "merchant/sales/week",
  async ({ businessId }: { businessId: string }) => {
    const response = await getCurrentWeekSalesData(businessId);
    return response;
  }
);

export const currentMonthSalesData = createAsyncThunk(
  "merchant/sales/month",
  async ({ businessId }: { businessId: string }) => {
    const response = await getCurrentMonthSalesData(businessId);
    return response;
  }
);

export const previousMonthSalesData = createAsyncThunk(
  "merchant/sales/last/month",
  async ({ businessId }: { businessId: string }) => {
    const response = await getPreviousMonthSalesData(businessId);
    return response;
  }
);

export const merchantBusinessDetail = createAsyncThunk(
  "merchant/business",
  async (businessId: string) => getMerchantBusiness(businessId)
);

export const merchantBusinessWallet = createAsyncThunk(
  "merchant/business/wallet",
  async (businessId: string) => getBusinessWallet(businessId)
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // increment: (state) => {
    //   // Redux Toolkit allows us to write "mutating" logic in reducers. It
    //   // doesn't actually mutate the state because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes
    //   state.value += 1;
    // },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action: PayloadAction<number>) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(filteredDaysSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(filteredDaysSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.filteredSales = action.payload;
      })
      .addCase(filteredDaysSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(thirtyDaysSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(thirtyDaysSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.thirtySales = action.payload;
      })
      .addCase(thirtyDaysSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(sevenDaysSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sevenDaysSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.sevenSales = action.payload;
      })
      .addCase(sevenDaysSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(currentDayTotalSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(currentDayTotalSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.daysTotal = action.payload;
      })
      .addCase(currentDayTotalSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(currentWeekTotalSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(currentWeekTotalSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.weeksTotal = action.payload;
      })
      .addCase(currentWeekTotalSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(currentMonthTotalSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(currentMonthTotalSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.monthsTotal = action.payload;
      })
      .addCase(currentMonthTotalSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(previousMonthTotalSales.pending, (state) => {
        state.status = "loading";
      })
      .addCase(previousMonthTotalSales.fulfilled, (state, action) => {
        state.status = "idle";
        state.previousMonthsTotal = action.payload;
      })
      .addCase(previousMonthTotalSales.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(currentWeekSalesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(currentWeekSalesData.fulfilled, (state, action) => {
        state.status = "idle";
        state.weeksSales = action.payload;
      })
      .addCase(currentWeekSalesData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(currentMonthSalesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(currentMonthSalesData.fulfilled, (state, action) => {
        state.status = "idle";
        state.monthsSales = action.payload;
      })
      .addCase(currentMonthSalesData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(previousMonthSalesData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(previousMonthSalesData.fulfilled, (state, action) => {
        state.status = "idle";
        state.previousMonthsSales = action.payload;
      })
      .addCase(previousMonthSalesData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(merchantBusinessDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBusinessDetail.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(merchantBusinessDetail.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(merchantBusinessWallet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBusinessWallet.fulfilled, (state, action) => {
        state.status = "idle";

        const businessWalletStatus = action.payload[0]?.enabled;
        state.walletStatus = businessWalletStatus;
      })
      .addCase(merchantBusinessWallet.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export default dashboardSlice.reducer;
