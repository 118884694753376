import React, { useState, useContext } from "react";
import { BlockWrapper, BlockBox, variants, transition } from "./style";
import { Button } from "../.././../../components/common/Button";
import Loader from "../../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { setCard, setCardBalance, setCardTransactions } from "../../CardSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getBusinessKeys, getCard, getCardBalance, getCardTransactions, unBlockCard } from "../../CardsApi";

type Props = {
  setUnBlockCard: (arg: boolean) => void;
};

const UnBlockCard = ({ setUnBlockCard }: Props) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { card } = useAppSelector((state) => state.cards);
  const { businessId, userObj } = useAppSelector((state) => state.users);

  const inactiveCard = card ? card.find((c) => c.isActive === false) : {};
  const cardId = inactiveCard  ? inactiveCard.cardId : '';

  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const { onShowAlert: onSuccess } = useContext(SuccessAlertContext);

  const handleUnBlockCard = async () => {
    try {
      setIsLoading(true);
      const publicKey = await getBusinessKeys(businessId);
      localStorage.setItem("key", publicKey.publicKey);

      if (publicKey) {
        const unBlockedData = await unBlockCard(cardId);
        
        if (unBlockedData) {
          const data = await getCard(userObj.id);
          dispatch(setCard(data));

          const activeCard = data.find((c) => c.isActive === true) || null;
          if(activeCard) {
            const id = activeCard?.cardId ;

            const cardBalance = await getCardBalance(id);
            dispatch(setCardBalance(cardBalance.data.balance));
            const cardTrans = await getCardTransactions(
              id,
              0,
              10
            );
            dispatch(setCardTransactions(cardTrans));
          }
        }
      }

      setIsLoading(false);
      setUnBlockCard(false);
      onSuccess("Card Unblocked.");
      localStorage.removeItem("key");
    } catch (err) {
      localStorage.removeItem("key");
      const errorObj = err?.response?.data;
      let errorMessage = "";

      if (errorObj && typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (errorObj && typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Failed. Try again";
      }

      setIsLoading(false);
      setUnBlockCard(false);
      onShowAlert(errorObj, errorMessage);
    }
    setIsLoading(false);
  };

  return (
    <BlockWrapper>
      <Loader isLoading={isLoading} />

      <BlockBox
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={transition}
      >
        <h3>Unblock card</h3>
        <p>
          Do you want to confirm the unblocking of this card? While the card is
          unblocked, you will be able to make any payments.
        </p>
        <div>
          <Button
            width="100%"
            label="No, cancel"
            theme="alternate"
            height="40px"
            fontSize="12px"
            onClick={() => setUnBlockCard(false)}
          />
          <Button
            width="100%"
            label="Yes, Unblock"
            theme="primary"
            height="40px"
            fontSize="12px"
            onClick={handleUnBlockCard}
          />
        </div>
      </BlockBox>
    </BlockWrapper>
  );
};

export default UnBlockCard;
