import styled from "styled-components";
import { Input } from "antd";

type Props = {
  width: string;
  height: string;
  fontSize: string;
}

export const SearchInput = styled(Input)<Props>`
  font-family: "Inter";
  width: 100%;
  height: ${({height}) => height ? height : '40px'};
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #000000;
  font-size: ${({fontSize}) => fontSize ? fontSize : '14px'};

  p {
    cursor: pointer;
    margin: 0;
  }

  &:hover {
    border: 1px solid #a3a3a3 !important;
  }
  &:focus {
    border: 1px solid #000000 !important;
  }

  @media (min-width: 768px){
    width: ${({width}) => width ? width : '180px'};
  }
`;