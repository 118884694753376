import React from "react";
import { Controller, useForm } from "react-hook-form";
import CustomModal from "../../../components/common/CustomModal";
import { Btns, Content, InputWrapper } from "../styles";
import { Input } from "../../../components/common/Input";
import { Button } from "../../../components/common/Button";
import CustomSelect from "../../../components/common/CustomSelect";

interface Props {
    setState: (data) => void;
    openModal: boolean;
    handleNext: (data) => void;
    formRef?: any;
    apiValue?: boolean;
    transaction?: any;
}

export interface formData {
    txRef: string;
    amount: string;
    refundType: string;
    refundReason?: string;
}

const RequestRefundModal = ({
    openModal,
    setState,
    handleNext,
    formRef,
    apiValue,
    transaction,
}: Props) => {
    const options = [
        { label: "Full payment", value: "full" },
        { label: "Half payment", value: "partial" },
    ];

    const {
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm<formData>() || {};

    const watchAll = watch();
    const DEFAULTAMOUNT = transaction?.destinationAmount;

    const refund = watchAll.refundType === "full";

    React.useEffect(() => {
        if (refund) {
            setValue("amount", DEFAULTAMOUNT || "");
        } else {
            setValue("amount", "");
        }
    }, [refund, DEFAULTAMOUNT, setValue]);

    const handleCloseModal = () => {
        setState((prev) => ({
            ...prev,
            openModal: false,
            transaction: null,
            apiValue: false,
        }));
        setValue("amount", "");
        setValue("txRef", "");
        setValue("refundType", null);
    };

    return (
        <CustomModal
            isModalVisible={openModal}
            onClose={handleCloseModal}
            width="480px"
        >
            <Content>
                <h1>Refund payment</h1>
                <p>
                A refund will be processed and completed within 1-3 working days
{" "}
                </p>
            </Content>

            <InputWrapper>
                <Controller
                    name="txRef"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Transaction ID"
                            value={field.value}
                            onChange={field.onChange}
                            disableLabel
                        />
                    )}
                />
                {errors?.txRef?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </InputWrapper>

            {apiValue && (
                <div>
                    <Controller
                        name="refundType"
                        control={control}
                        rules={{
                            required: "Field is required",
                            validate: (value) => {
                                if (!value) {
                                    setState((prev) => ({
                                        ...prev,
                                        refundType: null,
                                    }));
                                    return false;
                                }
                                return true;
                            },
                        }}
                        render={({ field }) => (
                            <InputWrapper>
                                <CustomSelect
                                    placeholder="Refund Type"
                                    $width="100%"
                                    options={options}
                                    onChange={(
                                        selectedOption,
                                    ) => {
                                        field.onChange(
                                            selectedOption,
                                        );
                                    }}
                                    value={field.value}
                                />
                                {errors?.refundType && (
                                    <span className="error">
                                        {
                                            errors
                                                ?.refundType
                                                ?.message
                                        }
                                    </span>
                                )}
                            </InputWrapper>
                        )}
                    />
                    <InputWrapper>
                        <Controller
                            name="amount"
                            defaultValue={DEFAULTAMOUNT}
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="Amount"
                                    disabled={refund}
                                    disableLabel
                                    value={field.value}
                                    onChange={
                                        field.onChange
                                    }
                                />
                            )}
                        />
                        {errors?.amount?.type ===
                            "required" && (
                            <span className="error">
                                Field is required
                            </span>
                        )}
                    </InputWrapper>

                    <InputWrapper>
                        <Controller
                            name="refundReason"
                            control={control}
                            rules={{ required: false }}
                            render={({ field }) => (
                                <Input
                                    label="Refund reason (optional)"
                                    value={field.value}
                                    onChange={
                                        field.onChange
                                    }
                                    disableLabel
                                />
                            )}
                        />
                    </InputWrapper>
                </div>
            )}

            <Btns>
                <Button
                    theme="alternate"
                    label="Cancel"
                    height="40px"
                    width="210px"
                    onClick={handleCloseModal}
                />
                <Button
                    theme="secondary"
                    label="Confirm"
                    height="40px"
                    width="210px"
                    onClick={handleSubmit(handleNext)}
                    ref={formRef}
                    testId="confirm"
                />
            </Btns>
        </CustomModal>
    );
};

export default RequestRefundModal;
