import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    gap: 10px;

    h1 {
        font-family: "Athletics";
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.48px;  
        color: #000000; 
        margin: 10px 0 0 0;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; 
        color: #8F8F8F;
    }

    button {
        margin-top: 20px;
    }
`;