import React from "react";
import styled from "styled-components";

interface FlagIconProps {
    length: number;
  }


const FlagIcon = styled.span<FlagIconProps>`
  background-size: contain;
  height: ${(props: { length: number }) => props.length ? `${props.length}px` : "25px"};
  width: ${(props: { length: number }) => props.length ? `${props.length}px` : "25px"};
  border-radius: 50px;
  margin-right: 8px;
`

const CurrencyFlag = ({ currency, length }: {currency: "USD" | "NGN", length?: number}) => {
  return (<FlagIcon className={`currency-flag currency-flag-${currency.toLowerCase()}`} length={length} />)
}

export default CurrencyFlag;