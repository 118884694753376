import { Input } from "antd";
import styled from "styled-components";

export const Title = styled.p`
  font-family: "Athletics";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
  text-align: center;
  color: #000000;
  margin: 0;
`;

export const Inputs = styled.div<{ showTitle: boolean }>`
  margin: ${({showTitle}) => showTitle ? "20px 0 50px 0" : "0 0 50px 0"};
  text-align: left;
`;


export const InputBox = styled.div`
  width: 100%;
  margin-bottom: 25px;

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;

export const CountryBox = styled.div`
  width: 100%;
  margin-bottom: 25px;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;

export const ContentWrapper = styled.div`
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .button {
        margin-top: 30px;
    }

    @media (min-width: 768px) {
        max-width: 440px;
        margin: 0 auto;
    }
    @media (min-width: 1024px) {
        max-width: 540px;
    }
`;

export const Header = styled.header`
    margin-bottom: 20px;

    h1 {
        font-family: "Athletics";
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: -0.56px;
        color: #000000;
        margin: 0;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: #767676;
        margin: 8px auto 0 auto;

        a {
            color: var(--primary-pink);
            text-decoration: underline;
        }
    }

    @media (min-width: 768px) {
     p {
        max-width: 80%;
     }   
    }
`;

export const FeeAlert = styled.div`
    text-align: left;
    margin-top: 4px;
    font-size: 10px;
    color: #484848;
    line-height: 15.6px;
`;

export const RateBox = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #F9F9F9;
    
    span {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }
`;

