import React from 'react';
import { Bottom, BottomItem, BottomLabel, BottomValue, MobileWrapper } from '../styles';


interface Props{
    transactionRef: string;
    payoutAmount: string;
    payoutCurrency: string;
    transferFees: string;
    rate: string;
    totalDebit: string;
    beneficiary:string;
    date:string
}



const MobileCard = ({transactionRef, payoutAmount, transferFees, rate, totalDebit, beneficiary,  date }: Props) => {
  return (
    <MobileWrapper>
        <Bottom>
            <BottomItem>
            <BottomLabel> Transaction ref</BottomLabel>
            <BottomValue>{transactionRef}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Payout Amount</BottomLabel>
            <BottomValue>{payoutAmount}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Transfer Fees</BottomLabel>
            <BottomValue>{transferFees}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Rate</BottomLabel>
            <BottomValue>{rate}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Total Debit</BottomLabel>
            <BottomValue>{totalDebit}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Beneficiary</BottomLabel>
            <BottomValue>{beneficiary}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Date</BottomLabel>
            <BottomValue>{date}</BottomValue>
            </BottomItem>

        </Bottom>


    </MobileWrapper>
  )
}

export default MobileCard;