import React from "react";
import { DetailsWrapper, Flex, Heading, Paragraph } from "./style";

interface Props {
    email: string;
    phone: string;
    method: string;
}

const CustomerDetails = ({
    email,
    phone,
    method,
}: Props) => {
    return (
        <DetailsWrapper>
            <h3>Customer details</h3>
            <Flex>
                <Paragraph>Email</Paragraph>
                <Heading>{email}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Phone Number</Paragraph>
                <Heading>{phone}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Payment method</Paragraph>
                <Heading>{method}</Heading>
            </Flex>
        </DetailsWrapper>
    );
};

export default CustomerDetails;
