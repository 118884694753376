import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;

    h1 {
        color: #000000;
        font-family: "Athletics";
        font-size: 24px;
        font-weight: 600;
        line-height: 32px; 
        letter-spacing: -0.48px;
    }

    p {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        line-height: 16px; 
        color: #484848;
    }

    svg {
        margin: 40px 0 30px 0;
    }
`;