import React, { useContext } from "react";
import { Title, Content, Item } from "./style";
import { useAppSelector } from "../../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import { Button } from "../../../../../components/common/Button";
import { createAndUpdateWireBeneficiary } from "../../../wiresAPI";


const ManageBeneficiaryModal = ({
  recipient,
  setShowModal,
  setLoading,
  getBeneficiaries,
}: {
  recipient: any;
  setShowModal?: (arg: boolean) => void;
  setLoading?: (arg: boolean) => void;
  getBeneficiaries?: (id: number) => void;
}) => {
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onShowSuccessAlert } = useContext(SuccessAlertContext);
  const { businessId } = useAppSelector((state) => state.users);

  const items = [
    {
      id: 1,
      title: "Beneficiary name",
      value: recipient?.beneficiaryName,
    },
    {
      id: 2,
      title: "Business name",
      value: recipient?.business.name,
    },
    {
      id: 3,
      title: "Country",
      value: recipient?.country,
    },
    {
      id: 4,
      title: "Bank name",
      value: recipient?.bankName,
    },
    {
      id: 5,
      title: "Bank address",
      value: recipient?.bankAddress,
    },
    {
      id: 6,
      title: "Phone number",
      value: recipient?.phone,
    },
    {
      id: 7,
      title: "Beneficiary email",
      value: recipient?.email,
    },
    {
      id: 8,
      title: "Beneficiary address",
      value: recipient?.address,
    },
    {
      id: 9,
      title: "IBAN",
      value: recipient?.iban,
    },
    {
      id: 10,
      title: "Swift code",
      value: recipient?.swiftCode,
    }
  ]

  const onSubmit = async () => {
    const payload = {
      name: recipient.name,
      beneficiaryName: recipient.beneficiaryName,
      country: recipient.country,
      countryCode: recipient.countryCode,
      bankName: recipient.bankName,
      accountNumber: recipient.accountNumber,
      phone: recipient.phone,
      email: recipient.email,
      iban: recipient.iban,
      swiftCode: recipient.swiftCode,
      business: businessId,
      address: recipient.address,
      bankAddress: recipient.bankAddress,
      id: recipient.id,
      vfxId: recipient.vfxId,
      disabled: !recipient.disabled,
    };

    try {
      setLoading(true);
      await createAndUpdateWireBeneficiary(payload);
      setLoading(false)
      setShowModal(false)

      onShowSuccessAlert("Beneficiary updated successfully");
      getBeneficiaries(businessId)
    } catch (err) {
      setLoading(false)
      onShowErrorAlert(err, err.message);
    }
  };

  return (
    <div>
      <Title>Manage beneficiary</Title>

      <Content>
       {items.map((item) => (
          <Item key={item.id} disabled={recipient?.disabled}>
            <span>
              {item.title}
            </span>
            <p>
              {item.value}
            </p>
          </Item>
       ))}
      </Content>
      
      <Button
        theme="secondary"
        height="40px"
        fontSize="12px"
        label={`${recipient.disabled ? "Enable" : "Disable"} beneficiary`}
        onClick={onSubmit}
      />
    </div>
  );
};

export default ManageBeneficiaryModal;
