import React from "react";
import LocalBeneficiary from "./LocalBeneficiary";
import {
    BeneficiaryWrapper,
    Heading,
    PayoutWrapper,
} from "../../styles";
import InternationalBeneficiary from "./InternationalBeneficiary";

interface UserData {
    accountName: string;
    accountNumber: number;
    sortcode: string | number;
    paymentScheme: string;
}

interface LocalBankData {
    accountName: string;
    accountNumber: string;
    bank: string;
}

interface Props {
    activeCurrency: string;
    basicInfo: UserData;
    setBasicInfo: (data: UserData) => void;
    localBankInfo: LocalBankData;
    setLocalBankInfo: (data: LocalBankData) => void;
    setBankName: (data: string) => void;
    accountName: string;
    setAccountName: (data: string) => void;
    handleNext: () => void
    internationalBeneficiary?:any;
    setInternationalBeneficiary?:any
}

const Beneficiary = ({
    activeCurrency,
    localBankInfo,
    setLocalBankInfo,
    accountName,
    setBankName,
    setAccountName,
    handleNext,
    internationalBeneficiary,
    setInternationalBeneficiary
    
}: Props) => {
    return (
        <PayoutWrapper>
            <Heading>
                Enter beneficiary bank details
            </Heading>
            <BeneficiaryWrapper>
                {activeCurrency === "Naira payout" ? (
                    <LocalBeneficiary
                        localBankInfo={localBankInfo}
                        setlocalBankInfo={setLocalBankInfo}
                        setBankName={setBankName}
                        initialAccountName={accountName}
                        setInitialAccountName={
                            setAccountName
                        }
                        onNext={handleNext}
                    />
                ) : <InternationalBeneficiary  onNext={handleNext} selectedCard={internationalBeneficiary} setSelectedCard={setInternationalBeneficiary}/>}
            </BeneficiaryWrapper>
        </PayoutWrapper>
    );
};

export default Beneficiary;
