import React from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "antd";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { UpgradeButtonStyle } from "./styles";

const UpgradeButton = () => {
  const navigate = useNavigate();
  const uniqueIdentifier = uuidv4();
  const routeToken = "klasha-business";

  return (
    <UpgradeButtonStyle
      onClick={() =>
        navigate(
          `/upgrade/business-compliance/${uniqueIdentifier}/${routeToken}`,
        )
      }
    >
      Upgrade to <span>REGISTERED BUSINESS</span>
      <Tooltip
        overlayClassName="input-tooltip"
        placement="right"
        title="When you upgrade to Registered Business, this will unlock more features and benefits on the dashboard! Contact our sales team to learn more"
        trigger={["hover", "click"]}
      >
        <InfoIcon />
      </Tooltip>
    </UpgradeButtonStyle>
  );
};

export default UpgradeButton;
