import React, { useState } from "react";
import {
    EmptyWrapper,
    GenerateVirtualAccountWrapper,
    AccountWrapper,
    Flex,
    Paragraph,
    SubHeading,
} from "../styles";
import { ReactComponent as EmptyAccount } from "../assets/emptyAccount.svg";
import { Button } from "../../../components/common/Button";
import { ReactComponent as Check } from "../assets/checkIcon.svg";
import { ReactComponent as Copy } from "../assets/copyIcon.svg";

interface CopyIconProps {
    data?: string;
    onClick: () => void;
    copied: boolean;
}

const CopyIcon: React.FC<CopyIconProps> = ({ data, onClick, copied }) => (
    <span onClick={onClick}>
        {copied ? <Check /> : <Copy data-testid="copy-icon" />}
        {data}
    </span>
);

interface VirtualAccountProps {
    generateVirtualAccount: () => void;
    hasVirtualAccount: any;
    accountName: string;
    accountNumber: string;
    bankName: string;
    fetchingVirtualAccount: boolean;
}

const VirtualAccount: React.FC<VirtualAccountProps> = ({
    generateVirtualAccount,
    hasVirtualAccount,
    accountName,
    accountNumber,
    bankName,
    fetchingVirtualAccount,
}) => {
    const [copyAccount, setCopyAccount] = useState<string | null>(null);
    const [copyAll, setCopyAll] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopyAccount(data);
        setTimeout(() => {
            setCopyAccount(null);
        }, 3000);
    };

    const onCopyAll = () => {
        const allDetails = `Account Name: ${accountName}\nBank name: ${bankName}\nAccount number: ${accountNumber}`;

        onCopyLinkToClipboard(allDetails);
        setCopyAll(allDetails);

        setTimeout(() => {
            setCopyAll(null);
        }, 3000);
    };


    return (
        <GenerateVirtualAccountWrapper>
            {fetchingVirtualAccount ? (
                "loading..."
            ) : (
                <>
                    {!hasVirtualAccount ? (
                        <EmptyWrapper>
                            <EmptyAccount />
                            <h3>No virtual accounts yet</h3>
                            <p>
                                You have not generated any virtual
                                bank account
                            </p>
                            <p> for this customer.</p>
                            <Button
                                label="Generate virtual account "
                                theme="secondary"
                                width="250px"
                                height="46px"
                                disabled
                                onClick={generateVirtualAccount}
                                
                            />
                        </EmptyWrapper>
                    ) : (
                        <AccountWrapper>
                            <h1>Virtual account</h1>

                            <Flex>
                                <Paragraph>
                                    Account name
                                </Paragraph>
                                <SubHeading>
                                <CopyIcon
                                        onClick={() =>
                                            onCopyLinkToClipboard(
                                                accountName
                                            )
                                        }
                                        copied={
                                            copyAccount ===
                                            accountName
                                        }
                                    />
                                    {accountName}
                                    
                                </SubHeading>
                            </Flex>

                            <Flex>
                                <Paragraph>
                                    Account number
                                </Paragraph>
                                <SubHeading>
                                    <CopyIcon
                                        onClick={() =>
                                            onCopyLinkToClipboard(
                                                accountNumber
                                            )
                                        }
                                        copied={
                                            copyAccount ===
                                            accountNumber
                                        }
                                    />
                                    {accountNumber}
                                </SubHeading>
                            </Flex>

                            <Flex>
                                <Paragraph>
                                    Bank Name
                                </Paragraph>
                                <SubHeading>
                                    <CopyIcon
                                        onClick={() =>
                                            onCopyLinkToClipboard(
                                                bankName
                                            )
                                        }
                                        copied={
                                            copyAccount === bankName
                                        }
                                    />
                                    {bankName}
                                </SubHeading>
                            </Flex>

                            <Button
                                label={`${
                                    copyAll
                                        ? "copied"
                                        : "Copy account details"
                                }`}
                                theme="alternate"
                                height="40px"
                                onClick={onCopyAll}
                            />
                        </AccountWrapper>
                    )}
                </>
            )}
        </GenerateVirtualAccountWrapper>
    );
};

export default VirtualAccount;
