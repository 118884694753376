import styled from "styled-components";

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 40px;
`;

export const EmptyWrapperTitle = styled.p`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin: 0;

`;

export const EmptyWrapperSubTitle = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
  color: #8F8F8F;
  text-align: center;
  margin-bottom: 24px;
`;