import React from 'react';
import { Wrapper, Flex, Paragraph, SubHeading, SenderHeading } from '../styles';


interface Props {
    account_name: string;
    account_number: string;
    bank_name: string

}

const SenderDetails = ({account_name, account_number, bank_name}: Props) => {
  return (
    <Wrapper>
        <SenderHeading>Sender’s details</SenderHeading>
        <Flex>
        <Paragraph>Account name</Paragraph>
        <SubHeading>{account_name}</SubHeading>
        </Flex>

       <Flex>
       <Paragraph>Account number</Paragraph>
        <SubHeading>{account_number}</SubHeading>
       </Flex>

       <Flex>
       <Paragraph>Bank name</Paragraph>
        <SubHeading>{bank_name}</SubHeading>
       </Flex>


    </Wrapper>
  )
}

export default SenderDetails