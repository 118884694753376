import React, { useEffect, useState } from "react";
import {
    Beneficiary,
    Payout,
    Preview,
} from "../components/index";
import dayjs from "dayjs";
import {
    Wrapper,
    StepContainer,
    StepContent,
    BackButton,
} from "../styles";
import { ReactComponent as BackIcon } from "../assets/icons/backIcon.svg";

const StepNavigation = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [activeCurrency, setActiveCurrency] =
        useState("Naira payout");
        const [beneficiaryAmount, setBeneficiaryAmount] = useState(0)
        const [country, setCountry] = useState(null)
        const [sendingCountriesData, setSendingCountriesData] =
    useState<string[]>([]); 
    const [
        InternationalTransfer,
        setInternationalTransfer,
    ] = useState(null);
    const [localBankInfo, setLocalBankInfo] =
        useState(null);
    const [bankName, setBankName] = useState<any>();
    const [accountName, setAccountName] =
        useState<string>();
    const [amount, setAmount] = useState<number>();
    const [
        internationalCurrency,
        setInternationalCurrency,
    ] = useState(null);
    const [inTCharges, setIntTcharges] = useState(null);
    const [intRate, setIntRate] = useState(null);
    const [
        internationalBeneficiary,
        setInternationalBeneficiary,
    ] = useState(null);
    const [intAmount, setIntAmount] = useState(null);
    const [fee, setFee] = useState(null);

    const totalLocalAmount = amount + 53.75;

    const TotalCharges =
        activeCurrency === "Naira payout"
            ? totalLocalAmount
            : inTCharges + Number(fee * intRate?.rate);

    const formattedDate = dayjs().format("DD.MM.YYYY");

    const handleStepClick = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBackClick = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };
    

    useEffect(() => {
        if (activeCurrency === "Naira payout") {
            setIntAmount(null);
            setInternationalCurrency(null);
            setIntTcharges(null);
            setIntRate(null);
            setInternationalBeneficiary(null);
            setFee(null);
            setCountry(null)
        }
        if (activeCurrency === "International payout") {
            setLocalBankInfo(null);
            setBankName(null);
            setAccountName(null);
            setAmount(null);
            setBeneficiaryAmount(0)
        }
    }, [activeCurrency]);

    const steps = [
        {
            title: "Amount",
            content: (
                <Payout
                    handleNext={handleStepClick}
                    activeCurrency={activeCurrency}
                    country={country}
                    setCountry={setCountry}
                    setActiveCurrency={setActiveCurrency}
                    amount={amount}
                    setAmount={setAmount}
                    internationalCurrency={
                        internationalCurrency
                    }
                    setInternationalCurrency={
                        setInternationalCurrency
                    }
                    intTCharges={inTCharges}
                    intRate={intRate}
                    setIntRate={setIntRate}
                    setIntTcharges={setIntTcharges}
                    intAmount={intAmount}
                    setIntAmount={setIntAmount}
                    interNationalFee={fee}
                    setInternationalFee={setFee}
                    beneficiaryAmount={beneficiaryAmount}
                    setBeneficiaryAmount={setBeneficiaryAmount}
                    sendingCountriesData={sendingCountriesData}
                    setSendingCountriesData={setSendingCountriesData}
                />
            ),
        },
        {
            title: "Beneficiary",
            content: (
                <Beneficiary
                    activeCurrency={activeCurrency}
                    basicInfo={InternationalTransfer}
                    setBasicInfo={setInternationalTransfer}
                    localBankInfo={localBankInfo}
                    setLocalBankInfo={setLocalBankInfo}
                    setBankName={setBankName}
                    accountName={accountName}
                    setAccountName={setAccountName}
                    handleNext={handleStepClick}
                    internationalBeneficiary={
                        internationalBeneficiary
                    }
                    setInternationalBeneficiary={
                        setInternationalBeneficiary
                    }
                />
            ),
        },
        {
            title: "Review",
            content: (
                <Preview
                    onGoBack={setCurrentStep}
                    amount={amount || intAmount}
                    fee={fee ? fee : 53.75}
                    date={formattedDate}
                    beneficiaryName={
                        accountName ||
                        internationalBeneficiary?.beneficiaryName
                    }
                    bankName={
                        bankName ||
                        internationalBeneficiary?.bankName
                    }
                    accountNumber={
                        localBankInfo?.accountNumber ||
                        internationalBeneficiary?.accountNumber
                    }
                    totalAmount={TotalCharges}
                    bankCode={localBankInfo?.bankName}
                    currency={internationalCurrency}
                    rate={intRate?.rate}
                    id={internationalBeneficiary?.id}
                    setFee={setFee}
                />
            ),
        },
    ];

    return (
        <Wrapper>
            <StepContainer current={currentStep}>
                {steps.map((step, index) => (
                    <StepContainer.Step
                        key={index}
                        title={step.title}
                    />
                ))}
            </StepContainer>
            {currentStep > 0 && (
                <BackButton onClick={handleBackClick}>
                    <BackIcon />
                </BackButton>
            )}

            <StepContent>
                {steps[currentStep].content}
            </StepContent>
        </Wrapper>
    );
};

export default StepNavigation;
