import React, { useContext, useEffect, useState } from "react";
import CustomModal from "../../../../../components/common/CustomModal";
import { Controller, useForm } from "react-hook-form";
import { Flex, InviteContent } from "../../styles";
import { Input } from "../../../../../components/common/Input";
import { Button } from "../../../../../components/common/Button";
import { InputPhone } from "../../../../../components/common/InputPhone";
import CustomSelect from "../../../../../components/common/CustomSelect";
import { capitalizeName } from "../../utils/capitalizeName";
import { GetAllRoles } from "../../redux/actions";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";

interface Props {
    setState?: (data) => void;
    data?: any;
    open: boolean;
    handleEditData?: (data) => void;
}

export interface formData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    role?:string
}

const EditModal = ({
    setState,
    open,
    data,
    handleEditData,
}: Props) => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<formData>() || {};
    const dispatch = useAppDispatch();
    const { businessId } = useAppSelector(
        (state) => state.users,
    );


    const { onShowAlert: onShowErrorAlert } =
    useContext(NetworkErrorAlertContext) || {};
    const [roleData, setRoleData] = useState({
        roles: null
     });

    const getAllBusinessRoles = async () => {
        const data = await dispatch(
            GetAllRoles({ businessId }),
        );
        if (GetAllRoles.rejected.match(data)) {
            onShowErrorAlert(
                "",
                String(
                    "An error occured when getting roles",
                ),
            );
        }
        if (GetAllRoles.fulfilled.match(data)) {
           
            setRoleData((prev) => ({
                ...prev,
                roles: data.payload.data.business_roles,
            }));
        }

    };

    useEffect(() => {
        if(businessId){
            getAllBusinessRoles();
        }
    }, [businessId]);

    const handleCloseModal = () => {
        setState((prev) => ({
            ...prev,
            editModal: false,
            userData: data,
        }));
        setValue("role", null);

    };

    useEffect(() => {
        setValue("phoneNumber", data?.phoneNumber || "");
    }, [data, setValue]);

    const mailformat =
        /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

        const rolesOptions = roleData?.roles?.map(role => ({
            value: role.name,
            label: capitalizeName(role.name)
        }));
        
    return (
        <CustomModal
            isModalVisible={open}
            onClose={handleCloseModal}
            width="480px"
        >
            <InviteContent>
                <h1>Edit member details</h1>

                <Flex>
                    <div>
                        <Controller
                            name="firstName"
                            rules={{ required: false }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="First name"
                                    field={field}
                                    disableLabel
                                    disabled
                                    value={data?.firstName}
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name="lastName"
                            rules={{ required: false }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="Last name"
                                    value={data?.lastName}
                                    disableLabel
                                    disabled
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name="email"
                            rules={{
                                required: false,
                                pattern: mailformat,
                            }}
                            control={control}
                            render={({ field }) => (
                                <Input
                                    label="Email"
                                    field={field}
                                    value={data?.fullEmail}
                                    disableLabel
                                    disabled
                                />
                            )}
                        />
                    </div>

                    <div>
                        <Controller
                            name="phoneNumber"
                            rules={{
                                required: true,
                            }}
                            control={control}
                            defaultValue={data?.phoneNumber}
                            render={({ field }) => (
                                <InputPhone
                                    label="Phone number"
                                    field={field}
                                />
                            )}
                        />
                        {errors?.phoneNumber?.type ===
                            "required" && (
                            <span className="error">
                                Field is required
                            </span>
                        )}

                        {errors.phoneNumber?.type ===
                            "pattern" && (
                            <span className="error">
                                Please enter a valid phone
                                number
                            </span>
                        )}
                    </div>
                    <div>
                        <Controller
                            name="role"
                            rules={{
                                required: true,
                            }}
                            control={control}
                            render={({ field }) => (
                                <CustomSelect placeholder={data?.role}  
                                field={field}
                                options={rolesOptions}
                                $width="430px"/>
                            )}
                        />
                        {errors?.role?.type ===
                            "required" && (
                            <span className="error">
                                Field is required
                            </span>
                        )}

                    
                    </div>
                    
                </Flex>
                <Button
                    label="Update"
                    height="40px"
                    onClick={handleSubmit(handleEditData)}
                    className="edit-btn"
                />
            </InviteContent>
        </CustomModal>
    );
};

export default EditModal;
