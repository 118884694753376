import styled from "styled-components";

export const Wrapper = styled.div`
  //height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-family: "Inter";
`;

export const PageTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 4px;

  @media (min-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #464646;
  margin-bottom: 60px !important;
  @media (min-width: 768px) {
    margin-bottom: 48px !important;
  }
`;

export const Cards = styled.div`
  /* display: flex;
  align-items: flex-start;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 23px;
  @media (min-width: 1440px) {
    gap: 7%;
  }
`;
