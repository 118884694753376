import api from "../../app/api";

export const fetchTransactions = async ({ walletId }) => {
  const response = await api.get(`wallet/withdraw/request/${walletId}/mine`);
  return response.data;
};

export const getPendingBalance = async ({ businessId }) => {
  const response = await api.get(
    `nucleus/settlementunit/${businessId}/pending`,
  );

  return response.data;
};

type StatementRequestParams = {
  businessId: string;
  startDate: string;
  endDate: string;
  pageNumber: number;
}

type StatementResponse = {
  data: {
    transactions: any [];
    nextPage: number | null;
    currentPage: number | null;
  };
}
export const getMerchantCollectionAccountStatement = async ({businessId, startDate, endDate, pageNumber}: StatementRequestParams) => {
  const response = await api.get<StatementResponse>(`wallet/account/statement/${businessId}/${startDate}/${endDate}?page=${pageNumber}`)
  return response.data
}

export const getSettlementBalance = async ({ businessId }) => {
  const response = await api.get(`wallet/business/${businessId}/wallets`);
  return response.data?.data;
};

export const getFilteredPayoutRequests = async (data) => {
  const response = await api.post(
    `wallet/withdraw/merchant/request/admin/transaction/paginated/filter`,
    data,
  );
  return response.data?.merchantWithdrawRequests;
};

export const getCollectionBreakDown = async ({ businessId }) => {
  const response = await api.get(`nucleus/tnx/get/collection/${businessId}`);
  return response;
};
export const getallCollectionsBreakdown = async (data) => {
  const response = await api.post(`nucleus/tnx/filter/paginated`, data);
  return response.data;
};



export const requestPayout = async (payload, token = null) => {
  const headers = {};
  if (token) {
    headers['x-auth-token'] = token;
  }

  const response = await api.post(
    "/wallet/withdraw/merchant/request/v2",
    payload,
    {
      headers,
    }
  );
  return response.data;
};



export const getAllTimePayoutData = async ({ businessId }) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/all/time/sum/${businessId}/1`,
  );
  return data;
};

export const getAllFees = async () => {
  const { data } = await api.get(`wallet/fees/all`);
  return data;
};

export const getRequestFee = async ({ countryCode, feeType }) => {
  const { data } = await api.get(`wallet/fees/get/${countryCode}/${feeType}`);
  
  return data;
};

export async function getDefaultExchangeRate({
  sourceCurrency,
  amount,
  destinationCurrency,
}) {
  const { data } = await api.post("nucleus/exchange/", {
    sourceCurrency,
    amount,
    destinationCurrency,
  });
  return data;
}

export const getCollectionTransactions = async (requestData) => {
  const { data } = await api.post(`nucleus/tnx/filter/paginated`, requestData);
  return data;
};
