import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@reach/window-size";
import dayjs from "dayjs";
import {
    ActionBtns,
    BoxBody,
    EmptyWrapper,
    EmptyWrapperTitle,
    EmptyWrapperSubTitle,
    LinkItem,
    Wrapper,
    Title,
    MobileBody,
} from "./style";
import CustomTable from "../../components/common/CustomTable";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Loader from "../../components/common/Loader";
import TitleFilter from "../../components/links/components/TitleFilter";
import CustomModal from "../../components/common/CustomModal";
import NewLinkModal from "../../components/links/components/NewLinkModal";
import ShareLinkModal from "../../components/links/components/ShareLinkModal";
import { ReactComponent as ShareIcon } from "./assets/share.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "./assets/failed.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import LinkCard from "../../components/links/components/LinkCard";
import { formatCurrency } from "../../utils/formatCurrency";
import { gtagEvent } from "../../utils/gtag";
import {ReactComponent as EmptyIcon } from "./assets/emptyTransaction.svg";
import LinkType from "../../components/links/components/LinkType";
import { formatStrAndCapitalize } from "../../utils/format";
import EmptyMobileCard from "../../components/emptyStates/EmptyMobileCard";
import { Button } from "../../components/common/Button";
import AddBankAccount from "../../components/links/components/AddBankAccount";
import AddPaymentType from "../../components/links/components/AddPaymentType";
import Success from "../../components/links/components/Success";
import { deleteLink, getCustomerLinks } from "./linksAPI";
import { setLinks } from "./linksSlice";
import { selectUserRoleAndPermissions } from "../Settings/Team/redux/slice";

const EmptyBalanceDescription = ({onCreateLink}: {onCreateLink: () => void}) => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>No payment links created yet</EmptyWrapperTitle>
            <EmptyWrapperSubTitle>No payment links yet. All payment links will appear here once you have created them.</EmptyWrapperSubTitle>
            <Button
                label='Create new payment link'
                theme="secondary"
                height="40px"
                width="184px"
                fontSize="12px"
                onClick={onCreateLink}
            />
        </EmptyWrapper>
    );
};

const PaymentLinks = () => {
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();
    const { onShowAlert } = useContext(SuccessAlertContext);
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext
    );
    const [addBankAcct, setAddBankAcct] = useState(false);
    const [addPaymentType, setAddPaymentType] = useState(false);
    const [paymentType, setPaymentType] = useState("");
    const [linksData, setLinksData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedLink, setSelectedLink] = useState("");
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [success, setSuccess] = useState(false);
    const [search, setSearch] = useState('');
    const [newLink, setNewLink] = useState(null);

    const [loading, setLoading] = useState(false);
    const { businessId } = useAppSelector((state) => state.users);
    const { business, bankAccounts } = useAppSelector((state) => state.settings);
    const { links } = useAppSelector((state) => state.links);
    const {role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const hasCreateLinkPermission =
        permissions?.["payment-links"]?.includes("CREATE_PAYMENT_LINK");

    function redirectToUrl(url: string | URL) {
        window.open(url, '_blank');
    }

    const tableColumns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
        },
        {
            title: "Reference",
            dataIndex: "link",
            key: "link",
            render: ({url, token}: {url: string; token: string}) => (
                <LinkItem onClick={() => redirectToUrl(url)}>
                    {token}
                </LinkItem>
            ),
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: string) => (
                <LinkType text={type} />
            ),
        },
        {
            title: "",
            dataIndex: "",
            key: "x",
            render: (text, record) => (
                <ActionBtns>
                    <button
                        onClick={() => onCopyLinkToClipboard(record)}
                        title="copy"
                        type="button"
                    >
                        {selectedRecord === record.id ? <CheckIcon /> : <CopyIcon />}
                    </button>
                    <button
                        title="share"
                        type="button"
                        onClick={() => openShareModal(record.link.url)}
                    >
                        <ShareIcon />
                    </button>
                    <button
                        title="delete"
                        type="button"
                        onClick={() => onDeleteLink(record.id)}
                    >
                        <DeleteIcon />
                    </button>
                </ActionBtns>
            ),
        },
    ];

    const getData = async () => {
        try {
            setLoading(true);

            const data = await getCustomerLinks(businessId,1, 40)
            dispatch(setLinks(data.content));
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }    
    }

    useEffect(() => {
        if (businessId) {
            getData()
        }
    }, [businessId]);

    const removeSpecialCharacter = (urlHost:string, id: string) => {        
        const token = id
        const modifiedLink = token.replace(/[*.=]/g, '')
        const url = `${urlHost}?token=${modifiedLink}`;
        return {url, token};
    }

    useEffect(() => {
        const filteredData = links.length
            ? links.filter((item) =>
                  Object.values(item).some(
                      (value) =>
                          typeof value === 'string' &&
                          value.toLowerCase().includes(search.toLowerCase())
                  )
              )
            : [];
    
        const formattedData = filteredData.map((link, index) => ({
            no: index + 1,
            id: link.id,
            key: link.id,
            title: formatStrAndCapitalize(link.name, 12),
            amount: formatCurrency(link.amount),
            currency: link.currency,
            link: removeSpecialCharacter(link?.urlHost, link?.token),
            date: dayjs(link.createdAt).format("DD.MM.YYYY"),
            type: link.payLinkType,
        }));
    
        setLinksData(formattedData);
    }, [links, search]);
    

    const onShareLinkSubmit = () => {
        onShowAlert("Payment link has been successfully shared.");
        setShowShareModal(false);

        gtagEvent({
            action: "paylink_share_success",
            category: "paylink",
            label: "Payment link shared",
        });
    };

    const openModal = () => {
        if(hasCreateLinkPermission || role === "OWNER"){
            if (!business.approved) {
                onShowErrorAlert({}, "Business awaiting approval");
            } else if (!bankAccounts || bankAccounts.length < 1) {
                setAddBankAcct(true)
            } else {
                setAddPaymentType(true)
            }
        
        }else{
            onShowErrorAlert("", "Access is denied");

        
        }
        
    };
  
    const openShareModal = (url: string) => {
        setSelectedLink(url);
        setShowShareModal(true);
    };

    const onDeleteLink = async (id: number) => {
        try {
            setLoading(true);

            await deleteLink(id)
            onShowAlert("Payment link has been successfully deleted.");
            getData();
        } catch (e) {
            setLoading(false);
            onShowErrorAlert(Error, "Delete Failed")
        }
    };

    const onCopyLinkToClipboard = (record: any) => {
        navigator.clipboard.writeText(record.link.url);
        setSelectedRecord(record.id);

        gtagEvent({
            action: "paylink_copy_success",
            category: "paylink",
            label: "Payment link copied",
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setSelectedRecord(null);
        }, 2000);
    
        return () =>{
          clearTimeout(timer)
        }
      }, [ selectedRecord]);
 
    return (
        <Wrapper>
            <Loader isLoading={loading} />

            <Title>
                <h1>Payment link</h1>
                <p>No e-commerce store? Accept payment in Africa seamlessly with payment link.</p>
            </Title>

            <BoxBody>
               {links.length > 0 &&
                    <TitleFilter 
                        search={search}
                        setSearch={setSearch}
                        onCreateLink={openModal} 
                        data={linksData}
                    />
               }

                {width > 767 ? (
                    <CustomTable
                        columns={tableColumns}
                        extraEmptyChildren={<EmptyBalanceDescription onCreateLink={openModal} />}
                        dataSource={linksData}
                        icon={<EmptyIcon/>}
                    />
                ) : (
                    <MobileBody>
                        {linksData?.length ? linksData.map((lin) => (
                            <LinkCard
                                key={lin.key}
                                record={lin}
                                selectedRecord={selectedRecord}
                                onCopyLinkToClipboard={onCopyLinkToClipboard}
                                onDeleteLink={onDeleteLink}
                                openShareModal={openShareModal}
                                redirectToUrl={redirectToUrl}
                            />
                        )) : 
                        <EmptyMobileCard
                            title="No payment link created yet"
                            subText="You have not created any payment link on this account. All payment links will appear here once you have created them."
                            icon={<EmptyIcon />}
                        />
                        }
                    </MobileBody>
                )}
            </BoxBody>

            <CustomModal
                isModalVisible={showModal}
                onClose={() => setShowModal(false)}
                width="480px"
            >
                <NewLinkModal 
                    setSuccess={setSuccess}
                    paymentType={paymentType}
                    setLoading={setLoading}
                    setShowModal={setShowModal}
                    setNewLink={setNewLink}
                />
            </CustomModal>

            <CustomModal
                isModalVisible={showShareModal}
                onClose={() => setShowShareModal(false)}
                width="480px"
             >
                <ShareLinkModal
                    onShareLinkSubmit={onShareLinkSubmit}
                    link={selectedLink}
                    setShowShareModal={setShowShareModal}
                    setLoading={setLoading}
                />
            </CustomModal>


            <CustomModal
                isModalVisible={addBankAcct}
                onClose={() => setAddBankAcct(false)}
                width="480px"
            >
                <AddBankAccount 
                    setAddBankAcct={setAddBankAcct}
                />
            </CustomModal>


            <CustomModal
                isModalVisible={addPaymentType}
                onClose={() => setAddPaymentType(false)}
                width="480px"
            >
                <AddPaymentType
                    setAddPaymentType={setAddPaymentType}
                    setPaymentType={setPaymentType}
                    setShowModal={setShowModal}
                />
            </CustomModal>

            <CustomModal
                isModalVisible={success}
                onClose={() => setSuccess(false)}
                width="480px"
            >
                <Success
                    newLink={newLink}
                    setSuccess={setSuccess}
                    getData={getData}
                />
            </CustomModal>
        </Wrapper>
    );
};

export default PaymentLinks;