import React from "react";
import TransactionStatus from "../TransctionStatus";
import {
  Wrapper,
  Top,
  TopLeft,
  Bottom,
  BottomItem,
  BottomLabel,
  BottomValue,
} from "./style";
import { formatLongString } from "../../../../utils/formatString";

interface CardProps {
  amount: string;
  transactionId: string;
  status: string;
  email: string;
  method: string;
  source: string;
  reqDate: string;
}

const TransactionCard = ({
  amount,
  transactionId,
  status,
  email,
  method,
  source,
  reqDate,
}: CardProps) => {
  return (
    <Wrapper title="transaction-card">
      <Top>
        <TopLeft>
          <span>Amount</span>
          <h1>{amount}</h1>
        </TopLeft>
      </Top>
      <Bottom>
        <BottomItem>
          <BottomLabel>Transactions ID</BottomLabel>
          <BottomValue>{formatLongString(transactionId, 15)}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Source</BottomLabel>
          <BottomValue>{source}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Email</BottomLabel>
          <BottomValue>{formatLongString(email, 15)}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Method</BottomLabel>
          <BottomValue>{method}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Date - Time</BottomLabel>
          <BottomValue>{reqDate}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Status</BottomLabel>
          <BottomValue>{<TransactionStatus text={status}/>}</BottomValue>
        </BottomItem>
      </Bottom>
    </Wrapper>
  );
};

export default TransactionCard;
