import React from "react"
import CryptoJS from "crypto-js";
import { APP_API_ENCRYPTION_KEY } from "../app/const/staticKeys"


export const encryptAlgorithm = (data, alg = APP_API_ENCRYPTION_KEY) => {
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      alg
    ).toString();
    return encrypted;
};