import styled from "styled-components";

export const PaginationControls = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-pagination {
    font-size: 14px;
    font-weight: 600;
    width: 100%;
  }

  .ant-pagination-item {
    background-color: #fff;
    border: none;
    margin: 5px;
  }

   .ant-pagination-item a {
    color: #A39D9E !important;
  }

   .ant-pagination-item-active a {
    color: #000000 !important;
  }

  .ant-pagination-item-active {
    background-color: #e9e9e9;
    border-radius: 5px;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none !important;
    background-color: transparent !important;
    color: #A39D9E !important;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: #d9d9d9 !important;
    cursor: not-allowed;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardContainer = styled.div`
  margin-bottom: 1rem;
`;

export const PageButton = styled.button`
  padding: 0.5rem 0.75rem;
  margin: 0 0.25rem;
  border-radius: 0.25rem;

  &:hover {
  }
  &:disabled {
    background-color: #f3f4f6;
    color: #9ca3af;
    cursor: not-allowed;
  }
`;

export const NavigationButton = styled(PageButton)`
  padding: 0.5rem;
`;
