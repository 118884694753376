import React from "react";
import { Wrapper, ToggleItem } from "./style";

interface Props {
  toggleType?: string[];
  activeToggle?: string;
  setActiveToggle?: React.Dispatch<
    React.SetStateAction<string>
  >;
  handleToggle?: (data: string) => void;
}

const ToggleButton = ({
  toggleType,
  activeToggle,
  handleToggle,
}: Props) => {

  return (
    <Wrapper>
      {toggleType.map((item) => (
        <ToggleItem
          key={item}
          onClick={() => handleToggle(item)}
          type={item}
          active={activeToggle}
        >
          {item}
        </ToggleItem>
      ))}
    </Wrapper>
  );
};

export default ToggleButton;
