import React, { ChangeEvent } from 'react'
import { SearchInput } from './style';
import { ReactComponent as CloseIcon } from "../../../components/common/OTP/assets/close.svg"
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";


type Props= {
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    width?: string;
    height?: string;
    fontSize?: string;
    placeholder?: string;
    showClose?: boolean;
    clearSearch?: () => void;
}

const Search = ({value, onChange, placeholder, width, height, fontSize, showClose, clearSearch }: Props) => {
  return (
    <SearchInput
      width={width}
      height={height}
      fontSize={fontSize}
      prefix={<SearchIcon />}
      suffix={showClose ?
          <p onClick={clearSearch}>
            <CloseIcon />
          </p> 
      : null}
      placeholder={placeholder || "Search"}
      value={value}
      onChange={onChange}
    />
  )
}

export default Search