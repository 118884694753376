import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

export const Wrapper = styled.input`
border-radius: 8px;
border: 1px solid var(--border-input-empty, #D1D1D1);
padding: 8px 12px;
width: 100%;
&:focus {
    outline: none !important;
    border: 1px solid #000000;
  }
  &::placeholder {
    background-color: transparent;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565C69 !important;
  }


`