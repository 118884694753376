import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "../../../assets/icons/delete-icon.svg";
import { Button } from "../../../components/common/Button";
import { Input } from "../../../components/common/Input";
import {
    Flex,
    HeaderText,
    KeyList,
    Wrapper,
} from "./styles";
import {
    CreateWhiteList,
    GetAllWhiteList,
    formData,
} from "./redux/actions";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../app/hooks";
import Loader from "../../../components/common/Loader";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";

const IPWhiteList = () => {
    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors, isDirty },
    } = useForm<formData>({
        defaultValues: {
            ipAddress: "",
        },
    });
    const dispatch = useAppDispatch();
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { loading } = useAppSelector(
        (state) => state.ipAddressWhiteList || {},
    );

    const { onShowAlert: onShowSuccessAlert } = useContext(
        SuccessAlertContext,
    );
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const [allIPS, setAllIPS] = useState<string[]>([]);
    const handleSaveWhiteList = async () => {
        const watchedValues = watch();
        const ipAddresses = Object.keys(watchedValues)
            .filter((key) => key.startsWith("ipAddress-"))
            .map((key) => watchedValues[key])
            .filter((ip) => ip.trim() !== "");
        const ipAddressString = ipAddresses.join(", ");
        const action = await dispatch(
            CreateWhiteList({
                businessId: businessId,
                ipAddress: ipAddressString,
            }),
        );

        if (CreateWhiteList.fulfilled.match(action)) {
            const response = await dispatch(
                GetAllWhiteList({ businessId }),
            );
            if (
                response.payload &&
                Array.isArray(response.payload)
            ) {
                setAllIPS(
                    response.payload.flatMap((ip) =>
                        ip.split(",").map((i) => i.trim()),
                    ),
                );
                setValue("ipAddress", "");
            }
            onShowSuccessAlert(
                "Successfully saved IP address(es)",
            );
        }
        if (CreateWhiteList.rejected.match(action)) {
            onShowErrorAlert("", String(action.payload));
        }
    };

    useEffect(() => {
        const fetchIPAddresses = async () => {
            if (businessId) {
                const response = await dispatch(
                    GetAllWhiteList({ businessId }),
                );
                if (
                    response.payload &&
                    Array.isArray(response.payload)
                ) {
                    setAllIPS(
                        response.payload.flatMap((ip) =>
                            ip
                                .split(",")
                                .map((i) => i.trim()),
                        ),
                    );
                    const savedIPs =
                        response.payload.join(", ");
                    setValue("ipAddress", String(savedIPs));
                }
            }
        };

        fetchIPAddresses();
    }, [dispatch, businessId]);

    const validateIPAddresses = (value: string) => {
        if (!value) return "IP address is required";
    
        const ipList = value.split(",").map(ip => ip.trim());
        const ipPattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        
        for (let ip of ipList) {
            if (!ipPattern.test(ip)) {
                return "Invalid IP address format. Example: 192.168.123.132";
            }
        }
    
        return true;
    };
    

    const handleAddNewAddress = () => {
        setAllIPS([...allIPS, ""]);
    };

    const handleRemoveAddress = async (index: number) => {
        const updatedIPs = [...allIPS];
        updatedIPs.splice(index, 1);
        setAllIPS(updatedIPs);
        setValue(`ipAddress-${index}` as any, "");
        await handleSaveWhiteList();
    };
    

    return (
        <Wrapper>
            <Loader isLoading={loading} />
            <KeyList>
                <HeaderText>IP whitelisting</HeaderText>

                {allIPS.map((ip, index) => (
                    <Flex key={index}>
                        <div>
                        <Controller
                            name={
                                `ipAddress-${index}` as any
                            }
                            rules={{
                                required:
                                    "IP address is required",
                                validate:
                                    validateIPAddresses,
                            }}
                            control={control}
                            defaultValue={ip}
                            render={({ field }) => (
                                <Input
                                    label="IP address"
                                    value={field.value}
                                    onChange={
                                        field.onChange
                                    }
                                    width="430px"
                                />
                            )}
                        />
                        {errors[`ipAddress-${index}`] && (
                <span className="error">{errors[`ipAddress-${index}`].message}</span>
            )}
                        </div>

                        <img
                            src={CloseIcon}
                            alt="Close Icon"
                            onClick={() =>
                                handleRemoveAddress(index)
                            }
                            style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                            }}
                        />
                    </Flex>
                ))}

                <Flex>
                    <Button
                        label="Add new address"
                        width="141px"
                        height="40px"
                        className="btn"
                        onClick={handleAddNewAddress}
                        disabled={loading}
                    />
                    <Button
                        label="Save"
                        width="90px"
                        height="40px"
                        className="btn"
                        onClick={handleSubmit(
                            handleSaveWhiteList,
                        )}
                        disabled={!isDirty || loading}
                    />
                </Flex>
            </KeyList>
        </Wrapper>
    );
};

export default IPWhiteList;
