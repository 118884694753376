import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  margin-top: 40px;
  font-family: "Inter";

  @media (min-width: 1024px) {
    //width: 329px;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 48%;
    }
  }
`;

export const KeyList = styled.div`
  width: 100%;
`;

export const AboutKeys = styled.div`
  margin-top: 30px;
`;

export const AboutTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const AboutContent = styled.div`
  border: 1px solid #464646;
  border-radius: 8px;
  padding: 10px;
  max-width: 505px;
  & svg {
    margin-bottom: 32px;
  }

  & ${AboutTitle} {
    margin-bottom: 16px;
  }

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const AboutDescription = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;
