import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: var(--gray-500, #667085);
  }
  .item__child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: var(--gray-500, #667085);
    }
  };

  .upgrade-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: black !important;
}
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const UpgradeButtonStyle = styled.button`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #efefef;
  text-align: center;
  color: #667085;
  font-size: 12px;
  span {
    border-radius: 6px;
    background: var(--button-secondary-default, #000);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 41, 0.05);
    color: #fff;
    padding: 3px 6px 2px 6px;
  }
`;
