import type { ChartArea } from "chart.js";
export const cardChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: { display: false },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: { display: false },

      min: 0,
    },
  },
};

export const mainChartOptions = {
  maintainAspectRatio: false,
  responsive: true,
  chartArea: {
    backgroundColor: "rgba(251, 85, 85, 0.4)",
  },
  plugins: {
    legend: { display: false },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      grid: {
        drawBorder: false,
      },
      min: 0,
    },
  },
};

let width, height, gradient;

export function createGradient(
  ctx: CanvasRenderingContext2D,
  chartArea: ChartArea
) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

    gradient.addColorStop(0, "rgba(239, 44, 90, 0)");
    gradient.addColorStop(0.5, "rgba(239, 44, 90, 0.3)");
    gradient.addColorStop(1, "rgba(239, 44, 90, 0.6)");
  }

  return gradient;
}

export const mainChartData = {
  labels: [],
  datasets: [
    {
      label: "Sales",
      data: [],
      borderColor: ["#EF2C5A"],
      borderWidth: 1.2,
      hoverOffset: 4,
      fill: true,
      borderCapStyle: "round",
      pointStyle: "line",
      radius: 0,
    },
  ],
};

export const cardChartData = {
  labels: [],
  datasets: [
    {
      label: "Sales",
      data: [],
      borderColor: ["#EF2C5A"],
      borderWidth: 1.2,
      hoverOffset: 4,
      fill: true,
      borderCapStyle: "round",
      pointStyle: "line",
      radius: 0,
    },
  ],
};
