import React, { ChangeEvent } from "react";
import { Popover } from "antd";
import {
  Right,
  FilterBox,
  FilterButton,
  FilterBoxFooter,
  FilterBoxItem,
  FilterBoxItemTitle,
  FilterFooterApplyButton,
  FilterFooterResetButton,
  Left,
  Wrapper,
} from "./style";
import "./style.css";
import { ReactComponent as FilterIcon } from "../../../../assets/icons/filter.svg";
import CustomDateRangePicker from "../../../common/CustomDateRangePicker";
import { Button } from "../../../common/Button";
import Search from "../../../common/Search";
import Export from "../../../common/Export";
import { exportCSV } from "../../utils";

const FilterContent = () => {
  return (
    <FilterBox>
      <FilterBoxItem>
        <FilterBoxItemTitle>Date range</FilterBoxItemTitle>
        <CustomDateRangePicker />
      </FilterBoxItem>
      <FilterBoxFooter>
        <FilterFooterApplyButton>Filter</FilterFooterApplyButton>
        <FilterFooterResetButton>Reset</FilterFooterResetButton>
      </FilterBoxFooter>
    </FilterBox>
  );
};
const TitleFilter = ({
  onCreateLink,
  search,
  setSearch,
  data,
}: {
  search: string;
  setSearch: (val: string) => void;
  onCreateLink: () => void;
  data: {}[];
}) => {

  return (
     <Wrapper>
      <Left>
        <Button
          label="Create payment link"
          height="40px"
          theme="secondary"
          fontSize="12px"
          onClick={onCreateLink}
        />

        <Search
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />
      </Left>
    
      <Right>
        <Popover
          overlayClassName="filter-popover-container"
          content={<FilterContent />}
          trigger="click">
            
            <FilterButton>
              <span>Filter</span>
              <FilterIcon />
            </FilterButton>
        </Popover>

        <Export
          downloadCSV={() => exportCSV(data)}
          downloadXLS={() => exportCSV(data)}
        />
      </Right>
    </Wrapper>
  );
};

export default TitleFilter;
