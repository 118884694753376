
export const roleList =  [
    {
        label: 'Dashboard',
        value: 'dashboard'
    },
    {
        label: 'Collections',
        value: 'collections'
    },
    {
        label: 'Customers',
        value: 'customers'
    },
    {
        label: 'Wallet',
        value: 'wallet'
    },
    {
        label: 'Card',
        value: 'card'
    },
    {
        label: 'Transactions',
        value: 'transactions'
    },
    {
        label: 'Exchange',
        value: 'exchange-rates'
    },
    {
        label: 'Wire',
        value: 'wire'
    },
    {
        label: 'API Keys',
        value: 'api-keys'
    },
    {
        label: 'Bank Account',
        value: 'bank-account'
    },
    {
        label: 'Team',
        value: 'team'
    },
    {
        label: 'Virtual Account',
        value: 'virtual-account'
    },
    {
        label: 'Payment Links',
        value: 'payment-links'
    },
]




export const DashboardPermissions = [
    {   id: 1,
        title: 'Read only',
        value: 'Read only'
    },
    {   id: 2,
        title: 'Download report',
        value:  'Download report'
    }

]


export const CollectionPermissions = [
    {   id: 1,
        title: 'View collection transaction',
        value: 'View collection transaction'
    },
    {   id: 2,
        title: 'Export collection statement',
        value: 'Export collection statement'
    },
    {   id: 3,
        title: 'Create collection payout',
        value: 'Create collection payout'
    }

]

export const CustomersPermissions = [
    {   id: 1,
        title: 'Export customer',
        value: 'Export customer'
    },
    {   id: 2,
        title: 'View customers',
        value: 'View customers'
    },

]

export const WalletPermissions = [
    {   id: 1,
        title: 'Export wallet statement',
        value: 'Export wallet statement'
    },
    {   id: 2,
        title: 'Create account VA',
        value: 'Create account VA'
    },
    {   id: 3,
        title: 'Create Wallet',
        value: 'Create Wallet'
    },
    {   id: 4,
        title: 'View wallet transaction',
        value: 'View wallet transaction'
    },
    {   id: 5,
        title: 'Wallet read privilege',
        value: 'Wallet read privilege'
    },
    {   id: 6,
        title: 'Create wallet payout',
        value: 'Create wallet payout'
    },

]

export const CardPermissions = [
    {   id: 1,
        title: 'Withdraw from card',
        value: 'withdrawal from card'
    },
    {   id: 2,
        title: 'View card transaction',
        value: 'View card transaction'
    },
    {   id: 3,
        title: 'create card',
        value: 'create card'
    },
    {   id: 4,
        title: 'Export card statement',
        value: 'Export card statement'
    },
    {   id: 5,
        title: 'View card details',
        value: 'View card details'
    },
    {   id: 6,
        title: 'Fund card',
        value: 'Fund card'
    },

]

export const TransactionsPermissions = [
    {   id: 1,
        title: 'View transaction',
        value: 'View transaction'
    },
    {   id: 2,
        title: 'Export transaction statement',
        value: 'Export transaction statement'
    },

]

export const ExchangePermissions = [
    {   id: 1,
        title: 'View rate',
        value: 'View rate'
    },

]


export const WirePermissions = [
    {   id: 1,
        title: 'View wire transaction',
        value: 'View wire transaction'
    },
    {   id: 2,
        title: 'Send wire',
        value: 'Send wire'
    },
    {   id: 3,
        title: 'Download receipt',
        value: 'Download receipt'
    },


]

export const PaymentLinkPermissions = [
    {   id: 1,
        title: 'View payment link',
        value: 'View payment links'
    },
    {   id: 2,
        title: 'Create payment link',
        value: 'Create payment link'
    },
]

export const VirtualAccountPermissions = [
    {   id: 1,
        title: 'View VA transaction',
        value: 'View VA transaction'
    },
    {   id: 2,
        title: 'Export VA transaction',
        value: 'Export VA transaction'
    },
    {   id: 3,
        title: 'Instant VA settlement',
        value: 'Instant VA settlement'
    },
]

export const TeamPermissions = [
    {   id: 1,
        title: 'Manage user',
        value: 'Manage user'
    },
    {   id: 2,
        title: 'Manage role',
        value: 'Manage role'
    },
    
]

export const APIKeysPermissions = [
    {   id: 1,
        title: 'View APIKEY',
        value: 'View APIKEY'
    },
]


export const BankAccountPermissions = [
    {   id: 1,
        title: 'View bank account',
        value: 'View bank account'
    },
    {   id: 2,
        title: 'Add bank account',
        value: 'Add bank account'
    },
    {   id: 3,
        title: 'Manage bank account',
        value: 'Manage bank account'
    },
]





