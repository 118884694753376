import styled from "styled-components";

type StyledTransactionsTableProps = {
  width?: number
}

export const StyledTransactionsTable = styled.div<StyledTransactionsTableProps>`
  font-family: "Inter";
  margin-top: ${props => props.width > 768 ? '35px' : '0'};
  overflow: hidden;
  border-top: ${props => props.width > 768 ? '1px solid #f0f0f0' : 'none'};
  padding-top: 1.5rem;

  .transaction-list {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    margin: 20px;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
    line-height: 28px;

    @media (max-width: 768px) {
      margin: 0;
      margin-bottom: 1.5rem;
    }
  }

  @media (min-width: 768px) {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-top: 35px;
  }
`;

export const MobileBody = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;
`;

export const ToggleContainer = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;

  @media(max-width: 768px) {
  padding: 16px 0 0;
  border-bottom: 0;
   border-top: 1px solid #f0f0f0;
   margin-top: 16px;
  }
`;
