import styled from "styled-components";
import Alert from "@reach/alert";

export const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: unset !important;
    z-index: 1001;
    font-family: "Inter";
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1001;
    pointer-events: none;
`;

export const AlertBox = styled(Alert)`
    position: fixed;
    right: 100px;
    bottom: 50px;
    max-width: 370px;
    min-width: 250px;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 10px 16px !important;
    background-color: #f9123b;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: 0.5px solid #d1d1d1;
    box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08);
    .close-icon{
        display: none !important;
    
    }

    border-radius: 8px;
    & > span.close-icon {
        position: absolute;
        top: 10px;
        right: 16px;
        height: 10px;
        width: 10px;
        pointer-events: auto;
    }
    & > span.close-icon svg {
        height: 10px;
        width: 10px;
        display: block;
        pointer-events: auto;
    }
    @media (max-width: 546px) {
        right: 50%;
    }
`;
export const Head = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
`;

export const Message = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e9e9e9 !important;
    margin-bottom: 0;
`;
