import exportFromJSON from 'export-from-json';
import dayjs from "dayjs";
import { formatCurrencyAmount } from '../../utils/currency';

export const tableColumns = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
    },
    {
        title: "Virtual account number",
        dataIndex: "account",
        key: "account",
      },

      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      }
  ];




export const mockVirtualColumn = [
    {
        title: "Bank name ",
        dataIndex: "bank",
        key: "bank",
      },
      {
        title: "Account number",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "Created on",
        dataIndex: "date",
        key: "date",
      },

]



const getExportData = (allCustomers) => {
  return allCustomers?.map((item) => ({
    name: item.name,
    email: item.email,
    phone: item.phone || "---",
    account: item.account || "---",
    date: dayjs(item.date).format("DD.MM.YYYY HH:mm"),
  }));
};

export const exportData = async (allCustomers, fileType) => {
  const data = await getExportData(allCustomers);

  const exportType =
    fileType === "csv"
      ? exportFromJSON.types.csv
      : fileType === "xls"
      ? exportFromJSON.types.xls
      : null;

  if (!exportType) {
    return ("Invalid export file type");
  }

  exportFromJSON({
    data,
    fileName: "Custom Transaction details",
    exportType,
  });
};


export const customTransaction = (data) => {
    return data?.map((item) => ({
        transactionId: item.transaction.tnxRef,
        amount: item.currency + " " + formatCurrencyAmount("", item.amount),
        date:  dayjs(item.date).format("DD.MM.YYYY HH:mm"),
        status: item.transaction.status
    
    }))
}

export const exportTransactionData = async (transaction, fileType) => {
    const data = await customTransaction(transaction);
  
    const exportType =
      fileType === "csv"
        ? exportFromJSON.types.csv
        : fileType === "xls"
        ? exportFromJSON.types.xls
        : null;
  
    if (!exportType) {
      return ("Invalid export file type");
    }
  
    exportFromJSON({
      data,
      fileName: "Customer Transaction",
      exportType,
    });
  };

  
  export const types = exportFromJSON.types;