import React from 'react';
import { Menu, Space } from 'antd';
import { DropDown, Flex } from '../../style';
import DotIcon from '../../../../../../assets/icons/dot-icon.svg';

interface ItemType {
  key: string;
  label: string;
  icon: any;
}

interface Props {
  handleItemClick: (data: string) => void;
  items: ItemType[];
  tableData: any;
}

const MenuList = ({ handleItemClick, items, tableData }: Props) => {
  const hasItems = items && items.length > 0;

  return (
    <Space size="middle">
      {hasItems && (
        <DropDown
          overlay={
            <div>
              <Menu className="drop-menu">
                {items.map((item) => {
                  if (tableData?.payment_status === 'successful' && item.label === 'Update payment') {
                    return null;
                  }

                  return (
                    <Menu.Item
                      key={item.key}
                      onClick={() => handleItemClick(item.key)}
                    >
                      <Flex>
                        <img src={item.icon} width={20} />
                        {item.label}
                      </Flex>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
          }
          trigger={['click']}
        >
          <a>
            <img src={DotIcon} width={50} alt="Dropdown Icon" />
          </a>
        </DropDown>
      )}
    </Space>
  );
};

export default MenuList;
