import React, { createContext, ReactNode, useEffect, useState } from "react";

export const CopyAlertContext = createContext({
    showAlert: false,
    onShowAlert: () => { },
    onClose: () => { },
});

const CopyAlertContextProvider = ({ children }: { children: ReactNode }) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);

    const onShowAlert = () => {
        setShowAlert(true);
    };

    const onClose = () => {
        setShowAlert(false);
    };

    return (
        <CopyAlertContext.Provider
            value={{
                showAlert,
                onShowAlert,
                onClose,
            }}
        >
            {children}
        </CopyAlertContext.Provider>
    );
};

export default CopyAlertContextProvider;
