import React, { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from '../api';
import { persistor } from '../store';
import AppShell from '../../components/AppShell';

interface ProtectedProps {
  element: React.ReactElement;
}

const Protected: React.FC<ProtectedProps> = ({ element }) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const posthog = usePostHog();

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      undefined,
      (error) => {
        if (
          error.response?.status === 401 ||
          error.response?.data?.message === '401 Unauthorized'
        ) {
          logout();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, posthog]);

  const logout = () => {
    posthog.reset();
    persistor.purge().then(() => {
      persistor.flush().then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('u-1-a');
        localStorage.removeItem('expiresAt');
        navigate('/auth/login');
      });
    });
  };

  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }

  return <AppShell>{element}</AppShell>;
};

export default Protected;
