import styled from "styled-components";
import { Table } from "antd";

export const Wrapper = styled(Table)`
    position: relative;
    div[class~="ant-table"]
    div[class~="ant-table-content"] {
    overflow-x: auto;
    overflow-y: hidden;
  }

  & tbody[class="ant-table-tbody"] > tr > td {
    border-bottom: none;
    font-family: "Inter";
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #484848;
    border-bottom: 1px solid #f7f7f7;
  }

  .ant-table-tbody > tr > td {
    padding: 24px 0 24px 16px;
    cursor: pointer;
  }

  .ant-table-tbody > tr > td:first-child{
    padding: 16px 0 16px 16px;
  }

  .ant-table-tbody > tr > td:last-child {
    padding: 16px 10px 16px 16px;
  }

  & thead[class="ant-table-thead"] > tr > th {
    background-color: #f9f9f9;
    padding: 14px 0 14px 16px;
    border-bottom: 1px solid #F0F0F0;
    font-family: "Inter";
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--shades-black-600, #5f5f5f);
    white-space: nowrap;

    :not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }
  }

  & li[class~="ant-pagination-prev"],
  & li[class~="ant-pagination-next"] {
    width: 32px;

    & button[class="ant-pagination-item-link"] {
      border: 1px solid #e8e8e8;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a39d9e;

      &:hover {
        background-color: #e8e8e866;
      }
    }

    & button[class="ant-pagination-item-link"]:disabled {
      color: #a39d9e51;
      border: none;
      background-color: white;
    }
  }

  & li[class~="ant-pagination-item"] {
    min-width: 32px;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    & > a {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #a39d9e;
    }

    &:hover {
      background-color: #e8e8e866;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 20px;
  }

  & li[class~="ant-pagination-item-active"] {
    border: 1px solid #e8e8e8;
    background-color: #e8e8e8;

    & > a {
      color: #000000;
    }
  }
`;

export const EmptyWrapper = styled.div`
  margin-top: 50px;
`;
