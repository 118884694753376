import React, { useEffect, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useToast } from '../../../../app/hooks/useToast';
import { InputBox } from '../../styles';
import { Input } from '../../../../components/common/Input';
import { motion } from "framer-motion";
import { Button } from '../../../../components/common/Button';
import { nextVariants } from '../../../Wallets/utils';
import CustomSelect from '../../../../components/common/CustomSelect';
import Loader from '../../../../components/common/Loader';
import ErrorMessage from '../../../../components/ErrorMessage';
import { Form } from '../../../Wallets/pages/SingleWallet/components/SendFunds/components/StepAmount/style';
import { useAppSelector } from '../../../../app/hooks';
import { createUpdateBankAccount } from '../../../Settings/settingsAPI';
import { getAllBankAccount, getBankAccountDetail } from '../../api';

interface FormInputs {
    bankName?: string;
    accountName?: string;
    accountNumber?: string;
    label?: string;
    value?: string;
    account_name?: string
    account_number?: string
}

interface LocalTransferProps {
    onNext?: () => void;
    setlocalBankInfo?: (data: FormInputs) => void;
    localBankInfo: FormInputs;
    setBankName: (data: string) => void;
    setInitialAccountName: (data: string) => void,
    initialAccountName: string
}

const LocalBeneficiary = ({ onNext, setlocalBankInfo, localBankInfo, setBankName, setInitialAccountName, initialAccountName }: LocalTransferProps) => {
    const [formData, setFormData] = useState<FormInputs>(localBankInfo || {});
    const toast = useToast()

    const [banks, setBanks] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [activeBank, setActiveBank] = useState(null)
    const [error, setError] = useState<string>("no-error");
    const { userId } = useAppSelector((state) => state?.users);


    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<FormInputs>({
        defaultValues: {
            ...formData,
            accountName: initialAccountName,
        },
    });


    const watchAll = watch();
    

    const accountNumberValue = watch("accountNumber");


    const onCreateDefaultAccount = async () => {
        setLoading(true);
        const addPayload = {
            bankCode: watchAll?.bankName,
            accountNumber: watchAll?.accountNumber,
            accountName: initialAccountName,
            country: "Nigeria",
            currency: "NGN",
            owner: userId
        };
        
        try {
            await createUpdateBankAccount(addPayload)
            setLoading(false);
            onNext();
        } catch (error) {
            toast.notifyError(error.response.data.error)
            setLoading(false);
            
        }
    };
    

    const handleBankChange = (selectedBankCode) => {
        const selectedBank = selectBanks.find((bank) => bank.value === selectedBankCode);
        setBankName(selectedBank?.label)
        setActiveBank(selectedBank?.label)
        setlocalBankInfo(selectedBank)

    };

    const selectBanks = Array.isArray(banks)
    ? banks.map((bank) => ({
        label: bank.name,
        value: bank.code,
    }))
    : [];


    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                await getAllBankAccount().then((data) => {
                    setBanks(data.data);
                    setLoading(false)

                });
            } catch (error) {
                setLoading(false)

            }
        }

        fetchData();
    }, []);


    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const data = await getBankAccountDetail({
                    account_number: accountNumberValue,
                    account_bank: watchAll.bankName,
                })
                setlocalBankInfo(data);
                const { account_name } = data.data;
                setInitialAccountName(account_name);
                setLoading(false);
                
                if(data?.status === 'success'){
                    setError("no-error");

                }else if(!data?.status){
                    setError("error");
                    setInitialAccountName("")
                }
            } catch (error) {
                setError("error");
                setInitialAccountName("")
                setLoading(false);
            }
        }
    
        if (accountNumberValue?.length >= 10 && activeBank !== null) {
            fetchData();
        }
    }, [accountNumberValue, activeBank]); 

    useEffect(() => {
        if(accountNumberValue?.length !== 10){
            setInitialAccountName('')
        }
    
    }, [accountNumberValue])


    

  return (
    <motion.div
            variants={nextVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            <Loader isLoading={loading} />
            <Form>
                <InputBox>
                    <Controller
                        name="bankName"
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ field }) => (
                            <CustomSelect
                                placeholder="Select Bank name"
                                $width="100%"
                                field={field}
                                disableLabel
                                options={selectBanks}
                                onChange={handleBankChange}
                                showSearch
                                filterOption={(inputValue, option) =>
                                    option.label.toLowerCase().includes(inputValue.toLowerCase())
                                }
                            />
                        )}
                    />
                    {errors.bankName?.type === "required" && (
                        <span>Please input the beneficiary&apos;s Bank name</span>
                    )}
                </InputBox>

                <InputBox>
                    <Controller
                        name="accountNumber"
                        control={control}
                        rules={{
                            required: true,
                            pattern: /^[0-9]*$/,
                        }}
                        render={({ field }) => (
                            <Input
                                type="number"
                                label="Account Number"
                                field={field}
                                labelColor="#FFFFFF"
                                disableLabel
                            />
                        )}
                    />
                    {watchAll?.accountNumber && (
                        watchAll?.accountNumber?.length !== 10 &&
                            watchAll?.accountNumber?.length !== 0 ? (
                            <ErrorMessage message="Account number must be 10 digits" />
                        ) : (
                            error === "error" && <ErrorMessage message="Account number is not valid" />

                        )
                    )}

                    {errors.accountNumber?.type === "required" && (
                        <span>Please input the beneficiary&apos;s account number</span>
                    )}
                </InputBox>


                <InputBox>
                    <Controller
                        name="accountName"
                        control={control}
                        render={({ field }) => (
                            <Input
                                type="text"
                                label="Account Name"
                                value={initialAccountName}
                                labelColor="#FFFFFF"
                                disableLabel
                                disabled
                            />
                        )}
                    />

                </InputBox>



                <Button
                    label="Continue"
                    height='48px'
                    theme="primary"
                    type="submit"
                    disabled={error
                    === 'error' || 
                    loading ||
                     accountNumberValue?.length !== 10}
                    onClick={handleSubmit((data) => {
                        setlocalBankInfo(data);
                        setFormData(data)
                        onCreateDefaultAccount()
                    })}
                />
            </Form>
        </motion.div>
  )
}

export default LocalBeneficiary;