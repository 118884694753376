import React from "react";
import {
  InfoSection,
  InfoSectionText,
  InfoSectionTitle,
  TopDiv,
} from "../../style";

export interface IBusinessStatus {
  status_title?: string;
  status_message?: any;
  status_icon?: any;
  business_status?: "active" | "pending" | "disapproved";
}

const BusinessStatus = ({
  status_title,
  status_message,
  status_icon,
  business_status,
}: IBusinessStatus) => {
  return (
      <TopDiv $businessStatus={business_status}>
        {status_icon}
        <InfoSection>
          <InfoSectionTitle>{status_title}</InfoSectionTitle>
          {status_message && (
            <InfoSectionText>{status_message}</InfoSectionText>
          )}
        </InfoSection>
      </TopDiv>
  );
};

export default BusinessStatus;
