import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  align-items: center;
  font-family: "Inter";
  margin-top: 8px;

  svg {
    height: 16px;
  }

  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    color: var(--primary-pink);
  }
`;
