import React, { useState, useEffect, useContext } from 'react'
import { Portal } from '@reach/portal';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Wrapper, Back } from "./style";
import { initial, animate, exit } from '../../../components/wire/utils';
import CustomStepper from '../../../components/common/CustomStepper';
import PaymentDetails from './components/PaymentDetails';
import Beneficiary from './components/Beneficiary';
import Review from './components/Review';
import Loader from '../../../components/common/Loader';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg'
import { getReceivingCountries, getWireBeneficiaries } from '../wiresAPI';
import { NetworkErrorAlertContext } from '../../../context/NetworkErrorAlert';
import { formatCurrencyAmount } from '../../../utils/currency';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setBeneficiaries } from '../wiresSlice';
import PaymentStatus from './components/PaymentStatus';

const stages = [
  {
    stage: 1,
    title: "Payment details",
    toolTip: true,
  },
  {
    stage: 2,
    title: "Beneficiary",
  },
  {
    stage: 3,
    title: "Review",
  }
]

export type Countries = {
  value: string;
  label: string;
}

const SendWire
 = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { businessId } = useAppSelector((state) => state.users);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const { onShowAlert } = useContext(NetworkErrorAlertContext);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [receivingCurrencies, setReceivingCurrencies] = useState([]);
  const [payingCurrencies, setPayingCurrencies] = useState([]);
  const [selectedRecCurr, setSelectedRecCurr] = useState(null);
  const [selectedPayCurr, setSelectedPayCurr] = useState(null);
  const [amount, setAmount] = useState('');
  const [payAmount, setPayAmount] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [rate, setRate] = useState(null);
  const [buyingRate, setBuyingRate] = useState(null)
  const [charges, setCharges] = useState(null);
  const [duration, setDuration] = useState('');

  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [desc, setDesc] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);

  const resetAll = () =>{
    setStep(1);
    setSelectedCountry(null);
    setReceivingCurrencies([]);
    setPayingCurrencies([]);
    setSelectedRecCurr(null);
    setSelectedPayCurr(null);
    setAmount('');
    setTotalAmount('');
    setPayAmount('');
    setRate(null);
    setCharges(null);
    setDuration('');
    setSelectedRecipient(null);
    setDesc('');
  }

  const getCountriesAndBeneficiaries = async (id: number) => {
    try {
      setLoading(true);
      const benData = await getWireBeneficiaries(id);
      dispatch(setBeneficiaries(benData))

      const contData = await getReceivingCountries();
      const options = contData.map((c) => ({
        label: c.destinationcountries,
        value: c.destinationcountries,
      }));

      setCountries(options);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      onShowAlert(err, "Failed to get Data");
    }
  };

  useEffect(() => {
    getCountriesAndBeneficiaries(businessId);
  }, []);

  return (
    <AnimatePresence>
      <Portal>
        <Wrapper
          initial={initial}
          animate={animate}
          transition={{ type: "spring", stiffness: 70, when: "beforeChildren" }}
          exit={exit}
          >
          <Loader isLoading={loading} />

          {step > 1 && (
            <Back onClick={() => setStep(step - 1)}>
              <BackIcon/>
            </Back>
          )}

          <CustomStepper 
            stages={stages}
            step={step}
            setStep={setStep}
            onClose={() => navigate('/klasha-wire')}
            tooltipText={`You send ${formatCurrencyAmount(selectedPayCurr, totalAmount)} Beneficiary gets ${formatCurrencyAmount(selectedRecCurr, amount)}`}
          />

          {step === 1 && 
            <PaymentDetails 
              setLoading={setLoading}
              setStep={setStep}
              countries={countries}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              amount={amount}
              setAmount={setAmount}
              setPayAmount={setPayAmount}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              setRate={setRate}
              setDuration={setDuration}
              setCharges={setCharges}
              selectedRecCurr={selectedRecCurr}
              setSelectedRecCurr={setSelectedRecCurr}
              selectedPayCurr={selectedPayCurr}
              setSelectedPayCurr={setSelectedPayCurr}
              receivingCurrencies={receivingCurrencies}
              setReceivingCurrencies={setReceivingCurrencies}
              setPayingCurrencies={setPayingCurrencies}
              payingCurrencies={payingCurrencies}
              setBuyingRate={setBuyingRate}
            />
          }

          {step === 2 && 
            <Beneficiary 
              setLoading={setLoading}
              setStep={setStep}
              desc={desc}
              setDesc={setDesc}
              selectedPayCurr={selectedPayCurr}
              getCountriesAndBeneficiaries={getCountriesAndBeneficiaries}
              selectedRecipient={selectedRecipient}
              setSelectedRecipient={setSelectedRecipient}
            />
          }

          {step === 3 &&
            <Review
              setLoading={setLoading}
              setStep={setStep}
              desc={desc}
              rate={rate}
              buyingRate={buyingRate}
              duration={duration}
              charges={charges}
              amount={amount}
              payAmount={payAmount}
              totalAmount={totalAmount}
              selectedPayCurr={selectedPayCurr}
              selectedRecCurr={selectedRecCurr}
              selectedRecipient={selectedRecipient}
              setPaymentStatus={setPaymentStatus}
            />
          }

          {paymentStatus &&
              <PaymentStatus
                status={paymentStatus}
                setPaymentStatus={setPaymentStatus}
                resetAll={resetAll}
              />
            }
        </Wrapper>
      </Portal>
    </AnimatePresence>
  )
}

export default SendWire
