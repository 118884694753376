import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../components/common/Input";
import { Button } from "../../../components/common/Button";
import { VirtualAccountWrapper, Error  } from "../styles";

interface FormInputs {
    bvn: string;
}

interface Props {
    onSubmit?: (data: FormInputs) => void;
    loading?: boolean;
}

const GenerateVirtualAccount = ({
    onSubmit,
    loading,
}: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm<FormInputs>();

    const watchAll = watch();

    return (
        <VirtualAccountWrapper>
            <h3 data-testid="heading">Generate Virtual Account</h3>
            <Controller
                name="bvn"
                rules={{
                    required: true,
                }}
                control={control}
                render={({ field }) => (
                    <div>
                        <Input
                         data-testid="bvn"
                            type="number"
                            label="Enter your customer's BVN"
                            field={field}
                            labelColor="#FFFFFF"
                        />
                        {errors.bvn?.type ===
                            "required" && (
                            <Error className="error">
                                Please provide your BVN
                            </Error>
                        )}

                        {watchAll?.bvn &&
                            watchAll?.bvn?.length !== 11 &&
                            watchAll?.bvn?.length !== 0 && (
                                <Error data-testid="bvn must be 11">
                                    BVN must be 11 digits
                                </Error>
                            )}
                    </div>
                )}
            />
            <Button data-testid="continue" loading={loading} label="Continue" onClick={handleSubmit((data) => {
          onSubmit(data);
        })}/>
        </VirtualAccountWrapper>
    );
};

export default GenerateVirtualAccount;
