import styled from "styled-components";
import { Select } from "antd";

export const Container = styled.div`
  position: relative;
  font-family: "Inter";

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: ${(props: { $disabled?: boolean }) =>
      props.$disabled ? "#d8d8d8" : "#a3a3a3"};
    position: absolute;
    margin: -8px 14px;
    background-color: #ffffff;
    padding: 0 5px;
    z-index: 2;

    &.input-label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #565c69;
      position: absolute;
      top: 8px;
      left: 14px;
      margin: 0;
      padding: 0;
      display: none;
      &.has-value {
        z-index: -1;
      }
      &.top {
        font-size: 12px;
        line-height: 16px;
        color: #2f343f;
        transform: translate(-6px, -16px);
        padding: 0 2px;
        z-index: 1;
        display: block;
      }
    }
  }
  & svg path {
    stroke: ${(props) => (props.$disabled ? "#a3a3a3" : "#000000")};
  }
`;

export const EmptyContent = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #464646;
`;

export const Wrapper = styled(Select)`
  width: ${(props: {
    $width?: string;
    $height?: string;
    $showBorder?: boolean;
    $showArrow?: boolean;
    $showSelector?: boolean;
  }) => props.$width || "min(285px, 100%)"};
  height: ${(props) => props.$height || "40px"};
  border: ${(props) => (!props.$showBorder ? "1px solid #f0f0f0" : "none")};
  border-radius: 8px;

  .ant-select-arrow {
    display: ${(props) => (props.$showArrow ? "none" : "default")};
    pointer-events: ${(props) => (props.$showArrow ? "none" : "")};
  }

  .ant-select-selector {
    left: ${(props) => (props.$showSelector ? "20px" : "")};
    pointer-events: ${(props) => (props.$showArrow ? "none !important" : "")};
    cursor: pointer !important;
  }

  & span[class="ant-select-selection-item"] {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 14px;
    line-height: 16px;
  }

  & input[class="ant-select-selection-search-input"] {
    color: #000000;
    font-size: 14px;
    line-height: 16px;
  }

  & span[class="ant-select-selection-placeholder"] {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #565c69 !important;
  }
  &[class~="ant-select-disabled"] > div[class="ant-select-selector"] {
    background-color: #ffffff !important;
    color: #a3a3a3 !important;
    cursor: default !important;
  }
  & > div[class="ant-select-selector"] {
    height: 100% !important;
    border-radius: 8px !important;
    border: none !important;
    padding-left: 14px !important;
    box-shadow: none !important;
  }
  & input[class="ant-select-selection-search-input"] {
    height: 100% !important;
  }

  &:hover{
    border: ${(props) => (!props.$showBorder ? "1px solid #a3a3a3" : "none")};
  }
`;
