import React from "react";
import { useParams } from "react-router-dom";
import BankInfo from "./Transfer/Local";
import InternationalTransfer from "./Transfer/International";

interface UserData {
  accountName: string;
  accountNumber: number;
  sortcode: string | number;
  paymentScheme: string;
}

interface LocalBankData {
  accountName: string;
  accountNumber: string;
  bank: string;
}

interface Props {
  activeCurrency: string;
  onNext: () => void;
  basicInfo: UserData;
  setBasicInfo: (data: UserData) => void;
  localBankInfo: LocalBankData;
  setLocalBankInfo: (data: LocalBankData) => void;
  setBankName: (data: string) => void;
  accountName: string;
  setAccountName: (data: string) => void;
  setSelectedPaymentScheme: (data: string) => void;
  identity: string;
  setidentity: (data: string) => void;
  accountType:string;
  setAccountType:(data: string) => void;
}

const StepBeneficiary = ({
  activeCurrency,
  onNext,
  basicInfo,
  setBasicInfo,
  localBankInfo,
  setLocalBankInfo,
  setBankName,
  accountName,
  setAccountName,
  setSelectedPaymentScheme,
  identity,
  setidentity,
  accountType,
  setAccountType
}: Props) => {
  const { currencyCode } = useParams();

  const AfricaTransfer = currencyCode === "NGN" || "GHS" || "ZAR";

  return (
    <div>
      {activeCurrency === "Same currency" && AfricaTransfer && (
        <BankInfo
          onNext={onNext}
          localBankInfo={localBankInfo}
          setlocalBankInfo={setLocalBankInfo}
          setBankName={setBankName}
          initialAccountName={accountName}
          setInitialAccountName={setAccountName}
          accountType={accountType}
          setAccountType={setAccountType}
          identity={identity}
          setIdentity={setidentity}
          
        />
      )}

      {(activeCurrency === "International" ||
        (activeCurrency === "Same currency" &&
          !AfricaTransfer)) && (
        <InternationalTransfer
          setBasicInfo={setBasicInfo}
          basicInfo={basicInfo}
          onNext={onNext}
          setSelectedPaymentScheme={
            setSelectedPaymentScheme
          }
        />
      )}
    </div>
  );
};

export default StepBeneficiary;
