import React, { useState } from "react";
import { Check, CheckFlex, CopyIcon, Flex, Heading, Paragraph, TransactionAmount, TransactionWrapper } from "./style";

interface Props {
    reference: string;
    merchantAmount: string;
    payerAmount: string;
    amountLessFess: string;
    currency: string;
    settledAmount: string;
    rate: string;
    date: string;
    merchant_amount?:string
    transaction_id?:string;
    key?: string;
}

const TransactionDetails = ({
    reference,
    merchantAmount,
    payerAmount,
    amountLessFess,
    currency,
    settledAmount,
    rate,
    date,
    merchant_amount,
    transaction_id,
    key,
}: Props) => {
    const [copy, setCopy] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

    
    return (
        <TransactionWrapper key={key}>
            <TransactionAmount>{merchant_amount}</TransactionAmount>
            <Flex>
                <Paragraph>Reference</Paragraph>
                <CheckFlex>
                {copy ? <Check /> : <CopyIcon onClick={() => onCopyLinkToClipboard(transaction_id)} data-testid="copy-icon"/>}
         
         <Heading>{reference}</Heading>
                </CheckFlex>
                
            </Flex>

            <Flex>
                <Paragraph>Merchant amount</Paragraph>
                <Heading>{merchantAmount}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Payer amount</Paragraph>
                <Heading>{payerAmount}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Amount less fees</Paragraph>
                <Heading>{amountLessFess}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Currency</Paragraph>
                <Heading>{currency}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Settled amount</Paragraph>
                <Heading>{settledAmount}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Settlement rate</Paragraph>
                <Heading>{rate}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Date</Paragraph>
                <Heading>{date}</Heading>
            </Flex>
        </TransactionWrapper>
    );
};

export default TransactionDetails;
