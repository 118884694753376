import styled from "styled-components";
import { Table } from "antd";

export const Wrapper = styled.div`
  width: 100%;
`;
export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 60px;
`;

export const EmptyWrapperTitle = styled.p`
  font-family: "Athletics";
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin: 10px 0 0 0;
`;

export const EmptyWrapperSubTitle = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
  margin-bottom: 24px;
  color: #8F8F8F;
  margin-bottom: 24px;
`;

export const CustomTable = styled(Table)`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  & thead[class="ant-table-thead"] > tr > th {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #5f5f5f;
    padding: 12px 0;
    background-color: #F9F9F9;
  }
  & thead[class="ant-table-thead"] > tr > th:nth-child(1) {
    padding: 12px 0 12px 20px;
  }
  & thead[class="ant-table-thead"] > tr > th:nth-child(2) {
    padding: 12px 0 0 -20px;
  }
  & thead[class="ant-table-thead"] > tr > th::before {
    display: none;
  }
  & tbody[class="ant-table-tbody"] > tr > td {
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: #484848;
    padding: 20px 0;
  }
  & tbody[class="ant-table-tbody"] > tr > td:nth-child(1){
    padding: 20px 0 20px 20px;
  }
  & tbody[class="ant-table-tbody"] > tr > td:nth-child(2){
    padding: 20px 0 0 -20px;
  }

  & li[class~="ant-pagination-prev"],
  & li[class~="ant-pagination-next"] {
    width: 32px;
    & button[class="ant-pagination-item-link"] {
      border: 1px solid #e8e8e8;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #a39d9e;
      &:hover {
        background-color: #e8e8e866;
      }
    }
    & button[class="ant-pagination-item-link"]:disabled {
      color: #a39d9e51;
      border: none;
      background-color: white;
    }
  }

  & li[class~="ant-pagination-item"] {
    min-width: 32px;
    border-radius: 8px !important;
    border: 1px solid #e8e8e8 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    & > a {
      font-family: "Proxima Nova", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #a39d9e;
    }
    &:hover {
      background-color: #e8e8e866;
    }
  }
  .ant-table-pagination.ant-pagination {
    margin: 25px 30px 15px 30px;
  }
  & li[class~="ant-pagination-item-active"] {
    border: 1px solid #e8e8e8;
    background-color: #e8e8e8;
    & > a {
      color: #000000;
    }
  }

  @media (min-width: 1024px) {
    & thead[class="ant-table-thead"] > tr > th {
    padding: 14px 0 14px 50px;
    }
    & tbody[class="ant-table-tbody"] > tr > td {
      padding: 18px 0 18px 50px;
    }
  }
`;

export const Header = styled.div`
  margin-bottom: 40px;

  h1 {
    font-family: "Athletics";
    color: #000000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.48px;
    margin-bottom: 8px;
  }
  p {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; 
    color: #767676;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 4px;
  @media (min-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #464646;
`;

export const BoxBody = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
`;
