import React, { useState, useEffect } from 'react'
import { 
ContentWrapper, 
Header, 
Beneficiaries,
Title,
TextArea,
NotFound,
} from '../../style'
import { usePostHog } from 'posthog-js/react'
import { BeneficiaryProps } from '../../types';
import Search from '../../../../../components/common/Search';
import { useAppSelector } from '../../../../../app/hooks';
import Card from './Card';
import { Button } from '../../../../../components/common/Button';
import CustomModal from '../../../../../components/common/CustomModal';
import BeneficiaryModal from './BeneficiaryModal';

const Beneficiary = ({
  setLoading, 
  setStep, 
  desc,
  setDesc,
  selectedPayCurr,
  selectedRecipient,
  setSelectedRecipient,
  getCountriesAndBeneficiaries,
}: BeneficiaryProps) => {
  const posthog = usePostHog();
  const [search, setSearch] = useState('');
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { beneficiaries } = useAppSelector((state) => state.wires);

  useEffect(() => {
    if (!beneficiaries || beneficiaries.length === 0) return;
  
    const searchQuery = (search || "").toLowerCase();

    const active = beneficiaries.filter((b) => b.disabled !== true);
    const filtered = active.filter(
      (item) =>
        (item.beneficiaryName?.toLowerCase().includes(searchQuery)) ||
        (item.accountNumber?.toString().includes(searchQuery)) ||
        (item.bankName?.toLowerCase().includes(searchQuery))
    );
  
    setFilteredBeneficiaries([...filtered].reverse());
  }, [beneficiaries, search]);

  const onSelectBeneficiary = (beneficiary) => {
    setSelectedRecipient(beneficiary);
  };

  const handleBeneficiary = () =>{
    setStep(3)

    posthog?.capture("beneficiary_wire_b2b", { currency: selectedPayCurr})
  }

  return (
  <ContentWrapper>
    <Header>
      <h1>Enter beneficiary details</h1>
      <p>Kindly provide beneficiary and payment details</p>
    </Header>

    {beneficiaries?.length ?
      <div>
        <Search
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search beneficiary name, account number'
          width='100%'
        />

        {filteredBeneficiaries.length > 0 && 
          <Title>
            <p>Saved beneficiaries</p>
            <span onClick={() => setShowModal(true)}>Add new beneficiary</span>
          </Title>
        }

        <Beneficiaries>
          {filteredBeneficiaries.length ? filteredBeneficiaries.map((beneficiary) => (
             <Card
              key={beneficiary.id}
              accName={beneficiary.beneficiaryName}
              bankName={beneficiary.bankName}
              accNumber={beneficiary.accountNumber}
              currency={beneficiary.country}
              beneficiary={beneficiary}
              selectedRecipientId={selectedRecipient?.id}
              setSelectedRecipient={onSelectBeneficiary}
              disabled={beneficiary.disabled}
              width='250px'
         />
          )) :
          <NotFound>
            <p>No result found</p>
            <Button
              label='Add new beneficiary'
              height='40px'
              fontSize='12px'
              onClick={() =>setShowModal(true)}
            />
          </NotFound>
        }
        </Beneficiaries>

        <Title>
          <p>Description</p>
        </Title>

        <TextArea
           value={desc}
           onChange={(e) => setDesc(e.target.value)}
           rows={4}
           placeholder="Enter description here..."
        />

        <Button
          label='Continue'
          height='40px'
          fontSize='12px'
          disabled={!selectedRecipient}
          onClick={handleBeneficiary}
        />

      </div>
      : 
      <BeneficiaryModal 
        setLoading={setLoading}
        getBeneficiaries={getCountriesAndBeneficiaries}
        showTitle={false}
      />
    }   

    <CustomModal
      isModalVisible={showModal}
      onClose={() => setShowModal(false)}
      width="480px"
    >
      <BeneficiaryModal 
        showTitle={true}
        setShowNewModal={setShowModal} 
        setLoading={setLoading}
        getBeneficiaries={getCountriesAndBeneficiaries}
      />
    </CustomModal>
  </ContentWrapper>
  );
}

export default Beneficiary