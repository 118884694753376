import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AxiosError } from "axios";
import {
  FormBodyDiv,
  PasswordDiv,
  PasswordErrorBox,
  PasswordErrorBoxBody,
  PasswordsDiv,
  SubTitleParagraph,
  TitleParagraph,
  Wrapper,
} from "./style";
import { AuthWrapper } from "../../components/AuthWrapper";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { changePassword } from "../../api/authAPI";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import PasswordErrorItem from "../../components/auth/PasswordErrorItem";
import PasswordCreated from "../../components/auth/PasswordCreated";
import Loader from "../../components/common/Loader";

type Inputs = {
  password: string;
  passwordConfirmation: string;
};

const ResetPassword = () => {
  const {
    handleSubmit,
    control,
    getValues,
    watch,
  } = useForm<Inputs>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const url = window.location.href
  const token = new URL(url)?.searchParams?.get('token')

  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [initialState, setInitialState] = useState(true);
  const watchAll = watch();

  // Check the length of the input
  const checkPasswordLength = (password: string) => {
    if (password?.length >= 8) {
      setCharNumberValid(true);
      return true;
    }
    setCharNumberValid(false);
    return false;
  };

  // Check for special characters
  const checkSpecialCharacters = (password: string) => {
    const pattern = /[ !@#$%^&*()_+={};':,.<>/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
      return true;
    }
    setSpecialCharValid(false);
    return false;
  };

  // Check for an uppercase character
  const checkUppercase = (password: string) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
      return true;
    }
    setUppercaseValid(false);
    return false;
  };

  // Check for a number
  const checkNumber = (password: string) => {
    const pattern = /\d/;
    if (pattern.test(password)) {
      setNumberValid(true);
      return true;
    }
    setNumberValid(false);
    return false;
  };

  const onChangePassword = (val) => {
    setInitialState(false);
    checkPasswordLength(val);
    checkNumber(val);
    checkUppercase(val);
    checkSpecialCharacters(val);
  };

  useEffect(() => {
    const pass = watchAll?.password;

    if (pass || watchAll?.passwordConfirmation) {
      checkPasswordLength(pass);
      checkNumber(pass);
      checkUppercase(pass);
      checkSpecialCharacters(pass);
      setPasswordMatch(pass === watchAll?.passwordConfirmation);
    }
  }, [watchAll?.password, watchAll?.passwordConfirmation]);

  const mutation = useMutation(changePassword, {
    onError: (error: AxiosError) => {
      const errorObj = error?.response?.data;
      const errorMessage = errorObj.error;

      onShowAlert(errorObj, errorMessage);
    },
    onSuccess: async (data) => {
      setIsSubmitted(true);
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    mutation.mutate({
      password: data.password,
      token:  token || '',
    });
  };

  return (
    <AuthWrapper>
      {!isSubmitted ? (
        <Wrapper>
          <Loader isLoading={mutation.isLoading} data-testid='loader'/>
          
          <TitleParagraph>Create new password</TitleParagraph>
          <SubTitleParagraph>
            Enter the new password for your account
          </SubTitleParagraph>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormBodyDiv>
              <PasswordsDiv>

                <PasswordDiv>
                  <span>New password</span>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: true,
                      minLength: 7,
                      maxLength: 20,
                      pattern: /[ !@#$%^&*()_+={};':,.<>/?]/i,
                    }}
                    render={({ field }) => (
                      <Input
                        type="password"
                        label=""
                        field={field}
                        onChange={() => {
                          onChangePassword(field?.value);
                        }}
                        classname={`${
                          field?.value &&
                          !charNumberValid &&
                          !numberValid &&
                          !passwordMatch &&
                          !specialCharValid &&
                          !uppercaseValid &&
                          "password_error_style"
                          // : "password_success_style"
                        }`}
                      />
                    )}
                  />
                </PasswordDiv>
              
              <PasswordDiv>
                <span>Confirm new password</span>
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  rules={{
                    required: true,
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { password } = getValues();
                        setPasswordMatch(password === value);
                        return password === value;
                      },
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      type="password"
                      label=""
                      field={field}
                      />
                  )}
                />
                </PasswordDiv>

                {watchAll?.password?.length !== undefined &&
                watchAll?.password?.length > 0 ? (
                  <PasswordErrorBox $initialState={initialState}>
                    <PasswordErrorBoxBody>
                      <PasswordErrorItem
                        hasError={!charNumberValid}
                        message="8-20 characters"
                      />
                      <PasswordErrorItem
                        hasError={!numberValid}
                        message="At least 1 number"
                      />

                      <PasswordErrorItem
                        hasError={!passwordMatch}
                        message="Password must match"
                      />

                      <PasswordErrorItem
                        hasError={!specialCharValid}
                        message="At least 1 special character(!@#$%^&*()_+-={}[]|:”;’<>?/.,)"
                      />

                      <PasswordErrorItem
                        hasError={!uppercaseValid}
                        message="At least 1 uppercase letter"
                      />
                    </PasswordErrorBoxBody>
                  </PasswordErrorBox>
                ) : (
                  ""
                )}
              </PasswordsDiv>
              <Button 
                label="Submit" 
                type="submit" 
                fontSize="12px"
                height="40px"
                disabled={
                  !passwordMatch || 
                  !charNumberValid || 
                  !numberValid || 
                  !specialCharValid || 
                  !uppercaseValid
                }
              />
            </FormBodyDiv>
          </form>
        </Wrapper>
      ) : (
        <PasswordCreated />
      )}
    </AuthWrapper>
  );
};

export default ResetPassword;
