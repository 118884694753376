import React, { Dispatch, SetStateAction } from "react";
import {
  Wrapper,
  AccountNumber,
  Bottom,
  Label,
  Bank,
  Country,
  Top,
  Circle,
} from "./style";

export interface CardProps {
    bankName?: string;
    accName?: string;
    accNumber?: string;
    beneficiary?: any;
    currency?: string;
    selectedRecipientId?: number | string;
    setSelectedRecipient?: Dispatch<SetStateAction<any>>;
    disabled?: boolean;
    width?:string
  }
const Card = ({
  bankName,
  accName,
  accNumber,
  beneficiary,
  currency,
  selectedRecipientId,
  setSelectedRecipient,
  disabled,
  width,
}: CardProps) => {

    const handleClick = () => {
        if (!disabled && setSelectedRecipient) {
            setSelectedRecipient(beneficiary);
        }
    };
  
  return (
    <Wrapper
      width={width}
      $isSelected={selectedRecipientId === beneficiary.id}
      $disabled={disabled}
      onClick={handleClick}
    >
      <Top>
        <div>
          <Label>{accName}</Label>
          <AccountNumber>{accNumber}</AccountNumber>
        </div>

        <Circle 
          $isSelected={selectedRecipientId === beneficiary.id}
          $disabled={disabled}
        />
      </Top>

      <Bottom>
        <Bank>{bankName}</Bank>
        <Country>{currency}</Country>
      </Bottom>
    </Wrapper>
  );
};

export default Card;
