import styled from "styled-components";
import Alert from "@reach/alert";

export const Wrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 95%;
  z-index: 3;
  font-family: "Inter";

  @media (min-width: 768px) {
    right: 75%;
  }
`;

export const Head = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #1e1e1e;
`;

export const Message = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #3a3a3a;
  opacity: 0.8;
`;

export const AlertBox = styled(Alert)`
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 6px 30px -20px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 20px 24px;
  width: 295px;
  position: absolute;
  & > svg {
  }
  & > button {
    background-color: transparent;
    border: none;
    padding: 5px;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;
