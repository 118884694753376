import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: flex-end;
  
  input{
    max-height: 45px;
}

  .select {
    position: absolute;
    margin: 1px 8px 25px;
    display: flex;
    height: 47px;
  }

  .demo-option-label-item {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: 7px;
  }

  .curr-code {
    color: var(--border-input-active, #000);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  height: 45px;


`;

export const DropDownBoxCurrencyCode = styled.span`
  font-size: 14px;
`;
