import styled from "styled-components";
import { ReactComponent as WarningIcon } from '../../assets/icons/warning-icon.svg';


export const TitleParagraph = styled.p`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin-bottom: 16px;
`;

export const SignupDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  & > span {
    margin-right: 10px;
  }
  & > a {
    font-weight: 600;
    text-decoration: none;
    color: var(--primary-pink);
  }
`;


export const Wrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center; /* Corrected property name */
    
    h1 {
        color: var(--shades-black, #000);
        text-align: center;
        font-family: Athletics;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.48px;
    }
    
    p {
        color: var(--shades-black-500, #767676);
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }
`;

export const Icon = styled(WarningIcon)`
    display: block;
    margin: 0 auto;
`;