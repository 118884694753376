import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';


export const tableColumns = [
    {
        title: "Transaction ID",
        dataIndex: "transaction_id",
        key: "transaction_id",
    },
    {
        title: "Virtual account number",
        dataIndex: "account_number",
        key: "account_number",
    },
    {
        title: "Amount received",
        dataIndex: "amount_received",
        key: "amount_received",
    },
    {
        title: "Amount settled",
        dataIndex: "amount_settled",
        key: "amount_settled",
    },
    

    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
];

export const VirtualAccountTrsaction = (data) => {
    return data?.virtualAccountCollections.map((item) => ({
        transactionId:  item.transaction.tnxRef,
        accountNumber: item.virtualAccountNumber,
        amountReceived: `${item.transaction.currency} ${item.transaction.amount}`,
        amountSettled: `${item.transaction.currency} ${item.transaction.settledAmount}`,        
        date:  dayjs(item.transaction.createdAt).format("DD.MM.YYYY HH:mm"),
    
    }))
}


export const exportVirtualAccountData = async (transaction, fileType) => {
    const data = await VirtualAccountTrsaction(transaction);
  
    const exportType =
      fileType === "csv"
        ? exportFromJSON.types.csv
        : fileType === "xls"
        ? exportFromJSON.types.xls
        : null;
  
    if (!exportType) {
      return ("Invalid export file type");
    }
  
    exportFromJSON({
      data,
      fileName: "Virtual Account Trsancation",
      exportType,
    });
  };
