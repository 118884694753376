import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-family: "Inter"

  & p {
    margin-bottom: 0;
  }
`;

export const TitleParagraph = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 24px !important;
  font-family: Inter;
`;

export const Top = styled.div`
  margin-bottom: 32px;
  /* font-size: 14px; */
  & div[class~="ant-tabs-tab"] {
    padding: 4px 0;
    margin-bottom: 10px;
  }
  & div[class~="ant-tabs-tab"]:not(:first-child) {
    padding-left: 10px;
    margin-left: 0;
  }
  & div[class~="ant-tabs-tab"]:not(:last-child) {
    padding-right: 10px;
    border-right: 1px solid #000000;
  }
  & div[class~="ant-tabs-tab-btn"] {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    outline-color: #000000;
  }
  & div[class~="ant-tabs-tab-btn"][aria-disabled="true"] {
    color: #717070 !important;
    outline-color: #717070 !important;
  }
  & div[class~="ant-tabs-tab-active"] > div[class~="ant-tabs-tab-btn"] {
    color: var(--primary-pink) !important;
    outline-color: var(--primary-pink) !important;
  }
  & div[class~="ant-tabs-ink-bar"] {
    background-color: transparent;
  }
  & div[class~="ant-tabs-nav"]::before {
    border-bottom: none;
  }
  & div[class~="ant-tabs-nav-wrap"] div[class~="ant-tabs-nav-list"] {
    flex-wrap: wrap;
  }
  @media (min-width: 768px) {
    & div[class~="ant-tabs-tab"] {
      margin-bottom: 0;
    }
  }
`;
