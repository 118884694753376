import styled from "styled-components";

export const PasswordErrorBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 8px;
  & p {
    margin: unset;
    margin-left: 5px;
  }
`;

export const PasswordErrorText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  margin: 0;
  color: ${(props: { $hasError: boolean }) =>
    props.$hasError ? "#F9123B" : "#0EAB44"};
`;
