import api from "../../app/api";

export const getMerchant = async (userId) => {
  const { data } = await api.get(`merchant/user/${userId}`);
  return data;
};

export const fetchBankAccounts = async ({ userId }) => {
  const { data } = await api.get(`merchant/bankaccount/user/${userId}`);
  return data;
};

export async function createUpdateBankAccount(payload) {
  const { data } = await api.post("merchant/bankaccount/", payload);
  return data;
}

export async function createUpdateBusiness(payload) {
  const { data } = await api.post("merchant/business/", payload);
  return data;
}

export async function getBusinessTypes() {
  const { data } = await api.get("merchant/businesstype");
  return data;
}

export async function getBusinessCategories() {
  const { data } = await api.get("merchant/businesscategory");
  return data;
}

export async function getBusinessContacts(businessId) {
  const { data } = await api.get(
    `merchant/businessstaff/${businessId}/business`
  );
  return data;
}

export async function createUpdateBusinessContacts(payload) {
  const { data } = await api.post("merchant/businessstaff/", payload);
  return data;
}

export async function getContactRoles() {
  const { data } = await api.get("merchant/businessstaffrole");
  return data;
}

export async function getBusinessKeys(businessId) {
  const { data } = await api.get(`merchant/v2/business/${businessId}/keys`);
  return data;
}

export async function getBusinessWallet(businessId) {
  const { data } = await api.get(`wallet/business/${businessId}/all`);
  return data;
}

export async function createBusinessPaymentOption(payload) {
  const { data } = await api.post(
    "/merchant/business/payment/options",
    payload
  );
  return data;
}

export async function getBusinessPaymentOptions(payload) {
  const { data } = await api.post("merchant/merchantexchange", payload);
  return data;
}

export async function deletePaymentOptionById({
  businessId,
  paymentOptionId,
}: {
  businessId: number;
  paymentOptionId: number;
}) {
  const { data } = await api.get(
    `/merchant/business/payment/options/delete/${paymentOptionId}/${businessId}`
  );
  return data;
}

export async function getAllPaymentOptions() {
  const { data } = await api.get("/merchant/payment/options");
  return data;
}


export const getBankList = async (countryCode: string) => {
  const { data } = await api.get(
    `${process.env.REACT_APP_API_URL}pay/transfer/flutter/banks/${countryCode}`,
    {
      headers: {
        "x-auth-token": "GByi/gkhn5+BX4j6uI0lR7HCVo2NvTsVAQhyPko/uK4=",
      },
    }
  );
  return data;
};

export const getAccountName = async (accountNumber: string, bankCode: string) => {
  const { data } = await api.post(
    `${process.env.REACT_APP_API_URL}pay/transfer/flutter/resolve/account`,
    {
      account_number: accountNumber,
      account_bank: bankCode,
    },
    {
      headers: {
        "x-auth-token": "GByi/gkhn5+BX4j6uI0lR7HCVo2NvTsVAQhyPko/uK4=",
      },
    }
  );
  return data?.data?.account_name;
};

export const createWebHook = async (data) => {
    const response = await api.post(`/merchant/businesswebhook`, data)
    return response.data

}

export const CreateBusinessLogo = async (data) => {
    const response = await api.post(`/merchant/customization`, data)
    return response.data
}

export const UpdateMerchantBusinessLogo = async (data) => {
    const response = await api.patch(`/merchant/customization`, data)
    return response.data
}

export const GetMerchantBusinessLogo = async (businessId) => {
    const response = await api.get(`/merchant/customization/${businessId}?type=EMAIL`)
    return response.data
}

export const getBusinessWebHook = async ({id}) => {

    const response = await api.get(`/merchant/businesswebhook/business/${id}`)
    return response.data


}

export const UpdateMerchantDetails = async (data, id) => {
    const response = await api.patch(`/merchant/business/${id}/update`, data)
    return response.data
}
