import React from "react";
import { EmptyWrapperStyle } from "../../styles";
import { Button } from "../../../../../components/common/Button";

interface Props {
    handleInviteTeam: () => void;
    loading?: boolean;
}

const EmptyWrapper = ({
    handleInviteTeam,
    loading,
}: Props) => {
    return (
        <>
            {!loading && (
                <EmptyWrapperStyle>
                    <h1>
                        Do you want to invite team members?
                    </h1>
                    <p>
                        Give your team and partners access
                        to this account <br /> to make
                        business operations easier.
                    </p>
                    <Button
                        onClick={handleInviteTeam}
                        label="Invite team member"
                        height="40px"
                        width="156px"
                    />
                </EmptyWrapperStyle>
            )}
        </>
    );
};

export default EmptyWrapper;
