import React from "react";
import {
  Wrapper,
  Top,
  Bottom,
  BottomItem,
  BottomLabel,
  BottomValue,
  BottomTransactionValue
} from "./style";
import TransactionStatus from "../../transactions/components/TransctionStatus";
import { formatLongString } from "../../../utils/formatString";

interface CardProps {
  amount: string;
  payoutId: string;
  accBank: string;
  accNo: string;
  accName: string;
  status: string;
  reqDate: string;
}

const BalanceListCard = ({
  amount,
  payoutId,
  accNo,
  accBank,
  accName,
  status,
  reqDate,
}: CardProps) => {
  return (
    <Wrapper title="balance-card">
      <Bottom>
        <BottomItem>
          <BottomLabel>Payout ID</BottomLabel>
          <BottomValue>{formatLongString(payoutId, 15)}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Bank</BottomLabel>
          <BottomValue>{accBank}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Account name</BottomLabel>
          <BottomValue>{accName}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Account number</BottomLabel>
          <BottomValue>{accNo}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Amount</BottomLabel>
          <BottomValue>{amount}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Status</BottomLabel>
          <BottomTransactionValue><TransactionStatus text={status}/></BottomTransactionValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Date & Time</BottomLabel>
          <BottomValue>{reqDate}</BottomValue>
        </BottomItem>
      </Bottom>
    </Wrapper>
  );
};

export default BalanceListCard;
