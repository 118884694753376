import React from "react";
import {
    Module,
    PermissionItem,
    PermissionTitle,
} from "../../styles";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as UnCheckIcon } from "../../assets/icons/uncheck.svg";

import {
    APIKeysPermissions,
    BankAccountPermissions,
    CardPermissions,
    CollectionPermissions,
    CustomersPermissions,
    DashboardPermissions,
    ExchangePermissions,
    PaymentLinkPermissions,
    TeamPermissions,
    TransactionsPermissions,
    VirtualAccountPermissions,
    WalletPermissions,
    WirePermissions,
} from "../../utils/RoleList";
import { removeHyphensAndDashes, titleCase } from "../../utils/capitalizeName";

interface PermissionItem {
    id: number;
    title: string;
}

interface PermissionsProps {
    module: string;
    permissions: string[];
}

const RolePermissionList: React.FC<PermissionsProps> = ({
    module,
    permissions,
}) => {
    const permissionsMap: Record<string, PermissionItem[]> =
        {
            dashboard: DashboardPermissions,
            collections: CollectionPermissions,
            customers: CustomersPermissions,
            wallet: WalletPermissions,
            card: CardPermissions,
            transactions: TransactionsPermissions,
            "exchange-rates": ExchangePermissions,
            wire: WirePermissions,
            "api-keys": APIKeysPermissions,
            "bank-account": BankAccountPermissions,
            "payment-links": PaymentLinkPermissions,
            team: TeamPermissions,
            "virtual-account": VirtualAccountPermissions
        };


    const modulePermissions = permissionsMap[module] || [];
   

    return (
        <div>
            <Module>{removeHyphensAndDashes(module)}</Module>
            {modulePermissions.map((permission) => (
                <div key={permission.id}>
                    <PermissionItem>
                        <PermissionTitle>
                            {titleCase(permission.title)}
                        </PermissionTitle>
                        {permissions.includes(
                            permission.title
                                .toUpperCase()
                                .replace(/\s+/g, "_"),
                        ) ? (
                            <CheckIcon />
                        ) : (
                            <UnCheckIcon />
                        )}
                    </PermissionItem>
                </div>
            ))}
        </div>
    );
};

export default RolePermissionList;
