import api from "../../app/api";
import dayjs from "dayjs";

const dateFormat = 'YYYY-MM-DD';

export const getFilteredSales = async (businessId: string, date: string, values?:any) => {
  let startDate: string;
  let endDate: string;
  const end: string = dayjs().format(dateFormat);

  if(date === '24'){
    startDate = dayjs().subtract(1, 'day').format(dateFormat);
    endDate = end;
  } else if (date === '30'){
    startDate = dayjs().subtract(29, 'day').format(dateFormat);
    endDate = end;
  } else if (date === '3'){
    startDate = dayjs().subtract(3, 'month').format(dateFormat);
    endDate = end;
  } else if (date === '12'){
    startDate = dayjs().subtract(12, 'month').format(dateFormat);
    endDate = end;
  } else if (date === 'custom'){
    startDate = (values[0]);
    endDate = values[1];
  } else {
    startDate = dayjs().subtract(6, 'day').format(dateFormat);
    endDate = end;
  }

  const { data } = await api.post(
    `wallet/extra/admin/tnx/narration/date/range?businessId=${businessId}&transactionType=0`,
    JSON.stringify({
      startDate : `${startDate} 00:00:00`,
      endDate : `${endDate} 23:59:59`,
    }));
  return data;
};

export const getLastSevenDaysSales = async (businessId: string) => {
  const end = dayjs().format(dateFormat);
  const start = dayjs().subtract(6, 'day').format(dateFormat);

  const { data } = await api.post(
    `wallet/extra/admin/tnx/narration/date/range?businessId=${businessId}&transactionType=0`,
    JSON.stringify({
      startDate : `${start} 00:00:00`,
      endDate : `${end} 23:59:59`,
    }));
  return data;
};

export const getLastThirtyDaysSales = async (businessId: string) => {
  const endDate = dayjs().format(dateFormat);
  const startDate = dayjs().subtract(29, 'day').format(dateFormat);

  const { data } = await api.post(
    `wallet/extra/admin/tnx/narration/date/range?businessId=${businessId}&transactionType=0`,
    JSON.stringify({
      startDate : `${startDate} 00:00:00`,
      endDate : `${endDate} 23:59:59`,
    }));
  return data;
};

export const getCurrentDayTotalSales = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/daily/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentWeekTotalSales = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/weekly/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentMonthTotalSales = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/monthly/sum/${businessId}/0`
  );
  return data;
};

export const getPreviousMonthTotalSales = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/previous/month/sum/${businessId}/0`
  );
  return data;
};

export const getCurrentWeekSalesData = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/weekly/chart/${businessId}/0`
  );
  return data;
};

export const getCurrentMonthSalesData = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/monthly/chart/${businessId}/0`
  );
  return data;
};

export const getPreviousMonthSalesData = async (businessId: string) => {
  const { data } = await api.get(
    `wallet/extra/admin/tnx/narration/previous/month/chart/${businessId}/0`
  );
  return data;
};

export async function getMerchantBusiness(businessId: string) {
  const { data } = await api.get(`merchant/business/${businessId}`);
  return data;
}

export async function getBusinessWallet(businessId: string) {
  const { data } = await api.get(`wallet/business/${businessId}/all`);
  return data;
}


export async function getBusinessApproval(businessId: string){
    const { data } = await api.get(`merchant/business/${businessId}`)
    return data

}