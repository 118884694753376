import React, { ChangeEvent, useContext, useState } from "react";
import { Select, Tooltip } from "antd";
import {
  Header,
  Wrapper,
  CustomInput,
  TextArea,
  Amount,
  ButtonsDiv,
  CustomSelect,
  CustomSelectTwo,
  SelectOption,
  Currency,
  Limit,
  IntervalBox,
} from "./style";
import { Button } from "../../../common/Button";
import { useAppSelector } from "../../../../app/hooks";
import { NewLinkModalProps, LinkProps } from "./types";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import ErrorMessage from "../../../ErrorMessage";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/arrow-down.svg";
import { currencies } from "../../../settings/Accounts/components/AccountModal/utils";
import { PAYLINK_ENV, intervals, message } from "./utils";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/info.svg"
import { createLink } from "../../../../pages/PaymentLinks/linksAPI";

const { Option } = Select;

const NewLinkModal = ({ 
  paymentType, 
  setSuccess, 
  setLoading, 
  setShowModal,
  setNewLink
  }: NewLinkModalProps) => {
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const [error, setError] = useState(false);
  const { businessCurrency } = useAppSelector((state) => state.settings);
  const { businessId } = useAppSelector((state) => state.users);

  const initialState = {
    title: '',
    desc: '',
    amount: '',
    currency: businessCurrency,
    interval: '',
    limit: '',  
  }
  const [linkForm, setLinkForm] = useState(initialState);


  const checkForErrors = (item: LinkProps) => {
    if( paymentType === "ONETIME" && (
        !item.title ||
        !item.desc ||
        !item.amount
    )) {
      setError(true)
    } else if ( paymentType === "RECURRING" && (
      !item.title ||
      !item.desc ||
      !item.amount ||
      !item.interval
    )) {
      setError(true)
    } else {
      setError(false)
      createNewLink(item)
    }
  };

  const createNewLink = async (data: LinkProps) => {    
      const payload = {
        businessId,
        linkName: data.title,
        description: data.desc,
        paymentAmount: +data.amount,
        selectedCurrency: data.currency,
        PAYLINK_URL: PAYLINK_ENV,
        paymentType,
        paymentInterval: +data.interval || null,
        paymentLimit: +data.limit || null,
      }

      try {
        setLoading(true);
        const res = await createLink(payload);
        setNewLink(res)
        setLinkForm(initialState)
        setLoading(false);
        
        setShowModal(false)
        setSuccess(true)
      } catch (err) {
        setLoading(false);
        onShowErrorAlert(err, err.message);
      } 
  }

  

  return (
    <div>
      <Header>Create Payment Link</Header>
      <Wrapper>
        <div>
          <CustomInput
            value={linkForm.title}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLinkForm({...linkForm, title: e.target.value})}
            placeholder="Title"
          />
          
          {!linkForm.title && error ? <ErrorMessage message="Field is required" /> : null}
        </div>

        <div>
          <TextArea
            value={linkForm.desc}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setLinkForm({...linkForm, desc: e.target.value})}
            rows={4}
            placeholder="Description"
          />
          
          {!linkForm.desc && error ? <ErrorMessage message="Field is required" /> : null}
        </div>
       
       <div>
          <Amount> 
            <Currency
              placeholder="Amount"
              value={linkForm.amount}
              onValueChange={(value: string) => setLinkForm({...linkForm, amount: value})}
            />

            <CustomSelect
              dropdownMatchSelectWidth={false}
              placement="bottomLeft"
              value={linkForm.currency}
              onChange={(value: number) => setLinkForm({...linkForm, currency: value})}
              suffixIcon={<DropdownIcon />}
              popupClassName="filter-dropdown"
            >
              {currencies.map((curr) => (
                <Option key={curr.label} value={curr.value}>
                  <SelectOption>
                    <span className={`currency-flag currency-flag-${curr.value.toLowerCase()}`} />
                    <span>{curr.value}</span>
                  </SelectOption>
              </Option> 
              ))}
            </CustomSelect>
          </Amount>

          {!linkForm.amount && error ? <ErrorMessage message="Field is required" /> : null}
        </div>

        {paymentType === "RECURRING" && (
          <>
            <IntervalBox>
              <CustomSelectTwo
                value={linkForm.interval}
                onChange={(value: string) => setLinkForm({...linkForm, interval: value})}
                suffixIcon={<DropdownIcon />}
                popupClassName="filter-dropdown"
              >
              {intervals.map((item) => (
                  <Option key={item.label} value={item.value}>
                    <SelectOption>
                      {item.label}
                    </SelectOption>
                  </Option> 
                ))}
              </CustomSelectTwo>
              {!linkForm.interval && <p>Interval</p>}  

              {!linkForm.interval && error ? <ErrorMessage message="Field is required" /> : null}
            </IntervalBox>

            <Limit>
              <CustomInput
                type="number"
                placeholder="Invoice limit"
                value={linkForm.limit}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setLinkForm({...linkForm, limit: e.target.value})}
              />
              <Tooltip placement="topRight" title={message} color="#313131">
                <span className="tooltip">
                  <AlertIcon />
                </span>
              </Tooltip>
            </Limit>
            </>
        )}
      </Wrapper>
                
      <ButtonsDiv>
        <Button
          label="Reset"
          type="button"
          theme="alternate"
          height="40px"
          fontSize="12px"
          onClick={() => setLinkForm(initialState)}
        />
        <Button
          label="Create link"
          theme="secondary"
          height="40px"
          fontSize="12px"
          onClick={() =>checkForErrors(linkForm)}
        />
      </ButtonsDiv>
    </div>
  );
};

export default NewLinkModal;