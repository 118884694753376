import React, {useContext} from 'react'
import { Wrapper, TitleParagraph, FormStyle, FormInputDiv, LoginButton } from "./styles";
import { AuthWrapper } from "../../../components/AuthWrapper";
import { Input } from "../../../components/common/Input";
import { Button } from "../../../components/common/Button";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { InputProps } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { adminLogin } from "../../redux/action";
import Loader from "../../../components/common/Loader";
import { useNavigate } from "react-router-dom";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";



const AdminLogin = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<InputProps>({
    defaultValues: {
      username: "",
      password: "",
    },
  });


  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.admin || {});
  const navigate = useNavigate();
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );

  const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  const onSubmit: SubmitHandler<InputProps> = async (data, e) => {
    const action = await dispatch(
        adminLogin({
          username: data.username,
          password: data.password,
        })
    );

    if(adminLogin.fulfilled.match(action)) {
      navigate("/white-house/dashboard");
    }

    else  if(adminLogin.rejected.match(action)) {
        onShowErrorAlert("error", String(action.payload));
    }

  };

  return (
      <AuthWrapper>
        <Wrapper>
          <Loader isLoading={loading} />

          <FormStyle onSubmit={handleSubmit(onSubmit)}>
            <TitleParagraph>Welcome Admin</TitleParagraph>

            <FormInputDiv>
              <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: true,
                    pattern: mailformat,
                  }}
                  render={({ field }) => (
                      <Input
                          type="email"
                          label="Email"
                          value={field.value}
                          onChange={field.onChange}
                          hasError={!!errors.username}
                      />
                  )}
              />
              {errors.username?.type === "required" && <span>Field is required</span>}
            </FormInputDiv>

            <FormInputDiv>
              <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                      <Input
                          type="password"
                          label="Password"
                          value={field.value}
                          onChange={field.onChange}
                          hasError={!!errors.password}
                      />
                  )}
              />
              {errors.password?.type === "required" && (
                  <span>Field is required</span>
              )}
            </FormInputDiv>

            <LoginButton>
              <Button
                  label="Log in"
                  type="submit"
                  disabled={loading}
                  height="40px"
                  fontSize="12px"
              />
            </LoginButton>
          </FormStyle>
        </Wrapper>
      </AuthWrapper>
  );
};

export default AdminLogin;
