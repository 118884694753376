import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px;
  margin: 0;

  @media(max-width: 768px) {
    border-bottom: none;
    padding: 0;

    button {
      width: 100%;
      background-color: #EF2C5A;
    }
  }
`;

export const ToggleButtonContainer = styled.div`
 @media(max-width: 768px) {
  display: none
 }
`

export const ToggleButtonContainerMobile = styled.div`
 display: none;

 @media(max-width: 768px) {
  display: block;
  margin-top: 1rem;
 }
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 16px;
  z-index: 2;
  gap: 1rem;

  @media(max-width: 768px) {
    padding: 1rem 0;
    border-bottom: 1px solid #F0F0F0;
  }
`;


export const Title = styled.p`
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin: 0;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 94px;
  height: 40px;
  border: 1px solid #000000;
  border-radius: 8px;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 40px;
    padding: 0;
    justify-content: center;
  }

  span {
    font-family: "Inter";
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;

    @media (max-width: 768px) {
      display: none;
      width: max-content;
    }
  }
`;

export const RightButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  @media (min-width: 768px) {
    width: unset;
    justify-content: flex-start;
    gap: 12px;
  }
`;

export const FilterBox = styled.div`
  padding: 32px 24px;
  position: absolute;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
  width: 333px;
  right: -16px;
  bottom: unset;
  top: calc(100% + 42px);
  font-family: "Inter";

  @media (max-width: 768px) {
    left: -30px;
  }
`;

export const FilterBoxItem = styled.div`
  margin-bottom: 24px;
`;
export const FilterBoxItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222223;
  margin-bottom: 8px;
`;

export const FilterBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterFooterResetButton = styled.button`
  width: 78px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const FilterFooterApplyButton = styled.button`
  width: 69px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
`;
