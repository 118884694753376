import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as KlashaLogo } from "../../../../assets/icons/logo.svg";

import { Switch } from "antd";
import { motion, Variants } from "framer-motion";

export const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 200, damping: 15 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.5 } }
};

export const list = {
  open: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.3,
    },
  },
  closed: {
    opacity: 0,
    transition: {
      when: "afterChildren",
    },
  },
}

export const Wrapper = styled.div`
  position: sticky;
  inset: 0;
  z-index: 2;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  .overlay {
    position: fixed;
    inset: 0;
    width: 100%;
    height: 100vh;
  }

  @media(max-width: 992px) {
    padding: 0 16px;
  }
`;

export const LogoMenuDiv = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  gap: 12px;

  button {
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
    svg {
      height : 22px;
    }
  }

  @media (min-width: 993px) {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
  }

  p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0 16px;
    visibility: hidden;
  }

  @media (min-width: 993px) {
    width: 100%;

    p {
      visibility: ${(props: { back: boolean}) => props.back ? "visible" : "hidden"};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

interface Props {
  isOpen: boolean;
}


export const UserBox = styled(motion.div)<Props>`
  display: flex;
  position: relative;
  align-items: center;
  gap: 2px;
  cursor: pointer;

  .dropdown {
    position: absolute;
    top: 35px;
    right: 0;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    gap: 20px;
    padding: 14px;
    background: #ffffff;
    border: 1px solid #000000;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.08), 2px 4px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    width: 180px;
    list-style: none;
    z-index: 3;
  }

  .dropdown-space{
    margin-top: -20px;

}
`;


export const DropdownMenuItem = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: 10px;

   span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  } 

  &:hover {
    span {
      color: var(--primary-pink);
    }
    &:nth-child(1) svg path {
      stroke: var(--primary-pink);
    }
    &:nth-child(2) svg path {
      fill: var(--primary-pink);
      stroke: none !important;
    }
  } 

`;


export const DropdownMenuButton = styled(motion.li)`
  display: flex;
  align-items: center;
  gap: 10px;
  
  span {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
  } 

  &:hover {
    span {
      color: var(--primary-pink);
    }
    svg path {
      stroke: var(--primary-pink);
    }
  } 
`;

export const PendingComplaince = styled.div`
  position: sticky;
  inset: 0;
  z-index: 2;
  background: #FEF3C7;
  width: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  h1 {
    font-family: "Athletics";
    color: #000000;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px; 
    letter-spacing: -0.4px;
  }

  p {
    font-family: "Inter";
    color: #565C69;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15.6px;
  };

  span {
    color: var(--primary-pink);
    font-family: "Inter";
    font-weight: 500;
    line-height: 15.6px;
    text-decoration: underline;
    cursor:pointer;
  }
`;

export const ToggleBox = styled.div`
  display: none;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
  }
  & p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-left: 8px;
  }
`;


export const Toggle = styled(Switch)`
  &[class~="ant-switch-checked"] {
    background-color: var(--primary-pink);
  }
`;

export const Close = styled(CloseIcon)`
cursor:pointer;
margin-left: 40px;

`

export const Logo = styled(KlashaLogo)`
@media screen and (max-width: 759px){
    display: none;

}

`