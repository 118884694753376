import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

`;

export const BodyDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const HeaderTitle = styled.p`
  font-family: "Athletics";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #191919;
  margin-bottom: 8px;
`;

export const SubtitleText = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px; 
  letter-spacing: -0.32px;
  text-align: center;
  color: #A39D9E;

`;
