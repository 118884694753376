import styled from "styled-components";
import Alert from "@reach/alert";

export const Wrapper = styled.div`
    position: absolute;
    top: 20px;
    right: 95%;
    z-index: 3;
    font-family: "Inter";

    @media (min-width: 768px) {
        right: 75%;
    }
`;

export const Head = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
`;

export const Message = styled.p`
color: var(--shades-black, #0A0A0A);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500 !important;
line-height: 24px;
letter-spacing: -0.32px;
margin: 0;
padding: 0;
`;

export const AlertBox = styled(Alert)`
    position: fixed;
    right: 20px;
    top: 50px;
    min-width: 300px;
    padding: 10px 20px !important;
    color: #0a0a0a;
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0px 4px 48px 0px rgba(0, 0, 0, 0.08); 
    font-family: Inter !important;
    border-left: 4px solid #0eab44 !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border: 0.5px solid #d1d1d1;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.04),
        0px 4px 8px -2px rgba(16, 24, 40, 0.04);
    border-radius: 6px;

    & > button {
        position: absolute;
        top: 10px;
        right: 16px;
        height: 10px;
        width: 10px;
        background: unset;
    }
    & > button svg {
        height: 10px;
        width: 10px;
        display: block;
    }
    @media (max-width: 546px) {
        right: 50%;
    }
`;
export const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .success-icon{

}

  .close-icon {
    margin-left: auto;
  }
`;


export const AlertBoxWrapper = styled.div`
display: flex;
align-item:center;
gap: 10px;

`