export function capitalizeName(name) {
    if (typeof name !== "string" || name.length === 0) {
        return "";
    }

    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}


export function removeHyphensAndDashes(str) {
    if(str === 'api-keys'){
        return 'API keys'
    }else{
        str = str.replace(/[-–—]/g, ' ');
    }

    return str.toLowerCase().split(' ').map(function(word, index) {
        if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
            if (word === 'api') {
                return 'API';
            } else {
                return word;
            }
        }
    }).join(' ');
}



export function titleCase(str) {
    if(str === "View APIKEY"){
        return "View api key"
    }
    else{
        return str.toLowerCase().split(' ').map(function(word, index){
            if(index === 0){
            return word.charAt(0).toUpperCase() + word.slice(1);

            }else{
                return word
            }
        
        }).join(' ');
    
    }
    


}