import React from "react";
import { BodyDiv, Footer, HeaderTitle, SubtitleText, Wrapper } from "./style";
import { ReactComponent as SuccessMailIcon } from "../assets/mail-sent.svg";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";

const PasswordSent = ({ email }: { email: string }) => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate("/login");
  };
  return (
    <Wrapper>
      <BodyDiv>
        <SuccessMailIcon />
        <HeaderTitle>Password reset link sent</HeaderTitle>
        <SubtitleText>
          {`If an account for ${email} exists, you will receive an email with
          instructions to reset your password `}
        </SubtitleText>
      </BodyDiv>
      <Footer>
        <Button
          label="Proceed to login"
          type="button"
          theme="primary"
          onClick={onLoginClick}
        />
      </Footer>
    </Wrapper>
  );
};

export default PasswordSent;
