import React, {
    useContext,
    useState,
    useEffect,
} from "react";
import { usePostHog } from "posthog-js/react";
import { useNavigate } from "react-router-dom";
import {
    Wrapper,
    Title,
    BoxBody,
    DownloadButton,
    EmptyWrapper,
    EmptyWrapperTitle,
    EmptyWrapperSubTitle,
    MobileBody,
} from "./style";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import { useWindowSize } from "@reach/window-size";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download_icon.svg";
import Loader from "../../components/common/Loader";
import { getWirePaymentsFiltered } from "./wiresAPI";
import dayjs from "dayjs";
import { setWireTransactions } from "./wiresSlice";
import { formatCurrencyAmount } from "../../utils/currency";
import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import { WireTableData } from "../../components/wire/utils";
import { Button } from "../../components/common/Button";
import CustomTable from "../../components/common/CustomTable";
import WireCard from "../../components/wire/components/WireCard";
import EmptyMobileCard from "../../components/emptyStates/EmptyMobileCard";
import CustomModal from "../../components/common/CustomModal";
import Receipt from "../../components/wire/components/Receipt";
import TitleFilter from "../../components/wire/components/TitleFilter";
import { stringToSentenceCase } from "../../utils/format";
import { selectUserRoleAndPermissions } from "../Settings/Team/redux/slice";

const EmptyWireDescription = ({
    sendWire,
}: {
    sendWire: () => void;
}) => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>
                No wire transactions yet
            </EmptyWrapperTitle>
            <EmptyWrapperSubTitle>
                All wire transactions done will appear here.
            </EmptyWrapperSubTitle>

            <Button
                label="Send wire"
                theme="secondary"
                width="230px"
                height="40px"
                fontSize="12px"
                onClick={sendWire}
            />

            <a
                href="https://wa.me/14157671834"
                target="_blank"
                rel="noreferrer"
            >
                Get daily FX rates on WhatsApp
            </a>
        </EmptyWrapper>
    );
};

const KlashaWire = () => {
    const posthog = usePostHog();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { width } = useWindowSize();
    const receiptRef = React.useRef();
    const [
        filteredWireTransaction,
        setFilteredWireTransaction,
    ] = useState([]);
    const [showReceiptModal, setShowReceiptModal] =
        useState(false);
    const [currentWire, setCurrentWire] =
        useState<WireTableData>(null);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { wireTransactions } = useAppSelector(
        (state) => state.wires,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const hasSendWirePermission =
        permissions?.["wire"]?.includes("SEND_WIRE");
    const hasViewReceiptPermission = permissions?.[
        "wire"
    ]?.includes("DOWNLOAD_RECEIPT");

    const handleReceipt = () => {
        if (hasViewReceiptPermission || role === "OWNER") {
            setShowReceiptModal(true);
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };    

    const tableColumns = [
        {
            title: "Beneficiary name",
            dataIndex: "beneficiaryName",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Total amount paid (Incl. fees)",
            dataIndex: "merchantPay",
            key: "total",
        },
        {
            title: "Beneficiary gets",
            dataIndex: "recipientPay",
            key: "recipientAmount",
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: string) => (
                <TransactionStatus text={text} />
            ),
        },
        {
            title: "",
            key: "options",
            render: (record: WireTableData) => (
                <DownloadButton
                    onClick={() => onShowReceipt(record)}
                >
                    <DownloadIcon title="download" />
                </DownloadButton>
            ),
        },
    ];

    const getWireTransactions = async () => {
        const payload = {
            businessId: businessId,
            pageSize: 40,
            pageNumber: 1,
        };

        try {
            setLoading(true);
            const data = await getWirePaymentsFiltered(
                payload,
            );

            const filteredData = data.map((paym, index) => {
                const merchantPay = paym.bulkMerchantPayment
                    .sourceCurrency
                    ? formatCurrencyAmount(
                          paym.bulkMerchantPayment
                              .sourceCurrency,
                          paym.bulkMerchantPayment
                              .amountReceived || 0,
                      )
                    : "--";
                const convertedAmt = paym
                    .bulkMerchantPayment.sourceCurrency
                    ? formatCurrencyAmount(
                          paym.bulkMerchantPayment
                              .sourceCurrency,
                          paym.bulkMerchantPayment
                              .sourceAmount || 0,
                      )
                    : "--";
                const recipientAmount = paym
                    .bulkMerchantPayment.destinationCurrency
                    ? formatCurrencyAmount(
                          paym.bulkMerchantPayment
                              .destinationCurrency,
                          paym.bulkMerchantPayment
                              .destinationAmount || 0,
                      )
                    : "--";
                const payStatus = paym.bulkMerchantPayment
                    .paid
                    ? paym.bulkMerchantPayment
                          .bulkMerchantRequestStatus
                    : "FAILED";
                const beneficiaryDetails =
                    paym.bulkMerchantPayment
                        .merchantBeneficiary;

                return {
                    key: index,
                    beneficiaryName: stringToSentenceCase(
                        beneficiaryDetails.beneficiaryName,
                    ),
                    email: beneficiaryDetails.email,
                    merchantPay,
                    convertedAmt,
                    recipientPay: recipientAmount,
                    status: payStatus,
                    createdAt: paym.bulkMerchantPayment
                        .createdAt
                        ? dayjs(
                              paym.bulkMerchantPayment
                                  .createdAt,
                          ).format("DD.MM.YYYY")
                        : dayjs(
                              paym.bulkMerchantPayment
                                  .updatedAt,
                          ).format("DD.MM.YYYY"),
                    rate: paym.bulkMerchantPayment.rate,
                    totalPaid:
                        paym.bulkMerchantPayment
                            .amountReceived,
                    ref: paym.bulkMerchantPayment.ref,
                    recipient: {
                        beneficiaryName:
                            beneficiaryDetails.beneficiaryName,
                        accountNumber:
                            beneficiaryDetails.accountNumber,
                        bankName:
                            beneficiaryDetails.bankName,
                        name: beneficiaryDetails.name,
                        email: beneficiaryDetails.email,
                        address: beneficiaryDetails.address,
                        country: beneficiaryDetails.country,
                    },
                };
            });

            dispatch(setWireTransactions(filteredData));
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (businessId) {
            getWireTransactions();
        }
    }, []);

    useEffect(() => {
        const filteredData = wireTransactions?.length
            ? wireTransactions.filter((item) =>
                  Object.values(item).some(
                      (value) =>
                          typeof value === "string" &&
                          value
                              .toLowerCase()
                              .includes(
                                  search.toLowerCase(),
                              ),
                  ),
              )
            : [];

        setFilteredWireTransaction(filteredData);
    }, [wireTransactions, search]);

    const onShowReceipt = (record: WireTableData) => {
        if(hasViewReceiptPermission || role === "OWNER"){
            setShowReceiptModal(true);
            setCurrentWire(record);
        }else{
            onShowErrorAlert("", "Access is denied")
        
        }
    };

    const onSendWire = () => {
        if (hasSendWirePermission || role === "OWNER") {
            if (business?.approved) {
                navigate("/klasha-wire/send");
                posthog?.capture("initiate_wire_b2b", {
                    status: "success",
                });
            } else {
                onShowErrorAlert(
                    null,
                    "Business awaiting approval",
                );
                posthog?.capture("initiate_wire_b2b", {
                    status: "failure",
                    failure_reason:
                        "Business awaiting approval",
                });
            }
        } else {
            onShowErrorAlert("", "Access is denied");
        }
    };

    return (
        <Wrapper>
            <Loader isLoading={loading} />

            <Title>
                <h1>Klasha wire</h1>
                <p>
                    Wire funds internationally from Africa
                    to pay for your goods and services.
                </p>
            </Title>

            <BoxBody>
                {wireTransactions?.length ? (
                    <TitleFilter
                        search={search}
                        setSearch={setSearch}
                        onSendWire={onSendWire}
                    />
                ) : null}

                {width > 767 ? (
                    <CustomTable
                        columns={tableColumns}
                        extraEmptyChildren={
                            <EmptyWireDescription
                                sendWire={onSendWire}
                            />
                        }
                        dataSource={filteredWireTransaction}
                    />
                ) : (
                    <MobileBody>
                        {filteredWireTransaction?.length ? (
                            filteredWireTransaction.map(
                                (lin) => (
                                    <WireCard
                                        key={lin?.key}
                                        wire={lin}
                                        onShowReceipt={
                                            onShowReceipt
                                        }
                                    />
                                ),
                            )
                        ) : (
                            <EmptyMobileCard
                                title="No wire transactions yet"
                                subText="All wire transactions done will appear here."
                                button={
                                    <Button
                                        label="Send wire"
                                        theme="secondary"
                                        width="230px"
                                        height="40px"
                                        fontSize="12px"
                                        onClick={onSendWire}
                                    />
                                }
                            />
                        )}
                    </MobileBody>
                )}
            </BoxBody>

            <CustomModal
                isModalVisible={showReceiptModal}
                onClose={() => setShowReceiptModal(false)}
                width="576px"
            >
                <Receipt
                    receiptRef={receiptRef}
                    receipt={currentWire}
                    setShowReceiptModal={handleReceipt}
                />
            </CustomModal>
        </Wrapper>
    );
};

export default KlashaWire;
