import React, { useContext, useEffect, useState } from "react";
import type { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable,
} from "firebase/storage";
import {
    Content,
    ProprietorContainer,
    Section,
    SubmitButtons,
    Wrapper,
    AddProprietor,
    ProprietorCards,
    UploadHeader,
    UploadContainer,
    UploadWrapper,
    UploadButton,
    InputContainer,
    SubHeader,
} from "./style";
import {
    addEditProprietor,
    fetchAllBusinessProprietors,
    modifyComplianceData,
} from "../../../../../pages/Compliance/complianceSlice";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import Select from "react-select";
import { Input } from "../../../../common/Input";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import Loader from "../../../../common/Loader";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { gtagEvent } from "../../../../../utils/gtag";
import ProprietorCard from "../StepThree/ProprietorCard";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus_pink.svg";
import ErrorMessage from "../../../../ErrorMessage";
import CustomDatePicker from "../../../../common/CustomDateRangePicker/CustomDatePicker";
import { Button } from "../../../../common/Button";
import { Proprietor, StepTwoFormInputs } from "../../../types";
import { countryOptions } from "../../../../../pages/KlashaWire/SendWire/components/Beneficiary/BeneficiaryModal/utils";
import firebaseApp from "../../../../../app/firebase";
import { ProgressWrapper } from "../../../../common/CustomUpload/style";
import { Progress, Tooltip } from "antd";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info2.svg";
import { validateDOB } from "../../../utils";

type ProgressStatus = "idle" | "progress" | "done";
interface Props {
    onSave: () => void;
    businessType: any
}

const StepTwo = ({ onSave, businessType }: Props) => {
    const {
        handleSubmit,
        reset,
        control,
        getValues,
        watch,
        formState: { errors },
        setValue,
    } = useForm<StepTwoFormInputs>();
    const watchAll = watch();
    const { onShowAlert } = useContext(NetworkErrorAlertContext);
    const dispatch = useAppDispatch();
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [uploadingProgress, setUploadingProgress] = useState(0);
    const [invoiceUrl, setInvoiceUrl] = useState("");
    const [uploadingStatus, setUploadingStatus] =
        useState<ProgressStatus>("idle");

    const { userId, businessId } = useAppSelector((state) => state.users);
    const {
        proprietors,
        status: complianceStatus,
        complianceData,
    } = useAppSelector((state) => state.compliance);

    const [isAdding, setIsAdding] = useState(
        !proprietors || proprietors?.length === 0
    );

    useEffect(() => {
        dispatch(fetchAllBusinessProprietors({ businessId }));
    }, []);

    const StarterBusiness = businessType === 'Starter Business';

    const onSubmitProprietor = async () => {
        const data = getValues();
        const bvnVal = !data.bvn || data.bvn?.length === 0 ? null : data.bvn;
        const payload = {
            name: data.name,
            dob: data.dob.format("DD-MM-YYYY"),
            idExpirationDate: data.idExpirationDate.format("YYYY-MM-DD"),
            bvn: bvnVal,
            nationality: data?.nationality?.code,
            documentUrl: invoiceUrl,
            userId,
            businessId,
            id: undefined,
        };

        if (selectedCardId) {
            payload.id = selectedCardId;
        }
        try {
            await dispatch(addEditProprietor(payload)).unwrap();
            setIsEditing(false);
            setIsAdding(false);
            onReset();
        } catch (e) {
            onShowAlert(e, e.message);
        }
    };

    const onAddAnotherProprietor: SubmitHandler<StepTwoFormInputs> = async () => {
        await onSubmitProprietor();
    };

    const onSubmit = async () => {
        if (proprietors.length > 0) {
            return dispatch(
                modifyComplianceData({
                    ...complianceData,
                    startupComplianceStatus: "STEP3",
                })
            ).unwrap();
        }
    };

    const onTrySubmit = async (onFinish?: () => void) => {
        try {
            await onSubmit();
            setSelectedCardId(null);
            if (onFinish) {
                onFinish();
            }
            gtagEvent({
                action: "Compliance_submit_step_2",
                category: "compliance",
                label: "Compliance submit step 2",
            });
        } catch (err) {
            onShowAlert(err, err.message);
        }
    };

    const onAddNext: SubmitHandler<StepTwoFormInputs> = async () => {
        await onSubmitProprietor();
        await onTrySubmit(onSave);
    };

    const onSaveProprietor: SubmitHandler<StepTwoFormInputs> = async () => {
        await onSubmitProprietor();
    };

    const onNext = async () => {
        await onTrySubmit(onSave);
    };

    const onReset = () => {
        reset(
            {
                name: null,
                dob: null,
                bvn: null,
                nationality: null,
            },
            {
                keepErrors: true,
                keepTouched: false,
            }
        );
        setSelectedCardId(null);
    };

    const onClickCardEditButton = (prop: Proprietor) => {
        setIsEditing(true);
        setSelectedCardId(prop.id);
        setValue("name", prop.name);
        setValue("proprietorsId", prop.name);
        setValue("nationality", prop.nationality);
        setValue("dob", dayjs(prop.dob, "DD-MM-YYYY"));
        setValue("bvn", null);
    };

    const onShowAddForm = () => {
        onReset();
        setIsAdding(true);
        setUploadingStatus("idle");
    };
    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        const minDate = dayjs().subtract(18, "year");
        
        return current && current > minDate;
      };

      const disabledID: RangePickerProps["disabledDate"] = (current) => {
        const today = dayjs();
        return current && current < today;
      };
      
      

    const uploadImage = (file) => {
        const imageId = file.uid;
        const storage = getStorage(firebaseApp);

        const storageRef = ref(
            storage,
            `compliance/documents/${businessId}/${imageId}`
        );
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                setUploadingStatus("progress");
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                const progressToTwoDec = Number(progress.toFixed(2));
                setUploadingProgress(progressToTwoDec);
            },
            (error) => {
                setUploadingStatus("idle");
            },
            () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        setInvoiceUrl(downloadURL);
                        setValue("proprietorsId", downloadURL);
                    })
                    .then(() => {
                        setUploadingStatus("done");
                    });
            }
        );
    };

    return (
        <Wrapper>
            <Loader isLoading={complianceStatus === "loading"} />

            <Content>
                <ProprietorContainer>
                    <ProprietorCards>
                        {proprietors?.map((propr) => (
                            <ProprietorCard
                                key={propr.id}
                                name={propr.name}
                                nationality={propr.nationality}
                                dob={propr.dob}
                                documentUrl={propr.documentUrl}
                                proprietorObj={propr}
                                onClickCard={onClickCardEditButton}
                            />
                        ))}
                    </ProprietorCards>
                    {!isEditing && !isAdding && (
                        <AddProprietor onClick={onShowAddForm} type="button">
                            <PlusIcon />
                            <p>Add another proprietor</p>
                        </AddProprietor>
                    )}
                </ProprietorContainer>
                {(isEditing || isAdding) && (
                    <>
                        <SubHeader disabled>
                            {
                                `${StarterBusiness ? "Please identify at least 2 business owners of your business" : "Please identify at least 2 business directors of your business"}`
                            }
                        </SubHeader>
                        <Section>
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <div>
                                        <Input
                                            type="text"
                                            label={`${StarterBusiness ? "Business owner's name" : "Director's name"}`}
                                            value={field.value}
                                            onChange={field.onChange}
                                            labelColor="#FFFFFF"
                                            height="40px"
                                            tooltipText={`${StarterBusiness ? "Write the name of your business owner as it appears on the ID." : "Write the name of your director as it appears on the ID.."}`}
                                        />
                                        {errors.name?.type === "required" && (
                                            <ErrorMessage message="Field is required" />
                                        )}
                                    </div>
                                )}
                            />
                        </Section>
                        <Section>
                            <Controller
                                name="dob"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: validateDOB
                                }}
                                render={({ field }) => (
                                    <div>
                                        <CustomDatePicker
                                            {...field}
                                            disabledDate={disabledDate}
                                            placeholder={`${StarterBusiness ? "Business owner's date of birth" : "Director’s date of birth"}`}
                                        />

                                        {errors.dob?.type === "required" && (
                                            <ErrorMessage message="Field is required" />
                                        )}
                                    </div>
                                )}
                            />
                        </Section>

                        <Section>
                            <div>
                                <Controller
                                    name="nationality"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={countryOptions}
                                            placeholder="Nationality"
                                            isSearchable
                                            filterOption={({ label }, inputValue) =>
                                                label.toLowerCase().startsWith(inputValue.toLowerCase())
                                            }
                                            theme={(theme) => ({
                                                ...theme,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: "pink",
                                                    primary: "none",
                                                },
                                            })}
                                        />
                                    )}
                                />
                                {errors.nationality && errors.nationality.type === "required" && (
                                    <ErrorMessage message="Field is required" />
                                )}

                            </div>
                        </Section>

                        {watchAll?.nationality?.value && watchAll?.nationality?.value === "NG" && (
                            <Section>
                                <Controller
                                    name="bvn"
                                    control={control}
                                    render={({ field }) => (
                                        <div>
                                            <Input
                                                type="number"
                                                label={`${StarterBusiness ? "Business owner's BVN" : "Directors’s BVN"}`}
                                                field={field}
                                                labelColor="#FFFFFF"
                                                height="40px"
                                            />
                                            {watchAll?.bvn &&
                                                watchAll?.bvn?.length !== 11 &&
                                                watchAll?.bvn?.length !== 0 && (
                                                    <ErrorMessage message="BVN must be 11 digits" />
                                                )}
                                        </div>
                                    )}
                                />
                            </Section>
                        )}
                        <Section>
                            <Controller
                                name="proprietorsId"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <>
                                        <InputContainer>
                                            <UploadContainer>
                                                <UploadHeader>
                                                    <p>{`${StarterBusiness ? "Upload business owner's ID" : "Upload director's ID"}`}</p>
                                                </UploadHeader>
                                                <UploadWrapper
                                                    name="id"
                                                    accept=".doc,.docx,image/*,.pdf"
                                                    customRequest={({ file }) => uploadImage(file)}
                                                >
                                                    <UploadButton>
                                                        {uploadingStatus === "idle" && (
                                                            <p>
                                                                <span>Click to upload</span> or drag and drop
                                                            </p>
                                                        )}
                                                        {uploadingStatus === "progress" && (
                                                            <ProgressWrapper>
                                                                <Progress
                                                                    percent={uploadingProgress}
                                                                    showInfo={false}
                                                                />
                                                                <p>{uploadingProgress}%</p>
                                                            </ProgressWrapper>
                                                        )}
                                                        {uploadingStatus === "done" && <p>Done</p>}
                                                    </UploadButton>
                                                </UploadWrapper>
                                            </UploadContainer>

                                            <Tooltip
                                                overlayClassName="input-tooltip"
                                                placement="right"
                                                title="This should be a government-issued ID such as a driver's license or international passport."
                                                trigger={["hover", "click"]}
                                            >
                                                <InfoIcon />
                                            </Tooltip>
                                        </InputContainer>
                                        {errors.proprietorsId?.type === "required" && (
                                            <ErrorMessage message="Field is required" />
                                        )}
                                    </>
                                )}
                            />
                        </Section>

                        <Section>
                            <Controller
                                name="idExpirationDate"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <div>
                                        <CustomDatePicker
                                            {...field}
                                            disabledDate={disabledID}
                                            placeholder="ID expiration date"
                                        />

                                        {errors.dob?.type === "required" && (
                                            <ErrorMessage message="Field is required" />
                                        )}
                                    </div>
                                )}
                            />
                        </Section>
                    </>
                )}
            </Content>
            {!isEditing && proprietors?.length === 0 && (
                <SubmitButtons>
                    <Button
                        label={`${StarterBusiness ? "Add another business owner" : "Add another business director"}`}
                        width="100%"
                        theme="alternate"
                        type="button"
                        height="40px"
                        onClick={handleSubmit(onAddAnotherProprietor)}
                    />
                    <Button
                        label="Continue"
                        width="100%"
                        theme="primary"
                        type="button"
                        height="40px"
                        onClick={handleSubmit(onAddNext)}
                    />
                </SubmitButtons>
            )}
            {(isEditing || isAdding && proprietors.length > 0) && (
                <SubmitButtons>
                    <Button
                        label="Save"
                        width="100%"
                        theme="primary"
                        type="button"
                        height="40px"
                        onClick={handleSubmit(onSaveProprietor)}
                    />
                </SubmitButtons>
            )}
            {!isEditing && !isAdding && proprietors?.length > 0 && (
                <SubmitButtons>
                    <Button
                        label="Continue"
                        width="100%"
                        theme="primary"
                        type="button"
                        height="40px"
                        onClick={handleSubmit(onNext)}
                    />
                </SubmitButtons>
            )}
        </Wrapper>
    );
};

export default StepTwo;
