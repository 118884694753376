import React, { useEffect, useState } from "react";
import BeneficiaryCard from "./BeneficiaryCard";
import { useToast } from "../../../../app/hooks/useToast";
import EmptyBeneficiary from "../../../../components/common/EmptyBeneficiary/EmptyBeneficiary";
import { ReactComponent as SearchIcon } from "../../assets/icons/SearchIcon.svg";
import {
    AddBeneficiary,
    GridBox,
    GridContainer,
    InputWrapper,
    Search,
} from "../../styles";
import { Button } from "../../../../components/common/Button";
import {
    useAppSelector,
} from "../../../../app/hooks";
import CustomModal from "../../../../components/common/CustomModal";
import { createUpdateBankAccount } from "../../../Settings/settingsAPI";
import Loader from "../../../../components/common/Loader";
import BeneficiaryModal from "../BeneficiaryModal";
import { getWireBeneficiaries } from "../../api";

interface Props {
    onNext: () => void;
    selectedCard: any;
    setSelectedCard: (data: any) => void;
}

const InternationalBeneficiary = ({
    onNext,
    selectedCard,
    setSelectedCard,
}: Props) => {
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const [showModal, setShowModal] = useState(false);
    const [sortedBeneficiaries, setSortedBeneficiaries] =
        useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const { userId } = useAppSelector(
        (state) => state.users,
    );
    const toast = useToast();

    const handleNext = () => {
        if (selectedCard) {
            onNext();
        } else {
            toast.notifyError("Beneficiary is required");
        }
    };

    const onCreateDefaultAccount = async () => {
        setLoading(true);
        const addPayload = {
            bankName: selectedCard?.bankName,
            accountNumber: selectedCard?.accountNumber,
            accountName: selectedCard?.beneficiaryName,
            country: selectedCard?.country,
            currency: selectedCard?.countryCode,
            owner: userId,
        };

        try {
            await createUpdateBankAccount(addPayload);
            setLoading(false);
            handleNext();
        } catch (error) {
            toast.notifyError(error.response.data.error);
            setLoading(false);
        }
    };

    const onShowModal = async () => {
        setShowModal(true);
    };
    

    const handlefetchBeneficiary = async () => {
            if (businessId) {
                setLoading(true);
                try {
                   const res =  await getWireBeneficiaries(businessId);
                   setSortedBeneficiaries(res);
                } catch (error) {
                    return error
                } finally {
                    setLoading(false);
                    setShowModal(false);
                } 
        }
    }

    const onClose = async () => {
        setShowModal(false);
        await getWireBeneficiaries(businessId)
    };


    const handleSelect = (data) => {
        setSelectedCard(
            data === selectedCard ? null : data,
        );
    };

    useEffect(() => {
    handlefetchBeneficiary()
        
    }, [businessId]);

    const  filteredBeneficiaries = sortedBeneficiaries?.filter((beneficiary) =>
        beneficiary?.beneficiaryName?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        beneficiary?.accountNumber?.includes(searchQuery)
    );

    
    

    return (
        <>
            <Search
                prefix={<SearchIcon />}
                placeholder="Search by beneficiary name or account number"
                value={searchQuery}
                onChange={(e) =>
                    setSearchQuery(e.target.value)
                }
            />
            <AddBeneficiary>
                <p>Saved beneficiary</p>
                <p
                    className="add-beneficiary"
                    onClick={onShowModal}
                >
                    Add new beneficiary
                </p>
            </AddBeneficiary>
            <br />

            <Loader isLoading={loading} />

            <GridContainer>
                <GridBox>
                    {filteredBeneficiaries?.map(
                        (beneficiary) => (
                            <BeneficiaryCard
                                key={beneficiary?.id}
                                name={
                                    beneficiary?.beneficiaryName
                                }
                                accountNumber={
                                    beneficiary?.accountNumber
                                }
                                bank={beneficiary?.bankName}
                                country={
                                    beneficiary?.country
                                }
                                isSelected={
                                    beneficiary?.accountNumber ===
                                    selectedCard?.accountNumber
                                }
                                onSelect={() =>
                                    handleSelect(
                                        beneficiary,
                                    )
                                }
                            />
                        ),
                    )}
                </GridBox>
                {!sortedBeneficiaries?.length || !filteredBeneficiaries?.length && (
                    <EmptyBeneficiary
                        title="No Beneficiary"
                        subtitle="You do not have any beneficiary yet"
                    />
                )}

            </GridContainer>
                <InputWrapper>
                    {sortedBeneficiaries?.length >= 1 && (
                        <Button
                            label="Continue"
                            height="46px"
                            onClick={onCreateDefaultAccount}
                            disabled={!filteredBeneficiaries?.length}
                        />
                    )}
                </InputWrapper>

            <CustomModal
                isModalVisible={showModal}
                onClose={onClose}
                width="464px"
            >
                <BeneficiaryModal onSaved={handlefetchBeneficiary} />
            </CustomModal>

            <Loader isLoading={loading} />
        </>
    );
};

export default InternationalBeneficiary;
