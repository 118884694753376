import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMerchant } from "../../../../../api/usersAPI";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import Loader from "../../../../../components/common/Loader";
import { ResendOTP, VerifyTeamOTP } from "../../redux/actions";
import OTP from "../../../../../components/common/OTP";
import {
    setBusinessId,
    updateUser,
} from "../../../../Login/usersSlice";
import { useUser } from "../../../../../context/UserContext";
import NetworkErrorAlert from "../../../../../components/common/alerts/NetworkErrorAlert";
import SuccessAlert from "../../../../../components/common/alerts/SuccessAlert";

interface Props {
    setData: (data) => void;
    showOTP: boolean;
    email?: string;
}

const SetOtp = ({ setData, showOTP, email }: Props) => {
    const [state, setState] = useState({
        otp: null,
        verifyOtp: null,
        resendOtp: null,
        loading: false,
        userEmail: null,
        error: false,
        message: null,
        success: false,
    });

    const { dispatch: localDispatch } = useUser();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );

    const handleDataUpdate = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleVerifyOTP = () => {
        if (state?.otp?.length < 6 || !state?.otp) {
            setState((prev) => ({
                ...prev,
                message: "The OTP code is not complete",
                error: true,
            }));
            setTimeout(() => {
                setState((prev) => ({
                    ...prev,
                    error: false,
                }));
            }, 1000);
        } else {
            verifyUserOTP(state?.otp);
        }
    };

    const verifyUserOTP = async (otp) => {
        const action = await dispatch(
            VerifyTeamOTP({ otp }),
        );
        if (VerifyTeamOTP.rejected.match(action)) {
            setState((prev) => ({
                ...prev,
                message: action.payload,
                error: true,
            }));
            setTimeout(() => {
                setState((prev) => ({
                    ...prev,
                    message: action.payload,
                    error: false,
                }));
            }, 1000);
        }
        if (VerifyTeamOTP.fulfilled.match(action)) {
            const { businessOwnerId } =
                action.payload.data.loginData;

            await getMerchant(businessOwnerId).then(
                (res: any) => {
                    const findId = res.data.find(
                        (id) => id,
                    );

                    dispatch(setBusinessId(findId?.id));
                    localDispatch({
                        type: "updateUser",
                        payload:
                            action?.payload?.data
                                ?.loginData,
                    });
                    const { accountUser } =
                        action.payload.data.loginData;

                    const userData = {
                        ...accountUser,
                        id: businessOwnerId,
                    };
                    dispatch(updateUser(userData));
                    navigate("/");
                },
            );
        }
    };

    const handleResendOTP = async () => {
        const action = await dispatch(
            ResendOTP({ email: email }),
        );

        if (ResendOTP.rejected.match(action)) {
            setState((prev) => ({
                ...prev,
                message: action.payload,
                error: true,
            }));

            setTimeout(() => {
                setState((prev) => ({
                    ...prev,
                    message: action.payload,
                    error: false,
                }));
            }, 1000);
        }

        if (ResendOTP.fulfilled.match(action)) {
            setState((prev) => ({
                ...prev,
                success: true,
            }));

            setTimeout(() => {
                setState((prev) => ({
                    ...prev,
                    success: false,
                }));
            }, 2000);
        }
    };

    const handleCloseModal = () => {
        setData((prev) => ({
            ...prev,
            showOTP: false,
        }));
    };

    return (
        <>
            {showOTP && (
                <div>
                    <Loader
                        isLoading={loading || state.loading}
                    />
                    <OTP
                        value={state.otp}
                        onChange={(data) =>
                            handleDataUpdate("otp", data)
                        }
                        onClose={handleCloseModal}
                        onClick={handleVerifyOTP}
                        buttonLabel="Continue"
                        smallText="Enter the verification code sent to your email address to verify your profile."
                        handleClick={handleResendOTP}
                    />
                </div>
            )}

            {state.error && (
                <NetworkErrorAlert
                    message={state.message}
                />
            )}

            {state?.success && (
                <SuccessAlert message="Activation OTP re-sent to your email" />
            )}
        </>
    );
};

export default SetOtp;
