import React, { useState, useEffect } from 'react'
import { DetailsWrapper, DetailsContent, Header, Box, ButtonWrapper } from './style';
import {ReactComponent as CloseIcon} from "../../../../../assets/icons/close.svg";
import {ReactComponent as CopyIcon} from "../../../../../assets/icons/copy.svg";
import {ReactComponent as CopiedIcon} from "../../../../../assets/icons/check.svg";
import { Button } from '../../../../../components/common/Button';
import { variants, transition } from "../style"
import { useAppSelector } from '../../../../../app/hooks';
import { insertSpaceToString } from '../../../utils';

const Details = ({setShowDetails}: any) => {
  const [copied, setCopied] = useState(0);
  const { card } = useAppSelector((state) => state.cards);
  const usdCard = card ? card.find((c) => c.isActive === true ) : {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(0)
    }, 2000);

    return () =>{
      clearTimeout(timer)
    }
  }, [copied]);

  const copyToClipboard = (value: string, id:number) => {
    if (value && typeof value === "string" ) {
      navigator.clipboard.writeText(value);
      setCopied(id)
    }
  };

  const copyAllDetails = () => {
    const details =
    `Card holder’s name: ${usdCard.cardName}\nCard number: ${usdCard.cardPan}\nExpiry date: ${usdCard.expiration}\nCVV: ${usdCard.cvv}\nBilling address: ${usdCard.address}\nCity/Region: ${usdCard.city}\nPostal code: ${usdCard.zipCode}\nCountry: ${usdCard.country}`;

    copyToClipboard(details, 9)
  }

  const items = [
    {
      id: 1,
      title: "Card holder’s name",
      name: usdCard.cardName || "",
      func: () => copyToClipboard(usdCard.cardName, 1),
    },
    {
      id: 2,
      title: "Card number",
      name: insertSpaceToString(usdCard.cardPan) || "",
      func: () => copyToClipboard(usdCard.cardPan, 2),
    },
    {
      id: 3,
      title: "Expiry date",
      name: (usdCard.expiration ? usdCard.expiration.replace("-", "/") : ''),
      func: () => copyToClipboard(usdCard.expiration, 3),
    },
    {
      id: 4,
      title: "CVV",
      name: usdCard.cvv || "",
      func: () => copyToClipboard(usdCard.cvv, 4),
    },
    {
      id: 5,
      title: "Billing address",
      name: usdCard.address || "",
      func: () => copyToClipboard(usdCard.address, 5),
    },
    {
      id: 6,
      title: "City/Region",
      name: usdCard.city || "",
      func: () => copyToClipboard(usdCard.city, 6),
    },
    {
      id: 7,
      title: "Postal code",
      name: usdCard.zipCode || "",
      func: () => copyToClipboard(usdCard.zipCode, 7),
    },
    {
      id: 8,
      title: "Country",
      name: usdCard.country || "",
      func: () => copyToClipboard(usdCard.country, 8),
    },
  ];


  return (
    <DetailsWrapper>
      <DetailsContent
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={transition}
      >
        <Header>
          <h1>Card details</h1>
          <CloseIcon onClick={() => setShowDetails(false)}/>
        </Header>

      {items.map((item) => (
        <Box key={item.id}>
          <div>
            <p>{item.title}</p>
            <h1>{item.name}</h1>
          </div>

          {copied === item.id ? 
            <CopiedIcon title="Copied"/> 
            : 
            <CopyIcon
            title="Copy"
            onClick={item.func}/>
          }
        </Box>
      ))}

      <ButtonWrapper>
      {copied === 9 ? 
        <Button
          label="Copied"
          theme="primary"
          height='56px'
          fontSize='16px'
        />
        :
        <Button
          label="Copy card details"
          theme="alternate"
          height='56px'
          fontSize='16px'
          onClick={copyAllDetails}
        />
      }
      </ButtonWrapper>
      </DetailsContent>
    </DetailsWrapper>
  )
}

export default Details