import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Text, Wrapper, Content, Close, SecurityText, variants, transition } from "./style";
import { ReactComponent as SuccessIcon } from "../../../../../assets/icons/success-new.svg";
import { ReactComponent as FailedIcon } from "../../../../../assets/icons/error-icon.svg";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close_icon.svg";
import { ReactComponent as Logo } from "../../../../../assets/icons/logo.svg";
import { ReactComponent as Lock } from "../../../../../assets/icons/lock.svg";
import { Portal } from "@reach/portal";

const PaymentStatus = ({
  status,
  setPaymentStatus,
  resetAll,
}: {
  status: string;
  resetAll?: () => void;
  setPaymentStatus: (arg: any) => void;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if(status === "success") {
        setPaymentStatus(null);
        resetAll();
        navigate('/klasha-wire');
      } else {
        setPaymentStatus(null);
        navigate('/klasha-wire');
      }
    }, 6000);
  
    return () => {
      clearTimeout(timer); 
    };
  }, [status, setPaymentStatus, resetAll, navigate]);
  
  
  return (
  <Portal>
    <Wrapper>
      <Content
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={transition}
      >
        <Close onClick={() => setPaymentStatus(null)} data-testid="close-btn">
          <CloseIcon/>
        </Close>

        {status === "success" && <SuccessIcon />}
        {status !== "success" && <FailedIcon />}  
        
        {status === "success" &&
          <Text>
            <h1>Your payment was successful</h1>
            <p>Redirecting you back to the initial page...</p>
          </Text>
        }

        {status === "failed" &&
          <Text>
            <h1>Your payment was unsuccessful.</h1>
            <p>Please try again...</p>
          </Text>
        }

        {status === "error" &&
          <Text>
            <h1>An unexpected error occurred.</h1>
            <p>Please try again...</p>
          </Text>
        }
      </Content>

      <SecurityText>
        <Lock />
        Secured by
        <span>
          <Logo />
        </span>
      </SecurityText>
    </Wrapper>
  </Portal>  
  );
};

export default PaymentStatus;
