import styled from "styled-components";
import { MarketingCardProps } from "../MarketingCard";

export const Wrapper = styled.div`
  display: flex;
  font-family: "Proxima Nova", sans-serif;
  /* min-width: 100%; */
  /* width: 100%; */
  flex-direction: column;
  /* margin-bottom: 40px; */
  & p {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    /* margin-right: 20px; */
    /* min-width: unset; */
    /* width: 240px; */
    /* width: 100%; */
  }
`;

export const ImageContainer = styled.div<MarketingCardProps>`
  height: 250px;
  width: 100%;
  background-color: red;
  border-radius: 4px;
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
`;

export const Image = styled.img`
  /* width: 100%; */
  margin-bottom: 24px;
  border-radius: 4px;

  @media (min-width: 768px) {
    height: 250px;
  }
`;

export const CardTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 8px;
`;

export const CardDescription = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #464646;
`;

export const ActionBtns = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & button {
    display: flex;
    align-items: center;
    background-color: unset;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #000000;
    padding: 5px 0;
    
    &:first-child {
      margin-right: 30px;
    }
    & span {
      margin-right: 4px;
    }
  }
  @media (min-width: 768px) {
    justify-content: space-between;
    & button:first-child {
      margin-right: 0;
    }
  }
`;
