import React, { useContext } from "react"
import { Button } from "../../../../components/common/Button"
import { encryptAlgorithm } from "../../../../utils/encryptionAlgorithm"
import { useDispatch } from "react-redux"
import { initiateSwapRequest  } from "../../redux/slice"
import { useAppSelector } from "../../../../app/hooks"
import { formatCurrencyAmount } from "../../../../utils/currency"
import Loader from "../../../../components/common/Loader"
import { useNavigate } from "react-router-dom"
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert"
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext"
import { clearUserData } from "../../redux/formDataSlice"
import { Heading, ReviewBox, ItemFlex, BoxTitle, EditBtn, Details, FormWrapper, InputAreaWrapper } from "./styles"



const Review = () => {
  const dispatch = useDispatch()
  const { loading } = useAppSelector((state) => state?.wallets || {});
  const { onShowAlert: onShowSuccessAlert } = useContext(SuccessAlertContext) || {};
  const { onShowAlert: onShowErrorAlert } = useContext(NetworkErrorAlertContext) || {};

  const navigate = useNavigate();

  const savedUserData = useAppSelector(
    (state) => state?.formData?.userData,
  );


  const handleRequestQuote = async () => {
    const data = {
      quoteToken: savedUserData.quoteToken,
    };
    const encryptedData = encryptAlgorithm(data, savedUserData.encryptionKey);
    const payload = { message: encryptedData };

    // @ts-ignore
    const action = await dispatch(initiateSwapRequest({ message: payload, token: savedUserData.publicKey }));
    if (initiateSwapRequest.fulfilled.match(action)) {
      onShowSuccessAlert("Currency swap successful ");
      navigate("/wallets/");
      setTimeout(() => {
        dispatch(clearUserData());
      }, 3000);
    }
    else if (initiateSwapRequest.rejected.match(action)) {
      onShowErrorAlert("", "Failed to process swap request");
    }

  };

  const merchantRate = 1 / savedUserData?.rate

  return (
    <InputAreaWrapper>
      <FormWrapper>
        <Loader isLoading={loading} />
        <Heading>Review the details of your conversion</Heading>
        <ReviewBox>

          <ItemFlex>
            <BoxTitle>Conversion details</BoxTitle>
            <EditBtn onClick={() => navigate(-1)}>Edit</EditBtn>
          </ItemFlex>
          <Details>
            <div className="text-left">
              <p>Buying</p>
              <p>Paying</p>
              <p>Exchange rate</p>
            </div>
            <div className="text-right">
              <p>{formatCurrencyAmount(savedUserData.destinationCurrency, savedUserData.destinationAmount)}</p>
              <p>{formatCurrencyAmount(savedUserData.sourceCurrency, savedUserData.convertedAmount)}</p>
              <p>{formatCurrencyAmount(savedUserData.destinationCurrency, 1)}
                <span className="m-item">=</span>
                {formatCurrencyAmount(savedUserData.sourceCurrency, merchantRate, 6)}</p>
            </div>
          </Details>

        </ReviewBox>

        <Button width="100%" height="48px" label="Continue" fontSize="12px" className="mt-5" onClick={handleRequestQuote} />
      </FormWrapper>
    </InputAreaWrapper>
  )
}

export default Review