import { countries, Country } from "countries-list";
import { sortCurrencyObjectByNameFunction } from "../../../../utils/format";

interface CountryWithAltSpellings extends Country {
  altSpellings?: string[];
}

const formatCountries = () => {
  const list = [];
  for (const [key, value] of Object.entries(countries)) {
    const country: CountryWithAltSpellings = value;
    list.push({
      value: key,
      label: value.name,
      altLabel: country.altSpellings?.join(", ") || "",
    });
  }

  return list.sort(sortCurrencyObjectByNameFunction);
};

export const countryOptions = formatCountries();