import React from "react";
import {
  Wrapper,
  Top,
  Bottom,
  BottomItem,
  BottomLabel,
  BottomValue,
} from "./style";
import TransactionStatus from "../../transactions/components/TransctionStatus";
import { formatLongString } from "../../../utils/formatString";

interface CardProps {
  transactionRef: string;
  merchantAmount: string;
  payerAmount: string;
  settlementCurrency: string;
  settlementAmount: string;
  rate: string;
  status: string;
  dateAndTime: string;
  handleCardClick: () => void
  key: string
}

const CollectionsListCard = ({
  status,
  transactionRef,
  merchantAmount,
  payerAmount,
  settlementCurrency,
  settlementAmount,
  rate,
  dateAndTime,
  handleCardClick,
  key,
}: CardProps) => {
  return (
    <Wrapper key={key} title="balance-card" onClick={handleCardClick}>
      <Bottom>
        <BottomItem>
            <BottomLabel>Transaction ref</BottomLabel>
            <BottomValue>{formatLongString(transactionRef, 15)}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Merchant amount</BottomLabel>
          <BottomValue>{merchantAmount}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Payer amount</BottomLabel>
          <BottomValue>{payerAmount}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Settlement currency</BottomLabel>
          <BottomValue>{settlementCurrency}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Settlement amount</BottomLabel>
          <BottomValue>{settlementAmount}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Rate</BottomLabel>
          <BottomValue>{rate}</BottomValue>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Status</BottomLabel>
          <TransactionStatus text={status}/>
        </BottomItem>
        <BottomItem>
          <BottomLabel>Date & Time</BottomLabel>
          <BottomValue>{dateAndTime}</BottomValue>
        </BottomItem>
      </Bottom>
    </Wrapper>
  );
};

export default CollectionsListCard;
