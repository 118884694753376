import React from "react";
import { ReactComponent as EmptyTransactions } from "../../../../../components/common/CustomTable/assets/no-transactions.svg";
import { TableWrapper, Empty } from "./style";
import { TableProps } from "../../../types";

const tableColumns = [
  {
    title: "Date - Time",
    dataIndex: "createdAt",
    key: "dateTime",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "desc",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Type",
    dataIndex: "cardTransactionType",
    key: "type",
  },
  {
    title: "Reference",
    dataIndex: "transactionReference",
    key: "ref",
  },
];

const Table = ({ setIsLoading, tableData }: TableProps) => {

  const locale = {
    emptyText: (
      <Empty>
        <EmptyTransactions />
        <h1>No transactions yet</h1>
        <p>All received or sent transactions will appear here.</p>
      </Empty>
    ),
  };

  return (
    <TableWrapper
      columns={tableColumns}
      dataSource={tableData}
      locale={locale}
    />
  );
};

export default Table;
