import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { getCustomers } from "./api";
import TableTools from "../../components/common/TableTools";
import { Heading } from "./styles";
import { exportData, tableColumns } from "./utils";
import EmptyTable from "./components/EmptyTable";
import CustomTable from "../../components/common/CustomTable";
import { StyledTransactionsTable } from "../Wallets/components/TransactionsTable/style";
import { useAppSelector } from "../../app/hooks";
import Loader from "../../components/common/Loader";
import { useWindowSize } from "@reach/window-size";
import MobileTable from "./components/MobileTable";
import { ReactComponent as EmptyCustomer } from './assets/emptyCustomer.svg';
import { selectUserRoleAndPermissions } from "../Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";

const Customers = () => {
    const navigate = useNavigate();
    const [allCustomers, setAllCustomers] = useState([]);
    const { width } = useWindowSize();
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const { businessId } = useAppSelector(
        (state) => state?.users
    );
    const { onShowAlert } = useContext(NetworkErrorAlertContext);
    const { role, permissions } = useAppSelector(selectUserRoleAndPermissions);

    const handleRowClick = (row) => {
        navigate(row?.account, {
            state: { customerDetail: row }
        });
    };

    const hasDownloadPermission = permissions?.["customers"]?.includes("EXPORT_CUSTOMER");

    const fetchCustomers = async () => {
        if (!businessId) {
            return null;
        }

        try {
            setLoading(true);
            const response = await getCustomers({ businessId });
            const responseData = response.data.virtualAccounts.map((item) => ({
                key: item.id,
                name: item.accountName,
                email: item.email || 'NA',
                phone: item.phone || 'NA',
                account: item.accountNumber,
                date: dayjs(item.createdAt).format('DD.MM.YY HH:mm')
            }));

            setAllCustomers(responseData);

        } catch (error) {
            return error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const filteredCustomers = allCustomers.filter(customer =>
        customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        customer.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const onCSVButtonClick = async () => {
        if (hasDownloadPermission || role === "OWNER") {
            await exportData(allCustomers, "csv");
        } else {
            onShowAlert("", "Access is denied");
        }
    };

    const onXLSButtonClick = async () => {
        if (hasDownloadPermission || role === "OWNER") {
            await exportData(allCustomers, "xls");
        } else {
            onShowAlert("", "Access is denied");
        }
    };

    return (
        <>
            <Heading>Customers</Heading>
            <StyledTransactionsTable>
                {allCustomers?.length > 0 && (
                    <TableTools
                        placeHolder="Search by name or email"
                        onSearchChange={(e) =>
                            setSearchQuery(e.target.value)
                        }
                        hideFilter
                        onCSVButtonClick={onCSVButtonClick}
                        onXLSButtonClick={onXLSButtonClick}
                    />
                )}

                {loading ? (
                    <Loader isLoading={loading} />
                ) : (
                    <>
                        {width > 767 ? (
                            <CustomTable
                                columns={tableColumns}
                                dataSource={filteredCustomers}
                                showEmptyImg={filteredCustomers.length === 0}
                                icon={<EmptyCustomer />}
                                handleRowClick={handleRowClick}
                                extraEmptyChildren={<EmptyTable />}
                            />
                        ) : (
                            <>
                                {filteredCustomers.map((customer) => (
                                    <MobileTable
                                        key={customer.key}
                                        fullName={customer.name}
                                        email={customer.email}
                                        phoneNumber={customer.phone}
                                        country="Nigeria"
                                        virtualAccount={customer.account}
                                        date={customer.date}
                                        onClick={() =>
                                            handleRowClick(customer)
                                        }
                                    />
                                ))}
                            </>
                        )}
                    </>
                )}
            </StyledTransactionsTable>
        </>
    );
};

export default Customers;
