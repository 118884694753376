import React from "react";
import "./style.css";

export enum Direction {
  start = "start",
  middle = "middle",
  end = "end",
}
export enum Position {
  top = "top",
  left = "left",
  bottom = "bottom",
  right = "right",
}

export interface TooltipProps {
  position: Position;
  direction: Direction;
  children: React.JSX.Element | string;
}

const Tooltip = ({
  position,
  direction,
  children,
}: TooltipProps): React.JSX.Element => {
  return (
    <div className={`${position} ${direction} tooltip-container`}>
      {children}
    </div>
  );
};

export default Tooltip;
