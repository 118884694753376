import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  backdrop-filter: blur(4px);
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 340px;
    height: 530px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0px 0px 38px rgba(45, 66, 89, 0.16);
    border-radius: 8px;
    z-index: 11;

    @media (min-width: 768px){
        width: 463px;
        height: 550px;
        padding: 24px;
    }
`;

export const ContentSection = styled.div`
    header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;
    border-bottom: 1px solid #E8E8E8;

        h1{   
            font-family: "Athletics";
            font-weight: 500;
            color: #000000;
            font-size: 20px;
            margin-bottom: 0;
            line-height: 32px;
            letter-spacing: -0.4px;
        }

        span{
            font-family: "Inter";
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-pink);
            cursor: pointer;

            &:hover{
                color: var(--alert-red);
            }
        }
    }

    main {
        margin-top: 20px;
        font-family: "Inter";

        div{
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 24px;

            p{  
                color: #767676;
                font-weight: 400;
            }

            span {
                color: #000000;
                font-weight: 500;
            }
        }
    }

    @media (min-width: 768px){
        header{
            padding-bottom: 8px;
            h1{   
                font-size: 20px;
            }

            span{
                font-size: 16px;
            }
        }
        main {
            div{
                font-size: 16px;
            }
    }
    }
`;

export const ContentButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    button {
        width: 48%
    }
`;