import { InfoWrapper, ContentBlockWrapper, ContentHeader, ContentBody } from "./style";

const pageContent = [
    {
        id: 1,
        title: 'Available balance',
        body: 'Funds available for withdrawal after rolling reserve have been deducted from your daily collections.'
    },
    {
        id: 2,
        title: 'Ledger balance',
        body: 'The total sum of the rolling reserve, available balance, and total collections for today.'
    },
    {
        id: 3,
        title: 'Total collection today',
        body: 'The amount collected from transactions processed today.'
    },
    {
        id: 4,
        title: 'Rolling reserve wallet',
        body: 'The reserve funds which are timed to be released after 90 days from the entry date.'
    },
]

export default function InfoModal() {
    return (
        <InfoWrapper>
            {pageContent.map((item) => (
                <ContentBlockWrapper key={item.id}>
                    <ContentHeader>
                        {item.title}
                    </ContentHeader>
                    <ContentBody>
                        {item.body}
                    </ContentBody>
                </ContentBlockWrapper>
            ))}
        </InfoWrapper>
    )
}