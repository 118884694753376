import styled from "styled-components";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg"

export const Heading = styled.h1`
color: #000;

/* Paragraph - Inter/P3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 114.286% */

`

export const Wrapper = styled.div`
.refund-cta{
color: var(--button-text-white, #FFF);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 15.6px; /* 130% */
padding: 12px 20px;
}

`

export const Content = styled.div`
text-align: center;
h1{
color: var(--shades-black, #000);
text-align: center;
font-family: Athletics;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.4px;
}

p{
color: var(--shades-black-500, #757575);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */

}

`

export const Btns = styled.div`
display: flex;
justify-content: space-betweem;
gap: 20px;
margin-top: 20px;

button{
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 15.6px; /* 130% */

}
`

export const InputWrapper = styled.div`
margin-top: 20px;

`

export const TableWrapper = styled.div`
margin-top: 40px;
@media (min-width: 768px) {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    overflow: hidden;
  }

`


export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const EmptyWrapperTitle = styled.h1`
  font-family: "Athletics";
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; 
  letter-spacing: -0.48px;
  color: #000000;
  margin: 0;
`;

export const EmptyWrapperSubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
  color: #8F8F8F;
  margin-bottom: 24px;
`;

export const TransactionWrapper = styled.div`
border-radius: 8px;
background: #F9F9F9;
padding: 24px 16px;
margin-top: 20px;
`

export const TransactionAmount = styled.h1`
color: #000;
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.48px;

`

export const CheckFlex = styled.div`
display: flex;
align-items: center;
gap: 4px;


`

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center
`;

export const Paragraph = styled.p`
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
color: #6B6B6B;
padding-top: 15px;
`;

export const CopyIcon = styled(Copy)`
margin-bottom: 10px;
width:15px;

`;

export const Check = styled(CheckIcon)`
margin-bottom: 10px;
width:15px;

`;

export const Title = styled.h1`
color: #000;
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
letter-spacing: -0.48px;

`

export const PageTitle = styled.h1`
color: var(--shades-black, #000);
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
letter-spacing: -0.48px;


`