import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Options = styled.div``;

export const InputBox = styled.div`
  margin-bottom: 32px;
`;

export const DualInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  & > * {
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    margin-bottom: 16px;
    & > * {
      margin-bottom: 0;
    }
    & > *:first-child {
      margin-right: 24px;
    }
  }
`;

export const Error = styled.span`
    color: red;
`;