import React, { useState } from "react";
import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import { ButtonTitle, Check, CheckFlex, CopyIcon, DetailTitle, Flex, Heading, Paragraph, TransactionAmount, TransactionWrapper } from "./style";
import { Button } from "../../components/common/Button";

interface Props {
    reference: string;
    amount?: string;
    source?: string;
    customerName?: string;
    customerEmail?: string;
    method?: string;
    rate?: string;
    date: string;
    merchant_amount?:string
    transaction_id?:string
    status?:string
    transactionRef?:string;
    onRefundRequest?: (data) => void;
}

const Details = ({
    reference,
    amount,
    source,
    customerName,
    customerEmail,
    method,
    date,
    merchant_amount,
    transactionRef,
    status,
    onRefundRequest,
}: Props) => {
    const [copy, setCopy] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

    
    return (
        <>
        <DetailTitle>{amount}</DetailTitle>
        <TransactionWrapper>

        <Flex>
                <Paragraph>Status</Paragraph>
                <TransactionStatus text={status} />
            </Flex>
            <TransactionAmount>{merchant_amount}</TransactionAmount>
            <Flex>
                <Paragraph>Transaction ID</Paragraph>
                <CheckFlex>
                    {copy ? <Check /> : <CopyIcon onClick={() => onCopyLinkToClipboard(transactionRef)} data-testid="copy-icon" />}

                    <Heading>{reference}</Heading>
                </CheckFlex>

            </Flex>

            <Flex>
                <Paragraph>Request date</Paragraph>
                <Heading>{date}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Source</Paragraph>
                <Heading>{source}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Transaction amount</Paragraph>
                <Heading>{amount}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Method</Paragraph>
                <Heading>{method}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Customer name</Paragraph>
                <Heading>{customerName}</Heading>
            </Flex>

            <Flex>
                <Paragraph>Customer email</Paragraph>
                <Heading>{customerEmail}</Heading>
            </Flex>


           
        </TransactionWrapper>
        <ButtonTitle>
        <Button label="Refund payment"  theme="alternate" width="224px" height="36px" onClick={onRefundRequest}/>
        </ButtonTitle>
        </>
    );
};

export default Details;
