import React from "react";
import { Portal } from "@reach/portal";
import { ReactComponent as SmallLogo } from "../../../assets/icons/logo-k-pink.svg";
import { LoaderContainer, SpinDiv, SpinLogo, Spinner, Wrapper } from "./style";

const Loader = ({
  isLoading = false,
  isComponentType = false,
  isNotFull = false,
}: {
  isLoading: boolean;
  isComponentType?: boolean;
  isNotFull?: boolean;
}) => {
  if (!isLoading) {
    return null;
  }
  if (isComponentType) {
    return (
      <Wrapper $isComponentType={isComponentType}>
        <LoaderContainer>
          <SpinDiv>
            <SpinLogo>
              <SmallLogo />
            </SpinLogo>
            <Spinner $isComponentType={isComponentType} />
          </SpinDiv>
        </LoaderContainer>
      </Wrapper>
    );
  }
  return (
    <Portal>
      <Wrapper $isComponentType={isComponentType} data-testid="loader">
        <LoaderContainer>
          <SpinDiv $isNotFull={isNotFull}>
            <SpinLogo>
              <SmallLogo />
            </SpinLogo>
            <Spinner $isComponentType={isComponentType} />
          </SpinDiv>
        </LoaderContainer>
      </Wrapper>
    </Portal>
  );
};

export default Loader;
