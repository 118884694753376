import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Inter";

  @media (min-width: 768px) {
    padding: 0 15%;
    /* display: block; */
  }
`;

export const BodyDiv = styled.div`
  flex: 1;
  width: min(100%, 492px);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 768px) {
    flex: unset;
  }
`;

export const HeaderTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #191919;
  margin: 60px 0 8px;
  @media (min-width: 768px) {
    margin: 40px 0 8px;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
  }
`;

export const SubtitleText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #767676;
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    & button {
      width: 375px;
    }
  }
`;
