
export const smallMobile = '(max-width: 375px)';

export const mobile = '(max-width: 576px)';

export const smallTab = '(max-width: 768px)';

export const tab = '(max-width: 992px)';

export const desktop = '(max-width: 1200px)';

export const mediumDesktop = '(max-width: 1440px)';

export const largeDesktop = '(max-width: 1920px)';

export const largerThanDesktop = '(min-width: 1920px)';

export const largerThanMediumDesktop = '(min-width: 1440px)'

export const maxWidth = '1920px';