import React from "react";
import {
  Wrapper,
  Top,
  TopLeft,
  TopRight,
  Bottom,
  BottomLeft,
  BottomRight,
} from "./style";
import { ReactComponent as EditButton } from "../../../../../assets/icons/edit.svg";
import { AccountCardProps } from "../types";

const AccountCard = ({
  currency,
  accName,
  bankName,
  accNum,
  country,
  setSelectedAccount,
  account,
}: AccountCardProps) => {
  const onClick = () => {
    setSelectedAccount(account);
  };
  return (
    <Wrapper title="account-card">
      <Top>
        <TopLeft>
          <p>{currency}</p>
          <p>{accNum}</p>
          <p>{bankName}</p>
        </TopLeft>
        <TopRight>
          <button onClick={onClick}>
            <span>Edit</span>
            <EditButton />
          </button>
        </TopRight>
      </Top>
      <Bottom>
        <BottomLeft>
          <p>ACCOUNT NAME</p>
          <p>{accName}</p>
        </BottomLeft>
        <BottomRight>
          <p>COUNTRY</p>
          <p>{country}</p>
        </BottomRight>
      </Bottom>
    </Wrapper>
  );
};

export default AccountCard;
