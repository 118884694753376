import React from "react";
import dayjs from "dayjs";
import { Button } from "../../../../../../components/common/Button";
import { Input } from "../../../../../../components/common/Input";
import { Controller, useForm } from "react-hook-form";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import { FormWrapper } from "../../style";
import CustomDatePicker from "../../../../../../components/common/CustomDateRangePicker/CustomDatePicker";
import { addBeneficiary, updateFormData } from "../../../../redux/formDataSlice";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import useCountryOptions from "../../../../../../app/hooks/useCountryList";

interface Props {
    handleNext: () => void;
}

interface formData {
    directorName: string;
    directorDob: string;
    directorCountry: string;
}


const Director = ({ handleNext }: Props) => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<formData>() || {};
    const dispatch = useAppDispatch();
    const formData = useAppSelector(
        (state) => state.formData,
    );
    const countryIncorporation = useCountryOptions();
    const formatAndDispatch = (data: formData) => {
        const formattedDate = dayjs(
            data.directorDob,
        );
        const formattedDateString =
            formattedDate.format("YYYY-MM-DD");
        data.directorDob = formattedDateString;
        dispatch(updateFormData(data));
        // dispatch(addBeneficiary(data));
        console.log("Submitted data:", data);
    };

    const onSubmit = (data: formData) => {
        const updatedData = {
            ...data,
            foreignBankAccountOwnershipType: "DIRECTOR",
        };
        formatAndDispatch(updatedData)
        handleNext();
    };

    React.useEffect(() => {
        setValue("directorName", formData?.directorName);
        setValue("directorCountry", formData?.directorCountry);
    }, [formData, setValue]);

    return (
        <FormWrapper>
            <div></div>
            <div>
                <Controller
                    name="directorName"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Full name"
                            value={field.value}
                            onChange={field.onChange}
                            hasError={
                                errors?.directorName?.type ===
                                "required"
                            }
                        />
                    )}
                />
                {errors?.directorName?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>
            <div>
                <Controller
                    name="directorDob"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <CustomDatePicker
                            {...field}
                            placeholder="Date joined as director"
                        />
                    )}
                />
                {errors?.directorDob?.type ===
                    "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="directorCountry"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <CustomSelect
                            placeholder="Residence country"
                            options={countryIncorporation}
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                            $width="100%"
                            field={field}
                        />
                    )}
                />
                {errors?.directorCountry?.type === "required" && (
                    <span className="error">
                        Field is required
                    </span>
                )}
            </div>

            <Button
                label="Continue"
                height="40px"
                onClick={handleSubmit(onSubmit)}
            />
        </FormWrapper>
    );
};

export default Director;
