import styled from 'styled-components'

export const Wrapper = styled.div`
    margin-top: 14px;
`

export const HeaderWrapper = styled.div`
    padding-bottom: 10px;
`

export const ModalTitle = styled.h2`
    font-family: Athletics;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
`

export const ModalSubtitle = styled.p`
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
`

export const BodyWrapper = styled.div`
    padding: 24px;
    border: 1px solid #F5F5F5;
    border-left: 0;
    border-right: 0;
    display: flex;
    flex-direction: column;
    gap: 24px;

    section {

    }

    h2 {
        font-family: Inter;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000
        margin-bottom: 12px
    }
`

export const ButtonContainer = styled.div`
    padding: 24px 24px 0 24px;
`