import React from "react";
import { BodyDiv, HeaderTitle, SubtitleText, Wrapper } from "./style";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";

const PasswordCreated = () => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate("/login");
  };
  return (
    <Wrapper>
      <BodyDiv>
        <HeaderTitle>Password created</HeaderTitle>
        <SubtitleText>You’ve successfully changed your password</SubtitleText>
      </BodyDiv>

      <Button
        label="Continue to login"
        type="button"
        theme="primary"
        onClick={onLoginClick}
        height="40px"
        width="193px"
        fontSize="12px"
      />
    </Wrapper>
  );
};

export default PasswordCreated;
