import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { addUpdateMerchantBusiness } from "../Settings/settingsSlice";

export interface UserState {
  token: string;
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: boolean;
  error: string | null;
  businessId: number | null;
  userId: string | null;
  userObj: any;
  loginEmail: string;
  loginPassword: string;
}

const initialState: UserState = {
  token: "",
  status: "idle",
  isLoading: false,
  error: null,
  businessId: null,
  userId: null,
  userObj: {},
  loginEmail: '',
  loginPassword: ''
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
      updateUser: (state, action) => {
        state.userObj = action.payload;
        const userId = action.payload ? action.payload?.id : null;
        state.userId = userId;
      },
      updateBusinessId: (state, action) => {
        state.businessId = action.payload;
      },
      updateLoginDetails: (state, action) => {
        state.loginEmail = action.payload.email;
        state.loginPassword = action.payload.password;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(addUpdateMerchantBusiness.fulfilled, (state, action) => {
        state.status = "idle";

        if (action.payload?.id) {
          const busId = action.payload?.id;
          state.businessId = busId;
        }
      });
    },

});

export const { updateUser, updateBusinessId, updateLoginDetails } = usersSlice.actions;

export const setBusinessId =
  (busId: number): AppThunk =>
  (dispatch, getState) => {
    dispatch(updateBusinessId(busId));
  };

  

export default usersSlice.reducer;
