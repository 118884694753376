import styled from "styled-components";
import { motion } from "framer-motion";

export const Card = styled.section`
  width: 100%;
  overflow-y: auto;
`;

export const TopCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
`;

export const ImageBox = styled.div`
  width: 316px;
  height: 186.5px;
  color: #FFFFFF;
  box-shadow: 0px 6px 40px 2px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #000000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Logo = styled.div`
  p{
    margin: 3px 0 0 0;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px; 
    text-align: center;
  }

  img {
    width: 109.7px;
    height: 33.5px;
    object-fit: cover;
  }
`;

export const Visa = styled.img`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 46px;
  height: 14.2px;
  object-fit: cover;
`;

export const Lines = styled.img`
  position: absolute;
  right: 0;
  width: 75%;
  height: 100%;
`;

export const TextBox = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    color: #000000;
    font-family: "Athletics";
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin-bottom: 6px;
  }

  span {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px; 
    color: #767676;
    margin-bottom: 30px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 46px;
      line-height: 60px;
      letter-spacing: -0.92px;
    }

    span {
      font-size: 16px;
      line-height: 24px;
      width: 620px;
    }
  }
`;

export const BottomCard = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 23px;
  padding: 0;
  margin-top: 48px;
  
  @media (min-width: 768px) {
    justify-content: center;
  }
`;

export const ListItems = styled.li`
  display: flex;
  gap: 12px;
  font-family: "Inter";

  img {
    height: 32px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    margin-bottom: 6px;
    color: #000000;
  }

  span {
    margin-bottom: 0;
    color: #767676;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; 
  }

  @media (min-width: 768px) {
    width: 344px;
  }

  @media (min-width: 992px) {
    width: 432px;
  }
`;

export const ReadyWrapper = styled(motion.div)`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20%;
  gap: 70px;

  @media (min-width: 768px) {
    max-width: 610px;
    gap: 90px;
    padding-top: 7%;
  }
`;

export const CardImage = styled.div`
  width: 316px;
  height: 186.5px;
  position: relative;
`;

export const ShadowImage = styled.div`
  width: 316px;
  height: 186.5px;
  position: absolute;
  bottom: -6px;
  left: -7px;
  background-color: var(--primary-pink);
  border-radius: 10px;
  box-shadow: 0px 6px 40px 2px rgba(0, 0, 0, 0.08);
`;

export const ReadyText = styled.div`
  text-align: center;

  h1 {
    font-family: "Athletics";
    font-size: 28px;
    font-weight: 600;
    line-height: 34px; 
    letter-spacing: -0.92px;
    color: #000000;
    margin: 0;
  }

  p {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    color: #767676;
    margin: 10px 0;
  }

  button {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 46px;
      line-height: 60px;
    }
    
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const WalletWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-left: 0;
  align-items: center;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  backdrop-filter: blur(2px);
`;

export const Content = styled(motion.div)`
  width: 343px;
  height: 350px;
  padding: 12px 24px 24px 24px;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 0px 38px 0px rgba(45, 66, 89, 0.16);
  position: relative;

  span {
    position: absolute;
    top: 4%;
    right: 4%;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 480px;
    height: 376px;
  }
`;

export const NoWalletBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-top: 20px;

    img {
      height: 100px;
    }

    @media (min-width: 768px) {
      gap: 20px;
      margin-top: 35px;

      img {
        height: 120px;
      }
    }
`;

export const NoWalletText = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 10px;
  
  h3 {
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.48px;
    color: #000000;
  }
  
  p {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    color: #767676;
  }

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

