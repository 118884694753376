import styled from "styled-components";
import { Dropdown } from "antd";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import InputNumber from "antd/lib/input-number";

export const Title = styled.h1`
    font-family: "Athletics";
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.48px;

    @media (min-width: 768px) {
        font-size: 24px;
    }
`;

export const CurrencyBalance = styled.h2`
    font-family: "Athletics";
    font-weight: 500;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.92px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -2.4px;
    }
`;

export const ButtonsDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;

    button {
        width: 50%;
    }

    @media (min-width: 768px) {
        button {
            width: 120px;
        }
    }

     .account-fund-btn{
        width: 150px;
    }

    @media(max-width: 768px) {
        button  {
            flex: 1
        }
          
        .account-fund-btn {
            width: 100%;
        }
    }

    .flex{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 7px;
    }
`;

export const AddFundsHeader = styled.h3`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.02em;
    margin-left: 15px;

    color: #000000;
`;

export const Icon = styled(CloseIcon)`
    float: right;
    cursor: pointer;
    margin-right: 60px;
`;

export const Modal = styled.div`
    position: fixed;
    top: 10;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    height: "100vh";
    background-color: "#FFFFFF";
    padding-top: "50px";
    font-family: "Proxima Nova", sans-serif;
`;

export const ErrorWrapper = styled.div`
    position: relative;
    bottom: 12px;
`;

export const InputContainer = styled(InputNumber)`
    border-radius: 8px;
    height: 40px;
    .ant-input-number-group .ant-input-number {
        padding: 5px;
    }

    .ant-input-number-group-addon {
    }
`;

export const SendPaymentWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    margin-left: -8px;

    button {
        position: relative;
        left: 12px;
        width: 394px;
    }
`;

export const Heading = styled.h3`
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.48px;
`;
export const DropDown = styled(Dropdown)``;

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 10px;
    gap: 4px;
`;

export const BassWrapper = styled.div`
    margin-top: 20px;
`;
export const FormWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    gap: 20px;
    overflow-x: auto;
`;

export const Question = styled.div`
    & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
        letter-spacing: -0.03em;
        color: #000000;
        margin-bottom: 24px;
    }
    @media (min-width: 768px) {
    }
`;

export const YesNoDiv = styled.div`
    margin-bottom: 24px;

    & > * {
        display: flex;
        & > label {
            margin-right: 24px;
            width: 100px !important;
            height: 40px !important;
            display: flex;
            align-items: center;
        }
    }
`;

export const PaymentDetailsBox = styled.div`
    gap: 0px;
    border-radius: 8px;
    padding: 10px 16px;
    justify: space-between;
    opacity: 0px;
    border: 1px solid #f9f9f9;
    background: #f9f9f9;
    h1 {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
    }
    p {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        text-align: left;
    }
    .item-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const AccountDetailsWrapper = styled.div`
    button {
        width: 100%;
    }
        
    @media (max-width: 768px) {
        display: inline-block;
        flex-basis: 100%;
    }
`
