import { createSlice } from "@reduxjs/toolkit";

interface WireState {
  wireTransactions: {}[],
  beneficiaries: {}[],
}

const initialState: WireState = {
  wireTransactions: [],
  beneficiaries: [],
};

export const wireSlice = createSlice({
  name: "wires",
  initialState,

  reducers: {
        setWireTransactions: (state, action) => {
          state.wireTransactions = action.payload;
        },
        setBeneficiaries: (state, action) => {
          state.beneficiaries = action.payload;
        },
    }
});

export const { setWireTransactions, setBeneficiaries } = wireSlice.actions;

export default wireSlice.reducer;

