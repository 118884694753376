import {
    createSlice,
} from "@reduxjs/toolkit";
import { CreateWhiteList, GetAllWhiteList } from "./actions";

const initialState = {
    loading: false,
    error: null,
    success: false,
    errormsg: null,
    data: {},
};

const whitelistSlice = createSlice({
    name: "ipAddressWhiteList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(CreateWhiteList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(CreateWhiteList.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                CreateWhiteList.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                },
            ) .addCase(GetAllWhiteList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetAllWhiteList.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                GetAllWhiteList.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                },
            );
    },
});

export default whitelistSlice.reducer;
