import React, { useContext } from "react";
import { saveAs } from "file-saver";
import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { ReactComponent as DownloadIcon } from "../assets/arrow-down.svg";
import {
  CardDescription,
  CardTitle,
  Image,
  Wrapper,
  ActionBtns
} from "./style";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";

export interface MarketingCardProps {
  url?: string;
  title?: string;
  description?: string;
  downloadLink?: string;
}
const MarketingCard = ({
  url,
  title,
  description,
  downloadLink,
}: MarketingCardProps) => {
  const { onShowAlert } = useContext(SuccessAlertContext);
  const onDownloadClick = () => {
    saveAs(downloadLink, `${title}.png`);
  };

  const onCopyLinkToClipboard = () => {
    navigator.clipboard.writeText(`${title}. ${description}`);
    onShowAlert("The text is copied.");
  };

  return (
    <Wrapper>
      <Image src={url} alt={title} />
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
      <ActionBtns>
        <button type="button" onClick={onCopyLinkToClipboard}>
          <span>Copy text</span>
          <CopyIcon />
        </button>
        <button type="button" onClick={onDownloadClick}>
          <span>Download image </span>
          <DownloadIcon />
        </button>
      </ActionBtns>
    </Wrapper>
  );
};

export default MarketingCard;
