import React from 'react'
import { useNavigate } from 'react-router-dom';
import { WalletWrapper, Content, NoWalletBox, NoWalletText } from '../style';
import NoCardIcon from '../assets/no-cards.svg';
import {ReactComponent as CloseIcon} from '../../././../components/common/OTP/assets/close.svg';
import { Button } from '../../../components/common/Button';
import { itemTransition, itemVariant } from '../utils';

type Props = {
  setCreateWallet: (arg: boolean) => void;
}

const CreateWallet = ({setCreateWallet}: Props) => {
  const navigate = useNavigate();

  const handleCreateWallet = () => {
    navigate('/wallets')
    setCreateWallet(false)
  };

  return (
    <WalletWrapper data-testid="create-wallet">
      <Content 
        initial="hidden"
        animate="visible"
        variants={itemVariant}
        transition={itemTransition}
      >
        <span onClick={() =>setCreateWallet(false)} data-testid="close">
          <CloseIcon/>
        </span>

        <NoWalletBox>
          <img src={NoCardIcon} alt='logo'/>

          <NoWalletText>
            <h3>Oops! No business wallet yet</h3>
            <p>Looks like you have not created any business wallet yet.</p>
          </NoWalletText>

          <Button 
             label="Create a wallet now"
             theme="secondary"
             height="48px"
             fontSize="14px"
             onClick={handleCreateWallet}
          />
        </NoWalletBox>
      </Content>
    </WalletWrapper>
  )
}

export default CreateWallet