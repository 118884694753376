import { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function useToast() {
  useEffect(() => {
    return () => {
      toast.dismiss();
    };
  }, []);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'toast-success',
      bodyClassName: 'toast-success-body',
      progressClassName: 'toast-success-progress',
      theme: "colored"
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      className: 'toast-error',
      bodyClassName: 'toast-error-body',
      progressClassName: 'toast-error-progress',
      theme: "colored"
    });
  };

  return { notifySuccess, notifyError };
}

