import React, { useState, useContext } from "react";
import { Wrapper, Title } from "./style";
import CardDetails from "./CardDetails";
import CardTransactionsTable from "./CardTransationsTable";
import Details from "./CardDetails/Details";
import Loader from "../../../../components/common/Loader";
import NetworkErrorAlert from "../../../../components/common/alerts/NetworkErrorAlert";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import SuccessAlert from "../../../../components/common/alerts/SuccessAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import BlockCard from "./BlockCard";
import UnBlockCard from "./UnBlockCard";

const VirtualCard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [blockCard, setBlockCard] = useState(false);
  const [unBlockCard, setUnBlockCard] = useState(false);

  const { showAlert: errorAlert, alertMessage } = useContext(
    NetworkErrorAlertContext,
  );
  const { showAlert: successAlert, alertMessage: successMessage } =
    useContext(SuccessAlertContext);

  return (
    <Wrapper>
      <Loader isLoading={isLoading} />
      {errorAlert && <NetworkErrorAlert message={alertMessage} />}
      {successAlert && <SuccessAlert message={successMessage} />}

      <Title>
        Card
      </Title>

      <CardDetails
        setBlockCard={setBlockCard}
        setUnBlockCard={setUnBlockCard}
        setShowDetails={setShowDetails}
      />
      <CardTransactionsTable setIsLoading={setIsLoading} />

      {showDetails && <Details setShowDetails={setShowDetails}/>}
      {blockCard && <BlockCard setBlockCard={setBlockCard} />}
      {unBlockCard && <UnBlockCard setUnBlockCard={setUnBlockCard} />}
    </Wrapper>
  );
};

export default VirtualCard;
