import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
  
  @media (min-width: 768px) {
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    button {
      width: 200px;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    button {
      width: 156px;
    }
  }
`;

export const JoinWhatsappLink = styled.a`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid  #000000;
  background: #ffffff;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.6px;

  &:hover {
    color: #000000;
  }

  @media (min-width: 768px) {
    width: 224px;
  }
`;
