import React from "react";
import { Wrapper } from "./style";
import "./style.css";
import { ReactComponent as CalendarIcon } from "./assets/calendar.svg";
import { ReactComponent as PrevIcon } from "./assets/arrow left.svg";
import { ReactComponent as NextIcon } from "./assets/arrow-right.svg";

interface InputProps {
    value?: any;
    onChange?: any;
    disabledDate?: any;
    open?: boolean
}

const CustomDateRangePicker = ({ ...props }: InputProps) => {
    return (
        <Wrapper
            {...props}
            suffixIcon={<CalendarIcon />}
            dropdownClassName="picker-container"
            prevIcon={<PrevIcon />}
            nextIcon={<NextIcon />}
            superPrevIcon={null}
            superNextIcon={null}
        />
    );
};

export default CustomDateRangePicker;
