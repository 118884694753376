import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../../app/api";

interface CreateWhiteListInput {
  businessId: string;
  ipAddress?: string;
}

interface CreateWhiteListResponse {
  message: string;
  data: any;
}

interface ErrorResponse {
  message: string;
}

export interface formData {
    ipAddress: any;
    
}

export const CreateWhiteList = createAsyncThunk<
  CreateWhiteListResponse,
  CreateWhiteListInput,
  {
    rejectValue: ErrorResponse;
  }
>(
  "Ipwhitelist/save",
  async ({ businessId, ipAddress }, { rejectWithValue }) => {
    try {
      const response = await api.post<CreateWhiteListResponse>(
        `/api/whitelist/add`,
        { businessId, ipAddress }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || error.message
      );
    }
  }
);

export const GetAllWhiteList = createAsyncThunk(
    "IpWhitelist/get",
    async ({ businessId }: CreateWhiteListInput, { rejectWithValue }) => {
        try {
            const response = await api.get(
                `/api/whitelist/business/${businessId}`,
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                error.response.data.message,
            );
        }
    },
);
