import styled, { css } from "styled-components";

export const LinkStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 98px;
  border-radius: 29px;
  font-size: 14px;
  font-Weight: 400;
  line-height: 16px;

  ${(props: { $type: string }) => {
    if (props.$type === "onetime") {
      return css`
        background: #C1D6FF;
        & span {
          color: #0A44C3;
        }
      `;
    } else if (props.$type === "recurring") {
      return css`
        background: #B0FBD5;
        & span {
          color: #004700;
          text-transform: capitalize;
        }
      `;
    } 
    else {
        return css`
        background-color: #e42c5a3a;
      & > span {
        color: #e01b4c;
        text-transform: capitalize;
      }
    `;
    }
  }}
`;