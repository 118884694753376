import styled from "styled-components";
import { Drawer } from "antd";

export const Wrapper = styled(Drawer)`
    .ant-drawer-header {
        border-bottom: none;
        padding-top: 54px;
    }

    .ant-drawer-header-title {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
    }

    .ant-drawer-close {
        order: 1;
    }
`;
