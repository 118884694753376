import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button } from '../../../../common/Button';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { CreateBusinessLogo, GetMerchantBusinessLogo, UpdateMerchantBusinessLogo } from '../../../../../pages/Settings/settingsAPI';
import { useAppSelector } from '../../../../../app/hooks';
import Loader from '../../../../common/Loader';
import { NetworkErrorAlertContext } from '../../../../../context/NetworkErrorAlert';
import { Initials, Paragraph, Wrapper, Image } from './styles';


const LogoInputComponent = () => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [logoId, setLogoId] = useState(null)
  const { businessId } = useAppSelector((state) => state.users);
  const { business } = useAppSelector((state) => state.settings);
const {  name: businessName } = business;
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileType = selectedFile.type;
      const fileSize = selectedFile.size;

      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        console.error("Only JPEG and PNG files are allowed.");
        return;
      }

      if (fileSize > 5 * 1024 * 1024) {
        console.error("File size must be less than 5MB.");
        return;
      }

      uploadFileToFirebase(selectedFile);
    }
  };

  const uploadFileToFirebase = (file: File) => {
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    setUploading(true);
  
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      }, 
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      }, 
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setLogoUrl(downloadURL);
          setUploading(false);
          if (logoUrl && downloadURL) {
            UpdateBusinessLogo(downloadURL);
          } else {
            CreateNewBusinessLogo(downloadURL);
          }
        }).catch((error) => {
          console.error("Error getting download URL:", error);
          setUploading(false);
        });
      }
    );
  };

  const extractInitials = (name) => {
    if (!name) return '';

    const words = name.split(' ');

    const initials = words.map(word => word.charAt(0).toUpperCase()).join('');

    return initials;
  };

  const initials = extractInitials(businessName);
  

  const generateInitials = () => {
    return initials;
  };

  const renderInitials = () => {
    const initials = generateInitials();
    return <Initials>{initials}</Initials>;
  };

  const CreateNewBusinessLogo = async (logoUrl) => {
    const data = {
      businessId: businessId,
      customizationPage: "EMAIL",
      businessLogo: logoUrl
    };
    setUploading(true);
    try {
      await CreateBusinessLogo(data);
      setUploading(false);
      await fetchBusinessLogo()
    } catch (error) {
        const { message } = error.response.data
        onShowErrorAlert("error", message)
    }
  };

  const UpdateBusinessLogo = async (logoUrl: string) => {
    const data = {
      businessId: businessId,
      customizationPage: "EMAIL",
      businessLogo: logoUrl,
      id: logoId
    };
    try {
      await UpdateMerchantBusinessLogo(data);
      await fetchBusinessLogo()
    } catch (error) {
        const { message } = error.response.data
        onShowErrorAlert("error", message)    }
  };

  const fetchBusinessLogo = async () => {
    try {
      const logoResponse = await GetMerchantBusinessLogo(businessId);      
      if (logoResponse && logoResponse.data && logoResponse.data.businessLogo) {
        setLogoUrl(logoResponse.data.businessLogo);
        setLogoId(logoResponse.data?.id)
      }
    } catch (error) {
      console.error('Error fetching business logo:', error);
    }
  };


    useEffect(() => {
    
    fetchBusinessLogo();
  }, []);
  


  return (
    <>
      <Wrapper>
        <Loader isLoading={uploading}/>
        {logoUrl ? (
          <Image src={logoUrl} alt="Logo" className="logo-image" />
        ) : (
          renderInitials()
        )}
        <input
          id="logoInput"
          type="file"
          accept=".png, .jpg, .jpeg"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button onClick={() => fileInputRef.current?.click()} theme="alternate" height="36px" width="136px" fontSize="12px">
          {uploading ? "Uploading..." : "Upload Logo"}
        </Button>
      </Wrapper>
      <Paragraph>Suggested dimensions: 1516 x 416 px
      </Paragraph>
      <Paragraph>Your business logo will be included on all transaction receipts issued to your customers.</Paragraph>

    </>
  );
};

export default LogoInputComponent;
