import styled from "styled-components";
import { motion } from "framer-motion";
import { Input } from "antd";

export const Wrapper = styled(motion.div)`
    font-family: "Inter";
    position: fixed;
    inset: 0;
    width: 100%;
    z-index: 10;
    padding: 30px 30px;
    background-color: #FFFFFF;
    overflow-y: auto;
    
    @media (min-width: 786px) {
        padding: 70px 0 30px 0;
    }
`;

export const Back = styled.span`
    position: absolute;
    top: 70px;
    left: 20%;
    cursor: pointer;
`;

export const ContentWrapper = styled.div`
    padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .button {
        margin-top: 30px;
    }

    @media (min-width: 768px) {
        max-width: 440px;
        margin: 0 auto;
    }
    @media (min-width: 1024px) {
        max-width: 540px;
    }
`;

export const Header = styled.header`
    margin-bottom: 20px;

    h1 {
        font-family: "Athletics";
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: -0.56px;
        color: #000000;
        margin: 0;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: #767676;
        margin: 8px auto 0 auto;

        a {
            color: var(--primary-pink);
            text-decoration: underline;
        }
    }

    @media (min-width: 768px) {
     p {
        max-width: 80%;
     }   
    }
`;

export const FeeAlert = styled.div`
    text-align: left;
    margin-top: 4px;
    font-size: 10px;
    color: #484848;
    line-height: 15.6px;
`;

export const RateBox = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #F9F9F9;
    
    span {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }
`;

export const Beneficiaries = styled.div`
    width: 100%;
    height: 280px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto;
`;


export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E9E9E9;
     p {
        color: #000000;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; 
        margin: 0;
     }

     span {
        font-size: 12px;
        color: var(--primary-pink);
        font-weight: 600;
        line-height: 15.6px; 
        cursor: pointer;
     }
`;

export const TextArea = styled(Input.TextArea)`
  margin: 15px 0 45px 0;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  color: #000000;

  &:hover {
    border: 1px solid #a3a3a3;
  }

  &:focus {
    border: 1px solid #000000;
    box-shadow: none;
  }
`;

export const NotFound = styled.div`
 width: 100%;
 margin-top: 20px;

 p {
    font-weight: 500;
    line-height: 16px;
    color: #000000;
    font-size: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
    text-align: left;
    margin-bottom: 16px;
 }
`;

export const Box = styled.div`
    width: 100%;
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    padding: 24px 0;
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px;
    border-bottom: 1px solid #E8E8E8;

    h1 {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px; 
        color: #000000;
        margin: 0;
    }

    span {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px; 
        color: var(--primary-pink);
        cursor: pointer;
    }
`;

export const BoxContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 24px 0 24px;

    span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
        color: #767676;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; 
        color: #000000;
        margin: 0;
    }
`;