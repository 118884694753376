import React from 'react';
import { Menu, Space } from 'antd';
import DotIcon from '../assets/icons/dotIcon.svg';
import { DropDown, MenuFlex } from '../styles';

interface ItemType {
  key: string;
  label: string;
  icon: any;
}

interface Props {
  handleItemClick: (data: string) => void;
  items: ItemType[];
  tableData: any;
}

const MenuList = ({ handleItemClick, items, tableData }: Props) => {
  const hasItems = items && items.length > 0;
  

  return (
    <Space size="middle">
      {hasItems && (
        <DropDown
          overlay={
            <div>
              <Menu className='drop-menu'>
                {items.map((item) => {
                  if (tableData?.status === 'INACTIVE' && item.label === 'Disable') {
                    return null;
                  }  if (tableData?.role === 'Owner'  && item.label === 'Disable') {
                    return null;
                  }
                  if (tableData?.status === 'ACTIVE'  && item.label === 'Resend invite link') {
                    return null;
                  }

                  if (tableData?.role !== 'Owner' && item.label === 'edit') {
                    return null;
                }


                  return (
                    <Menu.Item
                      key={item.key}
                      onClick={() => handleItemClick(item.key)}
                    >
                      <MenuFlex>
                        <img src={item.icon} width={20} />
                        <span className={`${item.label === "Disable" ? 'color' : ""}`}>{item.label}</span>
                      </MenuFlex>
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
          }
          trigger={['click']}
        >
          <a>
            <img src={DotIcon} width={50} alt="Dropdown Icon" />
          </a>
        </DropDown>
      )}
    </Space>
  );
};

export default MenuList;
