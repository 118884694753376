import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  font-family: "Inter";
`;

export const Title = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;

  h1{
    font-family: "Athletics";
    font-size: 24px;
    font-weight: 500;
    line-height: 32px; 
    letter-spacing: -0.48px;
    color: #000000;
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    color: #767676;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  button {
    margin-top: 20px;
  }

  a {
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px; 
    text-decoration: underline;
    margin-top: 10px;

    &:hover {
      color: var(--primary-pink);
    }
  }
`;

export const EmptyWrapperTitle = styled.h1`
  font-family: "Athletics";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin: 0;
`;

export const EmptyWrapperSubTitle = styled.p`
  color: #8F8F8F;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
`;

export const DownloadButton = styled.button`
  width: 30px;
  height: 30px;
  background: transparent;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileBody = styled.div`
  padding: 16px 0;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const BoxBody = styled.div`
  overflow: hidden;
  
  @media (min-width: 768px) {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
  }

  .upgrade__business {
    padding: 40px;
  }
`;

export const ReceiptWrapper = styled.div`
  font-family: "Inter";
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 2px;
  background-color: var(--primary-pink);
`;

export const ReceiptButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 30px 0 10px 0;
  align-self: center;

  span{
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    line-height: 15.6px;
  }
`;

export const ReceiptFooterNote = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; 
  color: #767676;
`;

export const ReceiptContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  img {
    height: 25px;
    object-fit: cover;
  }

  p {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 28px; 
  }

  @media (min-width: 768px) {
    p {
      font-size: 18px;
    }
  }
`;

export const ReceiptHeading = styled.div`
  background: #f9f9f9;
  height: 44px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px; 
  color: #000000;
  margin-top: 20px;
  text-transform: capitalize;
`;

export const ReceiptItemsSection = styled.div`
  margin: 35px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ReceiptItemHeader = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px; 
  padding-bottom: 8px;
  color: #000000;
  border-bottom: 1px solid #E8E8E8;
  margin: 0;
`;

export const ReceiptItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;

  p { 
    color: #A4A3AD;
    margin: 0;
  }

  span {
    color: #000000;
    width: 65%;
    text-align: right;
  }

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

