import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import VirtualCard from "./components/virtualCard";
import EmptyCard from "./components/EmptyCard";
import { Card } from "./style";
import {
  getWallets,
  getRate,
  getCard,
  getCardTransactions,
  getCardBalance,
} from "./CardsApi";
import {
  setCard,
  setCardBalance,
  setCardTransactions,
  setRate,
  setWallets,
  setWithdrawRate,
} from "./CardSlice";
import Loader from "../../components/common/Loader";

const Cards = () => {
  const [loadingCard, setLoadingCard] = useState(false);
  const [getTransDetails, setGetTransDetails] = useState(false);
  const dispatch = useAppDispatch();
  const { card } = useAppSelector((state) => state.cards);
  const { businessId, userObj } = useAppSelector((state) => state.users);

  const getMerchantDetails = async () => {
    setLoadingCard(true);

    try {
      
      const data = await getCard(userObj.id);
      dispatch(setCard(data));
      const activeCard = data.find((c) => c.isActive === true) || null;

      const wallets = await getWallets(businessId);
      dispatch(setWallets(wallets));
      const rate = await getRate("USD", "NGN", 1);
      const withdrawRate = await getRate("USD", "NGN", 1, true);
      dispatch(setRate(rate.rate));
      dispatch(setWithdrawRate(withdrawRate.rate));

      if(activeCard){
        setGetTransDetails(true);  
        const id = activeCard?.cardId ;

        const cardBalance = await getCardBalance(id);
        dispatch(setCardBalance(cardBalance.data.balance));
      }

      setLoadingCard(false);
    } catch (error) {
      setLoadingCard(false);
    }
    setLoadingCard(false);
  };

  useEffect(() => {
      getMerchantDetails();
  }, []);
 
  useEffect(() => {
    // Fetch card transactions after 6 seconds as it gets updated
    // after some seconds on the backend after funding and withdrawing
    // from the card.
    if (getTransDetails === true) {
      const timer = setTimeout(() => {

        const getCardTrans = async () => {
          try {
            const activeCard = card.find((c) => c.isActive === true) || null;
            const id = activeCard ? activeCard?.cardId : '';

            const cardTrans = await getCardTransactions(
              id,
              0,
              40
            );

            dispatch(setCardTransactions(cardTrans));
            setGetTransDetails(false);
          } catch (e) {
            setGetTransDetails(false);
          }
        };
  
        getCardTrans();
      }, 6000);
  
      return () => {
        clearTimeout(timer);
      };
    }
  }, [getTransDetails]);
  
  return (
    <>
      <Loader isLoading={loadingCard} data-testid="loader"/>
      {!loadingCard && (
        <Card> 
          {
          card.length > 0 ? <VirtualCard /> : <EmptyCard />
          }
        </Card>
      )}
    </>
  );
};

export default Cards;
