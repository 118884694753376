import api from "../../app/api";

export const getCustomerLinks = async (businessId: number, page: number, pageLimit: number) => {
  const { data } = await api.get(
    `merchant/paylink/business/${businessId}?page=${page - 1}&size=${pageLimit}`
  );
  return data;
};

export const deleteLink = async (id: number) => {
  return await api.get(`merchant/paylink/delete/${id}`);
};

export const createLink = async ({
  businessId,
  linkName,
  description,
  paymentAmount,
  selectedCurrency,
  PAYLINK_URL,
  paymentType,
  paymentInterval,
  paymentLimit,
}) => {
  const { data } = await api.post(`merchant/paylink/`, {
    businessId,
    name: linkName,
    description,
    amount: paymentAmount,
    currency: selectedCurrency,
    urlHost: PAYLINK_URL,
    payLinkType: paymentType,
    interval: paymentInterval,
    times: paymentLimit ?? 1000,
  });
  return data;
};

export const shareLink = async ({
  message,
  link,
  sendType,
  sendValue,
  businessName,
}) => {
  return await api.post(`merchant/paylink/share`, {
    message,
    link,
    [sendType]: sendValue,
    businessName,
  });
};
