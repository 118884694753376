import React from 'react';
import { Wrapper, Bottom, BottomItem, BottomLabel, BottomValue } from '../styles';
import TransactionStatus from '../../../components/transactions/components/TransctionStatus';


interface Props{
    transactionRef:string;
    amount:string;
    date:string;
    status: string;

}

const TransactionCard = ({transactionRef, amount, date, status}: Props) => {
  return (
    <Wrapper>
        <Bottom>
            <BottomItem>
            <BottomLabel>Transaction Id</BottomLabel>
            <BottomValue>{transactionRef}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Amount</BottomLabel>
            <BottomValue>{amount}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Date</BottomLabel>
            <BottomValue>{date}</BottomValue>
            </BottomItem>

            <BottomItem>
            <BottomLabel>Status</BottomLabel>
            <TransactionStatus text={status}/>
            </BottomItem>
        </Bottom>
    </Wrapper>
  )
}

export default TransactionCard