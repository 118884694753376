import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import {
    createWebHook,
    getBusinessWebHook,
} from "../../../../pages/Settings/settingsAPI";
import { Button } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { WebhookWrapper, Error } from "./Key/style";
import { useAppSelector } from "../../../../app/hooks";
import Loader from "../../../common/Loader";

interface FormInputs {
    webhookUrl: string;
    webhookKey: string;
}

const WebhookUrl = () => {
    const successAlertContext = useContext(
        SuccessAlertContext,
    );
    const { onShowAlert: onSuccess } =
        successAlertContext || {};
        const { onShowAlert} = useContext(
            NetworkErrorAlertContext,
          );
       
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors, isDirty },
        setValue,
    } = useForm<FormInputs>({
        defaultValues: {
            webhookUrl: "",
            webhookKey: "",
        },
    });
   
  

    const [loading, setLoading] = useState(false);
    const { businessId } = useAppSelector(
        (state) => state.users,
    );

    

    const getBusinessWebHookData = async () => {
        try {
            await getBusinessWebHook({
                id: businessId,
            }).then((res) => {                
                const { key, url } = res;
                setValue("webhookUrl", url);
                setValue("webhookKey", key);
            });
        } catch (error) {
            return error;
        }
    };

    const webhookUrlValue = watch("webhookUrl");
    const webhookKeyValue = watch("webhookKey");


    const createMerchantWebHook = async () => {

        const formData = {
            url: webhookUrlValue,
            key: webhookKeyValue,
            businessId,
        };

        try {
            setLoading(true);
            await createWebHook(formData).then(() => {
                setLoading(false);
                onSuccess("Webhook saved");
                getBusinessWebHookData();
            });
        } catch (error) {
            onShowAlert("", error?.response?.data.message);
            setLoading(false);
            return error;
        }
    };

    useEffect(() => {
        if (businessId) {
            getBusinessWebHookData();
        }
        
    }, [businessId]);

   

    return (
        <WebhookWrapper>
            <Loader isLoading={loading}/>
            <h2>Webhook</h2>
            <div className="content">
                <Controller
                    name="webhookUrl"
                    rules={{
                        required: "Webhook URL is required",
                        validate: (value) => {
                            const urlPattern = /^(https?:\/\/)?(www\.)?[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,}(\/.*)?$/i;
                            if (urlPattern.test(value)) {
                                return true;
                            }
                            return "Enter a valid URL";
                        },
                    }}
                    control={control}
                    render={({ field }) => (
                        <div>
                            <Input
                                label="Enter your webhook URL"
                                value={field.value}
                                onChange={field.onChange}
                                labelColor="#FFFFFF"
                                inputMode="url"
                            />
                            {errors.webhookUrl && (
                                <Error className="error">
                                    {
                                        errors.webhookUrl
                                            .message
                                    }
                                </Error>
                            )}
                        </div>
                    )}
                />

                <Controller
                    name="webhookKey"
                    rules={{
                        required: true,
                    }}
                    control={control}
                    render={({ field }) => (
                        <div>
                            <Input
                                label="Enter your webhook key"
                                value={field.value}
                                onChange={field.onChange}
                                labelColor="#FFFFFF"
                            />
                            {errors.webhookKey?.type ===
                                "required" && (
                                <Error className="error">
                                    Webhook key is required
                                </Error>
                            )}
                        </div>
                    )}
                />
                <Button
                    label={loading ? "loading..." : "Save"}
                    height="40px"
                    width="90px"
                    onClick={handleSubmit(
                        createMerchantWebHook,
                    )}
                    disabled={!isDirty || loading}
                    
                     />
            </div>
        </WebhookWrapper>
    );
};

export default WebhookUrl;
