import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { countries } from "countries-list";

dayjs.extend(weekday);

export const todaysDate = dayjs().format("YYYY-MM-DD");
const firstDayOfTheWeek = dayjs().weekday(0).format("YYYY-MM-DD");
const lastDayOfTheWeek = dayjs().weekday(6).format("YYYY-MM-DD");
const lastMonthDate = dayjs().date(-1);
const lastMonthFirstDay = lastMonthDate.startOf("month").format("YYYY-MM-DD");
const lastMonthLastDay = lastMonthDate.endOf("month").format("YYYY-MM-DD");
const thisMonthFirstDay = dayjs().startOf("month").format("YYYY-MM-DD");
const thisMonthLastDay = dayjs().endOf("month").format("YYYY-MM-DD");

export const weekDateFromTo = `${dayjs()
  .weekday(0)
  .format("MMM DD")} - ${dayjs().weekday(6).format("MMM DD YYYY")}`;
export const thisMonthDateString = dayjs().date(1).format("MMMM YYYY");
export const lastMonthDateString = dayjs().date(-1).format("MMMM YYYY");
export const todaysDateFormatted = dayjs().format("DD MMMM, YYYY");

export const todaySales = (salesData) => {
  const salesAmountToday = salesData.filter(
    (sales) => sales.createdAt.split(" ")[0] === todaysDate,
  );
  const todaySalesTotal = salesAmountToday.reduce(
    (prev, sales) => sales.sourceAmount + prev,
    0,
  );
  const salesTotal = (Math.round(todaySalesTotal * 100) / 100).toFixed(2);
  const salesCount = salesAmountToday.length;
  return { count: salesCount, total: salesTotal };
};

export const weekSales = (salesData) => {
  const salesForThisWeek = salesData.filter(
    (sale) =>
      sale.createdAt.split(" ")[0] >= firstDayOfTheWeek &&
      sale.createdAt.split(" ")[0] <= lastDayOfTheWeek,
  );
  const salesTotal = salesForThisWeek.reduce(
    (prev, sales) => sales.sourceAmount + prev,
    0,
  );
  return {
    count: salesForThisWeek.length,
    total: (Math.round(salesTotal * 100) / 100).toFixed(2),
  };
};

export const lastMonthSales = (salesData) => {
  const salesForLastMonth = salesData.filter(
    (sale) =>
      sale.createdAt.split(" ")[0] >= lastMonthFirstDay &&
      sale.createdAt.split(" ")[0] <= lastMonthLastDay,
  );

  const lastMonthTotalSales = salesForLastMonth.reduce(
    (prev, sales) => sales.sourceAmount + prev,
    0,
  );
  const salesTotal = lastMonthTotalSales;
  return {
    count: salesForLastMonth.length,
    total: (Math.round(salesTotal * 100) / 100).toFixed(2),
  };
};

export const thisMonthSales = (salesData) => {
  const salesForLastMonth = salesData.filter(
    (sale) =>
      sale.createdAt.split(" ")[0] >= thisMonthFirstDay &&
      sale.createdAt.split(" ")[0] <= thisMonthLastDay,
  );

  const lastMonthTotalSales = salesForLastMonth.reduce(
    (prev, sales) => sales.sourceAmount + prev,
    0,
  );
  const salesTotal = lastMonthTotalSales;
  return {
    count: salesForLastMonth.length,
    total: (Math.round(salesTotal * 100) / 100).toFixed(2),
  };
};

export const thisWeekSalesData = (salesData) =>
  salesData.filter(
    (sale) =>
      sale.createdAt.split(" ")[0] >= firstDayOfTheWeek &&
      sale.createdAt.split(" ")[0] <= lastDayOfTheWeek,
  );

export const thisMonthSalesData = (salesData) =>
  salesData.filter(
    (sale) =>
      sale.createdAt.split(" ")[0] >= thisMonthFirstDay &&
      sale.createdAt.split(" ")[0] <= thisMonthLastDay,
  );

export const sortCurrencyObjectByNameFunction = (a, b) => {
  const nameA = a.label.toUpperCase(); // ignore upper and lowercase
  const nameB = b.label.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

export const formatWithoutGhanaCountries = () => {
  const list = [];

  Object.entries(countries).forEach((country: any) => {
    const [key, value] = country;
    if (value.name !== "Ghana") {
      // exclude the country Ghana
      list.push({ key, label: value.name, value: value.name });
    }
  });
  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return list;
};

export const formatCountries = () => {
  let i;
  const countriesValues = Object.values(countries);
  const list = [];
  for (i = 0; i < countriesValues.length; i += 1) {
    list.push({
      value: countriesValues[i].name,
      label: countriesValues[i].name,
    });
  }

  return list.sort(sortCurrencyObjectByNameFunction);
};

export const countryOptions = formatCountries();
export const countryOptionsWithoutGhana = formatWithoutGhanaCountries();

export const getFormattedSalesChartDataForRange = (
  range,
  data,
  customRange?: number,
  endDate?: string,
) => {
  const formattedData = [];
  let i = 0;
  let period: any;
  let date: any;

  if (range === "24") {
    period = 2;
  } else if (range === "3") {
    period = 90;
  } else if (range === "12") {
    period = 365;
  } else if (range === "custom") {
    period = customRange + 1;
  } else {
    period = range;
  }

  for (i; i < period; i += 1) {
    if (range === "custom") {
      date = dayjs(endDate).subtract(i, "day");
    } else {
      date = dayjs().subtract(i, "day");
    }
    const formattedDate = date.format("YYYY-MM-DD");
    const dateInData = data
      ? data.find((dat) => dat.date === formattedDate)
      : [];

    if (dateInData) {
      formattedData.push({
        title: date.format("DD MMM"),
        value: dateInData.sum,
      });
    } else {
      formattedData.push({ title: date.format("DD MMM"), value: 0 });
    }
  }
  return formattedData;
};

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array?.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: item,
    }),
    initialValue,
  );
};

export const stringToSentenceCase = (myString: string) => {
  if(myString?.length){
    const rg = /(^\w|\.\s*\w)/gi;
    return myString.replace(rg, function (toReplace) {
      return toReplace.toUpperCase();
    });
  } else {
    return myString;
  }
};

export const getCurrencySymbolFromCode = (currencyCode: string) => {
  const currencySymbols = {
    NGN: "₦",
    USD: "$",
    KES: "K",
    RAND: "R",
  };
  const currencySymbol = currencySymbols[currencyCode];

  return currencySymbol;
};
export function sortAndFixCapitalization(items, targetLabel) {
  const capitalizeLabel = (label) => {
    const words = label.split(" ");
    for (let i = 1; i < words.length; i++) {
      words[i] = words[i].toLowerCase();
    }
    return words.join(" ");
  };

  const lowercaseTargetLabel = targetLabel.toLowerCase();

  const sortedItems = items.sort((a, b) => {
    const labelA = capitalizeLabel(a.label);
    const labelB = capitalizeLabel(b.label);

    if (a.label.toLowerCase() === lowercaseTargetLabel) {
      return 1;
    } else if (b.label.toLowerCase() === lowercaseTargetLabel) {
      return -1;
    } else {
      return labelA.localeCompare(labelB);
    }
  });

  const finalItems = sortedItems.map((item) => ({
    label: capitalizeLabel(item.label),
    value: item.value,
  }));

  return finalItems;
}

export const formatStrAndCapitalize = (str:string, maxLength: number) => {
  if (str){
    const trimmedStr = str.trim();
    const firstChar = trimmedStr.charAt(0).toUpperCase();
    const restOfStr = trimmedStr.slice(1).toLowerCase();
    const capitalizedStr = `${firstChar}${restOfStr}`;

    if (capitalizedStr.length <= maxLength) {
      return capitalizedStr;
    } else {
        return capitalizedStr.substring(0, maxLength) + "...";
    }
  }
}
