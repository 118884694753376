import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  font-family: "Inter";
`;

export const Header = styled.p`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

export const Inputs = styled.div`
  width: 100%;
  margin-bottom: 42px;
`;

export const InputBox = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 24px;

  & > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const RadioInputs = styled.div`
  padding-top: 17px;
  border-top: 1px solid #f0f0f0 !important;
  margin-bottom: 24px;

  & > div {
    display: flex;
    justify-content: space-between;
    
    & > *:first-child {
      margin-right: 24px;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }
`;
