import styled from "styled-components";

export const TitleParagraph = styled.p`
  font-family: "Athletics";
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #000000;
  margin-bottom: 8px;
`;

export const SubTitleParagraph = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.32px;
  text-align: center;
  color: #A39D9E;

`;

export const FormBodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    margin-bottom: 28px;
    max-width: 400px;
  }
  & > button {
    max-width: 400px;
  }
`;

export const FormInputDiv = styled.div`
  display: block;
  width: 100%;
  margin: 18px 0 32px;
  font-family: "Inter";
  
  & > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    margin-top: 4px;
    color: var(--primary-pink);
  }
  @media (min-width: 768px) {
    margin: 18px 0 32px;
  }
`;
