import api from "./api";

export const getExchangeRates = async () => {
  const { data } = await api.get('/nucleus/general/exchange/all');

  return data.data;
};

export async function getRate(payload) {
  const { data } = await api.post("/nucleus/general/exchange", payload);

  return data.data.rate;
}

