import styled, { css } from "styled-components";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg"


export const CopyIcon = styled(Copy)`
margin-bottom: 10px;
width:15px;

`;

export const Check = styled(CheckIcon)`
margin-bottom: 10px;
width:15px;

`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const TransactionBreakDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  .filter {
    margin: 18px;
  }
`;

export const TitleParagraph = styled.p`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin-bottom: 24px;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const EmptyWrapperTitle = styled.p`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
  margin: 0;

`;

export const EmptyWrapperSubTitle = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; 
  color: #8F8F8F;
  text-align: center;
  margin-bottom: 24px;
`;

export const EmptyWrapperButton = styled.button`
  width: 128px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border: unset;
`;

export const StatusTag = styled.p`
  text-transform: capitalize;
  padding: 6px 0;
  width: 135px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  & > span {
    margin-left: 10px;
  }
  & > div {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  ${(props: { $status: string }) => {
    if (props.$status === "pending") {
      return css`
        & > div {
          background-color: #3076ff;
        }
        & > span {
          color: #3076ff;
        }
      `;
    } else if (props.$status === "successful") {
      return css`
        & > div {
          background-color: #0eab44;
        }
        & > span {
          color: #0eab44;
        }
      `;
    } else if (props.$status === "failed") {
      return css`
        & > div {
          background-color: #f9123b;
        }
        & > span {
          color: #f9123b;
        }
      `;
    }
    return css`
      //background-color: #ffffff;
      & > div {
        background-color: #000000;
      }
      & > span {
        color: #000000;
      }
    `;
  }}
`;

export const CardsWrapper = styled.div`
  display: block;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: [col1-start] 70% [col1-end col2-start] 30% [col2-end]; /* grid-auto-flow: row; */
    gap: 1.25rem;
    margin-bottom: 32px;
  }
  @media (min-width: 1024px) {
    display: flex;
    margin-bottom: 32px;
    grid-template-columns: unset;
    gap: unset;
  }
`;

export const BalanceCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  min-height: 240px;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @media (min-width: 768px) {
    margin-bottom: unset;
  }
  @media (min-width: 1024px) {
    margin-right: 24px;
    width: unset;
  }
`;

export const MobileBody = styled.div`
  padding-top: 24px;
  padding-bottom: 32px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const BoxBody = styled.div`
  margin-top: 40px;
  overflow: hidden;

  @media (min-width: 768px) {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
`;

export const BreakDown = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  &::-webkit-scrollbar {
    display: none; 
  }

  @media(max-width: 1287px) {
    gap: 24px;
  }

  @media(min-width: 1800px) {
    gap: 24px;
    justify-content: unset;
  }
`;

export const SuccessWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 14;
`;

export const SuccessDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  width: 330px;
  height: 220px;
  box-shadow: 0px 0px 38px rgba(45, 66, 89, 0.16);
  border-radius: 8px;
  padding: 10px;
  font-style: normal;
  font-family: "Proxima Nova";
  color: #000000;
  z-index: 20;

  p {
    font-family: "Athletics";
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    margin-top: 24px;
    margin-bottom: 0;
  }
  p:last-child {
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    width: 456px;
    height: 240px;
    padding: 20px;
  }
`;

export const CloseBtn = styled.div`
  align-self: self-end;
  cursor: pointer;
`;

export const CloseSuccess = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  background-color: transparent;
`;


export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center
`;

export const Paragraph = styled.p`
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
color: #6B6B6B;
padding-top: 15px;
`;

export const Heading = styled.h3`
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px; 
`

export const DetailsWrapper = styled.div`
margin: 27px -10px;
padding: 16px;
border-radius: 6px;
background: #F9F9F9;
margin-top: 15px;

h3{
font-family: Inter;


}

`

export const TransactionWrapper = styled.div`
`

export const TransactionAmount = styled.h1`
color: #000;
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px;
letter-spacing: -0.48px;

`

export const CheckFlex = styled.div`
display: flex;
align-items: center;
gap: 4px;


`

export const InfoTrigger = styled.span`
  cursor: pointer;
`