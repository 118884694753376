import React, { ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Left,
  Right,
  Wrapper,
  JoinWhatsappLink
} from "./style";
import { Button } from "../../../common/Button";
import Search from "../../../common/Search";

interface TitleFilterProps {
  search: string;
  setSearch: (search: string) => void;
  onSendWire: () => void;
}
const TitleFilter = ({
  search,
  setSearch,
  onSendWire,
}: TitleFilterProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Left>
        <Button
          label="Send wire"
          theme="secondary"
          height="40px"
          width="165px"
          onClick={onSendWire}
          fontSize="12px"
        />
        <Search
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>setSearch(e.target.value)}
          showClose={search?.length > 0 || false}
          clearSearch={() => setSearch('')}
        />
      </Left>

      <Right>
        <Button
          label="Manage beneficiaries"
          theme="alternate"
          height="40px"
          fontSize="12px"
          onClick={() => navigate("/manage-beneficiaries")}
        />
        <JoinWhatsappLink
          href="https://wa.me/14159933858"
          target="_blank"
        >
          Get daily FX rates on WhatsApp
        </JoinWhatsappLink>
      </Right>
    </Wrapper>
  );
};

export default TitleFilter;
