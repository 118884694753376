export const currencies = [
  { label: "AUD", value: "AUD" },
  { label: "CAD", value: "CAD" },
  { label: "CNY", value: "CNY" },
  { label: "GBP", value: "GBP" },
  { label: "JPY", value: "JPY" },
  { label: "KES", value: "KES" },
  { label: "NGN", value: "NGN" },
  { label: "USD", value: "USD" },
  { label: "INR", value: "INR" },
  { label: "TZS", value: "TZS" },
];

export const intervals = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 7 },
  { label: "Monthly", value: 30 },
  { label: "Yearly", value: 365 },
]

export const message = "If you want subscriptions to run for a set amount of times, use this."

const dev = process.env.REACT_APP_DEV_PAYLINK 
const prod = process.env.REACT_APP_PAYLINK_URL

export const PAYLINK_ENV = process.env.NODE_ENV === "development" ? dev : prod
