import React from 'react';
import { ToggleSwitch } from './styles';

interface Props{
    onChange: (data) => void;

}

const Toggle = ({onChange}: Props) => {
  return (

    <ToggleSwitch onChange={onChange}/>

  )
}

export default Toggle;