import React, { useContext } from "react";
import { AlertBox, Content, Message } from "./style";
import CloseIcon from "../NetworkErrorAlert/close.svg";
import  SuccessIcon from "../NetworkErrorAlert/success.svg";

import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { Overlay } from "../NetworkErrorAlert/style";

const AlertComponent = ({
    message,
}: {
    message: string;
}) => {
    const { onClose } = useContext(SuccessAlertContext);

    return (
        <Overlay>
            <AlertBox>
                <Content>
                    <img src={SuccessIcon} className="success-icon"/>
                    <Message>{message}</Message>
                    <button onClick={onClose} className="close-icon">
                        <img src={CloseIcon}  />
                    </button>
                </Content>
            </AlertBox>
        </Overlay>
    );
};

const SuccessAlert = ({ message }: { message: string }) => {
    return <AlertComponent message={message} />;
};

export default SuccessAlert;
