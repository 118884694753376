import React, { useEffect, useState } from "react";
import { useLocation, useMatch } from "react-router-dom";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import CustomModal from "../../components/common/CustomModal";
import GenerateVirtualAccount from "./components/GenerateVirtualAccount";
import { useToast } from "../../app/hooks/useToast";
import {
    getCustomerTransaction,
    generateVirtualAccount,
    getVirtualAccount,
} from "./api";
import Forward from "./assets/forward.svg";
import CustomTable from "../../components/common/CustomTable";
import {
    Box,
    FlexWrapper,
    SingleCustomerTitle,
} from "./styles";
import CustomerDetail from "./components/CustomerDetail";
import { StyledTransactionsTable } from "../Wallets/components/TransactionsTable/style";
import TableTools from "../../components/common/TableTools";
import TransactionStatus from "../../components/transactions/components/TransctionStatus";
import EmptyVirtualAccount from "./components/EmptyVirtualAccount";
import Loader from "../../components/common/Loader";
import { formatCurrencyAmount } from "../../utils/currency";
import { formatLongString } from "../../utils/formatString";
import { merchantBusinessKeys } from "../Settings/settingsSlice";
import VirtualAccount from "./components/VirtualAccount";
import { useWindowSize } from "@reach/window-size";
import TransactionCard from "./components/TransactionCard";
import { exportTransactionData } from "./utils";

interface DateProps {
    startDate: Date;
    endDate: Date;
}

const Customer = () => {
    const match = useMatch("/customers/:account");
    const location = useLocation();
    const toast = useToast();
    const { customerDetail } = location.state || {};
    const { width } = useWindowSize();
    const Title = match?.pathname?.split("/")[1];
    const account = match?.params?.account || "";

    const [data, setData] = useState<any>([]);
    const { businessId } = useAppSelector(
        (state) => state.users,
    );

    const dispatch = useAppDispatch();
    const nameParts = customerDetail?.name?.split(" ");
    const firstName = nameParts?.[0] ?? "";
    const lastName =
        nameParts && nameParts.length > 0
            ? nameParts[nameParts.length - 1]
            : "";
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState<any>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [generateAccount, setGenerateAccount] =
        useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [publicKey, setPublickey] = useState("");
    const [encriptionKey, setEncrytptionKey] = useState("");
    const [generatingBVN, setgeneratingBVN] =
        useState(false);
    const [date, setDate] = useState<DateProps>();

    const [
        loadingVirtualAccount,
        setLoadingVirtualAccount,
    ] = useState(true);
    const [virtualAccount, setVirtualAccount] =
        useState(null);
    const startDate =
        date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
    const endDate =
        date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");
        
    const tableColumns = [
        {
            title: "Transaction ID",
            dataIndex: "transaction_id",
            key: "transaction_id",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: string) => (
                <TransactionStatus text={text} />
            ),
        },
    ];

    const encryptData = (data) => {
        const encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            encriptionKey,
        ).toString();
        return encrypted;
    };

    const getTransactions = async () => {
        setLoading(true);
        try {
            await getCustomerTransaction({
                customerEmail: customerDetail?.email,
                business: businessId,
                pageNumber: pageNumber,
                pageSize: 10,
                search: searchQuery,
                startDay: startDate,
                endDay: endDate,
            }).then((res) => {
                setLoading(false);
                setPageCount(res);


                const tableData = res.transactionSplit.map(
                    (item) => ({
                        key: item.transaction.id,
                        transaction_id: formatLongString(
                            item.transaction.tnxRef,
                            20,
                        ),
                        amount: formatCurrencyAmount(
                            item.transaction.currency,
                            item.transaction.amount / 100,
                        ),
                        date: dayjs(
                            item.transaction.createdAt,
                        )?.format("DD.MM.YYYY HH:mm"),
                        status: item.transaction.status,
                    }),
                );
                setData(tableData);
            });
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        getTransactions();
    }, [businessId, pageNumber, searchQuery]);

    const onPageChange = (page: number) => {
        setPageNumber(page);
    };

    const handleVirtualModal = () => {
        setGenerateAccount(true);
    };

    const handleCloseVirtualModal = () => {
        setGenerateAccount(!generateAccount);
    };
    

    useEffect(() => {
        if (businessId) {
            dispatch(merchantBusinessKeys(businessId)).then(
                (data) => {
                    setPublickey(data?.payload?.publicKey);
                    setEncrytptionKey(
                        data?.payload?.encryptionKey,
                    );
                },
            );
        }
    }, []);

    const handleFormSubmit = async (data) => {
        const formData = {
            bvn: data.bvn,
            firstName: firstName,
            lastName: lastName,
            email: customerDetail?.email,
        };

        const encryptedData = encryptData(formData);
        const payload = {
            message: encryptedData,
        };

        setgeneratingBVN(true);
        try {
            await generateVirtualAccount(
                payload,
                publicKey,
            );
            toast.notifySuccess("Success");
            setgeneratingBVN(false);
            setGenerateAccount(false);
            getVirtualAccountNumber();
        } catch (error) {
            setgeneratingBVN(false);
            setGenerateAccount(false);
            toast.notifyError(error);
        }
    };

    const getVirtualAccountNumber = async () => {
        setLoadingVirtualAccount(true);
        await getVirtualAccount(account)
            .then((res) => {
                setVirtualAccount(res);
                setLoadingVirtualAccount(false);
            })
            .catch((error) => {
                throw new error();
            });
    };

    useEffect(() => {
        getVirtualAccountNumber();
    }, [businessId]);

    const filter = () => {
        getTransactions();
    };

    const handleReset = async () => {
        setDate(null);
    };


    const exportToCSV = async () => {
        const transaction = pageCount?.transactionSplit
        await exportTransactionData(transaction, "csv"); 
     
    };
  
    const exportToXLS = async () => {
        const transaction = pageCount?.transactionSplit
        await exportTransactionData(transaction, "xls");         
    };


    return (
        <div>
            <SingleCustomerTitle>
                <h1 className="grayed-title">{Title}</h1>
                <img src={Forward} />
                <h1 className="black-title">{account}</h1>
            </SingleCustomerTitle>

            <Box>
                <FlexWrapper>
                    <CustomerDetail
                        firstName={firstName}
                        lastName={lastName}
                        email={customerDetail?.email}
                        phone_number={customerDetail?.phone}
                    />

                    <VirtualAccount
                        generateVirtualAccount={
                            handleVirtualModal
                        }
                        hasVirtualAccount={virtualAccount}
                        bankName={virtualAccount?.bankName}
                        accountNumber={
                            virtualAccount?.accountNumber
                        }
                        accountName={
                            virtualAccount?.accountName
                        }
                        fetchingVirtualAccount={
                            loadingVirtualAccount
                        }
                    />
                </FlexWrapper>
            </Box>

            <Box>
                <StyledTransactionsTable>
                    <h3>Transactions</h3>
                    <TableTools
                        placeHolder="Search by transaction Id"
                        onSearchChange={(e) =>
                            setSearchQuery(e.target.value)
                        }
                        onDateChange={(date) =>
                            setDate(date)
                        }
                        onFilterButtonClick={filter}
                        onResetButtonClick={handleReset}
                        onXLSButtonClick={exportToXLS}
                        onCSVButtonClick={exportToCSV}
                    />
                    {width > 767 ? (
                        <CustomTable
                            columns={tableColumns}
                            dataSource={data}
                            currentPage={
                                pageCount?.pageNumber
                            }
                            totalItemCount={
                                pageCount?.totalCount
                            }
                            onPageChange={onPageChange}
                            extraEmptyChildren={
                                <EmptyVirtualAccount
                                    title="No transactions yet"
                                    paragraph="All received or sent transactions will appear here."
                                />
                            }
                        />
                    ) : (
                        
                            <>
                                {data?.map((data) => {
                                    return (
                                        <TransactionCard
                                            key={data?.id}
                                            transactionRef={
                                                data?.transaction_id
                                            }
                                            amount={
                                                data?.amount
                                            }
                                            date={
                                                data?.date
                                            }
                                            status={
                                                data?.status
                                            }
                                        />
                                    );
                                })}
                            </>
                    
                    )}
                </StyledTransactionsTable>
            </Box>
            <Loader isLoading={loading} />

            <CustomModal
                isModalVisible={generateAccount}
                onClose={handleCloseVirtualModal}
                width="470px"
            >
                <GenerateVirtualAccount
                    onSubmit={handleFormSubmit}
                    loading={generatingBVN}
                />
            </CustomModal>
        </div>
    );
};

export default Customer;
