import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  max-width: 455px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 576px) {
    max-width: 100%;
  }
`;

export const BodyDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderTitle = styled.p`
  font-family: "Athletics";
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.56px;
  color: #1a1a1a;
  margin: 30px 0 7px 0;

  @media (min-width: 768px) {
    margin: 40px 0 10px 0;
  }
`;

export const SubtitleText = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #313131;
  margin-bottom: 40px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  @media (min-width: 576px) {
    flex-direction: row;
    align-items: center;
  }
`;
