export const formatLongString = (str, maxLength) => {
    const replacement = 'SP';

    if (!str || str?.length <= maxLength) {
        return str;
    } else {
        const shortenedStr = str.replace('settlement-payout', replacement);
        return shortenedStr.length <= maxLength ? shortenedStr : shortenedStr.substring(0, maxLength) + "...";
    }
}
