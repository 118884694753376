export const cards = [
  {
    key: "magento",
    title: "Magento",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#magento-2",
    description:
      "Magento is an open-source e-commerce platform for high GMV merchants. We integrate into both Magento Community and Enterprise.",
  },
  {
    key: "flutter",
    title: "Flutter",
    url: "https://developers.klasha.com/plugins-and-sdks/mobile-sdks#flutter-sdk",
    description:
      "A Flutter plugin for making payments via Klasha Checkout Technology.",
  },
  {
    key: "ios",
    title: "iOS",
    url: "https://developers.klasha.com/plugins-and-sdks/mobile-sdks#ios-sdk",
    description:
      "Klasha iOS SDK allows you to build a quick, simple and excellent payment experience in your iOS app.",
  },
  {
    key: "woocommerce",
    title: "WooCommerce",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#woocommerce",
    description:
      "WooCommerce is an open-source e-commerce plugin for WordPress. It is designed for small to large-sized online merchants using WordPress.",
  },
  {
    key: "opencart",
    title: "OpenCart",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#opencart",
    description:
      "OpenCart is an easy to-use, powerful, open source online store management program that can manage multiple online stores from a single back-end.",
  },
  {
    key: "android",
    title: "Android",
    url: "https://developers.klasha.com/plugins-and-sdks/mobile-sdks#android-sdk",
    description:
      "The Klasha Android SDK supports 5 payments methods out of the box, they are same as that supported by the web platform.",
  },
  {
    key: "bigCommerce",
    title: "BigCommerce",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#bigcommerce",
    description:
      "BigCommerce is a public-traded e-commerce platform offering software-as-a-service (SaaS) services for building and hosting online stores.",
  },
  {
    key: "weebly",
    title: "Weebly",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#weebly",
    description:
      "Weebly is a free website builder that makes it easy to create a website, blog, or online store. It is an all-in-one powerful e-commerce tool to simplify order management, shipping & payments.",
  },
  {
    key: "square",
    title: "Squarespace",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#squarespace",
    description:
      "Squarespace is a website building and e-commerce platform that enables users to build brands and transact with their customers.",
  },
  {
    key: "wix",
    title: "Wix",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#wix",
    description:
      "Wix is a leading cloud-based development platform that makes it easy for everyone to create a beautiful, professional web presence.",
  },
  {
    key: "ecwid",
    title: "Ecwid",
    url: "https://developers.klasha.com/plugins-and-sdks/plugins#ecwid",
    description:
      "Ecwid is an e-commerce widget that can seamlessly integrate with whatever platform you already use to promote your business, whether a business website, marketplace, app or more.",
  },
  {
    key: "react",
    title: "React",
    url: "https://developers.klasha.com/plugins-and-sdks/web-sdks#react-sdk",
    description:
      "The Klasha React SDK allows businesses to use Klasha to collect payments within their websites.",
  },
  {
    key: "angular",
    title: "Angular",
    url: "https://developers.klasha.com/plugins-and-sdks/web-sdks#angular-sdk",
    description:
      "The Klasha Angular SDK allows businesses to use Klasha to collect payments within their websites.",
  },
  {
    key: "vue2",
    title: "Vue 2",
    url: "https://developers.klasha.com/plugins-and-sdks/web-sdks#vue-sdk",
    description:
      "The Klasha Vue 2 SDK allows businesses to use Klasha to collect payments within their websites.",
  },
  {
    key: "vue3",
    title: "Vue 3",
    url: "https://developers.klasha.com/plugins-and-sdks/web-sdks#vue-sdk",
    description:
      "The Klasha Vue 3 SDK allows businesses to use Klasha to collect payments within their websites.",
  },
  {
    key: "javascript",
    title: "Javascript",
    url: "https://developers.klasha.com/accepting-payments/klasha-inline",
    description:
      "Detailed information on how to integrate Klasha to your website using JavaScript.",
  },
  {
    key: "reactNative",
    title: "React Native",
    url: "https://developers.klasha.com/plugins-and-sdks/mobile-sdks#react-native-sdk",
    description:
      "The Klasha React-native SDK allows businesses using Klasha to collect payments within their app.",
  },
  {
    key: "ionic",
    title: "Ionic",
    url: "https://developers.klasha.com/plugins-and-sdks/mobile-sdks#ionic-sdk",
    description:
      "The Klasha Ionic SDK allows businesses using Klasha to collect payments within their Ionic app.",
  },
];

export const FilterByTitle = (title) => {
  const newList = cards.filter((card) =>
    card.title.toLowerCase().includes(title.toLowerCase())
  );
  return newList;
};
