import React, { useEffect } from "react";
import { ErrorWrapper, RedLine, Content, Box, Text } from "./style";
import { itemVariant, itemTransition } from "../../utils";
import { Portal } from "@reach/portal";
import { ReactComponent as ErrorIcon } from "../../assets/errror.svg";
import { ReactComponent as CloseIcon } from "../../../../components/common/OTP/assets/close.svg"
import { ErrorProps } from "../../types";

const ErrorMessage = ({ setShowCardError, errorMessage }: ErrorProps) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCardError(false);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Portal>
      <ErrorWrapper>
        <Box
          initial="hidden"
          animate="visible"
          variants={itemVariant}
          transition={itemTransition}
        >
          <RedLine />
          <Content>
            <div>
              <ErrorIcon />
            </div>

            <Text>
              <h1>{errorMessage}</h1>

              <p>
                Please try again or contact the support team if the issue
                persists.
              </p>
            </Text>
          </Content>

          <span onClick={() => setShowCardError(false)} data-testid="close">
            <CloseIcon />
          </span>
        </Box>
      </ErrorWrapper>
    </Portal>
  );
};

export default ErrorMessage;
