import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { Wrapper, TitleParagraph } from "./style";
import Business from "../../components/settings/Business";
import Keys from "../../components/settings/Keys";
import Accounts from "../../components/settings/Accounts";
import Verification from "../../components/settings/Verification";
import { useAppSelector } from "../../app/hooks";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import Main from "./Team/Main";
import { selectUserRoleAndPermissions } from "./Team/redux/slice";
import { IPWhiteList } from "./IPWhiteList";

const { TabPane } = Tabs;

const Settings = () => {
    const [defaultKey, setDefaultKey] =
        useState("business");
    const params = useParams();
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );
    const navigate = useNavigate();
    const { business } = useAppSelector(
        (state) => state.settings,
    );

    useEffect(() => {
        if (params.tab) {
            setDefaultKey(params.tab);
        } else {
            setDefaultKey("business");
        }
    }, [params]);

    const hasViewAPIKeyPermission =
        permissions?.["api-keys"]?.includes("VIEW_API_KEY");        

    const hasViewManageTeam =
        permissions?.["team"]?.includes("MANAGE_USER");
    const hasViewManageRole =
        permissions?.["team"]?.includes("MANAGE_ROLE");


    const hasViewBankAccount = permissions?.[
        "bank-account"
    ]?.includes("VIEW_BANK_ACCOUNT");

    const onChange = (key) => {
        if (
            key === "keys" &&
            business?.approved === false
        ) {
            onShowErrorAlert(
                "",
                "Your business needs to be approved to access your API keys",
            );
            return;
        }

        if (key === "keys" && businessId === null) {
            onShowErrorAlert(
                "",
                "You need to complete your business registration to access your API keys",
            );
            return;
        }

        navigate(`/settings/${key}`, { replace: true });
    };


    return (
        <Wrapper>
            <TitleParagraph>Settings</TitleParagraph>
            <Tabs
                activeKey={defaultKey}
                onChange={onChange}
            >
                <TabPane tab="Business" key="business">
                    <Business />
                </TabPane>
                <TabPane
                    tab="Business verification"
                    key="verification"
                >
                    <Verification />
                </TabPane>
                {(hasViewAPIKeyPermission ||
                    role === "OWNER") && (
                    <TabPane
                        tab="Generate API keys"
                        key="keys"
                    >
                        <Keys />
                    </TabPane>
                )}

                {(hasViewBankAccount ||
                    role === "OWNER") && (
                    <TabPane
                        tab="Bank accounts"
                        key="accounts"
                    >
                        <Accounts />
                    </TabPane>
                )}

                {(hasViewManageTeam ||hasViewManageRole ||
                    role === "OWNER") && (
                    <TabPane tab="Team" key="team">
                        <Main />
                    </TabPane>
                )}
                <TabPane tab="Security" key="ip-white-list">
                    <IPWhiteList />
                </TabPane>
            </Tabs>
        </Wrapper>
    );
};

export default Settings;
