import React, { createContext, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { merchantBusinessDetail } from "../../pages/Dashboard/dashboardSlice";
import { getUploadedDocumentsByBusinessId } from "../../pages/Compliance/complianceSlice";
import { PendingComplaince } from "../../components/AppShell/components/Header/style";

interface BusinessContextValue {
  businessType: string | null;
  uploadedDocuments: any[];
  businessId: string;
}

export const BusinessContext = createContext<BusinessContextValue | undefined>(
  undefined
);

export const BusinessContextProvider: React.FC = ({ children }) => {
  const { businessId, userId } = useAppSelector((state) => state?.users);
  const { businessType, uploadedDocuments, complianceData } = useAppSelector(
    (state) => state?.compliance
  );
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    dispatch(merchantBusinessDetail(businessId));
    dispatch(getUploadedDocumentsByBusinessId({ businessId }));
  }, [dispatch, businessId, userId,]);

  useEffect(() => {
    if(businessId)
    return

}, [uploadedDocuments, businessType, userId])


  return (
    <BusinessContext.Provider
      value={{ businessType, uploadedDocuments, businessId }}
    >
      {(userId && !businessId && complianceData?.startupComplianceStatus !== 'Done' && complianceData?.startupComplianceStatus === undefined) &&
      (location.pathname !== "/compliance") ? (
        <PendingComplaince>
           <h1>Update your account details</h1>
          <p>
            To start using Klasha Business, please{" "}
            <span onClick={() => navigate("/compliance")}>click here</span> to start our quick KYB process.
          </p>
        </PendingComplaince>
      ) : null}

      {children}
    </BusinessContext.Provider>
  
  );
};