import React, { useState, useEffect } from "react";
import Card from "./Card";
import BeneficiaryModal from "./BeneficiaryModal";
import CustomModal from "../../../../../../components/common/CustomModal";
import { Button } from "../../../../../../components/common/Button";
import Search from "../../../../../../components/common/Search";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
    Beneficiaries,
    BeneficiaryWrapper,
    ContentWrapper,
    MainHeader,
    MainTitle,
    NotFound,
} from "../styles";
import Loader from "../../../../../../components/common/Loader";
import { getBeneficiaries } from "../../../../redux/slice";
import { setBeneficiaries, setSelectedBeneficiary,  } from "../../../../redux/formDataSlice";
import {useSelector} from "react-redux";

export interface BeneficiaryProps {
    setStep: (arg: number) => void;
}

const Beneficiary = ({ setStep }: BeneficiaryProps) => {
    const [search, setSearch] = useState("");
    const dispatch = useAppDispatch();
    const { businessId } = useAppSelector((state) => state.users || {});
    const { loading } = useAppSelector((state) => state?.wallets || {});
    const savedBeneficiaries = useAppSelector((state) => state?.formData?.beneficiaries || []);
    const selectedBeneficiary = useAppSelector((state) => state?.formData?.selectedBeneficiary || null);
    const activeCurrency = useSelector((state: any) => state.formData.activeCurrency);

    const savedUserData = useAppSelector(
        (state) => state?.formData?.userData,
    );
    const [filteredBeneficiaries, setFilteredBeneficiaries] = useState(savedBeneficiaries);
    const [showModal, setShowModal] = useState(false);

    const fetchBeneficiaries = async () => {
        const action = await dispatch(getBeneficiaries({ businessId }));
        if (getBeneficiaries.fulfilled.match(action)) {
            dispatch(setBeneficiaries(action.payload));
        }
    };
    useEffect(() => {
            fetchBeneficiaries();
    }, [businessId, dispatch]);



    useEffect(() => {
        if (!savedBeneficiaries.length) return;
        const searchQuery = (search || "").toLowerCase();
        const active = savedBeneficiaries.filter((b: any) => !b.disabled);
        const filtered = active.filter(
            (item: any) =>
                item.beneficiaryName?.toLowerCase().includes(searchQuery) ||
                item.accountNumber?.toString().includes(searchQuery) ||
                item.bankName?.toLowerCase().includes(searchQuery)
        );
        setFilteredBeneficiaries([...filtered].reverse());
    }, [savedBeneficiaries, search]);

    const onSelectBeneficiary = (beneficiary) => {
        dispatch(setSelectedBeneficiary(beneficiary));
    };

    const handleBeneficiary = () => {
        setStep(3);
    };


    const renderBeneficiaries = () => {
        if (!filteredBeneficiaries.length) {
            return (
                <NotFound>
                    <p>No result found</p>
                    <Button
                        label="Add new beneficiary"
                        height="40px"
                        fontSize="12px"
                        onClick={() => setShowModal(true)}
                    />
                </NotFound>
            );
        }

        return filteredBeneficiaries.map((beneficiary: any) => (
            <Card
                key={beneficiary.id}
                accName={beneficiary.beneficiaryName}
                bankName={beneficiary.bankName}
                accNumber={beneficiary.accountNumber}
                currency={beneficiary.country}
                beneficiary={beneficiary}
                selectedRecipientId={selectedBeneficiary?.id}
                setSelectedRecipient={onSelectBeneficiary}
                disabled={
                    (beneficiary.country !== "United States" && !savedUserData?.selectedCountry &&
                        (activeCurrency === 'Same currency' || activeCurrency === 'International')) ||
                    (savedUserData?.selectedCountry && savedUserData.selectedCountry !== beneficiary.country)
                }
                width="250px"
            />

        ));
    };

    return (
        <ContentWrapper>
            <Loader isLoading={loading} />
            <MainHeader>
                <h1>Enter beneficiary details</h1>
                <p>Kindly provide beneficiary and payment details</p>
            </MainHeader>

            {!loading && savedBeneficiaries.length ? (
                <BeneficiaryWrapper>
                    <Search
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder="Search beneficiary name, account number"
                        width="100%"
                    />

                    {filteredBeneficiaries.length > 0 && (
                        <MainTitle>
                            <p>Saved beneficiaries</p>
                            <span onClick={() => setShowModal(true)}>Add new beneficiary</span>
                        </MainTitle>
                    )}

                    <Beneficiaries>{renderBeneficiaries()}</Beneficiaries>

                    <Button
                        label="Continue"
                        height="40px"
                        fontSize="12px"
                        className="btn"
                        disabled={!selectedBeneficiary}
                        onClick={handleBeneficiary}
                    />
                </BeneficiaryWrapper>
            ) : (
                !loading && <BeneficiaryModal showTitle={false} handleNext={setStep} getBeneficiaries={fetchBeneficiaries}/>
            )}

            <CustomModal
                isModalVisible={showModal}
                onClose={() => setShowModal(false)}
                width="480px"
            >
                <BeneficiaryModal showTitle={true} setShowNewModal={setShowModal} getBeneficiaries={fetchBeneficiaries}/>
            </CustomModal>
        </ContentWrapper>
    );
};

export default Beneficiary;
