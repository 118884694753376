import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 0 16px 16px 16px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const BottomItem = styled.div`
  width: 48%;
  margin-top: 16px;

  &:nth-child(2) {
    text-align: right;
  }

  &:nth-child(4) {
    text-align: right;
  }

  &:nth-child(5) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem
  }
`;

export const BottomLabel = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 15.6px;
  color: #767676;
  margin-bottom: 4px;
`;

export const BottomValue = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
  word-wrap: break-word;
`;

export const FlexToRight = styled.div`
  width: 100% @media (max-width: 768px) {
    text-align: right;
  }
`;
