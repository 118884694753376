import styled from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;
