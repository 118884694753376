import React, { useState, useEffect } from "react";
import { TableContainer } from "./style";
import Title from "./Title";
import Table from "./Table";
import { useAppSelector } from "../../../../../app/hooks";
import MobileCard from "./MobileCard";
import { formatCurrencyAndAmount, formatToSentenceCase } from "../../../utils";
import dayjs from "dayjs";

interface TransactionProps {
  setIsLoading: (arg: boolean) => void;
}

const CardTransactionsTable = ({ setIsLoading }: TransactionProps) => {
  const { cardTransactions } = useAppSelector((state) => state.cards);
  const [search, setSearch] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!cardTransactions?.data) {
      setTableData([]);
      return;
    }
  
    const filteredAndFormattedData = cardTransactions?.data
      .filter((item) =>
        [
          item.cardTransactionType,
          item.amount?.toString(),
          item.description,
          item.transactionReference,
          item.createdAt,
        ]
        .some((field) =>
          field?.toLowerCase().includes(search.toLowerCase())
        )
      )
      .map((item) => ({
        key: item.id,
        no: item.id,
        cardTransactionType: formatToSentenceCase(item.cardTransactionType) || '--',
        amount: formatCurrencyAndAmount(item.currency, item.amount),
        description: item.description,
        transactionReference: item.transactionReference || "--",
        createdAt: dayjs(item.createdAt).format("DD.MM.YYYY - HH:mm"),
      }));
  
    setTableData(filteredAndFormattedData);

  }, [search, cardTransactions]);

  return (
    <div>
      <TableContainer>
        <h3>Transactions</h3>

        {cardTransactions?.data?.length > 0 && (
          <Title search={search} setSearch={setSearch} />
        )}

        <Table setIsLoading={setIsLoading} tableData={tableData} />
      </TableContainer>

      <MobileCard search={search} setSearch={setSearch} tableData={tableData} />
    </div>
  );
};

export default CardTransactionsTable;
