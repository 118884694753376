import React, { useContext, useState, useRef, useEffect, ChangeEvent } from "react";
import { useMutation } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AxiosError } from "axios";
import {
  ForgotTextLink,
  FormInputDiv,
  SignupDiv,
  TitleParagraph,
  Wrapper,
  FormStyle,
  LoginButton,
} from "./style";
import { Input } from "../../components/common/Input";
import { Button } from "../../components/common/Button";
import { sendOtpToMerchant } from "../../api/authAPI";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { useAppDispatch } from "../../app/hooks";
import { updateLoginDetails } from "./usersSlice";
import Loader from "../../components/common/Loader";
import LoginOTP from "./loginOTP";

type Inputs = {
  email: string;
  password: string;
  userType: "MERCHANT";
};


const LoginForm = () => {
  const [verifyOTP, setVerifyOTP] = useState<boolean>(false);
  const { onShowAlert } = useContext(NetworkErrorAlertContext);
  const dispatch = useAppDispatch();
  const [formInputs, setFormInputs] = useState({
    email: '',
    password: '',
  });

  const mailformat = /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const alertParam = queryParams.get("alert");

  useEffect(() => {
    if (alertParam === "mobile-user") {
      onShowAlert("", "You are a mobile user. Please try the mobile app");
      localStorage.removeItem("token");
    }
  }, [alertParam]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const formRef = useRef();

  const sendOTP = useMutation(sendOtpToMerchant, {
    onError: (error: AxiosError) => {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (
        errorObj.error ===
        "Full authentication is required to access this resource"
      ) {
        errorMessage =
          "You have reached the maximum amount of login attempts. Please try again in 24 hours";
      } else if (typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Failed to login. Please try again";
      }

      onShowAlert(errorMessage, errorMessage);
    },
    onSuccess: async () => {
      setVerifyOTP(true);
    },
  });
  

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const lowercaseEmail = data.email ? data.email.toLowerCase() : '';
  
    const payload = {
      ...data,
      email: lowercaseEmail,
    };
  
    sendOTP.mutate(payload);
    dispatch(updateLoginDetails(payload))
    };


  return (
    <Wrapper>
      <Loader isLoading={sendOTP.isLoading} />

      <FormStyle onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <TitleParagraph>Welcome back</TitleParagraph>
        <FormInputDiv
          $hasError={["required", "pattern"].indexOf(errors.email?.type) > -1}
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: mailformat,
            }}
            render={({ field }) => (
              <Input 
                type="email" 
                label="Email" 
                field={field} 
                disableLabel 
                value={formInputs.email}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFormInputs({...formInputs, email: e.target.value})
                }
              />
            )}
          />
          {errors.email?.type === "required" && (
            <span>The email is required</span>
          )}
          {errors.email?.type === "pattern" && (
            <span>Please enter a valid email address</span>
          )}
        </FormInputDiv>
        <FormInputDiv>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                type="password"
                label="Password"
                field={field}
                disableLabel
                value={formInputs.password}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFormInputs({...formInputs, password: e.target.value})
                }
              />
            )}
          />

          <ForgotTextLink>
            <Link to="/auth/forgot-password">Forgot password?</Link>
          </ForgotTextLink>
        </FormInputDiv>

        <LoginButton>
          <Button 
              label="Log in" 
              type="submit" 
              disabled={!formInputs.email || !formInputs.password}
              height="40px"
              fontSize="12px"
          />
        </LoginButton>

        <SignupDiv>
          <span>
            Don’t have an account? <Link to="/auth/signup">Create account</Link>
          </span>
        </SignupDiv>
      </FormStyle>

      {verifyOTP && <LoginOTP setVerifyOTP={setVerifyOTP} />}
    </Wrapper>
  );
};



export default LoginForm;
