import React, { useEffect, useState } from "react";
import { roleList } from "../../utils/RoleList";
import RolePermissionList from "./RolePermissions";
import { ParamsTitle, RoleWrapper } from "../../styles";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import { GetRolePermissions } from "../../redux/actions";
import { useParams } from "react-router-dom";
import Loader from "../../../../../components/common/Loader";
import { capitalizeName } from "../../utils/capitalizeName";

const PermissionList = () => {
    const dispatch = useAppDispatch();
    const [permissions, setPermissions] = useState<
        Record<string, string[]>
    >({});

    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );

    const { name } = useParams() || {};
    const formattedRoleName = name
        ? name.replace(/[^\w\s]/gi, "")
        : "";

    useEffect(() => {
        const fetchPermissions = async () => {
            const action = await dispatch(
                GetRolePermissions({
                    businessId,
                    roleName: formattedRoleName,
                }),
            );
            const { permissions } = action.payload.data;

            const formattedPermissions =
                formatPermissions(permissions);
            setPermissions(formattedPermissions);
        };

        fetchPermissions();
    }, [businessId, name, dispatch]);

    const formatPermissions = (
        permissions: Record<string, string[]>,
    ) => {
        const formatted: Record<string, string[]> = {};
        Object.keys(permissions).forEach((module) => {
            formatted[module] = permissions[module].map(
                (permission) =>
                    permission
                        .toUpperCase()
                        .replace(/\s+/g, "_"),
            );
        });
        return formatted;
    };

    return (
        <RoleWrapper>
            <Loader isLoading={loading} />
            <ParamsTitle>
                <span> Roles</span>
                <span>
                    {capitalizeName(formattedRoleName)}
                </span>
            </ParamsTitle>
            {roleList?.map((module) => {
                if (!module || !module.value) {
                    return null;
                }

                const modulePermissions =
                    permissions[module.value] || [];

                const formattedPermissions =
                    modulePermissions.map((permission) =>
                        permission
                            .toUpperCase()
                            .replace(/\s+/g, "_"),
                    );

                return (
                    <RolePermissionList
                        key={module.value}
                        module={module.value}
                        permissions={formattedPermissions}
                    />
                );
            })}
        </RoleWrapper>
    );
};

export default PermissionList;
