import styled from "styled-components";

export const Wrapper = styled.a`
  padding: 24px 32px;
  width: 100%;
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: "Proxima Nova", sans-serif;

  & p {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin-right: 30px;
    width: unset;
  }
`;

export const Header = styled.div`
  margin-bottom: 6px;
  & span > svg {
    width: 24px;
    height: 24px;
  }
`;

export const Body = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  
  & p {
    margin-right: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #000000;
  }
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #464646;
`;
