import styled from 'styled-components';


export const Initials = styled.div`
  width: 100px;
  height: 100px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Paragraph = styled.p`
  color: #757575;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 10px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f0f0f0;
  object-fit: cover;
`;