import React from "react";
import { AuthWrapper } from "../../components/AuthWrapper";
import SignupForm from "../../components/forms/Signup";

const Signup = () => {
  return (
    <AuthWrapper>
      <SignupForm />
    </AuthWrapper>
  );
};
export default Signup;
