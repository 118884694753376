import React from 'react';
import { Bottom, BottomItem, BottomLabel, BottomValue,  Wrapper } from '../styles';


interface Props{
    fullName: string;
    email:string;
    phoneNumber: string;
    virtualAccount: string;
    date?: string;
    country: string
    onClick: () => void;

}

const MobileTable = ({fullName, phoneNumber, virtualAccount, date, email, country, onClick}: Props) => {
  return (
    <Wrapper onClick={onClick}>
        <Bottom>
        <BottomItem>
            <BottomLabel>Full name</BottomLabel>
            <BottomValue>{fullName}</BottomValue>
        </BottomItem>
        <BottomItem>
            <BottomLabel>Email address</BottomLabel>
            <BottomValue>{email}</BottomValue>
        </BottomItem>
        </Bottom>
       <Bottom>
       <BottomItem>
            <BottomLabel>Phone number</BottomLabel>
            <BottomValue>{phoneNumber}</BottomValue>
            
        </BottomItem>

        <BottomItem>
            <BottomLabel>Country</BottomLabel>
            <BottomValue>{country}</BottomValue>
            
        </BottomItem>
       </Bottom>


       <Bottom>
       <BottomItem>
            <BottomLabel>Virtual account number</BottomLabel>
            <BottomValue>{virtualAccount}</BottomValue>
            
        </BottomItem>

        <BottomItem>
            <BottomLabel>Date</BottomLabel>
            <BottomValue>{date}</BottomValue>
            
        </BottomItem>

       </Bottom>
        

    </Wrapper>
  )
}

export default MobileTable