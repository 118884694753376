import React from "react"

const themeColors = {
    primary: '#000000',
    secondary: '#FFFFFF'
} as const

type ThemeColor = keyof typeof themeColors

type CloseIconProps = {
    color?: ThemeColor | string;
}

const CloseIcon: React.FC<CloseIconProps> = ({ color = 'primary' }) => {
    const strokeColor = (color in themeColors) 
    ? themeColors[color as ThemeColor] 
    : (color || themeColors.primary);
    
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M6 6L18 18" stroke={strokeColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>;
}

export default CloseIcon;