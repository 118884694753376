import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { useAppDispatch } from "../../app/hooks";
import {
  clearFormData,
  clearUserData,
} from "../../pages/Wallets/redux/formDataSlice";
import { getBaseRoute } from "../../utils/getBaseRoute";

export const useHeaderLogic = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const posthog = usePostHog();
  const [close, setClose] = useState(false);
  const { currencyCode } = useParams();
  const { pathname } = useLocation();

  const segments = pathname.split("/").filter((segment) => segment);
  const baseRoute = getBaseRoute(pathname);
  const hasMoreThanOneSegment = segments.length > 2;

  useEffect(() => {
    setClose(hasMoreThanOneSegment);
  }, [pathname]);

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
    navigate("/auth/login");
    posthog.reset();
  };

  const handleNavigate = () => {
    if (baseRoute) {
      navigate(baseRoute);
      dispatch(clearUserData());
      dispatch(clearFormData());
    }
  };

  const path = hasMoreThanOneSegment || pathname.includes(currencyCode);

  return {
    close,
    path,
    logOut,
    handleNavigate,
    navigate,
  };
};
