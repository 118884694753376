import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  font-family: Inter;
`;

export const Content = styled.div`
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  font-family: Inter;
  & > * {
    margin-bottom: 16px;
    width: 100%;
  }
  @media (min-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 16px;
    flex-direction: row;
    align-items: flex-end;

    & > div {
      //width: min(48%, 505px);
      width: min(100%, 380px);
    }
    //& > div:nth-of-type(even) {
    //  width: calc(90% - 380px);
    //}
    & > div:nth-of-type(odd) {
      margin-right: 24px;
    }
    & > div:first-child {
      margin-right: 24px;
    }
    & > div > div > p {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
  }
  @media (min-width: 1024px) {
    & > div {
      margin-right: 24px;
    }
  }
`;

export const VerificationTag = styled.span`
  text-transform: capitalize;

  @media (max-width: 768px) {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
  ${(props: { $status: string }) => {
    if (props.$status === "pending" || props.$status === "PENDING") {
      return css`
        color: #717070;
      `;
    } else if (props.$status === "APPROVED") {
      return css`
        color: #0eab44;
      `;
    } else if (props.$status === "failed") {
      return css`
        color: #f9123b;
      `;
    }
    return css`
      color: #000000;
    `;
  }}
`;

export const UploadContainer = styled.div`
  width: 100%;
  font-family: Inter !important;
`;
