import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "klasha-merchant.firebaseapp.com",
  projectId: "klasha-merchant",
  storageBucket: "klasha-merchant.appspot.com",
};

const firebaseApp: any = initializeApp(firebaseConfig);

export default firebaseApp;
