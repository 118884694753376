import styled from "styled-components";
import { Modal } from "antd";

type Props = {
  $width: string;
  $noPadding: boolean;
}

export const Wrapper = styled(Modal)<Props>`
  font-family: "Inter";
  width: 90% !important;
  background: #ffffff;
  border-radius: 8px;
  padding-bottom: 0;
  overflow-y: auto;
  position: relative;

  & span[class="ant-modal-close-x"] {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & div.ant-modal-body {
    padding: ${(props) => `${props.$noPadding ?? false ? '24px 0' : '24px'}`};
  }

  @media (min-width: 768px) {
    width: ${(props) =>
      `min(${props.$width}) !important` || "464px !important"};
  }
`;

export const Header = styled.div<{ theme: string }>`
  background-color: ${({ theme }) => {
    switch (theme){
      case "primary": return "#FFFFFF";
      case 'secondary': return '#000000';
      default: return '#FFFFFF'
    }
  }};
  border: ${({ theme }) => {
    switch (theme){
      case "primary": return "1px solid #FFFFFF";
      case 'secondary': return '1px solid #000000';
      default: return '1px solid #FFFFFF'
    }
  }};
  color: ${({ theme }) => {
    switch (theme){
      case "primary": return "#000000";
      case 'secondary': return '#FFFFFF';
      default: return '#000000'
    }
  }};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 16px;
  border-radius-top: 8px; 
`
