import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomStepper from '../../../../components/common/CustomStepper';
import Amount from './Amount';
import Review from './Review';
import { Wrapper } from "./styles"

type Stage = {
  title: string;
  toolTip?: boolean;
};

const stages: Stage[] = [
  {
    title: "Amount",
    toolTip: true,
  },
  {
    title: "Review",
  },
];

const SWAP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialStepTitle = queryParams.get('type') || 'Amount';

  const [stepTitle, setStepTitle] = useState<string>(initialStepTitle);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get('type') || 'Amount';
    setStepTitle(type);
  }, [location.search]);

  const handleStepChange = (newStepTitle: string) => {
    setStepTitle(newStepTitle);
    navigate(`/wallets/swap/currency?type=${newStepTitle.toLowerCase()}`);
  };

  const renderStepContent = () => {
    switch (stepTitle.toLowerCase()) {
      case 'amount':
        return <Amount setStep={handleStepChange} />;
      case 'review':
        return <Review />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <CustomStepper
        stages={stages.map(stage => ({
          ...stage,
          stage: stage.title.toLowerCase()
        }))}
        step={stepTitle.toLowerCase()}
        setStep={(newStep: string) => handleStepChange(newStep)}
      />
      {renderStepContent()}
    </Wrapper>
  );
};

export default SWAP;