import api from "../../app/api";
import rateApi from "../ExchangeRate/api"

export async function getWirePayments(businessId: number) {
  const { data } = await api.get(`merchant/bulkmerchantpayment/${businessId}`);
  return data;
}

export async function getWirePaymentsFiltered(payload) {
  const { data } = await api.post(
    "/merchant/bulkmerchantpayment/admin/paginated/filter",
    payload,
  );
  return data?.bulkMerchantPayments;
}

export async function getWireBeneficiaries(businessId: number) {
  const { data } = await api.get(`merchant/merchantbeneficiary/${businessId}`);
  return data;
}

export async function createAndUpdateWireBeneficiary(payload) {
  const { data } = await api.post("/merchant/merchantbeneficiary/", payload);
  return data;
}

export async function postWireBeneficiary(payload) {
  const { data } = await api.post(
    "/merchant/merchantbeneficiary/admin/paginated/filter",
    payload,
  );
  return data;
}

export async function createWirePayment(payload) {
  const { data } = await api.post("/merchant/bulkmerchantpayment/kwire", payload);
  return data;
}

export async function getExchangeRate(
  sourceCurrency: string,
  amount: number,
  destinationCurrency: string,
  businessId?: string,
  productType?: string
) {
  const { data } = await rateApi.post("nucleus/general/exchange", {
    sourceCurrency,
    amount,
    destinationCurrency,
    businessId,
    productType
  });
  
  return data.data;
}

export async function getDefaultRate(
  sourceCurrency: string,
  amount: number,
  destinationCurrency: string,
) {
  const { data } = await rateApi.post("nucleus/exchange/", {
    sourceCurrency,
    amount,
    destinationCurrency,
  });
  return data;
}

export async function getWireCharges(
  selectedPayingCurrency: string,
  selectedSendingCurrency: string,
) {
  const { data } = await api.get(
    `merchant/bulktransferfee/by/${selectedPayingCurrency}/${selectedSendingCurrency}`,
  );
  return data;
}

export async function getWireChargesV2(
  selectedPayingCurrency: string,
  selectedSendingCurrency: string,
  amount: number,
) {
  const { data } = await api.get(
    `merchant/bulktransferfee/by/new/${selectedPayingCurrency}/${selectedSendingCurrency}/${amount}`,
  );
  return data;
}


export async function getWireChargesV3(
    selectedPayingCurrency: string,
    selectedSendingCurrency: string,
    amount: number,
    businessId: string
  ) {
    const { data } = await api.get(
      `merchant/bulktransferfee/by/id/new/${selectedPayingCurrency}/${selectedSendingCurrency}/${amount}/${businessId}`,
    );
    return data;
  }
  

export async function getReceivingCurrencies() {
  const { data } = await api.get(`/merchant/wirecurrency/get/unique/currency`);
  return data;
}

export async function getPayingCurrencies(receivingCurrency: string) {
  const { data } = await api.get(
    `merchant/wirecurrency/get/destination/${receivingCurrency}`,
  );
  return data;
}

export async function getReceivingCountries() {
  const { data } = await api.get(`/merchant/wirecountry/get/unique/country`);
  return data;
}

export async function getReceivingCurrenciesByCountry(country: string) {
  const { data } = await api.get(
    `/merchant/wirecountry/get/source/active/${country}`,
  );
  return data;
}

export async function loginToPayment(payload, token) {
  const { data } = await api.post("pay/wallet/popup", payload, {
    headers: {
      "x-auth-token": `${token}`,
    },
  });
  return data;
}

export async function sendFunds(payload) {
  try {
    const { data } = await api.post(
      `/wallet/foreign/banktransfer/request/`,
      payload,
    );
    return data;
  } catch (error) {
    throw error.response.data.error;
  }
}

export async function getTransactionDetails(payload) {
  try {
    const { data } = await api.post(
      `/wallet/admin/transaction/paginated/filter`,
      payload,
    );
    return data;
  } catch (error) {
    return error;
  }
}
