import React, {
    Dispatch,
    SetStateAction,
    useState,
    useEffect,
} from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router";
import { nextVariants } from "../../../../../../utils";
import {
    ContinueButton,
    Form,
    RateWrapper,
    Section,
    Text,
    FeeWrapper,
    DebitWrapper,
    Label,
} from "./style";
import CurrencySelector from "../../../CurrencySelector/CurrencySelector";
import { useAppSelector } from "../../../../../../../../app/hooks";
import ErrorMessage from "../../../../../../../../components/ErrorMessage";
import { ReactComponent as DebitIcon } from "../../../../../../assets/debit-icon.svg";
import { formatCurrency } from "../../../../../../../../utils/formatCurrency";
import { Link } from "react-router-dom";
import { Button } from "../../../../../../../../components/common/Button";

type SameCurrencyProps = {
    funds?: number;
    onNext?: () => void;
    sameCurrency?: string;
    transactionrate?: number;
    sendFunds?: Dispatch<SetStateAction<number>>;
    setSameCurrency?: Dispatch<SetStateAction<string>>;
    beneficiaryReceive?: number;
    rate?: number;
    transferFee?: { fees: number; currency: string };
};


const SameCurrency = ({
    funds,
    onNext,
    sendFunds,
    sameCurrency,
    beneficiaryReceive,
    transactionrate,
    rate,
    transferFee,
}: SameCurrencyProps) => {
    const { currencyCode } = useParams();
    const [error, setError] = useState(false);
    const { control } = useForm();
    const [currentWallet, setCurrentWallet] =
        useState(null);
    const [showMinimumError, setShowMinimumError] =
        useState<boolean>();

    const minimumPerCurrency = {
        "KES": 50,
        "ZAR": 100
    };

    const minValue = minimumPerCurrency[currencyCode] || 100;

    const { wallets } = useAppSelector(
        (state) => state.wallets,
    );

    useEffect(() => {
        if (funds && funds < minValue ) {
            setShowMinimumError(true);
        } else if (
            funds &&
            funds > currentWallet?.balance
        ) {
            setError(true);
            setShowMinimumError(false);
        } else {
            setShowMinimumError(false);
            setError(false);
        }
    }, [funds]);

    useEffect(() => {
        setCurrentWallet(
            wallets?.find(
                (wallet) =>
                    wallet.currency === currencyCode,
            ),
        );
    }, [wallets]);


    return (
        <AnimatePresence>
            <motion.div
                variants={nextVariants}
                initial="hidden"
                animate="visible"
            >
                <Form>
                    <Section>
                        <Controller
                            name="amount"
                            rules={{ required: true }}
                            control={control}
                            render={({ field }) => (
                                <CurrencySelector
                                    countries={[
                                        {
                                            currencyCode:
                                                currencyCode,
                                            flagCode:
                                                currencyCode,
                                        },
                                    ]}
                                    placeholder="Amount"
                                    value={funds}
                                    onValueChange={(
                                        value,
                                    ) => sendFunds(value)}
                                    selectValue={
                                        sameCurrency
                                    }
                                    arrow={true}
                                    selector={true}
                                />
                            )}
                        />

                        {showMinimumError && (
                            <div className="min">
                                <ErrorMessage
                                    message={`Minimum amount: ${sameCurrency} ${new Intl.NumberFormat().format(
                                        minValue,
                                    )}`}
                                />
                            </div>
                        )}

                        {error && (
                            <div className="min">
                                <span>
                                    Exceeds your wallet
                                    balance.
                                </span>
                                <Link to="/wallets">
                                    {" "}
                                    Top-up funds
                                </Link>
                            </div>
                        )}
                    </Section>

                    {!!beneficiaryReceive && (
                        <RateWrapper>
                            <Text>
                                <FeeWrapper>
                                    +
                                    <span>
                                        {`${transferFee?.currency} ${transferFee?.fees}`}
                                    </span>
                                </FeeWrapper>
                            </Text>
                            <Text>Processing fee</Text>
                        </RateWrapper>
                    )}

                    {typeof transactionrate === "number" &&
                        !isNaN(rate) && (
                            <RateWrapper>
                                <Text>
                                    {transactionrate?.toLocaleString(
                                        undefined,
                                        {
                                            style: "currency",
                                            currency:
                                                currencyCode,
                                        },
                                    )}
                                </Text>
                                <Text>Processing fee</Text>
                            </RateWrapper>
                        )}

                    {beneficiaryReceive !== null && (
                        <DebitWrapper>
                            <DebitIcon />
                            <Section>
                                <Label>
                                    Beneficiary gets
                                </Label>
                                <h3 className="debit">{`${
                                    beneficiaryReceive
                                        ? formatCurrency(
                                              beneficiaryReceive,
                                          )
                                        : "0.00"
                                } ${currencyCode}`}</h3>
                            </Section>
                        </DebitWrapper>
                    )}

                    <ContinueButton>
                        <span className="form-label">
                            {" "}
                            {currencyCode !== "NGN" &&
                                `Funds should arrive within 1-3 working days.`}
                        </span>

                        <Button
                            label="Continue"
                            height="48px"
                            fontSize="14px"
                            onClick={onNext}
                            disabled={
                                !funds ||
                                !sameCurrency ||
                                Number(sameCurrency) <
                                    100 ||
                                !currencyCode ||
                                error ||
                                showMinimumError
                            }
                        />
                    </ContinueButton>
                </Form>
            </motion.div>
        </AnimatePresence>
    );
};

export default SameCurrency;
