import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: min(100%, 504px);
  height: 270px;
  background: #fffbf7;
  border-radius: 8px;
  padding: 30px 22px;
  overflow-y: auto;
  font-family: "Proxima Nova";

  @media (min-width: 768px) {
    padding: 40px;
    height: 305px;
  }
`;

export const Body = styled.div``;
export const BodyHalf = styled.div`
  width: 48%;
`;

export const BodyLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 8px;
`;

export const BodyValue = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const BodyTop = styled.div`
  border-bottom: 1px solid #cccccc;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

export const BodyBottom = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
  & span {
    color: var(--primary-pink);
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  & > button {
    border: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 5px;
  }
`;

export const EmptyWrapper = styled.div`
  background: #f9f9f9;
  border: 1px solid rgba(143, 143, 143, 0.6);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min(100%, 504px);
  height: 270px;
  padding: 30px 65px;
  font-family: "Inter";

  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 18px;
  }
  & > button {
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    height: 56px;
    width: 111px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    & svg {
      margin-left: 10px;
    }
    & path {
      stroke: #000000;
    }
  }
`;

export const CardsToggleBox = styled.div`
  display: flex;
  align-items: center;
`;

export const CardsToggle = styled.button`
  border: 1px solid #8f8f8f;
  background-color: transparent;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  padding: 0;
  &:not(:last-child) {
    margin-right: 6px;
  }

  ${(props: { $isSelected: boolean }) => {
    if (props.$isSelected) {
      return css`
        border-color: #000000;
        background-color: #000000;
      `;
    }
    return css`
      border-color: #8f8f8f;
      background-color: transparent;
    `;
  }}
`;
