import styled from "styled-components";
import { Button, Upload } from "antd";

export const UploadWrapper = styled(Upload)`
  width: 100%;
  font-family: "Inter";

  & div[class~="ant-upload"] {
    width: 100%;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }
  & button:hover {
    color: black !important;
    border-color: black !important;
  }
`;

export const UploadButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  width: min(100%, 379px);
  height: 88px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  justify-content: space-between;
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 102px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
  justify-content: center;
  & > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-right: 8px;
  }
`;

export const RightWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  & > p {
    margin-bottom: 0;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 6px;
  }
  & div[class~="ant-progress-inner"] {
    background-color: #e9e9e9;
  }
  & div[class~="ant-progress-bg"] {
    background-color: #3a40dd;
    height: 4px !important;
  }
  & div[class~="ant-progress-text"] {
    display: none;
  }
`;

export const DoneText = styled.div`
  & > p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #717070;
  }
`;

export const LeftWrapper = styled.div`
  margin-right: 15px;
  & > p {
    margin-bottom: 0;
  }
`;

export const LeftNotIdle = styled.div`
  display: flex;
  align-items: center;
  & > p {
    margin-left: 12px;
  }
`;
