import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getAllVirtualAccountDetails,
  requestForAccountDetails,
} from "../../../../api";
import { Account } from "../../../../utils";
import AccountDetails from "../../../../components/AccountDetails/AccountDetails";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { Content, SlideDown } from "../AccountInfo/style";
import { useParams } from "react-router";
import InternationalVirtualAccount from "./components/VirtualAccounts/InternationalVirtualAccount";
import NGNVirutalAccount from "./components/VirtualAccounts/NGNVirutalAccount";
import { useAppSelector } from "../../../../../../app/hooks";

interface Props {
  onDone?: () => void;
  account?: Account;
  currency?: string;
  onShowAlert?: any;
  onShowSuccessAlert?: any;
}

const ReceiveFunds = ({ onDone }: Props) => {
  const { businessId } = useAppSelector(
    (state) => state.users,
  );

  const { business } = useAppSelector(
    (state) => state?.settings,
  );
  const [open, setOpen] = useState(true);
  const [activeKey, setActiveKey] = useState("1");
  const [loading, setLoading] = useState(false);
  const [virtualAccountLoading, setVirtualAccountLoading] =
    useState(false);
  const { onShowAlert: onShowSuccessAlert } = useContext(
    SuccessAlertContext,
  );
  const [showBankDetail, setShowBankDetail] =
    useState(false);
  const [virtualAccount, setVirtualAccount] = useState([]);
  const { onShowAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const { currencyCode } = useParams();
  const resolvedVirtualAccount = virtualAccount.find(
    (data) => data.currency === "NGN",
  );

  const resolvedIntVirtualAccount = virtualAccount.find(
    (data) => data.currency !== "NGN",
  );

  const handlePanelChange = () => {
    setOpen(!open);
  };

  const fetchVirtualAccount = async () => {
    setLoading(true);
    try {
      const data = await getAllVirtualAccountDetails(
        businessId,
      );
      setVirtualAccount(data);
      setShowBankDetail(true);
      setLoading(false);
    } catch (error) {
      setShowBankDetail(false);
      setLoading(true);
      setActiveKey(null);
    }
  };

  useEffect(() => {
    fetchVirtualAccount();
  }, []);

  const handleRequestAccountDetails = async (data) => {
    setVirtualAccountLoading(true);
    const commonPayload = {
      businessId,
      bvn: data.bvn
    };

    const ngnPayload = {
      ...commonPayload,
    };

    const internationalPayload = {
      ...commonPayload,
      paymentFlowDescription: data.paymentFlowDescription,
      monthlyVolume: data.monthlyVolume,
      reason: data.reason,
      KYCInformation: {
        city: String(business.city),
        state: String(business.state),
        zip: data.zip,
      },
    };

    try {
      let payload = {};
      if (currencyCode === "NGN") {
        payload = ngnPayload;
      } else {
        payload = internationalPayload;
      }

      await requestForAccountDetails(payload);
      setVirtualAccountLoading(false);
      fetchVirtualAccount();
      onShowSuccessAlert("Account requested");
    } catch (error) {
      
      setVirtualAccountLoading(false);
      onShowAlert(error, error);
    }
  };

  return (
    <Content
    open={open}
    onChange={handlePanelChange}
    defaultActiveKey={activeKey}
    >
      <SlideDown
        currencyCode={currencyCode}
        header={''}
        key="1"
      >
      {loading ? (
        "loading..."
      ) : (
      <div>
          {currencyCode === "NGN" && (
            <div>
              {resolvedVirtualAccount ? (
                <AccountDetails
                  bankName={
                  resolvedVirtualAccount?.bankName
                  }
                  accountName={
                    resolvedVirtualAccount?.accountName
                  }
                  accountNumber={
                    resolvedVirtualAccount?.accountNumber
                  }
                  accountType={
                    resolvedVirtualAccount?.accountType
                  }
                />
              ) : ( 
                <NGNVirutalAccount
                  onSubmit={
                    handleRequestAccountDetails
                  }
                  openModal={open}
                  currencyCode={currencyCode}
                  showBankDetail={showBankDetail}
                  loading={virtualAccountLoading}
                />
               )}
            </div>
            )} 
            
            {currencyCode !== "NGN" && (
              <div>
                {resolvedIntVirtualAccount ? (
                  <AccountDetails
                    bankName={
                          resolvedIntVirtualAccount?.bankName
                    }
                    accountName={
                      resolvedIntVirtualAccount?.accountName
                    }
                    accountNumber={
                      resolvedIntVirtualAccount?.accountNumber
                    }
                    accountType={
                      resolvedIntVirtualAccount?.accountType
                    }
                  />
                ) : (
                  <InternationalVirtualAccount
                    onSubmit={
                      handleRequestAccountDetails
                    }
                    loading={virtualAccountLoading}
                  />
                  )}
                </div>
            )}
        </div>
    )}
    </SlideDown>
  </Content>
  );
};

export default ReceiveFunds;
