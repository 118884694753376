import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/common/Button";
import debounce from "lodash.debounce";
import InputAndSelect from "../../../../components/common/InputAndSelect";
import { ReactComponent as RateIcon } from "../../assets/rateAddition.svg";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { formatCurrencyCodeAmount } from "../../../../utils/currency";
import { useUserData } from "../../../../app/hooks/useForm";
import { swapCurrency } from "../../redux/slice"
import { encryptAlgorithm } from "../../../../utils/encryptionAlgorithm"
import { merchantBusinessKeys } from "../../../Settings/settingsSlice"
import Loader from "../../../../components/common/Loader"
import { savePaymentData} from "../../redux/formDataSlice"
import { Heading, InputLabel, RateBox, InfoBox, FormWrapper, InputAreaWrapper } from "./styles"
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";

type AmountProps = {
  setStep: (data: string) => void;
};

const Amount: React.FC<AmountProps> = ({ setStep }) => {
  const [userData, updateField, setUserData] = useUserData();
  const isFirstRender = useRef(true);
  const { wallets, loading } = useAppSelector((state) => state?.wallets || {});
  const { onShowAlert: onShowErrorAlert } = useContext(NetworkErrorAlertContext) || {};


  const { keys } = useAppSelector(
    (state) => state.settings,
  );

  const { businessId } = useAppSelector(
    (state) => state.users,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNextStep = () => {
    navigate("/wallets/swap/currency/?type=amount");
    setStep("review");
    dispatch(savePaymentData(userData));

  };

  const filteredWallets = wallets
    .filter((wallet) => wallet.currency !== userData.destinationCurrency)
    .map((wallet) => ({
      label: wallet.currency,
      value: wallet.currency,
    }));

  const filteredDestinationWallets = wallets
    .filter((wallet) => wallet.currency !== userData.sourceCurrency)
    .map((wallet) => ({
      label: wallet.currency,
      value: wallet.currency,
    }));


  const findWalletByCurrencyBalance = wallets.find(
    (wallet) => wallet.currency === userData.destinationCurrency,
  );

  const findWalletBySourceBalance = wallets.find(
    (wallet) => wallet.currency === userData.sourceCurrency
  );

  useEffect(() => {
    if (businessId) {
      dispatch(merchantBusinessKeys(businessId));
    }
  }, [businessId, dispatch]);

  if (!wallets.length) {
    return <p>Loading wallets...</p>;
  }


  const debounceRequestQuote = useCallback(debounce(async (data) => {
    const encryptedData = encryptAlgorithm(data, keys?.data?.encryptionKey);
    const payload = { message: encryptedData };
    const action = await dispatch(swapCurrency({ message: payload, token: keys?.data?.publicKey }));

    if (swapCurrency.fulfilled.match(action)) {
      const { data } = action.payload;
      setUserData((prev) => ({
        ...prev,
        rate: data.rate,
        convertedAmount: data.sourceAmount,
        disable: false,
        quoteToken: data.quoteToken,
        publicKey: keys?.data?.publicKey,
        encryptionKey: keys?.data?.encryptionKey

      }));
    }

    else if(swapCurrency.rejected.match(action)) {
      onShowErrorAlert("", String(action.payload));

    }

  }, 1000), [dispatch, keys]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const { sourceCurrency, destinationCurrency, destinationAmount } = userData;

    if (sourceCurrency && destinationCurrency && destinationAmount > 0) {
      const data = {
        sourceCurrency,
        destinationCurrency,
        destinationAmount,
      };
      debounceRequestQuote(data);
    } else if (destinationAmount === 0 || destinationAmount === undefined) {
      setUserData((prev) => ({
        ...prev,
        rate: null,
        convertedAmount: null,
        disable: true,
      }));
    }

  }, [userData.sourceCurrency, userData.destinationCurrency, userData.destinationAmount, debounceRequestQuote]);


  return (
    <InputAreaWrapper>
      <Loader isLoading={loading} />
      <Heading>
        How much do you want to swap?
      </Heading>
      <FormWrapper>
        <div className="w-full">
          <InputLabel className="font-medium text-sm">Buying</InputLabel>
          <InputAndSelect
            options={filteredDestinationWallets}
            amountValue={userData.destinationAmount}
            onAmountChange={updateField("destinationAmount")}
            onSelectChange={updateField("destinationCurrency")}
            selectValue={userData.destinationCurrency}
            placeholder="Conversion amount"
            width="100%"

          />
          {userData.destinationCurrency && (
            <InfoBox className="text-xs font-normal text-[#8F8F8F]">
              You have{" "}
              <span className="item-content">
                {formatCurrencyCodeAmount(
                  userData.destinationCurrency, findWalletByCurrencyBalance?.availableBalance || 0
                )}
              </span>{" "}
              available in your balance.
            </InfoBox>
          )}
        </div>
        <RateBox>
          <span className="item-content">
            <RateIcon />
            {userData.rate || "0.0000"}
          </span>
          <span className="item-detail">Exchange rate</span>
        </RateBox>
        <div className="w-full">
          <InputLabel>Paying</InputLabel>
          <InputAndSelect
            options={filteredWallets}
            placeholder="Converted amount"
            onSelectChange={updateField("sourceCurrency")}
            selectValue={userData.sourceCurrency}
            amountValue={userData.convertedAmount}
            readOnly
            width="100%"
          />
          {userData.sourceCurrency && (
            <InfoBox className="text-xs font-normal text-[#8F8F8F]">
              You have{" "}
              <span className="item-content">
                {formatCurrencyCodeAmount(
                  userData.sourceCurrency,
                  findWalletBySourceBalance?.availableBalance || 0
                )}
              </span>{" "}
              available in your balance.
            </InfoBox>
          )}
        </div>
        <Button
          width="100%"
          height="48px"
          label="Continue"
          fontSize="12px"
          onClick={handleNextStep}
          disabled={loading || userData.disable || !userData.destinationAmount}
        />
      </FormWrapper>
    </InputAreaWrapper>
  );
};

export default Amount;