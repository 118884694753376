import styled from "styled-components";

interface ButtonProps {
    copied?: boolean;
    secondary?: string;
}

export const Main = styled.div`
    h1 {
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        text-align: center;
        font-weight: 500;
        line-height: 28px;
        color: #000000;
        margin: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid #f0f0f0;
    }
`;

export const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px 0;
    border-bottom: 1px solid #f0f0f0;
`;

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        margin: 0;
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: #767676;
    }

    h2 {
        color: #000000;
        font-family: Athletics;
        text-transform: capitalize;
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.4px;
        margin-top: 3px;
    }

    span {
        cursor: pointer;
    }
`;

export const Button = styled.button<ButtonProps>`
    width: 100%;
    height: 56px;
    border-radius: 8px;
    font-size: 16px;
    font-family: "Inter";
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    background: ${(props) => {
        if (props.copied) {
            return "#EF2C5A";
        } else if (props.secondary) {
            return "black";
        } else {
            return "#fff";
        }
    }};
    border: ${(props) =>
        props.copied ? "none" : "1px solid #000000"};
    color: ${(props) =>
        props.copied
            ? "#fff"
            : props.secondary
            ? "#fff"
            : "#000"};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 41, 0.05);
`;

export const NarrationWrapper = styled.div`
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 8px;
    background: #f9f9f9;
    span {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.6px; /* 130% */
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;

    > :nth-child(2) {
        margin-top: 19px;
    }
`;

export const Heading = styled.h1`
    color: #000;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
`;
