import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "Proxima Nova", sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const PageTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 40px;
`;

export const Cards = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  & > a {
    margin-bottom: 24px;
    width: 330px;
    @media (min-width: 768px) {
      margin-right: 24px;
    }
  }
`;
