import styled from "styled-components";

type Props = {
  currency?: string;
};

export const WalletSummarySection = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 1rem;
  overflow-x: scroll;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  &::-webkit-scrollbar {
    display: none; 
  }
  @media (max-width: 768px) {
    overflow: scroll;
    gap: 20px;
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const WalletSummaryCard = styled.button<Props>`
  background-color: "#FCFCFC";
  cursor: ${(props) => (props.currency === "GBP" ? "not-allowed" : "pointer")};
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  min-width: 214px;
  height: 128px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  color: #000000;

  h4 {
    font-family: "Athletics";
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin: 0;
  }

  p {
    margin: 0;
    display: flex;
    align-items: center;
    font-family: "Inter";
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &:hover {
    color: #000000;
  }
`;

export const WalletSummaryCardIcon = styled.span`
  border-radius: 50%;
  background: #e8eaee;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectedDropdownOption = styled.div`
  display: flex;
  align-items: center;

  & li {
    margin-right: 10px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin: 0;
  margin-top: 10px;

  button {
    width: 100%;
  }
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
  line-height: 32px;
  letter-spacing: -0.48px;
  font-family: "Athletics";
`;

export const Title = styled.p`
  font-family: "Athletics";
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #000000;
  margin-bottom: 20px;
`;

export const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;

  input[type="checkbox"] {
    margin: 0;
    vertical-align: middle;
  }

  p {
    margin-left: 8px;
    margin: 0;
    vertical-align: middle;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
  }
  span {
    color: #ef2c5a;
    cursor: pointer;
  }
`;

export const TernsContent = styled.div`
  h1 {
    font-family: Athletics;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: center;
  }

  p,
  ul {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #757575;
  }

  h3 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    font-weight: 700;
    color: #757575;
  }
  span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #757575;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & div {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const MobileButtonDiv = styled.div`
  display: none;
  width: 100%;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const SwapWrappBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  span {
    font-size: 12px;
    text-align: center;
  }
`;
