import React from "react";
import {
  Wrapper,
  Top,
  BoxLeft,
  BoxRight,
  Bottom,
  Item,
} from "./style";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download_icon.svg";
import TransactionStatus from "../../../transactions/components/TransctionStatus";


interface CardProps {
  wire: {
    beneficiaryName: string;
    convertedAmt: string;
    recipientPay: string;
    status: string;
    createdAt: string;
    email: string;
  },
  onShowReceipt: any;
}

const WireCard = ({
  wire,
  onShowReceipt,
}: CardProps) => {

  return (
    <Wrapper title="transaction-card">
      <Top>
        <BoxLeft>
          <p>Total Amount</p>
          <h1>{wire.convertedAmt}</h1>
        </BoxLeft>

        <BoxRight>
          <TransactionStatus text={wire.status} />
          <span onClick={() =>onShowReceipt(wire)}>
            <DownloadIcon />
          </span>
        </BoxRight>
      </Top>

      <Bottom>
        <Item>
          <BoxLeft>
            <p>Beneficiary gets</p>
            <h1>{wire.recipientPay}</h1>
          </BoxLeft>
          <BoxRight>
            <p>Email</p>
            <h2>{wire.email}</h2>
          </BoxRight>
        </Item>
        <Item>
          <BoxLeft>
            <p>Beneficiary Name</p>
            <h2>{wire.beneficiaryName}</h2>
          </BoxLeft>
          <BoxRight>
            <p>Date</p>
            <h2>{wire.createdAt}</h2>
          </BoxRight>
        </Item>
      </Bottom>
    </Wrapper>
  );
};

export default WireCard;
