import React, { useEffect, useState } from "react";
import { Header, HeaderText, Left, Right, Wrapper } from "./style";
import Contacts from "./components/Contacts";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import BusinessForm from "./components/BusinessForm";
import CustomModal from "../../common/CustomModal";
import ContactModal from "./components/Contacts/components/ContactModal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  businessCategories,
  businessTypes,
  contactRolesList,
  merchantBusinessContacts,
} from "../../../pages/Settings/settingsSlice";
import { merchantBusinessDetail } from "../../../pages/Dashboard/dashboardSlice";
import Loader from "../../common/Loader";
import { LogoInputComponent } from "./components/LogoComponent";

const Business = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [contactModalType, setContactModalType] = useState("add");
  const [mercContacts, setMercContacts] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [roleOptions, setRoleOptions] = useState([]);

  const { businessId } = useAppSelector((state) => state.users);
  const {
    business,
    contacts,
    contactRoles,
    status: settingsStatus,
  } = useAppSelector((state) => state.settings);

  useEffect(() => {
    if (!contactRoles?.length) {
      dispatch(contactRolesList());
    }
  }, []);

  useEffect(() => {
    if (contactRoles?.length) {
      const options = contactRoles.map((role) => ({
        label: role.name,
        value: role.id,
      }));
      setRoleOptions(options);
    }
  }, [contactRoles]);

  const getAllBusinessInfo = async () => {
    await dispatch(businessTypes());
    await dispatch(businessCategories());
    await dispatch(merchantBusinessDetail(businessId));
    await dispatch(merchantBusinessContacts(businessId));
  };

  useEffect(() => {
    if (businessId) {
      getAllBusinessInfo();
    }
  }, [businessId]);

  useEffect(() => {
    if (contacts) {
      setMercContacts(contacts);
    }
  }, [contacts]);


  const onAddContactClick = () => {
    setContactModalType("add");
    setSelectedContact({});
    onShowModal();
  };

  const onEditContactClick = (contact) => {
    setContactModalType("edit");
    setSelectedContact(contact);
    onShowModal();
  };

  const onShowModal = () => {
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
  };

  return (
    <Wrapper>
      <Loader isLoading={settingsStatus === "loading"} />


      <Left>
        <Header>
          <HeaderText>Business details</HeaderText>
        </Header>
          <div>
            <LogoInputComponent />
        </div>
        <BusinessForm initialBusiness={business} />
      </Left>
      <Right>
        <Header>
          <HeaderText>Contacts</HeaderText>
          {mercContacts && mercContacts.length > 0 && (
            <button onClick={onAddContactClick}>
              <span>Add new</span>
              <PlusIcon />
            </button>
          )}
        </Header>
        <Contacts
          onAddNewClick={onAddContactClick}
          onEditClick={onEditContactClick}
          contacts={mercContacts}
        />
      </Right>
      {showModal && (
        <CustomModal isModalVisible={showModal} onClose={onClose} width="464px">
          <ContactModal
            header={`${contactModalType === "add" ? "Add" : "Edit"} contact`}
            initialValues={selectedContact}
            roleOptions={roleOptions}
            onClose={onClose}
          />
        </CustomModal>
      )}
    </Wrapper>
  );
};

export default Business;
