// export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID
export const GA_TRACKING_ID = "G-H3KW4Y0JMW";

// declare const window & typeof globalThis & {
//   gtag: any;
// };

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (typeof window !== "undefined") {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value = undefined }) => {
  if (typeof window !== "undefined" && typeof window.gtag !== "undefined") {
    window.gtag("event", action, {
      event_category: category,
      event_label: label,
      value,
    });
  }
};


export function convertToSentenceCase(input: string): string {
    if (!input) {
        return input;
    }

    const lowerCased = input.toLowerCase();
    const sentenceCased = lowerCased.charAt(0).toUpperCase() + lowerCased.slice(1);
    return sentenceCased;
}

export function getFirstName(fullName: string): string {
    if (!fullName) {
        return fullName;
    }

    const firstName = fullName.split(' ')[0];
    return firstName;
}