import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
    useAppDispatch,
    useAppSelector,
} from "../../app/hooks";
import { useWindowSize } from "@reach/window-size";
import TableTools from "../../components/common/TableTools";
import DrawerContainer from "../../components/common/Drawer/Drawer";
import Account from "./components/Account";
import CustomTable from "../../components/common/CustomTable";
import { exportVirtualAccountData, tableColumns } from "./utils";
import { StyledTransactionsTable } from "../Wallets/components/TransactionsTable/style";
import TransactionDetails from "./components/TransactionDetails";
import SenderDetails from "./components/SenderDetails";
import { getVirtualAccountTransactions } from "./api";
import Loader from "../../components/common/Loader";
import { formatCurrencyAmount } from "../../utils/currency";
import EmptyVirtualAccount from "../Customers/components/EmptyVirtualAccount";
import MobileCard from "./components/MobileCard";
import { formatLongString } from "../../utils/formatString";
import EmptyMobileCard from "../../components/emptyStates/EmptyMobileCard";
import { allWallets } from "../Wallets/redux/slice";
import RequestForSettlement from "./components/RequestForSettlement";
import { selectUserRoleAndPermissions } from "../Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";

interface DateProps {
    startDate: Date;
    endDate: Date;
}

interface PageCountProps {
    pageNumber: number;
    totalCount: number;
    totalPages: number;
}

const toolTipText =
    "This balance will be automatically swept to your settlement balance at 1:00AM daily.";

const VirtualAccounts = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [date, setDate] = useState<DateProps>();
    const [allTransaction, setAllTransaction] =
        useState(null);
        const {role, permissions } = useAppSelector(
            selectUserRoleAndPermissions,
        );
        const [exportData, setExportData] = useState(null)
    const dispatch = useAppDispatch();
    const { width } = useWindowSize();
    const [pageCount, setPageCount] =
        useState<PageCountProps>();
    const [pageNumber, setPageNumber] = useState<number>(1);

    const [transactionDetail, setTransactionDetail] =
        useState(null);
    const { businessId, userId } = useAppSelector(
        (state) => state.users,
    );

    const startDate =
        date?.[0] && dayjs(date[0])?.format("YYYY-MM-DD");
    const endDate =
        date?.[1] && dayjs(date[1])?.format("YYYY-MM-DD");

    const { wallets } = useAppSelector(
        (state) => state.wallets,
    );

    const hasDownloadPermission =
        permissions?.["virtual-account"]?.includes("EXPORT_VA_TRANSACTION");


    useEffect(() => {
        if (businessId && userId) {
            dispatch(allWallets({ businessId }));
        }
    }, [businessId, userId]);

    const holdBalance = wallets.find(
        (curr) => curr?.currency === "NGN",
    );
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext
    );

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleRowClick = (row: any) => {
        setTransactionDetail(row);
        showDrawer();
    };

    const getTransactions = async () => {
        const data = {
                business: businessId,
                pageNumber: pageNumber,
                pageSize: 10,
                startDay: startDate,
                endDay: endDate,
        }
        if(!businessId){
            return null
        }

        else{
            setLoading(true);
            try {
                await getVirtualAccountTransactions(data).then((res) => {
                    setLoading(false);
                    setPageCount(res);
                    setExportData(res)
                    const tableData =
                        res.virtualAccountCollections.map(
                            (item) => ({
                                key: item.transaction.id,
                                no: item.id,
                                transaction_id: formatLongString(item.transaction.tnxRef, 10),
                                account_number:
                                    item.virtualAccountNumber,
                                amount_received:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .currency,
                                        item.transaction
                                            .amount / 100,
                                    ),
                                amount_settled:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .currency,
                                        item.transaction
                                            .settledAmount,
                                    ),
                                date: dayjs(
                                    item.transaction.createdAt,
                                )?.format("DD.MM.YYYY HH:mm"),
                                bank_name: item.bankName,
                                account_name:
                                    item.originatorAccountName,
                                origin_account:
                                    item.originatorAccountNumber,
                                not_formated_amount:
                                    item.transaction.amount /
                                    100,
                                not_formatted_settled:
                                    item.transaction
                                        .settledAmount,
                            }),
                        );
                    setAllTransaction(tableData);
                });
            } catch (error) {
                setLoading(false);
            }try {
                await getVirtualAccountTransactions(data).then((res) => {
                    setLoading(false);
                    setPageCount(res);
                    setExportData(res)
                    const tableData =
                        res.virtualAccountCollections.map(
                            (item) => ({
                                key: item.transaction.id,
                                no: item.id,
                                transaction_id:
                                    formatLongString(item.transaction.tnxRef, 10),
                                    ref: item.transaction.tnxRef,
                                account_number:
                                    item.virtualAccountNumber,
                                amount_received:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .currency,
                                        item.transaction
                                            .amount / 100,
                                    ),
                                amount_settled:
                                    formatCurrencyAmount(
                                        item.transaction
                                            .currency,
                                        item.transaction
                                            .settledAmount,
                                    ),
                                date: dayjs(
                                    item.transaction.createdAt,
                                )?.format("DD.MM.YYYY HH:mm"),
                                bank_name: item.bankName,
                                account_name:
                                    item.originatorAccountName,
                                origin_account:
                                    item.originatorAccountNumber,
                                not_formated_amount:
                                    item.transaction.amount /
                                    100,
                                not_formatted_settled:
                                    item.transaction
                                        .settledAmount,
                            }),
                        );
                    setAllTransaction(tableData);
                });
            } catch (error) {
                setLoading(false);
            }
        }
    };

    React.useEffect(() => {
        getTransactions();
    }, [pageNumber]);

    const filter = () => {
        getTransactions();
    };

    const handleReset = async () => {
        setDate(null);
    };

    const onPageChange = (page: number) => {
        setPageNumber(page);
    };

   
    const exportToXLS = async () => {
        if(hasDownloadPermission || role === "OWNER"){
            await exportVirtualAccountData(exportData, "xls");         

        }
        else{
            onShowErrorAlert("", "Access is denied")
        
        }
       
    };

    const exportToCSV = async () => {
        if(hasDownloadPermission || role === "OWNER"){
            await exportVirtualAccountData(exportData, "csv"); 

        }else{
            onShowErrorAlert("", "Access is denied")

        
        }
    };

    const filteredTransaction = allTransaction?.filter((item) =>
        Object.values(item).some(
          (val) =>
            typeof val === "string" &&
            val.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    
    

    const totalAmount =
        transactionDetail?.not_formated_amount ?? 0;        
    return (
        <div>
            <Account
                title="Virtual account balance"
                balance={formatCurrencyAmount(
                    "NGN",
                    holdBalance?.holdBalance?.toFixed(2) ||
                        0,
                )}
                tooltipText={toolTipText}
            />

            <RequestForSettlement />


            <StyledTransactionsTable>
                <h3>Transactions</h3>
                {allTransaction !== null && (
                    <TableTools
                        onSearchChange={(e) =>
                            setSearchQuery(e.target.value)
                        }
                        placeHolder="Search by account number"
                        onDateChange={(date) =>
                            setDate(date)
                        }
                        onFilterButtonClick={filter}
                        onResetButtonClick={handleReset}
                        onXLSButtonClick={exportToXLS}
                        onCSVButtonClick={exportToCSV}
                    />
                )}
                {width > 767 ? (
                    <CustomTable
                        columns={tableColumns}
                        dataSource={filteredTransaction}
                        handleRowClick={handleRowClick}
                        currentPage={pageCount?.pageNumber}
                        totalItemCount={
                            pageCount?.totalCount
                        }
                        onPageChange={onPageChange}
                        extraEmptyChildren={
                            <EmptyVirtualAccount
                                title="No transactions yet"
                                paragraph="All received transactions will be shown here."
                            />
                        }
                    />
                ) : (
                    <>
                        {allTransaction?.length === 0 ? (
                            <EmptyMobileCard
                                title="No transactions yet"
                                subText="All received or sent transactions will appear here"
                            />
                        ) : (
                            allTransaction?.map((data) => (
                                <MobileCard
                                    key={data.id}
                                    transactionRef={formatLongString(
                                        data.transaction_id,
                                        10,
                                    )}
                                    accountNumber={
                                        data.account_number
                                    }
                                    amountReceived={
                                        data.amount_received
                                    }
                                    amountSettled={
                                        data.amount_settled
                                    }
                                    date={data.date}
                                    onClick={() =>
                                        handleRowClick(data)
                                    }
                                />
                            ))
                        )}
                    </>
                )}
            </StyledTransactionsTable>

            <DrawerContainer
                title="Transaction details"
                onClose={onClose}
                open={open}
                placement={"right"}
            >
                <TransactionDetails
                    balance={formatCurrencyAmount(
                        "NGN",
                        totalAmount,
                    )}
                    transaction_id={
                        transactionDetail?.transaction_id
                    }
                    virtual_account={
                        transactionDetail?.account_number
                    }
                    amount_received={
                        transactionDetail?.amount_received
                    }
                    amount_settled={
                        transactionDetail?.amount_settled
                    }
                    date={transactionDetail?.date}
                    transaction_ref={transactionDetail?.ref}
                />
                <SenderDetails
                    account_name={
                        transactionDetail?.account_name
                    }
                    account_number={
                        transactionDetail?.origin_account
                    }
                    bank_name={transactionDetail?.bank_name}
                    
                />
            </DrawerContainer>

            <Loader isLoading={loading} />
        </div>
    );
};

export default VirtualAccounts;
