import { Input } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
    font-family: "Proxima Nova", sans-serif;
    display: ${(props: { $show: boolean }) =>
        props.$show ? "block" : "none"};
    @media (min-width: 768px) {
        display: ${(props: { $show: boolean }) =>
            props.$show ? "block" : "none"};
        align-items: center;
        justify-content: space-between;
    }
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    flex-wrap: wrap;

    button {
        padding: 12px 20px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 768px) {
        align-items: left;
        .pop_over {
            width: 100%;
            margin: auto;
        }
    }
`;

export const SearchInput = styled(Input)`
    width: 100%;
    height: 38px;
    border: 1px solid #b8b8b8;
    box-sizing: border-box;
    border-radius: 8px;

    @media (min-width: 768px) {
        width: 176px;
    }
    @media (min-width: 1024px) {
        width: 285px;
    }

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;
export const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;

    @media (min-width: 768px) {
        width: unset;
    }

    @media (max-width: 768px) {
        padding: 16px 0px 24px;
        border-bottom: none;
    }
`;

/* -------------------------------------------- */

export const FilterAndButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    /* background-color: blue; */
    /* position: relative; */
`;

export const FilterButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 98px;
    height: 40px;
    border: 1px solid #000000;
    border-radius: 6px;
    padding: 10px 17px;
    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }

    @media (min-width: 768px) {
        border-right: 1px solid #000000;
    };

font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 15.6px;
`;

export const RightButtons = styled.div`
    display: flex;
    @media (max-width: 768px) {
        margin-left: 20px;
    }
`;

export const ExportDiv = styled.div`
    margin-right: 8px;
    & > * {
        width: 112px;
    }

    & div[class~="ant-dropdown-trigger"] {
        width: 102px;
        height: 40px;
        cursor: pointer;
        border: 1px solid #000000;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 12px 18px;
        justify-content: space-between;
    }
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.6px;

    & span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
    }
    @media (min-width: 768px) {
        margin-right: 16px;
        margin-left: 16px;
    }
`;

export const RequestButton = styled.button`
    width: 128px;
    height: 40px;
    background: #000000;
    border-radius: 6px;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    :disabled {
        background-color: #f1f1f1;
        color: #717070;
    }
`;

export const FilterBox = styled.div`
    padding: 32px 24px;
    position: absolute;
    z-index: 9999;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 333px;
    right: -16px;
    bottom: unset;
    top: calc(100% + 42px);

    @media (max-width: 768px) {
        left: -30px;
    }
`;

export const FilterBoxItem = styled.div`
    margin-bottom: 24px;
`;
export const FilterBoxItemTitle = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #222223;
    margin-bottom: 8px;
`;

export const FilterBoxFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FilterFooterResetButton = styled.button`
    width: 78px;
    height: 40px;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
`;

export const FilterFooterApplyButton = styled.button`
    width: 69px;
    height: 40px;
    background: #000000;
    border-radius: 6px;
    color: #ffffff;
`;

export const DownloadButton = styled.div`


`;


export const InputWrapper = styled.div`
margin-top: 20px;

`