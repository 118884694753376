import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Wrapper,
    StepContainer,
    StepContent,
    Title,
    Paragraph,
} from "./styles";
import {
    CompanyInfo,
    CompanyDirector,
    AdditionalCompanyInfo,
    Beneficfiary,
} from "./AccountCreationSteps";
import Documents from "./AccountCreationSteps/Documents";

const COMPANY_PATH =
    "/wallets/USD/account-creation/company?=additional-data";

const steps = [
    {
        title: "Company",
        header: "Enter company details",
        subCopy: "",
    },
    {
        title: "Director",
        header: "Enter director details",
        subCopy: "",
    },
    {
        title: "Beneficiary",
        header: "Enter the ultimate beneficial information (UBO)",
        subCopy: "",
    },
];

const BASS = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [
        showAdditionalCompany,
        setShowAdditionalCompany,
    ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const additionalCompanyRef = useRef(null);
    const [showDocumentUpload, setShowDocumentUpload] = useState(false)

    useEffect(() => {
        const pathSegments = location.pathname.split("/");
        const stepIndex = steps.findIndex(
            (step) =>
                step.title.toLowerCase() ===
                pathSegments[pathSegments.length - 1],
        );

        if (stepIndex !== -1) {
            setCurrentStep(stepIndex);
        }
    }, [location.pathname]);

    const handleClickStep = (index) => {
        setCurrentStep(index);
        setShowAdditionalCompany(false);
        navigate(
            `/wallets/USD/account-creation/${steps[
                index
            ].title.toLowerCase()}`,
        );
    };

    const handleContinue = () => {
        setShowAdditionalCompany(true);
        navigate(COMPANY_PATH);
    };

    const handleNextStep = () => {
        setShowAdditionalCompany(false);
        handleClickStep(currentStep + 1);
    };

    const handAddDocuments = () => {
        setShowDocumentUpload(true)

    }

    const stepComponents = steps.map((step, index) => {
        switch (step.title) {
            case "Company":
                return (
                    <CompanyInfo
                        key={index}
                        handleNext={handleContinue}
                    />
                );
            case "Director":
                return (
                    <CompanyDirector
                        key={index}
                        handleNext={handleNextStep}
                    />
                );
            case "Beneficiary":
                return <Beneficfiary key={index}  handleNext={handAddDocuments}/>;
            default:
                return null;
        }
    });

    useEffect(() => {
        const handleBackNavigation = () => {
            if (showAdditionalCompany) {
                setShowAdditionalCompany(false);
            }
            navigate(-1);
        };

        window.addEventListener(
            "popstate",
            handleBackNavigation,
        );

        return () => {
            window.removeEventListener(
                "popstate",
                handleBackNavigation,
            );
        };
    }, [navigate, showAdditionalCompany]);

    useEffect(() => {
        if (showAdditionalCompany) {
            additionalCompanyRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [showAdditionalCompany]);

    return (
        <Wrapper>
            <StepContainer current={currentStep}>
                {steps.map((step, index) => (
                    <StepContainer.Step
                        key={index}
                        title={step.title}
                    />
                ))}
            </StepContainer>
            {!showDocumentUpload && (
                <StepContent>
                <Title ref={additionalCompanyRef}>
                    {steps[currentStep].header}
                </Title>
                <Paragraph>
                    {steps[currentStep].subCopy}
                </Paragraph>
                {!showAdditionalCompany ? (
                    stepComponents[currentStep]
                ) : (
                    <div>
                        <AdditionalCompanyInfo
                            handleNext={handleNextStep}
                        />
                    </div>
                )}
            </StepContent>
            )}
                {showDocumentUpload && (
                    <Documents />
                )}
        </Wrapper>
    );
};

export default BASS;
