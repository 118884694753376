import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createBusinessPaymentOption,
  createUpdateBankAccount,
  createUpdateBusiness,
  createUpdateBusinessContacts,
  deletePaymentOptionById,
  fetchBankAccounts,
  getAllPaymentOptions,
  getBusinessCategories,
  getBusinessContacts,
  getBusinessKeys,
  getBusinessPaymentOptions,
  getBusinessTypes,
  getContactRoles,
} from "./settingsAPI";
import { merchantBusinessDetail } from "../Dashboard/dashboardSlice";

type KeysType = {
  encryptionKey: string;
  publicKey: string;
  secretKey: string;
  id: number;
  business: null;
};

export interface BusinessType {
  address: string | null;
  bankAccounts: any[];
  businessCategory: number | null;
  businessType: number | null;
  city: string | null;
  createdAt: string;
  country: string | null;
  email: string | null;
  enabled: boolean;
  id: number;
  name: string;
  phone: string | null;
  state: string | null;
  updatedAt: string;
  website: string | null;
  zipCode: string | null;
}

export interface SettingsState {
  status: "idle" | "loading" | "failed" | "succeeded";
  businessExists: boolean;
  loading: boolean;
  error: string | null;
  bankAccounts: any[];
  keys: KeysType | null;
  business: BusinessType;
  contacts: any | null;
  busTypes: any | null;
  busCategories: any | null;
  contactRoles: any | null;
  businessCurrency: string | null;
  merchantExchangeRate: any;
  paymentOptions: any[];
  allAvailableOptions: any[];
}

const initialState: SettingsState = {
  status: "idle",
  businessExists: false,
  loading: false,
  error: null,
  bankAccounts: [],
  keys: null,
  business: {
    name: "",
    address: "",
    bankAccounts: [],
    businessCategory: undefined,
    businessType: undefined,
    city: "",
    createdAt: "",
    country: "",
    email: "",
    enabled: false,
    id: undefined,
    phone: "",
    state: "",
    updatedAt: "",
    website: "",
    zipCode: "",
  },
  contacts: null,
  busTypes: null,
  busCategories: null,
  contactRoles: null,
  businessCurrency: null,
  merchantExchangeRate: null,
  paymentOptions: [],
  allAvailableOptions: [],
};

export const merchantBankAccounts = createAsyncThunk(
  "merchant/accounts",
  async (userId: string) => fetchBankAccounts({ userId })
);

export const addUpdateBankAccount = createAsyncThunk(
  "merchant/account/new",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await createUpdateBankAccount(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addUpdateMerchantBusiness = createAsyncThunk(
  "merchant/business/upsert",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await createUpdateBusiness(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const businessTypes = createAsyncThunk("/business/types", async () =>
  getBusinessTypes()
);

export const businessCategories = createAsyncThunk(
  "/business/categories",
  async () => getBusinessCategories()
);

export const merchantBusinessContacts = createAsyncThunk(
  "merchant/business/contacts",
  async (businessId: number) => getBusinessContacts(businessId)
);

export const addUpdateBusinessContacts = createAsyncThunk(
  "business/contact/upsert",
  async (payload: any) => createUpdateBusinessContacts(payload)
);

export const contactRolesList = createAsyncThunk("contact/roles", async () =>
  getContactRoles()
);

export const merchantBusinessKeys = createAsyncThunk(
  "merchant/business/keys",
  async (businessId: number) => getBusinessKeys(businessId)
);

export const addPaymentOption = createAsyncThunk(
  "merchant/payment/options/new",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await createBusinessPaymentOption(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getMerchantExchangeRateAndPaymentOptions = createAsyncThunk(
  "merchant/payment/options",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await getBusinessPaymentOptions(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removePaymentOption = createAsyncThunk(
  "merchant/payment/options/remove",
  async ({
    businessId,
    paymentOptionId,
  }: {
    businessId: number;
    paymentOptionId: number;
  }) => deletePaymentOptionById({ businessId, paymentOptionId })
);

export const fetchAllPaymentOptions = createAsyncThunk(
  "merchant/payment/options/all",
  async () => getAllPaymentOptions()
);

export const settingsSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateBusinessObj: (state, action) => {
      state.business = action.payload;
    },
  },
 
  extraReducers: (builder) => {
    builder
      .addCase(merchantBusinessDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBusinessDetail.fulfilled, (state, action) => {
        const business = action.payload;
        state.business = business;
      })
      .addCase(merchantBusinessDetail.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(addUpdateMerchantBusiness.pending, (state) => {
        state.loading = true
      })
      .addCase(addUpdateMerchantBusiness.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;

        const business = action.payload;
        state.business = business;
      })
      .addCase(addUpdateMerchantBusiness.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(merchantBankAccounts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBankAccounts.fulfilled, (state, action) => {
        state.status = "idle";
        // Add any fetched posts to the array

        const bankAccounts = action.payload;
        const primaryAcc = bankAccounts.find((acc) => acc.primary);
        state.bankAccounts = bankAccounts;
        state.businessCurrency = primaryAcc?.currency;
      })
      .addCase(merchantBankAccounts.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(addUpdateBankAccount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUpdateBankAccount.fulfilled, (state, action) => {
        state.status = "idle";

        if (state.bankAccounts.length === 0) {
          state.businessCurrency = action.payload.currency;
        }

        const accExists = state.bankAccounts.findIndex(
          (acc) => acc.id === action.payload?.id
        );
        if (accExists !== -1) {
          state.bankAccounts.splice(accExists, 1, action.payload);
        } else {
          state.bankAccounts.push(action.payload);
        }
      })
      .addCase(addUpdateBankAccount.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(merchantBusinessContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBusinessContacts.fulfilled, (state, action) => {
        state.status = "idle";

        const contacts = action.payload;
        state.contacts = contacts;
      })
      .addCase(merchantBusinessContacts.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(addUpdateBusinessContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUpdateBusinessContacts.fulfilled, (state, action) => {
        state.status = "idle";

        const conExists = state.contacts.findIndex(
          (acc) => acc.id === action.payload?.id
        );
        if (conExists !== -1) {
          state.contacts.splice(conExists, 1, action.payload);
        } else {
          state.contacts.push(action.payload);
        }
      })
      .addCase(addUpdateBusinessContacts.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(businessTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(businessTypes.fulfilled, (state, action) => {
        state.status = "idle";

        const busTypes = action.payload;
        state.busTypes = busTypes;
      })
      .addCase(businessTypes.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(contactRolesList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(contactRolesList.fulfilled, (state, action) => {
        state.status = "idle";

        const contactRoles = action.payload;
        state.contactRoles = contactRoles;
      })
      .addCase(contactRolesList.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(businessCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(businessCategories.fulfilled, (state, action) => {
        state.status = "idle";

        const busCategories = action.payload;
        state.busCategories = busCategories;
      })
      .addCase(businessCategories.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(merchantBusinessKeys.pending, (state) => {
        state.status = "loading";
      })
      .addCase(merchantBusinessKeys.fulfilled, (state, action) => {
        state.status = "idle";

        const keys = action.payload;
        state.keys = keys;
      })
      .addCase(merchantBusinessKeys.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(getMerchantExchangeRateAndPaymentOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        getMerchantExchangeRateAndPaymentOptions.fulfilled,
        (state, action) => {
          state.status = "idle";

          const result = action.payload;
          state.merchantExchangeRate = result;
          state.paymentOptions = result.paymentOptions;
        }
      )
      .addCase(
        getMerchantExchangeRateAndPaymentOptions.rejected,
        (state, action) => {
          state.status = "idle";
        }
      )
      .addCase(addPaymentOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPaymentOption.fulfilled, (state, action) => {
        state.status = "idle";

        const result = action.payload;
        state.paymentOptions.push(result);
      })
      .addCase(addPaymentOption.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(removePaymentOption.pending, (state) => {
        state.status = "loading";
      })
      .addCase(removePaymentOption.fulfilled, (state, action) => {
        state.status = "idle";
        const newOptions = state.paymentOptions.filter(
          (opt) => opt.id !== action.payload.id
        );
        state.paymentOptions = newOptions;
      })
      .addCase(removePaymentOption.rejected, (state) => {
        state.status = "idle";
      })
      .addCase(fetchAllPaymentOptions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllPaymentOptions.fulfilled, (state, action) => {
        state.status = "idle";
        state.allAvailableOptions = action.payload;
      })
      .addCase(fetchAllPaymentOptions.rejected, (state) => {
        state.status = "idle";
      });
  },
});
export const { updateBusinessObj } = settingsSlice.actions;

export default settingsSlice.reducer;
