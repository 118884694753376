import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Flex, Main, FlexContainer, FundingButton, DetailsWrapper, ButtonWrapper, FlexBox, CopyFlex } from "./styles";
import { ReactComponent as KlashaPayIcon } from "../../assets/klasha-pay-icon.svg";
import { ReactComponent as BankTransferIcon } from "../../assets/withdrawal-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { Button } from "../../../../components/common/Button";
import { useAppSelector } from "../../../../app/hooks";
import { staticCurrencies } from "../../static";


interface AccountProps {
    accountName: string;
    accountBIC: string;
    accountIban: string;
    intermediaryBIC: string;
    intermediaryBank: string;
    navigate: (data) => void;
    handleAccountTransfer?: () => void;
}

const FundingContent = ({
    accountName,
    accountBIC,
    accountIban,
    intermediaryBIC,
    intermediaryBank,
    navigate,
    handleAccountTransfer
}: AccountProps) => {

    const { currencyCode } = useParams();
    const [copyAccount, setCopyAccount] = useState<string | null>(null);
    const [copyAll, setCopyAll] = useState<string | null>(null);


    const {  virtualAccounts } = useAppSelector(
        (state) => state?.wallets,
    );
    const findVirtualAccount = virtualAccounts.find(
        (data) => data.currency === "USD",
    );

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopyAccount(data);
        setTimeout(() => {
            setCopyAccount(null);
        }, 3000);
    };

    const onCopyAll = () => {
        const allDetails = `Account holder: ${accountName}\nAccount IBAN: ${accountIban}\nAccount BIC: ${accountBIC}\nIntermediary BIC: ${intermediaryBIC}\nIntermediary Bank: ${intermediaryBank}`;

        onCopyLinkToClipboard(allDetails);
        setCopyAll(allDetails);

        setTimeout(() => {
            setCopyAll(null);
        }, 3000);
    };
    return (
        <Main>
            <h1>Choose funding method</h1>

            <FlexContainer>
                <Flex>
                    <KlashaPayIcon aria-label="Klasha Pay Icon" />
                    <div>
                        <h3>Klasha Pay</h3>
                        <p>Fund account using different payment options</p>
                    </div>
                    <FundingButton
                        label="Proceed"
                        theme="alternate"
                        height="40px"
                        width="89px"
                        fontSize="12px"
                        onClick={navigate}
                    />
                </Flex>

                {/* Todo: combine the two checks into a component. i.e USD and KES should be in a component */}

                {currencyCode === "USD"  && (
                    <Flex>
                    <BankTransferIcon aria-label="Bank Transfer Icon" />
                    <div className={`${!findVirtualAccount ? "disabled-text" : ""}`}>
                        <div className="class-flex">
                        <h3>Bank Transfer</h3> 
                        {!findVirtualAccount && (
                        <span>Unavailable</span>

                        )}
                        </div>
                        <p>Transfer funds directly into your {currencyCode} account. Find account details below.</p>
                        
                    </div>
                </Flex>
                )}


                {staticCurrencies.includes(currencyCode) &&(
                    <Flex>
                    <BankTransferIcon aria-label="Bank Transfer Icon" />
                    <div>
                        <h3>Bank Transfer</h3>
                        <p>Transfer funds directly into our </p>
                        <p>{currencyCode} account and we will credit your wallet.</p>
                    </div>
                    <FundingButton
                        label="Proceed"
                        theme="alternate"
                        height="40px"
                        width="89px"
                        fontSize="12px"
                        onClick={handleAccountTransfer}
                    />
                </Flex>
                )}


               

                {findVirtualAccount && currencyCode === "USD" && (
                    <DetailsWrapper>
                    <h3>Your USD bank account details</h3>
    
                    <FlexBox>
                    <CopyFlex>
                        <div>
                            <p>Account holder</p>
                        </div>
                        <Flex>
                            <h2>{accountName}</h2>
                            {copyAccount === accountName ? (
                                <CheckIcon title="copied" />
                            ) : (
                                <CopyIcon
                                    title="copy"
                                    onClick={() => onCopyLinkToClipboard(accountName)}
                                />
                            )}
                        </Flex>
                    </CopyFlex>
                    <CopyFlex>
                        <div>
                            <p>Account IBAN</p>
                        </div>
                        <Flex>
                            <h2>{accountIban}</h2>
                            {copyAccount === accountIban ? (
                                <CheckIcon title="copied" />
                            ) : (
                                <CopyIcon
                                    title="copy"
                                    onClick={() => onCopyLinkToClipboard(accountIban)}
                                />
                            )}
                        </Flex>
                    </CopyFlex>
                    <CopyFlex>
                        <div>
                            <p>Account BIC</p>
                        </div>
                        <Flex>
                            <h2>{accountBIC}</h2>
                            {copyAccount === accountBIC ? (
                                <CheckIcon title="copied" />
                            ) : (
                                <CopyIcon
                                    title="copy"
                                    onClick={() => onCopyLinkToClipboard(accountBIC)}
                                />
                            )}
                        </Flex>
                    </CopyFlex>
        
                    <CopyFlex>
                        <div>
                            <p>Intermediary BIC</p>
                        </div>
                        <Flex>
                            <h2>{intermediaryBIC}</h2>
                            {copyAccount === intermediaryBIC ? (
                                <CheckIcon title="copied" />
                            ) : (
                                <CopyIcon
                                    title="copy"
                                    onClick={() => onCopyLinkToClipboard(intermediaryBIC)}
                                />
                            )}
                        </Flex>
                    </CopyFlex>
                    <CopyFlex>
                        <div>
                            <p>Intermediary Bank</p>
                        </div>
                        <Flex>
                            <h2>{intermediaryBank}</h2>
                            {copyAccount === intermediaryBank ? (
                                <CheckIcon title="copied" />
                            ) : (
                                <CopyIcon
                                    title="copy"
                                    onClick={() => onCopyLinkToClipboard(intermediaryBank)}
                                />
                            )}
                        </Flex>
                    </CopyFlex>
                </FlexBox>
    
                <ButtonWrapper>
                    <Button fontSize="12px" onClick={onCopyAll} height="40px" theme="alternate">{copyAll ? "Copied" : "Copy all account details"}</Button>
                </ButtonWrapper>
    
                    </DetailsWrapper>
                )}

            </FlexContainer>
        </Main>
    );
};

export default FundingContent;
