import React, { useContext } from "react";
import { Body, CopyButton, KeyValue, Label, Wrapper } from "./style";
import { ReactComponent as CopyIcon } from "../../../assets/copy.svg";
import { CopyAlertContext } from "../../../../../context/CopyAlertContext";

const Key = ({ label, value }: { value: string; label: string }) => {
  const { onShowAlert } = useContext(CopyAlertContext);

  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(value);
    onShowAlert();
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Body>
        <KeyValue>{value}</KeyValue>
        <CopyButton onClick={onCopyToClipboard}>
          <span>Copy</span>
          <CopyIcon />
        </CopyButton>
      </Body>
    </Wrapper>
  );
};

export default Key;
