import styled from "styled-components";

type Props = {
  disabled: boolean;
}

export const Title = styled.h1`
  font-family: "Athletics";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
  color: #000000;
  text-align: center;
  margin: 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 40px 0;
`;

export const Item = styled.div<Props>`

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px;
    color: #565C69;
    padding-left: 10px;
  }

  p {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    padding-left: 10px;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; 
    opacity: ${({disabled}) => disabled ? "0.4" : "1"};
    margin: 0;
  }
`;

