import styled from "styled-components";
import { motion } from "framer-motion";

export const PaymentWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-left: 0;
  align-items: center;
  font-family: "Proxima Nova", sans-serif;
  font-style: normal;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  backdrop-filter: blur(2px);

  @media (min-width: 1200px) {
    padding-left: 15%;
  }
`;

export const OTPText = styled.header`
  text-align: center;
  margin: 0;
  
  h1{
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.48px;
    color: #000000;
    margin-bottom: 8px;
  }

  p{
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #767676;
  }

  @media (min-width: 768px) {
    margin: 0 10px;

    h1{
      font-size: 24px;
    }
  }
`;

export const OTPInput = styled.input`
  font-family: "Inter";
  width: 100%;
  border: 1px solid #F0F0F0;
  outline: 0;
  border-radius: 8px;
  height: 48px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.3em;
  background-color: transparent;
  padding: 0 15px;
  margin: 35px 0 32px 0;
  text-align: center;
  color: #000000;

  &:focus{
    border: 1px solid var(--primary-pink);
    box-shadow: 0px 0px 5px #ef2c5961;
  }

  &::placeholder{
    font-weight: 400;
    letter-spacing: 0;
  }

  @media (min-width: 768px) {
    font-size: 22px;
  }
`
export const ResendText = styled.p`
  font-family: "Inter";
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-top: 30px;

  span {
    color: var(--primary-pink);
    cursor: pointer;

    &:hover {
      color: #f9123cb8;
    }
  }
`;

export const OTPContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 24px;
  width: 343px;
  height: 390px;
  box-shadow: 0px 0px 38px rgba(45, 66, 89, 0.16);
  border-radius: 8px;
  background: #ffffff;
  z-index: 5;

  @media (min-width: 768px) {
    width: 480px;
    height: 376px;
  }
`;

export const CloseIcon = styled.div`
  margin-right: 7px;
  align-self: flex-end;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  margin: auto;
  backdrop-filter: blur(2px);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
`;