import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 8px;
  font-family: Inter;

  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: #464646;
    margin-bottom: 0;
    display: flex;
    font-family: Inter;
    align-items: center;
  }
  & > p > span {
    display: flex;
    align-items: center;
  }
  & > p > span:first-child {
    margin-right: 8px;
  }
`;

export const HTMLTooltipContainer = styled.div`
  ul {
    padding: 0px 10px 0px 20px;
  }
`;
