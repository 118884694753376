import React, { ReactNode } from "react";
import { Wrapper } from "./style";
import CloseIcon from "../../../assets/icons/close";
import ModalHeader from "./ModalHeader";

interface CustomModalProps {
  isModalVisible: boolean;
  onClose: () => void;
  width?: string;
  height?:string;
  children?: ReactNode;
  theme?: string;
  modalTitle?: string;
  noPadding?: boolean;
}

const CustomModal = ({
  isModalVisible,
  onClose,
  width,
  children,
  theme,
  modalTitle,
  noPadding,
}: CustomModalProps) => {
  return (
    <Wrapper
      closeIcon={<CloseIcon color={theme ?? 'primary'}/>}
      onCancel={onClose}
      open={isModalVisible}
      footer={null}
      $width={width}
      $noPadding={noPadding}
      wrapClassName="custom-modal"
    >
      {theme && <ModalHeader theme={theme}>{modalTitle}</ModalHeader>}
      {children}
    </Wrapper>
  );
};

export default CustomModal;
