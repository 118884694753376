import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { Select } from "antd";

type Props = {
    width?: string;
}

export const Wrapper = styled.div`
  font-family: "Inter";
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  position: relative;

  @media (min-width: 768px) {
    width: ${({width}: Props) => width || "250px"}
  }
`

export const StyledCurrencyInput = styled(CurrencyInput)`
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 14px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  &:hover {
    border: 1px solid #a3a3a3;
  }

  &:focus {
    border: 1px solid #000000 !important;
  }
`;

export const CustomSelect = styled(Select)`
  position: absolute;
  top: 0;
  right: 0;
  width: 105px;

  div[class="ant-select-selector"] {
    height: 40px !important;
    border: 0px !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }
  span[class="ant-select-selection-item"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16px !important;
    color: #000000 !important;
    }

  span[class="ant-select-selection-placeholder"] {
    color: #000000 !important;
    line-height: 16px !important; 
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px; 
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
`;