import React from 'react'
import {  ReactComponent as PaymentSuccess} from '../../assets/icons/success-new.svg';
import {useNavigate} from "react-router-dom";

const Success = () => {
    const navigate = useNavigate();

    const Redirect = () => {
        window.location.href = "https://www.klasha.com/"
    }

   
  return (
    <div className='orange-screen'>
        <div className='orange-item'>
            <button className="close-btn" onClick={() => navigate(-1)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M15.9993 2.66663C8.65268 2.66663 2.66602 8.65329 2.66602 16C2.66602 23.3466 8.65268 29.3333 15.9993 29.3333C23.346 29.3333 29.3327 23.3466 29.3327 16C29.3327 8.65329 23.346 2.66663 15.9993 2.66663ZM20.4793 19.0666C20.866 19.4533 20.866 20.0933 20.4793 20.48C20.2793 20.68 20.026 20.7733 19.7727 20.7733C19.5193 20.7733 19.266 20.68 19.066 20.48L15.9993 17.4133L12.9327 20.48C12.7327 20.68 12.4793 20.7733 12.226 20.7733C11.9727 20.7733 11.7193 20.68 11.5193 20.48C11.1327 20.0933 11.1327 19.4533 11.5193 19.0666L14.586 16L11.5193 12.9333C11.1327 12.5466 11.1327 11.9066 11.5193 11.52C11.906 11.1333 12.546 11.1333 12.9327 11.52L15.9993 14.5866L19.066 11.52C19.4527 11.1333 20.0927 11.1333 20.4793 11.52C20.866 11.9066 20.866 12.5466 20.4793 12.9333L17.4127 16L20.4793 19.0666Z"
                        fill="black"/>
                </svg>

            </button>
            <PaymentSuccess/>
            <h1 className='title'>Your Payment was successful</h1>
            <p className='copy'>Check our <span className='web-visit' onClick={Redirect}>website</span> on how we are
                making payments acessible in Africa</p>
        </div>
    </div>
  )
}

export default Success