import React from "react";
import { ReactComponent as SendIcon } from "../assets/send.svg";
import { Body, Header, SubTitle, Title, Wrapper } from "./style";
import keyToIcon from "./utils";

interface ContactCardProps {
  url: string;
  id:
    | "twitter"
    | "whatsapp"
    | "facebook"
    | "email"
    | "instagram"
    | "linkedin"
    | "website"
    | "support"
    | "youtube";
  title: string;
  subTitle: string;
}

const ContactCard = ({ id, url, title, subTitle }: ContactCardProps) => (
  <Wrapper href={url} target="_blank" rel="noreferrer">
    <Header>
      <span>{keyToIcon[id]}</span>
    </Header>
    <Body>
      <Title>
        <p>{title}</p>
        <SendIcon />
      </Title>
      <SubTitle>{subTitle}</SubTitle>
    </Body>
  </Wrapper>
);

export default ContactCard;
