import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { Select } from "antd";

export const Wrapper = styled.div`
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-family: "Inter";

  svg:nth-child(2) {
    transform: rotate(90deg);
  }

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;

    svg:nth-child(2) {
      transform: rotate(0);
      margin: 0 20px;;
    }

    button {
      width: 98px;
    }
  }
`;

export const CustomSelect = styled(Select)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  div[class="ant-select-selector"] {
    height: 40px !important;
    border: 0px !important;
    cursor: pointer !important;
    box-shadow: none !important;
  }
  span[class="ant-select-selection-item"] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 16px !important;
    color: #000000 !important;
  }
  
  span[class="ant-select-selection-placeholder"] {
    font-size: 14px;
    color: #333;
    margin-top: 4px;
    margin-left: -1px
  }
`;

export const SelectOption = styled.div`
  display: flex;
  align-items: center;
  gap: 5px; 
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #000000;
`;

export const RateValue = styled.p`
  margin-top: 24px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #464646;
`;

export const CustomInput = styled.input`
  height: 48px;
  border: 1px solid #a3a3a3;
  border-radius: 8px;
  margin-left: 16px;
  flex: 1;
  padding: 12px 14px;

  @media (min-width: 768px) {
    margin-left: 24px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  position: relative;

  @media (min-width: 768px) {
    width: 295px;
  }
`;


export const StyledCurrencyInput = styled(CurrencyInput)`
  border: 1px solid #DBDBDB;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding-left: 14px;
`;
