import React, { useState } from "react";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
import { PaginationContainer, CardContainer, PaginationControls } from "../../../components/common/CustomMobileTable/style";
import EmptyMobileCard from "../../../components/emptyStates/EmptyMobileCard";
import { ReactComponent as EmptyTableIcon } from "../../../components/balances/assets/emptyTable.svg";
import CollectionsListCard from "../../../components/balances/BalanceListCard/CollectionsListCard";
import DrawerContainer from "../../../components/common/Drawer/Drawer";
import TransactionDetails from "../TransactionDetails";
import CustomerDetails from "../CustomerDetails";

type CustomerProps = {
    email: string;
    phone: string;
    method: string;
}

interface SettlementItem {
    key: string;
    payoutid: string;
    accBank: string;
    accNo: string;
    accName: string;
    amount: string;
    status: string;
    date: string;
    transaction_id: string;
    merchant_amount: string;
    payer_amount: string;
    currency: string;
    settlement_amount: string;
    rate: string;
    amount_less: string;
    ref: string;
    customer: CustomerProps;
}

interface BalanceListCardProps {
    payoutId: string;
    accBank: string;
    accNo: string;
    accName: string;
    amount: string;
    status: string;
    reqDate: string;
}

interface CustomMobileBalanceTableProps {
    settlementsData?: SettlementItem[];
    BalanceListCard: React.ComponentType<BalanceListCardProps>;
    currentPage?: number;
    totalItemCount?: number;
    onPageChange?: (page: number, pageSize: number) => void;
    pageSize?: number;
    pageSizeOptions?: number[];
    transactionType: string;
}

const CustomMobileBalanceTable: React.FC<CustomMobileBalanceTableProps> = ({
    settlementsData = [],
    BalanceListCard,
    currentPage,
    totalItemCount,
    onPageChange,
    pageSize,
    pageSizeOptions = [10, 20, 50],
    transactionType
}) => {
    const handlePageChange: PaginationProps["onChange"] = (page, size) => {
        onPageChange(page, size);
    };

    const [drawerData, setDrawerData] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const handleIndividualCardClick = (bal: SettlementItem) => {
        setOpenDrawer(!openDrawer)
        setDrawerData(bal)
    }


    return (
        <PaginationContainer>
            <CardContainer>
                {settlementsData.length ? (
                    transactionType === 'Collections' ? (
                        settlementsData.map((bal) => (
                            <div  key={bal.key}>
                                <CollectionsListCard
                                    key={bal.key}
                                    transactionRef={bal.ref}
                                    merchantAmount={bal.merchant_amount}
                                    payerAmount={bal.payer_amount}
                                    settlementCurrency={bal.currency}
                                    settlementAmount={bal.settlement_amount}
                                    rate={bal.rate}
                                    dateAndTime={bal.date}
                                    status={bal.status.toLowerCase()}
                                    handleCardClick={() => handleIndividualCardClick(bal)}
                                />
                            </div>
                           
                        ))
                    ) : (
                        settlementsData.map((bal) => (
                            <BalanceListCard
                                key={bal.key}
                                payoutId={bal.payoutid}
                                accBank={bal.accBank}
                                accNo={bal.accNo}
                                accName={bal.accName}
                                amount={bal.amount}
                                status={bal.status.toLowerCase()}
                                reqDate={bal.date}
                            />
                        ))
                    )

                ) : (
                    <EmptyMobileCard
                        icon={<EmptyTableIcon />}
                        title="No payout yet"
                        subText="All payout transactions will appear here."
                    />
                )}
            </CardContainer>
            <DrawerContainer
                title="Transaction details"
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                placement={"right"}
            >
                <TransactionDetails
                    key={drawerData?.key}
                    merchant_amount={drawerData?.merchant_amount}
                    reference={drawerData?.ref}
                    merchantAmount={drawerData?.merchant_amount}
                    payerAmount={drawerData?.payer_amount}
                    amountLessFess={drawerData?.amount_less || '--'}
                    currency={drawerData?.currency}
                    settledAmount={drawerData?.settlement_amount || "-"}
                    rate={drawerData?.rate}
                    date={drawerData?.date}
                    transaction_id={drawerData?.transaction_id}
                />

                <CustomerDetails
                    email={drawerData?.customer.email || '-'}
                    phone={drawerData?.customer.phone || '-'}
                    method={drawerData?.customer.method || "-"}
                />
            </DrawerContainer>
            {settlementsData.length > 0 && (
                <PaginationControls>
                    <Pagination
                        current={currentPage}
                        pageSize={pageSize}
                        total={totalItemCount}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        pageSizeOptions={pageSizeOptions}
                    />
                </PaginationControls>
            )}
        </PaginationContainer>
    )
}

export default CustomMobileBalanceTable