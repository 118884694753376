import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { uploadImage } from "../../compliance/utils";
import { CustomUpload } from "./index";
import { VerificationTag } from "../../settings/Verification/style";

type ProgressStatus = "idle" | "progress" | "done";

const statusToText = {
  PENDING: "Pending approval",
  FAILED: "Approval failed",
  "NOT UPLOADED": "Not uploaded",
  APPROVED: "Approved",
};

const SelfCustomUpload = ({
  onUpload,
  docId,
  docName,
  approvalStatus,
  uploadPath,
  onUploadFinish,
}: {
  onUpload: (newDoc: any) => void;
  onUploadFinish?: (docUrl: any, docId: any) => void;
  docName?: string;
  docId: number;
  approvalStatus?: string;
  uploadPath?: string;
}) => {
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [documentExistStatus, setDocumentExistStatus] = useState("idle");
  const [fileType, setFileType] = useState("");
  const [fileName, setFileName] = useState("");
  const [uploadingStatus, setUploadingStatus] =
    useState<ProgressStatus>("idle");
  const [documentUrl, setDocumentUrl] = useState("");

  const { businessId } = useAppSelector((state) => state.users);

  const onDocUploadFinish = (docUrl: any) => {
    if(onUploadFinish){
      onUploadFinish(docUrl, docId)
    }
  };

  const onUploadDocument = (file, docName) => {
    uploadImage({
      file,
      docName,
      setUploadingProgress: setUploadingProgress,
      setFileType: setFileType,
      setDocumentExistStatus: setDocumentExistStatus,
      setDocumentUrl: setDocumentUrl,
      setUploadingStatus: setUploadingStatus,
      businessId,
      setFileName: setFileName,
      uploadPath,
      onFinish: onDocUploadFinish,
    });
  };

  useEffect(() => {
    if (["APPROVED", "FAILED", "PENDING"].includes(approvalStatus)) {
      setUploadingStatus("done");
    }
  }, [approvalStatus]);

  useEffect(() => {
    if (uploadingStatus === "done") {
      const newDocDetail = {
        id: docId,
        fileName: fileName,
        fileType: fileType,
        uploadStatus: uploadingStatus,
        uploadProgress: uploadingProgress,
        docUrl: documentUrl,
        docExistsStatus: documentExistStatus,
      };
      onUpload(newDocDetail);
    }
  }, [uploadingStatus]);

  return (
      <CustomUpload
        name="id"
        customRequest={({ file }) => onUploadDocument(file, docName)}
        progressPercent={uploadingProgress}
        uploadingStatus={uploadingStatus}
        approvalStatus={approvalStatus}
        doneText={
          <VerificationTag $status={approvalStatus}>
            {statusToText[approvalStatus] || approvalStatus}
          </VerificationTag>
        }
      />
  );
};

export default SelfCustomUpload;
