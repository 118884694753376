import styled from "styled-components";
import { Collapse } from "antd";
const { Panel } = Collapse;

interface PanelWrapperProps {
    open?: boolean;
}


interface panelHeightProps {
    currencyCode?: string

}

export const Wrapper = styled.div`
  h1 {
    font-family: Athletics;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.4px;
    text-align: center;
  }
`;

export const NgnLabel = styled.p<PanelWrapperProps>`
  color: #767676;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
  text-align: center;
  padding-bottom: 20px;
`;

export const PanelWrapper = styled(Collapse) <PanelWrapperProps>`
  border-radius: 8px;
  padding: 7px;
  margin: 3px;
  margin-top: 15px;
  cursor: pointer;
  background: #fff;
  border: ${props => props.open ? '1px solid #000000' : '1px solid rgba(0, 0, 0, 0.1)'};

:hover{
    background: #F9F9F9;
    
}
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: none;
  }

  .box-item{
    padding: 10px 13px;
  }
`;

export const SlideDown = styled(Panel) <panelHeightProps>`

  .ant-collapse-expand-icon {
    display: none;
    border: none !important;
  }
  .ant-collapse-content {
    border: none;
  }


  .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  .ant-collapse-header-text {
    flex: auto;
    position: relative !important;
    left: -15px !important;
  }

  .ant-collapse-content > .ant-collapse-content-box {
   min-height: ${props => props.currencyCode === 'NGN' ? '141px' : 'none'};
    padding: 11px;
    position: relative;
    bottom: 34px;
  }

  button {
    margin-top: 21px;
  }
`;

export const Label = styled.p<PanelWrapperProps>`
  line-height: 16px;
  color: ${props => props.open ? '#606673' : '#B45309'};
  font-weight: 400;
  font-size: 12px;
`;

export const RequestButton = styled.div`
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;
`;

export const CompleteStepCaption = styled.h2`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-size: 20px;
`;

export const Inputs = styled.div`
  &:nth-child(2){
    margin-top: 27px;
  }

  span {
    color: var(--primary-pink);
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
  }
`;

export const RequestHeader = styled.h1`
  color: #565c69;
  font-weight: 500;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;
