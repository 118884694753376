import React from "react";
import { Wrapper } from "./style";

const ComplianceRadio = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return <Wrapper value={value}>{label}</Wrapper>;
};

export default ComplianceRadio;
