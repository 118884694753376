import React from "react";
import OtpInput from "react-otp-input";
import { ReactComponent as Close } from "./assets/close.svg";
import * as Style from "./style";
import "./style.css";

interface Props {
  value: any;
  onChange: (e: string) => void;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClick: () => void;
  smallText: string;
  buttonLabel: string;
}

const OTP = ({
  value,
  onChange,
  onClick,
  onClose,
  handleClick,
  buttonLabel,
  smallText,
}: Props) => {
  const {
    Content,
    Text,
    Button,
    ResendText,
    LargeText,
    SmallText,
    CloseIcon,
    variants,
    transition,
  } = Style;


  return (
    <Content
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={transition}
    >
      <CloseIcon onClick={onClose} data-testid="close-btn">
        <Close />
      </CloseIcon>
      <Text>
        <LargeText>Verification code</LargeText>
        <SmallText>{smallText}</SmallText>
      </Text>
      <OtpInput
        value={value}
        onChange={onChange}
        numInputs={6}
        renderSeparator={false}
        shouldAutoFocus={true}
        inputStyle="inputStyle"
        containerStyle="inputContainer"
        renderInput={(props) => <input data-testid="otp-input" {...props} />}
      />
      <Button onClick={onClick}>{buttonLabel}</Button>
      <ResendText> No code received? <span onClick={handleClick}> Resend code</span>
      </ResendText>
    </Content>
  );
};

export default OTP;
