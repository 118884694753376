export const statusOptions = [
  { value: "successful", label: "Successful" },
  { value: "pending", label: "Pending" },
  { value: "failed", label: "Failed" },
  { value: "all", label: "All" },
];

export const paymentMethods = [
  { value: "card", label: "Card" },
  { value: "banktransfer", label: "Bank transfer" },
  { value: "Wallet Popup Payment", label: "Klasha wallet" },
  { value: "mpesa", label: "M-Pesa" },
  { value: "mobilemoney", label: "Mobile money" }, 
  { value: "ussd", label: "USSD" },
  { value: "Stitch Payment", label: "Stitch payment" },
  { value: "all", label: "All" },
];

export const sourceMethods = [
  { value: "woo", label: "WooCommerce" },
  { value: "bigc", label: "BigCommerce" },
  { value: "magento", label: "Magento" },
  { value: "shopify", label: "Shopify" },
  { value: "ecwid", label: "Ecwid" },
  { value: "all", label: "All" },
];
