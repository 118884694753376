import React, {useEffect, useState} from "react";
import {
    EmptyWrapper,
    EmptyWrapperTitle,
    EmptyWrapperSubTitle,
    Header,
    Wrapper,
    BoxBody,
    CustomTable,
} from "./style";
import TitleFilter from "../../components/rates/components/TitleFilter";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import Loader from "../../components/common/Loader";
import Conversion from "../../components/rates/Conversion";
import {getCurrencyOptions} from "../../utils/currency";
import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as EmptyIcon} from '../../components/rates/assets/emptyRate.svg';
import {getExchangeRates, getRate} from "./rateAPI";
import {setBaseRate, setRates} from "./rateSlice";

const locale = {
    emptyText: (
        <EmptyWrapper>
            <EmptyIcon/>
            <EmptyWrapperTitle>No exchange rate</EmptyWrapperTitle>
            <EmptyWrapperSubTitle>Data on the current exchange rate for currency <br/>
                conversion will appear here.
            </EmptyWrapperSubTitle>
        </EmptyWrapper>
    ),
};

const tableColumns = [
    {
        title: "Source currency",
        dataIndex: "sourceCurrency",
        key: "source",
    },
    {
        title: "",
        dataIndex: '',
        key: "icon",
        render: () => <ArrowIcon/>,
    },
    {
        title: "Rate",
        dataIndex: "rate",
        key: "rate",
    },
    {
        title: "Inverse Rate",
        dataIndex: "inverseRate",
        key: "inverseRate",
    },
    {
        title: "Destination currency",
        dataIndex: "destinationCurrency",
        key: "destination",
    },
];

const ExchangeRate = () => {
    const dispatch = useAppDispatch();
    const [ratesData, setRatesData] = useState([]);
    const [search, setSearch] = useState("");
    const {businessId} = useAppSelector(
        (state) => state.users,
    );

    const [destinationCurrencyOptions, setDestinationCurrencyOptions] = useState(
        [],
    );
    const [loading, setLoading] = useState(false);
    const [sourceCurrencyOptions, setSourceCurrencyOptions] = useState([]);
    const {rates, usdRate} = useAppSelector((state) => state.rates);

    const fetchRates = async () => {
        if (!businessId) {
            return null
        } else {
            try {
                setLoading(true);
                const payload = {
                    sourceCurrency: "NGN",
                    amount: 1,
                    destinationCurrency: "USD",
                };

                const allRates = await getExchangeRates();
                dispatch(setRates(allRates));

                const rate = await getRate(payload)
                dispatch(setBaseRate((1 / rate).toFixed(6)));

                setLoading(false);
            } catch (err) {
                setLoading(false);
            }

        }
    };

    useEffect(() => {
        fetchRates();
    }, []);


    useEffect(() => {
        const destinationCurrencies = new Set();
        const sourceCurrencies = new Set();
        rates.forEach((rate) => {
            destinationCurrencies.add(rate.destinationCurrency);
            sourceCurrencies.add(rate.sourceCurrency);
        });
        const destOptions = getCurrencyOptions(Array.from(destinationCurrencies));
        const sourceOptions = getCurrencyOptions(Array.from(sourceCurrencies));

        setDestinationCurrencyOptions(destOptions);
        setSourceCurrencyOptions(sourceOptions);
    }, [rates]);

    useEffect(() => {
        const filteredData = rates?.length
            ? rates.filter(
                (item) =>
                    item.destinationCurrency
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    item.rate
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    item.sourceCurrency.toLowerCase().includes(search.toLowerCase()),
            )
            : [];

        const formattedData = filteredData.map((rate) => ({
            id: rate.id,
            key: rate.id,
            destinationCurrency: rate.destinationCurrency,
            sourceCurrency: rate.sourceCurrency,
            rate: rate?.sellingRate.toFixed(6) || "--",
            inverseRate: (1 / rate?.sellingRate).toFixed(6) || "--",
        }));

        setRatesData(formattedData);
    }, [rates, search]);


    return (
        <Wrapper>
            <Loader isLoading={loading}/>

            <Header>
                <h1>Exchange rates – {usdRate || 0} NGN = 1.00 USD </h1>
                <p>
                    Select a source currency and a destination currency to view the exchange rate
                </p>
            </Header>

            <Conversion
                destinationCurrencyOptions={destinationCurrencyOptions}
                sourceCurrencyOptions={sourceCurrencyOptions}
                setLoading={setLoading}
            />

            <BoxBody>
                {rates?.length > 0 &&
                    <TitleFilter
                        search={search}
                        setSearch={setSearch}
                    />
                }
                <CustomTable
                    columns={tableColumns}
                    dataSource={ratesData}
                    data-testid="exchange-rate-table"
                    locale={locale}
                />
            </BoxBody>
        </Wrapper>
    );
};

export default ExchangeRate;