import styled from "styled-components";
import { motion } from "framer-motion";

export const variants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

export const transition = {
  type: "spring",
  stiffness: 200,
  damping: 20,
};

export const Wrapper = styled.div`
  font-family: "Inter";
  position: fixed;
  inset: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  z-index: 10;
`;

export const Content  = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap : 20px;
  background-color: #FFFFFF;
  height: 523px;
  width: 885px;
  border-radius: 20px;
  border: 1px solid #E5E3E1;
`;

export const Close  = styled.span`
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
`;

export const Text = styled.div`
  text-align: center;

  h1 {
    font-size: 24px;
    color: #000000;
    font-weight: 500;
    margin: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #A3A3A3;
  }
`;
export const SecurityText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;

  span {
    height: 15px;
    margin-bottom: 3px;

     svg {
      height: 100%;
      object-fit: cover;
      margin-left: -15px;
    }
  }
`;


