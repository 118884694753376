import { useState } from "react";
import { useParams } from "react-router-dom";
import SuccessAlert from "../../components/common/alerts/SuccessAlert";
import NetworkErrorAlert from "../../components/common/alerts/NetworkErrorAlert";
import { Button } from "../../components/common/Button";
import { unSubscribeList } from "./UbSubscribeApi";
import { Wrapper } from "./styled";
import Loader from "../../components/common/Loader";

const UnSubscribe = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const { email, id } = useParams();

    const UbSubscribeMerchant = async () => {
        const data = {
            groupId: id,
            email: email,
        };
        setLoading(true);

        try {
            await unSubscribeList(data);
            setLoading(false);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
        } catch (error) {
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 1000);
            setLoading(false);
        }
    };

    return (
        <Wrapper>
            {error && (
                <NetworkErrorAlert message="An error occured" />
            )}
 
            <Loader isLoading={loading} />
            <div>
                <h1>
                    Do you want to unsubscribe from <br />{" "}
                    our messages?
                </h1>
                <p>
                    You’ll stop receiving messages from us.
                </p>
            </div>
            <Button
                theme="secondary"
                label="Unsubscribe"
                onClick={UbSubscribeMerchant}
            />

            {success && (
                <SuccessAlert message="Unsubscribed Successful" />
            )}
        </Wrapper>
    );
};

export default UnSubscribe;
