import React, {useState} from "react";
import { motion } from "framer-motion";
import {
  LogoMenuDiv,
  UserBox,
  Wrapper,
  DropdownMenuItem,
  Content,
  DropdownMenuButton,
  Right,
  list,
  itemVariants,
  Close,
  Logo,
} from "./style";
import { ReactComponent as KlashaLogo } from "../../../../assets/icons/logo.svg";
import { ReactComponent as NewMenuIcon } from "../../assets/newMenuIcon.svg";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as BellIcon } from "../../../../assets/icons/bell.svg";
import { ReactComponent as BackIcon } from "../../../../assets/icons/back.svg";
import { ReactComponent as UserIcon } from "../../../../assets/icons/user.svg";
import { ReactComponent as SettingIcon } from "../../assets/setting.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/icons/help.svg";
import { ReactComponent as LogoutIcon } from "../../assets/log-out.svg";
import { BusinessContextProvider } from "../../../../context/BusinessContext/BusinessContext";
import { useHeaderLogic } from "../../../../app/hooks/useHeaderLogic";

type Props = {
  onShowSide: () => void;
  setDropdownOpen: (val: boolean) => void;
};

const HeaderComponentV2 = ({ onShowSide, setDropdownOpen}: Props) => {

  const { close, path, logOut, handleNavigate, navigate } = useHeaderLogic(); 
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
    setDropdownOpen(!isOpen)
  }

  return (
    <BusinessContextProvider>
      <Wrapper>
        {close && <Logo />}
        <LogoMenuDiv>
          {!close && <span>
            <KlashaLogo />
          </span>}
          <button onClick={onShowSide}>
            <NewMenuIcon />
          </button>
        </LogoMenuDiv>

        <Right back={true}>
          <p onClick={() => navigate(-1)}>{path ? <BackIcon /> : null}</p>

          <Content>
            <BellIcon />
            <UserBox
              onClick={toggleDropdown}
              isOpen={isOpen}
            >
              <UserIcon />
              <DropdownIcon />

              <motion.ul
                className="dropdown"
                animate={isOpen ? "open" : "closed"}
                variants={list}
              >
                <DropdownMenuItem
                  variants={itemVariants}
                  onClick={() => {
                    navigate("/settings");
                    toggleDropdown
                  }}
                >
                  <SettingIcon />
                  <span>Settings</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  variants={itemVariants}
                  onClick={() => {
                    navigate("/contact");
                    toggleDropdown
                  }}
                >
                  <SupportIcon />
                  <span>Support</span>
                </DropdownMenuItem>
                <DropdownMenuButton variants={itemVariants} onClick={logOut}>
                  <LogoutIcon />
                  <span>Log out</span>
                </DropdownMenuButton>
              </motion.ul>
            </UserBox>
          </Content>
        </Right>

        {isOpen && (
          <div className="overlay" onClick={toggleDropdown} />
        )}
        {close && <Close onClick={handleNavigate} />}
      </Wrapper>
    </BusinessContextProvider>
  );
};

export default HeaderComponentV2;
