import { createSlice } from '@reduxjs/toolkit'
import { InitiateRefund, getAllRefunds, getATransactionDetails } from './actions';

const initialState = {
  loading: false, 
  error: null,
  success: false,
  errormsg: null,
  refundData: {refunds: {}}
}


const refundSlice = createSlice({
    name: 'refunds',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(InitiateRefund.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(InitiateRefund.fulfilled, (state) => {
          state.loading = false;
          state.success = true;
        })
        .addCase(InitiateRefund.rejected, (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        })
        .addCase(getAllRefunds.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(getAllRefunds.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.refundData = payload

          })
          .addCase(getAllRefunds.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          }).addCase(getATransactionDetails.pending, (state) => {
            state.loading = true;
            state.error = null;
          })
          .addCase(getATransactionDetails.fulfilled, (state, { payload }) => {
            state.loading = false;
            state.success = true;
            state.refundData = payload

          })
          .addCase(getATransactionDetails.rejected, (state, { payload }) => {
            state.loading = false;
            state.error = payload;
          })
    },
  });
  
  

export default refundSlice.reducer