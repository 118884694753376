import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  padding: 0 16px;
  font-family: "Proxima Nova", sans-serif;

  @media (min-width: 768px) {
    padding: 0 40px;
  }
`;

export const Header = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-bottom: 42px;
`;

export const InputBox = styled.div`
  display: block;
  //width: 100%;
  //margin-bottom: 24px;

  & > input {
    border: none;
    border-bottom: 1px solid #dcdcdc;
    width: 170px;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > p,
  & > div {
    margin-bottom: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 250%;
    letter-spacing: -0.03em;
    color: #000000;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    & input {
      margin: 0 12px;
    }
    & input:focus-visible {
      outline: none;
    }
  }
  @media (min-width: 768px) {
    & > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 250%;
    }
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  & button {
    width: 147px;
  }
  & button:first-child {
    margin-right: 12px;
  }
  @media (min-width: 768px) {
    margin-top: 30px;
    & button {
      width: 48%;
    }
  }
`;
