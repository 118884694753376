import React, {useState, useEffect, useContext, useCallback} from 'react'
import debounce from "lodash/debounce";
import { ContentWrapper, Header, RateBox } from '../style'
import CustomSelect from '../../../../components/common/CustomSelect';
import InputAndSelect from '../../../../components/common/InputAndSelect';
import { Button } from '../../../../components/common/Button';
import { getDefaultRate, getExchangeRate, getPayingCurrencies, getReceivingCurrenciesByCountry, getWireChargesV3 } from '../../wiresAPI';
import ErrorMessage from '../../../../components/ErrorMessage';
import { NetworkErrorAlertContext } from '../../../../context/NetworkErrorAlert';
import { formatCurrencyAmount, formatNewKwireAmount } from '../../../../utils/currency';
import { PaymentProps } from '../types';
import { useAppSelector } from '../../../../app/hooks';

const PaymentDetails = ({
    setStep,
    setBuyingRate,
    countries,
    selectedCountry, 
    setLoading, 
    amount,
    setAmount,
    setPayAmount,
    setRate,
    setCharges,
    setDuration,
    totalAmount,
    setTotalAmount,
    selectedRecCurr, 
    setSelectedRecCurr, 
    selectedPayCurr, 
    setSelectedPayCurr,
    setSelectedCountry,
    payingCurrencies,
    setPayingCurrencies,
    receivingCurrencies,
    setReceivingCurrencies,
}: PaymentProps) => {
    const minimum_usd = process.env.REACT_APP_ENV === "dev" ? 1 : 100;

    const [minAmount, setMinAmount] = useState(minimum_usd);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [convertedRate, setConvertedRate] = useState<string>(null);
    const [totalFee, setTotalFee] = useState<number>(null);
    const { onShowAlert } = useContext(NetworkErrorAlertContext);
    const { businessId } = useAppSelector((state) => state.users) || {};


    const onSendCountryChange = (value: string) => {
        setSelectedCountry(value);
        setSelectedRecCurr(null);
        setSelectedPayCurr(null);
        setPayingCurrencies([])
        setAmount('')
        setTotalAmount('')
        setConvertedRate(null)
    };

    const getAmountAndCharges = async (recCurr: string, amt: string, payingCurr: string) =>{
        if(recCurr && amt && payingCurr){
            try{
                const intAmount: number = +amt;
                setLoading(true);

                let rateAmt = intAmount;
                if(recCurr !== "USD" && payingCurr !== "USD"){
                    const rateData = await getExchangeRate(recCurr, intAmount, "USD", businessId, "KLASHA_WIRE");
                    rateAmt = rateData?.amount;
                }
                const data = await getExchangeRate(payingCurr, intAmount, recCurr, businessId, "KLASHA_WIRE")
                const feeData = await getWireChargesV3(payingCurr, recCurr, rateAmt, businessId);
                const charge = feeData?.total ?? null;                
                const highestAmt = intAmount * (1 / data?.rate) || 0;
                const totalCharge = (charge / 100) * highestAmt;

                let totalAmt = totalCharge + highestAmt;
                
                if(selectedPayCurr === 'USD'){
                    totalAmt = highestAmt + 25;
                
                }
                let calculatedFee = totalAmt - highestAmt
               if(selectedPayCurr === 'USD'){
                calculatedFee = 25;
            }
                setTotalFee(calculatedFee)              
                setRate(1 / data?.rate);
                setBuyingRate(1 / data?.buyingRate)
                setCharges(totalCharge)
                setPayAmount(highestAmt.toFixed(2));
                setConvertedRate(`${data?.rate} (${(1 / (data?.rate)).toFixed(6)})`);
                setTotalAmount(totalAmt.toFixed(2))
                setDuration(feeData?.duration || "1 - 4 Business days")

                setLoading(false)
                setIsTyping(false);
            } catch (err) {
                setLoading(false)
                onShowAlert(err, err.message);
                setIsTyping(false);
            }
        }
    }

    const receivingCurrencyChange = async (value: string) =>{
        setTotalAmount('')
        setRate(null)
        setSelectedPayCurr(null);
        setSelectedRecCurr(value);
        setConvertedRate(null)
        setMinAmount(minimum_usd);

        if (value !== "USD") {
            try {
                const ExcData = await getDefaultRate("USD", minimum_usd, value);
                const excRate = ExcData.rate || 0;

                setMinAmount(Math.ceil(excRate));
            } catch (e) {
                setMinAmount(minimum_usd);
            }
        }
    }

    const payingCurrencyChange = async (amt: string, payingCurr: string) =>{
       if(amt){
            setSelectedPayCurr(payingCurr);
       } else {
            onShowAlert(Error, "Please enter the amount your beneficiary should receive");
       }
    }

    const getReceivingCurrencies = async (value: string) => {
        try {
            const data = await getReceivingCurrenciesByCountry(value)
            const options = data.map((c) => ({
                label: c.availableCurrency,
                value: c.availableCurrency,
              }));

            setReceivingCurrencies(options)
        } catch (err) {
            onShowAlert(err, "Failed to get currencies");
        }
    }

    const getPayingCurrency = async (value: string) => {
        try {
            const data = await getPayingCurrencies(value)
            const options = data.map((c) => ({
                label: c.sourceCurrency,
                value: c.sourceCurrency,
              }));

            setPayingCurrencies(options)
        } catch (err) {
            onShowAlert(err, err.message);
        }
    }

    useEffect(() => {
        if(selectedCountry){
            getReceivingCurrencies(selectedCountry)
        }
    }, [selectedCountry]);

    useEffect(() => {
        if(selectedRecCurr){
            getPayingCurrency(selectedRecCurr)
        }
    }, [selectedRecCurr]);

    useEffect(() => {
        if (selectedRecCurr !== "GBP") {
            if (amount && parseFloat(amount) < minAmount) {
                setError(true);
                setErrorMsg(`Minimum amount: ${selectedRecCurr} ${new Intl.NumberFormat().format(minAmount)}`);
            } else if (amount && parseFloat(amount) > minAmount * 5000) {
                setError(true);
                setErrorMsg(`Maximum amount: ${selectedRecCurr} ${new Intl.NumberFormat().format(minAmount * 5000)}`);
            } else {
                setError(false);
            }
        } else {
            setError(false);
        }
    }, [amount, minAmount, selectedRecCurr])

    const debouncedSearch = useCallback(
        debounce(() => {
            getAmountAndCharges(selectedRecCurr, amount, selectedPayCurr)
        }, 1000),
        [selectedRecCurr, amount, selectedPayCurr] 
    );

    useEffect(() => {
        setIsTyping(true);
        debouncedSearch();

        return debouncedSearch.cancel;
      }, [selectedRecCurr, amount, selectedPayCurr, debouncedSearch]);

      


  return (
    <ContentWrapper>
       <Header>
        <h1>How much do you want to send?</h1>
       </Header>

       <CustomSelect
            placeholder="Beneficiary country"
            options={countries}
            value={selectedCountry}
            onChange={onSendCountryChange}
            $width="100%"
            showSearch
        />

        <div>
            <InputAndSelect
                amountValue={amount}
                selectPlaceholder='Currency'
                onAmountChange={(value: string) =>  setAmount(value)}
                width='100%'
                options={receivingCurrencies}
                selectValue={selectedRecCurr}
                onSelectChange={(value: string) =>receivingCurrencyChange(value)}
            />
            {error && <ErrorMessage message={errorMsg}/>}
        </div>

        {convertedRate && 
            <RateBox>
                <span>{convertedRate ? formatNewKwireAmount(selectedPayCurr, convertedRate): ''}</span>
                <span>Exchange rate</span>
            </RateBox>
        }

        {totalFee >= 0 && totalFee !== null && 
           ( <RateBox>
                <span>{totalAmount ? formatCurrencyAmount(selectedPayCurr, totalFee): ''}</span>
                <span>Processing fee</span>
            </RateBox>)
        }
        <div>
            <InputAndSelect
                amountValue={totalAmount}
                onAmountChange={(value: string) => setTotalAmount(value)}
                readOnly={true}
                width='100%'
                placeholder='Total amount you will be charged'
                selectPlaceholder='Currency'
                options={payingCurrencies}
                selectValue={selectedPayCurr}
                onSelectChange={(value: string) => payingCurrencyChange(amount, value)}
            />
        </div>

        <Button
            label='Next'
            height='40px'
            fontSize='12px'
            onClick={() => setStep(2)}
            disabled={!selectedCountry || !amount || !totalAmount || error || isTyping}
            className='button'
        />
    </ContentWrapper>
  )
}

export default PaymentDetails;