import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import { RoleWrapper } from "../../styles";
import Permissions from "./Permissions";
import { roleList } from "../../utils/RoleList";
import { Input } from "../../../../../components/common/Input";
import { Button } from "../../../../../components/common/Button";
import { CreateRole } from "../../redux/actions";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../components/common/Loader";
import { useNavigate } from "react-router-dom";

export interface formData {
    role: string;
    description: string;
}

const CreateRoles = () => {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<formData>() || {};
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { onShowAlert: onShowSuccessAlert } =
        useContext(SuccessAlertContext) || {};
    const { onShowAlert: onShowErrorAlert } =
        useContext(NetworkErrorAlertContext) || {};
        const { businessId } = useAppSelector(
            (state) => state.users,
        );
    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );
    const [selectedPermissions, setSelectedPermissions] =
        useState<string[]>([]);

    const handleRoleCreation = async (data: formData) => {
        const roleData = {
            roleName: data.role,
            description: data.description,
            permissions: selectedPermissions,
            businessId: businessId,
        };
        const action = await dispatch(CreateRole(roleData));
        if (CreateRole.rejected.match(action)) {
            onShowErrorAlert("", String(action.payload));
        }
        if (CreateRole.fulfilled.match(action)) {
            onShowSuccessAlert(
                "Successfully created a role",
            );
            navigate(-1)
        }
    };

    return (
        <RoleWrapper>
            <Loader isLoading={loading} />

            <h1>Create a custom role</h1>
            <div>
                <Controller
                    name="role"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Role name"
                            field={field}
                            disableLabel
                            hasError={
                                errors?.role?.type ===
                                "required"
                            }
                        />
                    )}
                />
                {errors?.role?.type === "required" && (
                    <span className="error">
                        Role is required
                    </span>
                )}
            </div>

            <div>
                <Controller
                    name="description"
                    rules={{ required: true, maxLength: 30 }}
                    control={control}
                    render={({ field }) => (
                        <Input
                            label="Description"
                            field={field}
                            disableLabel
                            hasError={
                                errors?.description?.type === "required" ||
                                errors?.description?.type === "maxLength"
                            }
                        />
                    )}
                />
                {errors?.description?.type ===
                    "required" && (
                    <span className="error">
                        Description is required
                    </span>
                )}
                {errors?.description?.type === "maxLength" && (
                <span className="error">Description must be maximum 30 characters</span>
                            )}
            </div>

            {roleList.map((role) => (
                <Permissions
                    key={role.value}
                    module={role.value}
                    selectedPermissions={
                        selectedPermissions
                    }
                    setSelectedPermissions={
                        setSelectedPermissions
                    }
                />
            ))}

            <Button
                label="Create role"
                height="40px"
                onClick={handleSubmit(handleRoleCreation)}
            />
        </RoleWrapper>
    );
};

export default CreateRoles;
