import styled from "styled-components";

export const Wrapper = styled.form`
  width: 100%;
  padding: 0 16px;
  font-family: "Proxima Nova";
`;

export const Header = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

export const Inputs = styled.div`
  width: 100%;
  margin-bottom: 42px;
`;

export const InputBox = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 24px;
  & > span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    margin-top: 4px;
    color: var(--primary-pink);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > *:first-child {
    margin-right: 10px;
  }
`;
