import styled from "styled-components";

import * as ResponsiveBreakpoint from "../../responsiveBreakpoints";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(376px, 100%);

  @media${ResponsiveBreakpoint.desktop} {
    width: min(392px, 100%);
  }
  @media${ResponsiveBreakpoint.tab} {
    width: min(429px, 100%);
  }
  @media${ResponsiveBreakpoint.smallTab} {
    width: min(454px, 100%);
  }
  @media${ResponsiveBreakpoint.mobile} {
    width: min(356px, 100%);
  }
  @media${ResponsiveBreakpoint.smallMobile} {
    width: min(344px, 100%);
  }
`;

export const TitleParagraph = styled.p`
  font-family: 'Athletics';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.48px;
  color: #191919;
  margin-bottom: 45px;
  
  @media (min-width: 768px) {
    margin-bottom: 54px;
  }
`;

export const FormStyle = styled.form`
  width: 100%;
`;

export const ForgotTextLink = styled.div`
  width: 100%;
  margin-top: 6px;

  & > * {
    font-family: "Inter";
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px; 
    text-decoration: underline;

    :hover {
      text-decoration: underline;
      color: var(--primary-pink);
    }
  }
`;

export const FormInputDiv = styled.div`
  text-align: left;
  height: ${(props: { $hasError?: boolean }) =>
    props.$hasError ? "75px" : "70px"};
  font-family: "Inter";

  & > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    margin-top: 4px;
    color: var(--primary-pink);
    text-align: left;
  }
  input {
    border: #d1d1d1;
    padding: 17px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69;
  }
`;

export const LoginButton = styled.div`
  padding-top: 32px;
  
  @media (min-width: 768px) {
    padding-top: 38px;
  }
`;

export const SignupDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 12px;
  color: #525252;
  font-weight: 400;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-family: "Inter";
  
  a{
    text-decoration: underline;
    color: #000000;

    &:hover{
      color: var(--primary-pink);
    }
  }
`;

export const LoginOTPWrapper = styled.div`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 12;
  backdrop-filter: blur(4px);
`;
