import { LinkStatus } from "./style";

type Props={
  text: string;
}

const LinkType = ({ text }: Props) => {

  return (
    <LinkStatus
      $type={text?.toLowerCase()}
      data-testid='link-type'
    >
      
      <span>
        { text?.toLowerCase() === "onetime" ? 
            "One-time" :
            text?.toLowerCase() }
      </span>
    </LinkStatus>
  );
};

export default LinkType