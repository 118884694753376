export const tableColumns = [
    {
        title: "Transaction ID",
        dataIndex: "transaction_id",
        key: "transaction_id",
    },
    {
        title: "Source",
        dataIndex: "source",
        key: "source",
    },
    {
        title: "Transaction type",
        dataIndex: "transaction_type",
        key: "transaction_type",
    },
    {
        title: "Currency",
        dataIndex: "currency",
        key: "currency",
    },
    {
        title: "Amount",
        dataIndex: "amount",
        key: "amount"
    
    },
    

    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },
];


export const payoutColumns = [
    {
        title: "Transaction ID",
        dataIndex: "transaction_id",
        key: "transaction_id",
    },
    {
        title: "Transaction amount",
        dataIndex: "amount_paid",
        key: "amount_paid",
    },
    {
        title: "Payout currency",
        dataIndex: "currency",
        key: "currency",
    },
    {
        title: "Transfer fee",
        dataIndex: "fees",
        key: "fees",
    },
    {
        title: "Exchange rate",
        dataIndex: "rate",
        key: "rate"
    
    },
    

    {
        title: "Total debit",
        dataIndex: "total_debit",
        key: "total_debit",
    },
    {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        key: "beneficiary"
    },

    {
        title: "Date",
        dataIndex: "date",
        key: "date",
    },

   
];