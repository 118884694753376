import styled from "styled-components";

export const Heading = styled.h1`
    color: var(--shades-black, #000);
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
`;

export const Title = styled.p`
    color: var(--shades-black, #000);
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
`;

export const Balance = styled.h1`
    color: var(--shades-black, #000);
    font-family: Athletics;
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
    line-height: 72px; /* 120% */
    letter-spacing: -2.4px;
`;

export const EmptyHeading = styled.h1`
    color: var(--base-black, #000);
    text-align: center;
    font-family: Athletics;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
`;

export const EmptyParagraph = styled.p`
    color: #8f8f8f;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
`;

export const SingleCustomerTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    h1 {
        margin: 0;
        font-family: Athletics;
        font-style: normal;
        line-height: 32px;
        letter-spacing: -0.4px;
    }

    .grayed-title {
        color: var(--shades-black-500, #767676);
        font-size: 20px;
    }

    .black-title {
        color: var(--shades-black, #000);
        font-size: 24px;
        font-weight: 600;
    }
`;

export const CustomerDetailBox = styled.div`
    padding: 16px;
    border-radius: 8px;
    background: #f9f9f9;
    width: 40%;
    height: 244px;
    h1 {
        color: var(--shades-black, #000);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        button {
            padding: 5px;
            font-size: 12px;
        }
        .tool-tip{
        display: none
    }
    }
`;

export const Paragraph = styled.p`
    color: #6b6b6b;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
    padding-top: 13px;
`;

export const SubHeading = styled.p`
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 540;
    line-height: 16px; /* 114.286% */
    display: flex;
    gap: 5px;
`;

export const Box = styled.div`
    margin-top: 40px;
`;

export const TooltipWrapper = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;

    .tool-tip {
        margin-bottom: 1.5rem;
    }
`;

export const ToggleWrapper = styled.div`
    padding: 15px;
`;

export const VirtualAccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;
export const Error = styled.span`
    color: red;
`;

export const FlexWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const GenerateVirtualAccountWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid #e8e4e1;
    background: var(--shades-white, #fff);
    padding: 16px;
    width: 40%;
    height: 244px;
    @media (max-width: 768px) {
        width: 100%;
        height: auto;
    }
`;

export const EmptyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    h3 {
        color: var(--base-black, #000);
        text-align: center;
        font-family: Athletics;
        font-size: 14.904px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.872px; /* 133.333% */
        letter-spacing: -0.298px;
    }
    p {
        color: #8f8f8f;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 9px; /* 114.286% */
    }
`;

export const AccountWrapper = styled.div`
    border-radius: 8px;
    h1 {
        color: var(--shades-black, #000);
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    padding: 16px 0px;
    margin-bottom: 24px;

    @media (min-width: 768px) {
        display: none;
    }
`;

export const Top = styled.div`
    padding: 0 16px;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;

    p {
        margin: 0;
        font-size: 10px;
        font-weight: 400;
        line-height: 15.6px;
        font-family: "Inter";
        color: #767676;
    }

    span {
        font-style: "Athletics";
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.4px;
        margin-top: 8px;
    }
`;

export const Bottom = styled.div`
    display: flex;
    padding: 16px 0 0 16px;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const BottomItem = styled.div`
    width: 40%;
    margin-bottom: 16px;
`;

export const BottomLabel = styled.p`
    font-family: "Inter";
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    color: #767676;
    margin-bottom: 4px;
`;

export const BottomValue = styled.p`
    font-family: "Inter";
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0;
`;

export const MobileWrapper = styled.div``;



