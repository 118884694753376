import styled from "styled-components";
import { Input } from "antd";

export const Wrapper = styled.div`
  font-family: "Inter";
  display: block;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding: 16px;
  }
`;

export const Left = styled.header`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 15px;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;

    button {
      width: 184px;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;


  @media (min-width: 768px) {
    justify-content: flex-start;
    gap: 15px;
    padding-bottom: 0;
    border-bottom: 0;
  }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: #ffffff;
  width: 94px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #000000;
  color: #000000;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 15.6px;
  }
`;

export const FilterBox = styled.div`
  padding: 32px 24px;
`;

export const FilterBoxItem = styled.div`
  margin-bottom: 24px;
`;
export const FilterBoxItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #222223;
  margin-bottom: 8px;
`;

export const FilterBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterFooterResetButton = styled.button`
  width: 78px;
  height: 40px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 6px;
`;

export const FilterFooterApplyButton = styled.button`
  width: 69px;
  height: 40px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
`;
