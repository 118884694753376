import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import {
    Agreement,
    Container,
    Logo,
    PasswordContainer,
    PasswordErrorBox,
    PasswordErrorBoxBody,
    Wrapper,
} from "../../../styles";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Button } from "../../../../../../components/common/Button";
import PasswordErrorItem from "../../../../../../components/auth/PasswordErrorItem";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import {
    ActivateTeamAccount,
    setTeamPassword,
} from "../../../redux/actions";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../../components/common/Loader";
import BrokenLink from "../BrokenLink";
import SetOtp from "../SetOtp";
import { Input } from "../../../../../../components/common/Input";

interface formData {
    password: string;
    passwordConfirmation: string;
}

const SetPassword = () => {
    const [state, setState] = useState({
        initialState: true,
        charNumberValid: false,
        specialCharValid: false,
        uppercaseValid: false,
        numberValid: false,
        passwordMatch: false,
        passwordToken: null,
        activationComplete: false,
        userEmail: null,
        brokenLink: false,
        showOTP: false,
    });
    const { handleSubmit, control, getValues, watch } =
        useForm<formData>();
    const dispatch = useAppDispatch();
    const watchAll = watch();
    const { token } = useParams();
    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );

    const { onShowAlert: onShowErrorAlert } =
        useContext(NetworkErrorAlertContext) || {};

    const checkPasswordLength = (password: string) => {
        if (password?.length >= 8) {
            handleDataUpdate("charNumberValid", true);
            return true;
        }
        handleDataUpdate("charNumberValid", false);
        return false;
    };

    const checkSpecialCharacters = (password: string) => {
        const pattern = /[ !@#$%^&*()_+={};':,.<>/?]/g;
        if (pattern.test(password)) {
            handleDataUpdate("specialCharValid", true);
            return true;
        }
        handleDataUpdate("specialCharacter", false);
        return false;
    };

    const checkUppercase = (password: string) => {
        const pattern = /[A-Z]/;
        if (pattern.test(password)) {
            handleDataUpdate("uppercaseValid", true);

            return true;
        }
        handleDataUpdate("uppercaseValid", false);
        return false;
    };

    const checkNumber = (password: string) => {
        const pattern = /\d/;
        if (pattern.test(password)) {
            handleDataUpdate("numberValid", true);
            return true;
        }
        handleDataUpdate("numberValid", false);
        return false;
    };

    const handleDataUpdate = (field, value) => {
        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    useEffect(() => {
        const pass = watchAll?.password;

        if (pass || watchAll?.passwordConfirmation) {
            checkPasswordLength(pass);
            checkNumber(pass);
            checkUppercase(pass);
            checkSpecialCharacters(pass);
            handleDataUpdate(
                "passwordMatch",
                pass === watchAll?.passwordConfirmation,
            );
        }
    }, [
        watchAll?.password,
        watchAll?.passwordConfirmation,
    ]);

    const onChangePassword = (val) => {
        handleDataUpdate("initialState", false);
        checkPasswordLength(val);
        checkNumber(val);
        checkUppercase(val);
        checkSpecialCharacters(val);
    };

    const handleSetPassword = async (data) => {
        const action = await dispatch(
            setTeamPassword({
                password: data.password,
                token: state.passwordToken,
            }),
        );
        if (setTeamPassword.rejected.match(action)) {
            onShowErrorAlert("", String(action.payload));
            handleDataUpdate("brokenLink", true);
            handleDataUpdate("activationComplete", false);
        }
        if (setTeamPassword.fulfilled.match(action)) {
            handleDataUpdate("showOTP", true);
        }
    };

    const handleActivation = async () => {
        const action = await dispatch(
            ActivateTeamAccount({ token: token }),
        );

        if (ActivateTeamAccount.rejected.match(action)) {
            handleDataUpdate("activationComplete", false);
            setState((prev) => ({
                ...prev,
                brokenLink: true,
                showOTP: false,
            }));
        }
        if (ActivateTeamAccount.fulfilled.match(action)) {
            handleDataUpdate("activationComplete", true);
            setState((prev) => ({
                ...prev,
                passwordToken:
                    action.payload?.data?.passwordToken,
                userEmail: action?.payload?.data?.userEmail,
            }));
        }
    };

    useEffect(() => {
        if (token !== undefined) {
            handleActivation();
        }
    }, [token]);


    return (
        <>
            <Loader isLoading={loading} />
            <Wrapper>
                <Logo />

                {state.activationComplete &&
                    !state.showOTP && (
                        <Container>
                            <h3>
                                Create password to join your
                                team
                            </h3>
                            <PasswordContainer>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: true,
                                        minLength: 7,
                                        maxLength: 20,
                                        pattern:
                                            /[ !@#$%^&*()_+={};':,.<>/?]/i,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            disableLabel
                                            type="password"
                                            label="Password"
                                            field={field}
                                            onChange={() => {
                                                onChangePassword(
                                                    field?.value,
                                                );
                                            }}
                                            classname={`${
                                                field?.value &&
                                                !state.charNumberValid &&
                                                !state.numberValid &&
                                                !state.passwordMatch &&
                                                !state.specialCharValid &&
                                                !state.uppercaseValid &&
                                                "password_error_style"
                                            }`}
                                        />
                                    )}
                                />

                                <Controller
                                    name="passwordConfirmation"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: {
                                            matchesPreviousPassword:
                                                (value) => {
                                                    const {
                                                        password,
                                                    } =
                                                        getValues();
                                                    setState(
                                                        (
                                                            prev,
                                                        ) => ({
                                                            ...prev,
                                                            passwordMatch:
                                                                password ===
                                                                value,
                                                        }),
                                                    );
                                                    return (
                                                        password ===
                                                        value
                                                    );
                                                },
                                        },
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            type="password"
                                            label="Confirm password"
                                            field={field}
                                            disableLabel
                                        />
                                    )}
                                />
                            </PasswordContainer>

                            {watchAll?.password?.length !==
                                undefined &&
                            watchAll?.password?.length >
                                0 ? (
                                <PasswordErrorBox
                                    $initialState={
                                        state.initialState
                                    }
                                >
                                    <PasswordErrorBoxBody>
                                        <PasswordErrorItem
                                            hasError={
                                                !state.charNumberValid
                                            }
                                            message="8-20 characters"
                                        />
                                        <PasswordErrorItem
                                            hasError={
                                                !state.numberValid
                                            }
                                            message="At least 1 number"
                                        />

                                        <PasswordErrorItem
                                            hasError={
                                                !state.passwordMatch
                                            }
                                            message="Password must match"
                                        />

                                        <PasswordErrorItem
                                            hasError={
                                                !state.specialCharValid
                                            }
                                            message="At least 1 special character(!@#$%^&*()_+-={}[]|:”;’<>?/.,)"
                                        />

                                        <PasswordErrorItem
                                            hasError={
                                                !state.uppercaseValid
                                            }
                                            message="At least 1 uppercase letter"
                                        />
                                    </PasswordErrorBoxBody>
                                </PasswordErrorBox>
                            ) : null}

                            <Button
                                label="Set password"
                                height="40px"
                                onClick={handleSubmit(
                                    handleSetPassword,
                                )}
                                className="pass-btn"
                            />

                            <Agreement>
                                By clicking ‘Join team’, I
                                acknowledge that I have read
                                and do hereby accept the
                                terms and conditions in{" "}
                                <a
                                    href="https://www.klasha.com/legal#klasha-merchant-service-agreement"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Klasha’s Merchant
                                    Agreement,
                                </a>{" "}
                                <a
                                    href="https://www.klasha.com/legal#privacy-policy"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                                ,{" "}
                                <a
                                    href="https://wa.me/+14159933858"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    and WhatsApp alert
                                    information service.
                                </a>
                            </Agreement>
                        </Container>
                    )}

                {state.brokenLink && <BrokenLink />}

                <div className="show-otp">
                    <SetOtp
                        showOTP={state.showOTP}
                        setData={setState}
                        email={state?.userEmail}
                    />
                </div>
            </Wrapper>
        </>
    );
};

export default SetPassword;
