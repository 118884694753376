import React from 'react';
import { Wrapper, StyledCurrencyInput, CustomSelect, SelectOption } from './style';
import '../CustomSelect/style.css';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrow-down.svg';
import { Select } from 'antd';

const { Option } = Select;

interface Props {
  placeholder?: string;
  selectPlaceholder?: string;
  amountValue: number | string;
  selectValue?: string;
  onSelectChange?: (value: string) => void;
  onAmountChange?: (value: string) => void;
  options?: any[];
  width?: string;
  readOnly?: boolean;
}

const InputAndSelect = ({
  placeholder,
  selectPlaceholder,
  amountValue,
  selectValue,
  onAmountChange,
  onSelectChange,
  options = [],
  width,
  readOnly,
}: Props) => {
  return (
    <Wrapper width={width}>
      <StyledCurrencyInput
        placeholder={placeholder || 'Amount'}
        value={amountValue}
        decimalsLimit={2}
        onValueChange={onAmountChange}
        readOnly={readOnly}
        min="0"
      />
      <CustomSelect
        placeholder={selectPlaceholder || ''}
        dropdownMatchSelectWidth={false}
        placement="bottomLeft"
        value={selectValue}
        onChange={onSelectChange}
        suffixIcon={<DropdownIcon />}
        popupClassName="filter-dropdown"
      >
        {Array.isArray(options) && options.length > 0 ? (
          options.map((item) => (
            <Option key={item.value} value={item.value}>
              <SelectOption>
                <span className={`currency-flag currency-flag-${item.value.toLowerCase()}`} />
                <span>{item.value}</span>
              </SelectOption>
            </Option>
          ))
        ) : null}
      </CustomSelect>
    </Wrapper>
  );
};

export default InputAndSelect;
