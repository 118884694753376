import React, { useContext } from "react";
import { AlertBox, Head, Message, Wrapper } from "./style";
import { ReactComponent as CloseIcon } from "../assets/close_small.svg";
import { CopyAlertContext } from "../../../../context/CopyAlertContext";

const AlertComponent = () => {
  const { onClose } = useContext(CopyAlertContext);
  const onClick = () => {
    onClose();
  };
  return (
    <Wrapper>
      <AlertBox>
        <button onClick={onClick}>
          <CloseIcon />
        </button>
        <Head>Copied</Head>
        <Message>Key has been copied to your clipboard</Message>
      </AlertBox>
    </Wrapper>
  );
};

const CopyAlertAlert = () => {
  return <AlertComponent />;
};

export default CopyAlertAlert;
