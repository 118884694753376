import React from "react";
import {
  Wrapper,
  AccountNumber,
  Bottom,
  Label,
  Bank,
  Country,
  Top,
  Circle,
} from "./style";
import { CardProps } from "../../../types";

const Card = ({
  bankName,
  accName,
  accNumber,
  beneficiary,
  currency,
  selectedRecipientId,
  setSelectedRecipient,
  disabled,
  width,
}: CardProps) => {
  
  return (
    <Wrapper
      width={width}
      $isSelected={selectedRecipientId === beneficiary.id}
      $disabled={disabled}
      onClick={() => setSelectedRecipient(beneficiary)}
    >
      <Top>
        <div>
          <Label>{accName}</Label>
          <AccountNumber>{accNumber}</AccountNumber>
        </div>

        <Circle 
          $isSelected={selectedRecipientId === beneficiary.id}
          $disabled={disabled}
        />
      </Top>

      <Bottom>
        <Bank>{bankName}</Bank>
        <Country>{currency}</Country>
      </Bottom>
    </Wrapper>
  );
};

export default Card;
