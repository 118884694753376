import React from 'react';
import Step from './components/Step';

const SettlementPayout = () => {
  return (
    <div>
        <Step />
    </div>
  )
}

export default SettlementPayout;