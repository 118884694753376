import styled from "styled-components";
import { Link } from 'react-router-dom';
import { ReactComponent as BackLines2 } from "../Dashboard/assets/background-lines-2.svg";
import { ReactComponent as LeftBackLine1 } from "../Dashboard/assets/left-backgroundLines1.svg";
import { ReactComponent as LeftBackLine2 } from "../Dashboard/assets/left-backgroundLines2.svg";
import { ReactComponent as RightBackLine1 } from "../Dashboard/assets/right-backgroundLines1.svg";
import { ReactComponent as RightBackLine2 } from "../Dashboard/assets/right-backgroundLines2.svg";

interface TopDivProps {
    $businessStatus: "active" | "pending" | "disapproved";
  }
  
  

export const Wrapper = styled.div`
  padding: unset 32px;
`;

export const WelcomeTitle = styled.h3`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.48px;
  color: #000000;
`;

export const SubtitleParagraph = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--primary-black);
  margin-top: 8px;
  margin-bottom: 0;
  @media (max-width: 375px) {
    margin-top: 16px;
  }
`;

export const BackLinesLeft1 = styled(LeftBackLine1)`
  position: absolute;
  left: 0;
  `;

export const BackLinesLeft2 = styled(LeftBackLine2)`
  position: absolute;
  left: 0;
`;

export const BackLinesRight1 = styled(RightBackLine1)`
  position: absolute;
  right: 0;
`;

export const BackLinesRight2 = styled(RightBackLine2)`
  position: absolute;
  right: 0;
`;

export const BottomBackLinesMobile = styled(BackLines2)`
  position: absolute;
  left: 0;
  top: 0;
`;

export const TopDiv = styled.div<TopDivProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
  background: #fafafa;
  border-radius: 8px;
  margin: 32px 0px;
  cursor: pointer;
  background-color: ${(props) =>
    props.$businessStatus === "active"
      ? "#ECFDF5"
      : props.$businessStatus === "pending"
      ? "#E6EFFA"
      : "#FFE8EC"};

  border: 1px solid
    ${(props) =>
      props.$businessStatus === "active"
        ? "#2CC962"
        : props.$businessStatus === "pending"
        ? "#3076FF"
        : "#F9123B"};

  a {
    color: #565c69;
    text-decoration: underline;
  }
`;


export const InfoSection = styled.div`
  flex: 1;
`;

export const InfoSectionTitle = styled.h3`
  font-family: "Athletics";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #0a0a0a;
  margin: unset;
`;

export const InfoSectionText = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565c69;
  margin: unset;
  margin-top: 4px;
`;

export const MiddleDiv = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 32px;
  font-family: "Inter";

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const LeftMiddleDiv = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
  min-height: 264px;
  position: relative;
  padding: 16px;
`;

export const MobileTopDiv = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 314px;
  margin-bottom: 32px;
  padding: 0 30px;

  p{
    font-family: "Inter";
    color: #313131;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
  }

  @media (min-width: 1200px){
    display: none;
  }
`;

export const MobileBottomDiv = styled.div`
  background: #fef8f2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 314px;
  padding: 0 40px;
  position: relative;

  p{
    font-family: "Inter";
    color: #313131;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
  }

  @media (min-width: 1200px){
    display: none;
  }
`;

export const RightMiddleDiv = styled.div`
  background: #fef8f2;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  flex: 1;
  min-height: 264px;
  position: relative;
`;

export const MiddleDivTitle = styled.h2`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  margin: unset;
  
  @media (min-width: 576px){
    font-size: 24px;
  }

`;

export const MiddleDivText = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #313131;
  margin: unset`;

  export const MiddleDivLink = styled(Link || 'a')`
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: -0.02em;
  font-feature-settings: "cv03" on, "cv04" on, "liga" off;
  color: #ef2c5a;

  &:hover {
    color: #ef2c59b4;
  }
`;

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 310px;
  padding: 31px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  text-align: center;

  @media (max-width: 1200px) {
    display: none;
  }
`;

export const BottomDivHeading = styled.h3`
  font-family: "Athletics";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
`;

export const BottomTitleParagraph = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #313131;
  max-width: 505px;

`;

export const BottomLogoDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const PayButton = styled.a`
  padding: 12px 20px;
  height: 40px;
  background-color: var(--primary-pink);
  border-radius: 6px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "cv03" on, "cv04" on, "liga" off;
  color: #ffffff;
  margin-top: 16px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 41, 0.05);

  &:hover{
    color: #ffffff;
    background-color: #ef2c59e1;
  }
`;