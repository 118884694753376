import api from "../app/api";

export const listUserByEmail = async (userEmail: string) => {
  return api.get(`auth/userdetail/${userEmail}`);

};

export const getMerchant = async (userId: string) => {
  return api.get(`merchant/user/${userId}`);
};

export async function sendOTP(email){
  const {data} = await api.get(`/auth/v2/generic/otp/${email}`);
  return data;
}

export const resendOTP = async (user: { email: string; }) => {
    return await api.get(`/auth/v2/generic/otp/${user.email}`);
};

export const verifyOTP = async (values, email) => {
     const { data } = await api.get(
          `/auth/user/email/confirmToken/${values}/${email}`,
        );
    return data;

};

