import React, { useState } from "react";
import { Dropdown, Menu, Popover } from "antd";
import {
    FilterContainer,
    Left,
    Right,
    Wrapper,
    ExportDiv,
    FilterAndButtons,
    FilterBox,
    FilterBoxFooter,
    FilterBoxItem,
    FilterBoxItemTitle,
    FilterButton,
    FilterFooterApplyButton,
    FilterFooterResetButton,
    RightButtons,
    SearchInput,
    DownloadButton,
    InputWrapper,
} from "./style";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

import CustomDateRangePicker from "../CustomDateRangePicker";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/arrow-down.svg";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import CustomSelect from "../CustomSelect";
import { Button } from "../Button";
import CurrencyInput from "../CurrencyInput/CurrencyInput";

const FilterContent = ({
    onDateChange,
    onFilterButtonClick,
    onResetButtonClick,
    onStatusChange,
    amountValueChange,
    amountValue,
    options,
    statusValue,
    setState,
    showAmount,
}: {
    onFilterButtonClick: () => void;
    onResetButtonClick: () => void;
    onStatusChange: (val) => void;
    amountValueChange?: (data: any) => void
    onDateChange: (val, val2) => void;
    amountValue?: any;
    statusOptions?: (data) => void;
    options?: {label: string; value: string}[];
    setState?: (data) => void;
    showAmount?: boolean;
    statusValue?:string
}) => {
    const [selectedDate, setSelectedDate] = useState([]);
    const onResetClick = () => {
        onResetButtonClick();
        setSelectedDate([]);
    };

    const onDateRangeChange = (dates, dateString) => {
        onDateChange(dates, dateString);
        setSelectedDate(dates);
    };
    const disabledDate: RangePickerProps["disabledDate"] = (
        current,
    ) => {
        return current && current > dayjs().endOf("day");
    };
    return (
        <FilterBox>
            <FilterBoxItem>
                <FilterBoxItemTitle>
                    Date range
                </FilterBoxItemTitle>
                <CustomDateRangePicker
                    value={selectedDate}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDate}
                />
                {setState && (
                    <InputWrapper>
                        <CustomSelect
                            placeholder="Status"
                            value={statusValue}
                            options={options}
                            onChange={onStatusChange}
                        />
                    </InputWrapper>
                )}

                {showAmount && (
                    <InputWrapper>
                        <CurrencyInput
                            value={amountValue}
                            onChange={amountValueChange}
                        />
                    </InputWrapper>
                )}
            </FilterBoxItem>
            <FilterBoxFooter>
                <FilterFooterApplyButton
                    onClick={onFilterButtonClick}
                >
                    Filter
                </FilterFooterApplyButton>
                <FilterFooterResetButton
                    onClick={onResetClick}
                >
                    Reset
                </FilterFooterResetButton>
            </FilterBoxFooter>
        </FilterBox>
    );
};

interface TitleFilterProps {
    onStatusChange?: (val) => void;
    onDateChange?: (val, val2) => void;
    onSearchChange?: (val) => void;
    onXLSButtonClick?: () => void;
    onCSVButtonClick?: () => void;
    onFilterButtonClick?: () => void;
    onResetButtonClick?: () => void;
    onSelectChange?: any;
    select?: string;
    placeHolder?: string;
    hideFilter?: boolean;
    hideSearch?: boolean;
    amountValue?: number;
    setState?: (data) => void;
    showAmount?: boolean;
    options?: {label: string, value: string}[]
    amountValueChange?: (data) => void
    handleNext?: (data) => void
    statusValue?:string
}
const TableTools = ({
    onXLSButtonClick,
    onCSVButtonClick,
    onResetButtonClick,
    onFilterButtonClick,
    onDateChange,
    onStatusChange,
    onSearchChange,
    placeHolder,
    hideFilter,
    hideSearch,
    amountValue,
    setState,
    showAmount,
    options,
    handleNext,
    amountValueChange,
    statusValue
}: TitleFilterProps) => {
    return (
        <Wrapper $show={true}>
            <FilterContainer>
                {!hideSearch && (
                    <Left>
                       {handleNext && (
                        <Button  width="148px"
                        className="refund-cta"
                        onClick={handleNext}

                height="40px" theme="secondary" label="Create new refund"/>
                        )}
                        <SearchInput
                            prefix={<SearchIcon />}
                            onChange={onSearchChange}
                            placeholder={placeHolder}
                        />
                    </Left>
                )}

                <Right>
                    <FilterAndButtons>
                        <Popover
                            content={
                                <FilterContent
                                    onStatusChange={
                                        onStatusChange
                                    }
                                    amountValue={
                                        amountValue
                                    }
                                    statusValue={statusValue}
                                    amountValueChange={amountValueChange}
                                    setState={setState}
                                    showAmount={showAmount}
                                    onDateChange={
                                        onDateChange
                                    }
                                    onFilterButtonClick={
                                        onFilterButtonClick
                                    }
                                    onResetButtonClick={
                                        onResetButtonClick
                                    }
                                    options={options}
                                />
                            }
                            trigger="click"
                            className="pop_over"
                        >
                            {!hideFilter && (
                                <FilterButton>
                                    <span>Filter</span>
                                    <FilterIcon />
                                </FilterButton>
                            )}
                        </Popover>

                        <RightButtons>
                            <ExportDiv>
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            <Menu.Item>
                                                <DownloadButton
                                                    onClick={
                                                        onCSVButtonClick
                                                    }
                                                >
                                                    Export
                                                    CSV
                                                </DownloadButton>
                                            </Menu.Item>
                                            <Menu.Item>
                                                <DownloadButton
                                                    onClick={
                                                        onXLSButtonClick
                                                    }
                                                >
                                                    Export
                                                    XLS
                                                </DownloadButton>
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <div>
                                        <span>Export</span>
                                        <DropdownIcon />
                                    </div>
                                </Dropdown>
                            </ExportDiv>
                        </RightButtons>
                    </FilterAndButtons>
                </Right>
            </FilterContainer>
        </Wrapper>
    );
};

export default TableTools;
