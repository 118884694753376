import React from "react";
import {
    Container,
    BrokenLinkImage,
    Title,
    SubTitle,
} from "../../styles";

const BrokenLink = () => {
    return (
        <div>
            <Container>
                <BrokenLinkImage />
                <Title>Invitation link broken</Title>
                <SubTitle>
                    This invitation link is no longer valid
                    or accessible. Please contact the business admin.
                </SubTitle>
            </Container>
        </div>
    );
};

export default BrokenLink;
