import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Inputs } from "../PaymentLinkSuccess/style";
import { Input } from "../../../../../../../../components/common/Input";
import { Button } from "../../../../../../../../components/common/Button";

interface FormInputs {
  reason: string;
  monthlyVolume: string;
  paymentFlowDescription: string;
  zip?: string;
}

interface Props {
  onSubmit?: (data: FormInputs) => void;
  loading?: boolean;
}

const InternationalVirtualAccount = ({
  onSubmit,
  loading,
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>();

  return (
    <div>
      <Inputs>
        <h1>Reasons for account details</h1>

        <Controller
          name="reason"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <Input
              type="text"
              label="Enter brief why your need a corporate account"
              field={field}
              labelColor="#FFFFFF"
              disableLabel
            />
          )}
        />

        {errors.reason?.type === "required" && (
          <span className="error">
            Please tell us why you need a corporate account
          </span>
        )}
      </Inputs>

      <Inputs>
        <h1>What is your monthly transaction volume?</h1>
        <Controller
          name="monthlyVolume"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <Input
              type="number"
              label="What is your monthly transactions?"
              field={field}
              labelColor="#FFFFFF"
              disableLabel
            />
          )}
        />
        {errors.reason?.type === "required" && (
          <span className="error">
            Please tell us your monthly transaction details
          </span>
        )}
      </Inputs>

      <Inputs>
        <h1>What is your zipCode?</h1>
        <Controller
          name="zip"
          rules={{
            required: "Please enter your zipCode",
          }}
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              label="What is your zipCode?"
              field={field}
              labelColor="#FFFFFF"
              disableLabel
            />
          )}
        />
        {errors.zip?.type === "required" && (
          <span className="error">
            {errors.zip.message}
          </span>
        )}
      </Inputs>

      <Inputs>
        <h1>Payment flow description</h1>

        <Controller
          name="paymentFlowDescription"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              label="Describe how often you get payment"
              field={field}
              labelColor="#FFFFFF"
              disableLabel
            />
          )}
        />
        {errors.reason?.type === "required" && (
          <span className="error">
            please describe how often you receive payment
          </span>
        )}
      </Inputs>

      <Button
        theme="secondary"
        height="40px"
        fontSize="12px"
        label={
          loading ? "loading..." : `Request account details`
        }
        type="submit"
        onClick={handleSubmit((data) => {
          onSubmit(data);
        })}
      />
    </div>
  );
};

export default InternationalVirtualAccount;
