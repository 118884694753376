import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { StyledTransactionsTable, ToggleContainer } from "./styles";
import ToggleButton from "../../../components/common/ToggleButton/ToggleButton";
import Team from "./components/Members/Team";
import { Roles } from "./components/Roles";
import { Audit } from "./components/AuditLogs";

const Main = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toggleType = [
        "Team members",
        "Roles",
    ];
    const [data, setData] = useState({
        activeToggle: "Team members",
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const activeToggleParam = searchParams.get("activeToggle");
        if (activeToggleParam && toggleType.includes(activeToggleParam)) {
            setData({ activeToggle: activeToggleParam });
        }
    }, []);

    const handleDataUpdate = (field, value) => {
        setData(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleToggle = (data) => {
        handleDataUpdate("activeToggle", data);
        navigate(`/settings/team?activeToggle=${data}`);
    };

    const toggleComponentMap = {
        "Team members": Team,
        "Roles": Roles,
        "Audit logs": Audit
    };

    const ActiveToggleComponent = toggleComponentMap[data.activeToggle] || null;

    return (
        <StyledTransactionsTable>
            <ToggleContainer>
                <ToggleButton
                    toggleType={toggleType}
                    activeToggle={data.activeToggle}
                    setActiveToggle={(data) =>
                        handleDataUpdate("activeToggle", data)
                    }
                    handleToggle={handleToggle}
                />
            </ToggleContainer>
            {ActiveToggleComponent && (
                <ActiveToggleComponent />
            )}
        </StyledTransactionsTable>
    );
};

export default Main;
