import React from "react";
import { Wrapper } from "./style";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download_icon.svg";

type Props = {
  downloadXLS: any;
  downloadCSV: any;
  customButtonTitle?: string;
};

const Export = ({ downloadCSV, downloadXLS, customButtonTitle }: Props) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span onClick={downloadCSV}>Export CSV</span>,
    },
    {
      key: "2",
      label: <span onClick={downloadXLS}>Export XLS</span>,
    },
  ];

  return (
    <Wrapper>
      <Dropdown menu={{ items }} placement="bottom">
        <div>
          <span>{customButtonTitle ?? 'Export'}</span>
          <DownloadIcon />
        </div>
      </Dropdown>
    </Wrapper>
  );
};

export default Export;
