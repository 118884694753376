import React, { useCallback, useEffect, useState } from "react";
import debounce from "lodash/debounce";
import {
    Cards,
    CheckoutSearch,
    Left,
    PageTitle,
    Right,
    SearchInput,
    SubTitle,
    Wrapper,
} from "./style";
import { ReactComponent as SearchIcon } from "./assets/search.svg";

import CheckoutCard from "../../components/checkout/CheckoutCard";
import { cards, FilterByTitle } from "./utils";

const KlashaCheckout = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState(cards);

    const debouncedSearch = useCallback(
        debounce(() => {
            const newList = FilterByTitle(searchQuery);
            setFilteredList(newList);
        }, 1000),
        [searchQuery] // will be created only once initially
    );
    const onChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        debouncedSearch();
        return debouncedSearch.cancel;
    }, [searchQuery, debouncedSearch]);

    return (
        <Wrapper>
            <CheckoutSearch>
                <Left>
                    <PageTitle>Klasha pay</PageTitle>
                    <SubTitle>
                        Klasha pay integrates seamlessly into a host of e-commerce
                        platforms through our API, allowing you to accept payments online
                        from Africa with ease.
                    </SubTitle>
                </Left>
                <Right>
                    <SearchInput
                        prefix={<SearchIcon />}
                        onChange={onChange}
                        placeholder="Search for platform"
                    />
                </Right>
            </CheckoutSearch>
            <Cards>
                {filteredList.map((card) => (
                    <CheckoutCard
                        key={card.key}
                        title={card.title}
                        description={card.description}
                        url={card.url}
                        id={card.key}
                    />
                ))}
            </Cards>
        </Wrapper>
    );
};

export default KlashaCheckout;
