import React from 'react';
import { Module, PermissionItem, PermissionTitle } from '../../styles';
import Toggle from '../../../../../components/common/Toggle/Toggle';
import { APIKeysPermissions, BankAccountPermissions, CardPermissions, CollectionPermissions, CustomersPermissions, DashboardPermissions, ExchangePermissions, PaymentLinkPermissions, TeamPermissions, TransactionsPermissions, VirtualAccountPermissions, WalletPermissions, WirePermissions } from '../../utils/RoleList';
import { removeHyphensAndDashes, titleCase } from '../../utils/capitalizeName';

interface PermissionItem {
    id: number;
    title: string;
}

interface PermissionsProps {
    module: string;
    selectedPermissions: string[];
    setSelectedPermissions: React.Dispatch<React.SetStateAction<string[]>>;
}

const Permissions: React.FC<PermissionsProps> = ({ module, selectedPermissions, setSelectedPermissions }) => {
    const permissionsMap: Record<string, PermissionItem[]> = {
        dashboard: DashboardPermissions,
        collections: CollectionPermissions,
        customers: CustomersPermissions,
        wallet: WalletPermissions,
        card: CardPermissions,
        transactions: TransactionsPermissions,
        "exchange-rates": ExchangePermissions,
        wire: WirePermissions,
        'api-keys': APIKeysPermissions,
        'bank-account': BankAccountPermissions,
        'payment-links': PaymentLinkPermissions,
        team: TeamPermissions,
        'virtual-account': VirtualAccountPermissions
    };

    const handleChange = (permissionTitle: string) => {
        const permissionKey = `${module}.${permissionTitle.replace(/\s+/g, '_').toUpperCase()}`;
        if (selectedPermissions.includes(permissionKey)) {
            setSelectedPermissions(selectedPermissions.filter(title => title !== permissionKey));
        } else {
            setSelectedPermissions([...selectedPermissions, permissionKey]);
        }
    };

    return (
        <div>
            <Module>{removeHyphensAndDashes(module)}</Module>
            {permissionsMap[module]?.map(permission => (
                <div key={permission.id}>
                    <PermissionItem>
                        <PermissionTitle>{titleCase(permission.title)}</PermissionTitle>
                        <Toggle onChange={() => handleChange(permission.title)} />
                    </PermissionItem>
                </div>
            ))}
        </div>
    );
};

export default Permissions;
