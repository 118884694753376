import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BlackCard,
  TopContainer,
  FrontLines,
  Balance,
  BalanceEyes,
  BalanceAmt,
  Bottom,
  Top,
  FreezeLogo,
  ListItems,
  Item,
  List,
} from "./style";
import logo from "../../../../../assets/icons/logo-white.svg";
import visa from "../../../../../assets/icons/visa.svg";
import lines from "../../../assets/lines.png";
import { ReactComponent as FundIcon } from "../../../assets/fund.svg";
import { ReactComponent as EyeIconWhite } from "../../../assets/eye-white.svg";
import { ReactComponent as EyeIconBlack } from "../../../assets/eye-black.svg";
import { ReactComponent as EyeCloseIcon } from "../../../assets/eye-close.svg";
import { ReactComponent as WithdrawIcon } from "../../../assets/download.svg";
import { ReactComponent as BlockIcon } from "../../../assets/freeze.svg";
import { ReactComponent as BlockIconLarge } from "../../../assets/freeze-large.svg";
import { useAppSelector } from "../../../../../app/hooks";
import { CardDetailsProps } from "../../../types";
import { selectUserRoleAndPermissions } from "../../../../Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";

const CardDetails = ({ setBlockCard, setUnBlockCard, setShowDetails }: CardDetailsProps) => {
  const navigate = useNavigate();
  const [hideBal, setHideBal] = useState(false);
  const { card, cardBalance } = useAppSelector((state) => state.cards);
  const { role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);
const { onShowAlert} = useContext(
    NetworkErrorAlertContext,
  );

  const usdCard = card ? card.find((c) => c.isActive === true ) : {};
  const activeCard = usdCard;
  const hasFundCardPermission = permissions?.["card"]?.includes("FUND_CARD");
  const hasWithdrawPermission = permissions?.["card"]?.includes("WITHDRAW_FROM_CARD");
  const hasDetailsPermission = permissions?.["card"]?.includes("VIEW_CARD_DETAILS");

  

  const handleCardInteractions = (val: string) => {
    const isOwner = role === "OWNER";

    switch (val) {
        case 'fund':
            if (hasFundCardPermission || isOwner) {
                navigate("send-funds");
            } else {
                onShowAlert("", "Access is denied");
            }
            break;

        case 'withdraw':
            if (hasWithdrawPermission || isOwner) {
                navigate("withdraw-funds");
            } else {
                onShowAlert("", "Access is denied");
            }
            break;

        case 'details':
            if (hasDetailsPermission || isOwner) {
                setShowDetails(true);
            } else {
                onShowAlert("", "Access is denied");
            }
            break;

        default:
            break;
    }
}


  return (
  <TopContainer>
    <BlackCard>
      <Top>
        <img src={logo} alt="logo" />
        <span>Black virtual card</span>
      </Top>
      {!activeCard && (
        <FreezeLogo>
          <BlockIconLarge />
        </FreezeLogo>
      )}
      <Bottom>
        <Balance active={activeCard}>
          <BalanceEyes>
            <p>Dollar balance</p>
            {activeCard && 
              <span onClick={() =>setHideBal((prev) =>!prev)}>
                {hideBal ? 
                  <EyeCloseIcon/> 
                  : 
                  <EyeIconWhite />
                }
              </span>
            }
          </BalanceEyes>
          <BalanceAmt>
            {hideBal ? 
            <p>
              ******
            </p>
              : 
            <span>
              ${cardBalance.toFixed(2)}
             </span>
            }
          </BalanceAmt>
        </Balance>
        <img src={visa} alt="visa-card" />
      </Bottom>

      <FrontLines src={lines} alt='lines'/>
    </BlackCard>
       
    <ListItems>
      <List active={activeCard}>
        <Item onClick={() => handleCardInteractions('fund')} disabled={!activeCard} data-testid="fund-card">
          <FundIcon />
        </Item>
        <p>Fund card</p>
      </List>
      <List active={activeCard}>
        <Item onClick={() => handleCardInteractions('details')} disabled={!activeCard} data-testid="card-details">
          <EyeIconBlack />
         </Item>
        <p>Card details</p>
      </List>
      <List active={activeCard}>
        <Item
          onClick={() => handleCardInteractions('withdraw')}
          data-testid="withdraw-card"
          disabled={!activeCard}
         >
          <WithdrawIcon />
        </Item>
        <p>Withdraw</p>
      </List>
          
      {activeCard ? (
        <List active={activeCard}>
          <Item onClick={() => setBlockCard(true)} disabled={!activeCard}>
            <BlockIcon />
          </Item>
          <p>Block card</p>
        </List>
      ) : (
        <List active={true}>
          <Item onClick={() => setUnBlockCard(true)} data-testid="unblock-card">
            <BlockIcon />
          </Item>
          <p>Unblock</p>
        </List>
      )}
    </ListItems>
  </TopContainer>
  );
};

export default CardDetails;