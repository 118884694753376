import styled from "styled-components";

export const Wrapper = styled.div`
   flex-direction: column;

   h1 {
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    color: #000000;
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
    margin: 0;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 16px;
  border-bottom: 1px solid #f0f0f0;
  
  @media (min-width: 768px) {
    gap:0;
    justify-content: space-between;
  }
`;
