import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding-top: 16px;
  margin-bottom: 24px;
  font-family: "Inter";

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Top = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 16px 10px 16px;
  align-items: center;
`;

export const TopLeft = styled.div`
  width: 50%;

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px;
    color: #767676;
  }

  h1 {
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 600;
    line-height: 32px; 
    letter-spacing: -0.4px;
    margin: 0;
  }
`;

export const TopRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  & p {
    text-transform: capitalize;
    ${(props: { $status: string }) => {
      if (props.$status === "pending") {
        return css`
          color: #d6a907;
        `;
      } else if (props.$status === "successful") {
        return css`
          color: #0eab44;
        `;
      } else if (props.$status === "failed") {
        return css`
          color: #f9123b;
        `;
      }
      return css`
        color: #000000;
      `;
    }}
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 0 0 16px;
`;

export const BottomItem = styled.div`
  width: 48%;
  margin-bottom: 16px;
`;

export const BottomLabel = styled.p`
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.6px; 
  color: #767676;
  margin: 0;
`;

export const BottomValue = styled.p`
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  color: #000000;
  margin-bottom: 0;
`;
