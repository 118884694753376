import React, { useState } from "react";
import {
    CustomerDetailBox,
    Flex,
    Paragraph,
    SubHeading,
} from "../styles";
import { ReactComponent as Check } from "../assets/checkIcon.svg";
import { ReactComponent as Copy } from "../assets/copyIcon.svg";

interface DetailsProps {
    firstName: string;
    lastName: string;
    email: string;
    phone_number: string;
}

const CustomerDetail = ({
    firstName,
    lastName,
    email,
    phone_number,
}: DetailsProps) => {
    const [copyAccount, setCopyAccount] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopyAccount(data);
        setTimeout(() => {
            setCopyAccount(null);
        }, 3000);
    };

    const renderCopyIcon = (data: string) => {
        if (data && data !== "NA" && data !== "--") {
            return copyAccount === data ? <Check /> : <Copy onClick={() => onCopyLinkToClipboard(data)} data-test-id="copy-icon"/>;
        }
        return null;
    };
    

    return (
        <CustomerDetailBox>
            <h1 data-test-id="copy-icon">Customers’ details</h1>

            <Flex>
                <Paragraph>First name</Paragraph>
                <SubHeading>
                    {renderCopyIcon(firstName)}
                    {firstName}
                </SubHeading>
            </Flex>
            <Flex>
                <Paragraph>Last name</Paragraph>
                <SubHeading>
                    {renderCopyIcon(lastName)}
                    {lastName}
                </SubHeading>
            </Flex>
            <Flex>
                <Paragraph>Email address</Paragraph>
                <SubHeading>
                    {renderCopyIcon(email)}
                    {email}
                </SubHeading>
            </Flex>

            <Flex>
                <Paragraph>Phone number</Paragraph>
                <SubHeading>
                    {renderCopyIcon(phone_number)}
                    {phone_number}
                </SubHeading>
            </Flex>
        </CustomerDetailBox>
    );
};

export default CustomerDetail;
