import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fffbf7;
  border: 1px solid #8f8f8f;
  border-radius: 8px;
  justify-content: space-between;
  display: flex;
  padding: 16px 24px;
  height: 150px;
  flex-direction: column;
  font-family: "Proxima Nova", sans-serif;

  &:not(:last-child) {
    margin-bottom: 22px;
  }
  & p {
    margin-bottom: 0;
  }
`;

export const Top = styled.div`
  width: 100%;
`;

export const TitleCountry = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Document = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Left = styled.div`
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const Right = styled.div`
  width: 28%;
  display: flex;
  justify-content: flex-end;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 2px;
`;

export const DOB = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #717070;
  margin-right: 1px;
`;

export const EditButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 5px;
  display: flex;
  align-items: center;

  & > span {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: var(--primary-pink);
    margin-right: 5px;
  }
  @media (min-width: 768px) {
    & > span {
      color: #000000;
    }
    & svg path {
      stroke: #000000;
    }
  }
`;

export const DocumentUrl = styled.p`
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #0a0a0a;
  width: 70%;
  max-width: 70%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-left: 5px;
`;

export const Nationality = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a;
  width: 28%;
  max-width: 28%;
  text-align: right;
`;
