import styled, { css } from "styled-components";

type Props ={
  width: string;
  $isSelected: boolean;
  $disabled: boolean 
}

export const Wrapper = styled.button<Props>`
  height: 130px;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Inter";
  text-align: left;

  ${({ $isSelected, $disabled }) => {
    if ($disabled) {
      return css`
        border-color: #000000;
        opacity: 0.3;
        cursor: not-allowed;

        ${Label}, ${Bank}, ${Country}  {
          color: #000000
        }
        ${AccountNumber}{
          color: #5F5F5F
        }
      `;
    } else if ($isSelected) {
      return css`
        border-color: #000000;

        ${Label}, ${Bank}, ${Country} {
          color: #000000;
        }
        ${AccountNumber} {
          color: #5F5F5F
        }
      `;
    } else {
      return css`
        border-color: #E9E9E9;

        ${Label}, ${Bank}, ${Country}  {
          color: #000000
        }
        ${AccountNumber}{
          color: #5F5F5F
        }
      `;
    }
  }};

  @media (min-width: 768px) {
    width: ${({width}) => width || "279px"};
  }
`;


export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Circle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;

  ${(props: { $isSelected: boolean; $disabled: boolean }) => {
    if (props.$disabled) {
      return css`
         border: 2px solid #E9E9E9;
      `;
    } else if (props.$isSelected) {
      return css`
         border: 5px solid var(--primary-pink);
      `;
    } else {
      return css`
        border: 2px solid #E9E9E9;
      `;
    }
  }};
`;

export const Label = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px; 
  margin: 0;
  text-transform: capitalize;
`;

export const AccountNumber = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px; 
  margin-top: 5px;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

export const Bank = styled.p`
  margin: 0;
  text-transform: capitalize;
`;

export const Country = styled.p`
  margin: 0;
  text-align: right;
`;


