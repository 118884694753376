import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
    Flex,
    FlexBox,
    Main,
    NarrationWrapper,
    Button,
    ButtonWrapper,
} from "./styles";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ReactComponent as Notify } from "../../../../assets/icons/notify-error.svg";

import { stringToSentenceCase } from "../../../../utils/format";

interface AccountProps {
    accountName: string;
    accountNumber: string;
    bankName: string;
    accountType: string;
    sortCode?: string;
    narration?: string;
    narrationDetail?: string;
    callBack?: () => void
}

const AccountDetails = ({
    accountName,
    accountNumber,
    bankName,
    accountType,
    sortCode,
    narration,
    narrationDetail,
    callBack
}: AccountProps) => {
    const { currencyCode } = useParams();
    const [copyAccount, setCopyAccount] = useState(null);
    const [copyAll, setCopyAll] = useState(null);

    const onCopyLinkToClipboard = (data) => {
        navigator.clipboard.writeText(data);

        setCopyAccount(data);
        setTimeout(() => {
            setCopyAccount(null);
        }, 3000);
    };

    const onCopyAll = () => {
        const allDetails = sortCode
            ? `Account holder: ${accountName}\nBank name: ${stringToSentenceCase(
                  bankName,
              )}\nAccount number: ${accountNumber}\nAccount type: ${stringToSentenceCase(
                  accountType,
              )}\nSort code: ${sortCode}`
            : `Account holder: ${accountName}\nBank name: ${stringToSentenceCase(
                  bankName,
              )}\nAccount number: ${accountNumber}\nAccount type: ${stringToSentenceCase(
                  accountType,
              )} \nNarration details ${narrationDetail}`;

        onCopyLinkToClipboard(allDetails);
        setCopyAll(allDetails);

        setTimeout(() => {
            setCopyAll(null);
        }, 3000);
    };

    return (
        <Main>
            {currencyCode === "NGN" && (
                <h1>
                    {" "}
                    {currencyCode} bank account details
                </h1>
            )}

            <FlexBox>
                <Flex>
                    <div>
                        <p>Account holder</p>
                        <h2>{accountName}</h2>
                    </div>

                    <span>
                        {copyAccount === accountName ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() =>
                                    onCopyLinkToClipboard(
                                        accountName,
                                    )
                                }
                            />
                        )}
                    </span>
                </Flex>
                <Flex>
                    <div>
                        <p>Bank name</p>
                        <h2>{bankName} </h2>
                    </div>

                    <span>
                        {copyAccount === bankName ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() =>
                                    onCopyLinkToClipboard(
                                        bankName,
                                    )
                                }
                            />
                        )}
                    </span>
                </Flex>
                <Flex>
                    <div>
                        <p>Account number</p>
                        <h2>{accountNumber} </h2>
                    </div>

                    <span>
                        {copyAccount === accountNumber ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() =>
                                    onCopyLinkToClipboard(
                                        accountNumber,
                                    )
                                }
                            />
                        )}
                    </span>
                </Flex>
                <Flex>
                    <div>
                        <p>Swift code</p>
                        <h2>{accountType} </h2>
                    </div>

                    <span>
                        {copyAccount === accountType ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() =>
                                    onCopyLinkToClipboard(
                                        accountType,
                                    )
                                }
                            />
                        )}
                    </span>
                </Flex>

                {currencyCode !== "NGN" && (
                    <>
                        <Flex>
                            <div>
                                <p>Narration</p>
                                <h2>{narration} </h2>
                            </div>

                            <span>
                                {copyAccount ===
                                narrationDetail ? (
                                    <CheckIcon title="copied" />
                                ) : (
                                    <CopyIcon
                                        title="copy"
                                        onClick={() =>
                                            onCopyLinkToClipboard(
                                                narrationDetail,
                                            )
                                        }
                                    />
                                )}
                            </span>
                        </Flex>
                        <NarrationWrapper>
                            <Notify />
                            <span>
                               You must send your payment with the narration above to complete the top-up. Your funds will reflect in your balance as soon as transfer is confirmed within 24-72 hours.
                            </span>
                        </NarrationWrapper>
                    </>
                )}
            </FlexBox>

            <ButtonWrapper>
                <Button
                    onClick={onCopyAll}
                    copied={copyAll}
                >
                    {copyAll
                        ? "Copied"
                        : "Copy account details"}
                </Button>

                {currencyCode !== "NGN" && (
                    <Button secondary="black" onClick={callBack}>
                        I have made payment
                    </Button>
                )}
            </ButtonWrapper>
        </Main>
    );
};

export default AccountDetails;
