import styled from "styled-components";
import { Input } from "antd";

export const Wrapper = styled.div`
  //height: 100%;
  font-family: "Proxima Nova", sans-serif;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  & p {
    margin-bottom: 0;
  }
`;

export const PageTitle = styled.p`
  font-style: normal;
 font-family: 'Athletics';
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 4px !important;
  @media (min-width: 768px) {
    margin-bottom: 8px !important;
  }
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  font-family: Inter;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #464646;
  margin-bottom: 60px !important;
  @media (min-width: 768px) {
    margin-bottom: 0 !important;
  }
`;

export const CheckoutSearch = styled.div`
  width: 100%;
  margin-bottom: 32px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    column-gap: 2rem;
  }
`;

export const Left = styled.div``;
export const Right = styled.div``;

export const SearchInput = styled(Input)`
  &[class~="ant-input-affix-wrapper"] {
    width: 100%;
    height: 38px;
    padding: 10px 15px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    border: 1px solid #a3a3a3;
    border-radius: 8px;
    @media (min-width: 768px) {
      width: 285px;
    }
  }
`;

export const Cards = styled.div`
  display: grid;
  font-family: 'Inter';
  grid-template-columns: repeat(auto-fill, minmax(309px, 1fr));
  gap: 24px;
`;
