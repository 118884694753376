import React from "react";
import { BodyDiv, HeaderTitle, Wrapper } from "./style";
import { ReactComponent as ConfirmIcon } from "../../../assets/icons/success-new.svg";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";

const AccountConfirmed = () => {
  const navigate = useNavigate();
  const onLoginClick = () => {
    navigate("/auth/login");
  };
  return (
    <Wrapper>
      <BodyDiv>
        <ConfirmIcon />
        <HeaderTitle>Account confirmed</HeaderTitle>
      </BodyDiv>
      
      <Button
        label="Log in"
        type="button"
        theme="primary"
        width="193px"
        height="40px"
        fontSize="12px"
        onClick={onLoginClick}
      />
      
    </Wrapper>
  );
};

export default AccountConfirmed;
