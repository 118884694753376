import TransactionStatus from "../../../../components/transactions/components/TransctionStatus";
import  EditIcon  from '../assets/icons/editIcon.svg';
import LinkIcon  from '../assets/icons/link.svg';
import DisableIcon  from '../assets/icons/disableIcon.svg';



export const items = [
    {
        key: "1",
        label: "Edit",
        icon: EditIcon,
    },
    {
        key: "2",
        label: "Resend invite link",
        icon: LinkIcon,
    },
    {
        key: "3",
        label: "Disable",
        icon: DisableIcon,
    },
];


export const tableColumn  = [
    {
        title: "First name",
        dataIndex: "firstName",
        key: "firstName",
    },
    {
        title: "Last name",
        dataIndex: "lastName",
        key: "lastName",
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "phone",
    },
    {
        title: "Phone number",
        dataIndex: "phoneNumber",
        key: "phoneNumber",
    },
    {
        title: "Role",
        dataIndex: "role",
        key: "role",
    },
    {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text: string) => (
            <TransactionStatus text={text} />
        ),
    },
]