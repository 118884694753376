import styled from "styled-components";

export const Wrapper = styled.div`
  width: min(100%, 348px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BodyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderTitle = styled.p`
  font-size: 28px;
  font-family: "Athletics";
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: -0.56px;
  margin: 26px 0px 30px 0;
`;

