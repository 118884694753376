import axios from "axios";
import api from "../../app/api";


//The exchange rates BaseUrl is the prod baseUrl
// This is to avoid any glitch on our rates

const defaultApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
    
  },
});

defaultApi.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

export default api;


export async function getRate(payload) {
    const { data } = await defaultApi.post(
        "/nucleus/general/exchange",
        payload,
    );

    return data.data;
}

export async function FundUsdSettlementWallet(payload, businessId) {
    const { data } = await api.post(
        `/wallet/business/${businessId}/wallet/fund`,
        payload,
    );

    return data.data;
}
