import React, { useContext, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Radio } from "antd";
import {
  Wrapper,
  Header,
  Inputs,
  InputBox,
  ButtonsDiv,
  RadioInputs,
} from "./style";
import { Button } from "../../../common/Button";
import { Input } from "../../../common/Input";
import { useAppSelector } from "../../../../app/hooks";
import { FormInputs, ShareLinkModalProps } from "./types";
import ComplianceRadio from "../../../compliance/ComplianceRadio";
import { InputPhone } from "../../../common/InputPhone";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import ErrorMessage from "../../../ErrorMessage";
import { shareLink } from "../../../../pages/PaymentLinks/linksAPI";

const ShareLinkModal = ({
  link,
  onShareLinkSubmit,
  setShowShareModal,
  setLoading,
}: ShareLinkModalProps) => {

  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FormInputs>({ defaultValues: { link } });
  const { business } = useAppSelector((state) => state.settings);
  const [shareType, setShareType] = useState("email");

  const onCancel = () => {
    reset(
      {
        message: null,
        email: null,
        phone: null,
        shareType: null,
        sendValue: null,
      },
      {
        keepErrors: true,
        keepTouched: false,
      },
    );
    setShowShareModal(false);
  };

  const onShareTypeChange = (e) => {
    setShareType(e.target.value);
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      setLoading(true);

      const shareVal = shareType === "email" ? data.email : data.phone;
      const sendType = shareType === "email" ? "toEmails" : "toNumbers";
      const payload = {
        message: data.message,
        link: data.link,
        sendType,
        sendValue: shareVal,
        businessName: business.name,
      }

      await shareLink(payload)

      setLoading(false);
      onShareLinkSubmit();
    } catch (err) {
      setLoading(false)
      onShowErrorAlert(err, "Error sharing link");
    }
    
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Header>Share payment link</Header>
      <Inputs>
        <InputBox>
          <Controller
            name="message"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Message"
                field={field}
                labelColor="#FFFFFF"
                placeholder="Enter message"
              />
            )}
          />
          {errors.message?.type === "required" && (
            <ErrorMessage message="Field is required" />
          )}
        </InputBox>
        <InputBox>
          <Controller
            name="link"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Input
                type="text"
                label="Link"
                field={field}
                labelColor="#FFFFFF"
                readonly
              />
            )}
          />
        </InputBox>
        <RadioInputs>
          <Radio.Group onChange={onShareTypeChange} value={shareType}>
            <ComplianceRadio label="Email" value="email" />
            <ComplianceRadio label="Phone" value="phone" />
          </Radio.Group>
        </RadioInputs>
        {shareType === "email" && (
          <InputBox>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  label="Email"
                  field={field}
                  labelColor="#FFFFFF"
                  placeholder="Enter email"
                />
              )}
            />
            {errors.email?.type === "required" && (
              <ErrorMessage message="Field is required" />
            )}
          </InputBox>
        )}
        {shareType === "phone" && (
          <InputBox>
            <Controller
              name="phone"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <InputPhone
                  label="Phone"
                  field={field}
                  placeholder="Enter phone"
                />
              )}
            />
            {errors.phone?.type === "required" && (
              <ErrorMessage message="Field is required" />
            )}
          </InputBox>
        )}
      </Inputs>
      
      <ButtonsDiv>
        <Button
          label="Share"
          type="submit"
          theme="secondary"
          height="40px"
          fontSize="12px"
        />
        <Button
          label="Cancel"
          type="button"
          theme="alternate"
          height="40px"
          fontSize="12px"
          onClick={onCancel}
        />
      </ButtonsDiv>
    </Wrapper>
  );
};

export default ShareLinkModal;
