import api from "../../app/api";

export const getCustomers = async (data) => {
    const response = await api.post(
        `/wallet/virtual/v2/filter/paginated`,data
    );
    return response;
};


export const getCustomerTransaction = async (data) => {
    const response = await api.post(
        `/nucleus/tnx/filter/paginated`,
        data
    );
    return response.data;
};

export const generateVirtualAccount = async (data, token: string) => {
    try {
      api.defaults.headers.common["x-auth-token"] = token;
      const response = await api.post(
        `/wallet/virtual/v2/business/create/account`,
        data
      );
  
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  };
  

  export const getVirtualAccountTransaction = async (data) => {
    const response = await api.post(
        `/nucleus/tnx/filter/paginated`,
        data
    );
    return response.data;
    
}

export const getVirtualAccount = async (virtualAccount: string) => {
    try {
      const response = await api.get(
        `/wallet/virtual/virtual/account/requery/${virtualAccount}`
      );
      return response.data;
    } catch (error) {
        throw new error
    }
  };
  