import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 35%;
    margin: auto;
    margin-top: 40px;
    @media (max-width: 768px) {
        width: 100%;
    }

    .rate-box {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    button {
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px; /* 130% */
    }
`;

export const Heading = styled.h1`
    color: #000;
    text-align: center;
    font-family: Athletics;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.56px;
`;

export const Paragraph = styled.p`
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #767676;
    margin-top: 4px !important;
    margin:0;
`;

export const InputWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
        margin-top: 20px;
    }
`;

export const RateBox = styled.div`
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f9f9f9;

    span {
        color: #000000;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
    }

    .fee-box{
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;