import styled from "styled-components";
import { motion } from "framer-motion";

export const Content = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 0 15px;
  width: 330px;
  height: 290px;
  box-shadow: 0px 0px 38px rgba(45, 66, 89, 0.16);
  border-radius: 6px;
  background: #ffffff;
  z-index: 20;
  font-style: normal;

  @media (min-width: 768px) {
    padding: 15px O;
    width: 463px;
    height: 378px;
    border-radius: 8px;
  }
`;

export const CloseIcon = styled.div`
  margin-right: 7px;
  align-self: flex-end;
  cursor: pointer;
`;

export const Text = styled.header`
  text-align: center;
  margin: 0;

  @media (min-width: 768px) {
    margin: 0 35px;
  }
`;

export const LargeText = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.48px;
  margin-bottom: 4px;
  color: #000000;
  font-family: 'Athletics';

  @media (min-width: 768px) {
    margin-bottom: 8px;
    font-size: 24px;
  }
`;

export const SmallText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  font-family: "Inter";

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

export const Button = styled.button`
  font-family: "inter";
  font-weight: 600;
  width: 290px;
  height: 48px;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
  background: #000000;
  border-radius: 6px;
  margin-top: 20px;

  &:active {
    transform: translateY(1px);
  }

  @media (min-width: 768px) {
    font-size: 14px;
    width: 415px;
    border-radius: 8px;
    margin-top: 35px;
  }
`;

export const ResendText = styled.p`
  color: #000000;
  font-family: "Inter";
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin-top: 10px;
  
  span {
    color: var(--primary-pink);
    cursor: pointer;
    font-weight: 600;

    &:hover {
      color: #ff567e;
    }
  }

  @media (min-width: 768px) {
    font-size: 14px;
    margin-top: 25px;
  }
`;

export const variants = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1 },
};

export const transition = {
  type: "spring",
  stiffness: 300,
  damping: 20,
};
