import React, { useContext, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Wrapper, ModalTitle, ModalSubtitle, HeaderWrapper, BodyWrapper, ButtonContainer } from "./style";
import { Button } from "../../common/Button";
import CustomRadio from "../../common/RadioInput";
import CustomDatePicker from "../../common/CustomDateRangePicker/CustomDatePicker";
import { formatDate } from "../../../utils/dateUtils";
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { fileFormatOptions } from './constants';
import { merchantCollectionAccountStatement, fetchCollectionsForExport, fetchPayoutForExport } from "../../../pages/Balances/settlementsSlice";

type Props = {
    exportByFilterFunction: (filterParams: any) => void;
}

const ExportByFilterModal: React.FC<Props> = ({exportByFilterFunction}) => {
    const dispatch = useAppDispatch()
    const abortControllerRef = useRef(null);
    const {onShowAlert: onShowErrorAlert} = useContext(NetworkErrorAlertContext);
    const {businessId} = useAppSelector((state) => state.users);
    const { control, handleSubmit, watch, setValue, formState: { isValid }, reset } = useForm({
        mode: "onChange",
        defaultValues: {
            startDate: "",
            endDate: "",
            transactionType: "",
            fileFormat: ""
        }
    });

    const watchAllFields = watch();

    const handleRadioChange = (name, value) => {
        setValue(name, watchAllFields[name] === value ? "" : value, { shouldValidate: true });
    };

    const disableFutureDates = (current: dayjs.Dayjs) => {
        return current && current > dayjs().endOf('day')
    }


    const onSubmit = async (data) => {
        try {
          
            const formattedData = {
                ...data,
                startDate: formatDate(data?.startDate) ?? "",
                endDate: formatDate(data?.endDate) ?? "",
                transactionType: 'allTransactions'
            };

            abortControllerRef.current = new AbortController();
           
            let allTransactions
            let exportCollectionsData
            let exportPayoutData

            if (formattedData.transactionType === 'allTransactions') {
                allTransactions = await dispatch(merchantCollectionAccountStatement({
                    businessId,
                    startDate: formattedData.startDate,
                    endDate: formattedData.endDate,
                })).unwrap()
            } else if (formattedData.transactionType === 'collections') {
                exportCollectionsData = await dispatch(fetchCollectionsForExport({
                    businessId,
                    startDate: formattedData.startDate,
                    endDate: formattedData.endDate,
                })).unwrap()
            } else {
                exportPayoutData = await dispatch(fetchPayoutForExport({
                    businessId,
                    startDay: formattedData.startDate,
                    endDay: formattedData.endDate,
                })).unwrap()
            }

            exportByFilterFunction({
                ...formattedData,
                allTransactions,  
                exportCollectionsData,
                exportPayoutData
            });

            reset({
                startDate: "",
                endDate: "",
                transactionType: "",
                fileFormat: ""
            });
        } catch (error) {
            onShowErrorAlert("", "Export failed: " + error.message);
        } finally {
            abortControllerRef.current = null;
        }
      };

    return (
        <Wrapper>
            <HeaderWrapper>
                <ModalTitle>
                    Get Account Statement
                </ModalTitle>
                <ModalSubtitle>
                    Download your account statement for a specific period.
                </ModalSubtitle>
            </HeaderWrapper>

            <form onSubmit={handleSubmit(onSubmit)}>
                <BodyWrapper>
                    <Controller
                        name="startDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder=""
                                animatedLabel="Start date"
                                lightBorder={true}
                                disabledDate={disableFutureDates}
                            />
                        )}
                    />
                    <Controller
                        name="endDate"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <CustomDatePicker
                                value={field.value}
                                onChange={(date) => field.onChange(date)}
                                placeholder=""
                                animatedLabel="End date"
                                lightBorder={true}
                                disabledDate={disableFutureDates}
                            />
                        )}
                    />

                    <section>
                        <h2>Preferred file format</h2>
                        <div>
                            <Controller
                                name="fileFormat"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                <>
                                    {fileFormatOptions.map((option) => (
                                        <CustomRadio
                                            key={option.value}
                                            label={option.label}
                                            value={option.value}
                                            onClick={() => handleRadioChange("fileFormat", option.value)}
                                            selected={field.value === option.value}
                                        />
                                    ))}
                                </>
                                )}
                            />
                        </div>
                    </section>
                </BodyWrapper>

                <ButtonContainer>
                    <Button
                        label="Submit request"
                        type="submit"
                        disabled={!isValid}
                        height="40px"
                        fontSize="12px"
                    />
                </ButtonContainer>
            </form>
        </Wrapper>
    );
};

export default ExportByFilterModal;