import React, { useState } from 'react';
import { Flex, Paragraph, SubHeading, Heading, DetailsWrapper, CheckFlex, Check, CopyIcon } from '../styles';

interface Props{
    transaction_id: string;
    virtual_account: string;
    amount_received: string;
    amount_settled: string;
    date: string;
    balance: string;
    transaction_ref: string;
}

const TransactionDetails = ({ balance, transaction_id, virtual_account, amount_received, amount_settled, date, transaction_ref }: Props) => {
    const [copy, setCopy] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopy(data);
        setTimeout(() => {
            setCopy(null);
        }, 3000);
    };

  return (
    <div>
        <Heading>{balance}</Heading>

        <DetailsWrapper>
        <Flex>
        <Paragraph>Transaction ID</Paragraph>
        <CheckFlex>
                    {copy ? <Check /> : <CopyIcon onClick={() =>
                         onCopyLinkToClipboard(transaction_ref)} data-testid="copy-icon" />}
        <SubHeading>{transaction_id}</SubHeading>

                </CheckFlex>
        
        </Flex>


       <Flex>
       <Paragraph>Virtual account number</Paragraph>
        <SubHeading>{virtual_account}</SubHeading>
       </Flex>

        <Flex>
        <Paragraph>Amount received</Paragraph>
        <SubHeading>{amount_received}</SubHeading>
        </Flex>

       <Flex>
       <Paragraph>Amount settled</Paragraph>
        <SubHeading>{amount_settled}</SubHeading>
       </Flex>

       <Flex>
       <Paragraph>Date</Paragraph>
        <SubHeading>{date}</SubHeading>
       </Flex>
        </DetailsWrapper>
        
        
    </div>
  )
}

export default TransactionDetails