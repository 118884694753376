import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../components/common/Button";
import CustomModal from "../../../components/common/CustomModal";
import { MainWrapper, RequestBtns, RequestSettlementWrapper } from "../styles";
import { getManualSettlement, settleFundsRequest } from "../api";
import { useAppSelector } from "../../../app/hooks";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import Loader from "../../../components/common/Loader";
import { getAllWallets } from "../../Wallets/api";
import { selectUserRoleAndPermissions } from "../../Settings/Team/redux/slice";

const RequestForSettlement = () => {
    const [openRequest, setOpenRequest] = useState(false)
    const [enableManualSettlement, setEnableManualSettlement] = useState(false)
    const {
        userObj: { accountUser } = { accountUser: null },
      } = useAppSelector((state) => state?.users) || {};
    const {role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );
    const hasCreateInstantVA =
    permissions?.["virtual-account"]?.includes("INSTANT_VA_SETTLEMENT");
    const [loading, setLoading] = useState(false)
    const { businessId  } = useAppSelector(
        (state) => state.users,
    );
    const successAlertContext = useContext(
        SuccessAlertContext,
    );
    const { onShowAlert: onSuccess } =
        successAlertContext || {};
        const { onShowAlert} = useContext(
            NetworkErrorAlertContext,
          );

    const handleCloseRequest = () => {
        setOpenRequest(!openRequest)
    
    }

    const handleOpenRequest = () => {
        if(hasCreateInstantVA || role === "OWNER"){
            setOpenRequest(true)
        }else{
            onShowAlert("", "Access is denied")
        
        }
    
    }

    const manualWithdrawal = async () => {
        setLoading(true)
        await settleFundsRequest(businessId).then(async () => {
            await getAllWallets({businessId})

            setLoading(false)
            setOpenRequest(false)
            onSuccess('Funds settled successfully')
            
        }).catch((err) => {
            setOpenRequest(false)
            setLoading(false)
            onShowAlert("", err?.response?.data.error);        
        })

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getManualSettlement(businessId).then(async (res) => {
                    const activeSettlement = res?.data?.manualSettlementActive
                    setEnableManualSettlement(activeSettlement);
                })
            } catch (error) {
                return error
            }
        };
    
        fetchData();
    }, [businessId]);
    


    return (
        <MainWrapper>
            <Loader isLoading={loading}/>
            {(enableManualSettlement || accountUser?.email === "luping@doithk.com") && (
        <Button
            label="Settle now"
            theme="secondary"
            width="120px"
            height="40px"
            onClick={handleOpenRequest}
            data-testid="button"
            className="settle-btn"
        /> 
    )}

            <CustomModal isModalVisible={openRequest} onClose={handleCloseRequest} width="480px">
                <RequestSettlementWrapper>
                <h1>Settle funds</h1>
                <p>By completing this action, 
                your virtual balance would be 
                transferred to your settlement balance.</p>

                </RequestSettlementWrapper>
                <RequestBtns>
                    <Button label="Cancel" className="cancel-btn" theme="alternate" height="40px" onClick={handleCloseRequest}/>
                    <Button label="Confirm" theme="secondary" className="confirm-btn" height="40px" onClick={manualWithdrawal}/>
                </RequestBtns>
            </CustomModal>
        </MainWrapper>
    );
};

export default RequestForSettlement;
