import React, {useState, useEffect} from 'react'
import { Wrapper, Box } from "./style";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/success-icon.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ReactComponent as CopiedIcon } from "../../../../assets/icons/check.svg";
import { Button } from '../../../common/Button';

type Props = {
  newLink: any;
  setSuccess: (arg: boolean) => void
  getData: () =>void;
}

const Success = ({newLink, setSuccess, getData}: Props) => {
  const [copied, setCopied] = useState(false);
  const link = newLink ? `${newLink.urlHost}?token=${newLink.token}` : '';

  const copyToClipboard = (value: string) => {
    if (value && typeof value === "string" ) {
      navigator.clipboard.writeText(value);
      setCopied(true)
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false)
    }, 2000);

    return () =>{
      clearTimeout(timer)
    }
  }, [copied]);

  const closeModal = () => {
    setSuccess(false)
    getData();
  }

  return (
    <Wrapper>
      <SuccessIcon />

      <p>Your payment link has been created. Copy the link and send to your clients.</p>

      <Box>
        <input 
          type="text" 
          value={link}
          readOnly
        />
        <span title='copy'>
          {copied ? <CopiedIcon /> : <CopyIcon onClick={() =>copyToClipboard(link)}/>}
        </span>
      </Box>

      <Button
        theme='secondary'
        label='View payment link'
        height='40px'
        fontSize='12px'
        onClick={closeModal}
      />
    </Wrapper>
  )
}

export default Success