import React, { useState } from "react";
import {
    StepsContainer,
    StepsTitle,
    ReviewBox,
    ReviewHeader,
    ReviewDetails,
    ReviewItem,
    ReviewRate,
} from "../../steps/style";
import { Button } from "../../../../../components/common/Button";
import { useAppSelector } from "../../../../../app/hooks";
import Loader from "../../../../../components/common/Loader";
import { addCommasToNumber } from "../../../utils";
import { sendOTP } from "../../../../../api/usersAPI";
import { ReactComponent as ExchangeIcon } from "../../../assets/transfer.svg";
import VerifyPayment from "../../GeneralComponents/VerifyPayment";

type Props = {
    setStep: (step: number) => void;
    code?: string;
    setCode?: (data: string) => void;
    makeRequest?: () => void;
};

const Rate = ({ rate }: any) => {
    return (
        <ReviewRate>
            $1
            <ExchangeIcon />₦{rate ? rate.toFixed(2) : 0}
        </ReviewRate>
    );
};

const Review = ({
    setStep,
    code,
    setCode,
    makeRequest,
}: Props) => {
    const today: string = new Date()
        .toLocaleDateString()
        .replaceAll("/", ".");
    const {
        withdrawalFundsValue,
        withdrawalFundsAmount,
        withdrawRate,
    } = useAppSelector((state) => state.cards);
    const [verifyOTP, setVerifyOTP] = useState(false);
    const { userObj } = useAppSelector(
        (state) => state.users,
    );
    const { accountUser } = userObj;    
    const [loading, setLoading] = useState(false);
    const amount = withdrawalFundsAmount
        ? parseFloat(withdrawalFundsAmount)
        : 0;
    const amountToBeCredited = amount * withdrawRate;

    const reviewItems = [
        {
            id: 1,
            name: "Amount to be withdrawn",
            value: `$${amount.toFixed(2)}`,
        },
        {
            id: 2,
            name: "Rate",
            value: <Rate rate={withdrawRate} />,
        },
        {
            id: 3,
            name: "Total Amount",
            value: `₦${addCommasToNumber(
                amountToBeCredited.toFixed(2),
            )}`,
        },
        {
            id: 4,
            name: "Destination wallet",
            value:
                withdrawalFundsValue &&
                `${withdrawalFundsValue.label} Wallet`,
        },
        {
            id: 5,
            name: "Date",
            value: today,
        },
    ];

    const handleSendOTP = async (user: {
        email: string;
    }) => {
        try {
            setLoading(true);
            await sendOTP(user);
            setVerifyOTP(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);

            const errorObj = error?.response?.data;
            let message: string = "";

            if (typeof errorObj?.message === "string") {
                message = errorObj.message;
            } else if (
                typeof errorObj?.error === "string"
            ) {
                message = errorObj.error;
            } else if (typeof errorObj === "string") {
                message = errorObj;
            } else {
                message = "Failed to send OTP";
            }
        }
    };

    return (
        <StepsContainer>
            <Loader isLoading={loading} />

            <StepsTitle>
                <h1>Review your transaction details</h1>
            </StepsTitle>

            <ReviewBox style={{ marginBottom: "40px" }}>
                <ReviewHeader>
                    <h1>Transaction details</h1>
                    <span onClick={() => setStep(1)}>
                        Edit
                    </span>
                </ReviewHeader>

                <ReviewDetails>
                    {reviewItems.map((item) => (
                        <ReviewItem key={item.id}>
                            <p>{item.name}</p>
                            <span>{item.value}</span>
                        </ReviewItem>
                    ))}
                </ReviewDetails>
            </ReviewBox>

             {verifyOTP && (
                <VerifyPayment
                    setVerifyOTP={setVerifyOTP}
                    makeRequest={makeRequest}
                    otp={code}
                    setOtp={setCode}
                />
            )}

            <Button
                label="Confirm and proceed"
                height="48px"
                fontSize="14px"
                onClick={() => handleSendOTP(accountUser?.email)}
            />

           
        </StepsContainer>
    );
};

export default Review;
