import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import {
    Controller,
    SubmitHandler,
    useForm,
} from "react-hook-form";
import {
    Content,
    FormContent,
    UploadContainer,
} from "./style";
import {
    getRequiredDocumentsByType,
    modifyComplianceData,
    uploadRequiredDocument,
} from "../../../../../pages/Compliance/complianceSlice";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import Loader from "../../../../common/Loader";
import { getCountryValue } from "../../../utils";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { gtagEvent } from "../../../../../utils/gtag";
import { Button } from "../../../../common/Button";
import ComplianceUpload from "../ComplianceUpload";
import CustomModal from "../../../../common/CustomModal";
import Declaration from "../Declaration";
import { StepThreeFormInputs } from "../../../types";
import { ErrorText } from "../StepOne/style";
import { ReactComponent as ErrorIcon } from "../../../../../assets/icons/alert-circle.svg";

const StepFour = () => {
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );
    const dispatch = useAppDispatch();
    const [updatedDocumentsList, setUpdatedDocumentsList] =
        useState([]);
    const [uploadDetails, setUploadDetails] = useState([]);
    const [showDeclaration, setShowDeclaration] =
        useState(false);

    const {
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );


    const {
        documents,
        uploadedDocuments,
        status: complianceStatus,
        complianceData,
    } = useAppSelector((state) => state.compliance);

    useEffect(() => {
        if (business?.businessType) {
            dispatch(
                getRequiredDocumentsByType({
                    businessType: business?.businessType,
                    country: getCountryValue(
                        business?.country,
                    ),
                }),
            );
        }
    }, [business]);

    

    useEffect(() => {
        if (documents?.length) {
            const newDocDetails = [];
            const updatedRequiredDocuments = documents.map(
                (doc) => {
                    const newDocDetail = {
                        id: doc.businessDocument.id,
                        fileName: "",
                        fileType: "",
                        uploadStatus: "idle",
                        uploadProgress: 0,
                        docUrl: null,
                        docExistsStatus: "idle",
                    };
                    newDocDetails.push(newDocDetail);
                    const uploadedDocs =
                        uploadedDocuments.filter(
                            (upDoc) =>
                                upDoc.businessDocument
                                    .id ===
                                doc.businessDocument.id,
                        );
                    const uploadedDocsLength =
                        uploadedDocs.length;
                    if (
                        uploadedDocs &&
                        uploadedDocsLength
                    ) {
                        const docId =
                            doc.businessDocument.id.toString();
                        setValue(docId, "url");
                        return uploadedDocs[
                            uploadedDocsLength - 1
                        ];
                    }
                    return doc;
                },
            );
            setUploadDetails(newDocDetails);
            setUpdatedDocumentsList(
                updatedRequiredDocuments,
            );
        }
    }, [documents, uploadedDocuments]);

    const onNext: SubmitHandler<
        StepThreeFormInputs
    > = async () => {
        try {
            await dispatch(
                modifyComplianceData({
                    ...complianceData,
                    startupComplianceStatus: "DONE",
                }),
            ).unwrap();
            gtagEvent({
                action: "Compliance_submit_step_4",
                category: "compliance",
                label: "Compliance submit step 4",
            });
            setShowDeclaration(true);
        } catch (err) {
            onShowErrorAlert(err, err.message);
        }
    };

    const uploadDocumentURL = async (downUrl, busDocId) => {
        const payload = {
            businessDocument: busDocId,
            url: downUrl,
            approved: false,
            business: businessId,
            documentApprovalStatus: "PENDING",
        };
        setValue(busDocId.toString(), downUrl);
        try {
            await dispatch(
                uploadRequiredDocument(payload),
            ).unwrap();
        } catch (err) {
            onShowErrorAlert(err, err.message);
        }
    };

    const onUploadDocumentDone = async (newDoc: any) => {
        const newDetails = [...uploadDetails];
        const detailsIndex = uploadDetails.findIndex(
            (det) => det.id === newDoc.id,
        );
        if (detailsIndex !== -1) {
            newDetails.splice(detailsIndex, 1, newDoc);
            setUploadDetails(newDetails);
        }
    };

    const onClose = () => {
        setShowDeclaration(false);
    };

    function formatLabelAsList(description) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(
            description,
            "text/html",
        );
        const listItems = Array.from(
            htmlDoc.querySelectorAll("li"),
        );

        if (listItems.length === 0) {
            return description;
        }

        const listContent = listItems
            .map((item) => item.textContent.trim())
            .join("\n");
        return `- ${listContent}`;
    }

    return (
        <div>
            <Loader
                isLoading={complianceStatus === "loading"}
            />
            <Content onSubmit={handleSubmit(onNext)}>
                <FormContent>
                    {updatedDocumentsList.map((busType) => {
                        return [
                            "document",
                            "upload",
                        ].includes(
                            busType.businessDocument.type,
                        ) ? (
                            
                                <Controller
                                    name={busType.businessDocument.id.toString()}
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <UploadContainer
                                            key={
                                                busType
                                                    .businessDocument
                                                    .id
                                            }
                                        >
                                            <ComplianceUpload
                                                approvalStatus={
                                                    busType?.documentApprovalStatus
                                                }
                                                onUpload={
                                                    onUploadDocumentDone
                                                }
                                                docId={
                                                    busType
                                                        .businessDocument
                                                        .id
                                                }
                                                docName={
                                                    busType
                                                        .businessDocument
                                                        .document
                                                }
                                                uploadPath={`verification/documents/${busType.businessDocument.id}/`}
                                                onUploadFinish={
                                                    uploadDocumentURL
                                                }
                                                label={formatLabelAsList(
                                                    busType
                                                        .businessDocument
                                                        .description,
                                                )}
                                                title={null}
                                            />
                                            {errors[
                                                busType.businessDocument.id.toString()
                                            ]?.type ===
                                                "required" && (
                                                <ErrorText>
                                                    <ErrorIcon />
                                                    <span>
                                                        Document
                                                        is
                                                        required
                                                    </span>
                                                </ErrorText>
                                            )}
                                        </UploadContainer>
                                    )}
                                />
                            
                        ) : null;
                    })}
                </FormContent>
                <div>
                    <Button
                        label="Done"
                        width="100%"
                        theme="primary"
                        type="submit"
                        height="40px"
                    />
                </div>
            </Content>
            <CustomModal
                isModalVisible={showDeclaration}
                onClose={onClose}
                width="775px"
            >
                <Declaration />
            </CustomModal>
        </div>
    );
};

export default StepFour;
