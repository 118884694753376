import styled from "styled-components";

export const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Wrapper = styled.div`
    width: 100%;
    padding: 14px;
    border-radius: 8px;
    border: 1px solid #e9e9e9;
`;

export const Heading = styled.h1`
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
`;

export const Paragraph = styled.p`
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
`;

export const Span = styled.span`
font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 15.6px;
text-align: left;
color: #5F5F5F;
`;
