import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 16px 0;
  margin-bottom: 24px;
  position: relative;
  font-family: "Inter";
`;

export const Top = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 0 16px 10px 16px;
  align-items: center;
  justify-content: space-between;
`;

export const BoxLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: left;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    color: #767676;
    margin-bottom: 4px;
  }

  h1 {
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 500;
    line-height: 28px; 
    color: #000000;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    color: #484848;
    margin: 0;
  }
`;

export const BoxRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  span{
    margin-top: 4px;
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    color: #767676;
    margin-bottom: 4px;
  }

  h1 {
    font-family: "Athletics";
    font-size: 20px;
    font-weight: 500;
    line-height: 28px; 
    color: #000000;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
    color: #484848;
    margin: 0;
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  
  &:last-child{
    border-bottom: 0;
    padding: 10px 16px 0 16px;
  }
`;

