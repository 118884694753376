import React, { ChangeEvent } from "react";
import { Wrapper, FilterContainer } from "./style";
import { useAppSelector } from "../../../../app/hooks";
import { exportCSV } from "../../utils";
import Search from "../../../common/Search";
import Export from "../../../common/Export";

const TitleFilter = ({ search, setSearch }: {
  search: string;
  setSearch: (val: string) => void;
}) => {
  const { rates } = useAppSelector((state) => state.rates);

  return (
     <Wrapper>
      <h1>Our rates</h1>
      
      <FilterContainer>
        <Search
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
        />

        <Export
          downloadCSV={() => exportCSV(rates)}
          downloadXLS={() => exportCSV(rates)}
        />
      </FilterContainer>
    </Wrapper>
  );
};

export default TitleFilter;
