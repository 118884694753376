import React from "react";
import { stringToSentenceCase } from "../../utils/format";
import TransactionStatus from "../../components/transactions/components/TransctionStatus";

export const tableColumns = [
  {
    title: "Transaction ID",
    dataIndex: "transId",
    key: "transId",
    fixed: "left",
  },
  { title: "Source", dataIndex: "source", key: "source" },
  { title: "Method", dataIndex: "method", key: "method" },
  { title: "Email", dataIndex: "email", key: "email" },
  { title: "Currency", dataIndex: "currency", key: "currency" },
  { title: "Amount", dataIndex: "amount", key: "amount" },
  {
    title: "Date - Time",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <TransactionStatus text={text} />,
  },
];

export const getMethodLabel = (method) => {
  switch (method) {
    case "card":
      return "Card";
    case "Stitch Payment":
      return "Stitch payment";
    case "banktransfer":
    case "bank_transfer":
      return "Bank transfer";
    case "Wallet Popup Payment":
    case "Wallet popup payment":
      return "Klasha wallet";
    case "mpesa":
      return "M-Pesa";
    case "mobilemoney":
      return "Mobile money";
    case "ussd":
      return "USSD";
    default:
      return stringToSentenceCase(method.toLowerCase());
  }
};

export const getBaeName = (extraName) => {
  if (extraName === "baepay") {
    return "BaePay-";
  }
  return "";
};

export const getSourceLabel = (method) => {
  switch (method) {
    case "ONETIME":
    case "RECURRING":
      return "Payment link";
    case "woo":
      return "WooCommerce";
    case "bigc":
      return "BigCommerce";
    case "magento":
      return "Magento";
    case "plugin":
      return "Plugin";
    case "shopify":
      return "Shopify";
    case "ecwid":
      return "Ecwid";
    case "BAEPAY":
      return "BaePay";
    default:
      return stringToSentenceCase(method?.toLowerCase());
  }
};