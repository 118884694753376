import React from 'react';
import { EmptyHeading, EmptyParagraph, Heading } from '../styles';


const EmptyTable = () => {
  return (
    <div>
        <Heading>No customers added yet</Heading>
        <EmptyParagraph>All customers added will be shown here</EmptyParagraph>

    </div>
  )
}

export default EmptyTable