import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 25px;  

    p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color:  #484848;
        margin-bottom: 0;
    }
`;

export const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    input, span {
        height: 42px;
        border: 1px solid #E9E9E9;
        border-radius: 8px;
        background-color: #e9e9e951;

        &:hover {
            border: 1px solid #a3a3a3;
        }
    }

    input{
        width: 86%;
        color: #000000;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px; 
        padding: 0 10px;
    }

    span {
        width: 11%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;