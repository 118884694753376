import React, { useState, useContext } from 'react'
import { Portal } from '@reach/portal';
import { usePostHog } from 'posthog-js/react'
import { CloseIcon, OTPText, PaymentWrapper, ResendText, OTPContent, OTPInput } from "./style";
import Loader from '../../../../components/common/Loader';
import NetworkErrorAlert from '../../../../components/common/alerts/NetworkErrorAlert';
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import SuccessAlert from '../../../../components/common/alerts/SuccessAlert';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { transition, variants } from '../virtualCard/style';
import { ReactComponent as Close } from "../../../../components/common/OTP/assets/close.svg"
import { Button } from '../../../../components/common/Button';
import { setVerifyPayment, updateCardStage, updateCardStep } from '../../CardSlice';
import { resendCardOTP, verifyCardOTP } from '../../CardsApi';

const CreatePayment = () => {
    const dispatch = useAppDispatch();
    const posthog = usePostHog();
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { businessId } = useAppSelector((state) => state.users);
    const { merchantBusiness, cardholderId } = useAppSelector((state) => state.cards);

    const merchant = merchantBusiness
    ? merchantBusiness.find((biz) => biz.id === businessId)
    : {};

    const {
        onShowAlert,
        showAlert: errorAlert,
        alertMessage,
      } = useContext(NetworkErrorAlertContext);

    const {
        onShowAlert: onSuccess,
        showAlert: successAlert,
        alertMessage: successMessage,
      } = useContext(SuccessAlertContext);

    const postHog = (type: string, msg?: string) =>{     
      if(type === "success"){
        posthog?.capture("card_created_b2b", { status: "successful"})
      }
      if(type === "failed"){
        posthog?.capture("card_created_b2b", { status: "failure", failure_reason: msg })
      }
    }


    const handleVerifyOTP = async (str: string, id: string) => {
        if (str.length < 5 || str.length > 7) {
          onShowAlert(Error, "The OTP is not correct");
        } else {
          setIsLoading(true);
    
          try {
            await verifyCardOTP(str, id);
    
            postHog("success")
            setOtp("");
            setIsLoading(false);
            dispatch(setVerifyPayment(false));

            dispatch(updateCardStage("cardReady"));
            dispatch(updateCardStep(1));
          } catch (err) {
            const errorObj = err?.response?.data;
            let errorMessage = "";
            
            if (typeof errorObj.error === "string") {
              errorMessage = errorObj.error;
            } else if (typeof errorObj.message === "string") {
              errorMessage = errorObj.message;
            } else {
              errorMessage = "Invalid OTP";
            }
            
            setIsLoading(false);
            onShowAlert(errorObj, errorMessage);
            postHog("failed", errorMessage)
          }
        }
      };
    
      const handleResendCardOTP = async (id: string) => {
        setIsLoading(true);
        
        try {
            await resendCardOTP(id);
            
            setOtp("");
            setIsLoading(false);
            onSuccess("Check your email for the OTP");
        } catch (error) {
          const errorObj = error?.response?.data;
          let errorMessage = "";
    
          if (typeof errorObj.error === "string") {
            errorMessage = errorObj.error;
          } else if (typeof errorObj.message === "string") {
            errorMessage = errorObj.message;
          } else {
            errorMessage = "Failed to resend OTP";
          }
    
          setIsLoading(false);
          onShowAlert(errorObj, errorMessage);
        }
      };


  return (
    <Portal>
        <PaymentWrapper>
        {errorAlert && <NetworkErrorAlert message={alertMessage} />}
        {successAlert && <SuccessAlert message={successMessage} />}

        <Loader isLoading={isLoading} />

            <OTPContent
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={transition}>

                <CloseIcon onClick={() => dispatch(setVerifyPayment(false))} >
                    <Close />
                </CloseIcon>

                <OTPText>
                    <h1>Enter your verification code</h1>
                    <p>{`To continue, enter the OTP sent to your email ${merchant ? merchant.email: ''}`}</p>
                </OTPText>

                <OTPInput 
                    type="number"
                    value={otp}
                    placeholder='Enter your OTP here'
                    onChange={(e) => setOtp(e.target.value)}
                />

                <Button 
                    theme="secondary"
                    fontSize = "16px"
                    height = "56px"
                    label="Submit"
                    onClick={() => handleVerifyOTP(otp, cardholderId)}
                />

                <ResendText onClick={() =>handleResendCardOTP(cardholderId)}>
                    No code received?
                    <span> Resend code</span>
                </ResendText>
            </OTPContent>

        </PaymentWrapper>
    </Portal>
  )
}

export default CreatePayment