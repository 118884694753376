import React from "react";
import {
  StepsContainer,
  StepsBox,
  StepsTitle,
  InputContainer,
  SelectContainer,
  FlagBox,
  Custom,
  SelectOption,
  Input,
  RateBox,
} from "../../steps/style";
import "../../../../../components/common/CustomSelect/style.css";
import { Button } from "../../../../../components/common/Button";
import { ReactComponent as TransferIcon } from "../../../assets/transfer.svg";
import { ReactComponent as DropdownIcon } from "../../../../../assets/icons/arrow-down.svg";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setWithdrawalFundsAmount,
  setWithrawalFundsValue,
} from "../../../CardSlice";
import { addCommasToNumber } from "../../../utils";
import { ReactComponent as ErrorIcon } from "../../../../../assets/icons/alert-circle.svg";

const { Option } = Select;

interface FundsProps {
  setStep: (arg: number) => void;
}

const Funds = ({ setStep }: FundsProps) => {
  const dispatch = useAppDispatch();
  const {
    wallets,
    withdrawalFundsValue,
    withdrawalFundsAmount,
    withdrawRate,
    cardBalance,
  } = useAppSelector((state) => state.cards);

  const amount = withdrawalFundsAmount ? parseFloat(withdrawalFundsAmount) : 0;
  const total = amount * withdrawRate;

  const ngnWallet = wallets.find(
    (wallet: { currency: string }) => wallet.currency === "NGN",
  );
  const balance = ngnWallet ? ngnWallet.balance : 0;
  const minimumWithdrawalAmt = 1;

  return (
    <StepsContainer>
      <StepsTitle>
        <h1>How much do you want to withdraw from your USD card</h1>
      </StepsTitle>

      <StepsBox>
        <InputContainer>
          <Input
            type="number"
            value={withdrawalFundsAmount}
            onChange={(e) => dispatch(setWithdrawalFundsAmount(e.target.value))}
            placeholder="Withdraw"
            hasError={
              amount > cardBalance ||
              parseFloat(withdrawalFundsAmount) < minimumWithdrawalAmt
            }
          />

          <FlagBox>
            <p
              style={{ width: "18px", borderRadius: "2px" }}
              className="currency-flag currency-flag-usd"
            />
            <p>USD</p>
          </FlagBox>

          {parseFloat(withdrawalFundsAmount) < minimumWithdrawalAmt && (
            <span>
              <ErrorIcon /> Minimum top-up amount is $1
            </span>
          )}

          {amount > cardBalance ? (
            <span>
              Exceeds your card balance by $
              {addCommasToNumber((amount - cardBalance).toFixed(2))}
            </span>
          ) : null}
        </InputContainer>

        <SelectContainer>
          <Custom
            labelInValue={true}
            optionLabelProp="label"
            value={{
              value: "",
              label: withdrawalFundsValue ? (
                <SelectOption>
                  <span className={"currency-flag currency-flag-ngn"} />
                  <span>{withdrawalFundsValue?.label} Wallet</span>
                  <span>
                    {withdrawalFundsValue?.label === "USD" ? "$" : "₦"}
                    {addCommasToNumber(withdrawalFundsValue?.value.toFixed(2))}
                  </span>
                </SelectOption>
              ) : (
                "Into my"
              ),
            }}
            onChange={(e: any) => dispatch(setWithrawalFundsValue(e))}
            suffixIcon={<DropdownIcon />}
            popupClassName="filter-dropdown"
          >
            <Option key={1} value={balance} label={ngnWallet?.currency}>
              <SelectOption>
                <span className="currency-flag currency-flag-ngn" />
                <span>NGN Wallet</span>
                <span>₦{addCommasToNumber(balance?.toFixed(2))}</span>
              </SelectOption>
            </Option>
          </Custom>

          {withdrawalFundsValue?.label === "NGN" && (
            <RateBox>
              ₦{addCommasToNumber(total.toFixed(2))} at $1
              <TransferIcon /> ₦{withdrawRate ? withdrawRate.toFixed(2) : 0}
            </RateBox>
          )}
        </SelectContainer>

        <Button
          onClick={() => setStep(2)}
          label="Continue"
          theme="primary"
          width="100%"
          fontSize="14px"
          height="48px"
          disabled={
            withdrawalFundsValue === null ||
            !withdrawRate ||
            amount < minimumWithdrawalAmt ||
            amount > cardBalance
          }
        />
      </StepsBox>
    </StepsContainer>
  );
};

export default Funds;
