import React from "react";
import { AuthWrapper } from "../../components/AuthWrapper";
import AccountConfirmed from "../../components/auth/AccountConfirmed";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { confirmRegistration } from "../../api/authAPI";

const AccountConfirm = () => {
  const { id } = useParams<{ id?: string }>();

  const { isLoading, isError } = useQuery(["registration", id], () =>
    confirmRegistration({ id })
  );

  return (
    <AuthWrapper>
      <>
        {isLoading && <p>Is loading ...</p>}
        {!isLoading && !isError && <AccountConfirmed />}
      </>
    </AuthWrapper>
  );
};
export default AccountConfirm;
