import React, { ChangeEvent } from 'react';
import { Wrapper } from './styles';

interface Types {
    value?: number | string;
    onChange?: (data: ChangeEvent<HTMLInputElement>) => void;

}

const CurrencyInput = ({value, onChange}: Types) => {
  return (
    <Wrapper placeholder='Amount' value={value} onChange={onChange}

    

    />
  )
}

export default CurrencyInput