import React, { ReactNode } from "react";
import { Header } from "./style";

type ModalHeaderProps = {
    theme?: string;
    children: ReactNode;
}

export default function ModalHeader({theme, children}: ModalHeaderProps) {
    return (
        <Header theme={theme}>
            {children}
        </Header>
    )
}