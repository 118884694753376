import React from 'react';
import { Checkbox } from 'antd';


interface Props {
    onChange?: any;
}

const CheckBox = ({ onChange }: Props) => {
    return (
        <div>
            <Checkbox onChange={onChange} />

        </div>
    )
}

export default CheckBox;