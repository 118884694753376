import React from 'react';
import { Radio } from 'antd';
import { BeneficiaryCardWrapper,  ItemWrapper } from '../../styles';

interface Props {
  name: string;
  accountNumber: string;
  bank: string;
  country: string;
  isSelected: boolean;
  onSelect: (data: any) => void; 
}

const BeneficiaryCard = ({ name, accountNumber, bank, country, isSelected, onSelect }: Props) => {

  const handleClick = () => {
    onSelect({ name, accountNumber, bank, country });
  };

  return (
    <BeneficiaryCardWrapper selected={isSelected} onClick={handleClick}>
     <ItemWrapper data-test-id="beneficiary-card">
        <div>
        <h1>{name}</h1>
      <p>{accountNumber}</p>
        </div>
        <Radio checked={isSelected} />
     </ItemWrapper>
     <ItemWrapper>
     <p className='sub-text'>{bank}</p>
      <p className='sub-text'>{country}</p>
     </ItemWrapper>
    </BeneficiaryCardWrapper>
  );
};

export default BeneficiaryCard;
