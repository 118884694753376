import React, { useContext } from "react";
import { usePostHog } from 'posthog-js/react'
import { Portal } from "@reach/portal";
import { Button } from "../../common/Button";
import { Wrapper, Content, ContentSection, ContentButtons } from "./style";
import { sendOTP } from "../../../api/usersAPI";
import { useAppSelector } from "../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import NetworkErrorAlert from "../../../components/common/alerts/NetworkErrorAlert";

type RequestData = {
  amount: number;
  accounts: number;
};

interface PreviewPayoutProps {
  setShowPreview: (arg: boolean) => void;
  setShowOTP: (arg: boolean) => void;
  setShowModal: (arg: boolean) => void;
  setLoading: (arg: boolean) => void;
  accounts: any[];
  requestData: RequestData;
  fee: number;
  businessCurrency: string;
}

const PreviewPayout = ({
  setShowPreview,
  setShowOTP,
  setShowModal,
  accounts,
  requestData,
  fee,
  businessCurrency,
  setLoading,
}: PreviewPayoutProps) => {
  const posthog = usePostHog();
  const { userObj } = useAppSelector((state) => state.users);
  const { accountUser } = userObj;

  const { onShowAlert, showAlert, alertMessage } = useContext(
    NetworkErrorAlertContext,
  );


  const handleProceed = async () => {
    
    try {
      setLoading(true);
      await sendOTP(accountUser?.email);
   
      posthog?.capture("review_payout_b2b", { status: "success", currency: businessCurrency})
      setLoading(false);
      setShowPreview(false);
      setShowOTP(true); 
    } catch (err) {
      const errorObj = err?.response?.data;
      let errorMessage = "";

      if (errorObj && typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (errorObj && typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Payout Failed";
      }

      setLoading(false);
      onShowAlert(errorObj, errorMessage);
      posthog?.capture("review_payout_b2b", { status: "failure", failure_reason: errorMessage, currency: businessCurrency})
    }
  };

  const editBtn = () => {
    setShowPreview(false);
    setShowModal(true);
  };

  const today: string = new Date().toLocaleDateString().replaceAll("/", ".");
  const merchantData = accounts
    ? accounts.find((account) => account.value === requestData.accounts)
    : {};
  const amount = requestData ? requestData.amount : 0;
  const fees = fee || 0;
  const total = amount + fees;

  return (
    <Portal>
      <Wrapper>
        {showAlert && <NetworkErrorAlert message={alertMessage} />}

        <Content>
          <ContentSection>
            <header>
              <h1>Payout request details</h1>
              <span onClick={editBtn}>Edit</span>
            </header>
            <main>
              <div>
                <p>Amount</p>
                <span>
                  {requestData ? requestData.amount.toFixed(2) : ""} {businessCurrency}
                </span>
              </div>
              <div>
                <p>Fee</p>
                <span>{fees.toFixed(2)}</span>
              </div>
              <div>
                <p>Total Amount</p>
                <span>
                  {total.toFixed(2)} {businessCurrency}
                </span>
              </div>
              <div>
                <p>Date</p>
                <span>{today}</span>
              </div>
            </main>
          </ContentSection>
          <ContentSection>
            <header />
            <main>
              <div>
                <p>Account name</p>
                <span>{merchantData ? merchantData.accName : ""}</span>
              </div>
              <div>
                <p>Account number</p>
                <span>{merchantData ? merchantData.accNumber : ""}</span>
              </div>
              <div>
                <p>Bank name</p>
                <span>{merchantData ? merchantData.bankName : ""}</span>
              </div>
              <div>
                <p>Country</p>
                <span>{merchantData ? merchantData.country : ""}</span>
              </div>
            </main>
          </ContentSection>
          <ContentButtons>
            <Button
              theme="alternate"
              width="100%"
              height="40px"
              label="Cancel"
              fontSize="12px"
              onClick={() => setShowPreview(false)}
            />
            <Button
              theme="secondary"
              width="100%"
              height="40px"
              label="Proceed"
              fontSize="12px"
              onClick={handleProceed}
            />
          </ContentButtons>
        </Content>
      </Wrapper>
    </Portal>
  );
};

export default PreviewPayout;
