import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
    InviteTeam,
    setTeamPassword,
    ActivateTeamAccount,
    GetAllInvitedTeam,
    VerifyTeamOTP,
    DisableTeam,
    UpdateTeamData,
    ResendInvitation,
    ResendOTP,
    CreateRole,
    GetAllRoles,
    GetRolePermissions,
    GetBusinessUserRole
} from "./actions";

const initialState = {
    loading: false,
    error: null,
    success: false,
    errormsg: null,
    data: {}
};

export const selectUserRoleAndPermissions = createSelector(
    state => state.multiUser?.data?.data?.role,
    state => state.multiUser?.data?.data?.permissions,
    (role, permissions) => ({ role, permissions })
  );

const refundSlice = createSlice({
    name: "refunds",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(InviteTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(InviteTeam.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                InviteTeam.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                },
            )
            .addCase(setTeamPassword.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(setTeamPassword.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                setTeamPassword.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                },
            )
            .addCase(
                ActivateTeamAccount.pending,
                (state) => {
                    state.loading = true;
                    state.error = null;
                },
            )
            .addCase(
                ActivateTeamAccount.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                ActivateTeamAccount.rejected,
                (state, { payload }) => {
                    state.loading = false;
                    state.error = payload;
                },
            )
            .addCase(GetAllInvitedTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                GetAllInvitedTeam.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                GetAllInvitedTeam.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            )
            .addCase(VerifyTeamOTP.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(VerifyTeamOTP.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                VerifyTeamOTP.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            )
            .addCase(DisableTeam.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(DisableTeam.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                DisableTeam.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            )
            .addCase(UpdateTeamData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(UpdateTeamData.fulfilled, (state) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(
                UpdateTeamData.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            )
            .addCase(ResendInvitation.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                ResendInvitation.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                ResendInvitation.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            ) .addCase(ResendOTP.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                ResendOTP.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                ResendOTP.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            ).addCase(CreateRole.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                CreateRole.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                CreateRole.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            ).addCase(GetAllRoles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                GetAllRoles.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                GetAllRoles.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            ).addCase(GetRolePermissions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                GetRolePermissions.fulfilled,
                (state) => {
                    state.loading = false;
                    state.success = true;
                },
            )
            .addCase(
                GetRolePermissions.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            ).addCase(GetBusinessUserRole.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(
                GetBusinessUserRole.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.success = true;
                    state.data = action.payload;
                },
            )
            .addCase(
                GetBusinessUserRole.rejected,
                (state, { error }) => {
                    state.loading = false;
                    state.error = error;
                },
            );
    },
});

export default refundSlice.reducer;
