import React, {
    useState,
    useEffect,
    useContext,
} from "react";
import { usePostHog } from "posthog-js/react";
import {
    ContentWrapper,
    Header,
    Box,
    Heading,
    BoxContent,
} from "../style";
import { ReviewProps } from "../types";
import { Button } from "../../../../components/common/Button";
import { formatCurrencyAmount } from "../../../../utils/currency";
import useKlashaPayment from "../../../../libs/use-klasha";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "../../../../app/hooks";
import { gtagEvent } from "../../../../utils/gtag";
import { createWirePayment } from "../../wiresAPI";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { useNavigate } from "react-router-dom";
import { encryptAlgorithm } from "../../../../utils/encryptionAlgorithm";

const whitelistedOrigins = [
    "https://stitch-redirect.herokuapp.com",
    "https://stitch-redirect-o542m.ondigitalocean.app",
    "https://secure.stitch.money",
    "https://plugins-redirect.klasha.com",
];

const Review = ({
    setLoading,
    setStep,
    desc,
    amount,
    totalAmount,
    duration,
    charges,
    rate,
    payAmount,
    selectedRecipient,
    selectedPayCurr,
    selectedRecCurr,
    buyingRate,
}: ReviewProps) => {
    const posthog = usePostHog();
    const [taxRefId, setTaxRefId] = useState("");
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { business } = useAppSelector(
        (state) => state.settings,
    );
    const [initialPaymentData, setInitialPaymentData] =
        useState({});
    const [paymentId, setPaymentId] = useState("");
    const { userObj } = useAppSelector(
        (state) => state.users,
    );
    const [referred, setReferred] = useState(false);
    const { publicReferrer } = userObj || {};
    const navigate = useNavigate();

    const { riskCategory } = business;
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext,
    );

    useEffect(() => {
        if (publicReferrer === "paystack") {
            setReferred(true);
        }
    }, [publicReferrer]);

    useEffect(() => {
        let txRefId = uuidv4();
        txRefId = `KWire-${txRefId}`;
        setTaxRefId(txRefId);
    }, []);

    useEffect(() => {
        // listen to stitch payment response
        const handleMessage = (ev: MessageEvent) => {
            if (whitelistedOrigins.includes(ev.origin)) {
                if (typeof ev.data !== "object") return;
                if (!ev.data.type) return;
                if (ev.data.type !== "klasha:stitch")
                    return;
                if (!ev.data.status) return;
                // callWhenDone(ev.data);
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener(
                "message",
                handleMessage,
            );
        };
    }, []);

    const trackWirePaymentEvent = (
        wireObj,
        failure_reason?: any,
    ) => {
        posthog?.capture(
            "klasha_wire_payment",
            wireObj,
            failure_reason,
        );
    };

    const onMakePayment = async (hasPaid: boolean) => {
        setLoading(true);

        const wireObj = {
            sourceCurrency: selectedPayCurr,
            sourceAmount: parseFloat(payAmount),
            destinationCurrency: selectedRecCurr,
            destinationAmount: amount,
            schedule: true,
            buyingRate: buyingRate,
            total_fees: charges,
            totalFees: charges,
            amountToConvert: parseFloat(payAmount),
            rate: rate,
            paid: hasPaid,
            amountReceived: parseFloat(totalAmount),
            business: businessId,
            merchantBeneficiary: selectedRecipient.id,
            transactionRef: taxRefId,
            description: desc,
            narration: desc,
            invoiceUrl: "",
        };

        const encryptedData = encryptAlgorithm(wireObj);
        const payload = {
            message: encryptedData,
        };

        try {
            await createWirePayment(payload)
            let randomId = uuidv4();
            randomId = `KWire-${randomId}`;
            setPaymentId(randomId);
            setInitialPaymentData(payload);
            trackWirePaymentEvent(wireObj);
            setLoading(false);
        } catch (e) {
            const { message } = e.response.data;
            trackWirePaymentEvent(wireObj, e);
            onShowErrorAlert("", message);
            setLoading(false);
        }
    };


    const callWhenDone = async(data: {
        status: string;
        message: string;
    }) => {
        if (
            data.status === "successful" ||
            data.message === "Payment Successful"
        ) {
            await navigate("/wire");

            posthog?.capture("confirmed_wire_b2b", {
                status: "success",
            });
            gtagEvent({
                action: "status_kwire_success",
                category: "wire",
                label: "KlashaWire successful",
            });
        } else {
            posthog?.capture("confirmed_wire_b2b", {
                status: "failure",
                failure_reason:
                    "KlashaWire failed or cancelled",
            });
            gtagEvent({
                action: "status_kwire_error",
                category: "wire",
                label: "KlashaWire failed or cancelled",
            });
        }
    }

    


    const initializePayment = useKlashaPayment({
        isTestMode: process.env.REACT_APP_ENV === "dev",
        email: business.email,
        phone_number: business.phone,
        merchantKey:
            "GByi/gkhn5+BX4j6uI0lR7HCVo2NvTsVAQhyPko/uK4=",
        businessId: businessId?.toString(),
        amount: totalAmount,
        destinationCurrency: selectedPayCurr,
        sourceCurrency: selectedPayCurr,
        tx_ref: taxRefId,
        fullname: business.name,
        kit: {
            currency: selectedPayCurr,
            tx_ref: taxRefId,
            paymentType: "bulkpayment",
            fullname: selectedRecipient?.name,
            email: business.email,
            phone_number: business.phone,
            callBack: callWhenDone,
            category: riskCategory,
        },
        paymentDescription: desc,
        channel: "bulk",
        // ...extraPayload,
    });

    useEffect(() => {
        if (paymentId) {
            initializePayment();
        }
    }, [paymentId, initialPaymentData]);

    const sendToKlasha = async () => {
        try {
            await onMakePayment(false);
        } catch (err) {
            onShowErrorAlert(err, err.message);
        }
    };

    return (
        <ContentWrapper>
            <Header>
                <h1>Review your transaction details</h1>
                <p>
                    Please check the payment details are
                    correct before completing the
                    transaction
                </p>
            </Header>

            <Box>
                <Heading>
                    <h1>Transaction details</h1>
                    <span onClick={() => setStep(1)}>
                        Edit
                    </span>
                </Heading>

                <BoxContent>
                    <span>Beneficiary’s amount</span>
                    <p>
                        {formatCurrencyAmount(
                            selectedRecCurr,
                            amount,
                        )}
                    </p>
                </BoxContent>
                <BoxContent>
                    <span>Estimated delivery</span>
                    <p>{duration}</p>
                </BoxContent>
                <BoxContent>
                    <span>Conversion rate</span>
                    <p>
                        {(+totalAmount / +amount).toFixed(
                            2,
                        )}
                    </p>
                </BoxContent>
                <BoxContent>
                    <span>Total amount</span>
                    <p>
                        {formatCurrencyAmount(
                            selectedPayCurr,
                            totalAmount,
                        )}
                    </p>
                </BoxContent>
                <BoxContent>
                    <span>Description</span>
                    <p>{desc}</p>
                </BoxContent>

                <Heading className="button">
                    <h1>Beneficiary details</h1>
                    <span onClick={() => setStep(2)}>
                        Edit
                    </span>
                </Heading>

                <BoxContent>
                    <span>Beneficiary’s name</span>
                    <p>{selectedRecipient?.name}</p>
                </BoxContent>
                <BoxContent>
                    <span>Email address</span>
                    <p>{selectedRecipient?.email}</p>
                </BoxContent>
                <BoxContent>
                    <span>Account number</span>
                    <p>
                        {selectedRecipient?.accountNumber}
                    </p>
                </BoxContent>
            </Box>

            <Button
                label="Send wire"
                height="48px"
                fontSize="14px"
                onClick={sendToKlasha}
            />
        </ContentWrapper>
    );
};

export default Review;
