import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { RoleHeading, StyledTransactionsTable } from "../../styles";
import { Button } from "../../../../../components/common/Button";
import { useNavigate } from "react-router-dom";
import RoleListData from "./RoleListData";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../app/hooks";
import { GetAllRoles } from "../../redux/actions";
import Loader from "../../../../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import {  titleCase } from "../../utils/capitalizeName";

const Roles = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { onShowAlert: onShowErrorAlert } =
        useContext(NetworkErrorAlertContext) || {};
    const [data, setData] = useState({
        tableData: null,
        customRoles: null,
        defaultRoles: null
    });
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const { loading } = useAppSelector(
        (state) => state.multiUser || {},
    );

    const handleTeamNav = (data) => {
        navigate(`/settings/team/${data}`)
    
    }

    const getAllBusinessRoles = async () => {
        const data = await dispatch(
            GetAllRoles({ businessId }),
        );
        if (GetAllRoles.rejected.match(data)) {
            onShowErrorAlert(
                "",
                String(
                    "An error occured when getting roles",
                ),
            );
        }
        if (GetAllRoles.fulfilled.match(data)) {
            const defaultRoles = data.payload.data.business_roles.filter(role => role.name === 'ADMIN' || role.name === 'SUPPORT');
            const customRoles = data.payload.data.business_roles.filter(role => role.name !== 'ADMIN' && role.name !== 'SUPPORT');

            setData((prev) => ({
                ...prev,
                tableData: data.payload.data.business_roles,
                customRoles: customRoles,
                defaultRoles: defaultRoles
            }));
        }
    };


    useEffect(() => {
        getAllBusinessRoles();
    }, [businessId]);

    

    return (
        <StyledTransactionsTable>
        <div className="invite-team">
            <Button
                theme="secondary"
                height="40px"
                width="105px"
                label="Create role"
                onClick={() => navigate(`/settings/team/create-role`)}
            />
        </div>
        <Loader isLoading={loading}/>

        {data?.defaultRoles?.length > 0 &&
            <div>
                <RoleHeading>Default roles</RoleHeading>
                {data?.defaultRoles.map((item, index) => (
                    <RoleListData
                        key={index}
                        title={item.name}
                        description={item.description}
                        total_members={item.members}
                        onClick={() => handleTeamNav((item.name))}
                    />
                ))}
            </div>
        }

        {data?.customRoles?.length > 0 &&
            <div>
                <RoleHeading>Custom roles</RoleHeading>
                {data?.customRoles.map((item, index) => (
                    <RoleListData
                        key={index}
                        title={titleCase(item.name)}
                        description={item.description}
                        total_members={item.members}
                        onClick={() => handleTeamNav((item.name))}
                    />
                ))}
            </div>
        }
    </StyledTransactionsTable>
    );
};

export default Roles;
