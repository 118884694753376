import React, { createContext, ReactNode, useEffect, useMemo } from "react";
import jwt_decode from "jwt-decode";
import { BusinessType, UserState } from "../../types/userTypes";

type Action = {
    type: "updateBusiness" | "updateEmail" | "initialise" | "updateUser";
    payload: any;
};
type Dispatch = (action: Action) => void;

const initialState: UserState = {
    token: null,
    email: null,
    businessExists: false,
    businessId: null,
    userId: null,
    bankAccounts: [],
    keys: null,
    business: null,
    contacts: null,
    busTypes: null,
    busCategories: null,
    contactRoles: null,
    businessCurrency: null,
    userObj: {},
    walletStatus: false,
};

function userReducer(state: UserState, action: Action) {
    switch (action.type) {
        case "initialise": {
            return { ...state, ...action.payload };
        }
        case "updateEmail": {
            return {
                ...state,
                email: action.payload.email,

            };
        }
        case "updateUser": {
            return {
                ...state,
                userId: action.payload.id,
                userObj: action.payload,
            };
        }
        case "updateBusiness": {
            const responseData: BusinessType[] = action.payload;
            let busId;
            if (responseData.length > 0) {
                const hasBusiness = responseData.find((bus) => bus.enabled);

                if (hasBusiness) {
                    busId = hasBusiness?.id;
                } else {
                    busId = responseData[0]?.id;
                }
            }
            return { ...state, businessId: busId };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

const UserContext = createContext<
    | { state: UserState; dispatch: Dispatch; updateToken: (x: string) => void }
    | undefined
>(undefined);

const getEmailFromToken = (token: string) => {
    const decodeToken: { sub: string } = jwt_decode(token);
    return decodeToken.sub;
};

const UserProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = React.useReducer(userReducer, initialState);

    const updateToken = (token: string) => {
        if (token) {
            const decodedEmail = getEmailFromToken(token);
            dispatch({ type: "updateEmail", payload: { email: decodedEmail } });

            localStorage.setItem("token", token);
        }
    };
    useEffect(() => {
        // on initial load, get data from storage
        const userLocalStoredDataString = localStorage.getItem("u-1-a");
        if (userLocalStoredDataString) {
            const userLocalStoredData = JSON.parse(userLocalStoredDataString);
            dispatch({ type: "initialise", payload: userLocalStoredData });
        }
    }, []);

    useEffect(() => {
        const stringData = JSON.stringify(state);
        localStorage.setItem("u-1-a", stringData);
    }, [state]);

    const value = useMemo(() => ({ state, dispatch, updateToken }), [state]);
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;

export const useUser = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a CountProvider");
    }
    return context;
};
