import React, { ReactNode } from 'react'
import {EmptyWrapper, EmptyWrapperTitle, EmptyWrapperSubTitle} from "./style"
import { ReactComponent as EmptyIcon } from '../../common/CustomTable/assets/no-transactions.svg'

interface Props {
  icon?: ReactNode,
  title: string,
  subText: string,
  button?: ReactNode,
}

const EmptyMobileCard = ({icon, title, subText, button}: Props) => {
  return (
    <EmptyWrapper>
        {icon || <EmptyIcon/>}
        <EmptyWrapperTitle>{title}</EmptyWrapperTitle>
        <EmptyWrapperSubTitle>{subText}</EmptyWrapperSubTitle>
        {button}
    </EmptyWrapper>
  )
}

export default EmptyMobileCard