import React, { useCallback, useContext, useEffect, useState } from "react";
import { CurrencyWrapper, RateBox } from "../styles";
import {
    getBassFee,
    getExchangeRate,
    getReceivingCountries,
    getReceivingCurrencyByCountry,
} from "../../../../redux/slice";
import CustomSelect from "../../../../../../components/common/CustomSelect";
import InputAndSelect from "../../../../../../components/common/InputAndSelect";
import { Button } from "../../../../../../components/common/Button";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import Loader from "../../../../../../components/common/Loader";
import debounce from "lodash.debounce";
import { savePaymentData } from "../../../../redux/formDataSlice";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";

type Props =  {
    setStep: (data: number) => void;
    currency?: string;
}

interface UserData {
    amount: number;
    convertedAmount: number;
    sourceCurrency?: string;
    loading: boolean;
    fees: number;
    countries?: any;
    selectedCountry?: string;
    availableCurrency?: any;
    destinationCurrency?: string;
    buyingRate: number;
    rate: any;
    convertedRate: any;
}

const initialState: UserData = {
    amount: null,
    convertedAmount: 0,
    loading: true,
    fees: 0,
    countries: null,
    selectedCountry: null,
    availableCurrency: "USD",
    destinationCurrency: "USD",
    buyingRate: null,
    rate: 0,
    sourceCurrency: "",
    convertedRate: "",
};

const InternationalPayout = ({ setStep }: Props) => {
    const dispatch = useAppDispatch();
    const { wallets, loading } = useAppSelector((state) => state?.wallets || {});
    const savedUserData = useAppSelector((state) => state?.formData?.userData || initialState);
    const [userData, setUserData] = useState<UserData>(savedUserData);
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext
      );

    const filteredWallets = wallets as Props[];

    const uniqueCurrencies = Array.from(new Set(filteredWallets?.map((wallet) => wallet.currency)));

    const options = uniqueCurrencies.map((currency) => ({
        label: currency,
        value: currency,
    }));

    const usdOption = options?.filter((option) => option.value === "USD");

    const fetchCountries = useCallback(async () => {
        const action = dispatch(getReceivingCountries());
        const response = await (await action).payload;
        const countries = response?.map((countryObj: any) => ({
            label: countryObj.destinationcountries,
            value: countryObj.destinationcountries,
        }));

        setUserData((prevData) => ({
            ...prevData,
            countries: countries,
        }));
    }, [dispatch]);

    useEffect(() => {
        fetchCountries();
    }, [fetchCountries]);

    const handleDataUpdate = (field: keyof UserData, value: any) => {
        setUserData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleCountryChange = (data: string) => {
        handleDataUpdate("selectedCountry", data);
    };

    useEffect(() => {
        const fetchCurrency = async () => {
            if (userData.selectedCountry) {
                const action = dispatch(getReceivingCurrencyByCountry(userData.selectedCountry));
                const response = await (await action).payload;
                const availableCurrency = response?.map((currency: any) => ({
                    label: currency.availableCurrency,
                    value: currency.availableCurrency,
                }));
                handleDataUpdate("availableCurrency", availableCurrency);
            }
        };

        fetchCurrency();
    }, [userData.selectedCountry, dispatch]);

    const handleAmountChange = (enteredAmount) => {
        setUserData((prevData) => ({
            ...prevData,
            amount: enteredAmount < 1 ? null : enteredAmount,
        }));
    };

    const handleDestinationCurrency = (data: string) => {
        handleDataUpdate("destinationCurrency", data);
    };
    

    const debouncedFetchBassFee = useCallback(
        debounce(async (amount, destinationCurrency) => {
            if (amount !== null && amount > 0 && destinationCurrency !== null) {
                setUserData((prevData) => ({
                    ...prevData,
                    loading: true,
                }));
    
                try {
                    const exchangeRate = await dispatch(
                        getExchangeRate({
                            destinationCurrency: destinationCurrency,
                            sourceCurrency: "USD",
                            provider: "BAAS",
                            source: "checkout",
                            amount,
                        })
                    );
    
                    if (getExchangeRate.fulfilled.match(exchangeRate)) {
                        const { rate, buyingRate, amount: newAmount } = exchangeRate.payload.data;
    
                        const action = await dispatch(getBassFee({ currency: "USD" }));
    
                        if (getBassFee.fulfilled.match(action)) {
                            const { fees } = action.payload;
                            const formattedRate = (1 / userData.rate).toFixed(6);
                            const output = `${formattedRate} (${userData.rate})`;                            
                            // Number((userData.amount /  (1  / rate)) + (fees))
                            
                            setUserData((prevData) => ({
                                ...prevData,
                                fees,
                                sourceCurrency: "USD",
                                apiStatus: "success",
                                convertedAmount: Number((prevData.amount * rate) + fees),
                                loading: false,
                                newAmount: newAmount,
                                convertedRate: output,
                            }));

                            
    
                            handleDataUpdate("rate", rate);
                            handleDataUpdate("buyingRate", buyingRate);
                        } else if (getBassFee.rejected.match(action)) {
                            setUserData((prevData) => ({
                                ...prevData,
                                fees: null,
                                convertedAmount: null,
                                sourceCurrency: null,
                                apiStatus: "failed",
                                loading: false,
                                newAmount: null,
                            }));
                            onShowErrorAlert("", "Failed to fetch Bass Fee. Please try again.");
                        }
                    } else if (getExchangeRate.rejected.match(exchangeRate)) {
                        setUserData((prevData) => ({
                            ...prevData,
                            fees: null,
                            convertedAmount: null,
                            sourceCurrency: null,
                            apiStatus: "failed",
                            loading: false,
                            amount: null,
                        }));
                        onShowErrorAlert("", "Failed to fetch Exchange Rate. Please try again.");
                    }
                } catch (error) {
                    setUserData((prevData) => ({
                        ...prevData,
                        fees: null,
                        convertedAmount: null,
                        sourceCurrency: null,
                        apiStatus: "failed",
                        loading: false,
                    }));
                    onShowErrorAlert("", "Something went wrong. Please try again.");
                }
            } else {
                setUserData((prevData) => ({
                    ...prevData,
                    fees: null,
                    convertedAmount: null,
                    loading: false,
                }));
            }
        }, 1000),
        [dispatch]
    );
    

    useEffect(() => {
        if (userData.amount !== null && userData.amount > 0 && userData.destinationCurrency && userData.selectedCountry) {
            debouncedFetchBassFee(userData.amount, userData.destinationCurrency, userData.selectedCountry);
        }
    }, [userData.amount, userData.destinationCurrency, debouncedFetchBassFee, userData.selectedCountry]);

    

    useEffect(() => {
        dispatch(savePaymentData(userData));
    }, [userData, dispatch]);

    const handleNextStep = () => {
        dispatch(savePaymentData(userData));
        setStep(2);
    };


    useEffect(() => {
        if (!userData.availableCurrency || !userData.destinationCurrency ) {
            setUserData((prevData) => ({
                ...prevData,
                destinationCurrency: "USD",
                availableCurrency: [{ label: "USD", value: "USD" }],
                convertedAmount: null,
                fees: null
            }));
        }
    }, [userData.availableCurrency, userData.destinationCurrency]);

    useEffect(() => {
        if(!userData.amount){
            setUserData((prevData) => ({
                ...prevData,
                convertedAmount: null
            }));

        }
    }, [userData.amount])

    const updatedCountries = userData.countries?.filter(country => country.value !== 'Nigeria');
    
    
    return (
        <CurrencyWrapper>
            <Loader isLoading={loading} />
            <CustomSelect
                $width="100%"
                placeholder="Countries"
                options={updatedCountries}
                showSearch
                onChange={handleCountryChange}
                value={userData.selectedCountry}
            />
            <InputAndSelect
                amountValue={userData.amount}
                width="100%"
                options={userData.availableCurrency}
                onAmountChange={handleAmountChange}
                onSelectChange={handleDestinationCurrency}
                selectValue={userData.destinationCurrency}
            />
            {userData.convertedAmount !== null && (
                <div className="rate-box">
                    <RateBox>
                        <span className="fee-box">
                            *
                            <span>{` $${(1 / userData.rate).toFixed(6)} (${userData.rate})`}</span>
                        </span>
                        <span>Exchange rate</span>
                    </RateBox>
                </div>
            )}
            {userData.convertedAmount !== null && (
                <div className="rate-box">
                    <RateBox>
                        <span className="fee-box">
                            +
                            <span>{`$ ${userData.fees}`}</span>
                        </span>
                        <span>Processing fee</span>
                    </RateBox>
                </div>
            )}
            {userData.convertedAmount !== null && (
                <div className="same-wallet">
                    <InputAndSelect
                        selectValue="USD"
                        options={usdOption}
                        amountValue={userData.convertedAmount.toFixed(4)}
                        width="100%"
                        placeholder="Total amount"
                    />
                </div>
            )}
            <Button
                onClick={handleNextStep}
                width="100%"
                height="48px"
                label="Continue"
                className="btn"
                disabled={loading || userData.fees === null || userData.rate === null || userData.convertedAmount === null}
            />
        </CurrencyWrapper>
    );
};

export default InternationalPayout;