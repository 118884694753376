import React, { ChangeEvent, useContext } from "react";
import { Wrapper } from "./style";
import { exportCSV } from "../../../utils";
import { useAppSelector } from "../../../../../app/hooks";
import Export from "../../../../../components/common/Export";
import Search from "../../../../../components/common/Search";
import { selectUserRoleAndPermissions } from "../../../../Settings/Team/redux/slice";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";

interface Props {
  search: string;
  setSearch: (search: string) => void;
}

const Title = ({ search, setSearch }: Props) => {
  const { cardTransactions } = useAppSelector((state) => state.cards);
  const { role, permissions } = useAppSelector(
    selectUserRoleAndPermissions,
);
const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext,
); 

const hasExportPermission = permissions?.['card']?.includes("EXPORT_CARD_STATEMENT")

  const handleDownload = () => {
    if(hasExportPermission || role === "OWNER"){
        exportCSV(cardTransactions.data)
    }

  else{
    onShowErrorAlert("", "Access is denied")
    

}


}

  return (
    <Wrapper>
      <Search
        value={search}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />

      <Export
        downloadCSV={handleDownload}
        downloadXLS={handleDownload}
      />
    </Wrapper>
  );
};

export default Title;
