import React, { ChangeEvent } from "react";
import { truncateString, exportCSV } from "../../../utils";
import {
  MobileContainer,
  Filters,
  Lists,
  ListsContainer,
  Box,
  Line,
} from "./style";
import EmptyMobileCard from "../../../../../components/emptyStates/EmptyMobileCard";
import { useAppSelector } from "../../../../../app/hooks";
import { TableProps } from "../../../types";
import Search from "../../../../../components/common/Search";
import Export from "../../../../../components/common/Export";

const MobileCard = ({ search, setSearch, tableData }: TableProps) => {
  const { cardTransactions } = useAppSelector((state) => state.cards);

  return (
    <MobileContainer>
      <h3>Transactions</h3>

      {cardTransactions?.data?.length > 0 && (
        <Filters>
          <Search
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />

          <Export
            downloadCSV={() => exportCSV(cardTransactions.data)}
            downloadXLS={() => exportCSV(cardTransactions.data)}
          />
        </Filters>
      )}

      <ListsContainer>
        {tableData?.length > 0
          && tableData.map((item) => (
              <Lists key={item.id}>
                <h1>{item.amount}</h1>
                <Line />

                <Box>
                  <div>
                    <p>Description</p>
                    <span>{item.description}</span>
                  </div>
                  <div>
                    <p>Type</p>
                    <span>{item.cardTransactionType}</span>
                  </div>
                </Box>

                <Box>
                  <div>
                    <p>Reference</p>
                    <span>{truncateString(item.transactionReference, 16)}</span>
                  </div>
                  <div>
                    <p>Date - Time</p>
                    <span>{item.createdAt}</span>
                  </div>
                </Box>
              </Lists>
            ))
          }
      </ListsContainer>

      {tableData?.length < 1  && (
        <EmptyMobileCard
          title="No transactions yet"
          subText="All received or sent transactions will appear here."
        />
      )}
    </MobileContainer>
  );
};

export default MobileCard;