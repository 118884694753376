import React from "react";
import { ButtonWrapper } from "./style";
import Loader from "./Loader";

interface InputProps {
  label?: string;
  theme?: "primary" | "secondary" | "alternate";
  type?: "submit" | "button";
  width?: string;
  ref?:any
  fontSize?: string;
  height?: string;
  disabled?: boolean;
  className?:string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading?:boolean;
  children?: React.ReactNode,
  testId?:string

}
export const Button = ({
  label,
  type = "button",
  theme = "primary",
  width = "100%",
  fontSize = "16px",
  height = "56px",
  onClick,
  disabled = false,
  className,
  loading,
  children,
  ref,
  testId
}: InputProps) => {
  return (
    <ButtonWrapper
      $theme={theme}
      $width={width}
      type={type}
      onClick={onClick}
      $fontSize={fontSize}
      $height={height}
      disabled={disabled}
      className={className}
      ref={ref}
      data-testid={testId}
    >
            {loading ? <Loader  /> : label}
            {children}

    </ButtonWrapper>
  );
};