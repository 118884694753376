import styled from "styled-components";
import * as ResponsiveBreakpoint from "../../../responsiveBreakpoints";


export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(376px, 100%);

  @media${ResponsiveBreakpoint.desktop} {
    width: min(392px, 100%);
  }
  @media${ResponsiveBreakpoint.tab} {
    width: min(429px, 100%);
  }
  @media${ResponsiveBreakpoint.smallTab} {
    width: min(454px, 100%);
  }
  @media${ResponsiveBreakpoint.mobile} {
    width: min(356px, 100%);
  }
  @media${ResponsiveBreakpoint.smallMobile} {
    width: min(344px, 100%);
  }
`;

export const TitleParagraph = styled.p`
  font-family: 'Athletics';
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.48px;
  color: #191919;
  margin-bottom: 45px;
  
  @media (min-width: 768px) {
    margin-bottom: 54px;
  }
`;

export const FormStyle = styled.form`
  width: 100%;
`;


export const FormInputDiv = styled.div`
  text-align: left;
  height: ${(props: { $hasError?: boolean }) =>
    props.$hasError ? "75px" : "70px"};
  font-family: "Inter";

  & > span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    margin-top: 4px;
    color: var(--primary-pink);
    text-align: left;
  }
  input {
    border: #d1d1d1;
    padding: 17px 14px;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #565c69;
  }
`;

export const LoginButton = styled.div`
  padding-top: 32px;
  
  @media (min-width: 768px) {
    padding-top: 38px;
  }
`;