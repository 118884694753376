/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { usePostHog } from 'posthog-js/react'
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../../../../../../components/common/Input';
import { InputBox } from '../../../../../../../../../components/balances/PayoutModal/style';
import { motion } from "framer-motion";
import CustomSelect from '../../../../../../../../../components/common/CustomSelect';
import { Button } from '../../../../../../../../../components/common/Button';
import { nextVariants } from '../../../../../../../utils';

interface FormInputs {
    accountName?: string;
    accountNumber?: string | number;
    sortCode?: string;
    paymentScheme?: string;
}


interface InternationalTransferProps {
    setBasicInfo: (data: FormInputs) => void;
    basicInfo?: FormInputs
    onNext: () => void
    setSelectedPaymentScheme: (data: string) => void;


}

const InternationalTransfer: React.FC<InternationalTransferProps> = ({ setBasicInfo, basicInfo, onNext, setSelectedPaymentScheme, ...props }) => {
    const posthog = usePostHog();
    const { currencyCode } = useParams();
    const [formData, setFormData] = useState<FormInputs>(basicInfo || {});


    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<FormInputs>({
        defaultValues: formData,
    });


    const paymentSchemes = [
        { value: "swift", label: "SWIFT" },
        { value: "sepa", label: "SEPA" },
        { value: "fps", label: "FPs" },
        { value: "chaps", label: "CHAPS" },
    ];

    const onSubmit = (data) => {
        setBasicInfo(data);
        setFormData(data)
        onNext()
       
        posthog?.capture("beneficiary_wallet_send_b2b", { currency: currencyCode})
    }


    return (
        <motion.form variants={nextVariants}
            initial="hidden"
            animate="visible"
            exit="exit">
            <InputBox>
                <Controller
                    name="accountName"
                    control={control}
                    rules={{
                        required: true,

                    }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="Account name"
                            field={field}
                            labelColor="#FFFFFF"
                            disableLabel

                        />
                    )}
                />

                {errors.accountName?.type === "required" && (
                    <span>Please input the beneficiary&apos;s account name</span>
                )}
            </InputBox>


            <InputBox>
                <Controller
                    name="accountNumber"
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="IBAN / Account number"
                            field={field}
                            labelColor="#FFFFFF"
                            disableLabel

                        />
                    )}
                />
                {errors.accountNumber?.type === "required" && (
                    <span>Please input the beneficiary&apos;s account number</span>
                )}
            </InputBox>

            <InputBox>
                <Controller
                    name="sortCode"
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field }) => (
                        <Input
                            type="text"
                            label="Sort code"
                            field={field}
                            labelColor="#FFFFFF"
                            disableLabel

                        />
                    )}
                />
                {errors.sortCode?.type === "required" && (
                    <span>Please input the beneficiary&apos;s sort code</span>
                )}
            </InputBox>


            <InputBox>
                <Controller
                    name="paymentScheme"
                    control={control}
                    rules={{
                        required: true,
                    }}
                    render={({ field }) => (
                        <CustomSelect
                            placeholder="Payment scheme"
                            $height="48px"
                            $width="100%"
                            field={field}
                            options={paymentSchemes?.map((scheme) => ({
                                value: scheme.value,
                                label: scheme.label,
                            }))}
                            onChange={(selectedOption) => setSelectedPaymentScheme(selectedOption)}
                        />
                    )}
                />
                {errors.paymentScheme?.type === "required" && (
                    <span>Please select the payment scheme</span>
                )}
            </InputBox>


            <Button 
                label='Continue' 
                theme='primary' 
                type="submit" 
                onClick={handleSubmit(onSubmit)}
            />

        </motion.form>
    )
}

export default InternationalTransfer;