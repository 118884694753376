import React, { useState, useContext } from "react";
import { Portal } from "@reach/portal";
import { Wrapper } from "../PreviewPayout/style";
import Otp from "../../common/OTP";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";
import NetworkErrorAlert from "../../../components/common/alerts/NetworkErrorAlert";
import SuccessAlert from "../../../components/common/alerts/SuccessAlert";
import { useAppSelector } from "../../../app/hooks";
import { resendOTP, verifyOTP } from "../../../api/usersAPI";

interface PayoutOTPProps {
  setShowOTP: (arg: boolean) => void;
  setLoading: (arg: boolean) => void;
  onRequestPayout: () => void;
}

const PayoutOTP = ({ setShowOTP, setLoading, onRequestPayout }: PayoutOTPProps) => {
  const [otp, setOtp] = useState("");
  const { userObj } = useAppSelector((state) => state.users);
  const { accountUser } = userObj;
  const { onShowAlert, showAlert, alertMessage } = useContext(
    NetworkErrorAlertContext,
  );
  const {
    onShowAlert: onSuccess,
    showAlert: successAlert,
    alertMessage: successMessage,
  } = useContext(SuccessAlertContext);

  const handleVerifyOTP = async (values: string) => {
    if (values.length < 6) {
      onShowAlert(Error, "The OTP code is not complete");
    } else {
      setLoading(true);      

      try {
        const data = await verifyOTP(values, accountUser?.email);
        setOtp("");

        if (data) {
          setLoading(false);
          setShowOTP(false);
          onRequestPayout();
        }
      } catch (err) {
        const errorObj = err?.response?.data;
        let errorMessage = "";

        if (typeof errorObj.error === "string") {
          errorMessage = errorObj.error;
        } else if (typeof errorObj.message === "string") {
          errorMessage = errorObj.message;
        } else {
          errorMessage = "Invalid OTP";
        }

        setLoading(false);
        onShowAlert(errorObj, errorMessage);
      }
    }
  };

  const handleResendOTP = async (user: any) => {
    
    try {
      setLoading(true);
      await resendOTP(user);

      setOtp("");
      setLoading(false);
      onSuccess("Check your email for the OTP");
    } catch (error) {
      const errorObj = error?.response?.data;
      let errorMessage = "";

      if (typeof errorObj.error === "string") {
        errorMessage = errorObj.error;
      } else if (typeof errorObj.message === "string") {
        errorMessage = errorObj.message;
      } else {
        errorMessage = "Failed to resend OTP";
      }

      setLoading(false);
      onShowAlert(errorObj, errorMessage);
    }
  };

  return (
    <Portal>
      <Wrapper>
        {showAlert && <NetworkErrorAlert message={alertMessage} />}
        {successAlert && <SuccessAlert message={successMessage} />}

        <Otp
          smallText="Enter the verification code sent to your email address to complete your request."
          value={otp}
          onChange={setOtp}
          buttonLabel="Request payout"
          onClick={() => handleVerifyOTP(otp)}
          handleClick={() => handleResendOTP(accountUser?.email)}
          onClose={() => setShowOTP(false)}
        />
      </Wrapper>
    </Portal>
  );
};

export default PayoutOTP;
