import styled from "styled-components";
import { motion } from "framer-motion";

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  font-family: "Inter";
`;


export const BlackCard = styled.div`
  width: 100%;
  height: 204px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 6px 40px 2px rgba(0, 0, 0, 0.08);
  background-color: #000000;
  border-radius: 12px;
  padding: 22px 19px 0 19px;
  justify-content: space-between;

  @media (min-width: 768px){
    width: 346px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  img {
    width: 50px;
    height: 16px;
    object-fit: cover;
    margin-bottom: 22px;
  }
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 52px;
    height: 15px;
    object-fit: cover;
  }

  span {
    color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    margin-top: 6px;
  }
`;

export const Balance = styled.div`
  color: ${(props: { active: boolean }) =>
    props.active ? "#ffffff" : "#ffffff68"};
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

export const BalanceAmt = styled.div`
    p{
      font-size: 22px;
      margin-bottom: 0;
      letter-spacing: 0.1em;
    }

    span{
      font-family: "Athletics";
      font-size: 28px;
      font-weight: 400;
      line-height: 34px; 
      letter-spacing: -0.56px;
      margin-bottom: 0;
    }
`

export const BalanceEyes = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    p{
      font-weight: 400;
      font-size: 12px;
      line-height: 15.6px;
      margin-bottom: 2px;
    }
    
    span {
      display: flex;
      cursor: pointer;
      z-index: 1;
      transition: all 3s ease;

      svg {
        height: 15px;
        width: 15px;
      }

    }
`;

export const FrontLines = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;

`;

export const FreezeLogo = styled.div`
  position: absolute;
  top: 27%;
  left: 37%;
`;

export const ListItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  width: 100%;

  @media (min-width: 768px){
    width: 346px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 15.6px;
    color: ${(props: { active: boolean }) =>
      props.active ? "#000000" : "#CECDCE"};
  }
`;

export const Item = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border: 1px solid #CECDCE;
  border-radius: 50%;
  background: #ffffff;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
    background-color: #CECDCE;
    border: 1px solid #CECDCE;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(2px);
  z-index: 10;
`;

export const DetailsContent = styled(motion.div)`
  width: 343px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  border-radius: 8px;
  box-shadow: 0px 0px 38px 0px rgba(45, 66, 89, 0.16);
  max-height: 90%;
  overflow-y: auto;

  svg {
    cursor: pointer;
  }

  @media (min-width: 768px) {
    width: 480px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px 16px;
  margin-bottom: 25px;
  border-bottom: 1px solid #f0f0f0;

  h1 {
    color: #000000;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px 25px 32px;
  font-family: "Inter";

  p {
    color: #767676;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px; 
    margin: 0;
  }

  h1 {
    color: #000000;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
  }
`;

export const ButtonWrapper = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 10px 32px 0 32px;
`;

