import React, { useContext, useEffect, useState } from "react";
import {
    Body,
    ButtonDiv,
    HeaderText,
    Wrapper,
    EmptyWrapper,
    EmptyWrapperSubTitle,
    EmptyWrapperTitle,
} from "./style";
import CustomModal from "../../common/CustomModal";
import CustomTable from "../../common/CustomTable";
import { useWindowSize } from "@reach/window-size";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import AccountCard from "./components/AccountCard";
import { ReactComponent as AddIcon } from "../../../assets/icons/plus.svg";
import {
    addUpdateBankAccount,
    merchantBankAccounts,
} from "../../../pages/Settings/settingsSlice";
import AccountModal from "./components/AccountModal";
import { FormInputs } from "./components/types";
import { SuccessAlertContext } from "../../../context/SuccessAlertContext";
import Loader from "../../common/Loader";
import { NetworkErrorAlertContext } from "../../../context/NetworkErrorAlert";
import { selectUserRoleAndPermissions } from "../../../pages/Settings/Team/redux/slice";

const EmptyBalanceDescription = () => {
    return (
        <EmptyWrapper>
            <EmptyWrapperTitle>No accounts</EmptyWrapperTitle>
            <EmptyWrapperSubTitle>
                No bank accounts added yet.
            </EmptyWrapperSubTitle>
        </EmptyWrapper>
    );
};

const Accounts = () => {
    const { width } = useWindowSize();
    const dispatch = useAppDispatch();
    const { onShowAlert } = useContext(SuccessAlertContext);
    const { onShowAlert: onShowErrorAlert } = useContext(
        NetworkErrorAlertContext
    );

    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState("add");
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState<any>({});
    const { role, permissions } = useAppSelector(
        selectUserRoleAndPermissions,
    );

    const { businessId, userId } = useAppSelector((state) => state.users);
    const { status, bankAccounts } = useAppSelector((state) => state.settings);
    const hasAddBankPermission = permissions?.["bank-account"]?.includes("ADD_BANK_ACCOUNT");

        const tableColumns = [
        {
            title: "Bank name",
            dataIndex: "bankName",
            key: "bankName",
        },
        {
            title: "Account number",
            dataIndex: "accNum",
            key: "accNum",
        },
        {
            title: "Account name",
            dataIndex: "accName",
            key: "accName",
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
        },
        {
            title: "Account",
            dataIndex: "primary",
            key: "primary",
            render: (text, record) =>
                record.primary ? <span>Primary</span> : <span>Secondary</span>,
        },
    ];

    const onSaveAccount = async (payload: FormInputs) => {
        const accountCode = payload.currency === "NGN" ? payload.bankCode : ""

        const addPayload = {
            bankName: payload.bankName,
            accountNumber: payload.accNum,
            accountName: payload.accName,
            swiftCode: payload.swiftCode,
            country: payload.country,
            currency: payload.currency,
            primary: payload.isPrimary,
            owner: userId,
            iban: payload.iban,
            bankCode: accountCode,
            businessId,
            // bankId: bankObj.id,
            routingNumber: payload.routingNum,

            sortCode: payload.sortCode,
            // accountType: payload.accountType
        };

        if (selectedAccount?.id) {
            // @ts-ignore
            addPayload.id = selectedAccount.id;
        }
        try {
            await dispatch(addUpdateBankAccount(addPayload)).unwrap();
            onShowAlert(
                modalType === "add"
                    ? "The new bank account has been added successfully."
                    : "Bank account has been saved successfully."
            );
            onCloseModal();
        } catch (err) {
            onShowErrorAlert(err, err.message);
        }
    };

    const getMerchantAccounts = async () => {
        await dispatch(merchantBankAccounts(userId));
    };

    useEffect(() => {
        if (userId && businessId) {
            getMerchantAccounts();
        }
    }, [userId, businessId]);
    const onShowModal = () => {
        setShowModal(true);
    };
    const onCloseModal = () => {
        setShowModal(false);
    };

    const openAddModal = () => {
        if(hasAddBankPermission || role === "OWNER"){
            setSelectedAccount({});
            setModalType("add");
            onShowModal();
        }
        else{
            onShowErrorAlert("", "Access is denied")
        }
       
    };

    useEffect(() => {
        if (bankAccounts) {
            const tableData = bankAccounts.map((acc) => ({
                key: acc.id,
                id: acc.id,
                bankName: acc.bankName,
                accName: acc.accountName,
                accNum: acc.accountNumber,
                currency: acc.currency,
                accType: acc.accountType,
                country: acc.country,
                iban: acc.iban,
                primary: acc.primary,
                routingNumber: acc.routingNumber,
                sortCode: acc.sortCode,
                swiftCode: acc.swiftCode,
            }));
            setAccounts(tableData);
        }
    }, [bankAccounts]);

    return (
        <Wrapper>
            <Loader isLoading={status === "loading"} />

            <HeaderText>Bank account</HeaderText>
            <Body>
                {width > 767 ? (
                    <CustomTable
                        columns={tableColumns}
                        extraEmptyChildren={<EmptyBalanceDescription />}
                        dataSource={accounts}
                    />
                ) : (
                    accounts.map((acc) => (
                        <AccountCard
                            key={acc.key}
                            currency={acc.currency}
                            accName={acc.accName}
                            bankName={acc.bankName}
                            accNum={acc.accNum}
                            country={acc.country}
                            account={acc}
                            setSelectedAccount={setSelectedAccount}
                        />
                    ))
                )}
                <ButtonDiv>
                    <button onClick={openAddModal}>
                        <span>Add new bank account</span>
                        <AddIcon />
                    </button>
                </ButtonDiv>
            </Body>
            {showModal && (
                <CustomModal
                    isModalVisible={showModal}
                    onClose={onCloseModal}
                    width="464px"
                >
                    <AccountModal
                        onSaveAccount={onSaveAccount}
                        modalType={modalType}
                        account={selectedAccount}
                    />
                </CustomModal>
            )}
        </Wrapper>
    );
};

export default Accounts;
