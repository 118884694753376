import { useState, useCallback } from 'react';
import { useAppSelector } from "../hooks";

type UserData = {
  userAmount: string | number | null;
  amount: null | string | number;
  destinationCurrency: string | null;
  destinationAmount: number;
  sourceCurrency: string | null;
  convertedAmount: string | number | null;
  rate: string | number | null;
  loading: boolean;
  disable: boolean;
  txRef: string;
  quoteToken: string;
  encryptionKey: string;
  publicKey: string;
};

const initialState: UserData = {
  userAmount: null,
  amount: null,
  destinationCurrency: null,
  destinationAmount: 0,
  sourceCurrency: null,
  convertedAmount: null,
  rate: null,
  loading: false,
  disable: false,
  txRef: "",
  encryptionKey: null,
  publicKey: "",
  quoteToken: "",
};

export const useUserData = () => {
  const savedUserData = useAppSelector(
    (state) => state?.formData?.userData || initialState
  );
  const [userData, setUserData] = useState<UserData>(savedUserData);

  const updateField = useCallback((fieldName: keyof UserData) => (value: any) => {
    setUserData(prevData => ({
      ...prevData,
      [fieldName]: value,
    }));
  }, []);

  return [userData, updateField, setUserData] as const;
};
