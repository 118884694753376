import styled from "styled-components";
import { ReactComponent as Copy } from "../../assets/icons/copy.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check-icon.svg"

export const Title = styled.p`
color: var(--shades-black, #000);
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
letter-spacing: -0.48px;
@media(max-width: 768px){
    font-size: 20px;

}
`;
export const Balance = styled.h1`
color: var(--shades-black, #000);
font-family: Athletics;
font-size: 60px;
font-style: normal;
font-weight: 500;
line-height: 62px;
letter-spacing: -2.4px;

@media(max-width: 768px){
    font-size: 35px;

}
`;


export const Flex = styled.div`
display: flex;
justify-content: space-between;
align-items:center;
`

export const Paragraph = styled.p`
color: #6B6B6B;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 114.286% */

`;

export const SubHeading = styled.p`
color: #000;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
padding: 10px;
text-align: right;
`;

export const Heading = styled.h3`
color: #000;
font-family: Athletics;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
letter-spacing: -0.48px;

`;

export const DetailsWrapper = styled.div`
margin-top: 40px

`;

export const Wrapper = styled.div`
border-radius: 8px;
background: #F9F9F9;
padding: 14px;
width: 100%;

`

export const SenderHeading = styled.h3`
color: var(--shades-black, #000);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 155.556% */

`


export const MobileWrapper = styled.div`
  width: 100%;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  padding: 16px 0px;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Top = styled.div`
  padding: 0 16px;
  border-bottom: 1px solid #F0F0F0;
  padding-bottom: 10px;

  p{
    margin: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 15.6px; 
    font-family: "Inter";
    color: #767676;
  }

  span {
    font-style: "Athletics";
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.4px;
    margin-top: 8px;
  }
  
`;

export const Bottom = styled.div`
  display: flex;
  padding: 16px 0 0 16px;
  flex-wrap: wrap;
  justify-content: space-between;

`;

export const BottomItem = styled.div`
  width: 40%;
  margin-bottom: 16px;

`;

export const BottomLabel = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 10px;
  line-height: 15.6px;
  color: #767676;
  margin-bottom: 4px;
`;

export const BottomValue = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0;
`;


export const RequestSettlementWrapper = styled.div`
text-align: center;
h1{
color: var(--shades-black, #000);
text-align: center;
font-family: Athletics;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 32px; /* 160% */
letter-spacing: -0.4px;
}
p{
color: var(--shades-black-500, #757575);
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;

}
`

export const RequestBtns = styled.div`
display: flex;
gap: 10px;
margin-top: 30px;

button{
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.6px; /* 130% */
    
}
`

export const PayoutBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  span {
    font-size: 12px;
    text-align: center;
  }
`;

export const MainWrapper = styled.div`
.settle-btn{
color: var(--button-text-white, #FFF);
text-align: center;
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 600;
line-height: 15.6px; /* 130% */

}

`

export const CheckFlex = styled.div`
display: flex;
align-items: center;
gap: -4px;
`;

export const CopyIcon = styled(Copy)`
margin-bottom: 14px;
width:15px;

`;

export const Check = styled(CheckIcon)`
margin-bottom: 14px;
width:15px;

`;