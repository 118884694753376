import React from "react";
import { Tooltip } from "antd";
import { HTMLTooltipContainer, Wrapper } from "./style";
import { ReactComponent as InfoIcon } from "../../../../../assets/icons/info.svg";

const UploadHeader = ({ label, title }: { label: string; title: string }) => {
  const convertedHtmlEntities = () => {
    return <HTMLTooltipContainer dangerouslySetInnerHTML={{ __html: label }} />;
  };
  return (
    <Wrapper>
      <p>
        <span>{title}</span>

        <span>
          <Tooltip title={convertedHtmlEntities}>
            <InfoIcon />
          </Tooltip>
        </span>
      </p>
    </Wrapper>
  );
};

export default UploadHeader;
