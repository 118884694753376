import React from 'react'
import { Wrapper, Section, Left, Line } from "./style";
import { ReactComponent as OneTimeIcon } from "../../../../pages/PaymentLinks/assets/onetime.svg";
import { ReactComponent as RecurrentIcon } from "../../../../pages/PaymentLinks/assets/recurring.svg";
import { Button } from '../../../common/Button';

interface Props {
    setAddPaymentType: (arg: boolean) => void
    setPaymentType: (val: string) => void;
    setShowModal: (arg: boolean) => void
}

const AddPaymentType = ({
    setAddPaymentType,
    setPaymentType,
    setShowModal,
}:Props) => {

    const onClose = (value: string) => {
        if(value === 'onetime'){
            setPaymentType("ONETIME")
        }
        if(value === 'recurring'){
            setPaymentType("RECURRING")
        }

        setAddPaymentType(false)
        setShowModal(true);
    }
  return (
    <Wrapper>
        <h1>Choose payment type</h1>

        <Section>
            <Left>
                <OneTimeIcon/>
                <div>
                    <h3>One-time payment</h3>
                    <p>Create a simple payment link for <br/> your customers to pay you</p>
                </div>
            </Left> 

            <Button
                label="Choose"
                theme="alternate"
                width="85px"
                height="40px"
                fontSize="12px"
                onClick={() =>onClose('onetime')}
            />
        </Section>

        <Line/>

        <Section>
            <Left>
                <RecurrentIcon/>
                <div>
                    <h3>Recurrent payment</h3>
                    <p>Create a payment link for recurring <br/> payments and subscriptions</p>
                </div>
            </Left> 

            <Button
                label="Choose"
                theme="alternate"
                width="85px"
                height="40px"
                fontSize="12px"
                onClick={() =>onClose('recurring')}
            />
        </Section>
    </Wrapper>
  )
}

export default AddPaymentType