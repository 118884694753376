import styled from "styled-components";

export const Error = styled.span`
    color: red;
`;

export const Wrapper = styled.div`
    width: 100%;
    margin-bottom: 24px;
    font-family: "Inter";

    @media (min-width: 768px) {
        width: min(98%, 505px);
        margin-bottom: 32px;
    }
`;

export const Label = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    margin-bottom: 8px;
    @media (min-width: 768px) {
        display: flex;
    }
`;

export const Body = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    //height: 96px;
    padding: 24px;
`;

export const CopyButton = styled.button`
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 6px;
    width: 92px;
    height: 40px;

    & > span {
        margin-right: 6px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
    }
`;

export const KeyValue = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
`;

export const WebhookWrapper = styled.div`
    width: 100%;
    margin-top: 40px;
    font-family: "Inter";
    h2{
    color: var(--shades-black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
    
    }

    h2{
    color: var(--shades-black, #000);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    
    }

    @media (min-width: 768px) {
        width: min(98%, 505px);
        margin-bottom: 32px;
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
`;
