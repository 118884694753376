import React from "react";
import { Button } from "../../../../components/common/Button";
import { Heading, Flex, SwapWrappBtn } from "./style";
import { ReactComponent as RecycleIcon } from "../../assets/recycle-icon.svg";

type HeaderProps = {
  onClick?: () => void;
};

const Header: React.FC<HeaderProps> = ({ onClick }) => {
  return (
    <Flex>
      <Heading>Wallet</Heading>
      <div>
        <Button height="40px" width="143px" fontSize="12px" onClick={onClick}>
          <SwapWrappBtn>
            <RecycleIcon />
            <span>Swap currency</span>
          </SwapWrappBtn>
        </Button>
      </div>
    </Flex>
  );
};
export default Header;
