import api from "../../app/api"


export async function getDefaultExchangeRate({
                                               sourceCurrency,
                                               amount,
                                               destinationCurrency
                                             }) {
  const { data } = await api.post(
    "nucleus/exchange/",
    { sourceCurrency, amount, destinationCurrency },
    {
      headers: {
        "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
      },
    }
  );
  return data;
}

export async function getReceivingCountries() {
  const { data } = await api.get(
    `/merchant/wirecountry/get/unique/country`,
    {
      headers: {
        "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
      },
    }
  );
  return data;
}

export async function getReceivingCurrenciesByCountry({ country }) {
  const { data } = await api.get(
    `/merchant/wirecountry/get/source/active/${country}`,
    {
      headers: {
        "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
      },
    }
  );
  return data;
}

export async function getExchangeRate({
                                        sourceCurrency,
                                        amount,
                                        destinationCurrency
                                      }) {
  const { data } = await api.post(
    "nucleus/general/exchange",
    { sourceCurrency, amount, destinationCurrency },
    {
      headers: {
        "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
      },
    }
  );
  return data.data;
}

export async function getWireCharges({
                                       selectedPayingCurrency,
                                       selectedSendingCurrency
                                     }) {
  const { data } = await api.get(
    `merchant/bulktransferfee/by/${selectedPayingCurrency}/${selectedSendingCurrency}`,
    {
      headers: {
        "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
      },
    }
  );
  return data;
}

export async function sendFunds(payload) {
  try {
    const { data } = await api.post(
      `/wallet/foreign/banktransfer/request/`,
      payload,
      {
        headers: {
          "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
}


export async function debitWallet(payload) {
  try {
    const { data } = await api.post(
      `/wallet/withdraw/merchant/request/debit`,
      payload,
      {
        headers: {
          "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN,
        },
      }
    );
    return data;
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
}




export async function getWireBeneficiaries(businessId) {
  const { data } = await api.get(`merchant/merchantbeneficiary/${businessId}`)
  return data
}

export const getInternationalTransferFee = async ({
                                                    destinationCountry,
                                                    sourceCountry
                                                  }) => {
  try {
    const response = await api.get(
      `/wallet/fees/get/v2/${destinationCountry}/merchantPayout/${sourceCountry}`,
      {
        headers: {
          "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN
        }
      }
    )
    return response.data
  } catch (error) {
    console.log(error.response?.data || error)
  }
}


export const getAllBankAccount = async () => {
  try {
    const response = await api.post(
      `/merchant/fincra/banks`,
      {},
      {
        headers: {
          "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN
        }
      }
    )

    return response.data.data
  } catch (error) {
    throw error.response?.data?.error || error
  }
}


export const getBankAccountDetail = async ({
                                             account_number,
                                             account_bank
                                           }) => {
  try {
    const response = await api.post(
      `/pay/transfer/flutter/resolve/account`,
      {
        account_number,
        account_bank
      },
      {
        headers: {
          "x-auth-token":
            "GByi/gkhn5+BX4j6uI0lR7HCVo2NvTsVAQhyPko/uK4=",
          "x-fe-token": process.env.REACT_APP_DASHBOARD_ACCESS_TOKEN
        }
      }
    )
    return response.data
  } catch (error) {
    throw error.response.error
  }
}