import styled from "styled-components";


export const Wrapper = styled.div`
 width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  //justify-content: space-between;
  margin-top: 40px;
  font-family: "Inter";

  @media (min-width: 1024px) {
    //width: 329px;
    display: flex;
    justify-content: space-between;
    & > div {
      width: 48%;
    }
  }
.btn{
margin-top: 10px;
}
`

export const KeyList = styled.div`
  width: 100%;
`;

export const HeaderText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #000000;
  font-family: Inter;
`;

export const Flex = styled.div`
display: flex;
align-items: center;
gap: 10px;
img{
cursor: pointer;
}
button{
font-size: 12px;
}
`