import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import CustomSelect from "../../../../../components/common/CustomSelect";
import { Fees } from "./Fees";
import { getCurrency } from "country-currency-map";
import {
    CurrencyCode,
    CurrencyFlag,
    CurrencyForm,
    CurrencyWrapper,
    InputBox,
    InputWrapper,
    SelectedDropdownOption,
} from "../../../styles";
import { Button } from "../../../../../components/common/Button";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { CurrencySelector } from "../../../../../components/common/CurrencySelector";
import Loader from "../../../../../components/common/Loader";
import { formatNumber } from "../../../../../utils/currency";
import ErrorMessage from "../../../../../components/ErrorMessage";
import {
    getDefaultExchangeRate,
    getExchangeRate,
    getInternationalTransferFee,
    getReceivingCountries,
    getReceivingCurrenciesByCountry,
} from "../../../api";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { addWalletData } from "../../../../Wallets/redux/formDataSlice";

interface Props {
    setInputtedAmount?: (data: any) => void;
    minimumPayoutValue?: number;
    selectedSendingCurrency: string;
    intAmount?: number;
    setIntAmount?: (data: number) => void;
    setSelectedSendingCurrency: (data: string) => void;
    totalCharges?: number;
    setInternationalFee: any;
    internationalFee?: any;
    setTotalCharges?: (data: any) => void;
    selectedCountry: string;
    setSelectedCountry: (data: string) => void;
    rate?: any;
    sendingCountriesData: string[];
    setSendingCountriesData: (data: string[]) => void;

    setRate?: (data: any) => void;
    onNext: () => void;
}

const MINIMUM_USD = 100;

const International = ({
    onNext,
    selectedSendingCurrency,
    setSelectedSendingCurrency,
    totalCharges,
    setTotalCharges,
    rate,
    setRate,
    intAmount,
    setIntAmount,
    internationalFee,
    setInternationalFee,
    selectedCountry,
    setSelectedCountry,
    sendingCountriesData,
    setSendingCountriesData,
}: Props) => {
    const [
        sendingCurrenciesData,
        setSendingCurrenciesData,
    ] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMinimumError, setShowMinimumError] =
        useState(false);
    const [showMaximumError, setShowMaximumError] =
        useState(false);
        const dispatch = useAppDispatch();
    const { wallets } = useAppSelector(
        (state) => state.wallets,
    );

    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const walletBalance = wallets?.find(
        (wallet) => wallet.currency == "NGN",
    );
    const Balance = walletBalance?.settlementBalance;

    const [
        minimumRecipientAmount,
        setMinimumRecipientAmount,
    ] = useState(MINIMUM_USD);

    useEffect(() => {
        async function fetchTransactionFee() {
            try {
                await getInternationalTransferFee({
                    destinationCountry: "NG",
                    sourceCountry: selectedSendingCurrency,
                }).then((res) => {
                    const { fees } = res;
                    setInternationalFee(fees);
        
                });
            } catch (error) {
                return error;
            }
        }

        if (
            selectedSendingCurrency !== null &&
            intAmount >= 100
        ) {
            fetchTransactionFee();
        }
    }, [selectedSendingCurrency, intAmount]);

    useEffect(() => {
        if (intAmount > Balance) {
            onShowAlert(
                "",
                "The amount is more than your settlements balance",
            );
        }
    }, [Balance, intAmount]);

    const totalTransferFee = (internationalFee) * (1 / rate?.rate);

    const getRecCountries = async () => {
        setLoading(true);
        try {
            const data = await getReceivingCountries();

            if (data.length) {
                const formattedReceivingCountries =
                    data.map(
                        (sen) => sen.destinationcountries,
                    );
                const receivingCountriesOptions =
                    formattedReceivingCountries.map(
                        (pay) => ({
                            label: pay,
                            value: pay,
                        }),
                    );
                setSendingCountriesData(
                    receivingCountriesOptions,
                );
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        getRecCountries();
    }, []);

    useEffect(() => {
        if (
            intAmount &&
            intAmount < minimumRecipientAmount
        ) {
            setShowMinimumError(true);
        } else {
            setShowMinimumError(false);
        }
    }, [intAmount]);

    const onSendCountryChange = (value: string) => {
        setSelectedCountry(value);
        onSendCurChange(null);
    };

    const onSendCurChange = async (value: string) => {
        setSelectedSendingCurrency(value);
        setShowMinimumError(false);
        if (value && value !== "USD") {
            try {
                const ExcData =
                    await getDefaultExchangeRate({
                        destinationCurrency: value,
                        sourceCurrency: "USD",
                        amount: MINIMUM_USD,
                    });
                const excAmount = ExcData.amount || 0;
                setMinimumRecipientAmount(
                    Math.ceil(excAmount / 100),
                );
            } catch (e) {
                setMinimumRecipientAmount(MINIMUM_USD);
            }
        } else {
            setMinimumRecipientAmount(MINIMUM_USD);
        }
    };

    const getRecCurr = async (country: string) => {
        setLoading(true);
        try {
            const data =
                await getReceivingCurrenciesByCountry({
                    country: country,
                });

            if (data.length) {
                const formattedReceivingCurrencies =
                    data.map(
                        (sen) => sen.availableCurrency,
                    );
                const receivingCurrenciesOptions =
                    formattedReceivingCurrencies.map(
                        (pay) => {
                            let theFullName;
                            if (pay === "GHS") {
                                theFullName = "Ghana Cedis";
                            } else if (pay === "TRY") {
                                theFullName =
                                    "Turkish lira";
                            } else {
                                const regex =
                                    /\(([^)]+)\)/i;
                                theFullName =
                                    getCurrency(pay)?.name;
                                theFullName = theFullName
                                    .replace(regex, "")
                                    .trimEnd();
                            }
                            return {
                                value: pay,
                                fullName: theFullName,
                                label: (
                                    <SelectedDropdownOption>
                                        <li
                                            className={`currency-flag currency-flag-${pay?.toLowerCase()}`}
                                        />{" "}
                                        {pay}
                                    </SelectedDropdownOption>
                                ),
                            };
                        },
                    );
                setSendingCurrenciesData(
                    receivingCurrenciesOptions,
                );
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (selectedCountry && selectedCountry.length) {
            getRecCurr(selectedCountry);
        }
    }, [selectedCountry]);

    const exchangeRate = async () => {
        setLoading(true);
        try {
            const response = await getExchangeRate({
                sourceCurrency: "NGN",
                amount: intAmount,
                destinationCurrency: selectedSendingCurrency,
            });
            const {  rate } = response;
            setRate(response);
            setTotalCharges((1 / rate) * intAmount);
            
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (intAmount >= 100 && selectedSendingCurrency) {
            exchangeRate();
        }
    }, [intAmount, selectedSendingCurrency]);

    const handleNext = async () => {
        const data = {
            processingFee: totalTransferFee.toFixed(2),
            exchangeRate: (1 / rate?.rate).toFixed(2),
            totalAmount: totalCharges + totalTransferFee
        }
        dispatch(addWalletData(data));
        onNext()
    }
        

    return (
        <div>
            <InputBox>
                <CustomSelect
                    options={sendingCountriesData}
                    value={selectedCountry}
                    showSearch
                    onChange={onSendCountryChange}
                    placeholder="Beneficiary Country"
                    $height="40px"
                    $width="100%"
                />
            </InputBox>
            <InputBox>
                <CurrencySelector
                    placeholder="Amount"
                    onSelectChange={onSendCurChange}
                    countries={sendingCurrenciesData?.map(
                        (currency) => ({
                            value: currency.value,
                            label: currency.label,
                        }),
                    )}
                    amount={intAmount}
                    setAmountValue={(value) =>
                        setIntAmount(value)
                    }
                />

                {showMinimumError &&
                    selectedSendingCurrency && (
                        <ErrorMessage
                            message={`Minimum amount: ${selectedSendingCurrency} ${new Intl.NumberFormat().format(
                                minimumRecipientAmount,
                            )}`}
                        />
                    )}
                {showMaximumError && (
                    <ErrorMessage
                        message={`Maximum amount: ${selectedSendingCurrency} ${new Intl.NumberFormat().format(
                            minimumRecipientAmount * 5000,
                        )}`}
                    />
                )}
            </InputBox>

            {totalTransferFee ? (
                <InputBox>
                <Fees
                    fee={
                        Number(totalTransferFee).toFixed(2)
                    }
                    text="Transfer fee"
                />
            </InputBox>
            ): null}

            {rate && (
                <InputBox>
                <Fees
                    fee={
                         Number(1 / rate?.rate).toFixed(2)
                    }
                    text="Exchange rate"
                />
            </InputBox>
            )}

            {totalCharges && (<InputBox>
                <CurrencyForm
                    width="width"
                    value={formatNumber(
                        totalCharges
                            ? Number(
                                  totalCharges + totalTransferFee,
                              ).toFixed(2)
                            : 0,
                    )}
                    placeholder="Total amount you will be charged"
                    addonAfter={
                        <CurrencyWrapper>
                            <CurrencyFlag
                                className={`currency-flag currency-flag-ngn`}
                            />
                            <CurrencyCode className="curr-code">
                                NGN
                            </CurrencyCode>
                        </CurrencyWrapper>
                    }
                />
            </InputBox>)}

            <InputWrapper>
                <Button
                    label="Continue"
                    onClick={handleNext}
                    height="48px"
                    disabled={
                        loading ||
                        !intAmount ||
                        !selectedSendingCurrency ||
                        showMinimumError ||
                        showMaximumError ||
                        intAmount > Balance
                    }
                />
            </InputWrapper>
            <Loader isLoading={loading} />
        </div>
    );
};

export default International;
