import styled from "styled-components";

interface TitleProps {
    stage?: number;
    step?: number;
    stages?: any[];
}

const setBallPosition = (step: number, stage: number, stages: {}[]) => {
    if(stages.length > 2){
        if (step === 2){
            return "48%"
        } else if (step === 3){
            return "96%"
        } else{
            return "0%"
        }
    } else {
        if(step === 2){
            return "96%";
        } else {
            return "0%";
        }
    }
}

export const Wrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 500px;
    margin: 0 auto;
  }
  @media (min-width: 1024px) {
    max-width: 600px;
  }
`;

export const Step = styled.div`
  display: none;
  
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;

    span:not(:last-child) {
      width: ${({ stages }: TitleProps) =>
          stages.length > 2 ? "105px" : "350px"};
      height: 2px;
      background-color: #E9E9E9;;
    }
  }

  @media (min-width: 1024px) {
    span:not(:last-child) {
      width: ${({ stages }: TitleProps) =>
          stages.length > 2 ? "145px" : "450px"};
    }
  }
`;

export const Close = styled.span`
  position: absolute;
  top: -25px;
  right: 0;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 0;
    right: -90px;
  }
  @media (min-width: 1300px) {
    right: -200px;
  }
`;

export const Title = styled.button`
  position: relative;
  font-family: "Inter";
  font-size: 14px;
  font-weight: ${({ stage, step }: TitleProps) =>
    stage === step ? "500" : "400"};
  line-height: 16px;
  margin-bottom: 0;
  color: ${({ stage, step }: TitleProps) =>
    stage === step ? "#EF2C5A" : "#767676"};
  outline: 0;
  border: 0;

  span {
    display: none;
    width: 183px;
    position: absolute;
    top: 200%;
    left: -20%;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px; 
    padding: 8px 12px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 56px 0px rgba(197, 197, 197, 0.40);
  }

  &:disabled{
    cursor: not-allowed;
  }

  &:hover span {
    display: ${({ stage, step }: TitleProps) =>
    step > stage ? "flex" : "none"};
  }
`;

export const MobileStep = styled.div`
  margin: 25px 0 30px 0;
  position: relative;

  div {
    display: flex;
    align-items: center;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;


export const Line = styled.span`
  &:not(:last-child){
    display: inline-block;
    width: ${({ stages }: TitleProps) =>
              stages.length > 2 ? "50%" : "100%"};
    height: 2px;
    background-color: ${({ stage, step }: TitleProps) =>
     step > stage ? "#ef2c5a" : "#F0F0F0"};
  }
  `;

export const Ball = styled.p`
  position: absolute;
  top: 35%;
  left: ${({ step, stage, stages }: TitleProps) => setBallPosition(step, stage, stages)};
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: var(--primary-pink);
  `;
