import styled from "styled-components";
import { Radio } from "antd";

export const Wrapper = styled(Radio)`
  padding: 12px 14px;
  height: 50px;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-family: "Inter";

  & > span:not([class~="ant-radio"]) {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    padding-left: 10px;
  }
  & > span[class~="ant-radio"] > span[class~="ant-radio-inner"] {
    border: 2px solid #d1d1d1;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
  }
  & > span[class~="ant-radio"] {
    top: 0;
  }
  & > span[class~="ant-radio-checked"] > span[class~="ant-radio-inner"] {
    background-color: #000000;
  }
  & > span[class~="ant-radio-checked"] > span[class~="ant-radio-inner"]:after {
    background-color: #ffffff;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
  }

  &:hover {
    border: 1px solid #a3a3a3;
  }

  @media (min-width: 768px) {
    width: 600.65px;
  }
`;
