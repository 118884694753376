import React from "react";
import { CollectionCardTitle, CollectionCardFigures, SummaryCardWrapper, CollectionCardNumber, CollectionCardSubline } from "./style";

type CollectionSummaryCardProps = {
    theme: string;
    title?: string;
    mainFigure?: string;
    subline?: string
}
export default function CollectionSummaryCard ({theme, title, mainFigure, subline}: CollectionSummaryCardProps) {
    return (
        <SummaryCardWrapper theme={theme}>
            <CollectionCardTitle theme={theme}>{title}</CollectionCardTitle>

            <CollectionCardFigures>
                <CollectionCardNumber theme={theme}>{mainFigure}</CollectionCardNumber>
                <CollectionCardSubline>{subline}</CollectionCardSubline>
            </CollectionCardFigures>
        </SummaryCardWrapper>
    )
}