import React from "react";
import AccountDetails from "../../../../components/AccountDetails/AccountDetails";
import { Heading } from "../../style";
import { useParams } from "react-router-dom";
import { accountsCurrencyDetails } from "../../../../static";

type Props = {
    title: string;
    narration: string;
    narrationDetail: string;
    callBack: () => void;
}

const KlashaAccount = ({
    title,
    narration,
    narrationDetail,
    callBack,
}: Props) => {
    const { currencyCode } = useParams();

    const accountDetails = accountsCurrencyDetails[currencyCode as keyof typeof accountsCurrencyDetails];


    return (
        <div>
            <Heading>{title}</Heading>
            <AccountDetails
                accountName="Klasha Technologies Ltd"
                bankName={accountDetails?.bankName}
                accountNumber={accountDetails?.accountNumber}
                accountType={accountDetails?.accountType}
                narration={narration}
                narrationDetail={narrationDetail}
                callBack={callBack}
            />{" "}
        </div>
    );
};

export default KlashaAccount;
