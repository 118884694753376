import React from "react";
import { PasswordErrorBoxWrapper, PasswordErrorText } from "./style";
import { ReactComponent as CancelIcon } from "../assets/close.svg";
import { ReactComponent as GoodIcon } from "../assets/check.svg";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    lintrk: any;
  }
}

const PasswordErrorItem = ({
  hasError,
  message,
}: {
  hasError: boolean;
  message: string;
}) => {
  return (
    <PasswordErrorBoxWrapper>
      {hasError ? <CancelIcon /> : <GoodIcon />}
      <PasswordErrorText $hasError={hasError}>{message}</PasswordErrorText>
    </PasswordErrorBoxWrapper>
  );
};
export default PasswordErrorItem;
