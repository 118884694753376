import React from "react";
import { Flex, Heading, Paragraph, Span, Wrapper } from "./styles";

interface BeneficfiaryCardProps {
    name: string;
    country: string;
    cent: string;
    date: string;
}

const BeneficiaryCard = ({
    name,
    country,
    cent,
    date,
}: BeneficfiaryCardProps) => {
    return (
        <Wrapper>
            <Flex>
                <Heading>{name}</Heading>
                <Paragraph>{country}</Paragraph>
            </Flex>
            <Flex>
                <Span>{date}</Span>
                <Paragraph>{cent}</Paragraph>
            </Flex>
        </Wrapper>
    );
};

export default BeneficiaryCard;
