import styled from "styled-components";

export const Wrapper = styled.a`
  padding: 24px 32px;
  width: 100%;
  font-family: 'Inter';
  border: 1px solid #8f8f8f;
  box-sizing: border-box;
  border-radius: 8px;
  height: 260px;
  //height: 284px;
  overflow-y: auto;
  & p {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    /* margin-right: 30px; */
    /* width: unset; */
    /* width: 330px; */
    width: 100%;
  }
`;

export const Header = styled.div`
  height: 64px;
  max-height: 64px;
  margin-bottom: 6px;
  & img {
    max-width: 100%;
    height: auto;
    max-height: 64px;
  }
`;

export const Body = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  & p {
    margin-right: 4px;
    font-family: 'Athletics';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #000000;
  }
`;

export const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #464646;
`;
