import api from "../../app/api";

export const getVirtualAccountTransactions = async (data) => {

    const response = await api.post(
        `/nucleus/virtual/account/collections/filter/paginated`,
        data
        )
        return response.data
}


export const settleFundsRequest = async (businessId) => {
    const response = await api.post(`wallet/virtual/account/collection/settlement?businessId=${businessId}`);
    return response.data

}

export const getManualSettlement = async (businessId) => {
    const response = await api.get(`wallet/virtual/account/collection/settings?businessId=${businessId}`);
    return response.data

}