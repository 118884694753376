import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Wrapper,
  Header,
  Buttons,
  Title,
  Beneficiaries,
} from "./style";
import Card from "../SendWire/components/Beneficiary/Card";
import ManageBeneficiaryModal from "./components/ManageBeneficiaryModal";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Loader from "../../../components/common/Loader";
import CustomModal from "../../../components/common/CustomModal";
import { Button } from "../../../components/common/Button";
import Search from "../../../components/common/Search";
import BeneficiaryModal from "../SendWire/components/Beneficiary/BeneficiaryModal";
import { getWireBeneficiaries } from "../wiresAPI";
import { setBeneficiaries } from "../wiresSlice";
import EmptyBeneficiary from "./components/EmptyBeneficiary";

const ManageBeneficiaries = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sortedBeneficiaries, setSortedBeneficiaries] = useState([]);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [search, setSearch] = useState('');

  const { businessId } = useAppSelector((state) => state.users);
  const { beneficiaries } = useAppSelector((state) => state.wires);


  const onSelectBeneficiary = (beneficiary) => {
    setShowModal(true);
    setSelectedRecipient(beneficiary);
  };

  const getBeneficiaries = async (id: number) => {
    try {
      setLoading(true);
      const data = await getWireBeneficiaries(id);
      dispatch(setBeneficiaries(data))

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
      getBeneficiaries(businessId);
  }, []);

  useEffect(() => {
    if (!beneficiaries || beneficiaries.length === 0) return;
  
    const searchQuery = (search || "").toLowerCase();
    const filtered = beneficiaries.filter(
      (item) =>
        (item.beneficiaryName?.toLowerCase().includes(searchQuery)) ||
        (item.accountNumber?.toString().includes(searchQuery)) ||
        (item.bankName?.toLowerCase().includes(searchQuery))
    );

    const active = filtered.filter(item => item?.disabled !== true).reverse();
    const disabled = filtered.filter(item => item?.disabled === true);
  
    setSortedBeneficiaries([...active, ...disabled]);
  }, [beneficiaries, search]);
  
  return (
    <Wrapper>
      <Loader isLoading={loading} />

      <Header>
        <h1>Manage beneficiaries</h1>
        <p>You can add or disable beneficiaries here</p>
      </Header>

     {beneficiaries && beneficiaries.length > 0 ?
      <Buttons>
          <Button
            label="Add new beneficiary"
            theme="secondary"
            fontSize="12px"
            height="40px"
            onClick={() =>setShowNewModal(true)}
          />
          <Search
            placeholder="Search beneficiary name, account number"
            width="379px"
            value={search}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
          />
        </Buttons> : null
      }
      
      <div>
      {beneficiaries && beneficiaries.length > 0 ? 
        <Title>Saved beneficiaries</Title> :
      null }

        <Beneficiaries>
          {sortedBeneficiaries?.length ? 
            sortedBeneficiaries.map((beneficiary) => (
              <Card
                key={beneficiary.id}
                accName={beneficiary.beneficiaryName}
                bankName={beneficiary.bankName}
                accNumber={beneficiary.accountNumber}
                currency={beneficiary.country}
                beneficiary={beneficiary}
                selectedRecipientId={selectedRecipient?.id}
                setSelectedRecipient={onSelectBeneficiary}
                disabled={beneficiary.disabled}
            />
            ))
          : <EmptyBeneficiary setShowNewModal={setShowNewModal}/>
          }
        </Beneficiaries>
       </div>

      <CustomModal
        isModalVisible={showNewModal}
        onClose={() => setShowNewModal(false)}
        width="480px"
      >
        <BeneficiaryModal 
          showTitle={true}
          setShowNewModal={setShowNewModal} 
          setLoading={setLoading}
          getBeneficiaries={getBeneficiaries}
        />
      </CustomModal>

      <CustomModal 
        isModalVisible={showModal} 
        onClose={() => setShowModal(false)} 
        width="480px"
        >
         <ManageBeneficiaryModal
           recipient={selectedRecipient}
           setShowModal={setShowModal}
           setLoading={setLoading}
           getBeneficiaries={getBeneficiaries}
         />
      </CustomModal>
    </Wrapper>
  );
};

export default ManageBeneficiaries;
