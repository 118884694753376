import React from 'react'
import { Wrapper } from "./style";
import { Button } from '../../../../../components/common/Button';
import { ReactComponent as EmptyUserIcon } from "../../../../../components/wire/assets/emptyState.svg"

const EmptyBeneficiary = ({ setShowNewModal }: { 
  setShowNewModal: (arg: boolean) => void
}) => {

  return (
    <Wrapper>
      <EmptyUserIcon/>
      <h1>No beneficiary yet</h1>
      <p>All saved beneficiaries will appear here.</p>
      
      <Button
        label='Add new beneficiary'
        theme='secondary'
        width='230px'
        height='40px'
        fontSize='12px'
        onClick={() => setShowNewModal(true)}
      />
    </Wrapper>
  )
}

export default EmptyBeneficiary