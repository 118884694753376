import React, {
    useContext,
    useEffect,
    useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import {
    CurrencyCode,
    CurrencyFlag,
    CurrencyForm,
    CurrencyWrapper,
    InputBox,
} from "../../../styles";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { BeneficiaryBox, Fees } from "./Fees";
import { Button } from "../../../../../components/common/Button";
import { useAppSelector } from "../../../../../app/hooks";
import Loader from "../../../../../components/common/Loader";
import { getExchangeRate } from "../../../api";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";

interface Props {
    inputtedAmount: number;
    setInputtedAmount: (data: any) => void;
    beneFiciaryAmount: number;
    setBeneficiaryAmount: (data: number) => void;
    onNext: () => void;
}

const Local = ({
    inputtedAmount,
    setInputtedAmount,
    beneFiciaryAmount,
    setBeneficiaryAmount,
    onNext,
}: Props) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const { wallets } = useAppSelector(
        (state) => state.wallets,
    );

    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const walletBalance = wallets?.find(
        (wallet) => wallet.currency == "NGN",
    );
    const Balance = walletBalance?.settlementBalance;

    const watchAll = watch();
    const amount = watchAll.amount;
    const minimumPayoutValue = 1000;

    const onSubmit = () => {
        onNext();
    };

    useEffect(() => {
        if (amount >= 50) {
            setLoading(true);
            getExchangeRate({
                sourceCurrency: "NGN",
                amount: amount,
                destinationCurrency: "NGN",
            }).then((data) => {
                setLoading(false);
                setBeneficiaryAmount(data.amount);
            });
        }
    }, [amount]);

    useEffect(() => {
        if (beneFiciaryAmount && beneFiciaryAmount > Balance) {
            onShowAlert(
                "",
                "The amount is more than your settlement balance",
            );
        }
    }, [beneFiciaryAmount]);

    

    return (
        <div>
            <Loader isLoading={loading} />
            <InputBox>
            <Controller
                    name="amount"
                    control={control}
                    rules={{
                        required: true,
                    }}
                    defaultValue={inputtedAmount}
                    render={({ field }) => (
                        <CurrencyForm
                            width="width"
                            {...field}
                            placeholder="Amount"
                            onChange={(value) => {
                                field.onChange(value);
                                setInputtedAmount(value);
                            }}
                            formatter={(value) =>
                                `${value
                                    .toString()
                                    .replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ",",
                                    )}`
                            }
                            parser={(value) =>
                                value.replace(
                                    /\$\s?|(,*)/g,
                                    "",
                                )
                            }
                            addonAfter={
                                <CurrencyWrapper>
                                    <CurrencyFlag
                                        className={`currency-flag currency-flag-ngn`}
                                    />
                                    <CurrencyCode className="curr-code">
                                        NGN
                                    </CurrencyCode>
                                </CurrencyWrapper>
                            }
                        />
                    )}
                />
                <div className="error-top">
                    {errors.amount?.type === "required" && (
                        <ErrorMessage message="Field is required" />
                    )}
                </div>
                {watchAll?.amount < minimumPayoutValue && (
                    <ErrorMessage
                        message={`Minimum amount is NGN ${minimumPayoutValue.toLocaleString()}`}
                    />
                )}
            </InputBox>
            {beneFiciaryAmount ? (
                <Fees fee={53.75} text="Processing fee" />
            ) : (
                ""
            )}

            <BeneficiaryBox amount={beneFiciaryAmount} />

            <Button
                theme="primary"
                label="Continue"
                height="48px"
                onClick={handleSubmit(onSubmit)}
                disabled={
                    beneFiciaryAmount > Balance ||
                    amount < minimumPayoutValue
                }
            />
        </div>
    );
};

export default Local;
