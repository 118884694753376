import React from 'react';
import { formatNumber } from '../../../../../utils/currency';
import { FeesWrapper, BeneficiaryBoxWrapper, Flex } from '../../../styles';
import { ReactComponent as ArrowUp} from '../../../assets/icons/arrowUp.svg';

interface Props{
    text: string;
    fee:any;

}

interface BeneficiaryProps{
    amount:number

}

export const Fees = ({text, fee}: Props) => {
  return (
    <FeesWrapper>
        <span>&#x20A6;{formatNumber(fee)}</span>
        <span>{text}</span>
    </FeesWrapper>
  )
}


export const BeneficiaryBox = ({amount}:BeneficiaryProps) => {
    return(
        <BeneficiaryBoxWrapper>
            <Flex>
            <ArrowUp />
            <div>
            <span>Beneficiary gets</span>
            <h1>&#x20A6;{formatNumber(amount)}</h1>
            </div>

            </Flex>
           
        </BeneficiaryBoxWrapper>
        )

}