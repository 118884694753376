import React, { useEffect, useState } from "react";
import {
  Body,
  BodyBottom,
  BodyHalf,
  BodyLabel,
  BodyTop,
  BodyValue,
  CardsToggle,
  CardsToggleBox,
  EmptyWrapper,
  Footer,
  Wrapper,
} from "./style";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/icons/edit.svg";

const EmptyContacts = ({ onAddNewClick }: { onAddNewClick: () => void }) => {
  return (
    <EmptyWrapper>
      <p>You haven&apos;t added any contacts yet</p>
      <button onClick={onAddNewClick}>
        <span>Add</span>
        <PlusIcon />
      </button>
    </EmptyWrapper>
  );
};

const Contacts = ({
  contacts = [],
  onAddNewClick,
  onEditClick,
}: {
  contacts: any[];
  onAddNewClick: () => void;
  onEditClick: (contact) => void;
}) => {
  const [selectedContact, setSelectedContact] = useState(null);

  const isContactsEmpty = !contacts || contacts.length === 0;
  
  useEffect(() => {
    if (!isContactsEmpty) {
      setSelectedContact(contacts[0]);
    }
  }, [contacts]);

  const onContactToggleClick = (event) => {
    const email = event.target.dataset.email;
    const theSelectedContact = contacts.find((con) => con.email === email);
    if (theSelectedContact) {
      setSelectedContact(theSelectedContact);
    }
  };

  const onEditButtonClick = () => {
    onEditClick(selectedContact);
  };
  if (isContactsEmpty) {
    return <EmptyContacts onAddNewClick={onAddNewClick} />;
  }

  return (
    <Wrapper>
      <Body>
        <BodyTop>
          <BodyHalf>
            <BodyLabel>Name</BodyLabel>
            <BodyValue>{selectedContact?.name}</BodyValue>
          </BodyHalf>
          <BodyHalf>
            <BodyLabel>Position</BodyLabel>
            <BodyValue>{selectedContact?.businessStaffRole?.name}</BodyValue>
          </BodyHalf>
        </BodyTop>
        <BodyBottom>
          <BodyHalf>
            <BodyLabel>Email</BodyLabel>
            <BodyValue>{selectedContact?.email}</BodyValue>
          </BodyHalf>
          <BodyHalf>
            <BodyLabel>Phone number</BodyLabel>
            <BodyValue>{selectedContact?.phone}</BodyValue>
          </BodyHalf>
        </BodyBottom>
      </Body>
      <Footer>
        <button onClick={onEditButtonClick}>
          <span>Edit</span>
          <EditIcon />
        </button>
        <CardsToggleBox>
          {contacts.map((con) => (
            <CardsToggle
              $isSelected={selectedContact?.email === con.email}
              key={con.email}
              data-email={con.email}
              onClick={onContactToggleClick}
              type="button"
            />
          ))}
        </CardsToggleBox>
      </Footer>
    </Wrapper>
  );
};

export default Contacts;
