import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../../assets/icons/check.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import { ButtonWrapper, Flex, FlexBox, Main } from "./styles";
import { Button } from "../../../../components/common/Button";

interface AccountProps {
    accountName: string;
    accountBIC: string;
    accountIban: string;
    intermediaryBIC: string;
    intermediaryBank: string;
}

const AccountDetails = ({
    accountName,
    accountBIC,
    accountIban,
    intermediaryBIC,
    intermediaryBank,
}: AccountProps) => {
    const { currencyCode } = useParams();
    const [copyAccount, setCopyAccount] = useState<string | null>(null);
    const [copyAll, setCopyAll] = useState<string | null>(null);

    const onCopyLinkToClipboard = (data: string) => {
        navigator.clipboard.writeText(data);

        setCopyAccount(data);
        setTimeout(() => {
            setCopyAccount(null);
        }, 3000);
    };

    const onCopyAll = () => {
        const allDetails = `Account holder: ${accountName}\nAccount IBAN: ${accountIban}\nAccount BIC: ${accountBIC}\nIntermediary BIC: ${intermediaryBIC}\nIntermediary Bank: ${intermediaryBank}`;

        onCopyLinkToClipboard(allDetails);
        setCopyAll(allDetails);

        setTimeout(() => {
            setCopyAll(null);
        }, 3000);
    };

    return (
        <Main>
            <h1>Your {currencyCode} bank account details</h1>

            <FlexBox>
                <Flex>
                    <div>
                        <p>Account holder</p>
                    </div>
                    <Flex>
                        <h2>{accountName}</h2>
                        {copyAccount === accountName ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() => onCopyLinkToClipboard(accountName)}
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex>
                    <div>
                        <p>Account IBAN</p>
                    </div>
                    <Flex>
                        <h2>{accountIban}</h2>
                        {copyAccount === accountIban ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() => onCopyLinkToClipboard(accountIban)}
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex>
                    <div>
                        <p>Account BIC/Swift Code</p>
                    </div>
                    <Flex>
                        <h2>{accountBIC}</h2>
                        {copyAccount === accountBIC ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() => onCopyLinkToClipboard(accountBIC)}
                            />
                        )}
                    </Flex>
                </Flex>
    
                <Flex>
                    <div>
                        <p>Intermediary BIC</p>
                    </div>
                    <Flex>
                        <h2>{intermediaryBIC}</h2>
                        {copyAccount === intermediaryBIC ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() => onCopyLinkToClipboard(intermediaryBIC)}
                            />
                        )}
                    </Flex>
                </Flex>
                <Flex>
                    <div>
                        <p>Intermediary Bank</p>
                    </div>
                    <Flex>
                        <h2>{intermediaryBank}</h2>
                        {copyAccount === intermediaryBank ? (
                            <CheckIcon title="copied" />
                        ) : (
                            <CopyIcon
                                title="copy"
                                onClick={() => onCopyLinkToClipboard(intermediaryBank)}
                            />
                        )}
                    </Flex>
                </Flex>
            </FlexBox>

            <ButtonWrapper>
                <Button onClick={onCopyAll} height="40px" theme="alternate">{copyAll ? "Copied" : "Copy all account details"}</Button>
            </ButtonWrapper>
        </Main>
    );
};

export default AccountDetails;
