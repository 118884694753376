import styled from "styled-components";
import { Button as CommonButton } from "../../../../components/common/Button";

interface FlexProps {
    disabledTextColor?: boolean;
}

export const Main = styled.div`
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    padding-bottom: 15px;
    padding: 10px;

    h1 {
        font-family: Athletics, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: center;
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 15px;
    }

    h3 {
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        margin: 0;
    }

    p {
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #757575;
        margin: 0;
    }
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`;

export const Flex = styled.div<FlexProps>`
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    padding: 10px;
    .disabled-text {
        h3,
        p {
            color: ${(isDisabled) =>
                isDisabled ? "#D1D1D1" : "inherit"};
        }
        p{
        margin-top: 7px;
        }
        .class-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        span {
         background: #fde68a99;
            padding: 10px;
            border-radius: 29px;
            min-width: 120px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
            color: #78350F;
        }
    }
`;

export const FundingButton = styled(CommonButton)`
    margin-left: auto;
`;

export const DetailsWrapper = styled.div`
    background: #f7f7f7;
    padding: 20px;

    h3 {
        font-family: Athletics;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.02em;
        text-align: left;
    }
`;

export const ButtonWrapper = styled.div`
    width: 50%;
    margin-left: auto;
    margin-top: 10px;
`;

export const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0f0f0;
`;

export const CopyFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;

        color: #767676;
    }

    h2 {
        color: #000000;
        font-family: Athletics;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.4px;
        margin-top: 3px;
    }

    span {
        cursor: pointer;
    }
`;
