import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  addBusiness,
  addBusinessContact,
  addBusinessSignupReason,
  addUpdateProprietor,
  getAllBusinessProprietors,
  getBusinessCategories,
  getBusinessTypes,
  getComplianceData,
  getMerchantCustomerOutreach,
  getMerchantUseCases,
  getOrganizationType,
  getRequiredDocuments,
  getStaff,
  getUploadedDocuments,
  updateComplianceData,
  uploadDocument,
  getAllOrgBusinesses,
} from "./complianceAPI";

export interface ComplianceData {
  businessType?: string;
  businessUseCase?: string;
  acceptPayment?: string;
  advert?: string;
  averageTransactionNumber?: string;
  monthlyTransactionValue?: string;
  fundSource?: string;
  companyConvictions?: string;
  corruptionPolicy?: string;
  moneyLaunderingAlert?: string;
  customerVerification?: string;
  affiliateCompanies?: string;
  companyBranches?: string;
  moneyLaunderingTraining?: string;
  declarationName?: string;
  declarationCompanyName?: string;
  startupComplianceStatus:
    | "STEP1"
    | "STEP2"
    | "STEP3"
    | "STEP4"
    | "STEP5"
    | "STEP6"
    | "STEP7"
    | "DONE";
  userId: number;
  businessId?: number;
}

export interface ComplianceState {
  status: "idle" | "loading" | "failed" | "succeeded";
  isLoading: false;
  error: string | null;
  businessTypes: any;
  businessCategories: any;
  documents: any;
  uploadedDocuments: any;
  businessUsage: any;
  customerReach: any;
  complianceData: ComplianceData | null;
  proprietors: any;
  organizationType: any;
  organizationBusinessType: any;
}

const initialState: ComplianceState = {
  status: "idle",
  isLoading: false,
  error: null,
  businessTypes: [],
  businessCategories: [],
  documents: [],
  uploadedDocuments: [],
  businessUsage: [],
  customerReach: [],
  complianceData: null,
  proprietors: [],
  organizationType: [],
  organizationBusinessType: [],
};

export const getRequiredDocumentsByType = createAsyncThunk(
  "compliance/required/documents",
  async (
    { businessType, country }: { businessType: number; country: string },
    { rejectWithValue },
  ) => {
    try {
      return await getRequiredDocuments({ businessType, country });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getUploadedDocumentsByBusinessId = createAsyncThunk(
  "compliance/uploaded/documents",
  async ({ businessId }: { businessId: number }, { rejectWithValue }) => {
    try {
      return await getUploadedDocuments({ businessId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getStaffById = createAsyncThunk(
  "business/staff",
  async ({ businessId }: { businessId: number }, { rejectWithValue }) => {
    try {
      return await getStaff({ businessId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getKlashaBusinessTypes = createAsyncThunk(
  "business/types",
  async () => {
    return await getBusinessTypes();
  },
);
export const getklashaOrganizationTypes = createAsyncThunk(
  "business/types",
  async () => {
    return await getOrganizationType();
  },
);

export const getKlashaBusinessCategories = createAsyncThunk(
  "compliance/categories",
  async () => {
    return await getBusinessCategories();
  },
);

export const getklashaOrganizationType = createAsyncThunk(
  "organisation/type",
  async () => {
    return await getOrganizationType();
  },
);

export const addNewBusiness = createAsyncThunk(
  "business/new",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await addBusiness(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addNewBusinessContact = createAsyncThunk(
  "business/contact/new",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await addBusinessContact(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const uploadRequiredDocument = createAsyncThunk(
  "compliance/document",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await uploadDocument(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getMerchantSignupReason = createAsyncThunk(
  "compliance/signup/reason",
  async () => {
    return await getMerchantUseCases();
  },
);

export const getMerchantCustomerReach = createAsyncThunk(
  "compliance/customers/marketing",
  async (
    { featureId }: { featureId: string | number },
    { rejectWithValue },
  ) => {
    try {
      return await getMerchantCustomerOutreach({ featureId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addMerchantSignupReason = createAsyncThunk(
  "business/new",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await addBusinessSignupReason(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const modifyComplianceData = createAsyncThunk(
  "business/compliance/add",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await updateComplianceData(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const addEditProprietor = createAsyncThunk(
  "business/compliance/proprietor",
  async (payload: any, { rejectWithValue }) => {
    try {
      return await addUpdateProprietor(payload);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAllBusinessProprietors = createAsyncThunk(
  "compliance/customers/proprietor",
  async ({ businessId }: { businessId: number }, { rejectWithValue }) => {
    try {
      return await getAllBusinessProprietors({ businessId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchComplianceData = createAsyncThunk(
  "business/compliance/get",
  async ({ userId }: { userId: number }, { rejectWithValue }) => {
    try {
      return await getComplianceData({ userId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const getAllBusinessInOrganization = createAsyncThunk(
  "business/orgType/get",
  async ({ orgId }: { orgId: number }, { rejectWithValue }) => {
    try {
      return await getAllOrgBusinesses({ orgId });
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {},
 
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state = initialState;
      })
      .addCase(getRequiredDocumentsByType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getRequiredDocumentsByType.fulfilled, (state, action) => {
        state.status = "idle";
        state.documents = action.payload;
      })
      .addCase(getRequiredDocumentsByType.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getUploadedDocumentsByBusinessId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUploadedDocumentsByBusinessId.fulfilled, (state, action) => {
        state.status = "idle";
        state.uploadedDocuments = action.payload;
      })
      .addCase(getUploadedDocumentsByBusinessId.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKlashaBusinessTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKlashaBusinessTypes.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessTypes = action.payload;
      })
      .addCase(getKlashaBusinessTypes.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getKlashaBusinessCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKlashaBusinessCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessCategories = action.payload;
        // Add any fetched posts to the array
      })
      .addCase(getKlashaBusinessCategories.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(uploadRequiredDocument.pending, (state) => {
        state.status = "loading";
      })
      .addCase(uploadRequiredDocument.fulfilled, (state, action) => {
        state.status = "idle";
        state.uploadedDocuments.push(action.payload);
      })
      .addCase(uploadRequiredDocument.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getMerchantSignupReason.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMerchantSignupReason.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessUsage = action.payload;
      })
      .addCase(getMerchantSignupReason.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getMerchantCustomerReach.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getMerchantCustomerReach.fulfilled, (state, action) => {
        state.status = "idle";
        state.customerReach = action.payload;
      })
      .addCase(getMerchantCustomerReach.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchComplianceData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchComplianceData.fulfilled, (state, action) => {
        state.status = "idle";
        const payloadData = action.payload;
        state.complianceData = payloadData ? payloadData[0] : null;
      })
      .addCase(fetchComplianceData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(modifyComplianceData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(modifyComplianceData.fulfilled, (state, action) => {
        state.status = "idle";
        state.complianceData = action.payload;
      })
      .addCase(modifyComplianceData.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(addEditProprietor.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addEditProprietor.fulfilled, (state, action) => {
        state.status = "idle";
        const actPayload = action.payload;

        const propExists = state.proprietors.findIndex(
          (prop) => prop.id === actPayload?.id,
        );
        if (propExists !== -1) {
          state.proprietors.splice(propExists, 1, actPayload);
        } else {
          state.proprietors.push(actPayload);
        }
      })
      .addCase(addEditProprietor.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchAllBusinessProprietors.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBusinessProprietors.fulfilled, (state, action) => {
        state.status = "idle";
        state.proprietors = action.payload || [];
      })
      .addCase(fetchAllBusinessProprietors.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAllBusinessInOrganization.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getAllBusinessInOrganization.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllBusinessInOrganization.fulfilled, (state, action) => {
        state.status = "idle";
        state.organizationBusinessType = action.payload || [];
      });
  },
});

export default complianceSlice.reducer;
