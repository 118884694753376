export const formatCurrency = (value) => {
    const numericValue = Number(value) || 0;

    const formattedValue = numericValue.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formattedValue;
};
